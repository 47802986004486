module.exports = {
    'webshop-data.order-list.screen.title': 'Megrendelések',
    'webshop-data.order-list.item.identifier.label': 'Azonosító',
    'webshop-data.order-list.item.name.label': 'Vásárló',
    'webshop-data.order-list.item.product-count.label': 'Tételek',
    'webshop-data.order-list.item.sum-expense.label': 'Összes költés',
    'webshop-data.order-list.item.order-price.label': 'Megrendelés összege',
    'webshop-data.order-list.item.order-date.label': 'Megrendelés ideje',
    'webshop-data.order-list.search.placeholder': 'Keresés',
    'webshop-data.order-list.screen.orders-count.label': 'Megrendelés',
    'webshop-data.order-list.screen.order-stats.button.label': 'Összegzés'
};
