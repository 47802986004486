"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminMenuFooterComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./admin-menu.component.scss");
var AdminMenuFooterComponent = (function (_super) {
    __extends(AdminMenuFooterComponent, _super);
    function AdminMenuFooterComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.user = local_store_1.LocalStore.getItem('User');
        return _this;
    }
    AdminMenuFooterComponent.prototype.render = function () {
        return React.createElement("div", { className: 'MenuFooterComponent' },
            React.createElement("div", null, this.renderUser()),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { compact: true, circular: true, basic: true, icon: 'long arrow alternate left', onClick: function () { return router_provider_1.RouterProvider.goTo('/'); } })));
    };
    AdminMenuFooterComponent.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        this.user = local_store_1.LocalStore.getItem('User');
    };
    AdminMenuFooterComponent.prototype.renderUser = function () {
        var _a, _b;
        if (!this.user) {
            return;
        }
        return React.createElement("div", { className: 'User display-flex align-items-center' },
            this.renderAvatar(),
            React.createElement("span", { className: 'ml-2' },
                trans_1.trans('menu.footer.user.greetings'),
                ", ",
                React.createElement("strong", null, (_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.firstName)));
    };
    AdminMenuFooterComponent.prototype.renderAvatar = function () {
        var _a, _b, _c, _d;
        var monogram = "" + ((_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.lastName[0]) + ((_d = (_c = this.user) === null || _c === void 0 ? void 0 : _c.meta) === null || _d === void 0 ? void 0 : _d.firstName[0]);
        return React.createElement("div", { className: 'Avatar' },
            React.createElement("span", null, monogram));
    };
    return AdminMenuFooterComponent;
}(responsive_component_1.ResponsiveComponent));
exports.AdminMenuFooterComponent = AdminMenuFooterComponent;
