"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpressionFilter = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var React = require("react");
var DatePicker = require("react-datepicker");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var session_storage_1 = require("../../../../libs/session.storage");
var ExpressionFilter = (function (_super) {
    __extends(ExpressionFilter, _super);
    function ExpressionFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            type: _this.props.type,
            operator: _this.props.operator,
            value: _this.props.value,
            nextType: null,
            nextOperator: null,
            nextValue: null,
            show: false
        };
        return _this;
    }
    ExpressionFilter.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.props.type !== prevProps.type) {
            this.setState({ type: this.props.type });
        }
        if (this.props.operator !== prevProps.operator) {
            this.setState({ operator: this.props.operator });
        }
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    };
    ExpressionFilter.prototype.render = function () {
        return [
            this.renderTrigger(),
            this.renderContent()
        ];
    };
    ExpressionFilter.prototype.renderTrigger = function () {
        var _this = this;
        var text = this.state.type && this.state.operator && this.state.value
            ? trans_1.trans("custom-filters-type." + this.state.type) + " " + trans_1.trans("custom-filters-operator." + this.state.operator, this.state.value)
            : 'Szűrő beállítása';
        return React.createElement(semantic_ui_react_1.Button.Group, { basic: true, fluid: true },
            React.createElement(semantic_ui_react_1.Button, { className: "PleasePushYouSiblingToRight", key: 0, onClick: function () { return _this.setState({ show: true }); } }, text),
            ",",
            React.createElement(semantic_ui_react_1.Button, { key: 1, icon: "trash alternate", onClick: function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (_this.props.onDelete) {
                        _this.props.onDelete();
                    }
                } }));
    };
    ExpressionFilter.prototype.renderContent = function () {
        if (!this.state.show) {
            return null;
        }
        if (!this.state.nextType) {
            return this.renderExpressions();
        }
        if (!this.state.nextOperator) {
            return this.renderOperator();
        }
        if (!this.state.nextValue) {
            return this.renderValue();
        }
        return this.renderValue();
    };
    ExpressionFilter.prototype.renderExpressions = function () {
        var _this = this;
        var items = this.props.expressions || [];
        return React.createElement(semantic_ui_react_1.Menu, { vertical: true, attached: "bottom", fluid: true },
            React.createElement(semantic_ui_react_1.Menu.Item, null,
                React.createElement(semantic_ui_react_1.Menu.Menu, null, items.map(function (item, index) { return React.createElement(semantic_ui_react_1.Menu.Item, { header: true, key: index, onClick: function () { return _this.setState({ nextType: item.expression }); }, content: trans_1.trans("custom-filters-type." + item.expression) + " ...", active: item.expression === _this.state.type }); }))));
    };
    ExpressionFilter.prototype.renderOperator = function () {
        var _this = this;
        var config = this.getNextExpressionConfig();
        return React.createElement(semantic_ui_react_1.Menu, { vertical: true, attached: "bottom", fluid: true },
            React.createElement(semantic_ui_react_1.Menu.Item, null,
                React.createElement(semantic_ui_react_1.Menu.Menu, null, config.operators.map(function (item, index) { return React.createElement(semantic_ui_react_1.Menu.Item, { key: index, onClick: function () { return _this.setState({ nextOperator: item }); }, content: "" + trans_1.trans("custom-filters-operator." + item, '...'), active: item === _this.state.operator }); }))));
    };
    ExpressionFilter.prototype.renderValue = function () {
        return React.createElement(semantic_ui_react_1.Menu, { vertical: true, attached: "bottom", fluid: true },
            React.createElement(semantic_ui_react_1.Menu.Item, null, this.renderValueUpdater()));
    };
    ExpressionFilter.prototype.getNextExpressionConfig = function () {
        var _this = this;
        var _a;
        return (_a = this.props.expressions) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.expression === _this.state.nextType; });
    };
    ExpressionFilter.prototype.getNextOperatorValueType = function () {
        var _a, _b;
        var expressionConfig = this.getNextExpressionConfig();
        return (_a = expressionConfig === null || expressionConfig === void 0 ? void 0 : expressionConfig.operatorValueTypes) === null || _a === void 0 ? void 0 : _a[(_b = this.state) === null || _b === void 0 ? void 0 : _b.nextOperator];
    };
    ExpressionFilter.prototype.clear = function () {
        return this.setState({
            nextType: null,
            nextOperator: null,
            nextValue: null
        });
    };
    ExpressionFilter.prototype.renderValueUpdater = function () {
        var operatorType = this.getNextOperatorValueType();
        console.log(operatorType);
        switch (operatorType) {
            case 'string':
                return this.renderStringForm();
            case 'number':
                return this.renderNumberForm();
            case 'date':
                return this.renderSingleDateForm();
            case 'fromToDate':
                return this.renderFromToDateForm();
            case 'startEndNumber':
                return this.renderStartEndNumber();
            case 'stringCustomerStatus':
                return this.renderCustomerStatus(['subscribed', 'unsubscribed']);
            case 'emailSourceSelectorMixed':
                return this.renderEmailSelector();
            default:
                return null;
        }
    };
    ExpressionFilter.prototype.renderStringForm = function () {
        var _this = this;
        var form = new form_builder_1.FormBuilder({
            fields: {
                value: new field_1.Field({
                    name: this.state.nextOperator,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? this.props.value
                        : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? this.props.value : ''
                })
            }
        });
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.emitChange(form.fields.value.getValue()); }, key: Math.random() },
            React.createElement(form_control_1.FormControl, { field: form.fields.value, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true })),
            React.createElement(semantic_ui_react_1.Button, { content: "Ment\u00E9s", fluid: true }));
    };
    ExpressionFilter.prototype.renderNumberForm = function () {
        var _this = this;
        var form = new form_builder_1.FormBuilder({
            fields: {
                value: new field_1.Field({
                    name: this.state.nextOperator,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? this.props.value
                        : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? this.props.value : 0
                })
            }
        });
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.emitChange(parseFloat(form.fields.value.getValue())); }, key: Math.random() },
            React.createElement(form_control_1.FormControl, { field: form.fields.value, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, type: "number" })),
            React.createElement(semantic_ui_react_1.Button, { content: "Ment\u00E9s", fluid: true }));
    };
    ExpressionFilter.prototype.renderSingleDateForm = function () {
        var _this = this;
        var form = new form_builder_1.FormBuilder({
            fields: {
                value: new field_1.Field({
                    name: this.state.nextOperator,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? this.props.value
                        : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? this.props.value : ''
                })
            }
        });
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.emitChange(form.fields.value.getValue()); }, key: Math.random() },
            React.createElement(form_control_1.FormControl, { field: form.fields.value, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                React.createElement(DatePicker.default, { showTimeSelect: true, dateFormat: 'yyyy.MM.dd. hh:mm:ss', style: { width: '100% !important' } })),
            React.createElement(semantic_ui_react_1.Button, { content: "Ment\u00E9s", fluid: true }));
    };
    ExpressionFilter.prototype.renderStartEndNumber = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var form = new form_builder_1.FormBuilder({
            fields: {
                start: new field_1.Field({
                    name: this.state.nextOperator,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? (_a = this.props.value) === null || _a === void 0 ? void 0 : _a.start : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? (_b = this.props.value) === null || _b === void 0 ? void 0 : _b.start : null
                }),
                end: new field_1.Field({
                    name: this.state.nextOperator,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? (_c = this.props.value) === null || _c === void 0 ? void 0 : _c.end : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? (_d = this.props.value) === null || _d === void 0 ? void 0 : _d.end : null
                })
            }
        });
        var emitChange = function (values) {
            _this.emitChange({
                start: parseInt(values.start, 10),
                end: parseInt(values.end, 10),
            });
        };
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return emitChange(form.toJSON()); }, key: Math.random() },
            React.createElement(form_control_1.FormControl, { field: form.fields.start, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, type: "number", min: 1 })),
            React.createElement(form_control_1.FormControl, { field: form.fields.end, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, type: "number" })),
            React.createElement(semantic_ui_react_1.Button, { content: "Ment\u00E9s", fluid: true }));
    };
    ExpressionFilter.prototype.renderFromToDateForm = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var form = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    name: this.state.nextOperator,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? (_a = this.props.value) === null || _a === void 0 ? void 0 : _a.from : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? (_b = this.props.value) === null || _b === void 0 ? void 0 : _b.from : null
                }),
                to: new field_1.Field({
                    name: this.state.nextOperator,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? (_c = this.props.value) === null || _c === void 0 ? void 0 : _c.to : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? (_d = this.props.value) === null || _d === void 0 ? void 0 : _d.to : null
                })
            }
        });
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.emitChange(form.toJSON()); }, key: Math.random() },
            React.createElement(form_control_1.FormControl, { field: form.fields.from, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                React.createElement(DatePicker.default, { showTimeSelect: true, dateFormat: 'yyyy.MM.dd. hh:mm:ss', style: { width: '100% !important' } })),
            React.createElement(form_control_1.FormControl, { field: form.fields.to, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                React.createElement(DatePicker.default, { showTimeSelect: true, dateFormat: 'yyyy.MM.dd. hh:mm:ss', style: { width: '100% !important' } })),
            React.createElement(semantic_ui_react_1.Button, { content: "Ment\u00E9s", fluid: true }));
    };
    ExpressionFilter.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    ExpressionFilter.prototype.renderCustomerStatus = function (options) {
        var _this = this;
        var form = new form_builder_1.FormBuilder({
            fields: {
                value: new field_1.Field({
                    name: this.state.nextOperator,
                    options: [
                        {
                            key: 'subscribed',
                            text: 'Feliratkozott',
                            value: 'subscribed',
                        },
                        {
                            key: 'unsubscribed',
                            text: 'Leiratkozott',
                            value: 'unsubscribed',
                        },
                    ],
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? this.props.value
                        : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? this.props.value : ''
                })
            }
        });
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.emitChange(form.fields.value.getValue()); }, key: Math.random() },
            React.createElement(form_control_1.FormControl, { field: form.fields.value, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { fluid: true, selection: true, control: semantic_ui_react_1.Select })),
            React.createElement(semantic_ui_react_1.Button, { content: "Ment\u00E9s", fluid: true }));
    };
    ExpressionFilter.prototype.renderEmailSelector = function () {
        var _this = this;
        var _a;
        var form = new form_builder_1.FormBuilder({
            fields: {
                value: new field_1.Field({
                    name: this.state.nextOperator,
                    options: [],
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template?_limit=100&_sort[createdAt]=-1",
                    optionsMap: function (response) { var _a; return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                        console.log(response);
                        return {
                            key: item._id,
                            value: item._id,
                            text: item.name
                        };
                    }); },
                    loadOptionsAfterMount: true,
                    placeholder: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    label: "" + trans_1.trans("custom-filters-operator." + this.state.nextOperator, '...'),
                    value: (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator)
                        ? this.props.value
                        : (this.props.type === this.state.nextType) && (this.props.operator === this.state.nextOperator) ? this.props.value : ''
                })
            }
        });
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.emitChange(form.fields.value.getValue()); }, key: Math.random() },
            React.createElement(form_control_1.FormControl, { field: form.fields.value, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { fluid: true, selection: true, control: semantic_ui_react_1.Select })),
            React.createElement(semantic_ui_react_1.Button, { content: "Ment\u00E9s", fluid: true }));
    };
    ExpressionFilter.prototype.emitChange = function (value) {
        if (this.props.onChange) {
            this.props.onChange({
                type: this.state.nextType,
                operator: this.state.nextOperator,
                value: value
            });
        }
        this.clear();
        this.setState({
            show: false
        });
    };
    return ExpressionFilter;
}(React.Component));
exports.ExpressionFilter = ExpressionFilter;
