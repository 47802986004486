"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var registration_component_1 = require("@codebuild/uikit/modules/codebuild/authentication/components/registration.component");
var semantic_ui_checkbox_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.checkbox.form-control-type");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var password_input_1 = require("../../common/semantic/password-input/password-input");
var password_strength_1 = require("../../common/semantic/password-strength/password-strength");
var lowercase_email_field_1 = require("./libs/lowercase-email-field");
var RegistrationComponent = (function (_super) {
    __extends(RegistrationComponent, _super);
    function RegistrationComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.form.fields.lastName.placeholder = '';
        _this.form.fields.firstName.placeholder = '';
        _this.form.fields.email.placeholder = '';
        _this.form.fields.password.placeholder = '';
        _this.form.fields.rePassword.placeholder = '';
        _this.form.field('acceptedTermsOfUse', new field_1.Field({
            name: 'accepted-terms-of-use',
            label: trans_1.trans('auth.registration.form.accepted-terms-of-use.label'),
            placeholder: trans_1.trans('auth.registration.form.accepted-terms-of-use.placeholder'),
            validators: [
                new required_validator_1.RequiredValidator('required')
            ]
        }));
        _this.form.field('acceptedPrivacyPolicy', new field_1.Field({
            name: 'subscribe-newsletter',
            label: trans_1.trans('auth.registration.form.accepted-privacy-policy.label'),
            placeholder: trans_1.trans('auth.registration.form.accepted-privacy-policy.placeholder'),
            value: true,
            validators: []
        }));
        _this.form.field('subscribeNewsletter', new field_1.Field({
            name: 'subscribe-newsletter',
            label: trans_1.trans('auth.registration.form.subscribe-newsletter.label'),
            placeholder: trans_1.trans('auth.registration.form.subscribe-newsletter.placeholder'),
            validators: []
        }));
        return _this;
    }
    RegistrationComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.handleSubmit(); } },
            React.createElement(form_control_1.FormControl, { field: this.form.fields.lastName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, "data-cy": "form-last-name" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.firstName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, "data-cy": "form-first-name" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.email, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: lowercase_email_field_1.LowercaseEmailField, type: "email", "data-cy": "form-email", pattern: "[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.password, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-password" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.password, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType, component: password_strength_1.PasswordStrength }),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.rePassword, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-re-password" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.acceptedTermsOfUse, type: semantic_ui_checkbox_form_control_type_1.SemanticUiCheckboxFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Checkbox, "data-cy": "accepted-terms-of-use" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.subscribeNewsletter, type: semantic_ui_checkbox_form_control_type_1.SemanticUiCheckboxFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Checkbox, "data-cy": "subscribe-newsletter" })),
            this.renderError(),
            this.renderSuccess(),
            this.renderSubmit());
    };
    RegistrationComponent.prototype.renderSubmit = function () {
        return React.createElement(semantic_ui_react_1.Button, { className: "LoginButton", fluid: true, type: "submit", loading: this.props.loading, positive: false, "data-cy": "form-submit" }, trans_1.trans('auth.registration.form.submit'));
    };
    return RegistrationComponent;
}(registration_component_1.RegistrationComponent));
exports.RegistrationComponent = RegistrationComponent;
