"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoListAutomaticNewslettersTabContent = void 0;
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var todo_list_tab_content_container_component_1 = require("./todo-list-tab-content-container.component");
var session_storage_1 = require("../../../../libs/session.storage");
var axios_1 = require("axios");
var semantic_ui_react_1 = require("semantic-ui-react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var shoprenter_mpa_newsletters_screen_1 = require("../../../newsletter/shoprenter-mpa-newsletters.screen");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var TodoListAutomaticNewslettersTabContent = (function (_super) {
    __extends(TodoListAutomaticNewslettersTabContent, _super);
    function TodoListAutomaticNewslettersTabContent(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            dashboardData: _this.props.dashboardData,
        };
        _this.child = React.createRef();
        return _this;
    }
    TodoListAutomaticNewslettersTabContent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (prevState.approvedTemplatesCount !== this.state.approvedTemplatesCount) {
                    this.props.handleApprovedTemplatesChange(this.state.approvedTemplatesCount);
                    this.props.refetch();
                }
                return [2];
            });
        });
    };
    TodoListAutomaticNewslettersTabContent.prototype.componentDidMount = function () {
        console.log('TodoListAutomaticNewslettersTabContent componentDidMount', this.props.dashboardData);
        this.setState({
            dashboardData: this.props.dashboardData
        });
    };
    TodoListAutomaticNewslettersTabContent.prototype.approveDefaultNewsletters = function () {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var currentShop, apiBaseUrl, apiRequestUrl, response;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        console.log('approveDefaultNewsletters');
                        currentShop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        apiBaseUrl = environment_1.Environment.get('api');
                        apiRequestUrl = apiBaseUrl + "/s/" + (currentShop === null || currentShop === void 0 ? void 0 : currentShop._id) + "/template/shoprenter-marketplace-shop/approve";
                        console.log('approveDefaultNewsletters', { apiRequestUrl: apiRequestUrl });
                        return [4, axios_1.default.post(apiRequestUrl, {}, { headers: { Authorization: get_access_token_1.getAccessToken() } })];
                    case 1:
                        response = _g.sent();
                        console.log('approveDefaultNewsletters', { response: response });
                        this.setState({ isLoading: false });
                        (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.refetch) === null || _b === void 0 ? void 0 : _b.call(_a);
                        if ((_d = (_c = this.child) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.refetch) {
                            console.log('approveDefaultNewsletters refetch child data...');
                            (_f = (_e = this.child.current).refetch) === null || _f === void 0 ? void 0 : _f.call(_e);
                        }
                        return [2];
                }
            });
        });
    };
    TodoListAutomaticNewslettersTabContent.prototype.handleApprovedTemplatesCountChange = function (count) {
        this.setState({ approvedTemplatesCount: count });
    };
    TodoListAutomaticNewslettersTabContent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        console.log('TodoListAutomaticNewslettersTabContent dashboardData', this.state.dashboardData);
        console.log('TodoListAutomaticNewslettersTabContent render', {
            approvedTemplatesCount: this.props.approvedTemplatesCount
        });
        var isApprovingDisabled = ((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.dashboardData) === null || _b === void 0 ? void 0 : _b.dashboard) === null || _c === void 0 ? void 0 : _c.autoMailUpAndRunning) === true || false;
        var isApprovalButtonDisabled = this.props.approvedTemplatesCount && this.props.approvedTemplatesCount >= 10;
        return (React.createElement(todo_list_tab_content_container_component_1.TodoListTabContentContainerComponent, null,
            React.createElement(semantic_ui_react_1.Message, { info: true, style: { backgroundColor: '#EDF4FF', boxShadow: 'none', color: "#051D40" } },
                React.createElement("p", null,
                    React.createElement("strong", null, "El\u00E9rt\u00FCnk az utols\u00F3 l\u00E9p\u00E9shez! Nincs m\u00E1s h\u00E1tra, hagyjuk j\u00F3v\u00E1 az el\u0151k\u00E9sz\u00EDtett leveleket!")),
                React.createElement("p", null,
                    "A levelek j\u00F3v\u00E1hagy\u00E1s\u00E1hoz ",
                    React.createElement("strong", null, "el\u0151re elk\u00E9sz\u00EDtettem neked 10 db e-mail sablon levelet"),
                    ", ez\u00E9rt neked most erre sem kell ford\u00EDtanod, csak j\u00F3v\u00E1hagynod!"),
                React.createElement("p", null, "Rem\u00E9lem te is annyira v\u00E1rod m\u00E1r, hogy a leveleid j\u00F3v\u00E1hagy\u00E1sa ut\u00E1n, v\u00E9gre elkezdhess\u00FCk a k\u00F6z\u00F6s munk\u00E1t!"),
                React.createElement("p", null,
                    React.createElement("strong", null, "\uD83D\uDCA1A levelek j\u00F3v\u00E1hagy\u00E1s\u00E1hoz, hoztam neked n\u00E9h\u00E1ny hasznos tippet:")),
                React.createElement("p", null,
                    "1. Ahhoz, hogy min\u00E9l nagyobb hat\u00E9konys\u00E1ga \u00E9s konverzi\u00F3ja legyen az e-maileknek ",
                    React.createElement("strong", null, "j\u00F3v\u00E1 kell hagynod legal\u00E1bb 10 db e-mail sablont !")),
                React.createElement("p", null,
                    "2. Olvasd \u00E1t a leveleket, \u00E9s ",
                    React.createElement("strong", null, "helyezz el benne 1-2 helyen kulcsszavakat, webshopodra jellemz\u0151 kifejez\u00E9seket"),
                    ", amik a te term\u00E9kedr\u0151l, t\u00E9m\u00E1dr\u00F3l sz\u00F3lnak."),
                React.createElement("p", null, "3. A sablon leveleket k\u00E9s\u0151bb is tudod m\u00F3dos\u00EDtani, ez\u00E9rt ha most nincs id\u0151d ezzel foglalkozni, akkor hagyd j\u00F3v\u00E1 egyszerre a leveleket!"),
                React.createElement("p", null,
                    "Ha mindennel elk\u00E9sz\u00FClt\u00E9l, \u00E9s indulhatunk, akkor kattints az ",
                    React.createElement("strong", null, "\u201CALAP\u00C9RTELMEZETT LEVELEK J\u00D3V\u00C1HAGY\u00C1SA\u201D"),
                    " gombra!")),
            !isApprovingDisabled && (React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.approveDefaultNewsletters(); }, content: 'Alapértelmezett levelek jóváhagyása', disabled: this.state.isLoading || isApprovalButtonDisabled, loading: this.state.isLoading, style: {
                    backgroundColor: '#004AAD'
                } })),
            this.state.isLoading && (React.createElement("p", null, "Folyamatban van az alap\u00E9rtelmezett levelek j\u00F3v\u00E1hagy\u00E1sa, egy kis t\u00FCrelmet k\u00E9r\u00FCnk, ez egy hossz\u00FA folyamat! K\u00E9rj\u00FCk, ne navig\u00E1lj el, ne z\u00E1rd be az oldalt!")),
            React.createElement(shoprenter_mpa_newsletters_screen_1.ShoprenterMarketplaceShopNewsletterScreen, __assign({ ref: this.child, onApprovedTemplatesCountChange: function (count) { return _this.handleApprovedTemplatesCountChange(count); }, approvedTemplatesCount: this.props.approvedTemplatesCount }, this.props))));
    };
    return TodoListAutomaticNewslettersTabContent;
}(abstract_component_1.AbstractComponent));
exports.TodoListAutomaticNewslettersTabContent = TodoListAutomaticNewslettersTabContent;
