module.exports = {
    'shoprenter-apps-list-menu.title': 'Shoprenter Appok',
    'shoprenter-apps-list-menu.item.active-shops': 'Aktív előfizetők',
    'shoprenter-apps-list-menu.item.registering-shops': 'Telepítés folyamatban',
    'shoprenter-apps-list-menu.item.installed-waiting-first-payment': 'Első díjfizetés előtt áll',
    'shoprenter-apps-list-menu.item.uninstalled-before-first-payment': 'Első díjfizetés előtt törölt',
    'shoprenter-apps-list-menu.item.paid-not-anymore-shops': 'Nem fizet',
    'shoprenter-apps-list-menu.item.paid-deleted-shops': 'Fizetés után törölt',
    'shoprenter-apps-list-menu.item.all-shops': 'Összes webshop',
    'shoprenter-apps-list-menu.item.deleted-shops': 'Törölt app-ok'
};
