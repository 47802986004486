"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_checkbox_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.checkbox.form-control-type");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
require("./billing.component.scss");
var BillingComponent = (function (_super) {
    __extends(BillingComponent, _super);
    function BillingComponent() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var _this = _super.apply(this, arguments) || this;
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: session_storage_1.SessionStorage.getItem('SelectedShop'),
            updateLoading: false,
            updateError: false,
            loading: false
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                companyName: new field_1.Field({
                    name: 'companyName',
                    placeholder: '',
                    label: trans_1.trans('settings.billing.companyName.label'),
                    value: ((_b = (_a = _this.state.shop) === null || _a === void 0 ? void 0 : _a.billingData) === null || _b === void 0 ? void 0 : _b.companyName) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                taxNumber: new field_1.Field({
                    name: 'taxNumber',
                    placeholder: '',
                    label: trans_1.trans('settings.billing.taxNumber.label'),
                    value: ((_d = (_c = _this.state.shop) === null || _c === void 0 ? void 0 : _c.billingData) === null || _d === void 0 ? void 0 : _d.taxNumber) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                euTaxNumber: new field_1.Field({
                    name: 'euTaxNumber',
                    placeholder: '',
                    label: trans_1.trans('settings.billing.euTaxNumber.label'),
                    value: ((_f = (_e = _this.state.shop) === null || _e === void 0 ? void 0 : _e.billingData) === null || _f === void 0 ? void 0 : _f.euTaxNumber) || '',
                    validators: []
                }),
                country: new field_1.Field({
                    name: 'country',
                    placeholder: '',
                    label: trans_1.trans('settings.billing.country.label'),
                    value: ((_h = (_g = _this.state.shop) === null || _g === void 0 ? void 0 : _g.billingData) === null || _h === void 0 ? void 0 : _h.country) || 'Magyarország',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                city: new field_1.Field({
                    name: 'city',
                    placeholder: '',
                    label: trans_1.trans('settings.billing.city.label'),
                    value: ((_k = (_j = _this.state.shop) === null || _j === void 0 ? void 0 : _j.billingData) === null || _k === void 0 ? void 0 : _k.city) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                zipCode: new field_1.Field({
                    name: 'zipCode',
                    placeholder: '',
                    label: trans_1.trans('settings.billing.zipCode.label'),
                    value: ((_m = (_l = _this.state.shop) === null || _l === void 0 ? void 0 : _l.billingData) === null || _m === void 0 ? void 0 : _m.zipCode) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                address: new field_1.Field({
                    name: 'address',
                    placeholder: '',
                    label: trans_1.trans('settings.billing.address.label'),
                    value: ((_p = (_o = _this.state.shop) === null || _o === void 0 ? void 0 : _o.billingData) === null || _p === void 0 ? void 0 : _p.address) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                termsOfUse: new field_1.Field({
                    name: 'termsOfUse',
                    label: trans_1.trans('settings.billing.termsOfUse.label'),
                    placeholder: trans_1.trans('settings.billing.termsOfUse.placeholder'),
                    validators: [
                        new required_validator_1.RequiredValidator('required')
                    ]
                })
            }
        });
        return _this;
    }
    BillingComponent.prototype.render = function () {
        return React.createElement("div", { className: 'BillingComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, trans_1.trans('settings.billing.title')))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 }, this.renderMailGunSenderForm()))));
    };
    BillingComponent.prototype.renderMailGunSenderForm = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Form, null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.companyName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: 'equal' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.taxNumber, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.euTaxNumber, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false }))),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.country, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: 'equal' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.city, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.zipCode, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false }))),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.address, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.termsOfUse, type: semantic_ui_checkbox_form_control_type_1.SemanticUiCheckboxFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Checkbox, "data-cy": "termsOfUse" })),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.handleSubmit(); }, content: 'Mentés' })));
    };
    BillingComponent.prototype.handleSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, data, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        data = this.form.toJSON();
                        return [4, this.shop.put("/s/" + this.getCurrentShop()._id + "/shop/billingData", { data: data })];
                    case 2:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success(trans_1.trans('Sikeres módosítás!'));
                        this.setState({ loading: false });
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.setState({ loading: false });
                        console.log(e_1);
                        handle_toasts_1.HandleToasts.error(trans_1.trans('Hiba történt!'));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    BillingComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return BillingComponent;
}(abstract_component_1.AbstractComponent));
exports.BillingComponent = BillingComponent;
