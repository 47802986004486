"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterScreen = void 0;
require("../../leads/components/list/lead-list.component.scss");
require("./shoprenter.screen.scss");
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var session_storage_1 = require("../../../libs/session.storage");
var state_repository_1 = require("../../common/libs/state-repository");
var todo_list_automatic_newsletters_tab_content_component_1 = require("./tabs/todo-list-automatic-newsletters-tab-content.component");
var todo_list_dns_tab_content_component_1 = require("./tabs/todo-list-dns-tab-content.component");
var todo_list_list_cleaning_tab_content_component_1 = require("./tabs/todo-list-list-cleaning-tab-content.component");
var todo_list_shop_profile_tab_content_component_1 = require("./tabs/todo-list-shop-profile-tab-content.component");
var todo_list_sync_tab_content_component_1 = require("./tabs/todo-list-sync-tab-content.component");
var todo_list_webshop_data_tab_content_component_1 = require("./tabs/todo-list-webshop-data-tab-content.component");
var shoprenter_todo_list_summary_content_component_1 = require("./tabs/shoprenter-todo-list-summary-content.component");
var axios_1 = require("axios");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var toast_util_1 = require("../errors/toast.util");
var errors_constant_1 = require("../errors/errors.constant");
var tabName1 = (React.createElement("div", { key: 'tab1' },
    "1. l\u00E9p\u00E9s ",
    React.createElement("br", null),
    " WEBSHOP ADATOK"));
var tabName2 = (React.createElement("div", { key: 'tab2' },
    "2. l\u00E9p\u00E9s ",
    React.createElement("br", null),
    " SZINKRONIZ\u00C1CI\u00D3"));
var tabName3 = (React.createElement("div", { key: 'tab3' },
    "3. l\u00E9p\u00E9s ",
    React.createElement("br", null),
    " WEBSHOP SZKRIPT"));
var tabName4 = (React.createElement("div", { key: 'tab4' },
    "3. l\u00E9p\u00E9s ",
    React.createElement("br", null),
    " LISTATISZT\u00CDT\u00C1S"));
var tabName5 = (React.createElement("div", { key: 'tab5' },
    "4. l\u00E9p\u00E9s ",
    React.createElement("br", null),
    " DNS BE\u00C1LL\u00CDT\u00C1S"));
var tabName6 = (React.createElement("div", { key: 'tab6' },
    "5. l\u00E9p\u00E9s ",
    React.createElement("br", null),
    " SABLON BE\u00C1LL\u00CDT\u00C1S"));
var tabName7 = (React.createElement("div", { key: 'tab7' },
    "6. l\u00E9p\u00E9s ",
    React.createElement("br", null),
    " AUTOMATA LEVELEK"));
var tabName8 = (React.createElement("div", { key: 'tab8' }, "\u00D6SSZEGZ\u00C9S"));
var shoprenterTabs = {
    TODO_LIST_TAB_INDEX_WEBSHOP_DATA: {
        index: 0,
        tabName: tabName1,
    },
    TODO_LIST_TAB_INDEX_SYNC: {
        index: 1,
        tabName: tabName2,
    },
    TODO_LIST_TAB_INDEX_LIST_CLEANING: {
        index: 3,
        tabName: tabName4,
    },
    TODO_LIST_TAB_INDEX_DNS: {
        index: 4,
        tabName: tabName5,
    },
    TODO_LIST_TAB_INDEX_SHOP_PROFILE: {
        index: 5,
        tabName: tabName6,
    },
    TODO_LIST_TAB_INDEX_AUTOMATIC_NEWSLETTERS: {
        index: 6,
        tabName: tabName7,
    },
    TODO_LIST_TAB_INDEX_SUMMARY: {
        index: 7,
        tabName: tabName8,
    },
};
var ShoprenterScreen = (function (_super) {
    __extends(ShoprenterScreen, _super);
    function ShoprenterScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            tab: shoprenterTabs.TODO_LIST_TAB_INDEX_WEBSHOP_DATA.index,
            apiData: null,
            loading: false,
            intervalId: null,
            shop: session_storage_1.SessionStorage.getItem('SelectedShop'),
        };
        _this.fetchApprovedTemplatesCount = function () { return __awaiter(_this, void 0, void 0, function () {
            var currentShop, apiBaseUrl, apiRequestUrl, response, data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('fetchApprovedTemplatesCount');
                        this.setState({ approvedTemplatesCount: null, loadingApprovedTemplates: true });
                        currentShop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        apiBaseUrl = environment_1.Environment.get('api');
                        apiRequestUrl = apiBaseUrl + "/s/" + (currentShop === null || currentShop === void 0 ? void 0 : currentShop._id) + "/template/shoprenter-marketplace-shop/count";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, axios_1.default.get(apiRequestUrl, { headers: { Authorization: get_access_token_1.getAccessToken() } })];
                    case 2:
                        response = _a.sent();
                        data = response.data;
                        console.log('fetchApprovedTemplatesCount response:', data);
                        this.setState({ approvedTemplatesCount: (data === null || data === void 0 ? void 0 : data.approvedTemplates) || null, loadingApprovedTemplates: false });
                        return [3, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error fetching data: ', error_1);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_APPROVED_EMAIL_TEMPLATES_COUNT);
                        this.setState({ loadingApprovedTemplates: false });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        }); };
        _this.fetchDataFromApi = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('fetchDataFromApi');
                        this.setState({ loading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4, fetch(environment_1.Environment.get('shoprenterApiRootUrl') + "/dashboard/shops/" + this.getCurrentShop()._id)];
                    case 2:
                        response = _a.sent();
                        return [4, response.json()];
                    case 3:
                        data = _a.sent();
                        this.setState({ apiData: data, loading: false });
                        return [3, 5];
                    case 4:
                        error_2 = _a.sent();
                        console.error('Error fetching data: ', error_2);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_APPROVED_EMAIL_TEMPLATES_COUNT);
                        this.setState({ loading: false });
                        return [3, 5];
                    case 5: return [2];
                }
            });
        }); };
        _this.handleTabChange = function (_, payload) {
            console.log('handleTabChange');
            _this.setState({ tab: payload.activeIndex });
            _this.fetchDataFromApi();
        };
        _this.refetchDashboardDataForShop = function () {
            console.log('refetchDashboardDataForShop 137');
            _this.fetchDashboardDataForShop();
        };
        _this.isShopProfileFilled = function () {
            if (_this.state.shop) {
                var logo = _this.state.shop.logo;
                return logo && (logo === null || logo === void 0 ? void 0 : logo.length) > 10;
            }
            return false;
        };
        _this.state = {
            isLoading: false,
            dashboardData: null,
            shop: null,
            loadingApprovedTemplates: true,
            approvedTemplatesCount: null
        };
        return _this;
    }
    ShoprenterScreen.prototype.getCurrentShop = function () {
        var currentShop = session_storage_1.SessionStorage.getItem('SelectedShop');
        return currentShop;
    };
    ShoprenterScreen.prototype.getTabIndexFromUrl = function () {
        var _a;
        var fallbackTabIndex = shoprenterTabs.TODO_LIST_TAB_INDEX_WEBSHOP_DATA.index;
        var tabIndex = fallbackTabIndex;
        if ((_a = this.props.route.query) === null || _a === void 0 ? void 0 : _a.tab) {
            var queryTab = this.props.route.query.tab;
            for (var _i = 0, _b = Object.values(shoprenterTabs); _i < _b.length; _i++) {
                var tabData = _b[_i];
                if (tabData.tabName === queryTab || tabData.index.toString() === queryTab) {
                    tabIndex = tabData.index;
                    break;
                }
            }
        }
        return tabIndex;
    };
    ShoprenterScreen.prototype.fetchDashboardDataForShop = function () {
        return __awaiter(this, void 0, void 0, function () {
            var currentShop, SHOPRENTER_API_ROOT_URL, url, response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('fetchDashboardDataForShop, preparing API request');
                        currentShop = this.getCurrentShop();
                        this.setState({ isLoading: true });
                        SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
                        url = new URL(SHOPRENTER_API_ROOT_URL + "/dashboard/shops/" + (currentShop === null || currentShop === void 0 ? void 0 : currentShop._id));
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, this.shop.get(url.toString())];
                    case 2:
                        response = _a.sent();
                        console.log('fetchDashboardDataForShop API response', response);
                        this.setState({ isLoading: false, dashboardData: response });
                        session_storage_1.SessionStorage.setItem('ShoprenterDashboardData', response);
                        return [3, 5];
                    case 3:
                        error_3 = _a.sent();
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_APPROVED_EMAIL_TEMPLATES_COUNT);
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    ShoprenterScreen.prototype.fetchShop = function () {
        return __awaiter(this, void 0, void 0, function () {
            var currentShop, shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentShop = this.getCurrentShop();
                        return [4, this.shop.get("/a/shop/" + (currentShop === null || currentShop === void 0 ? void 0 : currentShop._id), {})];
                    case 1:
                        shop = _a.sent();
                        console.log('fetchShop url', shop === null || shop === void 0 ? void 0 : shop.url);
                        if (shop) {
                            console.log('Overwriting shop in session storage');
                            session_storage_1.SessionStorage.setItem(session_storage_1.SESSION_STORAGE_KEYS.SESSION_STORAGE_SELECTED_SHOP_KEY, shop);
                        }
                        this.setState({ shop: shop });
                        return [2];
                }
            });
        });
    };
    ShoprenterScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.setState({ tab: this.getTabIndexFromUrl() });
        this.setState({ shop: this.getCurrentShop() });
        this.fetchApprovedTemplatesCount();
        this.fetchDashboardDataForShop();
        this.fetchShop();
        var startedIntervalId = setInterval(function () {
            console.log('Periodic fetch', new Date().toISOString());
            _this.fetchDashboardDataForShop();
        }, 30000);
        this.setState({
            intervalId: startedIntervalId
        });
    };
    ShoprenterScreen.prototype.componentWillUnmount = function () {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    };
    ShoprenterScreen.prototype.handleApprovedTemplatesChange = function (count) {
        this.setState({ approvedTemplates: count });
    };
    ShoprenterScreen.prototype.getTabPanes = function () {
        var _this = this;
        var _a, _b;
        var currentDashboardData = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.dashboardData) === null || _b === void 0 ? void 0 : _b.dashboard;
        var isWebshopDataSaved = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) || false;
        var isShopProfileFinished = this.isShopProfileFilled();
        var allStepsDone = ((currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) === true && (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.productSynced) === true && (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.listCleaned) === true && (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.dnsSetup) === true && isShopProfileFinished === true) || false;
        return Object.values(shoprenterTabs).map(function (tab) {
            var isCheckedTab = false;
            var isTabEnabled = false;
            var isExclamationMark = false;
            var isAutomaticEmailsTab = false;
            switch (tab.tabName) {
                case shoprenterTabs.TODO_LIST_TAB_INDEX_WEBSHOP_DATA.tabName:
                    isCheckedTab = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) || false;
                    isTabEnabled = true;
                    break;
                case shoprenterTabs.TODO_LIST_TAB_INDEX_SYNC.tabName:
                    isExclamationMark = ((currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.productSyncImminent) === true && (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.productSynced) === false) || false;
                    isCheckedTab = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.productSynced) || false;
                    isTabEnabled = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) === true;
                    break;
                case shoprenterTabs.TODO_LIST_TAB_INDEX_LIST_CLEANING.tabName:
                    isExclamationMark = ((currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.listCleanApproved) === true && (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.listCleaned) === false) || false;
                    isCheckedTab = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.listCleaned) || false;
                    isTabEnabled = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) === true;
                    break;
                case shoprenterTabs.TODO_LIST_TAB_INDEX_DNS.tabName:
                    isCheckedTab = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.dnsSetup) || false;
                    isTabEnabled = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) === true;
                    break;
                case shoprenterTabs.TODO_LIST_TAB_INDEX_SHOP_PROFILE.tabName:
                    isCheckedTab = isShopProfileFinished;
                    isTabEnabled = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) === true;
                    break;
                case shoprenterTabs.TODO_LIST_TAB_INDEX_AUTOMATIC_NEWSLETTERS.tabName:
                    isCheckedTab = (_this.state.approvedTemplatesCount && _this.state.approvedTemplatesCount >= 10) || false;
                    isTabEnabled = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) === true;
                    isAutomaticEmailsTab = true;
                    break;
                case shoprenterTabs.TODO_LIST_TAB_INDEX_SUMMARY.tabName:
                    isCheckedTab = allStepsDone;
                    isTabEnabled = (currentDashboardData === null || currentDashboardData === void 0 ? void 0 : currentDashboardData.webShopDataSaved) === true;
                    isExclamationMark = (isTabEnabled === true) && (allStepsDone === false);
                    break;
            }
            var icon = 'red times circle outline';
            if (isAutomaticEmailsTab) {
                if (_this.state.loadingApprovedTemplates) {
                    icon = 'yellow clock outline';
                }
            }
            if (isExclamationMark) {
                icon = 'yellow exclamation circle';
            }
            if (isCheckedTab) {
                icon = 'green check circle outline';
            }
            return ({
                menuItem: {
                    key: tab.index,
                    icon: isExclamationMark ? 'yellow exclamation circle' : isCheckedTab ? 'green check circle' : 'red exclamation circle',
                    content: tab.tabName,
                    disabled: !isTabEnabled
                },
                render: function () {
                    switch (tab.tabName) {
                        case shoprenterTabs.TODO_LIST_TAB_INDEX_WEBSHOP_DATA.tabName:
                            return React.createElement(todo_list_webshop_data_tab_content_component_1.TodoListWebshopDataTabContentComponent, __assign({}, _this.props, { isWebshopDataSaved: isWebshopDataSaved, refetch: function () {
                                    _this.refetchDashboardDataForShop();
                                    _this.fetchShop();
                                } }));
                        case shoprenterTabs.TODO_LIST_TAB_INDEX_SYNC.tabName:
                            return React.createElement(todo_list_sync_tab_content_component_1.TodoListSyncTabContent, __assign({}, _this.props, { refetch: function () { return _this.refetchDashboardDataForShop(); }, dashboardData: _this.state.dashboardData }));
                        case shoprenterTabs.TODO_LIST_TAB_INDEX_LIST_CLEANING.tabName:
                            return React.createElement(todo_list_list_cleaning_tab_content_component_1.TodoListListCleaningTabContent, __assign({}, _this.props, { refetch: function () { return _this.refetchDashboardDataForShop(); }, dashboardData: _this.state.dashboardData }));
                        case shoprenterTabs.TODO_LIST_TAB_INDEX_DNS.tabName:
                            return React.createElement(todo_list_dns_tab_content_component_1.TodoListDnsTabContent, __assign({}, _this.props, { refetch: function () { return _this.refetchDashboardDataForShop(); } }));
                        case shoprenterTabs.TODO_LIST_TAB_INDEX_SHOP_PROFILE.tabName:
                            return React.createElement(todo_list_shop_profile_tab_content_component_1.TodoListShopProfileTabContent, __assign({}, _this.props, { refetch: function () { return _this.fetchShop(); } }));
                        case shoprenterTabs.TODO_LIST_TAB_INDEX_AUTOMATIC_NEWSLETTERS.tabName:
                            return (React.createElement(todo_list_automatic_newsletters_tab_content_component_1.TodoListAutomaticNewslettersTabContent, __assign({}, _this.props, { refetch: function () {
                                    _this.fetchApprovedTemplatesCount();
                                    _this.refetchDashboardDataForShop();
                                }, dashboardData: _this.state.dashboardData, handleApprovedTemplatesChange: function (count) { return _this.handleApprovedTemplatesChange(count); }, approvedTemplatesCount: _this.state.approvedTemplatesCount })));
                        case shoprenterTabs.TODO_LIST_TAB_INDEX_SUMMARY.tabName:
                            return React.createElement(shoprenter_todo_list_summary_content_component_1.TodoListSummaryTabContent, __assign({}, _this.props, { refetch: function () { return _this.fetchShop(); }, dashboardData: _this.state.dashboardData, profileFinished: _this.isShopProfileFilled(), approvedTemplatesCount: _this.state.approvedTemplates }));
                        default:
                            return React.createElement(todo_list_webshop_data_tab_content_component_1.TodoListWebshopDataTabContentComponent, __assign({}, _this.props, { isWebshopDataSaved: isWebshopDataSaved, refetch: function () { return _this.refetchDashboardDataForShop(); } }));
                    }
                }
            });
        });
    };
    ShoprenterScreen.prototype.render = function () {
        var shop = this.getCurrentShop();
        return (React.createElement(semantic_ui_react_1.Container, { fluid: true, style: { color: "#051D40" } },
            React.createElement("div", { className: "LeadListComponent ListComponent", style: { fontSize: 16 } },
                React.createElement(semantic_ui_react_1.Grid, null,
                    React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                        React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                            React.createElement(semantic_ui_react_1.Header, { as: 'h2', style: {
                                    color: "#051D40"
                                } }, "M.I. - Shoprenter telep\u00EDt\u0151"),
                            shop && (React.createElement("div", { style: { marginLeft: '16px' } },
                                React.createElement("p", null,
                                    "Webshop: ",
                                    React.createElement("b", null,
                                        shop.title,
                                        " (",
                                        shop.url,
                                        ")")))))),
                    React.createElement(semantic_ui_react_1.Grid.Row, null,
                        React.createElement(semantic_ui_react_1.Grid.Column, { style: { marginLeft: '16px' } },
                            React.createElement(semantic_ui_react_1.Tab, { panes: this.getTabPanes(), activeIndex: this.state.tab || shoprenterTabs.TODO_LIST_TAB_INDEX_WEBSHOP_DATA.index, onTabChange: this.handleTabChange, style: { color: "#051D40" } })))))));
    };
    return ShoprenterScreen;
}(abstract_component_1.AbstractComponent));
exports.ShoprenterScreen = ShoprenterScreen;
