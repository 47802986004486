module.exports = {
    'general.password.strength.label.weak': 'Gyenge',
    'general.password.strength.label.medium': 'Közepes',
    'general.password.strength.label.strong': 'Erős',
    'industry-options.electronic': 'Elektronika',
    'industry-options.it': 'Informatika',
    'industry-options.fashion': 'Divat',
    'general.button.save.title': 'Mentés',
    'general.button.cancel.title': 'Mégse',
    'general.button.logout.title': 'Kijelentkezés',
    'general.list.empty': 'Üres lista'
};
