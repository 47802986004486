"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminLayout = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var admin_menu_component_1 = require("../../components/admin-menu/admin-menu.component");
require("./admin.layout.scss");
var AdminLayout = (function (_super) {
    __extends(AdminLayout, _super);
    function AdminLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdminLayout.prototype.render = function () {
        if (this.props.routeLoading) {
            return this.renderLoader();
        }
        return React.createElement("div", { className: 'DefaultLayout display-flex flex-row' },
            React.createElement("div", { className: 'MenuContainer' },
                React.createElement(admin_menu_component_1.AdminMenuComponent, __assign({}, this.props))),
            React.createElement(semantic_ui_react_1.Container, { className: "flex-fill MainContainer", fluid: true },
                this.renderLoader(),
                !this.state.routeLoading && React.createElement("div", null, this.props.children)));
    };
    AdminLayout.prototype.renderLoader = function () {
        if (!this.props.routeLoading) {
            return null;
        }
        return React.createElement("div", { className: 'Loader' },
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }));
    };
    return AdminLayout;
}(responsive_component_1.ResponsiveComponent));
exports.AdminLayout = AdminLayout;
