"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shopSettingsMenu = void 0;
exports.shopSettingsMenu = [
    {
        name: 'general',
        identifier: 'general',
        icon: 'warehouse',
    },
    {
        name: 'miniCrmSettings',
        identifier: 'miniCrmSettings',
        icon: 'file alternate',
    },
    {
        name: 'emailSettings',
        identifier: 'emailSettings',
        icon: 'envelope',
    },
    {
        name: 'integration',
        identifier: 'integration',
        icon: 'database',
    },
    {
        name: 'relevant-ranking',
        identifier: 'relevant-ranking',
        icon: 'database',
    },
    {
        name: 'import-subscribers',
        identifier: 'import-subscribers',
        icon: 'database',
    },
    {
        name: 'export-subscribers',
        identifier: 'export-subscribers',
        icon: 'download',
    },
    {
        name: 'bounced-emails',
        identifier: 'bounced-emails',
        icon: 'database',
    },
    {
        name: 'orders-with-different-emails',
        identifier: 'orders-with-different-emails',
        icon: 'shop',
    },
    {
        name: 'creditCardPayments',
        identifier: 'creditCardPayments',
        icon: 'credit card outline',
    }
];
