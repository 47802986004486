"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportEmailMenuItemComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var React = require("react");
require("./menu.component.scss");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_react_1 = require("semantic-ui-react");
var SupportEmailMenuItemComponent = (function (_super) {
    __extends(SupportEmailMenuItemComponent, _super);
    function SupportEmailMenuItemComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SupportEmailMenuItemComponent.prototype.render = function () {
        return React.createElement("div", { style: {
                padding: '4px 8px',
                marginTop: '8px',
                marginBottom: '40px',
                color: 'white',
                fontWeight: 700,
                lineHeight: '24px',
            } },
            React.createElement("a", { href: "mailto:help@miclub.hu", style: { color: 'white' } },
                React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', loading: false, name: 'question circle' }),
                trans_1.trans('menu.item.support-email-prefix'),
                " help@miclub.hu"));
    };
    return SupportEmailMenuItemComponent;
}(responsive_component_1.ResponsiveComponent));
exports.SupportEmailMenuItemComponent = SupportEmailMenuItemComponent;
