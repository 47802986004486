"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HappeningNowComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var moment = require("moment");
var React = require("react");
var rxjs_1 = require("rxjs");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var animated_list_component_1 = require("../../common/components/animated-list/animated-list.component");
var list_loader_1 = require("../../common/components/loader/list-loader");
var event_types_1 = require("../../common/libs/event-types");
var state_repository_1 = require("../../common/libs/state-repository");
require("./dashboard.component.scss");
var HappeningNowComponent = (function (_super) {
    __extends(HappeningNowComponent, _super);
    function HappeningNowComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.eventTypes = event_types_1.eventTypes;
        _this.happeningNow = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'happeningNow');
        _this.state = {
            shop: _this.getCurrentShop()
        };
        return _this;
    }
    HappeningNowComponent.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.$subscriptions.push(rxjs_1.timer(0, 1000 * 5).subscribe(function () { return _this.fetchCurrentOnline(); }));
    };
    HappeningNowComponent.prototype.fetchCurrentOnline = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.happeningNow.get("/s/" + ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/event/happening-now", {
                            params: {
                                'sort[createdAt]': -1,
                                withData: 1
                            }
                        })];
                    case 1:
                        _c.sent();
                        return [2];
                }
            });
        });
    };
    HappeningNowComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var hasMailgunProblem = ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.mailgunStatus) === false || false;
        var happenings = !!((_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.happeningNow) === null || _d === void 0 ? void 0 : _d.response) === null || _e === void 0 ? void 0 : _e.items)
            ? (_h = (_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.happeningNow) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.items.slice(0, 20) : [];
        return React.createElement("div", { className: 'HappeningNow' },
            React.createElement("div", { style: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: "8px",
                } },
                React.createElement("div", null,
                    React.createElement("h5", { style: { marginBottom: 0 } }, "Most t\u00F6rt\u00E9nik")),
                hasMailgunProblem && (React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Message, { color: 'red', style: {
                            padding: "4px 8px",
                            marginTop: 0,
                            cursor: 'pointer'
                        }, onClick: function (_) { return _this.handleMailgunMessageClick(); } }, "FIGYELEM! Az e-mailek k\u00FCld\u00E9se a DNS be\u00E1ll\u00EDt\u00E1sok hib\u00E1ja miatt le\u00E1llt! K\u00E9rj\u00FCk, kattints ide!")))),
            !(happenings === null || happenings === void 0 ? void 0 : happenings.length)
                ? React.createElement(list_loader_1.ListLoader, { content: 'Események betöltése' })
                : React.createElement(animated_list_component_1.AnimatedListComponent, null, happenings.map(function (item) { return _this.renderHappeningInfo(item); })),
            React.createElement("div", { className: 'fade-cover' }));
    };
    HappeningNowComponent.prototype.renderHappeningInfo = function (item) {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(semantic_ui_react_1.Message, { key: item._id, className: "HappeningInfo display-flex flex-row " + item.type, onClick: function (e) { return _this.handleClick(e, item); } },
            React.createElement("div", { className: 'icon-wrapper' },
                React.createElement(semantic_ui_react_1.Icon, { name: (_a = this.eventTypes[item.type]) === null || _a === void 0 ? void 0 : _a.icon })),
            React.createElement("div", { className: 'content-wrapper flex-fill' },
                React.createElement("section", { className: 'HappeningHeader display-flex' },
                    React.createElement("div", null,
                        React.createElement("span", null, trans_1.trans((_b = this.eventTypes[item.type]) === null || _b === void 0 ? void 0 : _b.title))),
                    React.createElement("div", null,
                        React.createElement("span", null, moment(item.updatedAt).locale('hu').format('LLL')))),
                React.createElement("section", null,
                    React.createElement("p", null, (_c = this.eventTypes[item.type]) === null || _c === void 0 ? void 0 : _c.render(item)))));
    };
    HappeningNowComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    HappeningNowComponent.prototype.handleClick = function (e, event) {
        var _a, _b;
        if ((_a = event === null || event === void 0 ? void 0 : event.processedEvent) === null || _a === void 0 ? void 0 : _a._id) {
            return router_provider_1.RouterProvider.goTo("/leads/pe/" + ((_b = event === null || event === void 0 ? void 0 : event.processedEvent) === null || _b === void 0 ? void 0 : _b._id));
        }
    };
    HappeningNowComponent.prototype.handleMailgunMessageClick = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var shopId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        shopId = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id;
                        return [4, router_provider_1.RouterProvider.updateQuery({ shop: shopId })];
                    case 1:
                        _c.sent();
                        return [2, router_provider_1.RouterProvider.goTo("/settings?tab=emailSettings&shop=" + shopId)];
                }
            });
        });
    };
    return HappeningNowComponent;
}(responsive_component_1.ResponsiveComponent));
exports.HappeningNowComponent = HappeningNowComponent;
