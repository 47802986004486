"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessReportExportScreen = void 0;
var environment_1 = require("@codebuild/uikit/libs/environment");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var axios_1 = require("axios");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var DatePicker = require("react-datepicker");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var session_storage_1 = require("../../../../libs/session.storage");
require("../libs/common.scss");
var BusinessReportExportScreen = (function (_super) {
    __extends(BusinessReportExportScreen, _super);
    function BusinessReportExportScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    name: 'from',
                    label: '',
                    value: moment().startOf('month').toDate(),
                }),
                to: new field_1.Field({
                    name: 'to',
                    label: '',
                    value: moment().endOf('month').toDate(),
                }),
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                from: _this.form.fields.from.getValue(),
                to: _this.form.fields.to.getValue(),
            }
        });
        _this.state = {
            isLoading: false
        };
        return _this;
    }
    BusinessReportExportScreen.prototype.handleClickDownloadBusinessReport = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apiBaseUrl, fromQueryParameter, toQueryParameter, shop, response, results, rows, csvContent, encodedUri, link, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        apiBaseUrl = environment_1.Environment.get('api');
                        fromQueryParameter = moment(this.form.fields.from.value).format('YYYY-MM-DD');
                        toQueryParameter = moment(this.form.fields.to.value).format('YYYY-MM-DD');
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, axios_1.default.get(apiBaseUrl + "/s/" + shop._id + "/data/business-report?from=" + fromQueryParameter + "&to=" + toQueryParameter, {
                                headers: {
                                    Authorization: get_access_token_1.getAccessToken()
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        results = (response === null || response === void 0 ? void 0 : response.data) || [];
                        rows = __spreadArrays([
                            [
                                'Integráció',
                                'Feliratkozók (db)',
                                'Új feliratkozók (db)',
                                'Leiratkozók (db)',
                                'Kiküldött automata levelek száma (db)',
                                'Kiküldött egyedi levelek száma (db)',
                                'Nem köthető bevétel (ft)',
                                'Nem köthető rendelések száma (db)',
                                'Feltehetően köthető bevétel (ft)',
                                'Feltehetően rendelések száma (db)',
                                'Erősen köthető bevétel (ft)',
                                'Erősen köthető rendelések száma (db)',
                                'Bizonyíthatóan köthető bevétel (ft)',
                                'Bizonyíthatóan köthető rendelések száma (db)',
                            ]
                        ], results.map(function (row) { return ([
                            row.shop,
                            row.subscribers,
                            row.newSubscribers,
                            row.lostSubscribers,
                            row.sentAutomatic,
                            row.sentCustom,
                            Math.round(row.notRelatedIncome),
                            row.notRelatedOrders,
                            Math.round(row.probablyIncome),
                            row.probablyOrders,
                            Math.round(row.stronglyIncome),
                            row.stronglyOrders,
                            Math.round(row.demonstrablyIncome),
                            row.demonstrablyOrders,
                        ]); }));
                        csvContent = "data:text/csv;charset=utf-8, " + rows.map(function (e) { return e.join(';'); }).join('\n');
                        encodedUri = encodeURI(csvContent);
                        link = document.createElement('a');
                        link.setAttribute('href', encodedUri);
                        link.setAttribute('download', "uzleti-riport_" + moment(this.form.fields.from.getValue()).format('YYYY-MM-DD') + "_" + moment(this.form.fields.to.getValue()).format('YYYY-MM-DD'));
                        document.body.appendChild(link);
                        link.click();
                        return [3, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('handleClickDownloadBusinessReport catch', error_1);
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    BusinessReportExportScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "\u00DCzleti riport export"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { floated: 'left', className: 'date-picker-wrapper justify-content-end' },
                                React.createElement("p", { className: 'header-filter-label' }, "D\u00E1tum sz\u0171r\u00E9s"),
                                React.createElement(semantic_ui_react_1.Grid.Row, null,
                                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                                        React.createElement(semantic_ui_react_1.Form, { className: 'display-flex' },
                                            React.createElement("div", { className: "date-wrapper" },
                                                React.createElement("div", { className: 'position-relative display-flex' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })),
                                                React.createElement("div", { className: 'date-picker-divider' }),
                                                React.createElement("div", { className: 'position-relative display-flex mr-4' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })))))))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { floated: 'left', className: 'date-picker-wrapper justify-content-end' },
                                React.createElement(semantic_ui_react_1.Button, { loading: this.state.isLoading, disabled: this.state.isLoading, size: 'small', color: "blue", basic: true, onClick: function () { return _this.handleClickDownloadBusinessReport(); }, content: "\u00DCzleti riport CSV let\u00F6lt\u00E9s" })))))));
    };
    return BusinessReportExportScreen;
}(abstract_component_1.AbstractComponent));
exports.BusinessReportExportScreen = BusinessReportExportScreen;
