"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoutComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var React = require("react");
var handle_toasts_1 = require("../../common/libs/handle.toasts");
var LogoutComponent = (function (_super) {
    __extends(LogoutComponent, _super);
    function LogoutComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LogoutComponent.prototype.render = function () {
        if (!this.props.content) {
            return React.createElement("div", null);
        }
        return React.createElement("div", null, this.props.content);
    };
    LogoutComponent.logout = function () {
        local_store_1.LocalStore.setItem('User', null);
        local_store_1.LocalStore.setItem('accessToken', null);
        router_provider_1.RouterProvider.goTo('/login');
        handle_toasts_1.HandleToasts.success('Sikeres kijelentkezés!');
    };
    return LogoutComponent;
}(abstract_component_1.AbstractComponent));
exports.LogoutComponent = LogoutComponent;
