"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.headerBlock = void 0;
var body = function () { return ({
    "type": "fullwidth", "children": [{
            "type": "box",
            "children": [{
                    "type": "multicolumn",
                    "children": [{
                            "type": "column", "children": [{
                                    "type": "dynamic-image",
                                    "attrs": { "mini": false, "sizeType": "FIXED", "link": "", "lockDimensions": { "width": 300, "height": 300 }, "altText": "", "fullWidthOnMobile": false, "align": "center" },
                                    "style": {
                                        "max-height": "180px",
                                        "max-width": "180px",
                                        "src": "{{ Context_Shop_Logo }}",
                                        "width": "180px",
                                        "background-color": "",
                                        "background-repeat": "no-repeat",
                                        "background-position": "center center",
                                        "background-image": "url()",
                                        "border-radius": "0px",
                                        "border-left-color": "#000000",
                                        "border-left-style": "solid",
                                        "border-left-width": "0px",
                                        "border-bottom-color": "#000000",
                                        "border-bottom-style": "solid",
                                        "border-bottom-width": "0px",
                                        "border-right-color": "#000000",
                                        "border-right-style": "solid",
                                        "border-right-width": "0px",
                                        "border-top-color": "#000000",
                                        "border-top-style": "solid",
                                        "border-top-width": "0px",
                                        "margin-left": "0px",
                                        "margin-bottom": "0px",
                                        "margin-right": "0px",
                                        "margin-top": "0px",
                                        "padding-left": "0px",
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "8px"
                                    }
                                }], "attrs": { "hideOnMobile": false, "lock": false }, "style": { "width": "292px", "background-color": "", "background-repeat": "no-repeat", "background-position": "center center", "background-image": "url()" }
                        }, {
                            "type": "column",
                            "children": [{
                                    "type": "text",
                                    "attrs": { "text": "<h2 style=\"text-align: center;\"><strong>{{ Variable_Header_Title }}</strong></h2>\n" },
                                    "style": {
                                        "background-color": "",
                                        "background-repeat": "no-repeat",
                                        "background-position": "center center",
                                        "background-image": "url()",
                                        "padding-left": "8px",
                                        "padding-bottom": "16px",
                                        "padding-right": "8px",
                                        "padding-top": "16px",
                                        "line-height": "24px",
                                        "letter-spacing": "normal",
                                        "color": "{{ Context_Shop_HeaderTextColor }}",
                                        "font-size": "16px",
                                        "font-family": "Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif",
                                        "a": { "text-decoration": "underline", "color": "{{ Context_Shop_HeaderTextColor }}" },
                                        "h3": { "line-height": "24px", "color": "{{ Context_Shop_HeaderTextColor }}", "font-size": "16px", "font-family": "Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif", "letter-spacing": "normal" },
                                        "h2": { "line-height": "32px", "color": "{{ Context_Shop_HeaderTextColor }}", "font-size": "24px", "font-family": "Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif", "letter-spacing": "normal" },
                                        "h1": { "line-height": "40px", "color": "{{ Context_Shop_HeaderTextColor }}", "font-size": "33px", "font-family": "Georgia", "letter-spacing": "normal" }
                                    }
                                }],
                            "attrs": { "hideOnMobile": false, "lock": false },
                            "style": { "width": "292px", "background-color": "", "background-repeat": "no-repeat", "background-position": "center center", "background-image": "url()" }
                        }],
                    "attrs": {
                        "hideOnMobile": false,
                        "hideOnDesktop": false,
                        "stacking": "left-on-top",
                        "spacerBefore": "0px",
                        "spacerBetween": "16px",
                        "spacerAfter": "0px",
                        "spacerBeforeMobile": "0px",
                        "spacerBetweenMobile": "0px",
                        "spacerAfterMobile": "0px"
                    },
                    "style": { "background-color": "", "background-repeat": "no-repeat", "background-position": "center center", "background-image": "url()" }
                }],
            "attrs": { "hideOnMobile": false, "hideOnDesktop": false },
            "style": {
                "background-color": "{{ Context_Shop_HeaderBackgroundColor }}",
                "background-repeat": "no-repeat",
                "background-position": "center center",
                "background-image": "url()",
                "border-radius": "0px",
                "border-left-color": "#000000",
                "border-left-style": "solid",
                "border-left-width": "0px",
                "border-bottom-color": "#000000",
                "border-bottom-style": "solid",
                "border-bottom-width": "0px",
                "border-right-color": "#000000",
                "border-right-style": "solid",
                "border-right-width": "0px",
                "border-top-color": "#000000",
                "border-top-style": "solid",
                "border-top-width": "0px",
                "margin-left": "0px",
                "margin-bottom": "0px",
                "margin-right": "0px",
                "margin-top": "0px",
                "padding-left": "0px",
                "padding-bottom": "8px",
                "padding-right": "0px",
                "padding-top": "8px"
            }
        }], "placeholder": [], "attrs": { "hideOnMobile": false, "hideOnDesktop": false, "lock": false, "marker": "" }, "style": {
        "background-color": "",
        "background-repeat": "no-repeat",
        "background-position": "center center",
        "background-image": "url()",
        "content-background-color": "",
        "content-background-repeat": "no-repeat",
        "content-background-position": "center center",
        "content-background-image": "url()",
        "content-border-radius": "0px",
        "content-border-left-color": "#000000",
        "content-border-left-style": "solid",
        "content-border-left-width": "0px",
        "content-border-bottom-color": "#000000",
        "content-border-bottom-style": "solid",
        "content-border-bottom-width": "0px",
        "content-border-right-color": "#000000",
        "content-border-right-style": "solid",
        "content-border-right-width": "0px",
        "content-border-top-color": "#000000",
        "content-border-top-style": "solid",
        "content-border-top-width": "0px",
        "content-margin-left": "0px",
        "content-margin-bottom": "0px",
        "content-margin-right": "0px",
        "content-margin-top": "32px",
        "content-padding-left": "0px",
        "content-padding-bottom": "0px",
        "content-padding-right": "0px",
        "content-padding-top": "0px",
        "content-width": null,
        "body-width": 600
    }, "customData": {}
}); };
var placeholder = function () { return [
    {
        "attrs": {
            "hideOnMobile": false,
            "hideOnDesktop": false
        },
        "style": {
            "background-color": "",
            "background-repeat": "no-repeat",
            "background-position": "center center",
            "background-image": "url()",
            "border-radius": "0px",
            "border-left-color": "#000000",
            "border-left-style": "solid",
            "border-left-width": "0px",
            "border-bottom-color": "#000000",
            "border-bottom-style": "solid",
            "border-bottom-width": "0px",
            "border-right-color": "#000000",
            "border-right-style": "solid",
            "border-right-width": "0px",
            "border-top-color": "#000000",
            "border-top-style": "solid",
            "border-top-width": "0px",
            "margin-left": "0px",
            "margin-bottom": "0px",
            "margin-right": "0px",
            "margin-top": "0px",
            "padding-left": "0px",
            "padding-bottom": "0px",
            "padding-right": "0px",
            "padding-top": "0px"
        },
        "type": "box",
        "children": [
            {
                "attrs": {
                    "mini": false,
                    "sizeType": "FIXED",
                    "link": "",
                    "lockDimensions": {
                        "width": 300,
                        "height": 300
                    },
                    "altText": "",
                    "fullWidthOnMobile": false,
                    "align": "center"
                },
                "style": {
                    "max-height": "448px",
                    "max-width": "1200px",
                    "src": "https://v3.miclub.hu/assets/images/Header@2x.png",
                    "width": "600px",
                    "background-color": "",
                    "background-repeat": "no-repeat",
                    "background-position": "center center",
                    "background-image": "url()",
                    "border-radius": "0px",
                    "border-left-color": "#000000",
                    "border-left-style": "solid",
                    "border-left-width": "0px",
                    "border-bottom-color": "#000000",
                    "border-bottom-style": "solid",
                    "border-bottom-width": "0px",
                    "border-right-color": "#000000",
                    "border-right-style": "solid",
                    "border-right-width": "0px",
                    "border-top-color": "#000000",
                    "border-top-style": "solid",
                    "border-top-width": "0px",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                    "margin-right": "0px",
                    "margin-top": "0px",
                    "padding-left": "0px",
                    "padding-bottom": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px"
                },
                "type": "image"
            }
        ]
    }
]; };
var headerBlock = function () { return ({
    title: 'Header',
    body: body(),
    placeholder: placeholder()
}); };
exports.headerBlock = headerBlock;
