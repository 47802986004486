"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var authorized_middleware_1 = require("../modules/common/middlewares/authorized.middleware");
var shop_registration_layout_1 = require("../modules/shop-registration/layouts/shop-registration.layout");
var shop_registration_screen_1 = require("../modules/shop-registration/screens/shop-registration.screen");
var commonMiddlewares = [
    new authorized_middleware_1.AuthorizedMiddleware({
        redirectTo: '/login'
    }),
];
module.exports = [
    {
        middlewares: commonMiddlewares,
        path: '/shop-registration',
        layout: shop_registration_layout_1.ShopRegistrationLayout,
        component: shop_registration_screen_1.ShopRegistrationScreen.withProps({})
    },
];
