"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationTemplateVariablesComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var observable_control_1 = require("@codebuild/uikit/libs/observable-control");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var React = require("react");
require("react-quill/dist/quill.snow.css");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var quill_form_control_type_1 = require("../../../libs/form-control-types/quill.form-control-type");
var quill_with_image_1 = require("../../../libs/quill-with-image");
var session_storage_1 = require("../../../libs/session.storage");
var content_variables_1 = require("../../admin/components/template/template-content/content-variables");
var state_repository_1 = require("../../common/libs/state-repository");
var contains_protocol_validator_1 = require("./contains-protocol-validator");
var preview_1 = require("./preview");
var sensitive_data_1 = require("../../common/libs/sensitive-data");
var IntegrationTemplateVariablesComponent = (function (_super) {
    __extends(IntegrationTemplateVariablesComponent, _super);
    function IntegrationTemplateVariablesComponent() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.subject = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'subject');
        _this.variables = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'variables');
        _this.preview = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'preview');
        _this.approve = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'approve');
        _this.disapprove = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'disapprove');
        _this.sendTest = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'sendTest');
        _this.integrationTemplate = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'integrationTemplate');
        _this.customerSearch$ = new rxjs_1.Subject();
        _this.subjectForm = new form_builder_1.FormBuilder({
            fields: {
                subject: new field_1.Field({
                    name: 'subject',
                    value: _this.props.subject,
                    label: 'Tárgy mező',
                    placeholder: 'Ez a levélnek a tárgya',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező')
                    ]
                })
            }
        });
        _this.customerForm = new form_builder_1.FormBuilder({
            fields: {
                email: new field_1.Field({
                    name: 'email',
                    value: null,
                    placeholder: 'Válassz az érdeklődőid közül',
                    label: 'Érdeklődők',
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: "/s/" + ((_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/customer",
                    optionsMap: function (response) {
                        var _a;
                        console.log('customerForm optionsMap: shoprenter check');
                        var currentShoprenterData = session_storage_1.SessionStorage.getItem(session_storage_1.SESSION_STORAGE_KEYS.SESSION_STORAGE_SHOPRENTER_DATA_KEY);
                        var mappedResults = ((response === null || response === void 0 ? void 0 : response.items) || []).map(function (item) {
                            var _a, _b;
                            return ({
                                text: sensitive_data_1.SensitiveData.customerEmail((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.email),
                                key: item.identifier,
                                value: (_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.email
                            });
                        });
                        if (!currentShoprenterData) {
                            console.log('No shoprenter data, return legacy data for preview');
                            return mappedResults;
                        }
                        console.log('Shoprenter data, return empty list for disabled preview');
                        return ((_a = currentShoprenterData === null || currentShoprenterData === void 0 ? void 0 : currentShoprenterData.dashboard) === null || _a === void 0 ? void 0 : _a.autoMailUpAndRunning) === true ? mappedResults : [];
                    },
                    loadOptionsAfterMount: false
                })
            }
        });
        _this.state = {
            ready: !!_this.props.integrationTemplateId || false,
            isOpen: false,
            isError: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {}
        });
        return _this;
    }
    IntegrationTemplateVariablesComponent.prototype.fetchBasics = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.integrationTemplate.get("/s/" + shop._id + "/template/" + this.props.integrationTemplateId)];
                    case 1:
                        _a.sent();
                        return [4, this.updateFormFields()];
                    case 2:
                        _a.sent();
                        return [4, this.forceUpdate()];
                    case 3:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.updatePreview = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.preview.get("/s/" + shop._id + "/template/" + this.props.integrationTemplateId + "/preview", { params: this.customerForm.toJSON() })];
                    case 1:
                        _a.sent();
                        return [4, this.setState({ ready: true })];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.sendTestEmail = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.sendTest.post("/s/" + shop._id + "/template/" + this.props.integrationTemplateId + "/send-test-email", {
                                data: {
                                    email: this.customerForm.fields.email.getValue()
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var reopen;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.updateVariables(); }), this.subjectForm.$submitSuccess.subscribe(function () { return _this.updateSubject(); }), this.customerForm.$value.subscribe(function () { return _this.updatePreview(); }), this.customerSearch$
                            .pipe(operators_1.debounceTime(250))
                            .subscribe(function (query) { return _this.customerForm.fields.email.loadOptions({ query: query }); }));
                        reopen = local_store_1.LocalStore.getItem('ReopenIntegrationTemplateEdit');
                        if (!(!!reopen && (this.props.templateId === reopen))) return [3, 2];
                        return [4, this.onOpen()];
                    case 1:
                        _a.sent();
                        local_store_1.LocalStore.deleteItem('ReopenIntegrationTemplateEdit');
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!prevProps.integrationTemplateId && this.props.integrationTemplateId) {
            this.onOpen();
        }
    };
    IntegrationTemplateVariablesComponent.prototype.updateFormFields = function () {
        return __awaiter(this, void 0, void 0, function () {
            var variables, values, _loop_1, this_1, _i, variables_1, variable;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        variables = this.getHbsVariables();
                        values = this.getVariableValues();
                        _loop_1 = function (variable) {
                            var value, fieldExists, isLink;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        value = values.find(function (item) { return item.key === variable; });
                                        fieldExists = this_1.form.fields[variable];
                                        if (!fieldExists) return [3, 2];
                                        return [4, fieldExists.setValue((value === null || value === void 0 ? void 0 : value.value) || '')];
                                    case 1:
                                        _a.sent();
                                        return [3, 3];
                                    case 2:
                                        isLink = variable.toLowerCase().split('_link').length > 1;
                                        this_1.form.field(variable, new field_1.Field({
                                            name: variable,
                                            value: (value === null || value === void 0 ? void 0 : value.value) || '',
                                            label: trans_1.trans("email-editor." + variable),
                                            placeholder: '',
                                            validators: __spreadArrays([
                                                new required_validator_1.RequiredValidator('Kötelező mező')
                                            ], (isLink ? [new contains_protocol_validator_1.ContainsProtocolValidator('Nem valid domain')] : ([])))
                                        }));
                                        _a.label = 3;
                                    case 3: return [2];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, variables_1 = variables;
                        _a.label = 1;
                    case 1:
                        if (!(_i < variables_1.length)) return [3, 4];
                        variable = variables_1[_i];
                        return [5, _loop_1(variable)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3, 1];
                    case 4: return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        var newsletterData = ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.integrationTemplate) === null || _b === void 0 ? void 0 : _b.response) || {};
        var isApproved = (newsletterData === null || newsletterData === void 0 ? void 0 : newsletterData.approvedAt) !== null;
        var disapproved = (newsletterData === null || newsletterData === void 0 ? void 0 : newsletterData.disapprovedAt) !== null;
        return React.createElement(semantic_ui_react_1.Modal, { trigger: this.renderTrigger(), size: "large", closeIcon: true, open: this.state.isOpen, onOpen: function () { return _this.onOpen(); }, onClose: function () { return _this.onClose(); }, className: "NewsletterPreview" },
            React.createElement(semantic_ui_react_1.Modal.Header, { as: 'h3' },
                isApproved && !disapproved && React.createElement(semantic_ui_react_1.Icon, { name: "check circle", key: 0 }),
                !isApproved && disapproved && React.createElement(semantic_ui_react_1.Icon, { name: "minus circle", key: 0 }),
                !disapproved && !isApproved && React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", key: 0 }), (_c = newsletterData === null || newsletterData === void 0 ? void 0 : newsletterData.template) === null || _c === void 0 ? void 0 :
                _c.name),
            this.state.isOpen && React.createElement(semantic_ui_react_1.Modal.Content, null, (_e = (_d = newsletterData === null || newsletterData === void 0 ? void 0 : newsletterData.template) === null || _d === void 0 ? void 0 : _d.description) === null || _e === void 0 ? void 0 :
                _e.split('\n').map(function (item, index) { return React.createElement("p", { key: index }, item); }),
                !!this.isReady() && React.createElement(semantic_ui_react_1.Grid, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { style: { minHeight: 700 } },
                        React.createElement(preview_1.Preview, { loading: (_f = this.state.preview) === null || _f === void 0 ? void 0 : _f.loading, error: (_h = (_g = this.state.preview) === null || _g === void 0 ? void 0 : _g.error) === null || _h === void 0 ? void 0 : _h.message, subject: (_k = (_j = this.state.preview) === null || _j === void 0 ? void 0 : _j.response) === null || _k === void 0 ? void 0 : _k.subject, html: (_m = (_l = this.state.preview) === null || _l === void 0 ? void 0 : _l.response) === null || _m === void 0 ? void 0 : _m.content })),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                            React.createElement(semantic_ui_react_1.Header, { content: "\u00C9rdekl\u0151d\u0151 v\u00E1laszt\u00E1sa el\u0151n\u00E9zethez", as: "h3" }),
                            React.createElement("p", null, "V\u00E1lassz egy felhaszn\u00E1l\u00F3t a fenti list\u00E1b\u00F3l akivel megn\u00E9zheted ennek a lev\u00E9lnek az el\u0151n\u00E9zet\u00E9t. Alist\u00E1ban olyan felhaszn\u00E1l\u00F3kat is tal\u00E1lsz akire ennek a lev\u00E9lnek a szab\u00E1lyai nem vonatkoznak."),
                            React.createElement(semantic_ui_react_1.Form, null,
                                React.createElement(form_control_1.FormControl, { field: this.customerForm.fields.email, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Dropdown, clearable: true, selection: true, search: true, fluid: true, disabled: false, onSearchChange: function (event) { return _this.customerSearch$.next(event.target.value); } })))),
                        !this.getHbsVariables().length && React.createElement(semantic_ui_react_1.Segment, { vertical: true }, "Ebben az emailben nem tal\u00E1lhat\u00F3ak v\u00E1ltoz\u00F3k!"),
                        React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                            React.createElement(semantic_ui_react_1.Header, { as: "h3", content: "Lev\u00E9l t\u00E1rgya" }),
                            React.createElement("p", null,
                                "\u00CDrd \u00E1t nyugodtan a javasolt t\u00E1rgy mez\u0151t hogyha neked nem tetszik. Eg\u00E9sz nyugodtan haszn\u00E1lj emojit a t\u00E1rgymez\u0151ben. Emojik list\u00E1j\u00E1t itt \u00E9red el: ",
                                React.createElement("a", { href: "http://unicode.org/emoji/charts/full-emoji-list.html", target: "_blank" }, "http://unicode.org/emoji/charts/full-emoji-list.html")),
                            React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.subjectForm.submit(); } },
                                React.createElement(form_control_1.FormControl, { field: this.subjectForm.fields.subject, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input })))),
                        !!this.getHbsVariables().length && React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                            React.createElement(semantic_ui_react_1.Header, { content: "Lev\u00E9l Tartalma", as: "h3" }),
                            React.createElement("p", null, "A lev\u00E9l tartalm\u00E1t nyugodtan \u00EDrd \u00E1t \u00E9s ha neked is megfelel\u0151 akkor mentsd el majd hagyd j\u00F3v\u00E1 a levelet."),
                            React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.form.submit(); } }, this.getHbsVariables().map(function (item, key) { return _this.renderVariableInputField(item, key); })),
                            this.state.isError ? React.createElement(semantic_ui_react_1.Message, { error: true, header: 'Hiányzó mezők!', content: 'Nézd át még egyszer. Fontos elemek hiányoznak.' }) : null)))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(observable_control_1.ObservableControl, { "$observable": this.customerForm.fields.email.$value, transform: function (value) { return ({ disabled: !value }); } },
                    React.createElement(semantic_ui_react_1.Button, { className: "send-button", type: "button", icon: "envelope", loading: (_o = this.state.sendTest) === null || _o === void 0 ? void 0 : _o.loading, content: "Email k\u00FCld\u00E9se", floated: "left", onClick: function () { return _this.sendTestEmail(); } })),
                React.createElement(semantic_ui_react_1.Button, { basic: true, className: "disapprove-button", icon: "minus circle", disabled: ((_p = this.state.approve) === null || _p === void 0 ? void 0 : _p.loading) || ((_q = this.state.disapprove) === null || _q === void 0 ? void 0 : _q.loading), loading: (_r = this.state.disapprove) === null || _r === void 0 ? void 0 : _r.loading, onClick: function () { return _this.handleDisapprove(); }, content: "Elutas\u00EDt\u00E1s" }),
                React.createElement(semantic_ui_react_1.Button, { className: "save-button", icon: "save", loading: (_s = this.state.variables) === null || _s === void 0 ? void 0 : _s.loading, onClick: function () { return _this.handleSave(); }, content: "Ment\u00E9s" }),
                React.createElement(semantic_ui_react_1.Button, { className: "approve-button", icon: "check circle", disabled: ((_t = this.state.approve) === null || _t === void 0 ? void 0 : _t.loading) || ((_u = this.state.disapprove) === null || _u === void 0 ? void 0 : _u.loading), loading: (_v = this.state.approve) === null || _v === void 0 ? void 0 : _v.loading, onClick: function () { return _this.handleApprove(); }, content: "J\u00F3v\u00E1hagy\u00E1s" })));
    };
    IntegrationTemplateVariablesComponent.prototype.renderVariableInputField = function (variable, key) {
        var _a, _b, _c;
        if (!((_a = this.form.fields) === null || _a === void 0 ? void 0 : _a[variable])) {
            return null;
        }
        var isEscapable = content_variables_1.isContentVariableEscapable(variable);
        if (isEscapable) {
            return React.createElement(form_control_1.FormControl, { field: (_b = this.form.fields) === null || _b === void 0 ? void 0 : _b[variable], key: key, type: quill_form_control_type_1.QuillFormControlType, component: quill_with_image_1.QuillWithImage });
        }
        return React.createElement(form_control_1.FormControl, { field: (_c = this.form.fields) === null || _c === void 0 ? void 0 : _c[variable], key: key, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType.withOptions({ detailedError: true }) },
            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input }));
    };
    IntegrationTemplateVariablesComponent.prototype.handleApprove = function () {
        return __awaiter(this, void 0, void 0, function () {
            var variableErrors, subjectErrors, shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        variableErrors = _a.sent();
                        return [4, this.subjectForm.validate()];
                    case 2:
                        subjectErrors = _a.sent();
                        if (!variableErrors) {
                            return [2, this.setState({ isError: true })];
                        }
                        if (!subjectErrors) {
                            return [2, this.setState({ isError: true })];
                        }
                        this.setState({ isError: false });
                        return [4, Promise.all([
                                this.updateSubject(true),
                                this.updateVariables(true)
                            ])];
                    case 3:
                        _a.sent();
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.approve.put("/s/" + shop._id + "/template/" + this.props.integrationTemplateId + "/approve")];
                    case 4:
                        _a.sent();
                        this.props.onApproval();
                        if (this.props.onSuccess) {
                            this.props.onSuccess();
                        }
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.handleSave = function () {
        return __awaiter(this, void 0, void 0, function () {
            var variableErrors, subjectErrors;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        variableErrors = _a.sent();
                        return [4, this.subjectForm.validate()];
                    case 2:
                        subjectErrors = _a.sent();
                        if (!variableErrors) {
                            return [2, this.setState({ isError: true })];
                        }
                        if (!subjectErrors) {
                            return [2, this.setState({ isError: true })];
                        }
                        this.setState({ isError: false });
                        return [4, Promise.all([
                                this.updateSubject(true),
                                this.updateVariables(true)
                            ])];
                    case 3:
                        _a.sent();
                        if (this.props.onSuccess) {
                            local_store_1.LocalStore.setItem('ReopenIntegrationTemplateEdit', this.props.templateId);
                            this.props.onSuccess();
                        }
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.handleDisapprove = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.disapprove.put("/s/" + shop._id + "/template/" + this.props.integrationTemplateId + "/disapprove")];
                    case 1:
                        _a.sent();
                        this.props.onDisapproval();
                        if (this.props.onSuccess) {
                            this.props.onSuccess();
                        }
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.renderTrigger = function () {
        return React.createElement(semantic_ui_react_1.Button, { size: "mini", basic: true, content: "Lev\u00E9l megtekint\u00E9se" });
    };
    IntegrationTemplateVariablesComponent.prototype.updateVariables = function (skipCallback) {
        if (skipCallback === void 0) { skipCallback = false; }
        return __awaiter(this, void 0, void 0, function () {
            var shop, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        data = this.form.toJSON();
                        return [4, this.variables.put("/s/" + shop._id + "/template/" + this.props.templateId + "/variables", {
                                data: {
                                    variables: Object.keys(data).map(function (key) { return ({
                                        key: key,
                                        value: data[key]
                                    }); })
                                }
                            })];
                    case 1:
                        _a.sent();
                        if (!skipCallback && this.props.onSuccess) {
                            this.props.onSuccess();
                        }
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.updateSubject = function (skipCallback) {
        if (skipCallback === void 0) { skipCallback = false; }
        return __awaiter(this, void 0, void 0, function () {
            var shop, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        data = this.subjectForm.toJSON();
                        return [4, this.subject.put("/s/" + shop._id + "/template/" + this.props.templateId + "/subject", {
                                data: data
                            })];
                    case 1:
                        _a.sent();
                        if (!skipCallback && this.props.onSuccess) {
                            this.props.onSuccess();
                        }
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.onOpen = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isOpen: true });
                        this.customerForm.fields.email.loadOptions();
                        return [4, this.fetchBasics()];
                    case 1:
                        _a.sent();
                        return [4, this.updatePreview()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IntegrationTemplateVariablesComponent.prototype.onClose = function () {
        this.setState({ isOpen: false });
    };
    IntegrationTemplateVariablesComponent.prototype.getHbsVariables = function () {
        var _a, _b, _c, _d;
        return ((_d = (_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.integrationTemplate) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.template) === null || _d === void 0 ? void 0 : _d.hbsVariables) || [];
    };
    IntegrationTemplateVariablesComponent.prototype.getVariableValues = function () {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.integrationTemplate) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.variables) || [];
    };
    IntegrationTemplateVariablesComponent.prototype.isReady = function () {
        if (!this.state.ready) {
            return false;
        }
        return true;
    };
    return IntegrationTemplateVariablesComponent;
}(abstract_component_1.AbstractComponent));
exports.IntegrationTemplateVariablesComponent = IntegrationTemplateVariablesComponent;
