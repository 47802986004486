module.exports = {
    body: function (args, title, description) { return ({
        type: 'fullwidth',
        children: [
            {
                type: 'loop',
                attrs: {
                    expression: args.valueKey,
                    hideOnMobile: false,
                    hideOnDesktop: false,
                    stacking: 'left-on-top',
                    spacerBefore: '0px',
                    spacerBetween: '0px',
                    spacerAfter: '0px',
                    spacerBeforeMobile: '0px',
                    spacerBetweenMobile: '0px',
                    spacerAfterMobile: '0px'
                },
                children: [
                    {
                        type: 'multicolumn',
                        children: [
                            {
                                type: 'column',
                                children: [
                                    {
                                        type: 'conditional',
                                        attrs: {
                                            expression: 'this.[0]'
                                        },
                                        children: [
                                            {
                                                type: 'box',
                                                children: [
                                                    {
                                                        type: 'box',
                                                        children: [
                                                            {
                                                                type: 'box',
                                                                children: [
                                                                    {
                                                                        type: 'box',
                                                                        children: [
                                                                            {
                                                                                type: 'dynamic-image',
                                                                                attrs: {
                                                                                    mini: false,
                                                                                    sizeType: 'FIXED',
                                                                                    link: '{{this.[0].data.link}}',
                                                                                    lockDimensions: { width: 300, height: 300 },
                                                                                    altText: '',
                                                                                    fullWidthOnMobile: false,
                                                                                    align: 'center'
                                                                                },
                                                                                style: {
                                                                                    'max-height': '1800px',
                                                                                    'max-width': '2700px',
                                                                                    src: '{{this.[0].data.image.contain}}',
                                                                                    width: '200px',
                                                                                    'background-color': '',
                                                                                    'background-repeat': 'no-repeat',
                                                                                    'background-position': 'center center',
                                                                                    'background-image': 'url()',
                                                                                    'border-radius': '0px',
                                                                                    'border-left': '0px solid #000000',
                                                                                    'border-bottom': '0px solid #000000',
                                                                                    'border-right': '0px solid #000000',
                                                                                    'border-top': '0px solid #000000',
                                                                                    'margin-left': '0px',
                                                                                    'margin-bottom': '0px',
                                                                                    'margin-right': '0px',
                                                                                    'margin-top': '0px',
                                                                                    'padding-left': '0px',
                                                                                    'padding-bottom': '0px',
                                                                                    'padding-right': '0px',
                                                                                    'padding-top': '0px'
                                                                                }
                                                                            }
                                                                        ],
                                                                        attrs: {
                                                                            mini: false,
                                                                            sizeType: 'FIXED',
                                                                            link: '{{this.[0].data.link}}',
                                                                            lockDimensions: { width: null, height: null },
                                                                            altText: '',
                                                                            fullWidthOnMobile: false,
                                                                            align: 'center',
                                                                            hideOnMobile: false,
                                                                            hideOnDesktop: false
                                                                        },
                                                                        style: {
                                                                            'max-height': '300px',
                                                                            'max-width': '300px',
                                                                            src: '{{this.[0].data.image.contain}}',
                                                                            width: '150px',
                                                                            'background-color': '',
                                                                            'background-repeat': 'no-repeat',
                                                                            'background-position': 'center center',
                                                                            'background-image': 'url()',
                                                                            'border-radius': '0px',
                                                                            'border-left': '0px solid #000000',
                                                                            'border-bottom': '0px solid #000000',
                                                                            'border-right': '0px solid #000000',
                                                                            'border-top': '0px solid #000000',
                                                                            'margin-left': '0px',
                                                                            'margin-bottom': '0px',
                                                                            'margin-right': '0px',
                                                                            'margin-top': '0px',
                                                                            'padding-left': '0px',
                                                                            'padding-bottom': '0px',
                                                                            'padding-right': '0px',
                                                                            'padding-top': '0px'
                                                                        }
                                                                    }
                                                                ],
                                                                attrs: {
                                                                    hideOnMobile: false,
                                                                    hideOnDesktop: false,
                                                                    mini: false,
                                                                    sizeType: 'FIXED',
                                                                    link: '{{this.[0].data.link}}',
                                                                    lockDimensions: { width: null, height: null },
                                                                    altText: '',
                                                                    fullWidthOnMobile: false,
                                                                    align: 'center'
                                                                },
                                                                style: {
                                                                    'background-color': '',
                                                                    'background-repeat': 'no-repeat',
                                                                    'background-position': 'center center',
                                                                    'background-image': 'url()',
                                                                    'border-radius': '0px',
                                                                    'border-left': '0px solid #000000',
                                                                    'border-bottom': '0px solid #000000',
                                                                    'border-right': '0px solid #000000',
                                                                    'border-top': '0px solid #000000',
                                                                    'margin-left': '0px',
                                                                    'margin-bottom': '0px',
                                                                    'margin-right': '0px',
                                                                    'margin-top': '0px',
                                                                    'padding-left': '0px',
                                                                    'padding-bottom': '0px',
                                                                    'padding-right': '0px',
                                                                    'padding-top': '0px',
                                                                    'max-height': '300px',
                                                                    'max-width': '300px',
                                                                    src: '{{this.[0].data.image.contain}}',
                                                                    width: '200px'
                                                                }
                                                            }, {
                                                                type: 'box',
                                                                children: [
                                                                    {
                                                                        type: 'box',
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                attrs: { text: '<p style="text-align: center;">{{this.[0].data.name}}</p>\n' },
                                                                                style: {
                                                                                    'background-color': '',
                                                                                    'background-repeat': 'no-repeat',
                                                                                    'background-position': 'center center',
                                                                                    'background-image': 'url()',
                                                                                    'padding-left': '10px',
                                                                                    'padding-bottom': '5px',
                                                                                    'padding-right': '10px',
                                                                                    'padding-top': '5px',
                                                                                    'line-height': '24px',
                                                                                    'letter-spacing': 'normal',
                                                                                    color: '#131313',
                                                                                    'font-size': '16px',
                                                                                    'font-family': 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
                                                                                    a: { 'text-decoration': 'underline', color: '#00c0e7' },
                                                                                    h3: { 'line-height': '27px', color: '#000000', 'font-size': '22px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                                    h2: { 'line-height': '34px', color: '#000000', 'font-size': '26px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                                    h1: { 'line-height': '40px', color: '#000000', 'font-size': '33px', 'font-family': 'Georgia', 'letter-spacing': 'normal' }
                                                                                }
                                                                            }
                                                                        ],
                                                                        attrs: { hideOnMobile: false, hideOnDesktop: false },
                                                                        style: {
                                                                            'background-color': '',
                                                                            'background-repeat': 'no-repeat',
                                                                            'background-position': 'center center',
                                                                            'background-image': 'url()',
                                                                            'border-radius': '0px',
                                                                            'border-left': '0px solid #000000',
                                                                            'border-bottom': '0px solid #000000',
                                                                            'border-right': '0px solid #000000',
                                                                            'border-top': '0px solid #000000',
                                                                            'margin-left': '0px',
                                                                            'margin-bottom': '0px',
                                                                            'margin-right': '0px',
                                                                            'margin-top': '0px',
                                                                            'padding-left': '0px',
                                                                            'padding-bottom': '0px',
                                                                            'padding-right': '0px',
                                                                            'padding-top': '0px'
                                                                        }
                                                                    }
                                                                ],
                                                                attrs: { text: 'Double click to edit text!', hideOnMobile: false, hideOnDesktop: false },
                                                                style: {
                                                                    'background-color': '',
                                                                    'background-repeat': 'no-repeat',
                                                                    'background-position': 'center center',
                                                                    'background-image': 'url()',
                                                                    'padding-left': '0px',
                                                                    'padding-bottom': '8px',
                                                                    'padding-right': '0px',
                                                                    'padding-top': '16px',
                                                                    'line-height': '25px',
                                                                    'letter-spacing': 'normal',
                                                                    color: '#131313',
                                                                    'font-size': '18px',
                                                                    'font-family': 'arial',
                                                                    a: { 'text-decoration': 'underline', color: '#00c0e7' },
                                                                    h3: { 'line-height': '27px', color: '#000000', 'font-size': '22px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                    h2: { 'line-height': '34px', color: '#000000', 'font-size': '26px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                    h1: { 'line-height': '40px', color: '#000000', 'font-size': '33px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                    'border-radius': '0px',
                                                                    'border-left': '0px solid #000000',
                                                                    'border-bottom': '0px solid #000000',
                                                                    'border-right': '0px solid #000000',
                                                                    'border-top': '0px solid #000000',
                                                                    'margin-left': '0px',
                                                                    'margin-bottom': '0px',
                                                                    'margin-right': '0px',
                                                                    'margin-top': '0px'
                                                                }
                                                            }, {
                                                                type: 'box',
                                                                children: [
                                                                    {
                                                                        type: 'box',
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                attrs: {
                                                                                    text: '<p><strong>{{Variable_ProductButtonTitle}}</strong></p>\n',
                                                                                    sizeType: 'FIT_TO_TEXT',
                                                                                    href: '{{this.[0].data.link}}',
                                                                                    fullWidthOnMobile: true,
                                                                                    align: 'center'
                                                                                },
                                                                                style: {
                                                                                    'line-height': '24px',
                                                                                    'font-size': '16px',
                                                                                    'font-family': 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
                                                                                    'letter-spacing': 'normal',
                                                                                    color: '{{ Context_Shop_ButtonTextColor }}',
                                                                                    'background-color': '{{ Context_Shop_ButtonBackgroundColor }}',
                                                                                    'background-repeat': 'no-repeat',
                                                                                    'background-position': 'center center',
                                                                                    'background-image': 'url()',
                                                                                    'border-radius': '5px',
                                                                                    'border-left': '0px solid #000000',
                                                                                    'border-bottom': '0px solid #000000',
                                                                                    'border-right': '0px solid #000000',
                                                                                    'border-top': '0px solid #000000',
                                                                                    'margin-left': '0px',
                                                                                    'margin-bottom': '0px',
                                                                                    'margin-right': '0px',
                                                                                    'margin-top': '0px',
                                                                                    'padding-left': '16px',
                                                                                    'padding-bottom': '8px',
                                                                                    'padding-right': '16px',
                                                                                    'padding-top': '8px'
                                                                                }
                                                                            }
                                                                        ],
                                                                        attrs: { hideOnMobile: false, hideOnDesktop: false },
                                                                        style: {
                                                                            'background-color': '',
                                                                            'background-repeat': 'no-repeat',
                                                                            'background-position': 'center center',
                                                                            'background-image': 'url()',
                                                                            'border-radius': '0px',
                                                                            'border-left': '0px solid #000000',
                                                                            'border-bottom': '0px solid #000000',
                                                                            'border-right': '0px solid #000000',
                                                                            'border-top': '0px solid #000000',
                                                                            'margin-left': '0px',
                                                                            'margin-bottom': '0px',
                                                                            'margin-right': '0px',
                                                                            'margin-top': '0px',
                                                                            'padding-left': '0px',
                                                                            'padding-bottom': '0px',
                                                                            'padding-right': '0px',
                                                                            'padding-top': '0px'
                                                                        }
                                                                    }
                                                                ],
                                                                attrs: {
                                                                    text: 'Click here to edit me',
                                                                    sizeType: 'FIT_TO_TEXT',
                                                                    href: '{{this.[0].data.link}}',
                                                                    fullWidthOnMobile: true,
                                                                    align: 'center',
                                                                    hideOnMobile: false,
                                                                    hideOnDesktop: false
                                                                },
                                                                style: {
                                                                    'line-height': '25px',
                                                                    color: '#ffffff',
                                                                    'font-size': '18px',
                                                                    'font-family': 'Arial',
                                                                    'letter-spacing': 'normal',
                                                                    'background-color': '',
                                                                    'background-repeat': 'no-repeat',
                                                                    'background-position': 'center center',
                                                                    'background-image': 'url()',
                                                                    'border-radius': '0px',
                                                                    'border-left': '0px solid #000000',
                                                                    'border-bottom': '0px solid #000000',
                                                                    'border-right': '0px solid #000000',
                                                                    'border-top': '0px solid #000000',
                                                                    'margin-left': '0px',
                                                                    'margin-bottom': '0px',
                                                                    'margin-right': '0px',
                                                                    'margin-top': '0px',
                                                                    'padding-left': '0px',
                                                                    'padding-bottom': '0px',
                                                                    'padding-right': '0px',
                                                                    'padding-top': '0px'
                                                                }
                                                            }
                                                        ],
                                                        attrs: {
                                                            mini: false,
                                                            sizeType: 'FIXED',
                                                            link: '{{this.[0].data.link}}',
                                                            lockDimensions: { width: null, height: null },
                                                            altText: '',
                                                            fullWidthOnMobile: false,
                                                            align: 'center',
                                                            hideOnMobile: false,
                                                            hideOnDesktop: false
                                                        },
                                                        style: {
                                                            'max-height': '300px',
                                                            'max-width': '300px',
                                                            src: '{{this.[0].data.image.contain}}',
                                                            width: '200px',
                                                            'background-color': '',
                                                            'background-repeat': 'no-repeat',
                                                            'background-position': 'center center',
                                                            'background-image': 'url()',
                                                            'border-radius': '4px',
                                                            'border-left': '1px solid #E0E0E0',
                                                            'border-bottom': '1px solid #E0E0E0',
                                                            'border-right': '1px solid #E0E0E0',
                                                            'border-top': '1px solid #E0E0E0',
                                                            'margin-left': '8px',
                                                            'margin-bottom': '8px',
                                                            'margin-right': '4px',
                                                            'margin-top': '8px',
                                                            'padding-left': '16px',
                                                            'padding-bottom': '16px',
                                                            'padding-right': '16px',
                                                            'padding-top': '16px'
                                                        }
                                                    }
                                                ],
                                                attrs: {
                                                    mini: false,
                                                    sizeType: 'FIXED',
                                                    link: '{{this.[0].data.link}}',
                                                    lockDimensions: { width: null, height: null },
                                                    altText: '',
                                                    fullWidthOnMobile: false,
                                                    align: 'center',
                                                    hideOnMobile: false,
                                                    hideOnDesktop: false
                                                },
                                                style: {
                                                    'background-color': '',
                                                    'background-repeat': 'no-repeat',
                                                    'background-position': 'center center',
                                                    'background-image': 'url()',
                                                    'border-radius': '0',
                                                    'border-left': '0px solid #E0E0E0',
                                                    'border-bottom': '0px solid #E0E0E0',
                                                    'border-right': '0px solid #E0E0E0',
                                                    'border-top': '0px solid #E0E0E0',
                                                    'margin-left': '0px',
                                                    'margin-bottom': '0px',
                                                    'margin-right': '0px',
                                                    'margin-top': '0px',
                                                    'padding-left': '0px',
                                                    'padding-bottom': '0px',
                                                    'padding-right': '0px',
                                                    'padding-top': '0px',
                                                    width: '300px'
                                                }
                                            }
                                        ],
                                        style: {}
                                    }
                                ],
                                attrs: { hideOnMobile: false, hideOnDesktop: false, lock: false },
                                style: {
                                    width: '300px'
                                }
                            },
                            {
                                type: 'column',
                                children: [
                                    {
                                        type: 'conditional',
                                        attrs: {
                                            expression: 'this.[1]'
                                        },
                                        children: [
                                            {
                                                type: 'box',
                                                children: [
                                                    {
                                                        type: 'box',
                                                        children: [
                                                            {
                                                                type: 'box',
                                                                children: [
                                                                    {
                                                                        type: 'box',
                                                                        children: [
                                                                            {
                                                                                type: 'dynamic-image',
                                                                                attrs: {
                                                                                    mini: false,
                                                                                    sizeType: 'FIXED',
                                                                                    link: '{{this.[1].data.link}}',
                                                                                    lockDimensions: { width: 300, height: 300 },
                                                                                    altText: '',
                                                                                    fullWidthOnMobile: false,
                                                                                    align: 'center'
                                                                                },
                                                                                style: {
                                                                                    'max-height': '1800px',
                                                                                    'max-width': '2700px',
                                                                                    src: '{{this.[1].data.image.contain}}',
                                                                                    width: '200px',
                                                                                    'background-color': '',
                                                                                    'background-repeat': 'no-repeat',
                                                                                    'background-position': 'center center',
                                                                                    'background-image': 'url()',
                                                                                    'border-radius': '0px',
                                                                                    'border-left': '0px solid #000000',
                                                                                    'border-bottom': '0px solid #000000',
                                                                                    'border-right': '0px solid #000000',
                                                                                    'border-top': '0px solid #000000',
                                                                                    'margin-left': '0px',
                                                                                    'margin-bottom': '0px',
                                                                                    'margin-right': '0px',
                                                                                    'margin-top': '0px',
                                                                                    'padding-left': '0px',
                                                                                    'padding-bottom': '0px',
                                                                                    'padding-right': '0px',
                                                                                    'padding-top': '0px'
                                                                                }
                                                                            }
                                                                        ],
                                                                        attrs: {
                                                                            mini: false,
                                                                            sizeType: 'FIXED',
                                                                            link: '{{this.[1].data.link}}',
                                                                            lockDimensions: { width: null, height: null },
                                                                            altText: '',
                                                                            fullWidthOnMobile: false,
                                                                            align: 'center',
                                                                            hideOnMobile: false,
                                                                            hideOnDesktop: false
                                                                        },
                                                                        style: {
                                                                            'max-height': '300px',
                                                                            'max-width': '300px',
                                                                            src: '{{this.[1].data.image.contain}}',
                                                                            width: '150px',
                                                                            'background-color': '',
                                                                            'background-repeat': 'no-repeat',
                                                                            'background-position': 'center center',
                                                                            'background-image': 'url()',
                                                                            'border-radius': '0px',
                                                                            'border-left': '0px solid #000000',
                                                                            'border-bottom': '0px solid #000000',
                                                                            'border-right': '0px solid #000000',
                                                                            'border-top': '0px solid #000000',
                                                                            'margin-left': '0px',
                                                                            'margin-bottom': '0px',
                                                                            'margin-right': '0px',
                                                                            'margin-top': '0px',
                                                                            'padding-left': '0px',
                                                                            'padding-bottom': '0px',
                                                                            'padding-right': '0px',
                                                                            'padding-top': '0px'
                                                                        }
                                                                    }
                                                                ],
                                                                attrs: {
                                                                    hideOnMobile: false,
                                                                    hideOnDesktop: false,
                                                                    mini: false,
                                                                    sizeType: 'FIXED',
                                                                    link: '{{this.[1].data.link}}',
                                                                    lockDimensions: { width: null, height: null },
                                                                    altText: '',
                                                                    fullWidthOnMobile: false,
                                                                    align: 'center'
                                                                },
                                                                style: {
                                                                    'background-color': '',
                                                                    'background-repeat': 'no-repeat',
                                                                    'background-position': 'center center',
                                                                    'background-image': 'url()',
                                                                    'border-radius': '0px',
                                                                    'border-left': '0px solid #000000',
                                                                    'border-bottom': '0px solid #000000',
                                                                    'border-right': '0px solid #000000',
                                                                    'border-top': '0px solid #000000',
                                                                    'margin-left': '0px',
                                                                    'margin-bottom': '0px',
                                                                    'margin-right': '0px',
                                                                    'margin-top': '0px',
                                                                    'padding-left': '0px',
                                                                    'padding-bottom': '0px',
                                                                    'padding-right': '0px',
                                                                    'padding-top': '0px',
                                                                    'max-height': '300px',
                                                                    'max-width': '300px',
                                                                    src: '{{this.[1].data.image.contain}}',
                                                                    width: '200px'
                                                                }
                                                            }, {
                                                                type: 'box',
                                                                children: [
                                                                    {
                                                                        type: 'box',
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                attrs: { text: '<p style="text-align: center;">{{this.[1].data.name}}</p>\n' },
                                                                                style: {
                                                                                    'background-color': '',
                                                                                    'background-repeat': 'no-repeat',
                                                                                    'background-position': 'center center',
                                                                                    'background-image': 'url()',
                                                                                    'padding-left': '10px',
                                                                                    'padding-bottom': '5px',
                                                                                    'padding-right': '10px',
                                                                                    'padding-top': '5px',
                                                                                    'line-height': '24px',
                                                                                    'letter-spacing': 'normal',
                                                                                    color: '#131313',
                                                                                    'font-size': '16px',
                                                                                    'font-family': 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
                                                                                    a: { 'text-decoration': 'underline', color: '#00c0e7' },
                                                                                    h3: { 'line-height': '27px', color: '#000000', 'font-size': '22px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                                    h2: { 'line-height': '34px', color: '#000000', 'font-size': '26px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                                    h1: { 'line-height': '40px', color: '#000000', 'font-size': '33px', 'font-family': 'Georgia', 'letter-spacing': 'normal' }
                                                                                }
                                                                            }
                                                                        ],
                                                                        attrs: { hideOnMobile: false, hideOnDesktop: false },
                                                                        style: {
                                                                            'background-color': '',
                                                                            'background-repeat': 'no-repeat',
                                                                            'background-position': 'center center',
                                                                            'background-image': 'url()',
                                                                            'border-radius': '0px',
                                                                            'border-left': '0px solid #000000',
                                                                            'border-bottom': '0px solid #000000',
                                                                            'border-right': '0px solid #000000',
                                                                            'border-top': '0px solid #000000',
                                                                            'margin-left': '0px',
                                                                            'margin-bottom': '0px',
                                                                            'margin-right': '0px',
                                                                            'margin-top': '0px',
                                                                            'padding-left': '0px',
                                                                            'padding-bottom': '0px',
                                                                            'padding-right': '0px',
                                                                            'padding-top': '0px'
                                                                        }
                                                                    }
                                                                ],
                                                                attrs: { text: 'Double click to edit text!', hideOnMobile: false, hideOnDesktop: false },
                                                                style: {
                                                                    'background-color': '',
                                                                    'background-repeat': 'no-repeat',
                                                                    'background-position': 'center center',
                                                                    'background-image': 'url()',
                                                                    'padding-left': '0px',
                                                                    'padding-bottom': '8px',
                                                                    'padding-right': '0px',
                                                                    'padding-top': '16px',
                                                                    'line-height': '25px',
                                                                    'letter-spacing': 'normal',
                                                                    color: '#131313',
                                                                    'font-size': '18px',
                                                                    'font-family': 'arial',
                                                                    a: { 'text-decoration': 'underline', color: '#00c0e7' },
                                                                    h3: { 'line-height': '27px', color: '#000000', 'font-size': '22px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                    h2: { 'line-height': '34px', color: '#000000', 'font-size': '26px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                    h1: { 'line-height': '40px', color: '#000000', 'font-size': '33px', 'font-family': 'Georgia', 'letter-spacing': 'normal' },
                                                                    'border-radius': '0px',
                                                                    'border-left': '0px solid #000000',
                                                                    'border-bottom': '0px solid #000000',
                                                                    'border-right': '0px solid #000000',
                                                                    'border-top': '0px solid #000000',
                                                                    'margin-left': '0px',
                                                                    'margin-bottom': '0px',
                                                                    'margin-right': '0px',
                                                                    'margin-top': '0px'
                                                                }
                                                            }, {
                                                                type: 'box',
                                                                children: [
                                                                    {
                                                                        type: 'box',
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                attrs: {
                                                                                    text: '<p><strong>{{Variable_ProductButtonTitle}}</strong></p>\n',
                                                                                    sizeType: 'FIT_TO_TEXT',
                                                                                    href: '{{this.[1].data.link}}',
                                                                                    fullWidthOnMobile: true,
                                                                                    align: 'center'
                                                                                },
                                                                                style: {
                                                                                    'line-height': '24px',
                                                                                    'font-size': '16px',
                                                                                    'font-family': 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
                                                                                    'letter-spacing': 'normal',
                                                                                    color: '{{ Context_Shop_ButtonTextColor }}',
                                                                                    'background-color': '{{ Context_Shop_ButtonBackgroundColor }}', 'background-repeat': 'no-repeat',
                                                                                    'background-position': 'center center',
                                                                                    'background-image': 'url()',
                                                                                    'border-radius': '5px',
                                                                                    'border-left': '0px solid #000000',
                                                                                    'border-bottom': '0px solid #000000',
                                                                                    'border-right': '0px solid #000000',
                                                                                    'border-top': '0px solid #000000',
                                                                                    'margin-left': '0px',
                                                                                    'margin-bottom': '0px',
                                                                                    'margin-right': '0px',
                                                                                    'margin-top': '0px',
                                                                                    'padding-left': '16px',
                                                                                    'padding-bottom': '8px',
                                                                                    'padding-right': '16px',
                                                                                    'padding-top': '8px'
                                                                                }
                                                                            }
                                                                        ],
                                                                        attrs: { hideOnMobile: false, hideOnDesktop: false },
                                                                        style: {
                                                                            'background-color': '',
                                                                            'background-repeat': 'no-repeat',
                                                                            'background-position': 'center center',
                                                                            'background-image': 'url()',
                                                                            'border-radius': '0px',
                                                                            'border-left': '0px solid #000000',
                                                                            'border-bottom': '0px solid #000000',
                                                                            'border-right': '0px solid #000000',
                                                                            'border-top': '0px solid #000000',
                                                                            'margin-left': '0px',
                                                                            'margin-bottom': '0px',
                                                                            'margin-right': '0px',
                                                                            'margin-top': '0px',
                                                                            'padding-left': '0px',
                                                                            'padding-bottom': '0px',
                                                                            'padding-right': '0px',
                                                                            'padding-top': '0px'
                                                                        }
                                                                    }
                                                                ],
                                                                attrs: {
                                                                    text: 'Click here to edit me',
                                                                    sizeType: 'FIT_TO_TEXT',
                                                                    href: '{{this.[1].data.link}}',
                                                                    fullWidthOnMobile: true,
                                                                    align: 'center',
                                                                    hideOnMobile: false,
                                                                    hideOnDesktop: false
                                                                },
                                                                style: {
                                                                    'line-height': '25px',
                                                                    color: '#ffffff',
                                                                    'font-size': '18px',
                                                                    'font-family': 'Arial',
                                                                    'letter-spacing': 'normal',
                                                                    'background-color': '',
                                                                    'background-repeat': 'no-repeat',
                                                                    'background-position': 'center center',
                                                                    'background-image': 'url()',
                                                                    'border-radius': '0px',
                                                                    'border-left': '0px solid #000000',
                                                                    'border-bottom': '0px solid #000000',
                                                                    'border-right': '0px solid #000000',
                                                                    'border-top': '0px solid #000000',
                                                                    'margin-left': '0px',
                                                                    'margin-bottom': '0px',
                                                                    'margin-right': '0px',
                                                                    'margin-top': '0px',
                                                                    'padding-left': '0px',
                                                                    'padding-bottom': '0px',
                                                                    'padding-right': '0px',
                                                                    'padding-top': '0px'
                                                                }
                                                            }
                                                        ],
                                                        attrs: {
                                                            mini: false,
                                                            sizeType: 'FIXED',
                                                            link: '{{this.[1].data.link}}',
                                                            lockDimensions: { width: null, height: null },
                                                            altText: '',
                                                            fullWidthOnMobile: false,
                                                            align: 'center',
                                                            hideOnMobile: false,
                                                            hideOnDesktop: false
                                                        },
                                                        style: {
                                                            'max-height': '300px',
                                                            'max-width': '300px',
                                                            src: '{{this.[1].data.image.contain}}',
                                                            width: '200px',
                                                            'background-color': '',
                                                            'background-repeat': 'no-repeat',
                                                            'background-position': 'center center',
                                                            'background-image': 'url()',
                                                            'border-radius': '4px',
                                                            'border-left': '1px solid #E0E0E0',
                                                            'border-bottom': '1px solid #E0E0E0',
                                                            'border-right': '1px solid #E0E0E0',
                                                            'border-top': '1px solid #E0E0E0',
                                                            'margin-left': '8px',
                                                            'margin-bottom': '8px',
                                                            'margin-right': '4px',
                                                            'margin-top': '8px',
                                                            'padding-left': '16px',
                                                            'padding-bottom': '16px',
                                                            'padding-right': '16px',
                                                            'padding-top': '16px'
                                                        }
                                                    }
                                                ],
                                                attrs: {
                                                    mini: false,
                                                    sizeType: 'FIXED',
                                                    link: '{{this.[1].data.link}}',
                                                    lockDimensions: { width: null, height: null },
                                                    altText: '',
                                                    fullWidthOnMobile: false,
                                                    align: 'center',
                                                    hideOnMobile: false,
                                                    hideOnDesktop: false
                                                },
                                                style: {
                                                    'background-color': '',
                                                    'background-repeat': 'no-repeat',
                                                    'background-position': 'center center',
                                                    'background-image': 'url()',
                                                    'border-radius': '0',
                                                    'border-left': '0px solid #E0E0E0',
                                                    'border-bottom': '0px solid #E0E0E0',
                                                    'border-right': '0px solid #E0E0E0',
                                                    'border-top': '0px solid #E0E0E0',
                                                    'margin-left': '0px',
                                                    'margin-bottom': '0px',
                                                    'margin-right': '0px',
                                                    'margin-top': '0px',
                                                    'padding-left': '0px',
                                                    'padding-bottom': '0px',
                                                    'padding-right': '0px',
                                                    'padding-top': '0px',
                                                    width: '300px'
                                                }
                                            }
                                        ],
                                        style: {}
                                    }
                                ],
                                attrs: { hideOnMobile: false, hideOnDesktop: false, lock: false },
                                style: {
                                    width: '300px'
                                }
                            },
                        ],
                        attrs: {
                            hideOnMobile: false,
                            lock: false,
                            hideOnDesktop: false,
                            stacking: 'left-on-top',
                            spacerBefore: '0px',
                            spacerBetween: '0px',
                            spacerAfter: '0px',
                            spacerBeforeMobile: '0px',
                            spacerBetweenMobile: '0px',
                            spacerAfterMobile: '0px'
                        },
                        style: { width: '600px', 'background-color': '#ffffff', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-image': 'url()' }
                    }
                ],
                style: { 'background-color': '#ffffff', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-image': 'url()' }
            }
        ],
        attrs: { hideOnMobile: false, hideOnDesktop: false, lock: false, marker: '' },
        style: {
            'background-color': '',
            'background-repeat': 'no-repeat',
            'background-position': 'center center',
            'background-image': 'url()',
            'content-background-color': '',
            'content-background-repeat': 'no-repeat',
            'content-background-position': 'center center',
            'content-background-image': 'url()',
            'content-border-radius': '0px',
            'content-border-left': '0px solid #000000',
            'content-border-bottom': '0px solid #000000',
            'content-border-right': '0px solid #000000',
            'content-border-top': '0px solid #000000',
            'content-margin-left': '0px',
            'content-margin-bottom': '0px',
            'content-margin-right': '0px',
            'content-margin-top': '0px',
            'content-padding-left': '0px',
            'content-padding-bottom': '0px',
            'content-padding-right': '0px',
            'content-padding-top': '0px',
            'content-width': null,
            'body-width': 600
        },
        customData: {}
    }); },
    placeholder: function (args, title, description) { return [
        {
            attrs: {
                hideOnMobile: false,
                hideOnDesktop: false
            },
            style: {
                'background-color': '',
                'background-repeat': 'no-repeat',
                'background-position': 'center center',
                'background-image': 'url()',
                'border-radius': '0px',
                'border-left-color': '#000000',
                'border-left-style': 'solid',
                'border-left-width': '0px',
                'border-bottom-color': '#000000',
                'border-bottom-style': 'solid',
                'border-bottom-width': '0px',
                'border-right-color': '#000000',
                'border-right-style': 'solid',
                'border-right-width': '0px',
                'border-top-color': '#000000',
                'border-top-style': 'solid',
                'border-top-width': '0px',
                'margin-left': '0px',
                'margin-bottom': '0px',
                'margin-right': '0px',
                'margin-top': '0px',
                'padding-left': '8px',
                'padding-bottom': '8px',
                'padding-right': '8px',
                'padding-top': '8px'
            },
            type: 'box',
            children: [
                {
                    attrs: {
                        hideOnMobile: false,
                        hideOnDesktop: false
                    },
                    style: {
                        'background-color': '#FCFCFC',
                        'background-repeat': 'no-repeat',
                        'background-position': 'center center',
                        'background-image': 'url()',
                        'border-radius': '2px',
                        'border-left-color': '#E0E0E0',
                        'border-left-style': 'solid',
                        'border-left-width': '1px',
                        'border-bottom-color': '#E0E0E0',
                        'border-bottom-style': 'solid',
                        'border-bottom-width': '1px',
                        'border-right-color': '#E0E0E0',
                        'border-right-style': 'solid',
                        'border-right-width': '1px',
                        'border-top-color': '#E0E0E0',
                        'border-top-style': 'solid',
                        'border-top-width': '1px',
                        'margin-left': '0px',
                        'margin-bottom': '0px',
                        'margin-right': '0px',
                        'margin-top': '0px',
                        'padding-left': '8px',
                        'padding-bottom': '32px',
                        'padding-right': '8px',
                        'padding-top': '32px'
                    },
                    type: 'box',
                    children: [
                        {
                            attrs: {
                                text: "<p style=\"text-align: center;\"><span style=\"font-size:24px;\"><strong>" + title + "</strong></span></p>\n"
                            },
                            style: {
                                'background-color': '',
                                'background-repeat': 'no-repeat',
                                'background-position': 'center center',
                                'background-image': 'url()',
                                'padding-left': '10px',
                                'padding-bottom': '5px',
                                'padding-right': '10px',
                                'padding-top': '5px',
                                'line-height': '32px',
                                'letter-spacing': 'normal',
                                color: '#131313',
                                'font-size': '24px',
                                'font-family': 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
                                a: {
                                    'text-decoration': 'underline',
                                    color: '#00c0e7'
                                },
                                h3: {
                                    'line-height': '27px',
                                    color: '#000000',
                                    'font-size': '22px',
                                    'font-family': 'Georgia',
                                    'letter-spacing': 'normal'
                                },
                                h2: {
                                    'line-height': '34px',
                                    color: '#000000',
                                    'font-size': '26px',
                                    'font-family': 'Georgia',
                                    'letter-spacing': 'normal'
                                },
                                h1: {
                                    'line-height': '40px',
                                    color: '#000000',
                                    'font-size': '33px',
                                    'font-family': 'Georgia',
                                    'letter-spacing': 'normal'
                                }
                            },
                            type: 'text',
                        },
                        {
                            attrs: {
                                text: "<p style=\"text-align: center;\">" + description + "</p>\n"
                            },
                            style: {
                                'background-color': '',
                                'background-repeat': 'no-repeat',
                                'background-position': 'center center',
                                'background-image': 'url()',
                                'padding-left': '10px',
                                'padding-bottom': '5px',
                                'padding-right': '10px',
                                'padding-top': '5px',
                                'line-height': '24px',
                                'letter-spacing': 'normal',
                                color: '#131313',
                                'font-size': '16px',
                                'font-family': 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
                                a: {
                                    'text-decoration': 'underline',
                                    color: '#00c0e7'
                                },
                                h3: {
                                    'line-height': '27px',
                                    color: '#000000',
                                    'font-size': '22px',
                                    'font-family': 'Georgia',
                                    'letter-spacing': 'normal'
                                },
                                h2: {
                                    'line-height': '34px',
                                    color: '#000000',
                                    'font-size': '26px',
                                    'font-family': 'Georgia',
                                    'letter-spacing': 'normal'
                                },
                                h1: {
                                    'line-height': '40px',
                                    color: '#000000',
                                    'font-size': '33px',
                                    'font-family': 'Georgia',
                                    'letter-spacing': 'normal'
                                }
                            },
                            type: 'text',
                        }
                    ]
                }
            ]
        }
    ]; },
};
