"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterAppsListScreen = void 0;
require("../libs/common.scss");
var React = require("react");
var moment = require("moment");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var shoprenter_apps_list_menu_1 = require("../libs/shoprenter-apps-list-menu");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var checkIcon = (React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'mr-3', color: 'green', loading: false, name: 'check circle' }));
var crossIcon = (React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'mr-3', color: 'red', loading: false, name: 'times circle' }));
var ShoprenterAppsListScreen = (function (_super) {
    __extends(ShoprenterAppsListScreen, _super);
    function ShoprenterAppsListScreen(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this.shoprenterAppsListMenu = shoprenter_apps_list_menu_1.shoprenterAppsListMenu;
        _this.state = {
            isLoading: false,
            activeTabId: ((_a = _this.shoprenterAppsListMenu[0]) === null || _a === void 0 ? void 0 : _a.identifier) || ''
        };
        return _this;
    }
    ShoprenterAppsListScreen.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop ID")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop neve")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop URL")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Kapcsolattart\u00F3")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Feliratkoz\u00F3k")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "\u00C1llapot")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "\u00C1llapotba l\u00E9p\u00E9s id\u0151pontja")))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    ShoprenterAppsListScreen.prototype.renderOperatingItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop neve")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop URL")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Kapcsolattart\u00F3")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "SUBS")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "DATA")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "SYNC")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "SCRIPT")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "LIST")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "DNS")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "PROFIL")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "MAIL")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "SEND")))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    ShoprenterAppsListScreen.prototype.renderArchiveItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop ID")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop neve")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop URL")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Kapcsolattart\u00F3")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Feliratkoz\u00F3k")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "T\u00F6rl\u00E9s id\u0151pontja")))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    ShoprenterAppsListScreen.prototype.renderTodoSteps = function (dashboard) {
        var autoMailUpAndRunning = dashboard.autoMailUpAndRunning, dnsSetup = dashboard.dnsSetup, installQueue = dashboard.installQueue, listCleanApproved = dashboard.listCleanApproved, listCleaned = dashboard.listCleaned, paymentFinished = dashboard.paymentFinished, productSyncImminent = dashboard.productSyncImminent, productSyncImminentTimeStamp = dashboard.productSyncImminentTimeStamp, productSynced = dashboard.productSynced, webShopDataSaved = dashboard.webShopDataSaved, webShopScriptsInserted = dashboard.webShopScriptsInserted;
        var todoSteps = {
            autoMailUpAndRunning: autoMailUpAndRunning,
            dnsSetup: dnsSetup,
            installQueue: installQueue,
            listCleanApproved: listCleanApproved,
            listCleaned: listCleaned,
            paymentFinished: paymentFinished,
            productSyncImminent: productSyncImminent,
            productSyncImminentTimeStamp: productSyncImminentTimeStamp,
            productSynced: productSynced,
            webShopDataSaved: webShopDataSaved,
            webShopScriptsInserted: webShopScriptsInserted
        };
        return (React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } }, Object.entries(todoSteps).map(function (_a) {
            var key = _a[0], value = _a[1];
            return (React.createElement("div", { key: key }, value ? (React.createElement(semantic_ui_react_1.Icon, { color: "green", name: "check circle" })) : (React.createElement(semantic_ui_react_1.Icon, { color: "red", name: "times circle" }))));
        })));
    };
    ShoprenterAppsListScreen.prototype.renderItem = function (ctx, item, index) {
        var _a;
        var _b = item.shop, _id = _b._id, title = _b.title, _c = _b.miniCrmSettings, contactFirstName = _c.contactFirstName, contactLastName = _c.contactLastName, contactEmail = _c.contactEmail, contactPhone = _c.contactPhone, url = _b.url, _d = item.status, status = _d.status, time = _d.time, latestShopFee = item.latestShopFee;
        var latestShopFeeCellContent = ((_a = latestShopFee === null || latestShopFee === void 0 ? void 0 : latestShopFee.latestShopFee) === null || _a === void 0 ? void 0 : _a.subscribers) ? "" + latestShopFee.latestShopFee.subscribers : "Nincs adat";
        return React.createElement(semantic_ui_react_1.Table.Row, { key: _id + index, verticalAlign: "middle", style: { cursor: 'pointer' }, onClick: function () { return router_provider_1.RouterProvider.goTo("/admin/shoprenter-apps/" + _id); } },
            React.createElement(semantic_ui_react_1.Table.Cell, null, _id),
            React.createElement(semantic_ui_react_1.Table.Cell, null, title),
            React.createElement(semantic_ui_react_1.Table.Cell, null, url),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                contactFirstName,
                " ",
                contactLastName,
                React.createElement("br", null),
                contactEmail,
                React.createElement("br", null),
                contactPhone),
            React.createElement(semantic_ui_react_1.Table.Cell, null, latestShopFeeCellContent),
            React.createElement(semantic_ui_react_1.Table.Cell, null, status),
            React.createElement(semantic_ui_react_1.Table.Cell, null, moment(time).format('YYYY.MM.DD. HH:mm')));
    };
    ShoprenterAppsListScreen.prototype.renderOperatingItem = function (ctx, item, index) {
        var _a;
        var _b = item.shop, _id = _b._id, title = _b.title, _c = _b.miniCrmSettings, contactFirstName = _c.contactFirstName, contactLastName = _c.contactLastName, contactEmail = _c.contactEmail, contactPhone = _c.contactPhone, url = _b.url, dashboard = item.dashboard, atLeast10TemplatesApproved = item.atLeast10TemplatesApproved, latestShopFee = item.latestShopFee;
        var latestShopFeeCellContent = ((_a = latestShopFee === null || latestShopFee === void 0 ? void 0 : latestShopFee.latestShopFee) === null || _a === void 0 ? void 0 : _a.subscribers) ? "" + latestShopFee.latestShopFee.subscribers : "Nincs adat";
        return React.createElement(semantic_ui_react_1.Table.Row, { key: _id + index, verticalAlign: "middle", style: { cursor: 'pointer' }, onClick: function () { return router_provider_1.RouterProvider.goTo("/admin/shoprenter-apps/" + _id); } },
            React.createElement(semantic_ui_react_1.Table.Cell, null, title),
            React.createElement(semantic_ui_react_1.Table.Cell, null, url),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                contactFirstName,
                " ",
                contactLastName,
                React.createElement("br", null),
                contactEmail,
                React.createElement("br", null),
                contactPhone),
            React.createElement(semantic_ui_react_1.Table.Cell, null, latestShopFeeCellContent),
            React.createElement(semantic_ui_react_1.Table.Cell, { title: dashboard.webShopDataSavedAt ? moment(dashboard.webShopDataSavedAt).format('YYYY.MM.DD. HH:mm:ss') : '' }, dashboard.webShopDataSaved ? checkIcon : crossIcon),
            React.createElement(semantic_ui_react_1.Table.Cell, { title: dashboard.productSyncedAt ? moment(dashboard.productSyncedAt).format('YYYY.MM.DD. HH:mm:ss') : '' }, dashboard.productSynced ? checkIcon : crossIcon),
            React.createElement(semantic_ui_react_1.Table.Cell, { title: dashboard.webShopScriptsInsertedAt ? moment(dashboard.webShopScriptsInsertedAt).format('YYYY.MM.DD. HH:mm:ss') : '' }, dashboard.webShopScriptsInserted ? checkIcon : crossIcon),
            React.createElement(semantic_ui_react_1.Table.Cell, { title: dashboard.listCleanedAt ? moment(dashboard.listCleanedAt).format('YYYY.MM.DD. HH:mm:ss') : '' }, dashboard.listCleaned ? checkIcon : crossIcon),
            React.createElement(semantic_ui_react_1.Table.Cell, { title: dashboard.dnsSetupAt ? moment(dashboard.dnsSetupAt).format('YYYY.MM.DD. HH:mm:ss') : '' }, dashboard.dnsSetup ? checkIcon : crossIcon),
            React.createElement(semantic_ui_react_1.Table.Cell, { title: dashboard.profileSetupAt ? moment(dashboard.profileSetupAt).format('YYYY.MM.DD. HH:mm:ss') : '' }, dashboard.profileSetup ? checkIcon : crossIcon),
            React.createElement(semantic_ui_react_1.Table.Cell, null, atLeast10TemplatesApproved ? checkIcon : crossIcon),
            React.createElement(semantic_ui_react_1.Table.Cell, { title: dashboard.autoMailUpAndRunningAt ? moment(dashboard.autoMailUpAndRunningAt).format('YYYY.MM.DD. HH:mm:ss') : '' }, dashboard.autoMailUpAndRunning ? checkIcon : crossIcon));
    };
    ShoprenterAppsListScreen.prototype.renderArciveItem = function (ctx, item, index) {
        var _a;
        var _id = item._id, title = item.title, url = item.url, email = item.email, phone = item.phone, firstName = item.firstName, lastName = item.lastName, createdAt = item.createdAt, latestShopFee = item.latestShopFee;
        var latestShopFeeCellContent = ((_a = latestShopFee === null || latestShopFee === void 0 ? void 0 : latestShopFee.latestShopFee) === null || _a === void 0 ? void 0 : _a.subscribers) ? "" + latestShopFee.latestShopFee.subscribers : "Nincs adat";
        return React.createElement(semantic_ui_react_1.Table.Row, { key: _id + index, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, null, index + 1),
            React.createElement(semantic_ui_react_1.Table.Cell, null, title !== null && title !== void 0 ? title : ''),
            React.createElement(semantic_ui_react_1.Table.Cell, null, url !== null && url !== void 0 ? url : ''),
            React.createElement(semantic_ui_react_1.Table.Cell, null, lastName !== null && lastName !== void 0 ? lastName : '',
                " ", firstName !== null && firstName !== void 0 ? firstName : '',
                React.createElement("br", null), email !== null && email !== void 0 ? email : '',
                React.createElement("br", null), phone !== null && phone !== void 0 ? phone : ''),
            React.createElement(semantic_ui_react_1.Table.Cell, null, latestShopFeeCellContent),
            React.createElement(semantic_ui_react_1.Table.Cell, null, moment(createdAt).format('YYYY.MM.DD. HH:mm')));
    };
    ShoprenterAppsListScreen.prototype.render = function () {
        return React.createElement("div", { className: "SettingsScreen display-flex flex-row" },
            React.createElement("div", { className: 'SettingsMenuWrapper' },
                React.createElement(semantic_ui_react_1.Header, { as: 'h4', className: 'mb-4' }, trans_1.trans('shop-settings-menu.title')),
                this.renderSettingsMenu()),
            React.createElement("div", { className: "flex-fill SettingsContentWrapper" },
                this.state.activeTabId === 'active-shops' && this.renderActiveShops(),
                this.state.activeTabId === 'all-shops' && this.renderAllShops(),
                this.state.activeTabId === 'registering-shops' && this.renderRegisteringShops(),
                this.state.activeTabId === 'installed-waiting-first-payment' && this.renderNotPaidNotDeletedShops(),
                this.state.activeTabId === 'uninstalled-before-first-payment' && this.renderNotPaidDeletedShops(),
                this.state.activeTabId === 'paid-deleted-shops' && this.renderPaidDeletedShops()));
    };
    ShoprenterAppsListScreen.prototype.renderSettingsMenu = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Menu, { secondary: true, vertical: true }, this.shoprenterAppsListMenu.map(function (m) { return _this.renderMenuItem(m); }));
    };
    ShoprenterAppsListScreen.prototype.renderMenuItem = function (m) {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Menu.Item, { className: 'MenuItem', active: m.identifier === this.state.activeTabId, onClick: function () { return _this.changeTab(m.identifier); }, content: this.renderItemContent(m) });
    };
    ShoprenterAppsListScreen.prototype.renderItemContent = function (item) {
        return React.createElement("div", { className: 'MenuItemContent' },
            React.createElement(semantic_ui_react_1.Icon, { name: item.icon, className: 'mr-4' }),
            React.createElement("span", null, trans_1.trans("shoprenter-apps-list-menu.item." + item.name)));
    };
    ShoprenterAppsListScreen.prototype.changeTab = function (tabId) {
        this.setState({ activeTabId: tabId });
    };
    ShoprenterAppsListScreen.prototype.renderTabContent = function () {
        var active = this.state.activeTabId;
        switch (active) {
            case 'all-shops':
                return this.render();
            case 'deleted-shops':
                return this.renderDeletedShops();
            default:
                return this.renderAllShops();
        }
    };
    ShoprenterAppsListScreen.prototype.renderActiveShops = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var url = new URL(SHOPRENTER_API_ROOT_URL + "/marketplace/shops/active");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3', style: { marginLeft: '16px' } }, "Akt\u00EDv el\u0151fizet\u0151k"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h5', style: { marginLeft: '16px' } }, "Itt l\u00E1that\u00F3k azok a webshopok, amelyek sikeresen v\u00E9gigmentek a telep\u00EDt\u00E9si folyamaton \u00E9s legal\u00E1bb egyszer minden elv\u00E1r\u00E1s teljes\u00FClt a levelez\u00E9s m\u0171k\u00F6d\u00E9s\u00E9hez. (Megj.: az\u00F3ta viszont el\u0151\u00E1llhatott a m\u0171k\u00F6d\u00E9st akad\u00E1lyoz\u00F3 probl\u00E9ma, pl. DNS be\u00E1ll\u00EDt\u00E1sok m\u00F3dosul\u00E1sa stb.)")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: url.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 12 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 12 })); }, renderItemsWrapper: function (ctx) { return _this.renderOperatingItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderOperatingItem(ctx, item, index); } }))));
    };
    ShoprenterAppsListScreen.prototype.renderAllShops = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var url = new URL(SHOPRENTER_API_ROOT_URL + "/dashboard/shops");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3', style: { marginLeft: '16px' } }, "\u00D6sszes app"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h5', style: { marginLeft: '16px' } }, "\u00C1llapott\u00F3l f\u00FCggetlen\u00FCl minden webshop, amelyek a Shoprenteren kereszt\u00FCl ker\u00FCltek telep\u00EDt\u00E9sre.")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: url.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 7 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 7 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }))));
    };
    ShoprenterAppsListScreen.prototype.renderDeletedShops = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var archivesUrl = new URL(SHOPRENTER_API_ROOT_URL + "/marketplace/shops/archives");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3', style: { marginLeft: '16px' } }, "T\u00F6r\u00F6lt app-ok")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: archivesUrl.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 6 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 6 })); }, renderItemsWrapper: function (ctx) { return _this.renderArchiveItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderArciveItem(ctx, item, index); } }))));
    };
    ShoprenterAppsListScreen.prototype.renderRegisteringShops = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var registeringUrl = new URL(SHOPRENTER_API_ROOT_URL + "/marketplace/shops/registering");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3', style: { marginLeft: '16px' } }, "Telep\u00EDt\u00E9s alatt \u00E1ll\u00F3 shop-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h5', style: { marginLeft: '16px' } }, "Azok a webshopok, amelyek az els\u0151 havid\u00EDjat sikeresen befizett\u00E9k, majd a be\u00E1ll\u00EDt\u00E1st megkezdt\u00E9k, de m\u00E9g nem v\u00E9geztek el minden sz\u00FCks\u00E9ges m\u00F3dos\u00EDt\u00E1st az automatikus levelez\u00E9s elindul\u00E1s\u00E1hoz.")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: registeringUrl.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 12 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 12 })); }, renderItemsWrapper: function (ctx) { return _this.renderOperatingItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderOperatingItem(ctx, item, index); } }))));
    };
    ShoprenterAppsListScreen.prototype.renderNotPaidNotDeletedShops = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var notPaidNotDeletedUrl = new URL(SHOPRENTER_API_ROOT_URL + "/marketplace/shops/installed-not-paid");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3', style: { marginLeft: '16px' } }, "Fizet\u00E9skor lemorzsol\u00F3dott - nem t\u00F6r\u00F6lt shop-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h5', style: { marginLeft: '16px' } }, "Azon webshopok, amelyek a telep\u00EDt\u00E9st elind\u00EDtott\u00E1k, de a kalkul\u00E1lt els\u0151 havid\u00EDjat m\u00E9g nem fizett\u00E9k be.")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: notPaidNotDeletedUrl.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 7 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 7 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }))));
    };
    ShoprenterAppsListScreen.prototype.renderNotPaidDeletedShops = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var notPaidDeletedUrl = new URL(SHOPRENTER_API_ROOT_URL + "/marketplace/shops/installed-not-paid-deleted");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3', style: { marginLeft: '16px' } }, "Fizet\u00E9skor lemorzsol\u00F3dott - t\u00F6r\u00F6lt shop-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h5', style: { marginLeft: '16px' } }, "Azok a webshopok, amelyek a telep\u00EDt\u00E9st elind\u00EDtott\u00E1k, de a kalkul\u00E1lt els\u0151 havid\u00EDjat m\u00E9g nem fizett\u00E9k be, majd a Shoprenter admin fel\u00FCleten t\u00F6r\u00F6lt\u00E9k az alkalmaz\u00E1st.")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: notPaidDeletedUrl.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 6 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 6 })); }, renderItemsWrapper: function (ctx) { return _this.renderArchiveItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderArciveItem(ctx, item, index); } }))));
    };
    ShoprenterAppsListScreen.prototype.renderPaidDeletedShops = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var paidDeletedUrl = new URL(SHOPRENTER_API_ROOT_URL + "/marketplace/shops/installed-paid-deleted");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3', style: { marginLeft: '16px' } }, "Fizet\u00E9s ut\u00E1n t\u00F6r\u00F6lt shop-ok shop-ok"),
                                React.createElement(semantic_ui_react_1.Header, { as: 'h5', style: { marginLeft: '16px' } }, "Azok a webshopok, amelyek az els\u0151 havid\u00EDjat sikeresen befizett\u00E9k, majd a Shoprenter admin fel\u00FCleten t\u00F6r\u00F6lt\u00E9k az alkalmaz\u00E1st.")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: paidDeletedUrl.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 6 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 6 })); }, renderItemsWrapper: function (ctx) { return _this.renderArchiveItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderArciveItem(ctx, item, index); } }))));
    };
    ShoprenterAppsListScreen.prototype.renderOld = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
        var url = new URL(SHOPRENTER_API_ROOT_URL + "/dashboard/shops");
        var archivesUrl = new URL(SHOPRENTER_API_ROOT_URL + "/marketplace/shops/archives");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter Marketplace App-ok")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: url.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 5 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 5 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }),
                    React.createElement(semantic_ui_react_1.Divider, null),
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "T\u00F6r\u00F6lt App-ok")))),
                    React.createElement(repository_list_1.RepositoryList, { hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: archivesUrl.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 6 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 6 })); }, renderItemsWrapper: function (ctx) { return _this.renderArchiveItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderArciveItem(ctx, item, index); } }))));
    };
    return ShoprenterAppsListScreen;
}(abstract_component_1.AbstractComponent));
exports.ShoprenterAppsListScreen = ShoprenterAppsListScreen;
