"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailedError = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var DetailedError = (function (_super) {
    __extends(DetailedError, _super);
    function DetailedError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DetailedError.isNotFound = function (error) {
        return (error === null || error === void 0 ? void 0 : error.message) === 'NotFound';
    };
    DetailedError.prototype.render = function () {
        if (!this.props.error) {
            return null;
        }
        var isDetailed = this.isDetailed(this.props.error);
        return isDetailed
            ? this.renderDetailed(this.props.error)
            : this.renderSimple(this.props.error);
    };
    DetailedError.prototype.renderSimple = function (error) {
        var _a, _b;
        var description = (_a = this.props.description) !== null && _a !== void 0 ? _a : (error.message
            ? trans_1.trans("errors." + error.message)
            : trans_1.trans('detailed-error.default-description'));
        var title = (_b = this.props.title) !== null && _b !== void 0 ? _b : trans_1.trans('detailed-error.default-title');
        return React.createElement(semantic_ui_react_1.Segment, __assign({ color: this.getColor() }, this.props),
            React.createElement(semantic_ui_react_1.Header, { content: title, subheader: description, as: "h3" }));
    };
    DetailedError.prototype.renderDetailed = function (error) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var title = (_a = this.props.title) !== null && _a !== void 0 ? _a : (error.message
            ? trans_1.trans("errors." + error.message)
            : trans_1.trans('detailed-error.default-title'));
        var description = (_b = this.props.description) !== null && _b !== void 0 ? _b : trans_1.trans('detailed-error.default-description');
        var items = this.getFlattenErrors((_e = (_d = (_c = this.props.error) === null || _c === void 0 ? void 0 : _c.payload) === null || _d === void 0 ? void 0 : _d.errors) !== null && _e !== void 0 ? _e : []);
        var reasons = (_h = (_g = (_f = this.props.error) === null || _f === void 0 ? void 0 : _f.payload) === null || _g === void 0 ? void 0 : _g.reasons) !== null && _h !== void 0 ? _h : [];
        return React.createElement(semantic_ui_react_1.Segment, __assign({ color: this.getColor() }, this.props),
            React.createElement(semantic_ui_react_1.Header, { content: title, subheader: description, as: "h3" }),
            React.createElement(semantic_ui_react_1.List, { bulleted: true },
                items.map(function (item, i) { return _this.renderDetailedItem(item, i); }),
                reasons.map(function (item, i) { return _this.renderDetailedReasonItem(item, i); })));
    };
    DetailedError.prototype.renderDetailedItem = function (item, key) {
        return item.errors.map(function (error, index) { return React.createElement(semantic_ui_react_1.List.Item, { key: key + "-" + index }, trans_1.trans("" + error)); });
    };
    DetailedError.prototype.renderDetailedReasonItem = function (item, key) {
        return React.createElement(semantic_ui_react_1.List.Item, { key: key }, trans_1.trans(item));
    };
    DetailedError.prototype.isDetailed = function (error) {
        var _a, _b, _c, _d;
        return !!((_b = (_a = error === null || error === void 0 ? void 0 : error.payload) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) || !!((_d = (_c = error === null || error === void 0 ? void 0 : error.payload) === null || _c === void 0 ? void 0 : _c.reasons) === null || _d === void 0 ? void 0 : _d.length);
    };
    DetailedError.prototype.getFlattenErrors = function (errors, chain, results) {
        var _a, _b;
        if (chain === void 0) { chain = []; }
        if (results === void 0) { results = []; }
        for (var _i = 0, errors_1 = errors; _i < errors_1.length; _i++) {
            var item = errors_1[_i];
            var subChain = __spreadArrays(chain, [item.property]);
            if ((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) {
                this.getFlattenErrors(item === null || item === void 0 ? void 0 : item.children, subChain, results);
            }
            else {
                results.push({ property: subChain.join('.'), errors: Object.values((_b = item.constraints) !== null && _b !== void 0 ? _b : {}) });
            }
        }
        return results;
    };
    DetailedError.prototype.getColor = function () {
        var _a;
        return (_a = this.props.color) !== null && _a !== void 0 ? _a : 'red';
    };
    return DetailedError;
}(abstract_component_1.AbstractComponent));
exports.DetailedError = DetailedError;
