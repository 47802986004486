"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCardPaymentSettings = void 0;
require("./billing.component.scss");
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var credit_card_component_1 = require("./credit-card.component");
var environment_1 = require("@codebuild/uikit/libs/environment");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var session_storage_1 = require("../../../../libs/session.storage");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var moment = require("moment");
var barion_full_pixel_1 = require("../../../barion/barion-full-pixel");
var CreditCardPaymentSettings = (function (_super) {
    __extends(CreditCardPaymentSettings, _super);
    function CreditCardPaymentSettings() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: _this.props.shop || session_storage_1.SessionStorage.getItem('SelectedShop'),
            loading: false,
            isCreditCardConfirmed: (_a = _this.props.shop) === null || _a === void 0 ? void 0 : _a.creditCardConfirmed,
            showConfirmationModal: false,
            isCreditCardLoading: false,
            creditCardError: false,
            paymentAszfConfirmed: false,
            automaticCreditCardPaymentsConsent: false,
        };
        return _this;
    }
    CreditCardPaymentSettings.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchShop()];
                    case 1:
                        _a.sent();
                        barion_full_pixel_1.sendContentViewMessage(barion_full_pixel_1.MONTHLY_SUBSCRIPTION_CONTENT_VIEW_PROPERTIES);
                        return [2];
                }
            });
        });
    };
    CreditCardPaymentSettings.prototype.fetchShopCreditCards = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shopId, baseUrl, creditCardsUrl, creditCards, lastCreditCard, error_1, errorMessage;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        shopId = (_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id;
                        baseUrl = environment_1.Environment.get('api');
                        creditCardsUrl = baseUrl + "/s/" + shopId + "/shop/bigfish/bank-cards?shopId=" + shopId;
                        this.setState({ isCreditCardLoading: true, creditCardError: false });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4, this.shop.get(creditCardsUrl)];
                    case 2:
                        creditCards = _b.sent();
                        console.log('fetchShopCreditCards', creditCards);
                        if (creditCards.length > 0) {
                            lastCreditCard = creditCards[creditCards.length - 1];
                            lastCreditCard.formattedExpirationDate = moment(lastCreditCard.PaymentDeviceExpiration).format('MM/YY');
                            lastCreditCard.formattedCardNumber = "**** **** **** " + lastCreditCard.PaymentDeviceNumber;
                            this.setState({ lastCreditCard: lastCreditCard });
                        }
                        return [3, 5];
                    case 3:
                        error_1 = _b.sent();
                        errorMessage = "Error from URL=" + creditCardsUrl;
                        this.setState({ creditCardError: true });
                        toast_util_1.openErrorToastWithMessage(errorMessage);
                        barion_full_pixel_1.sendErrorMessage("Cannot fetch registered credit cards");
                        return [3, 5];
                    case 4:
                        this.setState({ isCreditCardLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    CreditCardPaymentSettings.prototype.fetchShop = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.shop.get("/a/shop/" + this.state.shop._id.toString(), {})];
                    case 1:
                        shop = _a.sent();
                        this.setState({
                            shop: shop,
                            isCreditCardConfirmed: shop.creditCardConfirmed,
                            paymentAszfConfirmed: (shop === null || shop === void 0 ? void 0 : shop.paymentAszfConfirmed) || false,
                            automaticCreditCardPaymentsConsent: (shop === null || shop === void 0 ? void 0 : shop.automaticCreditCardPaymentsConsent) || false
                        });
                        if ((shop === null || shop === void 0 ? void 0 : shop.creditCardEnabled) && (shop === null || shop === void 0 ? void 0 : shop.creditCardConfirmed)) {
                            this.fetchShopCreditCards();
                        }
                        return [2];
                }
            });
        });
    };
    CreditCardPaymentSettings.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    CreditCardPaymentSettings.prototype.saveCreditCardConfirmation = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return __awaiter(this, void 0, void 0, function () {
            var paymentInitUrl, response, product, initiateCheckutProperties, addPaymentInfoProperties, initiatePurchaseProperties, error_2, errorMessage, paymentCancelUrl, error_3, errorMessage;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        this.setState({ loading: true });
                        if (!!this.state.isCreditCardConfirmed) return [3, 7];
                        paymentInitUrl = "/s/" + ((_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id) + "/shop/bigfish/payment-init?shopId=" + this.state.shop._id.toString();
                        _h.label = 1;
                    case 1:
                        _h.trys.push([1, 3, 4, 6]);
                        this.setState({ loading: true });
                        return [4, this.shop.put(paymentInitUrl, {})];
                    case 2:
                        response = _h.sent();
                        product = __assign({}, barion_full_pixel_1.MONTHLY_SUBSCRIPTION_CONTENT_VIEW_PROPERTIES);
                        if (response === null || response === void 0 ? void 0 : response.amount) {
                            product.unitPrice = response.amount;
                        }
                        barion_full_pixel_1.sendAddToCartMessage(product);
                        initiateCheckutProperties = __assign({}, barion_full_pixel_1.INITIATE_CHECKOUT_PROPERTIES);
                        if (response === null || response === void 0 ? void 0 : response.amount) {
                            initiateCheckutProperties.contents = [product];
                            initiateCheckutProperties.revenue = response.amount;
                        }
                        barion_full_pixel_1.sendInitiateCheckoutMessage(initiateCheckutProperties);
                        addPaymentInfoProperties = __assign({}, barion_full_pixel_1.ADD_PAYMENT_INFO_PROPERTIES);
                        if (response === null || response === void 0 ? void 0 : response.amount) {
                            addPaymentInfoProperties.contents = [product];
                        }
                        barion_full_pixel_1.sendInitiatePurchaseMessage(addPaymentInfoProperties);
                        initiatePurchaseProperties = __assign({}, barion_full_pixel_1.INITIATE_PURCHASE_PROPERTIES);
                        if (response === null || response === void 0 ? void 0 : response.amount) {
                            initiatePurchaseProperties.contents = [product];
                            initiatePurchaseProperties.revenue = response.amount;
                        }
                        barion_full_pixel_1.sendAddPaymentInfoMessage(initiatePurchaseProperties);
                        window.open(response.paymentUrl, '_self');
                        return [3, 6];
                    case 3:
                        error_2 = _h.sent();
                        errorMessage = "Error from URL=" + paymentInitUrl;
                        if (((_b = error_2.payload) === null || _b === void 0 ? void 0 : _b.resultCode) && ((_c = error_2.payload) === null || _c === void 0 ? void 0 : _c.details)) {
                            errorMessage = error_2.payload.resultCode + ": " + error_2.payload.details;
                        }
                        toast_util_1.openErrorToastWithMessage(errorMessage);
                        barion_full_pixel_1.sendErrorMessage("Cannot init payment");
                        return [3, 6];
                    case 4:
                        this.setState({ loading: false });
                        return [4, this.fetchShop()];
                    case 5:
                        _h.sent();
                        return [7];
                    case 6: return [3, 13];
                    case 7:
                        paymentCancelUrl = "/s/" + ((_d = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _d === void 0 ? void 0 : _d._id) + "/shop/bigfish/payment-cancel?shopId=" + this.state.shop._id.toString() + "&transactionId=" + this.state.shop.creditCards[0].ReferenceTransactionId;
                        _h.label = 8;
                    case 8:
                        _h.trys.push([8, 10, 11, 13]);
                        this.setState({ loading: true });
                        return [4, this.shop.delete(paymentCancelUrl, {})];
                    case 9:
                        _h.sent();
                        return [3, 13];
                    case 10:
                        error_3 = _h.sent();
                        errorMessage = "Error from URL=" + paymentCancelUrl;
                        if (((_e = error_3.payload) === null || _e === void 0 ? void 0 : _e.resultCode) && ((_f = error_3.payload) === null || _f === void 0 ? void 0 : _f.details)) {
                            errorMessage = error_3.payload.resultCode + ": " + error_3.payload.details;
                        }
                        toast_util_1.openErrorToastWithMessage(errorMessage);
                        barion_full_pixel_1.sendErrorMessage("Cannot delete registered credit card");
                        return [3, 13];
                    case 11:
                        this.setState({ loading: false, showConfirmationModal: false });
                        return [4, this.fetchShop()];
                    case 12:
                        _h.sent();
                        if ((_g = this.props) === null || _g === void 0 ? void 0 : _g.onSuccessfulUpdate) {
                            this.props.onSuccessfulUpdate();
                        }
                        return [7];
                    case 13: return [2];
                }
            });
        });
    };
    CreditCardPaymentSettings.prototype.replaceCard = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var paymentInitUrl, response, error_4, errorMessage;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        paymentInitUrl = "/s/" + ((_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id) + "/shop/bigfish/payment-init?shopId=" + this.state.shop._id.toString();
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, 4, 5]);
                        this.setState({ loading: true });
                        return [4, this.shop.put(paymentInitUrl, {})];
                    case 2:
                        response = _d.sent();
                        window.open(response.paymentUrl, '_self');
                        return [3, 5];
                    case 3:
                        error_4 = _d.sent();
                        errorMessage = "Error from URL=" + paymentInitUrl;
                        if (((_b = error_4.payload) === null || _b === void 0 ? void 0 : _b.resultCode) && ((_c = error_4.payload) === null || _c === void 0 ? void 0 : _c.details)) {
                            errorMessage = error_4.payload.resultCode + ": " + error_4.payload.details;
                        }
                        toast_util_1.openErrorToastWithMessage(errorMessage);
                        barion_full_pixel_1.sendErrorMessage("Cannot replace an existing credit card");
                        return [3, 5];
                    case 4:
                        this.setState({ loading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    CreditCardPaymentSettings.prototype.updateShopCreditCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            var baseUrl, updateShopUrl, updatedShop, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        baseUrl = environment_1.Environment.get('api');
                        updateShopUrl = baseUrl + "/s/" + this.state.shop._id.toString() + "/shop";
                        return [4, this.shop.put(updateShopUrl, {
                                data: {
                                    creditCardConfirmed: !this.state.isCreditCardConfirmed,
                                }
                            })];
                    case 1:
                        updatedShop = _a.sent();
                        this.props.onSuccessfulUpdate();
                        toast_util_1.openSuccessToast('A választásod mentésre került!');
                        this.setState({
                            isCreditCardConfirmed: updatedShop.creditCardConfirmed,
                            showConfirmationModal: false
                        });
                        return [3, 4];
                    case 2:
                        error_5 = _a.sent();
                        toast_util_1.openErrorToastWithMessage('Hiba történt a mentés során!');
                        barion_full_pixel_1.sendErrorMessage("Cannot update shop credit card");
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    CreditCardPaymentSettings.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (!((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.creditCardEnabled)) {
            return (React.createElement("p", null, "A bankk\u00E1rty\u00E1s fizet\u00E9s j\u00F3v\u00E1hagy\u00E1s\u00E1hoz el\u0151sz\u00F6r az adminisztr\u00E1tornak enged\u00E9lyeznie kell a bankk\u00E1rty\u00E1s fizet\u00E9st a webshop sz\u00E1m\u00E1ra. Ezzel kapcsolatban k\u00E9rlek, keresd az \u00FCgyf\u00E9lszolg\u00E1latot."));
        }
        return (React.createElement(React.Fragment, null,
            "Bankk\u00E1rty\u00E1s fizet\u00E9s st\u00E1tusza:",
            React.createElement("br", null),
            !((_b = this.state.shop) === null || _b === void 0 ? void 0 : _b.creditCardEnabled) ? (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "dont", size: "large" }),
                "Az adminisztr\u00E1tor m\u00E9g nem kapcsolta be a bankk\u00E1rty\u00E1s fizet\u00E9st.")) : (this.state.isCreditCardConfirmed ? (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Icon, { color: "green", name: "check circle", size: "large" }),
                "Meger\u0151s\u00EDtve.",
                React.createElement("br", null),
                React.createElement("br", null),
                this.state.isCreditCardLoading ? (React.createElement("p", null, "Regisztr\u00E1lt bankk\u00E1rtya bet\u00F6lt\u00E9se...")) :
                    this.state.creditCardError ? (React.createElement("p", null, "Nem siker\u00FClt a regisztr\u00E1lt bankk\u00E1rty\u00E1t bet\u00F6lteni.")) : (this.state.lastCreditCard && (React.createElement(credit_card_component_1.CreditCard, { cardHolderName: "CARD HOLDER", cardNumberWithLastFourDigits: this.state.lastCreditCard.formattedCardNumber, cardExpirationDate: this.state.lastCreditCard.formattedExpirationDate, cardType: this.state.lastCreditCard.PaymentDeviceBrand }))))) : (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Icon, { color: "yellow", name: "exclamation circle", size: "large" }),
                "Nincs bankk\u00E1rtya hozz\u00E1rendelve. Bankk\u00E1rtya megad\u00E1s\u00E1hoz k\u00E9rj\u00FCk kattints a \"Bankk\u00E1rtya hozz\u00E1rendel\u00E9se\" gombra."))),
            React.createElement("br", null),
            React.createElement("p", { style: {
                    marginTop: '8px'
                } },
                "Az ",
                React.createElement("b", null,
                    React.createElement("a", { href: "https://miclub.hu/aszf", target: "_blank" }, "\u00C1ltal\u00E1nos szerz\u0151d\u00E9si felt\u00E9telek (\u00C1SZF) ide kattintva")),
                " \u00E9rhet\u0151 el.",
                React.createElement("br", null),
                "Az ",
                React.createElement("b", null,
                    React.createElement("a", { href: "https://miclub.hu/adatvedelem", target: "_blank" }, "Adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3 ide kattintva")),
                " \u00E9rhet\u0151 el."),
            React.createElement(semantic_ui_react_1.Checkbox, { label: "Az \u00C1ltal\u00E1nos szerz\u0151d\u00E9si felt\u00E9telek (\u00C1SZF) \u00E9s az Adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3 tartalm\u00E1t megismertem \u00E9s elfogadom.", checked: this.state.paymentAszfConfirmed, onChange: function () {
                    _this.setState(function (prevState) {
                        var currentValue = !prevState.paymentAszfConfirmed;
                        if (currentValue) {
                            barion_full_pixel_1.sendGrantConsentMessage();
                        }
                        else {
                            barion_full_pixel_1.sendRejectConsentMessage();
                        }
                        return {
                            paymentAszfConfirmed: !prevState.paymentAszfConfirmed
                        };
                    });
                }, disabled: (_c = this.state.shop) === null || _c === void 0 ? void 0 : _c.paymentAszfConfirmed }),
            React.createElement("br", null),
            React.createElement(semantic_ui_react_1.Checkbox, { label: "Hozz\u00E1j\u00E1rulok az aktu\u00E1lis havi szolg\u00E1ltat\u00E1si d\u00EDj automatikus \u00E9s rendszeres levon\u00E1s\u00E1hoz a megadott bankk\u00E1rty\u00E1r\u00F3l.", checked: this.state.automaticCreditCardPaymentsConsent, onChange: function () {
                    _this.setState(function (prevState) {
                        return {
                            automaticCreditCardPaymentsConsent: !prevState.automaticCreditCardPaymentsConsent
                        };
                    });
                }, disabled: (_d = this.state.shop) === null || _d === void 0 ? void 0 : _d.automaticCreditCardPaymentsConsent }),
            React.createElement("br", null),
            React.createElement(semantic_ui_react_1.Button, { className: 'mt-4', disabled: this.state.loading ||
                    !((_e = this.state.shop) === null || _e === void 0 ? void 0 : _e.creditCardEnabled) ||
                    !this.state.paymentAszfConfirmed ||
                    !this.state.automaticCreditCardPaymentsConsent, loading: this.state.loading, onClick: function () {
                    _this.state.isCreditCardConfirmed ? _this.setState({ showConfirmationModal: true }) : _this.saveCreditCardConfirmation();
                }, content: "" + (this.state.isCreditCardConfirmed ? 'Bankkártyás fizetés törlése (szolgáltatás lemondása)' : 'Bankkártya hozzárendelése') }),
            React.createElement(semantic_ui_react_1.Button, { className: 'mt-4', disabled: this.state.loading
                    || !((_f = this.state.shop) === null || _f === void 0 ? void 0 : _f.creditCardEnabled)
                    || !((_g = this.state.shop) === null || _g === void 0 ? void 0 : _g.creditCardConfirmed)
                    || !((_j = (_h = this.state.shop) === null || _h === void 0 ? void 0 : _h.creditCards) === null || _j === void 0 ? void 0 : _j.length)
                    || !this.state.paymentAszfConfirmed
                    || !this.state.automaticCreditCardPaymentsConsent, loading: this.state.loading, onClick: function () { return _this.replaceCard(); }, content: 'Bankkártyacsere' }),
            React.createElement(semantic_ui_react_1.Modal, { onClose: function () { return _this.setState({ showConfirmationModal: false }); }, open: this.state.showConfirmationModal },
                React.createElement(semantic_ui_react_1.ModalHeader, null, "Bankk\u00E1rty\u00E1s fizet\u00E9s"),
                React.createElement(semantic_ui_react_1.ModalContent, null,
                    React.createElement(semantic_ui_react_1.ModalDescription, null,
                        React.createElement(semantic_ui_react_1.Header, null,
                            "Biztosan szeretn\u00E9d ",
                            React.createElement("strong", null, this.state.isCreditCardConfirmed ? 'kikapcsolni' : 'bekapcsolni'),
                            " a bankk\u00E1rty\u00E1s fizet\u00E9st?")),
                    "A bankk\u00E1rtya t\u00F6rl\u00E9s\u00E9vel az M.I. szolg\u00E1ltat\u00E1sa is megsz\u0171nik \u00E9s a rendszer v\u00E9g\u00E9rv\u00E9nyesen \u00E9s visszavonhatatlanul t\u00F6rl\u00E9sre ker\u00FCl a havi szolg\u00E1ltat\u00E1si id\u0151szak fordul\u00F3napj\u00E1t k\u00F6vet\u0151en. A fordul\u00F3napot a szolg\u00E1ltat\u00E1shoz kapcsol\u00F3d\u00F3 utols\u00F3 sz\u00E1ml\u00E1n fogod megtal\u00E1lni a \"t\u00E1rgy\" mez\u0151ben."),
                React.createElement(semantic_ui_react_1.ModalActions, null,
                    React.createElement(semantic_ui_react_1.Button, { color: "green", onClick: function () { return _this.setState({ showConfirmationModal: false }); } }, "M\u00E9gse"),
                    React.createElement(semantic_ui_react_1.Button, { content: "Meger\u0151s\u00EDtem a d\u00F6nt\u00E9semet!", color: "red", disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.saveCreditCardConfirmation(); } })))));
    };
    return CreditCardPaymentSettings;
}(abstract_component_1.AbstractComponent));
exports.CreditCardPaymentSettings = CreditCardPaymentSettings;
