"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuillWithImage = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var ReactQuill = require("react-quill");
var alignStyle = ReactQuill.Quill.import('attributors/style/align');
var boldStyle = ReactQuill.Quill.import('formats/bold');
var backgroundStyle = ReactQuill.Quill.import('attributors/style/background');
var colorStyle = ReactQuill.Quill.import('attributors/style/color');
var directionStyle = ReactQuill.Quill.import('attributors/style/direction');
var fontStyle = ReactQuill.Quill.import('attributors/style/font');
var sizeStyle = ReactQuill.Quill.import('attributors/style/size');
ReactQuill.Quill.register(alignStyle, true);
ReactQuill.Quill.register(backgroundStyle, true);
ReactQuill.Quill.register(colorStyle, true);
ReactQuill.Quill.register(directionStyle, true);
ReactQuill.Quill.register(fontStyle, true);
ReactQuill.Quill.register(sizeStyle, true);
boldStyle.tagName = 'B';
ReactQuill.Quill.register(boldStyle, true);
var QuillWithImage = (function (_super) {
    __extends(QuillWithImage, _super);
    function QuillWithImage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    QuillWithImage.prototype.componentDidMount = function () {
        var _this = this;
        this.editorRef.getEditor().getModule('toolbar').addHandler('image', function () { return _this.handleToolbarClick(); });
    };
    QuillWithImage.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement(ReactQuill.default, __assign(__assign({}, this.props), { className: ((_a = this.props.error) === null || _a === void 0 ? void 0 : _a.length) ? 'error' : '', ref: function (ref) { return (_this.editorRef = ref); } }));
    };
    QuillWithImage.prototype.handleToolbarClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var input;
            var _this = this;
            return __generator(this, function (_a) {
                input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.click();
                input.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                    var file, form, response, range;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                file = input.files.item(0);
                                form = new FormData();
                                form.append('data', file);
                                form.append('name', file.name);
                                return [4, repository_1.Repository.use('attachment').post('/attachment', {
                                        data: form
                                    })];
                            case 1:
                                response = _b.sent();
                                range = this.editorRef.getEditor().getSelection();
                                this.editorRef.getEditor().pasteHTML(range.index, "<img width=\"100%\" src=\"" + ((_a = response === null || response === void 0 ? void 0 : response.default) === null || _a === void 0 ? void 0 : _a.payload) + "\"></img>");
                                return [2];
                        }
                    });
                }); };
                return [2];
            });
        });
    };
    QuillWithImage.prototype.handleToolbarLink = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var href, range, selectedFragment;
            return __generator(this, function (_a) {
                if (value) {
                    href = prompt('Írd be a linket');
                    range = this.editorRef.getEditor().getSelection();
                    this.editorRef.getEditor().removeFormat(range.index, range.length);
                    selectedFragment = this.editorRef.getEditor().getContents(range.index, range.length).map(function (i) { return i.insert; }).join('');
                    this.editorRef.getEditor().deleteText(range.index, range.length);
                    this.editorRef.getEditor().pasteHTML(range.index, "<a href=\"" + href + "\"><font style=\"text-decoration: underline !important; color: blue !important;\">" + selectedFragment + "</font></a>");
                }
                return [2];
            });
        });
    };
    return QuillWithImage;
}(abstract_component_1.AbstractComponent));
exports.QuillWithImage = QuillWithImage;
