"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniCrmSettingsComponent = void 0;
var DatePicker = require("react-datepicker");
var React = require("react");
var moment = require("moment");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var billing_history_component_1 = require("./billing-history.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var semantic_ui_checkbox_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.checkbox.form-control-type");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var session_storage_1 = require("../../../../libs/session.storage");
var lodash_1 = require("lodash");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var MiniCrmSettingsComponent = (function (_super) {
    __extends(MiniCrmSettingsComponent, _super);
    function MiniCrmSettingsComponent() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        var _this = _super.apply(this, arguments) || this;
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: _this.props.shop,
            updateLoading: false,
            updateError: false,
            loading: false
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                customerId: new field_1.Field({
                    name: 'customerId',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.customerId.label'),
                    value: ((_b = (_a = _this.state.shop) === null || _a === void 0 ? void 0 : _a.miniCrmSettings) === null || _b === void 0 ? void 0 : _b.customerId) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                mainDataSheetId: new field_1.Field({
                    name: 'mainDataSheetId',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.mainDataSheetId.label'),
                    value: ((_d = (_c = _this.state.shop) === null || _c === void 0 ? void 0 : _c.miniCrmSettings) === null || _d === void 0 ? void 0 : _d.mainDataSheetId) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                discount: new field_1.Field({
                    name: 'discount',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.discount.label'),
                    value: ((_f = (_e = _this.state.shop) === null || _e === void 0 ? void 0 : _e.miniCrmSettings) === null || _f === void 0 ? void 0 : _f.discount) || 0,
                    validators: []
                }),
                miLastStarted: new field_1.Field({
                    name: 'miLastStarted',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.miLastStarted.label'),
                    value: ((_h = (_g = _this.state.shop) === null || _g === void 0 ? void 0 : _g.miniCrmSettings) === null || _h === void 0 ? void 0 : _h.miLastStarted) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                lastBillingDate: new field_1.Field({
                    name: 'lastBillingDate',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.lastBillingDate.label'),
                    value: ((_j = _this.state.shop) === null || _j === void 0 ? void 0 : _j.lastBillingDate) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                paymentDeadline: new field_1.Field({
                    name: 'paymentDeadline',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.paymentDeadline.label'),
                    value: ((_l = (_k = _this.state.shop) === null || _k === void 0 ? void 0 : _k.miniCrmSettings) === null || _l === void 0 ? void 0 : _l.paymentDeadline) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                maintenanceFee: new field_1.Field({
                    name: 'maintenanceFee',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.maintenanceFee.label'),
                    value: ((_o = (_m = _this.state.shop) === null || _m === void 0 ? void 0 : _m.miniCrmSettings) === null || _o === void 0 ? void 0 : _o.maintenanceFee) || 0,
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                maintenanceFeeEur: new field_1.Field({
                    name: 'maintenanceFeeEur',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.maintenanceFeeEur.label'),
                    value: ((_q = (_p = _this.state.shop) === null || _p === void 0 ? void 0 : _p.miniCrmSettings) === null || _q === void 0 ? void 0 : _q.maintenanceFeeEur) || 0,
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                billingCurrency: new field_1.Field({
                    name: 'billingCurrency',
                    placeholder: trans_1.trans('settings.miniCrmSettings.billingCurrency.placeholder'),
                    label: trans_1.trans('settings.miniCrmSettings.billingCurrency.label'),
                    value: ((_s = (_r = _this.state.shop) === null || _r === void 0 ? void 0 : _r.miniCrmSettings) === null || _s === void 0 ? void 0 : _s.billingCurrency) || 'HUF',
                    options: [
                        { value: 'EUR', text: 'Euró', key: 'EUR' },
                        { value: 'HUF', text: 'Forint', key: 'HUF' }
                    ],
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                billingPeriod: new field_1.Field({
                    name: 'billingPeriod',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.billingPeriod.label'),
                    value: ((_u = (_t = _this.state.shop) === null || _t === void 0 ? void 0 : _t.miniCrmSettings) === null || _u === void 0 ? void 0 : _u.billingPeriod) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                isReverseCharge: new field_1.Field({
                    name: 'isReverseCharge',
                    placeholder: '',
                    label: trans_1.trans('settings.miniCrmSettings.isReverseCharge.label'),
                    value: ((_w = (_v = _this.state.shop) === null || _v === void 0 ? void 0 : _v.miniCrmSettings) === null || _w === void 0 ? void 0 : _w.isReverseCharge) || '',
                    validators: []
                })
            }
        });
        return _this;
    }
    MiniCrmSettingsComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _this = this;
        if (!lodash_1.isEqual(this.props.shop, prevProps.shop)) {
            this.setState({ shop: this.props.shop }, function () { return _this.updateFormData(); });
        }
    };
    MiniCrmSettingsComponent.prototype.updateFormData = function () {
        var _this = this;
        lodash_1.map(this.form.fields, function (value, key) {
            _this.form.fields[key].setValue(_this.state.shop.miniCrmSettings[key]);
        });
    };
    MiniCrmSettingsComponent.prototype.render = function () {
        var _a;
        return React.createElement("div", { className: 'BillingComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, trans_1.trans('settings.miniCrmSettings.title')))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 }, this.renderMiniCrmSettingsForm())),
                React.createElement(semantic_ui_react_1.Grid.Row, null, React.createElement(billing_history_component_1.BillingHistoryComponent, { shop: (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop }))));
    };
    MiniCrmSettingsComponent.prototype.renderMiniCrmSettingsForm = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Form, null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.customerId, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { type: 'number', control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.mainDataSheetId, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { type: 'number', control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.discount, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { type: 'number', control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.maintenanceFee, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { type: 'number', control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.maintenanceFeeEur, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { type: 'number', control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.billingCurrency, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Dropdown, multiple: false, selection: true, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.miLastStarted, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.lastBillingDate, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.paymentDeadline, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { type: 'number', control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.billingPeriod, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { type: 'number', control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.isReverseCharge, type: semantic_ui_checkbox_form_control_type_1.SemanticUiCheckboxFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Checkbox, "data-cy": "isReverseCharge" })),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.handleSubmit(); }, content: 'Mentés' })));
    };
    MiniCrmSettingsComponent.prototype.handleSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, data, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        data = this.form.toJSON();
                        if (data.miLastStarted) {
                            data.miLastStarted = moment(data.miLastStarted).startOf('day').add(12, 'hours').toDate();
                        }
                        if (data.lastBillingDate) {
                            data.lastBillingDate = moment(data.lastBillingDate).startOf('day').add(12, 'hours').toDate();
                        }
                        data.customerId = +data.customerId;
                        data.mainDataSheetId = +data.mainDataSheetId;
                        data.paymentDeadline = +data.paymentDeadline;
                        data.maintenanceFee = +data.maintenanceFee;
                        data.maintenanceFeeEur = +data.maintenanceFeeEur;
                        data.billingPeriod = +data.billingPeriod;
                        data.discount = +data.discount;
                        data.isReverseCharge = data.isReverseCharge === true;
                        return [4, this.shop.put("/a/shop/" + this.props.shop._id + "/miniCrmSettings", { data: data })];
                    case 2:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success(trans_1.trans('Sikeres módosítás!'));
                        this.setState({ loading: false });
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.setState({ loading: false });
                        console.log(e_1);
                        handle_toasts_1.HandleToasts.error(trans_1.trans('Hiba történt!'));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    MiniCrmSettingsComponent.prototype.createBill = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.shop.put("/a/shop/" + this.props.shop._id + "/createBill", {})];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success(trans_1.trans('Sikeresen sikeresen kiállította a számlát!'));
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        handle_toasts_1.HandleToasts.error(trans_1.trans('A számlát nem sikerült kiállítani!'));
                        console.log(e_2);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    MiniCrmSettingsComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return MiniCrmSettingsComponent;
}(abstract_component_1.AbstractComponent));
exports.MiniCrmSettingsComponent = MiniCrmSettingsComponent;
