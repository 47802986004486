"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageUploader = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var handle_toasts_1 = require("../../libs/handle.toasts");
var state_repository_1 = require("../../libs/state-repository");
var ImageUploader = (function (_super) {
    __extends(ImageUploader, _super);
    function ImageUploader() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.upload = new state_repository_1.StateRepository((_a = _this.props.repository) !== null && _a !== void 0 ? _a : repository_1.Repository.use('attachment'), _this, 'upload');
        _this.state = {
            image: _this.props.value || null,
            showActions: false,
            error: false,
            loading: false
        };
        return _this;
    }
    ImageUploader.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            !this.state.image && this.renderUploadButton(),
            !!this.state.image && this.renderImagePreview(),
            React.createElement("input", { style: { position: 'absolute', padding: 0, opacity: 0, zIndex: -1, maxWidth: 0, maxHeight: 0 }, type: 'file', ref: function (ref) { return _this.fileInputRef = ref; }, onChange: (function (event) { return _this.uploadFile(event); }) }));
    };
    ImageUploader.prototype.renderUploadButton = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { type: "button", fluid: true, basic: true, loading: !!this.state.loading, icon: 'cloud upload', content: this.props.buttonTitle || 'Logó feltöltése', onClick: function () { return _this.fileInputRef.click(); } });
    };
    ImageUploader.prototype.renderImagePreview = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Segment, { style: { position: 'relative' }, onMouseOver: function () { return _this.showActions(); }, onMouseLeave: function () { return _this.hideActions(); } },
            this.renderLoading(),
            React.createElement(semantic_ui_react_1.Image, { centered: true, style: { maxWidth: '180px' }, fluid: false, src: this.state.image }),
            this.renderActions());
    };
    ImageUploader.prototype.renderLoading = function () {
        if (!this.state.loading) {
            return;
        }
        return React.createElement(semantic_ui_react_1.Dimmer, { inverted: true, active: true },
            React.createElement(semantic_ui_react_1.Loader, { inverted: true }));
    };
    ImageUploader.prototype.renderActions = function () {
        var _this = this;
        if (!this.state.showActions) {
            return;
        }
        return React.createElement("div", { style: {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                zIndex: 9
            }, className: 'display-flex flex-row justify-content-center align-items-center' },
            React.createElement(semantic_ui_react_1.Button, { size: 'small', icon: 'sync alternate', type: "button", content: 'Change', onClick: function () { return _this.fileInputRef.click(); } }),
            React.createElement(semantic_ui_react_1.Button, { color: 'red', size: 'small', type: "button", icon: 'trash alternate', content: 'Delete', onClick: function () { return _this.deleteFile(); } }));
    };
    ImageUploader.prototype.deleteFile = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({ image: null, loading: true })];
                    case 1:
                        _a.sent();
                        this.fileInputRef.value = null;
                        this.handleChange();
                        return [2];
                }
            });
        });
    };
    ImageUploader.prototype.uploadFile = function (event) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var files, file, vResult, form, response, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        event.preventDefault();
                        event.stopPropagation();
                        this.setState({ loading: true });
                        files = event.target.files;
                        file = files.item(0);
                        if (!file) {
                            return [2];
                        }
                        return [4, this.getValidationOfFile(file)];
                    case 1:
                        vResult = _b.sent();
                        console.log(vResult);
                        if (vResult) {
                            handle_toasts_1.HandleToasts.error(vResult, vResult);
                            this.setState({ loading: false, error: vResult });
                            return [2];
                        }
                        form = new FormData();
                        form.append('data', file);
                        form.append('name', file.name);
                        return [4, this.upload.post('/attachment', {
                                data: form
                            })];
                    case 2:
                        response = _b.sent();
                        if (!response) {
                            return [2];
                        }
                        return [4, this.setState({ image: (_a = response === null || response === void 0 ? void 0 : response.default) === null || _a === void 0 ? void 0 : _a.payload })];
                    case 3:
                        _b.sent();
                        this.handleChange();
                        return [3, 5];
                    case 4:
                        e_1 = _b.sent();
                        console.log(e_1);
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    ImageUploader.prototype.getValidationOfFile = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.props.maxHeight && !this.props.maxWidth) {
                    return [2, null];
                }
                return [2, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var allowedTypes, imageData, imageBlob, imgUrl, imageObj, e_2;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    allowedTypes = [
                                        'image/jpeg',
                                        'image/jpg',
                                        'image/png',
                                    ];
                                    if (!allowedTypes.includes(file.type)) {
                                        throw new Error();
                                    }
                                    return [4, file.arrayBuffer()];
                                case 1:
                                    imageData = _a.sent();
                                    imageBlob = new Blob([imageData]);
                                    imgUrl = URL.createObjectURL(imageBlob);
                                    imageObj = new Image();
                                    imageObj.src = imgUrl;
                                    imageObj.onload = function (event) {
                                        var _a, _b, _c, _d, _e, _f, _g, _h;
                                        if (_this.props.maxWidth) {
                                            if (((_b = (_a = event === null || event === void 0 ? void 0 : event.path) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.naturalWidth) > _this.props.maxWidth) {
                                                return resolve("A kiv\u00E1lasztott k\u00E9p t\u00FAl sz\u00E9les (maximum sz\u00E9less\u00E9g: " + _this.props.maxWidth + "px, jelenleg: " + ((_d = (_c = event === null || event === void 0 ? void 0 : event.path) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.naturalWidth) + "px)");
                                            }
                                        }
                                        if (_this.props.maxHeight) {
                                            if (((_f = (_e = event === null || event === void 0 ? void 0 : event.path) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.naturalHeight) > _this.props.maxHeight) {
                                                return resolve("A kiv\u00E1lasztott k\u00E9p t\u00FAl magass\u00E1ga (maximum magass\u00E1g: " + _this.props.maxHeight + "px, jelenleg: " + ((_h = (_g = event === null || event === void 0 ? void 0 : event.path) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.naturalHeight) + "px)");
                                            }
                                        }
                                        resolve(null);
                                    };
                                    return [3, 3];
                                case 2:
                                    e_2 = _a.sent();
                                    resolve('A kiválasztott fájl nem megfelelő');
                                    return [3, 3];
                                case 3: return [2];
                            }
                        });
                    }); })];
            });
        });
    };
    ImageUploader.prototype.handleChange = function () {
        if (this.props.onChange) {
            this.props.onChange({ target: { value: this.state.image } }, null);
        }
        this.setState({ loading: false });
    };
    ImageUploader.prototype.showActions = function () {
        this.setState({ showActions: true });
    };
    ImageUploader.prototype.hideActions = function () {
        this.setState({ showActions: false });
    };
    return ImageUploader;
}(abstract_component_1.AbstractComponent));
exports.ImageUploader = ImageUploader;
