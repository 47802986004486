"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteManagerToAllShopsScreen = void 0;
var environment_1 = require("@codebuild/uikit/libs/environment");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var axios_1 = require("axios");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("../libs/common.scss");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var errors_constant_1 = require("../../../shoprenter/errors/errors.constant");
var API_RESPONSE_TYPES = {
    INVITATION: "INVITATION",
    REMOVAL: "REMOVAL",
};
var InviteManagerToAllShopsScreen = (function (_super) {
    __extends(InviteManagerToAllShopsScreen, _super);
    function InviteManagerToAllShopsScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isLoading: false,
            isError: false,
            emailAddress: '',
            apiResponse: null,
            apiResponseType: null
        };
        return _this;
    }
    InviteManagerToAllShopsScreen.prototype.inviteManagerToAllShops = function (emailAddress) {
        return __awaiter(this, void 0, void 0, function () {
            var apiBaseUrl, inviteManagerToAllShopsResponse, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("inviteManagerToAllShops emailAddress=" + emailAddress);
                        this.setState({ isLoading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        apiBaseUrl = environment_1.Environment.get('api');
                        return [4, axios_1.default.post(apiBaseUrl + "/a/invite-manager-to-all-shops", {
                                email: emailAddress
                            }, {
                                headers: {
                                    Authorization: get_access_token_1.getAccessToken()
                                }
                            })];
                    case 2:
                        inviteManagerToAllShopsResponse = _a.sent();
                        console.log('inviteManagerToAllShopsResponse', inviteManagerToAllShopsResponse === null || inviteManagerToAllShopsResponse === void 0 ? void 0 : inviteManagerToAllShopsResponse.data);
                        this.setState({
                            apiResponse: (inviteManagerToAllShopsResponse === null || inviteManagerToAllShopsResponse === void 0 ? void 0 : inviteManagerToAllShopsResponse.data) || [],
                            isError: false,
                            apiResponseType: API_RESPONSE_TYPES.INVITATION
                        });
                        return [3, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('inviteManagerToAllShops error', error_1);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_INVITE_MANAGER_TO_ALL_SHOPS);
                        this.setState({ isError: true });
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    InviteManagerToAllShopsScreen.prototype.removeManagerFromAllShops = function (emailAddress) {
        return __awaiter(this, void 0, void 0, function () {
            var apiBaseUrl, removeManagerFromAllShopsResponse, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("removeManagerFromAllShops emailAddress=" + emailAddress);
                        this.setState({ isLoading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        apiBaseUrl = environment_1.Environment.get('api');
                        return [4, axios_1.default.post(apiBaseUrl + "/a/remove-manager-from-all-shops", {
                                email: emailAddress
                            }, {
                                headers: {
                                    Authorization: get_access_token_1.getAccessToken()
                                }
                            })];
                    case 2:
                        removeManagerFromAllShopsResponse = _a.sent();
                        console.log('removeManagerFromAllShopsResponse', removeManagerFromAllShopsResponse === null || removeManagerFromAllShopsResponse === void 0 ? void 0 : removeManagerFromAllShopsResponse.data);
                        this.setState({
                            apiResponse: (removeManagerFromAllShopsResponse === null || removeManagerFromAllShopsResponse === void 0 ? void 0 : removeManagerFromAllShopsResponse.data) || [],
                            isError: false,
                            apiResponseType: API_RESPONSE_TYPES.REMOVAL
                        });
                        return [3, 5];
                    case 3:
                        error_2 = _a.sent();
                        console.error('removeManagerFromAllShops error', error_2);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_INVITE_MANAGER_TO_ALL_SHOPS);
                        this.setState({ isError: true });
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    InviteManagerToAllShopsScreen.prototype.extractDatesFromFilename = function (a) {
        return a;
    };
    InviteManagerToAllShopsScreen.prototype.renderTableBody = function () {
        var _this = this;
        var _a;
        if (((_a = this.state.apiResponse) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return (React.createElement(semantic_ui_react_1.Table.Row, { verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, "Nincsen megjelen\u00EDtend\u0151 adat.")));
        }
        return this.state.apiResponse.map(function (shopWithData) {
            var isInvitation = _this.state.apiResponseType === API_RESPONSE_TYPES.INVITATION;
            var inviationOrRemovalValue = isInvitation ? shopWithData === null || shopWithData === void 0 ? void 0 : shopWithData.addedAsManager : shopWithData === null || shopWithData === void 0 ? void 0 : shopWithData.removedAsManager;
            var invitationOrRemovalText = isInvitation ?
                inviationOrRemovalValue ?
                    "Sikeresen hozzáadva kezelőként" :
                    "Nem sikerült hozzáadni kezelőként" :
                inviationOrRemovalValue ?
                    "Sikeresen eltávolítva" :
                    "Nem sikerült eltávolítani";
            return (React.createElement(semantic_ui_react_1.Table.Row, { key: shopWithData.shopId, verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, shopWithData.shopName),
                React.createElement(semantic_ui_react_1.Table.Cell, null, invitationOrRemovalText)));
        });
    };
    InviteManagerToAllShopsScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Kezel\u0151 megh\u00EDv\u00E1sa mindenhova"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, "Kezel\u0151 email c\u00EDme"),
                                React.createElement("input", { id: "emailAddress", name: "emailAddress", type: "email", onChange: function (event) { return _this.setState({ emailAddress: event.target.value }); }, value: this.state.emailAddress, style: { minWidth: "400px" } }),
                                React.createElement("br", null),
                                React.createElement(semantic_ui_react_1.Button, { loading: this.state.isLoading, disabled: this.state.isLoading, color: 'blue', onClick: function () { return _this.inviteManagerToAllShops(_this.state.emailAddress); }, style: { margin: '4px 0px' } }, "Kezel\u0151 hozz\u00E1ad\u00E1sa mindenhova"),
                                React.createElement("br", null),
                                React.createElement(semantic_ui_react_1.Button, { loading: this.state.isLoading, disabled: this.state.isLoading, color: 'blue', onClick: function () { return _this.removeManagerFromAllShops(_this.state.emailAddress); }, style: { margin: '4px 0px' } }, "Kezel\u0151 elt\u00E1vol\u00EDt\u00E1sa mindenhonnan"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                this.state.isLoading && (React.createElement("div", { className: 'p-8' },
                                    React.createElement(semantic_ui_react_1.Loader, { active: true }))),
                                this.state.isError && (React.createElement("div", { className: 'p-8' }, errors_constant_1.getErrorMessage(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_FETCH_GENERATED_REPORTS))),
                                this.state.apiResponse && this.state.apiResponseType && (React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
                                    React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                                        React.createElement(semantic_ui_react_1.Table.Row, null,
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                                                React.createElement("span", null, "Webshop")),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                                                React.createElement("span", null, this.state.apiResponseType === API_RESPONSE_TYPES.INVITATION ? "Hozzáadás" : "Eltávolítás")))),
                                    React.createElement(semantic_ui_react_1.Table.Body, { children: this.renderTableBody() })))))))));
    };
    return InviteManagerToAllShopsScreen;
}(abstract_component_1.AbstractComponent));
exports.InviteManagerToAllShopsScreen = InviteManagerToAllShopsScreen;
