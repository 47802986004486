"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerOrderStat = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var currency_parser_1 = require("../../../common/libs/currency-parser");
require("./lead-stats.scss");
var CustomerOrderStat = (function (_super) {
    __extends(CustomerOrderStat, _super);
    function CustomerOrderStat() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            stats: _this.props.stats || null
        };
        return _this;
    }
    CustomerOrderStat.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!lodash_1.isEqual(prevProps.stats, this.props.stats)) {
            this.setState({ stats: this.props.stats });
        }
    };
    CustomerOrderStat.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        return React.createElement(semantic_ui_react_1.Message, { className: 'LeadStat CustomerOrderStat' },
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(semantic_ui_react_1.Icon, { name: 'box' })),
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("div", { className: 'display-flex flex-column' },
                    React.createElement("span", { className: 'mb-1' }, "Eddigi \u00F6sszes rendel\u00E9s"),
                    React.createElement("h3", { className: 'mt-0' }, (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.stats) === null || _b === void 0 ? void 0 :
                        _b.totalOrderCountAll,
                        " rendel\u00E9s - ",
                        currency_parser_1.hufFormat((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.stats) === null || _d === void 0 ? void 0 : _d.totalOrderValueAll))),
                React.createElement("div", { className: 'display-flex flex-column' },
                    React.createElement("span", { className: 'mb-1' }, "Utols\u00F3 rendel\u00E9s"),
                    ((_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.stats) === null || _f === void 0 ? void 0 : _f.lastOrderDate) && React.createElement("div", null,
                        React.createElement("h3", { className: 'mt-0' },
                            ((_h = (_g = this.state) === null || _g === void 0 ? void 0 : _g.stats) === null || _h === void 0 ? void 0 : _h.lastOrderCount) || '-',
                            " t\u00E9tel - ",
                            currency_parser_1.hufFormat((_k = (_j = this.state) === null || _j === void 0 ? void 0 : _j.stats) === null || _k === void 0 ? void 0 : _k.lastOrderValue)),
                        React.createElement("span", null, moment((_m = (_l = this.state) === null || _l === void 0 ? void 0 : _l.stats) === null || _m === void 0 ? void 0 : _m.lastOrderDate).format('YYYY.MM.DD.'))),
                    !((_p = (_o = this.state) === null || _o === void 0 ? void 0 : _o.stats) === null || _p === void 0 ? void 0 : _p.lastOrderDate) && React.createElement("div", null, "-")),
                React.createElement("span", { className: 'cursor-pointer', color: 'blue', onClick: function () { return _this.handleFilter(); } },
                    React.createElement(semantic_ui_react_1.Icon, { name: 'filter' }),
                    "\u00D6sszes megtekint\u00E9se")));
    };
    CustomerOrderStat.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    CustomerOrderStat.prototype.handleFilter = function () {
        if (this.props.onFilter) {
            this.props.onFilter(['order']);
        }
    };
    return CustomerOrderStat;
}(responsive_component_1.ResponsiveComponent));
exports.CustomerOrderStat = CustomerOrderStat;
