"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonBlock = void 0;
var body = function (seed) { return ({
    "type": "fullwidth",
    "children": [{
            "type": "box",
            "children": [{
                    "type": "box",
                    "children": [{
                            "type": "button",
                            "attrs": { "text": "<p><strong>{{ Variable_Button_" + seed + "_Title }}</strong></p>\n", "sizeType": "FIT_TO_TEXT", "href": "{{ Variable_Button_" + seed + "_Link }}", "fullWidthOnMobile": true, "align": "center" },
                            "style": {
                                "line-height": "24px",
                                "color": "{{ Context_Shop_ButtonTextColor }}",
                                "font-size": "16px",
                                "font-family": "Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif",
                                "letter-spacing": "normal",
                                "background-color": "{{ Context_Shop_ButtonBackgroundColor }}",
                                "background-repeat": "no-repeat",
                                "background-position": "center center",
                                "background-image": "url()",
                                "border-radius": "5px",
                                "border-left-color": "#000000",
                                "border-left-style": "solid",
                                "border-left-width": "0px",
                                "border-bottom-color": "#000000",
                                "border-bottom-style": "solid",
                                "border-bottom-width": "0px",
                                "border-right-color": "#000000",
                                "border-right-style": "solid",
                                "border-right-width": "0px",
                                "border-top-color": "#000000",
                                "border-top-style": "solid",
                                "border-top-width": "0px",
                                "margin-left": "0px",
                                "margin-bottom": "0px",
                                "margin-right": "0px",
                                "margin-top": "0px",
                                "padding-left": "16px",
                                "padding-bottom": "12px",
                                "padding-right": "16px",
                                "padding-top": "12px"
                            }
                        }],
                    "attrs": { "hideOnMobile": false, "hideOnDesktop": false },
                    "style": {
                        "background-color": "",
                        "background-repeat": "no-repeat",
                        "background-position": "center center",
                        "background-image": "url()",
                        "border-radius": "0px",
                        "border-left-color": "#000000",
                        "border-left-style": "solid",
                        "border-left-width": "0px",
                        "border-bottom-color": "#000000",
                        "border-bottom-style": "solid",
                        "border-bottom-width": "0px",
                        "border-right-color": "#000000",
                        "border-right-style": "solid",
                        "border-right-width": "0px",
                        "border-top-color": "#000000",
                        "border-top-style": "solid",
                        "border-top-width": "0px",
                        "margin-left": "0px",
                        "margin-bottom": "0px",
                        "margin-right": "0px",
                        "margin-top": "0px",
                        "padding-left": "8px",
                        "padding-bottom": "0px",
                        "padding-right": "8px",
                        "padding-top": "0px"
                    }
                }],
            "attrs": { "hideOnMobile": false, "hideOnDesktop": false },
            "style": {
                "background-color": "#ffffff",
                "background-repeat": "no-repeat",
                "background-position": "center center",
                "background-image": "url()",
                "border-radius": "0px",
                "border-left-color": "#000000",
                "border-left-style": "solid",
                "border-left-width": "0px",
                "border-bottom-color": "#000000",
                "border-bottom-style": "solid",
                "border-bottom-width": "0px",
                "border-right-color": "#000000",
                "border-right-style": "solid",
                "border-right-width": "0px",
                "border-top-color": "#000000",
                "border-top-style": "solid",
                "border-top-width": "0px",
                "margin-left": "0px",
                "margin-bottom": "0px",
                "margin-right": "0px",
                "margin-top": "0px",
                "padding-left": "0px",
                "padding-bottom": "8px",
                "padding-right": "0px",
                "padding-top": "8px"
            }
        }],
    "placeholder": [], "attrs": { "hideOnMobile": false, "hideOnDesktop": false, "lock": false, "marker": "" }, "style": {
        "background-color": "",
        "background-repeat": "no-repeat",
        "background-position": "center center",
        "background-image": "url()",
        "content-background-color": "",
        "content-background-repeat": "no-repeat",
        "content-background-position": "center center",
        "content-background-image": "url()",
        "content-border-radius": "0px",
        "content-border-left-color": "#000000",
        "content-border-left-style": "solid",
        "content-border-left-width": "0px",
        "content-border-bottom-color": "#000000",
        "content-border-bottom-style": "solid",
        "content-border-bottom-width": "0px",
        "content-border-right-color": "#000000",
        "content-border-right-style": "solid",
        "content-border-right-width": "0px",
        "content-border-top-color": "#000000",
        "content-border-top-style": "solid",
        "content-border-top-width": "0px",
        "content-margin-left": "0px",
        "content-margin-bottom": "0px",
        "content-margin-right": "0px",
        "content-margin-top": "0px",
        "content-padding-left": "0px",
        "content-padding-bottom": "0px",
        "content-padding-right": "0px",
        "content-padding-top": "0px",
        "content-width": null,
        "body-width": 600
    }, "customData": {}
}); };
var placeholder = function (asd) { return [
    {
        "attrs": {
            "hideOnMobile": false,
            "hideOnDesktop": false
        },
        "style": {
            "background-color": "",
            "background-repeat": "no-repeat",
            "background-position": "center center",
            "background-image": "url()",
            "border-radius": "0px",
            "border-left-color": "#000000",
            "border-left-style": "solid",
            "border-left-width": "0px",
            "border-bottom-color": "#000000",
            "border-bottom-style": "solid",
            "border-bottom-width": "0px",
            "border-right-color": "#000000",
            "border-right-style": "solid",
            "border-right-width": "0px",
            "border-top-color": "#000000",
            "border-top-style": "solid",
            "border-top-width": "0px",
            "margin-left": "0px",
            "margin-bottom": "0px",
            "margin-right": "0px",
            "margin-top": "0px",
            "padding-left": "0px",
            "padding-bottom": "0px",
            "padding-right": "0px",
            "padding-top": "0px"
        },
        "type": "box",
        "children": [
            {
                "attrs": {
                    "mini": false,
                    "sizeType": "FIXED",
                    "link": "",
                    "lockDimensions": {
                        "width": 300,
                        "height": 300
                    },
                    "altText": "",
                    "fullWidthOnMobile": false,
                    "align": "center"
                },
                "style": {
                    "max-height": "448px",
                    "max-width": "1200px",
                    "src": "https://v3.miclub.hu/assets/images/Button@2x.png",
                    "width": "600px",
                    "background-color": "",
                    "background-repeat": "no-repeat",
                    "background-position": "center center",
                    "background-image": "url()",
                    "border-radius": "0px",
                    "border-left-color": "#000000",
                    "border-left-style": "solid",
                    "border-left-width": "0px",
                    "border-bottom-color": "#000000",
                    "border-bottom-style": "solid",
                    "border-bottom-width": "0px",
                    "border-right-color": "#000000",
                    "border-right-style": "solid",
                    "border-right-width": "0px",
                    "border-top-color": "#000000",
                    "border-top-style": "solid",
                    "border-top-width": "0px",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                    "margin-right": "0px",
                    "margin-top": "0px",
                    "padding-left": "0px",
                    "padding-bottom": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px"
                },
                "type": "image"
            }
        ]
    }
]; };
var buttonBlock = function (seed) { return ({
    title: "Gomb - " + seed,
    body: body(seed),
    placeholder: placeholder(seed)
}); };
exports.buttonBlock = buttonBlock;
