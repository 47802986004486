"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bannerBlock = void 0;
var body = function (seed) { return ({
    "type": "fullwidth",
    "children": [
        {
            type: 'conditional',
            attrs: {
                expression: 'Context_Shop_Banner'
            },
            children: [
                {
                    "attrs": {
                        "hideOnMobile": false,
                        "hideOnDesktop": false
                    },
                    "style": {
                        "background-color": "",
                        "background-repeat": "no-repeat",
                        "background-position": "center center",
                        "background-image": "url()",
                        "border-radius": "0px",
                        "border-left-color": "#000000",
                        "border-left-style": "solid",
                        "border-left-width": "0px",
                        "border-bottom-color": "#000000",
                        "border-bottom-style": "solid",
                        "border-bottom-width": "0px",
                        "border-right-color": "#000000",
                        "border-right-style": "solid",
                        "border-right-width": "0px",
                        "border-top-color": "#000000",
                        "border-top-style": "solid",
                        "border-top-width": "0px",
                        "margin-left": "0px",
                        "margin-bottom": "0px",
                        "margin-right": "0px",
                        "margin-top": "0px",
                        "padding-left": "0px",
                        "padding-bottom": "0px",
                        "padding-right": "0px",
                        "padding-top": "0px"
                    },
                    "type": "box",
                    "children": [
                        {
                            "attrs": {
                                "mini": false,
                                "sizeType": "FIXED",
                                "link": "{{ Context_Shop_BannerLink }}",
                                "lockDimensions": {
                                    "width": 300,
                                    "height": 300
                                },
                                "altText": "",
                                "fullWidthOnMobile": false,
                                "align": "center"
                            },
                            "style": {
                                "max-height": "448px",
                                "max-width": "1200px",
                                "src": "{{ Context_Shop_Banner }}",
                                "width": "600px",
                                "background-color": "",
                                "background-repeat": "no-repeat",
                                "background-position": "center center",
                                "background-image": "url()",
                                "border-radius": "0px",
                                "border-left-color": "#000000",
                                "border-left-style": "solid",
                                "border-left-width": "0px",
                                "border-bottom-color": "#000000",
                                "border-bottom-style": "solid",
                                "border-bottom-width": "0px",
                                "border-right-color": "#000000",
                                "border-right-style": "solid",
                                "border-right-width": "0px",
                                "border-top-color": "#000000",
                                "border-top-style": "solid",
                                "border-top-width": "0px",
                                "margin-left": "0px",
                                "margin-bottom": "0px",
                                "margin-right": "0px",
                                "margin-top": "0px",
                                "padding-left": "0px",
                                "padding-bottom": "0px",
                                "padding-right": "0px",
                                "padding-top": "0px"
                            },
                            "type": "dynamic-image"
                        }
                    ]
                }
            ],
            style: {}
        }
    ], "placeholder": [], "attrs": { "hideOnMobile": false, "hideOnDesktop": false, "lock": false, "marker": "" }, "style": {
        "background-color": "",
        "background-repeat": "no-repeat",
        "background-position": "center center",
        "background-image": "url()",
        "content-background-color": "",
        "content-background-repeat": "no-repeat",
        "content-background-position": "center center",
        "content-background-image": "url()",
        "content-border-radius": "0px",
        "content-border-left-color": "#000000",
        "content-border-left-style": "solid",
        "content-border-left-width": "0px",
        "content-border-bottom-color": "#000000",
        "content-border-bottom-style": "solid",
        "content-border-bottom-width": "0px",
        "content-border-right-color": "#000000",
        "content-border-right-style": "solid",
        "content-border-right-width": "0px",
        "content-border-top-color": "#000000",
        "content-border-top-style": "solid",
        "content-border-top-width": "0px",
        "content-margin-left": "0px",
        "content-margin-bottom": "0px",
        "content-margin-right": "0px",
        "content-margin-top": "0px",
        "content-padding-left": "0px",
        "content-padding-bottom": "0px",
        "content-padding-right": "0px",
        "content-padding-top": "0px",
        "content-width": null,
        "body-width": 600
    }, "customData": {}
}); };
var placeholder = function (asd) { return [
    {
        "attrs": {
            "hideOnMobile": false,
            "hideOnDesktop": false
        },
        "style": {
            "background-color": "",
            "background-repeat": "no-repeat",
            "background-position": "center center",
            "background-image": "url()",
            "border-radius": "0px",
            "border-left-color": "#000000",
            "border-left-style": "solid",
            "border-left-width": "0px",
            "border-bottom-color": "#000000",
            "border-bottom-style": "solid",
            "border-bottom-width": "0px",
            "border-right-color": "#000000",
            "border-right-style": "solid",
            "border-right-width": "0px",
            "border-top-color": "#000000",
            "border-top-style": "solid",
            "border-top-width": "0px",
            "margin-left": "0px",
            "margin-bottom": "0px",
            "margin-right": "0px",
            "margin-top": "0px",
            "padding-left": "0px",
            "padding-bottom": "0px",
            "padding-right": "0px",
            "padding-top": "0px"
        },
        "type": "box",
        "children": [
            {
                "attrs": {
                    "mini": false,
                    "sizeType": "FIXED",
                    "link": "",
                    "lockDimensions": {
                        "width": 300,
                        "height": 300
                    },
                    "altText": "",
                    "fullWidthOnMobile": false,
                    "align": "center"
                },
                "style": {
                    "max-height": "448px",
                    "max-width": "1200px",
                    "src": "https://v3.miclub.hu/assets/images/Banner@2x.png",
                    "width": "600px",
                    "background-color": "",
                    "background-repeat": "no-repeat",
                    "background-position": "center center",
                    "background-image": "url()",
                    "border-radius": "0px",
                    "border-left-color": "#000000",
                    "border-left-style": "solid",
                    "border-left-width": "0px",
                    "border-bottom-color": "#000000",
                    "border-bottom-style": "solid",
                    "border-bottom-width": "0px",
                    "border-right-color": "#000000",
                    "border-right-style": "solid",
                    "border-right-width": "0px",
                    "border-top-color": "#000000",
                    "border-top-style": "solid",
                    "border-top-width": "0px",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                    "margin-right": "0px",
                    "margin-top": "0px",
                    "padding-left": "0px",
                    "padding-bottom": "0px",
                    "padding-right": "0px",
                    "padding-top": "0px"
                },
                "type": "image"
            }
        ]
    }
]; };
var bannerBlock = function (seed) { return ({
    title: "Banner - " + seed,
    body: body(seed),
    placeholder: placeholder(seed)
}); };
exports.bannerBlock = bannerBlock;
