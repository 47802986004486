"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordStrength = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./password-strength.scss");
var PasswordStrength = (function (_super) {
    __extends(PasswordStrength, _super);
    function PasswordStrength() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            strength: {
                percent: 0,
                color: null,
                label: null
            }
        };
        return _this;
    }
    PasswordStrength.prototype.componentDidMount = function () {
        this.setStrength();
    };
    PasswordStrength.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setStrength();
        }
    };
    PasswordStrength.prototype.render = function () {
        return this.renderStrength();
    };
    PasswordStrength.prototype.renderStrength = function () {
        return React.createElement("div", { className: "PasswordStrengthContainer" },
            React.createElement(semantic_ui_react_1.Progress, { className: 'PasswordStrengthIndicator', size: 'tiny', percent: this.state.strength.percent, color: this.state.strength.color, label: this.state.strength.label ? trans_1.trans("general.password.strength.label." + this.state.strength.label) : '' }));
    };
    PasswordStrength.prototype.setStrength = function () {
        var _a;
        var value = (_a = this.props) === null || _a === void 0 ? void 0 : _a.value;
        if (!value || value.length === 0) {
            return this.setState({ strength: { percent: 0, color: 'red' } });
        }
        var isStrong = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/g);
        var isMedium = value.match(/^((?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[!@#\$%\^&\*])))(?=.{6,})/g);
        if (isStrong) {
            return this.setState({ strength: { percent: 100, color: 'green', label: 'strong' } });
        }
        if (isMedium && !isStrong) {
            return this.setState({ strength: { percent: 50, color: 'yellow', label: 'medium' } });
        }
        return this.setState({ strength: { percent: 25, color: 'red', label: 'weak' } });
    };
    return PasswordStrength;
}(abstract_component_1.AbstractComponent));
exports.PasswordStrength = PasswordStrength;
