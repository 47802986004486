"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomNewsletterRow = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var number_parser_1 = require("../../common/libs/number-parser");
var state_repository_1 = require("../../common/libs/state-repository");
require("./custom-newsletter-row.scss");
var CustomNewsletterRow = (function (_super) {
    __extends(CustomNewsletterRow, _super);
    function CustomNewsletterRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.performance = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'performance');
        return _this;
    }
    CustomNewsletterRow.prototype.componentDidMount = function () {
        var _a;
        _super.prototype.componentDidMount.call(this);
        this.performance.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/email-artifact/performance-2/" + this.props.item._id, {
            params: {
                from: moment().add(-10, 'years').toDate(),
                to: moment().toDate()
            }
        });
    };
    CustomNewsletterRow.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    CustomNewsletterRow.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: this.props.item._id, verticalAlign: "middle", onClick: function () { return _this.props.onClick(_this.props.item); } },
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("p", null, this.props.item.name),
                React.createElement("span", null, this.props.item.description)),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("div", { className: "w-100 display-flex justify-content-center align-items-center flex-column" },
                    ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.performance) === null || _b === void 0 ? void 0 : _b.loading) && React.createElement("h4", { className: "pb-0 mb-0" }, "-"),
                    !((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.performance) === null || _d === void 0 ? void 0 : _d.loading) &&
                        React.createElement("h4", { className: "pb-0 mb-0" }, number_parser_1.splitToNumberString((_h = (_g = (_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.performance) === null || _f === void 0 ? void 0 : _f.response) === null || _g === void 0 ? void 0 : _g.stats) === null || _h === void 0 ? void 0 : _h.sent)),
                    React.createElement("span", null, "Kik\u00FCldve"))),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("div", { className: "w-100 display-flex justify-content-center align-items-center flex-column" },
                    ((_k = (_j = this.state) === null || _j === void 0 ? void 0 : _j.performance) === null || _k === void 0 ? void 0 : _k.loading) && React.createElement("h4", { className: "pb-0 mb-0" }, "-"),
                    !((_m = (_l = this.state) === null || _l === void 0 ? void 0 : _l.performance) === null || _m === void 0 ? void 0 : _m.loading) &&
                        React.createElement("h4", { className: "pb-0 mb-0" }, number_parser_1.splitToNumberString((_r = (_q = (_p = (_o = this.state) === null || _o === void 0 ? void 0 : _o.performance) === null || _p === void 0 ? void 0 : _p.response) === null || _q === void 0 ? void 0 : _q.stats) === null || _r === void 0 ? void 0 : _r.click)),
                    React.createElement("span", null, "Kattintott"))),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("div", { className: "w-100 display-flex justify-content-center align-items-center flex-column" },
                    ((_t = (_s = this.state) === null || _s === void 0 ? void 0 : _s.performance) === null || _t === void 0 ? void 0 : _t.loading) && React.createElement("h4", { className: "pb-0 mb-0" }, "-"),
                    !((_v = (_u = this.state) === null || _u === void 0 ? void 0 : _u.performance) === null || _v === void 0 ? void 0 : _v.loading) &&
                        React.createElement("h4", { className: "pb-0 mb-0" }, number_parser_1.splitToNumberString((_z = (_y = (_x = (_w = this.state) === null || _w === void 0 ? void 0 : _w.performance) === null || _x === void 0 ? void 0 : _x.response) === null || _y === void 0 ? void 0 : _y.stats) === null || _z === void 0 ? void 0 : _z.opened)),
                    React.createElement("span", null, "Megnyitott"))),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.props.item.originalScheduleDate && moment(this.props.item.originalScheduleDate).format('YYYY-MM-DD HH:mm') || '-'),
            React.createElement(semantic_ui_react_1.Table.Cell, null, moment(this.props.item.createdAt).format('YYYY-MM-DD HH:mm')),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.props.item.contentUpdatedAt ? moment(this.props.item.contentUpdatedAt).format('YYYY-MM-DD HH:mm') : '-'),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderStatus()));
    };
    CustomNewsletterRow.prototype.renderStatus = function () {
        var createdAtDate = moment(this.props.item.createdAt);
        var originalScheduleDate = moment(this.props.item.originalScheduleDate || '1971-01-01');
        var originalScheduleDatePlus24 = moment(originalScheduleDate).add(24);
        var isScheduled = (createdAtDate.isBefore() && originalScheduleDate.isBefore());
        var isUnderSending = (originalScheduleDate.isBefore() && originalScheduleDatePlus24.isAfter());
        var isSent = originalScheduleDatePlus24.isBefore();
        var sentStatusString = 'Kiküldve';
        if (isScheduled) {
            sentStatusString = 'Ütemezve';
        }
        if (isUnderSending) {
            sentStatusString = 'Kiküldés alatt';
        }
        if (isSent) {
            sentStatusString = 'Kiküldve';
        }
        switch (this.props.item.status) {
            case 'scheduled':
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel scheduled" }, trans_1.trans('custom-newsletter.screen.list-item.status-scheduled'));
            case 'inprogress':
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel inprogress" }, trans_1.trans('custom-newsletter.screen.list-item.status-progress'));
            case 'sent':
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel sent" }, sentStatusString);
            default:
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel draft" }, trans_1.trans('custom-newsletter.screen.list-item.status-draft'));
        }
    };
    return CustomNewsletterRow;
}(responsive_component_1.ResponsiveComponent));
exports.CustomNewsletterRow = CustomNewsletterRow;
