"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var logout_component_1 = require("../../../authentication/components/logout.component");
var sensitive_data_1 = require("../../../common/libs/sensitive-data");
var change_password_component_1 = require("./change-password.component");
var UserProfileComponent = (function (_super) {
    __extends(UserProfileComponent, _super);
    function UserProfileComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            user: local_store_1.LocalStore.getItem('User'),
            presentationMode: local_store_1.LocalStore.getItem('PRESENTATION_MODE')
        };
        return _this;
    }
    UserProfileComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var user = local_store_1.LocalStore.getItem('User');
        if (!lodash_1.isEqual(prevState.user, user)) {
            this.setState({ user: user });
        }
    };
    UserProfileComponent.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return React.createElement("div", { className: 'UserProfileComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, trans_1.trans('settings.profile.user-profile.title')))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 },
                        React.createElement("span", null, trans_1.trans('settings.profile.user-profile.name.label'))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement("strong", null, (_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 :
                            _c.lastName,
                            " ", (_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.meta) === null || _f === void 0 ? void 0 :
                            _f.firstName))),
                React.createElement(semantic_ui_react_1.Divider, { className: 'my-0' }),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 },
                        React.createElement("span", null, trans_1.trans('settings.profile.user-profile.email.label'))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement("strong", null, (_h = (_g = this.state) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.email))),
                React.createElement(semantic_ui_react_1.Divider, { className: 'my-0' }),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 },
                        React.createElement("span", null, trans_1.trans('settings.profile.user-profile.password.label'))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(change_password_component_1.ChangePasswordComponent, null))),
                React.createElement(semantic_ui_react_1.Divider, { className: 'my-0' }),
                this.renderPresentationModeButton(),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                        React.createElement(semantic_ui_react_1.Button, { icon: 'sign-out', basic: true, content: trans_1.trans('general.button.logout.title'), onClick: function () { return logout_component_1.LogoutComponent.logout(); } })))));
    };
    UserProfileComponent.prototype.renderPresentationModeButton = function () {
        var _this = this;
        var _a;
        if (!((_a = local_store_1.LocalStore.getItem('User')) === null || _a === void 0 ? void 0 : _a.meta.isAdmin)) {
            return;
        }
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
            React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                React.createElement(semantic_ui_react_1.Button, { basic: true, color: !!sensitive_data_1.SensitiveData.isPresentationMode() ? 'red' : 'green', content: "Prezent\u00E1ci\u00F3s m\u00F3d " + (!!this.state.presentationMode ? 'kikapcsolása' : 'bekapcsolása'), onClick: function () { return _this.togglePresentationMode(); } })));
    };
    UserProfileComponent.prototype.togglePresentationMode = function () {
        sensitive_data_1.SensitiveData.setPresentationMode(!this.state.presentationMode);
        this.setState({ presentationMode: sensitive_data_1.SensitiveData.isPresentationMode() });
    };
    return UserProfileComponent;
}(abstract_component_1.AbstractComponent));
exports.UserProfileComponent = UserProfileComponent;
