"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandleToasts = void 0;
var react_toastify_1 = require("react-toastify");
var HandleToasts = (function () {
    function HandleToasts() {
    }
    HandleToasts.error = function (error, msg) {
        if (msg) {
            react_toastify_1.toast.error(msg);
            return;
        }
        react_toastify_1.toast.error(error.message);
    };
    HandleToasts.success = function (msg) {
        react_toastify_1.toast.success(msg);
    };
    return HandleToasts;
}());
exports.HandleToasts = HandleToasts;
