"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoListListCleaningTabContent = void 0;
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var axios_1 = require("axios");
var semantic_ui_react_1 = require("semantic-ui-react");
var todo_list_tab_content_container_component_1 = require("./todo-list-tab-content-container.component");
var session_storage_1 = require("../../../../libs/session.storage");
var environment_1 = require("@codebuild/uikit/libs/environment");
var errors_constant_1 = require("../../errors/errors.constant");
var toast_util_1 = require("../../errors/toast.util");
var TodoListListCleaningTabContent = (function (_super) {
    __extends(TodoListListCleaningTabContent, _super);
    function TodoListListCleaningTabContent(props) {
        var _this = _super.call(this, props) || this;
        _this.handleCheckboxChange = function (name) {
            _this.setState(function (prevState) {
                var _a;
                return (_a = {},
                    _a[name] = !prevState[name],
                    _a);
            });
        };
        _this.handleButtonClick = function () {
            _this.setState({ isLoading: true });
            var shop = _this.getCurrentShop();
            var api = environment_1.Environment.get('shoprenterApiRootUrl');
            axios_1.default.put(api + "/dashboard/shop/" + shop._id + "/clean-list?status=WAITING_FOR_LIST_CLEANING")
                .then(function (response) {
                var _a, _b;
                _this.setState({
                    isLoading: false,
                    isListCleanApproved: true,
                    isListCleaned: _this.props.dashboardData.dashboard.listCleaned
                });
                console.log('API response:', response.data);
                (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.refetch) === null || _b === void 0 ? void 0 : _b.call(_a);
            })
                .catch(function (error) {
                toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_REQUEST_LIST_CLEANING);
                _this.setState({
                    isLoading: false,
                    isListCleanApproved: false,
                    isListCleaned: false
                });
                console.error('API error:', error);
            })
                .finally(function () {
                _this.setState({ isLoading: false });
            });
        };
        _this.state = {
            isLoading: false,
            isListCleanApproved: _this.props.dashboardData.dashboard.listCleanApproved,
            isListCleaned: false,
            checkbox1: false,
            checkbox2: false
        };
        return _this;
    }
    TodoListListCleaningTabContent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    TodoListListCleaningTabContent.prototype.render = function () {
        var _this = this;
        var _a = this.state, isListCleanApproved = _a.isListCleanApproved, checkbox1 = _a.checkbox1, checkbox2 = _a.checkbox2;
        var allCheckboxesChecked = checkbox1 && checkbox2;
        return (React.createElement(todo_list_tab_content_container_component_1.TodoListTabContentContainerComponent, null,
            React.createElement(semantic_ui_react_1.Message, { info: true, style: { backgroundColor: '#EDF4FF', boxShadow: 'none', color: "#051D40" } },
                React.createElement("p", null,
                    React.createElement("strong", null, "Kezdj\u00FCk el a h\u00EDrlev\u00E9lre feliratkoz\u00F3 list\u00E1d friss\u00EDt\u00E9s\u00E9t!")),
                React.createElement("p", null, "Els\u0151 l\u00E9p\u00E9sk\u00E9nt a m\u00E1r meglev\u0151 \u00E9rdekl\u0151d\u0151i adatb\u00E1zisodat fogom \u00E1tn\u00E9zni \u00E9s kitiszt\u00EDtani."),
                React.createElement("p", null,
                    React.createElement("strong", null, "A list\u00E1b\u00F3l ki fogok t\u00F6r\u00F6lni"),
                    " minden olyan e-mail c\u00EDmet, ami m\u00E1r : ",
                    React.createElement("strong", null, "nem l\u00E9tezik, hib\u00E1s"),
                    ", vagy \u00E9ppen ",
                    React.createElement("strong", null, "adathal\u00E1sz e-mail c\u00EDm"),
                    ". (Ne agg\u00F3dj, csak azokat t\u00F6rl\u00F6m, amikre val\u00F3ban nincs sz\u00FCks\u00E9ged!)"),
                React.createElement("p", null,
                    "\uD83D\uDCA1",
                    React.createElement("strong", null, "FONTOS!"),
                    " Ha rossz min\u0151s\u00E9g\u0171 e-mail c\u00EDmre k\u00FCldenek e-mailt, az a webshop teljes levelez\u00E9s\u00E9re nagyon rossz hat\u00E1ssal lenne, \u00E9s a web\u00E1ruh\u00E1zad teljes m\u0171k\u00F6d\u00E9s\u00E9re is (SEO, PPC, stb.) nagyon negat\u00EDvan hatna. Ez\u00E9rt nagyon fontos a lista tiszt\u00EDt\u00E1sa indul\u00E1s el\u0151tt."),
                React.createElement("p", null,
                    React.createElement("strong", null, "\u00CDgy kezdd el a lista tiszt\u00EDt\u00E1st:")),
                React.createElement("p", null,
                    "1. Szedj \u00F6ssze minden olyan adatb\u00E1zist, amikre jogszer\u0171 keretek k\u00F6z\u00F6tt k\u00FCldhetsz e-mailt. Ezt a list\u00E1t tedd be a ",
                    React.createElement("strong", null, "Shoprenter \u201Ch\u00EDrlev\u00E9lre feliratkozottak\u201D"),
                    " adatb\u00E1zis\u00E1ba."),
                React.createElement("p", null,
                    "2. Ezt k\u00F6vet\u0151en ",
                    React.createElement("strong", null, "k\u00E9sz\u00EDts egy biztons\u00E1gi ment\u00E9st a h\u00EDrlev\u00E9l list\u00E1dr\u00F3l"),
                    " (csak a h\u00EDrlev\u00E9lre feliratkozott mentsd le, a t\u00F6bbihez nem fogok ny\u00FAlni. )"),
                React.createElement("p", null, "Ez az\u00E9rt fontos, mert a rossz min\u0151s\u00E9g\u0171 email c\u00EDmeket ki fogom t\u00F6r\u00F6lni a Shoprenterben l\u00E9v\u0151 feliratkozott list\u00E1r\u00F3l is (ha v\u00E1s\u00E1rl\u00F3hoz kapcsol\u00F3dik az adat, ahhoz nem fogok ny\u00FAlni) \u00EDgy biztos\u00EDtsd be magad, k\u00E9sz\u00EDts biztons\u00E1gi ment\u00E9st."),
                React.createElement("p", null,
                    "3. Ha megvagy, akkor kattints alul az ",
                    React.createElement("strong", null, "\u201CINDULHAT A LISTA TISZT\u00CDT\u00C1S\u201D"),
                    " -gombra \u00E9s mehet\u00FCnk is tov\u00E1bb!")),
            this.props.dashboardData.dashboard.listCleaned === false ? (React.createElement("div", null,
                " ",
                !isListCleanApproved ? (React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Checkbox, { label: "Elk\u00E9sz\u00EDtettem a biztons\u00E1gi ment\u00E9st.", checked: checkbox1, onChange: function () { return _this.handleCheckboxChange('checkbox1'); } }),
                    React.createElement("br", null),
                    React.createElement(semantic_ui_react_1.Checkbox, { label: "Meg\u00E9rtettem, hogy a h\u00EDrlev\u00E9l tiszt\u00EDt\u00E1s sor\u00E1n t\u00F6rl\u00E9sre ker\u00FClhetnek email c\u00EDmek, amiknek a vissza\u00E1ll\u00EDt\u00E1sa csak akkor lehets\u00E9ges, ha k\u00E9sz\u00EDtettem el\u0151z\u0151leg biztons\u00E1gi ment\u00E9st!", checked: checkbox2, onChange: function () { return _this.handleCheckboxChange('checkbox2'); } }),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement(semantic_ui_react_1.Button, { onClick: this.handleButtonClick, disabled: !allCheckboxesChecked }, "Indulhat a lista kitiszt\u00EDt\u00E1sa!"))) : (React.createElement("div", { style: { display: 'flex', flexDirection: 'row', alignItems: 'center' } },
                    React.createElement(semantic_ui_react_1.Button, { onClick: this.handleButtonClick, disabled: true }, "Indulhat a lista kitiszt\u00EDt\u00E1sa!"),
                    React.createElement(semantic_ui_react_1.Message, { success: true, style: {
                            marginLeft: 20,
                            boxShadow: '0px 0px 0px 1px #337EFF inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)',
                            backgroundColor: '#EDF4FF',
                            color: '#051D40'
                        } },
                        React.createElement("p", null,
                            "Listatisz\u00EDt\u00E1s folyamatban. Ez - a web\u00E1ruh\u00E1z m\u00E9ret\u00E9t\u0151l f\u00FCgg\u0151en - ak\u00E1r 2-30 \u00F3r\u00E1t is ig\u00E9nybe vehet, de menj\u00FCnk tov\u00E1bb nyugodtan.",
                            React.createElement("br", null),
                            React.createElement("strong", null, "K\u00E9rlek, kattints a \u201C4. l\u00E9p\u00E9s - DNS be\u00E1ll\u00EDt\u00E1s\u201D-ra fel\u00FCl."))))))) : (React.createElement(semantic_ui_react_1.Message, { success: true },
                React.createElement("p", null,
                    React.createElement("strong", null, "Listatiszt\u00EDt\u00E1s megt\u00F6rt\u00E9nt."))))));
    };
    return TodoListListCleaningTabContent;
}(abstract_component_1.AbstractComponent));
exports.TodoListListCleaningTabContent = TodoListListCleaningTabContent;
