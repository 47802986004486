"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
module.exports = {
    'webshop-data.category-list.screen.title': 'Kategóriák',
    'webshop-data.category-list.item.name.label': 'Név',
    'webshop-data.category-list.item.gross-income.label': 'Bevétel',
    'webshop-data.category-list.item.product-count.label': 'Termékek száma',
    'webshop-data.category-list.item.advertisable.label': React.createElement(semantic_ui_react_1.Icon, { name: "audio description", className: 'ad-icon' }),
    'webshop-data.category-list.item.recommended.label': 'Ajánlható kategóriák',
    'webshop-data.category-list.search.placeholder': 'Keresés',
    'webshop-data.category-list.screen.category-count.label': 'db'
};
