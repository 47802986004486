"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomListComponent = void 0;
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var activity_indicator_1 = require("../../../common/components/activity-indicator/activity-indicator");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var currency_parser_1 = require("../../../common/libs/currency-parser");
require("./lead-list.component.scss");
var sensitive_data_1 = require("../../../common/libs/sensitive-data");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var sort_header_1 = require("../../../common/components/sort-header/sort-header");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var CustomListComponent = (function (_super) {
    __extends(CustomListComponent, _super);
    function CustomListComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 50,
                limit: 50,
                _page: 0,
                page: 0
            }
        });
        _this.state = {
            results: {
                loading: false,
                response: null,
                error: false
            },
            search: _this.props.search,
            loadCount: 50,
            expressions: _this.props.expressions || []
        };
        _this.state = {
            results: {
                loading: false,
                response: null,
                error: false
            },
            search: _this.props.search,
            loadCount: 50,
            expressions: _this.props.expressions || []
        };
        return _this;
    }
    CustomListComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log('componentDidMount control', this.control);
                return [2];
            });
        });
    };
    CustomListComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var prev = JSON.stringify(prevProps.expressions);
        var next = JSON.stringify(this.props.expressions);
        if (prev !== next) {
            this.repositoryListRef.reloadWithExpressions(this.props.expressions);
            this.setState({ expressions: this.props.expressions });
        }
    };
    CustomListComponent.prototype.refetchWithExpressions = function () {
        console.log('Refetching with filter expressions:', this.props.expressions);
        this.repositoryListRef.reloadWithExpressions(this.props.expressions);
    };
    CustomListComponent.prototype.getItems = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var items = this.props.search
            ? (((_b = (_a = this.state.results) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.items) || []).filter(function (item) {
                var _a, _b, _c, _d;
                var searchString = (((_a = item.data) === null || _a === void 0 ? void 0 : _a.email) + " " + ((_b = item.data) === null || _b === void 0 ? void 0 : _b.firstName) + " " + ((_c = item.data) === null || _c === void 0 ? void 0 : _c.lastName)).toLowerCase();
                return searchString.split((_d = _this.props.search) === null || _d === void 0 ? void 0 : _d.toLowerCase()).length > 1;
            })
            : ((_d = (_c = this.state.results) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.items) || [];
        if (this.props.totalChange) {
        }
        return lodash_1.take(items, this.state.loadCount);
    };
    CustomListComponent.prototype.handleListChange = function (ctx) {
        var _a;
        console.log('handleListChange', ctx, ctx.state);
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    CustomListComponent.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "N\u00E9v"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement(sort_header_1.SortHeader, { title: "\u00C1llapot", property: 'statementSubscribed', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement(sort_header_1.SortHeader, { title: "Regisztr\u00E1ci\u00F3 ideje", property: 'dataCreatedAt', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: "Forr\u00E1s", property: 'dataOptimonk', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: "T\u00EDpus", property: 'dataGuest', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: "Elm\u00FAlt 30 nap", property: 'statisticsTotalOrderValue30', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: "\u00D6sszes k\u00F6lt\u00E9s", property: 'statisticsTotalOrderValueAll', "control$": this.control })))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    CustomListComponent.prototype.renderItem = function (ctx, item, index, level) {
        var _a, _b, _c, _d, _e;
        if (level === void 0) { level = 0; }
        var detailsPath = "/leads/customer/" + (item === null || item === void 0 ? void 0 : item._id);
        return [
            React.createElement(semantic_ui_react_1.Table.Row, { className: 'cursor-pointer', onClick: function () { return router_provider_1.RouterProvider.goTo(detailsPath); }, key: item._id, verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderCustomerName(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderSubscribeLabel(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, moment((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.createdAt).format('YYYY.MM.DD')),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, ((_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.optimonk) ? 'OptiMonk' : 'Webshop'),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, ((_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.guest) ? 'Vendég' : 'Regisztrált'),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, currency_parser_1.hufFormat((_d = item === null || item === void 0 ? void 0 : item.statistics) === null || _d === void 0 ? void 0 : _d.totalOrderValue30)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, currency_parser_1.hufFormat((_e = item === null || item === void 0 ? void 0 : item.statistics) === null || _e === void 0 ? void 0 : _e.totalOrderValueAll))),
        ];
    };
    CustomListComponent.prototype.render = function () {
        var _this = this;
        var _a;
        var items = this.getItems();
        return React.createElement("div", { className: "LeadListComponent ListComponent" },
            React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return _this.repositoryListRef = ref; }, control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/customer-filter/filter", data: {
                    expressions: this.state.expressions,
                    limitResponse: true
                }, method: "post", renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 7 })); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 7 })); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                    { text: 1, value: 1 },
                    { text: 10, value: 10 },
                    { text: 25, value: 25 },
                    { text: 50, value: 50 },
                    { text: 100, value: 100 },
                ] }));
    };
    CustomListComponent.prototype.renderLoadMoreButton = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var items = this.props.search
            ? (((_b = (_a = this.state.results) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.items) || []).filter(function (item) {
                var _a, _b, _c, _d;
                var searchString = (((_a = item.data) === null || _a === void 0 ? void 0 : _a.email) + " " + ((_b = item.data) === null || _b === void 0 ? void 0 : _b.firstName) + " " + ((_c = item.data) === null || _c === void 0 ? void 0 : _c.lastName)).toLowerCase();
                return searchString.split((_d = _this.props.search) === null || _d === void 0 ? void 0 : _d.toLowerCase()).length > 1;
            })
            : ((_d = (_c = this.state.results) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.items) || [];
        var remainingItems = items.length - this.state.loadCount;
        if (remainingItems <= 0) {
            return null;
        }
        if (remainingItems < 50) {
            return React.createElement("div", { className: 'display-flex justify-content-center aling-items-center mt-6' },
                React.createElement(semantic_ui_react_1.Button, { basic: true, content: "Tov\u00E1bbi " + remainingItems + " bet\u00F6lt\u00E9se", onClick: function () { return _this.setState({ loadCount: _this.state.loadCount + 50 }); } }));
        }
        return React.createElement("div", { className: 'display-flex justify-content-center aling-items-center mt-6' },
            React.createElement(semantic_ui_react_1.Button, { basic: true, content: 'További 50 betöltése', onClick: function () { return _this.setState({ loadCount: _this.state.loadCount + 50 }); } }));
    };
    CustomListComponent.prototype.renderCustomerName = function (item) {
        var _a, _b, _c, _d;
        return React.createElement("div", { className: 'w-100 display-inline-flex' },
            React.createElement("div", null,
                React.createElement(activity_indicator_1.ActivityIndicator, { shopId: (_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id, cIdOrSId: item === null || item === void 0 ? void 0 : item.identifier },
                    React.createElement("span", { className: '' },
                        sensitive_data_1.SensitiveData.customerName((_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.lastName),
                        " ",
                        sensitive_data_1.SensitiveData.customerName((_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.firstName))),
                React.createElement("span", { className: '' }, sensitive_data_1.SensitiveData.customerEmail((_d = item === null || item === void 0 ? void 0 : item.data) === null || _d === void 0 ? void 0 : _d.email))));
    };
    CustomListComponent.prototype.renderSubscribeLabel = function (item) {
        var _a, _b, _c, _d;
        var subscribed = (_a = item === null || item === void 0 ? void 0 : item.statement) === null || _a === void 0 ? void 0 : _a.subscribed;
        var unsubscribed = (_b = item === null || item === void 0 ? void 0 : item.statement) === null || _b === void 0 ? void 0 : _b.unsubscribed;
        var subscribeReason = (_d = (_c = item === null || item === void 0 ? void 0 : item.statement) === null || _c === void 0 ? void 0 : _c.unsubscribeReason) === null || _d === void 0 ? void 0 : _d.split('Unsubscribed at sync').length;
        if (subscribed) {
            return React.createElement("div", { className: 'subscription-label subscribed' },
                React.createElement(semantic_ui_react_1.Icon, { name: 'check' }),
                "Feliratkozva");
        }
        if (unsubscribed && subscribeReason > 1) {
            return React.createElement("div", { className: 'subscription-label unsubscribed' },
                React.createElement(semantic_ui_react_1.Icon, { name: 'delete' }),
                "Leiratkozva");
        }
        return React.createElement("div", { className: 'subscription-label not-subscribed' }, "Nincs feliratkozva");
    };
    CustomListComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CustomListComponent;
}(React.Component));
exports.CustomListComponent = CustomListComponent;
