"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hufFormat = exports.splitToMoney = void 0;
var splitToMoney = function (amount) {
    if (amount !== 0 && !amount) {
        return;
    }
    if (amount.toString().length <= 3) {
        return amount.toString();
    }
    var end = amount.toString().slice(amount.toString().length - 3, amount.toString().length);
    var carry = amount.toString().slice(0, amount.toString().length - 3);
    if (carry.length > 3) {
        return exports.splitToMoney(carry) + " " + end;
    }
    return carry + " " + end;
};
exports.splitToMoney = splitToMoney;
var hufFormat = function (amount) {
    if (amount !== 0 && !amount) {
        return '-';
    }
    var roundAmount = Math.round(amount);
    var number = exports.splitToMoney(roundAmount);
    return (number || 0) + " Ft";
};
exports.hufFormat = hufFormat;
