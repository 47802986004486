"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelevantRankingComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var React = require("react");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var state_repository_1 = require("../../../common/libs/state-repository");
var RelevantRankingComponent = (function (_super) {
    __extends(RelevantRankingComponent, _super);
    function RelevantRankingComponent() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.customerSearch$ = new rxjs_1.Subject();
        _this.products = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'products');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                user: new field_1.Field({
                    name: 'user',
                    placeholder: 'Felhasználó',
                    label: 'Felhasználó',
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: "/s/" + ((_a = _this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/customer",
                    loadOptionsAfterMount: true,
                    optionsMap: function (response) { return (response.items || []).map(function (item) {
                        var _a, _b;
                        return ({
                            key: item._id,
                            text: (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.email,
                            value: (_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.email,
                        });
                    }); }
                })
            }
        });
        return _this;
    }
    RelevantRankingComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.customerSearch$
            .pipe(operators_1.debounceTime(250))
            .subscribe(function (query) { return _this.form.fields.user.loadOptions({ query: query }); }), this.form.fields.user.$value.subscribe(function (value) { return _this.updateProducts(value); }));
    };
    RelevantRankingComponent.prototype.updateProducts = function (email) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, items;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.products.get("/a/shop/" + ((_a = this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/relevant-rank", {
                            params: {
                                email: email
                            }
                        })];
                    case 1:
                        result = _c.sent();
                        items = (((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.Context_AllSource__Chunked) || []).flat();
                        this.setState({
                            resultProducts: items
                        });
                        return [2];
                }
            });
        });
    };
    RelevantRankingComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, { className: 'mb-2 ListComponentHeader' },
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Pontsz\u00E1m\u00EDt\u00E1s")),
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement("div", { className: "display-flex" },
                                    React.createElement(semantic_ui_react_1.Form, { className: "flex-fill pr-4" },
                                        React.createElement(form_control_1.FormControl, { field: this.form.fields.user, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Dropdown, clearable: true, selection: true, search: true, fluid: true, disabled: false, onSearchChange: function (event) { return _this.customerSearch$.next(event.target.value); } })))))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: "ListWrapper" },
                            React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderItemsWrapper(this.state.resultProducts)))))));
    };
    RelevantRankingComponent.prototype.renderItemsWrapper = function (items) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative ListWrapper', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "SKU"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Term\u00E9k neve"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "\u00D6sszes pont"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kat. megtekint"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kat. megtekint (t)"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kat. v\u00E1s\u00E1rl\u00E1s"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Megn\u00E9zte"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "\u00DAjdons\u00E1g"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Bev\u00E9tel \u00F6ssz."),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Bev\u00E9tel db."),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "N\u00E9zetts\u00E9g"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kos\u00E1r"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Email"))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2 },
                ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.loading) && React.createElement(list_loader_1.ListLoader, { tableColspan: 13, table: true }),
                !((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.products) === null || _d === void 0 ? void 0 : _d.loading) && !(items === null || items === void 0 ? void 0 : items.length) && React.createElement(list_empty_1.ListEmpty, { tableColspan: 13, table: true }),
                !(((_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.products) === null || _f === void 0 ? void 0 : _f.loading) || !(items === null || items === void 0 ? void 0 : items.length)) && items.map(function (item, index) { return _this.renderItem(item, index); })));
    };
    RelevantRankingComponent.prototype.renderItem = function (item, index) {
        var _a, _b;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item === null || item === void 0 ? void 0 : item.identifier },
            React.createElement(semantic_ui_react_1.Table.Cell, null, (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.sku),
            React.createElement(semantic_ui_react_1.Table.Cell, null, (_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.name),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relp) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpCategoryViewFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpCategoryProductViewFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpCategoryOrderFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpCustomerProductViewFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpNewsFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpIncomeFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpSoldFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpViewFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpCartFactor) * 100) / 100),
            React.createElement(semantic_ui_react_1.Table.Cell, null, Math.round((item === null || item === void 0 ? void 0 : item.__relpEmailSentProductFactor) * 100) / 100));
    };
    return RelevantRankingComponent;
}(abstract_component_1.AbstractComponent));
exports.RelevantRankingComponent = RelevantRankingComponent;
