"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratedReportsScreen = void 0;
var environment_1 = require("@codebuild/uikit/libs/environment");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var axios_1 = require("axios");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("../libs/common.scss");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var errors_constant_1 = require("../../../shoprenter/errors/errors.constant");
var GeneratedReportsScreen = (function (_super) {
    __extends(GeneratedReportsScreen, _super);
    function GeneratedReportsScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isLoading: false,
            generatedReports: null,
            isError: false
        };
        return _this;
    }
    GeneratedReportsScreen.prototype.fetchGeneratedReports = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apiBaseUrl, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        apiBaseUrl = environment_1.Environment.get('api');
                        return [4, axios_1.default.get(apiBaseUrl + "/a/generated-reports", {
                                headers: {
                                    Authorization: get_access_token_1.getAccessToken()
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        console.log('fetchGeneratedReports response', response);
                        this.setState({ generatedReports: (response === null || response === void 0 ? void 0 : response.data) || [], isError: false });
                        return [3, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('fetchGeneratedReports error', error_1);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_FETCH_GENERATED_REPORTS);
                        this.setState({ isError: true });
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    GeneratedReportsScreen.prototype.componentDidMount = function () {
        this.fetchGeneratedReports();
    };
    GeneratedReportsScreen.prototype.extractDatesFromFilename = function (filename) {
        var pattern = /from-(\d{4}-\d{2}-\d{2})-to-(\d{4}-\d{2}-\d{2})/;
        var match = filename.match(pattern);
        if (match) {
            var fromDate = match[1];
            var toDate = match[2];
            return {
                from: fromDate,
                to: toDate
            };
        }
        return null;
    };
    GeneratedReportsScreen.prototype.renderTableBody = function () {
        var _this = this;
        var _a;
        if (((_a = this.state.generatedReports) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return (React.createElement(semantic_ui_react_1.Table.Row, { verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, "Nincsen megjelen\u00EDtend\u0151 riport.")));
        }
        return this.state.generatedReports.map(function (report) {
            var filename = report.Key, downloadUrl = report.PublicUrl;
            var dates = _this.extractDatesFromFilename(filename);
            var isWeekly = filename.includes("weekly");
            var dateInterval = '';
            if (dates) {
                var from = dates.from, to = dates.to;
                dateInterval = from + " - " + to;
            }
            return (React.createElement(semantic_ui_react_1.Table.Row, { key: filename, verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, dateInterval),
                React.createElement(semantic_ui_react_1.Table.Cell, null, isWeekly ? "Heti" : "Havi"),
                React.createElement(semantic_ui_react_1.Table.Cell, null,
                    " ",
                    React.createElement("a", { href: downloadUrl, target: '_blank' }, filename))));
        });
    };
    GeneratedReportsScreen.prototype.render = function () {
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Heti, havi statisztik\u00E1k"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                this.state.isLoading && (React.createElement("div", { className: 'p-8' },
                                    React.createElement(semantic_ui_react_1.Loader, { active: true }))),
                                this.state.isError && (React.createElement("div", { className: 'p-8' }, errors_constant_1.getErrorMessage(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_FETCH_GENERATED_REPORTS))),
                                this.state.generatedReports && (React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
                                    React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                                        React.createElement(semantic_ui_react_1.Table.Row, null,
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                                                React.createElement("span", null, "Id\u0151szak")),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                                                React.createElement("span", null, "Riport t\u00EDpusa")),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                                                React.createElement("span", null, "Riport let\u00F6lt\u00E9se")))),
                                    React.createElement(semantic_ui_react_1.Table.Body, { children: this.renderTableBody() })))))))));
    };
    return GeneratedReportsScreen;
}(abstract_component_1.AbstractComponent));
exports.GeneratedReportsScreen = GeneratedReportsScreen;
