"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterAppLayout = void 0;
require("./default.layout.scss");
var React = require("react");
var axios_1 = require("axios");
var semantic_ui_react_1 = require("semantic-ui-react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var menu_component_1 = require("../../components/menu/menu.component");
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var session_storage_1 = require("../../../../libs/session.storage");
var lodash_1 = require("lodash");
var ShoprenterAppLayout = (function (_super) {
    __extends(ShoprenterAppLayout, _super);
    function ShoprenterAppLayout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            shop: session_storage_1.SessionStorage.getItem('SelectedShop')
        };
        return _this;
    }
    ShoprenterAppLayout.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchFeeInfo()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    ShoprenterAppLayout.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        if (!!lodash_1.isEqual(prevState.shop, shop)) return [3, 2];
                        this.setState({ shop: shop });
                        if (!(((_a = prevState === null || prevState === void 0 ? void 0 : prevState.shop) === null || _a === void 0 ? void 0 : _a._id) !== (shop === null || shop === void 0 ? void 0 : shop._id))) return [3, 2];
                        return [4, this.fetchFeeInfo()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ShoprenterAppLayout.prototype.render = function () {
        var _a;
        if (this.props.routeLoading) {
            return this.renderLoader();
        }
        if (!this.state.shop) {
        }
        if (((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.source) !== 'shoprenterOnDemand' || this.state.accessGranted) {
            return this.renderDefaultLayout();
        }
        return this.renderPaymentWarningScreen();
    };
    ShoprenterAppLayout.prototype.renderDefaultLayout = function () {
        return React.createElement("div", { className: 'DefaultLayout' },
            React.createElement("div", { className: 'MenuContainer' },
                React.createElement(menu_component_1.MenuComponent, __assign({}, this.props))),
            React.createElement(semantic_ui_react_1.Container, { className: "flex-fill MainContainer", style: this.props.route.path === '/dashboard' ? { overflow: 'hidden' } : {}, fluid: true },
                this.renderLoader(),
                !this.state.routeLoading && React.createElement("div", null, this.props.children)));
    };
    ShoprenterAppLayout.prototype.renderLoader = function () {
        if (!this.props.routeLoading) {
            return null;
        }
        return React.createElement("div", { className: 'Loader' },
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }));
    };
    ShoprenterAppLayout.prototype.renderPaymentWarningScreen = function () {
        var _this = this;
        if (this.props.routeLoading) {
            return null;
        }
        return React.createElement("div", { className: 'DefaultLayout' },
            React.createElement("div", { className: "fee-block" },
                React.createElement(semantic_ui_react_1.Grid, { style: { height: '100vh' }, verticalAlign: "middle" },
                    React.createElement(semantic_ui_react_1.Grid.Row, null,
                        React.createElement(semantic_ui_react_1.Grid.Column, { style: { maxWidth: 500 } },
                            React.createElement("div", { className: 'flex-column justify-content-center align-items-center' },
                                React.createElement("img", { src: "https://www.miclub.hu/images/mi_club_logo.png", alt: "mi", className: "mb-6" })),
                            React.createElement("div", { role: "alert" },
                                React.createElement("p", { className: "text-lg-center" },
                                    React.createElement("p", null,
                                        React.createElement("strong", null, "Kedves \u00DCgyfel\u00FCnk!")),
                                    React.createElement("p", null,
                                        React.createElement("strong", null,
                                            "T\u00E1j\u00E9koztatunk, hogy aktu\u00E1lis egyenleged nyitott t\u00E9telt tartalmaz, melynek megfizet\u00E9s\u00E9re ",
                                            this.state.daysLeft,
                                            " nap \u00E1ll rendelkez\u00E9sedre!")),
                                    React.createElement("p", null, "K\u00E9rj\u00FCk, hogy ha az el\u0151fizet\u00E9si d\u00EDjadat most k\u00EDv\u00E1nod kiegyenl\u00EDteni, akkor kattints a \u201CFIZETEK\u201D gombra."),
                                    React.createElement("p", null, "Amennyiben most nem alkalmas, lehet\u0151s\u00E9ged van a fent megjel\u00F6lt hat\u00E1rid\u0151n bel\u00FCl egy \u00E1ltalad v\u00E1lasztott k\u00E9s\u0151bbi id\u0151pontban is rendezni az el\u0151fizet\u00E9si d\u00EDjat."),
                                    React.createElement("p", null,
                                        React.createElement("strong", null,
                                            "B\u00E1rmilyen tov\u00E1bbi inform\u00E1ci\u00F3ra vagy seg\u00EDts\u00E9gre lenne sz\u00FCks\u00E9ged a",
                                            React.createElement("a", { href: "mailto:help@miclub.hu" }, " help@miclub.hu "),
                                            "email c\u00EDmen tudod felvenni \u00FCgyf\u00E9lszolg\u00E1latunkkal a kapcsolatot!")))),
                            React.createElement("div", { className: "button-group" },
                                React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.handlePayment(); } }, "Fizetek"),
                                this.state.canPostpone && React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.handlePostpone(); } }, "K\u00E9s\u0151bb")))))));
    };
    ShoprenterAppLayout.prototype.fetchFeeInfo = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, data, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.source) === 'shoprenterOnDemand')) return [3, 6];
                        this.setState({ loading: true });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, 5, 6]);
                        return [4, axios_1.default.get(environment_1.Environment.get('shoprenterApiRootUrl') + "/marketplace/shop/" + this.state.shop._id + "/fee-info")];
                    case 2:
                        response = _b.sent();
                        return [4, (response === null || response === void 0 ? void 0 : response.data)];
                    case 3:
                        data = _b.sent();
                        this.setState({
                            accessGranted: data === null || data === void 0 ? void 0 : data.accessGranted,
                            canPostpone: data === null || data === void 0 ? void 0 : data.canPostpone,
                            paymentLink: data === null || data === void 0 ? void 0 : data.paymentLink,
                            daysLeft: (data === null || data === void 0 ? void 0 : data.daysLeft) > 0 ? data === null || data === void 0 ? void 0 : data.daysLeft : 0
                        });
                        return [3, 6];
                    case 4:
                        error_1 = _b.sent();
                        console.error('Error fetching data: ', error_1);
                        return [3, 6];
                    case 5:
                        this.setState({ loading: false });
                        return [7];
                    case 6: return [2];
                }
            });
        });
    };
    ;
    ShoprenterAppLayout.prototype.handlePayment = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                window.open(this.state.paymentLink, '_self');
                return [2];
            });
        });
    };
    ShoprenterAppLayout.prototype.handlePostpone = function () {
        this.setState({
            accessGranted: true
        });
    };
    return ShoprenterAppLayout;
}(responsive_component_1.ResponsiveComponent));
exports.ShoprenterAppLayout = ShoprenterAppLayout;
