"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxNumberInput = void 0;
var React = require("react");
var axios_1 = require("axios");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var errors_constant_1 = require("../../../shoprenter/errors/errors.constant");
var TaxNumberInput = (function (_super) {
    __extends(TaxNumberInput, _super);
    function TaxNumberInput(props) {
        var _this = _super.call(this, props) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                taxNumber: new field_1.Field({
                    name: 'taxNumber',
                    placeholder: '12345678-9-10',
                    value: '',
                }),
            }
        });
        console.log(props);
        console.log(_this.form.fields);
        if (props === null || props === void 0 ? void 0 : props.taxNumber) {
            _this.form.fields.taxNumber.setValue(props.taxNumber);
        }
        _this.state = {
            isLoading: false
        };
        return _this;
    }
    TaxNumberInput.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.handleButtonClick(); }));
    };
    TaxNumberInput.prototype.handleButtonClick = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var shopId, api, API_URL, jsonFormData, taxNumber, urlWithTaxNumber, response, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, 5, 6]);
                        shopId = this.props.shopId;
                        api = environment_1.Environment.get('shoprenterApiRootUrl');
                        API_URL = api + "/marketplace/shop/" + shopId + "/tax-number";
                        jsonFormData = this.form.toJSON();
                        taxNumber = jsonFormData === null || jsonFormData === void 0 ? void 0 : jsonFormData.taxNumber;
                        if (!taxNumber) return [3, 3];
                        urlWithTaxNumber = API_URL + "?taxNumber=" + taxNumber;
                        console.log('handleSubmit: Update taxNumber value with PATCH request', {
                            jsonFormData: jsonFormData,
                            urlWithTaxNumber: urlWithTaxNumber
                        });
                        return [4, axios_1.default.patch(urlWithTaxNumber)];
                    case 2:
                        response = _c.sent();
                        console.log('handleSubmit: PATCH response', response);
                        toast_util_1.openSuccessToast('Sikeres adószám mentés!');
                        (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.refetch) === null || _b === void 0 ? void 0 : _b.call(_a);
                        _c.label = 3;
                    case 3: return [3, 6];
                    case 4:
                        error_1 = _c.sent();
                        console.log('handleSubmit catch', error_1);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_UPDATE_TAX_NUMBER);
                        return [3, 6];
                    case 5:
                        this.setState({ isLoading: false });
                        return [7];
                    case 6: return [2];
                }
            });
        });
    };
    TaxNumberInput.prototype.render = function () {
        var _this = this;
        console.log('RequestSyncButton', this.props.shopId, this.props.isDisabled);
        return (React.createElement(semantic_ui_react_1.Grid, { as: semantic_ui_react_1.Form, className: 'pl-4', onSubmit: function () { return _this.form.submit(); } },
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 2, verticalAlign: 'middle' },
                    React.createElement("span", null, "Ad\u00F3sz\u00E1m")),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 5, verticalAlign: 'middle' },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.taxNumber, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false }))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4, verticalAlign: 'middle' },
                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.isLoading || this.props.isDisabled, loading: this.state.isLoading }, "Ment\u00E9s")))));
    };
    return TaxNumberInput;
}(abstract_component_1.AbstractComponent));
exports.TaxNumberInput = TaxNumberInput;
