"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LowercaseEmailField = void 0;
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var LowercaseEmailField = (function (_super) {
    __extends(LowercaseEmailField, _super);
    function LowercaseEmailField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LowercaseEmailField.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Input, __assign(__assign({}, this.props), { onChange: function (event) {
                var _a, _b;
                if (!_this.props.onChange) {
                    return;
                }
                event.target.value = (_b = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toLowerCase();
                _this.props.onChange(event);
            } }));
    };
    return LowercaseEmailField;
}(React.Component));
exports.LowercaseEmailField = LowercaseEmailField;
