"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuBodyComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var menu_1 = require("./menu");
var menu_support_email_component_1 = require("./menu-support-email.component");
require("./menu.component.scss");
var menu_email_status_component_1 = require("./menu-email-status.component");
var session_storage_1 = require("../../../../libs/session.storage");
var MenuBodyComponent = (function (_super) {
    __extends(MenuBodyComponent, _super);
    function MenuBodyComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.menu = menu_1.menu;
        _this.adminMenu = __spreadArrays(menu_1.menu, [
            {
                name: 'admin',
                path: '/admin',
                icon: 'terminal',
                activeRegexp: /^\/admin/,
                items: []
            }
        ]);
        _this.state = {
            active: null,
            isMenuDisabledForShoprenterShop: false,
        };
        return _this;
    }
    MenuBodyComponent.prototype.componentDidMount = function () {
        var _a;
        var currentShoprenterData = session_storage_1.SessionStorage.getItem(session_storage_1.SESSION_STORAGE_KEYS.SESSION_STORAGE_SHOPRENTER_DATA_KEY);
        if (!currentShoprenterData) {
            this.setState({ isMenuDisabledForShoprenterShop: false });
        }
        else {
            this.setState({ isMenuDisabledForShoprenterShop: !((_a = currentShoprenterData === null || currentShoprenterData === void 0 ? void 0 : currentShoprenterData.dashboard) === null || _a === void 0 ? void 0 : _a.autoMailUpAndRunning) || false });
        }
    };
    MenuBodyComponent.prototype.render = function () {
        return React.createElement("div", { className: 'MenuBodyComponent', style: { flex: 1 } }, this.renderMenu());
    };
    MenuBodyComponent.prototype.renderMenu = function () {
        var _this = this;
        var _a, _b;
        var m = ((_b = (_a = local_store_1.LocalStore.getItem('User')) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.admin) ? this.adminMenu
            : this.menu;
        return React.createElement(semantic_ui_react_1.Menu, { secondary: true, vertical: true },
            m.map(function (i) { return _this.renderMenuItem(i); }),
            React.createElement(menu_email_status_component_1.EmailMenuStatusComponent, __assign({}, this.props)),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(menu_support_email_component_1.SupportEmailMenuItemComponent, __assign({}, this.props)));
    };
    MenuBodyComponent.prototype.renderMenuItem = function (m) {
        return React.createElement(semantic_ui_react_1.Menu.Item, { disabled: this.state.isMenuDisabledForShoprenterShop, className: 'MenuItem', active: m.activeRegexp.test(this.props.route.path), onClick: function () { return router_provider_1.RouterProvider.goTo(m.path, { replaceQuery: true }); }, content: this.renderItemContent(m), key: m.name });
    };
    MenuBodyComponent.prototype.renderItemContent = function (item) {
        return React.createElement("div", { className: "MenuItemContent " + item.name },
            React.createElement(semantic_ui_react_1.Icon, { name: item.icon, className: "mr-4 " + item.name }),
            React.createElement("span", null, trans_1.trans("menu.item." + item.name)));
    };
    return MenuBodyComponent;
}(responsive_component_1.ResponsiveComponent));
exports.MenuBodyComponent = MenuBodyComponent;
