"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventListCardComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var event_types_1 = require("../../../common/libs/event-types");
require("./event-list-card.component.scss");
var EventListCardComponent = (function (_super) {
    __extends(EventListCardComponent, _super);
    function EventListCardComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.eventTypes = event_types_1.eventTypes;
        _this.state = {
            item: _this.props.item
        };
        return _this;
    }
    EventListCardComponent.prototype.componentDidMount = function () {
        this.setState({ item: this.props.item });
    };
    EventListCardComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!lodash_1.isEqual(prevProps.item, this.props.item)) {
            this.setState({ item: this.props.item });
        }
    };
    EventListCardComponent.prototype.render = function () {
        var _a, _b, _c;
        var item = this.state.item;
        return React.createElement(semantic_ui_react_1.Message, { key: item._id, className: "EventListCard display-flex flex-row " + item.type },
            React.createElement("div", { className: 'icon-wrapper' },
                React.createElement(semantic_ui_react_1.Icon, { name: (_a = this.eventTypes[item.type]) === null || _a === void 0 ? void 0 : _a.icon })),
            React.createElement("div", { className: 'content-wrapper flex-fill overflow-auto' },
                React.createElement("section", { className: 'HappeningHeader display-flex' },
                    React.createElement("div", null,
                        React.createElement("span", null, trans_1.trans((_b = this.eventTypes[item.type]) === null || _b === void 0 ? void 0 : _b.title))),
                    React.createElement("div", null,
                        React.createElement("span", null, moment(item.createdAt).locale('hu').format('LLL')))),
                React.createElement("section", null,
                    React.createElement("p", null, (_c = this.eventTypes[item.type]) === null || _c === void 0 ? void 0 : _c.render(item)))));
    };
    return EventListCardComponent;
}(abstract_component_1.AbstractComponent));
exports.EventListCardComponent = EventListCardComponent;
