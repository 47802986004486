"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateUpdateComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var quill_form_control_type_1 = require("../../../../libs/form-control-types/quill.form-control-type");
var quill_with_image_1 = require("../../../../libs/quill-with-image");
var session_storage_1 = require("../../../../libs/session.storage");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var state_repository_1 = require("../../../common/libs/state-repository");
var source_selector_1 = require("./source-selector");
var content_variables_1 = require("./template-content/content-variables");
var TemplateUpdateComponent = (function (_super) {
    __extends(TemplateUpdateComponent, _super);
    function TemplateUpdateComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.enableFilters = false;
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        _this.repositoryDelete = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repositoryDelete');
        _this.state = {
            sources: []
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                name: new field_1.Field({
                    name: 'name',
                    label: 'Név',
                    placeholder: 'Email neve',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                description: new field_1.Field({
                    name: 'description',
                    label: 'Leírás',
                    placeholder: 'Leírás',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                subject: new field_1.Field({
                    name: 'subject',
                    label: 'Email subject',
                    placeholder: 'Email subject',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                category: new field_1.Field({
                    name: 'category',
                    label: 'Email csomag',
                    placeholder: 'Email csomag',
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: '/a/category',
                    optionsMap: function (response) { return response.items.map(function (item) { return ({
                        value: item._id,
                        key: item._id,
                        text: item.name
                    }); }); },
                    loadOptionsAfterMount: true,
                    options: [],
                    value: [],
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                })
            }
        });
        _this.defaultVariablesForm = new form_builder_1.FormBuilder({
            fields: {}
        });
        return _this;
    }
    TemplateUpdateComponent.prototype.getEndpoint = function () {
        return '/a/template';
    };
    TemplateUpdateComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.$subscriptions.push(this.form.$submit.subscribe(function () { return _this.onSubmit(); }));
                if (this.props._id) {
                    this.fetchData();
                }
                return [2];
            });
        });
    };
    TemplateUpdateComponent.prototype.componentDidUpdate = function (prevProps) {
        if (this.props._id !== prevProps._id) {
            this.fetchData();
        }
        if (this.props.updateTrigger !== prevProps.updateTrigger) {
            this.fetchData();
        }
    };
    TemplateUpdateComponent.prototype.fetchData = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result, _i, _c, variable, field;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.repository.get(this.getEndpoint() + "/" + this.props._id)];
                    case 1:
                        result = _d.sent();
                        this.form.updateFields(result);
                        if (result.category) {
                            this.form.fields.category.setValue(((_a = result.category) === null || _a === void 0 ? void 0 : _a._id) || result.category);
                        }
                        for (_i = 0, _c = result.hbsVariables || []; _i < _c.length; _i++) {
                            variable = _c[_i];
                            field = new field_1.Field({
                                name: variable,
                                label: trans_1.trans("email-editor." + variable),
                                placeholder: trans_1.trans("email-editor." + variable),
                                value: (_b = result === null || result === void 0 ? void 0 : result.defaultVariableValues) === null || _b === void 0 ? void 0 : _b[variable],
                                validators: [
                                    new required_validator_1.RequiredValidator('Kötelező mező')
                                ]
                            });
                            this.defaultVariablesForm.field(variable, field);
                        }
                        return [4, this.setState({
                                sources: result.sources || []
                            })];
                    case 2:
                        _d.sent();
                        return [2];
                }
            });
        });
    };
    TemplateUpdateComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, isValid1, isValid2, _a, update, create, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.props.canItUpdateCallback) return [3, 2];
                        return [4, this.props.canItUpdateCallback()];
                    case 1:
                        result = _b.sent();
                        if (!result) {
                            return [2];
                        }
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 12, , 13]);
                        return [4, this.form.validate()];
                    case 3:
                        isValid1 = _b.sent();
                        if (!this.props.isAdmin) return [3, 4];
                        _a = true;
                        return [3, 6];
                    case 4: return [4, this.defaultVariablesForm.validate()];
                    case 5:
                        _a = _b.sent();
                        _b.label = 6;
                    case 6:
                        isValid2 = _a;
                        if (!isValid1 || !isValid2) {
                            return [2, false];
                        }
                        if (!this.props._id) return [3, 8];
                        return [4, this.repository.put(this.getEndpoint() + "/" + this.props._id, {
                                data: __assign(__assign({}, this.form.toJSON()), { sources: this.state.sources, defaultVariableValues: this.defaultVariablesForm.toJSON() })
                            })];
                    case 7:
                        update = _b.sent();
                        if (this.props.onUpdate) {
                            this.props.onUpdate(update);
                        }
                        handle_toasts_1.HandleToasts.success('Sikeres módosítás');
                        return [2, true];
                    case 8: return [4, this.repository.post(this.getEndpoint(), {
                            data: __assign(__assign({}, this.form.toJSON()), { sources: [] })
                        })];
                    case 9:
                        create = _b.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres létrehozás');
                        if (!this.props.onCreate) return [3, 11];
                        this.props.onCreate(create);
                        return [4, this.modalRef.setState({ open: false })];
                    case 10:
                        _b.sent();
                        _b.label = 11;
                    case 11: return [2, true];
                    case 12:
                        e_1 = _b.sent();
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [2, false];
                    case 13: return [2];
                }
            });
        });
    };
    TemplateUpdateComponent.prototype.onDelete = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repositoryDelete.delete(this.getEndpoint() + "/" + this.props._id)];
                    case 1:
                        _a.sent();
                        if (this.props.onDelete) {
                            this.props.onDelete();
                        }
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    TemplateUpdateComponent.prototype.render = function () {
        var _this = this;
        if (this.props.disableModal) {
            return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.form.submit(); }, className: "w-100" }, this.renderContent());
        }
        return React.createElement(semantic_ui_react_1.Modal, { ref: function (ref) { return (_this.modalRef = ref); }, centered: false, size: this.props._id ? 'large' : 'small', trigger: this.renderTrigger(), onSubmit: function () { return _this.form.submit(); }, as: semantic_ui_react_1.Form }, this.renderContent());
    };
    TemplateUpdateComponent.prototype.renderTrigger = function () {
        if (this.props.children) {
            return this.props.children;
        }
        return this.props._id
            ? React.createElement(semantic_ui_react_1.Button, { color: "blue" }, "Email m\u00F3dos\u00EDt\u00E1sa")
            : React.createElement(semantic_ui_react_1.Button, { color: "blue" }, "\u00DAj email l\u00E9trehoz\u00E1sa");
    };
    TemplateUpdateComponent.prototype.renderContent = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        var doubleColumnValue = this.props.disableModal ? 1 : 2;
        return [
            React.createElement(semantic_ui_react_1.Modal.Content, { key: 1 },
                React.createElement(semantic_ui_react_1.Grid, { columns: this.props._id ? doubleColumnValue : 1 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { content: "\u00C1ltal\u00E1nos inform\u00E1ci\u00F3k" }),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.name, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.TextArea })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.category, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Dropdown, selection: true })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.subject, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input })),
                        React.createElement(detailed_error_1.DetailedError, { error: (_a = this.state.repository) === null || _a === void 0 ? void 0 : _a.error }),
                        !!((_e = (((_d = (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.repository) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.hbsVariables) || [])) === null || _e === void 0 ? void 0 : _e.length) &&
                            React.createElement(semantic_ui_react_1.Header, { content: "V\u00E1ltoz\u00F3k" }), (_j = (((_h = (_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.repository) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.hbsVariables) || [])) === null || _j === void 0 ? void 0 :
                        _j.map(function (item, index) { return _this.renderVariableInputField(item, item, _this.defaultVariablesForm.fields[item]); })),
                    !!((_k = this.state.sources) === null || _k === void 0 ? void 0 : _k.length) && React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { content: "Forr\u00E1sok" }), (_l = this.state.sources) === null || _l === void 0 ? void 0 :
                        _l.map(function (item, index) { return _this.renderSource(item, index); })))),
            React.createElement(semantic_ui_react_1.Modal.Actions, { key: 2 },
                React.createElement(semantic_ui_react_1.Button, { color: "blue", disabled: (_m = this.state.repository) === null || _m === void 0 ? void 0 : _m.loading, loading: (_o = this.state.repository) === null || _o === void 0 ? void 0 : _o.loading }, "Ment\u00E9s"))
        ];
    };
    TemplateUpdateComponent.prototype.renderVariableInputField = function (variable, key, field) {
        if (!field) {
            return null;
        }
        var isEscapable = content_variables_1.isContentVariableEscapable(variable);
        if (isEscapable) {
            return React.createElement(form_control_1.FormControl, { field: field, key: key, type: quill_form_control_type_1.QuillFormControlType, component: quill_with_image_1.QuillWithImage });
        }
        return React.createElement(form_control_1.FormControl, { field: field, key: key, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input }));
    };
    TemplateUpdateComponent.prototype.renderSource = function (item, index) {
        var _this = this;
        var _a;
        var onUpdate = function (payload) {
            _this.state.sources[index] = payload;
            _this.setState({
                sources: _this.state.sources
            });
        };
        return React.createElement(source_selector_1.SourceSelector, { enableFilters: this.enableFilters, enabledFilterIntegration: (_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id, key: index, source: item, originalSource: item, isSelected: true, onUpdate: function (payload) { return onUpdate(payload); }, shop: this.getCurrentShop(), showProductFilter: this.props.disableModal });
    };
    TemplateUpdateComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return TemplateUpdateComponent;
}(abstract_component_1.AbstractComponent));
exports.TemplateUpdateComponent = TemplateUpdateComponent;
