"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SweetSpotChart = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./sheetspot-chart.scss");
var SweetSpotChart = (function (_super) {
    __extends(SweetSpotChart, _super);
    function SweetSpotChart() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SweetSpotChart.prototype.render = function () {
        var _a, _b, _c;
        var processedEvent = this.props.processedEvent;
        if (!processedEvent) {
            return null;
        }
        if (!((_a = processedEvent === null || processedEvent === void 0 ? void 0 : processedEvent.sweetSpot) === null || _a === void 0 ? void 0 : _a.time)) {
            return null;
        }
        return React.createElement(semantic_ui_react_1.Message, { className: 'LeadStat' },
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(semantic_ui_react_1.Icon, { name: 'clock' })),
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("p", null, "Legakt\u00EDvabb id\u0151pont"),
                React.createElement("h2", null, moment(moment.utc((_c = (_b = this.props.processedEvent) === null || _b === void 0 ? void 0 : _b.sweetSpot) === null || _c === void 0 ? void 0 : _c.time, 'HH:mm:ss').toISOString()).format('HH:mm'))));
    };
    return SweetSpotChart;
}(abstract_component_1.AbstractComponent));
exports.SweetSpotChart = SweetSpotChart;
