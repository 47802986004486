"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteFilterModal = void 0;
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var handle_toasts_1 = require("../../libs/handle.toasts");
var state_repository_1 = require("../../libs/state-repository");
var DeleteFilterModal = (function (_super) {
    __extends(DeleteFilterModal, _super);
    function DeleteFilterModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.delete = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'delete');
        _this.form = new form_builder_1.FormBuilder({ fields: {} });
        return _this;
    }
    DeleteFilterModal.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.deleteFilter(); }));
    };
    DeleteFilterModal.prototype.deleteFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.delete.delete("/s/" + shop._id + "/filter/" + this.props._id)];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeresen törölted a szűrőt');
                        if (this.modalRef$) {
                            this.modalRef$.setState({ open: false });
                        }
                        window.location.reload();
                        return [3, 3];
                    case 2:
                        e_1 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sajnos hiba történt a művelet végrehajtása során');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    DeleteFilterModal.prototype.render = function () {
        var _this = this;
        if (!this.props.show) {
            return null;
        }
        return React.createElement(semantic_ui_react_1.Modal, { ref: function (ref) { return (_this.modalRef$ = ref); }, size: "tiny", trigger: this.renderTrigger(), content: this.renderContent() });
    };
    DeleteFilterModal.prototype.renderTrigger = function () {
        if (this.props.children) {
            return this.props.children;
        }
        return React.createElement(semantic_ui_react_1.Button, { basic: true, content: "Sz\u0171r\u0151 t\u00F6rl\u00E9se", fluid: true });
    };
    DeleteFilterModal.prototype.renderContent = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Segment, null,
            React.createElement("h3", null, "Sz\u0171r\u0151 t\u00F6rl\u00E9se"),
            React.createElement(semantic_ui_react_1.Divider, null),
            React.createElement("p", null, "Biztosan v\u00E9gleg t\u00F6r\u00F6lni szeretn\u00E9d a sz\u0171r\u0151t?"),
            React.createElement(semantic_ui_react_1.Divider, null),
            React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.form.submit(); } },
                React.createElement(semantic_ui_react_1.Button, { type: "submit", color: "blue" }, "T\u00F6rl\u00E9s"),
                React.createElement(semantic_ui_react_1.Button, { basic: true, onClick: function () { return _this.closeModal(); } }, "M\u00E9gse")));
    };
    DeleteFilterModal.prototype.closeModal = function () {
        if (this.modalRef$) {
            this.modalRef$.setState({ open: false });
        }
    };
    return DeleteFilterModal;
}(abstract_component_1.AbstractComponent));
exports.DeleteFilterModal = DeleteFilterModal;
