"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalize = void 0;
var capitalize = function (value) {
    if (!value) {
        return;
    }
    if (value.length <= 1) {
        return value.toUpperCase();
    }
    var firstLetter = value.slice(0, 1).toUpperCase();
    var carry = value.slice(1, value.length);
    return "" + firstLetter + carry;
};
exports.capitalize = capitalize;
