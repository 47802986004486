"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterMarketplaceShopNewsletterScreen = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../libs/session.storage");
var list_empty_1 = require("../common/components/list-empty/list-empty");
var list_loader_1 = require("../common/components/loader/list-loader");
var pagination_list_control_1 = require("../common/components/repository-list/controls/pagination-list-control");
var repository_list_1 = require("../common/components/repository-list/repository-list");
var state_repository_1 = require("../common/libs/state-repository");
var integration_template_variables_component_1 = require("./components/integration-template-variables.component");
require("./newsletter.screen.scss");
var toast_util_1 = require("../shoprenter/errors/toast.util");
var errors_constant_1 = require("../shoprenter/errors/errors.constant");
var ShoprenterMarketplaceShopNewsletterScreen = (function (_super) {
    __extends(ShoprenterMarketplaceShopNewsletterScreen, _super);
    function ShoprenterMarketplaceShopNewsletterScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.templates = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'templates');
        _this.seedLO = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'seedLastOnline');
        _this.state = {
            filter: '',
            approvedTemplatesCount: 0,
            showSystemStartMessage: false,
            templatesAlreadyChecked: false,
            prevApprovedTemplatesCount: 0,
            currentApprovedTemplatesCount: 0
        };
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                compact: true
            }
        });
        return _this;
    }
    ShoprenterMarketplaceShopNewsletterScreen.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (prevState.approvedTemplatesCount !== this.state.approvedTemplatesCount) {
                            this.props.onApprovedTemplatesCountChange(this.state.approvedTemplatesCount);
                        }
                        if (!(prevState.approvedTemplatesCount === 9 && this.state.approvedTemplatesCount === 10)) return [3, 2];
                        return [4, this.seedLastOnline()];
                    case 1:
                        _a.sent();
                        if (this.state.showSystemStartMessage) {
                            this.setState({ showSystemStartMessage: false });
                        }
                        return [3, 3];
                    case 2:
                        if (this.state.prevApprovedTemplatesCount === 9 && this.state.currentApprovedTemplatesCount === 10) {
                            this.setState({
                                showSystemStartMessage: true,
                                currentApprovedTemplatesCount: 10
                            });
                        }
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        });
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.refetch = function () {
        console.log('ShoprenterMarketplaceShopNewsletterScreen: refetching child data...');
        this.control.reload();
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.render = function () {
        var _this = this;
        var _a;
        var approvedTemplatesCount = this.props.approvedTemplatesCount >= 10;
        var warningMessage = !approvedTemplatesCount && this.state.showSystemStartMessage;
        return React.createElement("div", { className: "TemplatesScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement(semantic_ui_react_1.Grid, { verticalAlign: "middle" },
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, verticalAlign: "middle" },
                        approvedTemplatesCount &&
                            React.createElement(semantic_ui_react_1.Message, { success: true, size: 'small', className: 'display-flex', style: { width: '100%' } },
                                React.createElement(semantic_ui_react_1.Message.Content, { textAlign: 'center' },
                                    React.createElement("strong", null, "Legal\u00E1bb 10 lev\u00E9l j\u00F3v\u00E1hagy\u00E1sa sikeres volt!"))),
                        React.createElement(semantic_ui_react_1.Grid.Column, { verticalAlign: "middle" }, ' '),
                        React.createElement(semantic_ui_react_1.Grid.Column, { verticalAlign: "middle", className: 'display-flex' }, warningMessage &&
                            React.createElement(semantic_ui_react_1.Message, { warning: true, size: 'small', className: 'display-flex align-self-end', style: {
                                    width: 'fit-content',
                                    backgroundColor: '#83DFB5',
                                    boxShadow: "0px 0px 0px 1px #377eff inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)",
                                    color: "#051D40"
                                } },
                                React.createElement(semantic_ui_react_1.Message.Header, { textAlign: 'center', className: 'mr-2', style: { color: "#051D40" } }, "FONTOS:"),
                                React.createElement(semantic_ui_react_1.Message.Content, { textAlign: 'center' }, "Legal\u00E1bb 10 levelet j\u00F3v\u00E1 kell hagyni a rendszer indul\u00E1s\u00E1hoz!"))))),
                React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/template/shoprenter-marketplace-shop", renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } })));
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.checkApprovedTemplates = function (ctx) {
        return __awaiter(this, void 0, void 0, function () {
            var approvedCount;
            return __generator(this, function (_a) {
                if (!this.state.templatesAlreadyChecked) {
                    approvedCount = ctx.state.items.filter(function (item) { return !!item.isApproved; }).length;
                    this.setState({
                        templatesAlreadyChecked: true,
                        showSystemStartMessage: approvedCount < 10,
                        approvedTemplatesCount: approvedCount
                    });
                    this.props.refetch();
                }
                return [2];
            });
        });
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.renderItemsWrapper = function (ctx) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        var items = ((_a = ctx.state) === null || _a === void 0 ? void 0 : _a.items) || [];
        var grouped = lodash_1.groupBy(items, function (item) { return item.isApproved; });
        if (items.length > 0) {
            console.log('renderItemsWrapper approved templates', items.filter(function (item) { return !!item.isApproved; }).length);
            this.checkApprovedTemplates(ctx);
        }
        var filteredItems = items;
        if (this.state.filter === 'undecided' && ((_b = grouped === null || grouped === void 0 ? void 0 : grouped.null) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            filteredItems = grouped === null || grouped === void 0 ? void 0 : grouped.null;
        }
        else if (this.state.filter === 'approved' && ((_c = grouped === null || grouped === void 0 ? void 0 : grouped.true) === null || _c === void 0 ? void 0 : _c.length) > 0) {
            filteredItems = grouped === null || grouped === void 0 ? void 0 : grouped.true;
        }
        else if (this.state.filter === 'disapproved' && ((_d = grouped === null || grouped === void 0 ? void 0 : grouped.false) === null || _d === void 0 ? void 0 : _d.length) > 0) {
            filteredItems = grouped === null || grouped === void 0 ? void 0 : grouped.false;
        }
        var filterButtons = [
            {
                buttonText: 'Jóváhagyásra vár',
                filter: 'undecided',
                items: (_e = grouped === null || grouped === void 0 ? void 0 : grouped.null) === null || _e === void 0 ? void 0 : _e.length,
            },
            {
                buttonText: 'Jóváhagyva',
                filter: 'approved',
                items: (_f = grouped === null || grouped === void 0 ? void 0 : grouped.true) === null || _f === void 0 ? void 0 : _f.length,
            },
            {
                buttonText: 'Elutasítva',
                filter: 'disapproved',
                items: (_g = grouped === null || grouped === void 0 ? void 0 : grouped.false) === null || _g === void 0 ? void 0 : _g.length,
            },
        ];
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, { className: 'FilterButtons' }, filterButtons.map(function (button, index) {
                    return _this.renderFilterButton(button.buttonText, button.filter, button.items, index);
                }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                ctx.renderLoading(ctx),
                !ctx.state.loading && filteredItems.map(function (item, index) { return React.createElement(semantic_ui_react_1.Grid.Column, { key: index }, _this.renderItem(ctx, item, index)); })));
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var thereIsNoDecision = item.isApproved === null;
        return React.createElement(semantic_ui_react_1.Card, { key: ((_a = item === null || item === void 0 ? void 0 : item.template) === null || _a === void 0 ? void 0 : _a._id) + index, className: 'blue-border' },
            React.createElement(semantic_ui_react_1.Card.Content, { className: 'display-flex align-items-center' },
                !thereIsNoDecision && item.isApproved && React.createElement(semantic_ui_react_1.Icon, { name: "check circle", key: 0 }),
                !thereIsNoDecision && !item.isApproved && React.createElement(semantic_ui_react_1.Icon, { name: "minus circle", key: 0 }),
                !!thereIsNoDecision && React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", key: 0 }),
                React.createElement("p", { className: 'flex-fill mr-4' }, (_b = item === null || item === void 0 ? void 0 : item.template) === null || _b === void 0 ? void 0 : _b.name),
                React.createElement(integration_template_variables_component_1.IntegrationTemplateVariablesComponent, { templateId: (_c = item === null || item === void 0 ? void 0 : item.template) === null || _c === void 0 ? void 0 : _c._id, integrationTemplateId: (_d = item === null || item === void 0 ? void 0 : item.integrationTemplate) === null || _d === void 0 ? void 0 : _d._id, onSuccess: function () { return _this.control.reload(); }, subject: ((_e = item === null || item === void 0 ? void 0 : item.integrationTemplate) === null || _e === void 0 ? void 0 : _e.subject) || ((_f = item === null || item === void 0 ? void 0 : item.template) === null || _f === void 0 ? void 0 : _f.subject), onApproval: function () { return _this.setState({
                        approvedTemplatesCount: _this.state.approvedTemplatesCount + 1,
                        templatesAlreadyChecked: false,
                        showSystemStartMessage: (_this.state.approvedTemplatesCount + 1) < 10,
                        prevApprovedTemplatesCount: _this.state.approvedTemplatesCount,
                        currentApprovedTemplatesCount: _this.state.approvedTemplatesCount + 1
                    }); }, onDisapproval: function () {
                        console.log('onDisapproval', _this.state.approvedTemplatesCount);
                        _this.setState({
                            templatesAlreadyChecked: false,
                            showSystemStartMessage: (_this.state.approvedTemplatesCount - 1) < 10,
                            prevApprovedTemplatesCount: _this.state.approvedTemplatesCount,
                            currentApprovedTemplatesCount: _this.state.approvedTemplatesCount - 1
                        });
                    } })));
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.renderFilterButton = function (buttonText, filter, itemsAmount, index) {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { basic: this.state.filter !== filter, onClick: function () { return _this.filterOnClick(filter); }, disabled: !itemsAmount, key: index, className: 'blue-border' },
            buttonText,
            " (",
            itemsAmount ? itemsAmount : ' - ',
            ")");
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.seedLastOnline = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.seedLO.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/event/seed-last-online")];
                    case 1:
                        _b.sent();
                        toast_util_1.openSuccessToast('M.I. rendszer sikeresen elindítva!');
                        return [3, 3];
                    case 2:
                        e_1 = _b.sent();
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_START_MI_SYSTEM);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.filterOnClick = function (filter) {
        if (this.state.filter === filter) {
            return this.setState({ filter: '' });
        }
        return this.setState({ filter: filter });
    };
    ShoprenterMarketplaceShopNewsletterScreen.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return ShoprenterMarketplaceShopNewsletterScreen;
}(abstract_component_1.AbstractComponent));
exports.ShoprenterMarketplaceShopNewsletterScreen = ShoprenterMarketplaceShopNewsletterScreen;
