"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecommendedCategorySelectorComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var state_repository_1 = require("../../../common/libs/state-repository");
var RecommendedCategorySelectorComponent = (function (_super) {
    __extends(RecommendedCategorySelectorComponent, _super);
    function RecommendedCategorySelectorComponent() {
        var _a, _b;
        var _this = _super.apply(this, arguments) || this;
        _this.categories = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'categories');
        _this.settings = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'settings');
        _this.state = {
            open: false,
            shop: _this.props.shop,
            category: _this.props.category,
            openedCategories: [],
            listLoading: true,
            loading: false,
            selectLoading: false,
            recommendedCategories: ((_b = (_a = _this.props.category) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.recommendedCategories) || [],
        };
        return _this;
    }
    RecommendedCategorySelectorComponent.prototype.fetchCategories = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ listLoading: true });
                        return [4, this.categories.get("/s/" + ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/data/temporary/category")];
                    case 1:
                        _c.sent();
                        this.setState({ listLoading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        console.log(e_1);
                        this.setState({ listLoading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    RecommendedCategorySelectorComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { dimmer: 'inverted', onClose: function () { return _this.toggleOpen(false); }, onOpen: function () { return _this.toggleOpen(true); }, open: this.state.open, closeIcon: true, trigger: React.createElement(semantic_ui_react_1.Button, { basic: true, content: 'Választás' }) },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                React.createElement("div", { className: 'display-flex align-items-center' },
                    React.createElement("span", { className: 'flex-fill' }, "Aj\u00E1nlhat\u00F3 kateg\u00F3ria v\u00E1laszt\u00E1s"),
                    React.createElement(semantic_ui_react_1.Icon, { name: 'close', className: 'cursor-pointer', onClick: function () { return _this.toggleOpen(false); } }))),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", null, this.renderSelector())),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { primary: false, basic: true, secondary: true, onClick: function () { return _this.toggleOpen(false); } }, "M\u00E9gse"),
                React.createElement(semantic_ui_react_1.Button, { loading: this.state.loading, primary: true, onClick: function () { return _this.save(); } }, "Ment\u00E9s")));
    };
    RecommendedCategorySelectorComponent.prototype.renderSelector = function () {
        var _this = this;
        var _a, _b;
        if (this.state.listLoading) {
            return React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' });
        }
        return React.createElement("div", null, (_b = (_a = this.state.categories) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.items.map(function (c, index) { return _this.renderCategory(c, 0); }));
    };
    RecommendedCategorySelectorComponent.prototype.renderCategory = function (category, level) {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", { style: { marginLeft: level * 24 + "px" } },
            React.createElement("div", { className: 'display-flex flex-row align-items-center justify-content-start CheckButton p-4' },
                React.createElement(semantic_ui_react_1.Button, { loading: this.state.loading, disabled: this.state.loading, size: 'tiny', basic: true, icon: this.isSelected(category) ? 'check' : this.isChildSelected(category) ? 'minus' : '', onClick: function () { return _this.toggleRecommended(category); } }),
                React.createElement("span", { className: 'ml-2' }, (_a = category === null || category === void 0 ? void 0 : category.data) === null || _a === void 0 ? void 0 : _a.name),
                !!((_b = category === null || category === void 0 ? void 0 : category.data) === null || _b === void 0 ? void 0 : _b.children.length) &&
                    React.createElement(semantic_ui_react_1.Icon, { className: 'ml-4 cursor-pointer', name: this.isOpenedCategory(category) ? 'chevron up' : 'chevron down', onClick: function () { return _this.toggleOpenedCategory(category); } })),
            this.isOpenedCategory(category) && ((_c = category === null || category === void 0 ? void 0 : category.data) === null || _c === void 0 ? void 0 : _c.children.map(function (c, i) { return _this.renderCategory(c, level + 1); })));
    };
    RecommendedCategorySelectorComponent.prototype.isSelected = function (category) {
        return this.state.recommendedCategories.includes(category === null || category === void 0 ? void 0 : category.identifier);
    };
    RecommendedCategorySelectorComponent.prototype.isChildSelected = function (category) {
        var _a;
        var l = false;
        for (var _i = 0, _b = (_a = category === null || category === void 0 ? void 0 : category.data) === null || _a === void 0 ? void 0 : _a.children; _i < _b.length; _i++) {
            var c = _b[_i];
            l = l || this.isSelected(c) || this.isChildSelected(c);
        }
        return l;
    };
    RecommendedCategorySelectorComponent.prototype.isOpenedCategory = function (category) {
        var _a;
        return (_a = this.state) === null || _a === void 0 ? void 0 : _a.openedCategories.includes(category === null || category === void 0 ? void 0 : category.identifier);
    };
    RecommendedCategorySelectorComponent.prototype.toggleOpenedCategory = function (category) {
        var openedCategories = this.state.openedCategories;
        if (this.isOpenedCategory(category)) {
            return this.setState({ openedCategories: openedCategories.filter(function (rc) { return rc !== (category === null || category === void 0 ? void 0 : category.identifier); }) });
        }
        return this.setState({ openedCategories: __spreadArrays(openedCategories, [category === null || category === void 0 ? void 0 : category.identifier]) });
    };
    RecommendedCategorySelectorComponent.prototype.toggleRecommended = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isSelected(category)) return [3, 2];
                        return [4, this.addCategory(category)];
                    case 1:
                        _a.sent();
                        return [2];
                    case 2: return [4, this.removeCategory(category)];
                    case 3:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    RecommendedCategorySelectorComponent.prototype.removeCategory = function (category) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var recommendedCategories;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        recommendedCategories = this.state.recommendedCategories;
                        return [4, this.setState({ recommendedCategories: recommendedCategories.filter(function (rc) { return rc !== (category === null || category === void 0 ? void 0 : category.identifier); }) })];
                    case 1:
                        _c.sent();
                        if ((_a = category === null || category === void 0 ? void 0 : category.data) === null || _a === void 0 ? void 0 : _a.children.length) {
                            (_b = category === null || category === void 0 ? void 0 : category.data) === null || _b === void 0 ? void 0 : _b.children.map(function (c) { return _this.removeCategory(c); });
                        }
                        return [2];
                }
            });
        });
    };
    RecommendedCategorySelectorComponent.prototype.addCategory = function (category) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var recommendedCategories;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        recommendedCategories = this.state.recommendedCategories;
                        return [4, this.setState({ recommendedCategories: __spreadArrays(recommendedCategories, [category === null || category === void 0 ? void 0 : category.identifier]) })];
                    case 1:
                        _c.sent();
                        if ((_a = category === null || category === void 0 ? void 0 : category.data) === null || _a === void 0 ? void 0 : _a.children.length) {
                            (_b = category === null || category === void 0 ? void 0 : category.data) === null || _b === void 0 ? void 0 : _b.children.map(function (c) { return _this.addCategory(c); });
                        }
                        return [2];
                }
            });
        });
    };
    RecommendedCategorySelectorComponent.prototype.toggleOpen = function (value) {
        if (value) {
            this.fetchCategories();
        }
        this.setState({ open: value });
    };
    RecommendedCategorySelectorComponent.prototype.save = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.settings.put("/s/" + ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/data/settings/" + ((_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.category) === null || _d === void 0 ? void 0 : _d._id), {
                                data: {
                                    key: 'recommendedCategories',
                                    value: this.state.recommendedCategories || []
                                }
                            })];
                    case 1:
                        _e.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success('Sikeresen módosítottad az ajánlható kategóriákat!');
                        this.toggleOpen(false);
                        return [3, 3];
                    case 2:
                        e_2 = _e.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error('Hiba történt!');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return RecommendedCategorySelectorComponent;
}(abstract_component_1.AbstractComponent));
exports.RecommendedCategorySelectorComponent = RecommendedCategorySelectorComponent;
