"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedFiltersBar = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var state_repository_1 = require("../../libs/state-repository");
require("./saved-filters-bar.scss");
var SavedFiltersBar = (function (_super) {
    __extends(SavedFiltersBar, _super);
    function SavedFiltersBar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filters = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'filters');
        return _this;
    }
    SavedFiltersBar.prototype.componentDidMount = function () {
        this.fetch();
    };
    SavedFiltersBar.prototype.fetch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.filters.get("/s/" + shop._id + "/filter")];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    SavedFiltersBar.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(semantic_ui_react_1.Grid.Column, { className: 'display-flex SavedFilterBar flex-wrap' },
            this.renderPrebuildFilters(), (_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.filters) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 :
            _c.map(function (item) { return React.createElement(semantic_ui_react_1.Button, { className: "mb-2 mr-2", key: item._id, basic: !_this.isSelected(item._id), icon: _this.isSelected(item._id), size: !!_this.props.buttonSize ? _this.props.buttonSize : null, onClick: function () { return _this.onClick(item); } },
                _this.isSelected(item._id) && React.createElement(semantic_ui_react_1.Icon, { name: "check" }),
                _this.isSelected(item._id) && React.createElement("span", null, "\u00A0\u00A0"),
                item.name); }));
    };
    SavedFiltersBar.prototype.isSelected = function (id) {
        var _a, _b;
        return ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.toString()) === id;
    };
    SavedFiltersBar.prototype.onClick = function (i) {
        if (this.props.onSelect) {
            this.props.onSelect(i);
        }
    };
    SavedFiltersBar.prototype.renderPrebuildFilters = function () {
        var _this = this;
        var _a;
        var filters = [
            {
                name: 'Feliratkozottak',
                data: {
                    _id: '1',
                    name: null,
                    description: null,
                    payload: [{
                            type: 'StatusFilterExpression',
                            operator: 'equals',
                            value: 'subscribed'
                        }],
                }
            },
            {
                name: 'Összes vásárló',
                data: {
                    _id: '2',
                    name: null,
                    description: null,
                    payload: [{
                            type: 'OrderedAmountSumFilterExpression',
                            operator: 'greaterThanEquals',
                            value: 0
                        }],
                }
            },
            {
                name: 'Aktív érdeklődő',
                data: {
                    _id: '3',
                    name: null,
                    description: null,
                    payload: [{
                            type: 'ActiveCustomerFilterExpression',
                            operator: 'lowerThanEquals',
                            value: new Date()
                        }],
                }
            },
            {
                name: 'Van kosara',
                data: {
                    _id: 'has-cart',
                    name: null,
                    description: null,
                    payload: [{
                            type: 'IncartSumPriceFilterExpression',
                            operator: 'greaterThanEquals',
                            value: 0
                        }],
                }
            },
        ];
        var selectFilter = (_a = router_provider_1.RouterProvider.getQuery()) === null || _a === void 0 ? void 0 : _a.selectFilter;
        if (selectFilter) {
            if (!this.isSelected(selectFilter)) {
                var filter = filters.find(function (item) { return item.data._id === selectFilter; });
                if (filter) {
                    this.onClick(filter.data);
                }
            }
        }
        return React.createElement(React.Fragment, null,
            !this.props.regular && React.createElement(semantic_ui_react_1.Button, { className: "mb-2 mr-2", basic: !this.isSelected('0'), onClick: function () { var _a; return (_a = _this.props) === null || _a === void 0 ? void 0 : _a.onDelete(); }, size: !!this.props.buttonSize ? this.props.buttonSize : null, icon: this.isSelected('0') ? 'check' : false },
                this.isSelected('0') && React.createElement(semantic_ui_react_1.Icon, { name: "check" }),
                this.isSelected('0') && React.createElement("span", null, "\u00A0\u00A0"),
                "\u00D6sszes"),
            filters.map(function (item, index) { return React.createElement(semantic_ui_react_1.Button, { className: "mb-2 mr-2", key: index, icon: _this.isSelected(item.data._id), basic: !_this.isSelected(item.data._id), size: !!_this.props.buttonSize ? _this.props.buttonSize : null, onClick: function () { return _this.onClick(item.data); } },
                _this.isSelected(item.data._id) && React.createElement(semantic_ui_react_1.Icon, { name: "check" }),
                _this.isSelected(item.data._id) && React.createElement("span", null, "\u00A0\u00A0"),
                item.name); }));
    };
    return SavedFiltersBar;
}(abstract_component_1.AbstractComponent));
exports.SavedFiltersBar = SavedFiltersBar;
