"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePasswordComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var equals_to_validator_1 = require("@codebuild/uikit/libs/form/validators/equals-to.validator");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var state_repository_1 = require("../../../common/libs/state-repository");
var password_input_1 = require("../../../common/semantic/password-input/password-input");
var password_strength_1 = require("../../../common/semantic/password-strength/password-strength");
var ChangePasswordComponent = (function (_super) {
    __extends(ChangePasswordComponent, _super);
    function ChangePasswordComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userRepository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'user');
        _this.state = {
            open: false,
            error: null,
            loading: false
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                oldPassword: new field_1.Field({
                    name: 'oldPassword',
                    placeholder: '',
                    label: trans_1.trans('settings.profile.change-password.old.label'),
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('required')
                    ]
                }),
                newPassword: new field_1.Field({
                    name: 'newPassword',
                    placeholder: '',
                    label: trans_1.trans('settings.profile.change-password.new.label'),
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('required')
                    ]
                }),
                newRePassword: new field_1.Field({
                    name: 'newRePassword',
                    placeholder: '',
                    label: trans_1.trans('settings.profile.change-password.re-new.label'),
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                        new equals_to_validator_1.EqualsToValidator('newPassword', 'Mismatch')
                    ]
                }),
            }
        });
        return _this;
    }
    ChangePasswordComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.handleSubmitSuccess(); }));
    };
    ChangePasswordComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { size: 'mini', onClose: function () { return _this.setOpen(false); }, onOpen: function () { return _this.setOpen(true); }, open: this.state.open, trigger: this.renderTrigger() },
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Header, { as: 'h3' }, trans_1.trans('settings.profile.change-password.title')),
                React.createElement("div", { className: 'mb-6' }, this.renderForm()),
                React.createElement(semantic_ui_react_1.Grid, null,
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'left' },
                            React.createElement(semantic_ui_react_1.Button, { basic: true, color: 'blue', onClick: function () { return _this.setOpen(false); }, content: trans_1.trans('general.button.cancel.title'), disabled: this.state.loading })),
                        React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'right' },
                            React.createElement(semantic_ui_react_1.Button, { color: 'blue', content: trans_1.trans('general.button.save.title'), onClick: function () { return _this.form.submit(); }, loading: this.state.loading, disabled: this.state.loading }))))));
    };
    ChangePasswordComponent.prototype.renderForm = function () {
        return React.createElement(semantic_ui_react_1.Form, null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.oldPassword, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-old-password" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.newPassword, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-new-password" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.newPassword, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType, component: password_strength_1.PasswordStrength }),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.newRePassword, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-new-re-password" })),
            React.createElement(detailed_error_1.DetailedError, { error: this.state.error }));
    };
    ChangePasswordComponent.prototype.handleSubmitSuccess = function () {
        return __awaiter(this, void 0, void 0, function () {
            var payload, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.setState({ error: null, loading: true });
                        payload = this.form.toJSON();
                        return [4, this.userRepository.put('/u/user/changePassword', { data: payload })];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success(trans_1.trans('setting.profile.change-password.success'));
                        this.setState({ error: false, loading: false });
                        this.setOpen(false);
                        return [3, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.setState({ error: e_1, loading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ChangePasswordComponent.prototype.renderTrigger = function () {
        return React.createElement("a", { className: 'cursor-pointer' }, trans_1.trans('settings.profile.user-profile.change-password.button.title'));
    };
    ChangePasswordComponent.prototype.setOpen = function (open) {
        this.form.updateFields({});
        this.form.clear();
        this.setState({ open: open, loading: false, error: false });
    };
    return ChangePasswordComponent;
}(abstract_component_1.AbstractComponent));
exports.ChangePasswordComponent = ChangePasswordComponent;
