"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsScreen = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var customer_details_component_1 = require("../../components/customer-details/customer-details.component");
require("./customer-details.screens.scss");
var CustomerDetailsScreen = (function (_super) {
    __extends(CustomerDetailsScreen, _super);
    function CustomerDetailsScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerDetailsScreen.prototype.render = function () {
        return React.createElement("div", { className: "LeadDetailsScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true }, this.renderComponent()));
    };
    CustomerDetailsScreen.prototype.renderComponent = function () {
        var useProcessedEvent = this.props.useProcessedEvent;
        var useCustomer = this.props.useCustomer;
        if (useProcessedEvent) {
            return React.createElement(customer_details_component_1.CustomerDetailsComponent, { identifier: this.props.route.params.peId, useProcessedEvent: this.props.useProcessedEvent, useCustomer: this.props.useCustomer });
        }
        if (useCustomer) {
            return React.createElement(customer_details_component_1.CustomerDetailsComponent, { identifier: this.props.route.params.cId, useProcessedEvent: this.props.useProcessedEvent, useCustomer: this.props.useCustomer });
        }
        return null;
    };
    return CustomerDetailsScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomerDetailsScreen = CustomerDetailsScreen;
