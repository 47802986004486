"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shoprenterAppsListMenu = void 0;
exports.shoprenterAppsListMenu = [
    {
        name: 'active-shops',
        identifier: 'active-shops',
        icon: 'check',
    },
    {
        name: 'registering-shops',
        identifier: 'registering-shops',
        icon: 'sync',
    },
    {
        name: 'installed-waiting-first-payment',
        identifier: 'installed-waiting-first-payment',
        icon: 'pause',
    },
    {
        name: 'uninstalled-before-first-payment',
        identifier: 'uninstalled-before-first-payment',
        icon: 'undo',
    },
    {
        name: 'paid-deleted-shops',
        identifier: 'paid-deleted-shops',
        icon: 'delete',
    },
    {
        name: 'all-shops',
        identifier: 'all-shops',
        icon: 'list',
    },
];
