"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'auth.forgot-password.request.description': 'Elfelejtett jelszó esetén add meg az email címed amivel regisztráltál. Ezután a küldés gomb megnyomásával küldünk Neked egy emailt a megadott email címre amivel aztán új jelszót készíthetsz. ',
    'auth.forgot-password.reset.description': 'Kérlek a jelszóváltoztatáshoz add meg az új jelszavadat. ',
    'auth.forgot-password.request-success.header': 'Az email kiküldése sikeres',
    'auth.forgot-password.request-success.description': 'Szuper! Az email kiküldésre került a megadott email címre. Az emailben megkaptad a további lépéseket a jelszóváltoztatással kapcsolatban.  ',
    'auth.registration.form.accepted-terms-of-use.label': React.createElement("label", null,
        "Elfogadom az ",
        React.createElement("a", { target: "_blank", href: "https://miclub.hu/aszf", onClick: function (e) { return e.stopPropagation(); } }, " \u00E1ltal\u00E1nos szerz\u0151d\u00E9si felt\u00E9telek"),
        "et \u00E9s az ",
        React.createElement("a", { target: "_blank", href: "https://miclub.hu/adatvedelem", onClick: function (e) { return e.stopPropagation(); } }, "adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3"),
        "t"),
    'auth.registration.form.subscribe-newsletter.label': React.createElement("label", null, "Szeretn\u00E9k h\u00EDrlevelet kapni az M.I. Club exkluz\u00EDv aj\u00E1nlatair\u00F3l \u00E9s a megjelen\u0151 \u00FAjdons\u00E1gokr\u00F3l."),
    'auth.registration.social-button.facebook': 'Regisztráció Facebook fiókkal',
    'auth.registration.social-button.google': 'Regisztráció Google fiókkal',
    'auth.login.social-button.facebook': 'Belépés Facebook fiókkal',
    'auth.login.social-button.google': 'Belépés Google fiókkal',
};
