"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordInput = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var PasswordInput = (function (_super) {
    __extends(PasswordInput, _super);
    function PasswordInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            revealPassword: false
        };
        return _this;
    }
    PasswordInput.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Input, __assign({}, this.props, { type: this.state.revealPassword ? 'text' : 'password', icon: this.renderRevealIcon() }));
    };
    PasswordInput.prototype.renderRevealIcon = function () {
        var _this = this;
        if (!this.props.enableReveal) {
            return null;
        }
        return React.createElement(semantic_ui_react_1.Icon, { name: this.state.revealPassword ? 'eye slash' : 'eye', link: true, onClick: function () { return _this.toggleReveal(); } });
    };
    PasswordInput.prototype.toggleReveal = function () {
        var revealPassword = this.state.revealPassword;
        this.setState({ revealPassword: !revealPassword });
    };
    return PasswordInput;
}(abstract_component_1.AbstractComponent));
exports.PasswordInput = PasswordInput;
