"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomNewsletterTemplateContentComponent = void 0;
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../../libs/session.storage");
var template_content_component_1 = require("./template-content.component");
var CustomNewsletterTemplateContentComponent = (function (_super) {
    __extends(CustomNewsletterTemplateContentComponent, _super);
    function CustomNewsletterTemplateContentComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomNewsletterTemplateContentComponent.prototype.getEndpoint = function () {
        var _a;
        return "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template";
    };
    CustomNewsletterTemplateContentComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    CustomNewsletterTemplateContentComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        return [
            React.createElement(semantic_ui_react_1.Button, { basic: true, key: 0, loading: ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.accessToken) === null || _b === void 0 ? void 0 : _b.loading) || ((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.sources) === null || _d === void 0 ? void 0 : _d.loading), onClick: function () { return _this.getAccessToken(); } },
                React.createElement(semantic_ui_react_1.Icon, { name: "pencil" }),
                "Sablon szerkeszt\u00E9se"),
            React.createElement("input", { key: 1, style: { position: 'absolute', padding: 0, opacity: 0, zIndex: -1, maxWidth: 0, maxHeight: 0 }, type: 'file', ref: function (ref) { return (_this.fileInputRef = ref); } }),
            this.state.modalSpot
        ];
    };
    return CustomNewsletterTemplateContentComponent;
}(template_content_component_1.TemplateContentComponent));
exports.CustomNewsletterTemplateContentComponent = CustomNewsletterTemplateContentComponent;
