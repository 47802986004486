"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailSettingsComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var handle_toasts_1 = require("../../../../common/libs/handle.toasts");
var state_repository_1 = require("../../../../common/libs/state-repository");
require("./email.component.scss");
var EmailSettingsComponent = (function (_super) {
    __extends(EmailSettingsComponent, _super);
    function EmailSettingsComponent() {
        var _a, _b;
        var _this = _super.apply(this, arguments) || this;
        _this.mailgunCredentials = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'mailgunCredentials');
        _this.mailgunTest = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'mailgunTest');
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: _this.props.shop,
            updateLoading: false,
            updateError: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                senderEmail: new field_1.Field({
                    name: 'senderEmail',
                    placeholder: '',
                    label: trans_1.trans('settings.email.mailgun-credentials.senderEmail.label'),
                    value: ((_a = _this.props.shop) === null || _a === void 0 ? void 0 : _a.senderEmail) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                mailgunDomain: new field_1.Field({
                    name: 'mailgunDomain',
                    placeholder: '',
                    label: trans_1.trans('settings.email.mailgun-credentials.mailgunDomain.label'),
                    value: ((_b = _this.props.shop) === null || _b === void 0 ? void 0 : _b.mailgunDomain) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                })
            }
        });
        return _this;
    }
    EmailSettingsComponent.prototype.componentDidMount = function () {
        this.fetchMailgunSettings();
        this.updateFormData();
    };
    EmailSettingsComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _this = this;
        if (!lodash_1.isEqual(this.props.shop, prevProps.shop)) {
            this.setState({ shop: this.props.shop }, function () {
                _this.fetchMailgunSettings();
                _this.updateFormData();
            });
        }
    };
    EmailSettingsComponent.prototype.updateFormData = function () {
        var _a, _b;
        for (var _i = 0, _c = Object.keys(this.form.fields); _i < _c.length; _i++) {
            var key = _c[_i];
            if (!((_a = this.state) === null || _a === void 0 ? void 0 : _a.shop)) {
                continue;
            }
            this.form.fields[key].setValue((_b = this.state) === null || _b === void 0 ? void 0 : _b.shop[key]);
        }
    };
    EmailSettingsComponent.prototype.fetchMailgunSettings = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.setState({ mailgunCredentials: null })];
                    case 1:
                        _b.sent();
                        return [4, this.mailgunCredentials.get("/s/" + ((_a = this.state) === null || _a === void 0 ? void 0 : _a.shop._id) + "/shop/domain")];
                    case 2:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    EmailSettingsComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'EmailComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, trans_1.trans('settings.email.title')))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderMailGunSenderForm())),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement("h5", null, "E-mail k\u00FCld\u00E9s \u00E1llapota a DNS \u00E9rt\u00E9keknek megfelel\u0151en"),
                        React.createElement("div", { className: 'mb-4' }, this.renderEmailSendingStatusByDNSRecords()),
                        React.createElement("h5", null, "DNS records for sending"),
                        React.createElement("div", { className: 'mb-4' }, this.renderSendingDnsRecords()),
                        React.createElement("div", { className: 'mb-4' }, this.renderMxRecords()),
                        React.createElement("h5", null, "DNS record for tracking"),
                        React.createElement("div", { className: 'mb-4' }, this.renderTrackingRecords())))),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { content: 'Test mailgun', onClick: function () { return _this.testMailgun(); } })));
    };
    EmailSettingsComponent.prototype.testMailgun = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.mailgunTest.post("/s/" + ((_a = this.state) === null || _a === void 0 ? void 0 : _a.shop._id) + "/shop/mailgunTest")];
                    case 1:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    EmailSettingsComponent.prototype.renderEmailSendingStatusByDNSRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.mailgunStatus) {
            return React.createElement("div", null,
                "Enged\u00E9lyezve, minden rekord \u00E9rv\u00E9nyes.",
                React.createElement("br", null),
                "Utolj\u00E1ra ellen\u0151rizve: ",
                new Date((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.shop) === null || _d === void 0 ? void 0 : _d.mailgunStatusUpdatedAt).toLocaleDateString(),
                ",",
                new Date((_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.shop) === null || _f === void 0 ? void 0 : _f.mailgunStatusUpdatedAt).toLocaleTimeString());
        }
        return React.createElement("div", null,
            "Letiltva, \u00E9rv\u00E9nytelen rekordok.",
            React.createElement("br", null),
            "Utolj\u00E1ra ellen\u0151rizve: ",
            new Date((_h = (_g = this.state) === null || _g === void 0 ? void 0 : _g.shop) === null || _h === void 0 ? void 0 : _h.mailgunStatusUpdatedAt).toLocaleDateString(),
            ",",
            new Date((_k = (_j = this.state) === null || _j === void 0 ? void 0 : _j.shop) === null || _k === void 0 ? void 0 : _k.mailgunStatusUpdatedAt).toLocaleTimeString());
    };
    EmailSettingsComponent.prototype.renderMailGunSenderForm = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Form, null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.senderEmail, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.mailgunDomain, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.submitMailGunCredentials(); }, content: 'Mentés' })));
    };
    EmailSettingsComponent.prototype.renderSendingDnsRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        if (!((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.mailgunCredentials) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.text)) {
            return React.createElement("div", { className: 'p-8' },
                React.createElement(semantic_ui_react_1.Loader, { active: true }));
        }
        var records = (_g = JSON.parse(((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.mailgunCredentials) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.text) || '')) === null || _g === void 0 ? void 0 : _g.sending_dns_records;
        return React.createElement(semantic_ui_react_1.Table, { className: 'w-100 MailgunCredentials', celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 1 }, "Type"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 3 }, "Hostname"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 6 }, "Enter this value"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 6 }, "Current value"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_h = records[0]) === null || _h === void 0 ? void 0 : _h.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_j = records[0]) === null || _j === void 0 ? void 0 : _j.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_k = records[0]) === null || _k === void 0 ? void 0 : _k.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_m = (_l = records[0]) === null || _l === void 0 ? void 0 : _l.cached) === null || _m === void 0 ? void 0 : _m.length) && ((_o = records[0]) === null || _o === void 0 ? void 0 : _o.valid) === 'valid') ? 'valid' : 'invalid') }, ((_q = (_p = records[0]) === null || _p === void 0 ? void 0 : _p.cached) === null || _q === void 0 ? void 0 : _q.length) ? (_r = records[0]) === null || _r === void 0 ? void 0 : _r.cached : 'Not found')),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_s = records[1]) === null || _s === void 0 ? void 0 : _s.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_t = records[1]) === null || _t === void 0 ? void 0 : _t.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_u = records[1]) === null || _u === void 0 ? void 0 : _u.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_w = (_v = records[1]) === null || _v === void 0 ? void 0 : _v.cached) === null || _w === void 0 ? void 0 : _w.length) && ((_x = records[1]) === null || _x === void 0 ? void 0 : _x.valid) === 'valid') ? 'valid' : 'invalid') }, ((_z = (_y = records[1]) === null || _y === void 0 ? void 0 : _y.cached) === null || _z === void 0 ? void 0 : _z.length) ? (_0 = records[1]) === null || _0 === void 0 ? void 0 : _0.cached : 'Not found')),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_1 = records[3]) === null || _1 === void 0 ? void 0 : _1.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_2 = records[3]) === null || _2 === void 0 ? void 0 : _2.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_3 = records[3]) === null || _3 === void 0 ? void 0 : _3.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_5 = (_4 = records[3]) === null || _4 === void 0 ? void 0 : _4.cached) === null || _5 === void 0 ? void 0 : _5.length) && ((_6 = records[3]) === null || _6 === void 0 ? void 0 : _6.valid) === 'valid') ? 'valid' : 'invalid') }, ((_8 = (_7 = records[3]) === null || _7 === void 0 ? void 0 : _7.cached) === null || _8 === void 0 ? void 0 : _8.length) ? (_9 = records[3]) === null || _9 === void 0 ? void 0 : _9.cached : 'Not found'))));
    };
    EmailSettingsComponent.prototype.renderMxRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        if (!((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.mailgunCredentials) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.text)) {
            return React.createElement("div", { className: 'p-8' },
                React.createElement(semantic_ui_react_1.Loader, { active: true }));
        }
        var records = (_g = JSON.parse(((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.mailgunCredentials) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.text) || '')) === null || _g === void 0 ? void 0 : _g.receiving_dns_records;
        var name = (_m = (_l = JSON.parse(((_k = (_j = (_h = this.state) === null || _h === void 0 ? void 0 : _h.mailgunCredentials) === null || _j === void 0 ? void 0 : _j.response) === null || _k === void 0 ? void 0 : _k.text) || '')) === null || _l === void 0 ? void 0 : _l.domain) === null || _m === void 0 ? void 0 : _m.name;
        return React.createElement(semantic_ui_react_1.Table, { className: 'w-100 MailgunCredentials', celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 1 }, "Type"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 2 }, "Hostname"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 1 }, "Priority"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 6 }, "Enter this value"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 6 }, "Current value"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_o = records[0]) === null || _o === void 0 ? void 0 : _o.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, name),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_p = records[0]) === null || _p === void 0 ? void 0 : _p.priority),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_q = records[0]) === null || _q === void 0 ? void 0 : _q.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_s = (_r = records[0]) === null || _r === void 0 ? void 0 : _r.cached) === null || _s === void 0 ? void 0 : _s.length) && ((_t = records[0]) === null || _t === void 0 ? void 0 : _t.valid) === 'valid') ? 'valid' : 'invalid') }, ((_v = (_u = records[0]) === null || _u === void 0 ? void 0 : _u.cached) === null || _v === void 0 ? void 0 : _v.length) ? (_w = records[0]) === null || _w === void 0 ? void 0 : _w.cached : 'Not found')),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_x = records[1]) === null || _x === void 0 ? void 0 : _x.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, name),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_y = records[1]) === null || _y === void 0 ? void 0 : _y.priority),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_z = records[1]) === null || _z === void 0 ? void 0 : _z.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_1 = (_0 = records[1]) === null || _0 === void 0 ? void 0 : _0.cached) === null || _1 === void 0 ? void 0 : _1.length) && ((_2 = records[1]) === null || _2 === void 0 ? void 0 : _2.valid) === 'valid') ? 'valid' : 'invalid') }, ((_4 = (_3 = records[1]) === null || _3 === void 0 ? void 0 : _3.cached) === null || _4 === void 0 ? void 0 : _4.length) ? (_5 = records[1]) === null || _5 === void 0 ? void 0 : _5.cached : 'Not found'))));
    };
    EmailSettingsComponent.prototype.renderTrackingRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        if (!((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.mailgunCredentials) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.text)) {
            return React.createElement("div", { className: 'p-8' },
                React.createElement(semantic_ui_react_1.Loader, { active: true }));
        }
        var records = (_g = JSON.parse(((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.mailgunCredentials) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.text) || '')) === null || _g === void 0 ? void 0 : _g.sending_dns_records;
        return React.createElement(semantic_ui_react_1.Table, { className: 'w-100 MailgunCredentials', celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 1 }, "Type"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 3 }, "Hostname"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 6 }, "Enter this value"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 6 }, "Current value"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_h = records[2]) === null || _h === void 0 ? void 0 : _h.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, (_j = records[2]) === null || _j === void 0 ? void 0 : _j.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_k = records[2]) === null || _k === void 0 ? void 0 : _k.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_m = (_l = records[2]) === null || _l === void 0 ? void 0 : _l.cached) === null || _m === void 0 ? void 0 : _m.length) && ((_o = records[2]) === null || _o === void 0 ? void 0 : _o.valid) === 'valid') ? 'valid' : 'invalid') }, ((_q = (_p = records[2]) === null || _p === void 0 ? void 0 : _p.cached) === null || _q === void 0 ? void 0 : _q.length) ? (_r = records[2]) === null || _r === void 0 ? void 0 : _r.cached : 'Not found'))));
    };
    EmailSettingsComponent.prototype.submitMailGunCredentials = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var valid, senderEmail, mailgunDomain, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        return [4, this.form.validate()];
                    case 1:
                        valid = _d.sent();
                        if (!valid) {
                            return [2];
                        }
                        senderEmail = (_a = this.form.toJSON()) === null || _a === void 0 ? void 0 : _a.senderEmail;
                        mailgunDomain = (_b = this.form.toJSON()) === null || _b === void 0 ? void 0 : _b.mailgunDomain;
                        return [4, this.shop.put("/a/shop/" + ((_c = this.props.shop) === null || _c === void 0 ? void 0 : _c._id) + "/mailgunSettings", {
                                data: {
                                    senderEmail: senderEmail,
                                    mailgunDomain: mailgunDomain
                                }
                            })];
                    case 2:
                        _d.sent();
                        handle_toasts_1.HandleToasts.success(trans_1.trans('Sikeres módosítás!'));
                        if (this.props.onUpdate) {
                            this.props.onUpdate();
                        }
                        return [3, 4];
                    case 3:
                        e_1 = _d.sent();
                        console.log(e_1);
                        handle_toasts_1.HandleToasts.error(trans_1.trans('Hiba történt!'));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return EmailSettingsComponent;
}(abstract_component_1.AbstractComponent));
exports.EmailSettingsComponent = EmailSettingsComponent;
