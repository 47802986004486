"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEmpty = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./list-empty.scss");
var ListEmpty = (function (_super) {
    __extends(ListEmpty, _super);
    function ListEmpty() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListEmpty.prototype.render = function () {
        return this.props.table
            ? this.renderTableRow()
            : this.renderDiv();
    };
    ListEmpty.prototype.renderDiv = function () {
        return React.createElement("div", null,
            React.createElement("h3", { className: 'p-5 my-5 display-flex justify-content-center align-items-center' },
                React.createElement(semantic_ui_react_1.Icon.Group, { size: 'large' },
                    React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'grey', name: 'dont' }),
                    React.createElement(semantic_ui_react_1.Icon, { size: 'small', color: 'grey', name: 'list ul' }))));
    };
    ListEmpty.prototype.renderTableRow = function () {
        return React.createElement(semantic_ui_react_1.Table.Row, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: this.props.tableColspan || 1 },
                React.createElement("h3", { className: 'p-5 my-5 display-flex justify-content-center align-items-center' },
                    React.createElement(semantic_ui_react_1.Icon.Group, { size: 'large' },
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'grey', name: 'dont' }),
                        React.createElement(semantic_ui_react_1.Icon, { size: 'small', color: 'grey', name: 'list ul' })))));
    };
    return ListEmpty;
}(abstract_component_1.AbstractComponent));
exports.ListEmpty = ListEmpty;
