"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentVariableComponent = void 0;
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var ContentVariableComponent = (function (_super) {
    __extends(ContentVariableComponent, _super);
    function ContentVariableComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContentVariableComponent.prototype.getCategories = function () {
        var _a, _b;
        var contextVariables = (_a = this.props.variables) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return /^Context_/.test(item === null || item === void 0 ? void 0 : item.valueKey); });
        var genericVariables = (_b = this.props.variables) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return /^Variable_/.test(item === null || item === void 0 ? void 0 : item.valueKey); });
        return [
            {
                title: 'Kontextus változók',
                description: 'Ezek a változók minden hírlevélben, felhasználónként kapnak értéket',
                items: contextVariables
            },
            {
                title: 'Változók',
                description: 'Ezek a változók az áruház tulajdonos álltal kapnak értéket az hírlevlek szerkesztésénél',
                items: genericVariables
            }
        ];
    };
    ContentVariableComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { open: true, onClose: function () { return _this.props.onClose(); } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "V\u00E1ltoz\u00F3 kiv\u00E1laszt\u00E1sa"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Grid, { columns: this.getCategories().length },
                    this.getCategories().map(function (item, index) { return React.createElement(semantic_ui_react_1.Grid.Column, { key: index },
                        React.createElement(semantic_ui_react_1.Header, { as: "h3", content: item.title, subheader: item.description })); }),
                    this.getCategories().map(function (item, index) { return React.createElement(semantic_ui_react_1.Grid.Column, { key: index }, item.items.map(function (sItem, sIndex) { return React.createElement(semantic_ui_react_1.Segment, { columns: 2, key: sIndex },
                        React.createElement("div", { className: "display-flex" },
                            React.createElement("div", { className: "flex-fill display-flex align-items-center" },
                                React.createElement(semantic_ui_react_1.Header, { as: "h5", content: sItem.key, subheader: sItem.description })),
                            React.createElement("div", { className: "display-flex align-items-center" },
                                React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.props.onSelect(sItem); }, icon: "add" })))); })); }))));
    };
    return ContentVariableComponent;
}(React.Component));
exports.ContentVariableComponent = ContentVariableComponent;
