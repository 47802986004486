"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLOR_OPTIONS = void 0;
exports.COLOR_OPTIONS = [
    '#001427',
    '#708D81',
    '#F4D58D',
    '#BF0603',
    '#8D0801',
    '#242038',
    '#9067C6',
    '#8D86C9',
    '#CAC4CE',
    '#F7ECE1',
    '#4ECDC4',
    '#FF6B6B',
    '#FFE66D',
    '#004777',
    '#00AFB5',
    '#FF7700',
    '#E8AA14',
    '#533B4D',
    '#F564A9',
    '#576066',
    '#2C514C',
    '#95818D',
    '#E3C0D3',
    '#C4B2BC',
    '#D8D4F2',
    '#92DCE5',
    '#D64933'
];
