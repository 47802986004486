"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.miniCrmCountries = void 0;
exports.miniCrmCountries = [
    {
        key: 7840,
        value: 'Abházia',
        text: 'Abházia'
    },
    {
        key: 93,
        value: 'Afganisztán',
        text: 'Afganisztán'
    },
    {
        key: 355,
        value: 'Albánia',
        text: 'Albánia'
    },
    {
        key: 213,
        value: 'Algéria',
        text: 'Algéria'
    },
    {
        key: 1684,
        value: 'Amerikai Szamoa',
        text: 'Amerikai Szamoa'
    },
    {
        key: 1340,
        value: 'Amerikai Virgin-szigetek',
        text: 'Amerikai Virgin-szigetek'
    },
    {
        key: 376,
        value: 'Andorra',
        text: 'Andorra'
    },
    {
        key: 244,
        value: 'Angola',
        text: 'Angola'
    },
    {
        key: 1264,
        value: 'Anguilla',
        text: 'Anguilla'
    },
    {
        key: 1268,
        value: 'Antigua és Barbuda',
        text: 'Antigua és Barbuda'
    },
    {
        key: 54,
        value: 'Argentína',
        text: 'Argentína'
    },
    {
        key: 297,
        value: 'Aruba',
        text: 'Aruba'
    },
    {
        key: 247,
        value: 'Ascension-sziget',
        text: 'Ascension-sziget'
    },
    {
        key: 61,
        value: 'Ausztrália',
        text: 'Ausztrália'
    },
    {
        key: 672,
        value: 'Ausztrália külbirtoka',
        text: 'Ausztrália külbirtoka'
    },
    {
        key: 43,
        value: 'Ausztria',
        text: 'Ausztria'
    },
    {
        key: 994,
        value: 'Azerbajdzsán',
        text: 'Azerbajdzsán'
    },
    {
        key: 1242,
        value: 'Bahama-szigetek',
        text: 'Bahama-szigetek'
    },
    {
        key: 973,
        value: 'Bahrein',
        text: 'Bahrein'
    },
    {
        key: 880,
        value: 'Banglades',
        text: 'Banglades'
    },
    {
        key: 1246,
        value: 'Barbados',
        text: 'Barbados'
    },
    {
        key: 88217,
        value: 'Barbuda',
        text: 'Barbuda'
    },
    {
        key: 32,
        value: 'Belgium',
        text: 'Belgium'
    },
    {
        key: 501,
        value: 'Belize',
        text: 'Belize'
    },
    {
        key: 229,
        value: 'Benin',
        text: 'Benin'
    },
    {
        key: 1441,
        value: 'Bermuda',
        text: 'Bermuda'
    },
    {
        key: 975,
        value: 'Bhután',
        text: 'Bhután'
    },
    {
        key: 245,
        value: 'Bissau-Guinea',
        text: 'Bissau-Guinea'
    },
    {
        key: 591,
        value: 'Bolívia',
        text: 'Bolívia'
    },
    {
        key: 387,
        value: 'Bosznia-Hercegovina',
        text: 'Bosznia-Hercegovina'
    },
    {
        key: 267,
        value: 'Botswana',
        text: 'Botswana'
    },
    {
        key: 55,
        value: 'Brazília',
        text: 'Brazília'
    },
    {
        key: 246,
        value: 'Brit Indiai-óceáni Terület',
        text: 'Brit Indiai-óceáni Terület'
    },
    {
        key: 1284,
        value: 'Brit Virgin-szigetek',
        text: 'Brit Virgin-szigetek'
    },
    {
        key: 673,
        value: 'Brunei',
        text: 'Brunei'
    },
    {
        key: 359,
        value: 'Bulgária',
        text: 'Bulgária'
    },
    {
        key: 226,
        value: 'Burkina Faso',
        text: 'Burkina Faso'
    },
    {
        key: 257,
        value: 'Burundi',
        text: 'Burundi'
    },
    {
        key: 64,
        value: 'Chatham-szigetek (Új-Zéland)',
        text: 'Chatham-szigetek (Új-Zéland)'
    },
    {
        key: 56,
        value: 'Chile',
        text: 'Chile'
    },
    {
        key: 357,
        value: 'Ciprus',
        text: 'Ciprus'
    },
    {
        key: 269,
        value: 'Comore-szigetek',
        text: 'Comore-szigetek'
    },
    {
        key: 682,
        value: 'Cook Szigetek',
        text: 'Cook Szigetek'
    },
    {
        key: 506,
        value: 'Costa Rica',
        text: 'Costa Rica'
    },
    {
        key: 235,
        value: 'Csád',
        text: 'Csád'
    },
    {
        key: 420,
        value: 'Cseh Köztársaság',
        text: 'Cseh Köztársaság'
    },
    {
        key: 599,
        value: 'Curacao',
        text: 'Curacao'
    },
    {
        key: 45,
        value: 'Dánia',
        text: 'Dánia'
    },
    {
        key: 27,
        value: 'Dél-afrikai Köztársaság',
        text: 'Dél-afrikai Köztársaság'
    },
    {
        key: 82,
        value: 'Dél-Korea',
        text: 'Dél-Korea'
    },
    {
        key: 88233,
        value: 'Déli-Georgia és Déli-Sandwich-szigetek',
        text: 'Déli-Georgia és Déli-Sandwich-szigetek'
    },
    {
        key: 88222,
        value: 'Diego Garcia',
        text: 'Diego Garcia'
    },
    {
        key: 1767,
        value: 'Dominikai Közösség',
        text: 'Dominikai Közösség'
    },
    {
        key: 1809,
        value: 'Dominikai köztársaság',
        text: 'Dominikai köztársaság'
    },
    {
        key: 253,
        value: 'Dzsibuti',
        text: 'Dzsibuti'
    },
    {
        key: 593,
        value: 'Ecuador',
        text: 'Ecuador'
    },
    {
        key: 240,
        value: 'Egyenlítői-Guinea',
        text: 'Egyenlítői-Guinea'
    },
    {
        key: 971,
        value: 'Egyesült Arab Emírségek',
        text: 'Egyesült Arab Emírségek'
    },
    {
        key: 44,
        value: 'Egyesült Királyság',
        text: 'Egyesült Királyság'
    },
    {
        key: 20,
        value: 'Egyiptom',
        text: 'Egyiptom'
    },
    {
        key: 503,
        value: 'El Salvador',
        text: 'El Salvador'
    },
    {
        key: 225,
        value: 'Elefántcsontpart',
        text: 'Elefántcsontpart'
    },
    {
        key: 291,
        value: 'Eritrea',
        text: 'Eritrea'
    },
    {
        key: 1670,
        value: 'Észak Mariana szigetek',
        text: 'Észak Mariana szigetek'
    },
    {
        key: 850,
        value: 'Észak-Korea',
        text: 'Észak-Korea'
    },
    {
        key: 389,
        value: 'Észak-macedón Köztársaság',
        text: 'Észak-macedón Köztársaság'
    },
    {
        key: 372,
        value: 'Észtország',
        text: 'Észtország'
    },
    {
        key: 251,
        value: 'Etiópia',
        text: 'Etiópia'
    },
    {
        key: 500,
        value: 'Falkland Szigetek',
        text: 'Falkland Szigetek'
    },
    {
        key: 375,
        value: 'Fehéroroszország',
        text: 'Fehéroroszország'
    },
    {
        key: 298,
        value: 'Feröer',
        text: 'Feröer'
    },
    {
        key: 679,
        value: 'Fidzsi',
        text: 'Fidzsi'
    },
    {
        key: 358,
        value: 'Finnország',
        text: 'Finnország'
    },
    {
        key: 596,
        value: 'Francia antillák',
        text: 'Francia antillák'
    },
    {
        key: 594,
        value: 'Francia Guiana',
        text: 'Francia Guiana'
    },
    {
        key: 689,
        value: 'Francia Polinézia',
        text: 'Francia Polinézia'
    },
    {
        key: 33,
        value: 'Franciaország',
        text: 'Franciaország'
    },
    {
        key: 63,
        value: 'Fülöp-szigetek',
        text: 'Fülöp-szigetek'
    },
    {
        key: 241,
        value: 'Gabon',
        text: 'Gabon'
    },
    {
        key: 220,
        value: 'Gambia',
        text: 'Gambia'
    },
    {
        key: 233,
        value: 'Ghána',
        text: 'Ghána'
    },
    {
        key: 350,
        value: 'Gibraltár',
        text: 'Gibraltár'
    },
    {
        key: 30,
        value: 'Görögország',
        text: 'Görögország'
    },
    {
        key: 1473,
        value: 'Grenada',
        text: 'Grenada'
    },
    {
        key: 299,
        value: 'Grönland',
        text: 'Grönland'
    },
    {
        key: 995,
        value: 'Grúzia',
        text: 'Grúzia'
    },
    {
        key: 590,
        value: 'Guadeloupe',
        text: 'Guadeloupe'
    },
    {
        key: 1671,
        value: 'Guam',
        text: 'Guam'
    },
    {
        key: 88224,
        value: 'Guantanamo Bay',
        text: 'Guantanamo Bay'
    },
    {
        key: 502,
        value: 'Guatemala',
        text: 'Guatemala'
    },
    {
        key: 224,
        value: 'Guinea',
        text: 'Guinea'
    },
    {
        key: 592,
        value: 'Guyana',
        text: 'Guyana'
    },
    {
        key: 509,
        value: 'Haiti',
        text: 'Haiti'
    },
    {
        key: 88226,
        value: 'Holland Antillák',
        text: 'Holland Antillák'
    },
    {
        key: 31,
        value: 'Hollandia',
        text: 'Hollandia'
    },
    {
        key: 504,
        value: 'Honduras',
        text: 'Honduras'
    },
    {
        key: 852,
        value: 'Hongkong',
        text: 'Hongkong'
    },
    {
        key: 385,
        value: 'Horvátország',
        text: 'Horvátország'
    },
    {
        key: 88223,
        value: 'Húsvét Szigetek',
        text: 'Húsvét Szigetek'
    },
    {
        key: 91,
        value: 'India',
        text: 'India'
    },
    {
        key: 62,
        value: 'Indonézia',
        text: 'Indonézia'
    },
    {
        key: 870,
        value: 'Inmarsat SNAC',
        text: 'Inmarsat SNAC'
    },
    {
        key: 964,
        value: 'Irak',
        text: 'Irak'
    },
    {
        key: 98,
        value: 'Irán',
        text: 'Irán'
    },
    {
        key: 353,
        value: 'Írország',
        text: 'Írország'
    },
    {
        key: 354,
        value: 'Izland',
        text: 'Izland'
    },
    {
        key: 972,
        value: 'Izrael',
        text: 'Izrael'
    },
    {
        key: 1876,
        value: 'Jamaika',
        text: 'Jamaika'
    },
    {
        key: 81,
        value: 'Japán',
        text: 'Japán'
    },
    {
        key: 967,
        value: 'Jemen',
        text: 'Jemen'
    },
    {
        key: 962,
        value: 'Jordánia',
        text: 'Jordánia'
    },
    {
        key: 1345,
        value: 'Kajmán-szigetek',
        text: 'Kajmán-szigetek'
    },
    {
        key: 855,
        value: 'Kambodzsa',
        text: 'Kambodzsa'
    },
    {
        key: 237,
        value: 'Kamerun',
        text: 'Kamerun'
    },
    {
        key: 88235,
        value: 'Kanada',
        text: 'Kanada'
    },
    {
        key: 88239,
        value: 'Kanári szigetek',
        text: 'Kanári szigetek'
    },
    {
        key: 88218,
        value: 'Karácsony szigetek',
        text: 'Karácsony szigetek'
    },
    {
        key: 974,
        value: 'Katar',
        text: 'Katar'
    },
    {
        key: 76,
        value: 'Kazahsztán',
        text: 'Kazahsztán'
    },
    {
        key: 670,
        value: 'Kelet Timor',
        text: 'Kelet Timor'
    },
    {
        key: 254,
        value: 'Kenya',
        text: 'Kenya'
    },
    {
        key: 86,
        value: 'Kína',
        text: 'Kína'
    },
    {
        key: 996,
        value: 'Kirgizisztán',
        text: 'Kirgizisztán'
    },
    {
        key: 686,
        value: 'Kiribati',
        text: 'Kiribati'
    },
    {
        key: 88219,
        value: 'Kókusz (Keeling)-szigetek',
        text: 'Kókusz (Keeling)-szigetek'
    },
    {
        key: 57,
        value: 'Kolumbia',
        text: 'Kolumbia'
    },
    {
        key: 242,
        value: 'Kongó',
        text: 'Kongó'
    },
    {
        key: 88220,
        value: 'Kongó - Brazzaville',
        text: 'Kongó - Brazzaville'
    },
    {
        key: 88221,
        value: 'Kongó - Kinshasa',
        text: 'Kongó - Kinshasa'
    },
    {
        key: 243,
        value: 'Kongó, Zaire',
        text: 'Kongó, Zaire'
    },
    {
        key: 236,
        value: 'Közép-Afrika',
        text: 'Közép-Afrika'
    },
    {
        key: 53,
        value: 'Kuba',
        text: 'Kuba'
    },
    {
        key: 965,
        value: 'Kuvait',
        text: 'Kuvait'
    },
    {
        key: 856,
        value: 'Laosz',
        text: 'Laosz'
    },
    {
        key: 48,
        value: 'Lengyelország',
        text: 'Lengyelország'
    },
    {
        key: 266,
        value: 'Lesotho',
        text: 'Lesotho'
    },
    {
        key: 371,
        value: 'Lettország',
        text: 'Lettország'
    },
    {
        key: 961,
        value: 'Libanon',
        text: 'Libanon'
    },
    {
        key: 231,
        value: 'Libéria',
        text: 'Libéria'
    },
    {
        key: 218,
        value: 'Líbia',
        text: 'Líbia'
    },
    {
        key: 423,
        value: 'Liechtenstein',
        text: 'Liechtenstein'
    },
    {
        key: 370,
        value: 'Litvánia',
        text: 'Litvánia'
    },
    {
        key: 352,
        value: 'Luxemburg',
        text: 'Luxemburg'
    },
    {
        key: 261,
        value: 'Madagaszkár',
        text: 'Madagaszkár'
    },
    {
        key: 36,
        value: 'Magyarország',
        text: 'Magyarország'
    },
    {
        key: 853,
        value: 'Makaó',
        text: 'Makaó'
    },
    {
        key: 60,
        value: 'Malajzia',
        text: 'Malajzia'
    },
    {
        key: 265,
        value: 'Malawi',
        text: 'Malawi'
    },
    {
        key: 960,
        value: 'Maldív-szigetek',
        text: 'Maldív-szigetek'
    },
    {
        key: 223,
        value: 'Mali',
        text: 'Mali'
    },
    {
        key: 356,
        value: 'Málta',
        text: 'Málta'
    },
    {
        key: 88238,
        value: 'Man Sziget',
        text: 'Man Sziget'
    },
    {
        key: 212,
        value: 'Marokkó',
        text: 'Marokkó'
    },
    {
        key: 692,
        value: 'Marshall-szigetek',
        text: 'Marshall-szigetek'
    },
    {
        key: 88225,
        value: 'Martinique',
        text: 'Martinique'
    },
    {
        key: 222,
        value: 'Mauritánia',
        text: 'Mauritánia'
    },
    {
        key: 230,
        value: 'Mauritius',
        text: 'Mauritius'
    },
    {
        key: 88229,
        value: 'Mayotte',
        text: 'Mayotte'
    },
    {
        key: 52,
        value: 'Mexikó',
        text: 'Mexikó'
    },
    {
        key: 95,
        value: 'Mianmar',
        text: 'Mianmar'
    },
    {
        key: 1808,
        value: 'Midway-atoll',
        text: 'Midway-atoll'
    },
    {
        key: 691,
        value: 'Mikronézia',
        text: 'Mikronézia'
    },
    {
        key: 373,
        value: 'Moldova',
        text: 'Moldova'
    },
    {
        key: 377,
        value: 'Monaco',
        text: 'Monaco'
    },
    {
        key: 976,
        value: 'Mongólia',
        text: 'Mongólia'
    },
    {
        key: 382,
        value: 'Montenegró',
        text: 'Montenegró'
    },
    {
        key: 1664,
        value: 'Montserrat',
        text: 'Montserrat'
    },
    {
        key: 258,
        value: 'Mozambik',
        text: 'Mozambik'
    },
    {
        key: 264,
        value: 'Namíbia',
        text: 'Namíbia'
    },
    {
        key: 674,
        value: 'Nauru',
        text: 'Nauru'
    },
    {
        key: 49,
        value: 'Németország',
        text: 'Németország'
    },
    {
        key: 977,
        value: 'Nepál',
        text: 'Nepál'
    },
    {
        key: 1869,
        value: 'Nevis',
        text: 'Nevis'
    },
    {
        key: 687,
        value: 'New Caledonia',
        text: 'New Caledonia'
    },
    {
        key: 505,
        value: 'Nicaragua',
        text: 'Nicaragua'
    },
    {
        key: 227,
        value: 'Niger',
        text: 'Niger'
    },
    {
        key: 234,
        value: 'Nigéria',
        text: 'Nigéria'
    },
    {
        key: 683,
        value: 'Niue',
        text: 'Niue'
    },
    {
        key: 88228,
        value: 'Norfolk Szigetek',
        text: 'Norfolk Szigetek'
    },
    {
        key: 47,
        value: 'Norvégia',
        text: 'Norvégia'
    },
    {
        key: 39,
        value: 'Olaszország',
        text: 'Olaszország'
    },
    {
        key: 968,
        value: 'Omán',
        text: 'Omán'
    },
    {
        key: 374,
        value: 'Örményország',
        text: 'Örményország'
    },
    {
        key: 7,
        value: 'Oroszország',
        text: 'Oroszország'
    },
    {
        key: 92,
        value: 'Pakisztán',
        text: 'Pakisztán'
    },
    {
        key: 680,
        value: 'Palau',
        text: 'Palau'
    },
    {
        key: 970,
        value: 'Palesztina',
        text: 'Palesztina'
    },
    {
        key: 507,
        value: 'Panama',
        text: 'Panama'
    },
    {
        key: 675,
        value: 'Pápua Új-Guinea',
        text: 'Pápua Új-Guinea'
    },
    {
        key: 595,
        value: 'Paraguay',
        text: 'Paraguay'
    },
    {
        key: 51,
        value: 'Peru',
        text: 'Peru'
    },
    {
        key: 351,
        value: 'Portugália',
        text: 'Portugália'
    },
    {
        key: 1787,
        value: 'Puerto Rico',
        text: 'Puerto Rico'
    },
    {
        key: 262,
        value: 'Réunion',
        text: 'Réunion'
    },
    {
        key: 40,
        value: 'Románia',
        text: 'Románia'
    },
    {
        key: 250,
        value: 'Ruanda',
        text: 'Ruanda'
    },
    {
        key: 88230,
        value: 'Saint Barthélemy',
        text: 'Saint Barthélemy'
    },
    {
        key: 88232,
        value: 'Saint Kitts és Nevis',
        text: 'Saint Kitts és Nevis'
    },
    {
        key: 1758,
        value: 'Saint Lucia',
        text: 'Saint Lucia'
    },
    {
        key: 88231,
        value: 'Saint Martin',
        text: 'Saint Martin'
    },
    {
        key: 1784,
        value: 'Saint Vincent és a Grenadine-szigetek',
        text: 'Saint Vincent és a Grenadine-szigetek'
    },
    {
        key: 508,
        value: 'Saint-Pierre és Miquelon',
        text: 'Saint-Pierre és Miquelon'
    },
    {
        key: 677,
        value: 'Salamon-szigetek',
        text: 'Salamon-szigetek'
    },
    {
        key: 378,
        value: 'San Marino',
        text: 'San Marino'
    },
    {
        key: 239,
        value: 'São Tomé és Príncipe',
        text: 'São Tomé és Príncipe'
    },
    {
        key: 248,
        value: 'Seychelle-szigetek',
        text: 'Seychelle-szigetek'
    },
    {
        key: 232,
        value: 'Sierra Leone',
        text: 'Sierra Leone'
    },
    {
        key: 1721,
        value: 'Sint Maarten',
        text: 'Sint Maarten'
    },
    {
        key: 34,
        value: 'Spanyolország',
        text: 'Spanyolország'
    },
    {
        key: 94,
        value: 'Srí Lanka',
        text: 'Srí Lanka'
    },
    {
        key: 597,
        value: 'Suriname',
        text: 'Suriname'
    },
    {
        key: 41,
        value: 'Svájc',
        text: 'Svájc'
    },
    {
        key: 46,
        value: 'Svédország',
        text: 'Svédország'
    },
    {
        key: 685,
        value: 'Szamoa',
        text: 'Szamoa'
    },
    {
        key: 966,
        value: 'Szaúd-Arábia',
        text: 'Szaúd-Arábia'
    },
    {
        key: 221,
        value: 'Szenegál',
        text: 'Szenegál'
    },
    {
        key: 290,
        value: 'Szent Ilona',
        text: 'Szent Ilona'
    },
    {
        key: 381,
        value: 'Szerbia',
        text: 'Szerbia'
    },
    {
        key: 65,
        value: 'Szingapúr',
        text: 'Szingapúr'
    },
    {
        key: 963,
        value: 'Szíria',
        text: 'Szíria'
    },
    {
        key: 421,
        value: 'Szlovákia',
        text: 'Szlovákia'
    },
    {
        key: 386,
        value: 'Szlovénia',
        text: 'Szlovénia'
    },
    {
        key: 252,
        value: 'Szomália',
        text: 'Szomália'
    },
    {
        key: 249,
        value: 'Szudán',
        text: 'Szudán'
    },
    {
        key: 268,
        value: 'Szváziföld',
        text: 'Szváziföld'
    },
    {
        key: 992,
        value: 'Tádzsikisztán',
        text: 'Tádzsikisztán'
    },
    {
        key: 886,
        value: 'Tajvan',
        text: 'Tajvan'
    },
    {
        key: 255,
        value: 'Tanzánia',
        text: 'Tanzánia'
    },
    {
        key: 66,
        value: 'Thaiföld',
        text: 'Thaiföld'
    },
    {
        key: 88234,
        value: 'Timor Leste',
        text: 'Timor Leste'
    },
    {
        key: 228,
        value: 'Togo',
        text: 'Togo'
    },
    {
        key: 690,
        value: 'Tokelau-szigetek',
        text: 'Tokelau-szigetek'
    },
    {
        key: 676,
        value: 'Tonga',
        text: 'Tonga'
    },
    {
        key: 90,
        value: 'Törökország',
        text: 'Törökország'
    },
    {
        key: 1868,
        value: 'Trinidad és Tobago',
        text: 'Trinidad és Tobago'
    },
    {
        key: 216,
        value: 'Tunézia',
        text: 'Tunézia'
    },
    {
        key: 993,
        value: 'Türkmenisztán',
        text: 'Türkmenisztán'
    },
    {
        key: 1649,
        value: 'Turks- és Caicos-szigetek',
        text: 'Turks- és Caicos-szigetek'
    },
    {
        key: 688,
        value: 'Tuvalu',
        text: 'Tuvalu'
    },
    {
        key: 256,
        value: 'Uganda',
        text: 'Uganda'
    },
    {
        key: 88227,
        value: 'Új-Zéland',
        text: 'Új-Zéland'
    },
    {
        key: 380,
        value: 'Ukrajna',
        text: 'Ukrajna'
    },
    {
        key: 598,
        value: 'Uruguay',
        text: 'Uruguay'
    },
    {
        key: 1,
        value: 'USA',
        text: 'USA'
    },
    {
        key: 998,
        value: 'Üzbegisztán',
        text: 'Üzbegisztán'
    },
    {
        key: 678,
        value: 'Vanuatu',
        text: 'Vanuatu'
    },
    {
        key: 39066,
        value: 'Vatikán',
        text: 'Vatikán'
    },
    {
        key: 58,
        value: 'Venezuela',
        text: 'Venezuela'
    },
    {
        key: 84,
        value: 'Vietnam',
        text: 'Vietnam'
    },
    {
        key: 88236,
        value: 'Wake Sziget',
        text: 'Wake Sziget'
    },
    {
        key: 681,
        value: 'Wallis és Futuna',
        text: 'Wallis és Futuna'
    },
    {
        key: 260,
        value: 'Zambia',
        text: 'Zambia'
    },
    {
        key: 88237,
        value: 'Zanzibár',
        text: 'Zanzibár'
    },
    {
        key: 263,
        value: 'Zimbabwe',
        text: 'Zimbabwe'
    },
    {
        key: 238,
        value: 'Zöld-foki Köztársaság',
        text: 'Zöld-foki Köztársaság'
    }
];
