"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContinueLaterComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var logout_component_1 = require("../../../authentication/components/logout.component");
var ContinueLaterComponent = (function (_super) {
    __extends(ContinueLaterComponent, _super);
    function ContinueLaterComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            open: false
        };
        return _this;
    }
    ContinueLaterComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { centered: true, closeIcon: true, size: 'mini', onClose: function () { return _this.setOpen(false); }, onOpen: function () { return _this.setOpen(true); }, open: this.state.open },
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Modal.Description, { className: 'mb-6' },
                    React.createElement(semantic_ui_react_1.Header, null, trans_1.trans('shop-registration.continue-later.title')),
                    React.createElement("p", null, trans_1.trans('shop-registration.continue-later.description'))),
                React.createElement(semantic_ui_react_1.Grid, null,
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, centered: false },
                        React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'left' },
                            React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.setOpen(false); }, basic: true, color: 'blue', content: trans_1.trans('shop-registration.continue-later.button.cancel') })),
                        React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'right' },
                            React.createElement(semantic_ui_react_1.Button, { basic: false, primary: true, color: 'blue', onClick: function () { return logout_component_1.LogoutComponent.logout(); }, content: trans_1.trans('shop-registration.continue-later.button.logout') }))))));
    };
    ContinueLaterComponent.prototype.setOpen = function (value) {
        this.setState({ open: value });
    };
    return ContinueLaterComponent;
}(abstract_component_1.AbstractComponent));
exports.ContinueLaterComponent = ContinueLaterComponent;
