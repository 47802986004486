"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomNewsletterTemplateUpdateComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var state_repository_1 = require("../../../common/libs/state-repository");
var template_update_component_1 = require("./template-update.component");
var CustomNewsletterTemplateUpdateComponent = (function (_super) {
    __extends(CustomNewsletterTemplateUpdateComponent, _super);
    function CustomNewsletterTemplateUpdateComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.enableFilters = true;
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        _this.repositoryDelete = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repositoryDelete');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                name: new field_1.Field({
                    name: 'name',
                    label: 'Név',
                    placeholder: 'Email neve',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                description: new field_1.Field({
                    name: 'description',
                    label: 'Leírás',
                    placeholder: 'Leírás',
                    value: '',
                    validators: []
                }),
                subject: new field_1.Field({
                    name: 'subject',
                    label: 'Email subject',
                    placeholder: 'Email subject',
                    value: '',
                    validators: []
                }),
            }
        });
        return _this;
    }
    CustomNewsletterTemplateUpdateComponent.prototype.getEndpoint = function () {
        var _a;
        return "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template";
    };
    CustomNewsletterTemplateUpdateComponent.prototype.renderTrigger = function () {
        if (this.props.children) {
            return this.props.children;
        }
        return this.props._id
            ? React.createElement(semantic_ui_react_1.Button, { className: this.props.className }, "Email m\u00F3dos\u00EDt\u00E1sa")
            : React.createElement(semantic_ui_react_1.Button, { className: this.props.className, icon: "plus", content: "\u00DAj l\u00E9trehoz\u00E1sa" });
    };
    CustomNewsletterTemplateUpdateComponent.prototype.renderContent = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        var doubleColumnValue = this.props.disableModal ? 1 : 2;
        return [
            React.createElement(semantic_ui_react_1.Modal.Content, { key: 1 },
                React.createElement(semantic_ui_react_1.Grid, { columns: this.props._id ? doubleColumnValue : 1 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        !this.props.disableModal && React.createElement(semantic_ui_react_1.Header, { content: "\u00C1ltal\u00E1nos inform\u00E1ci\u00F3k" }),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.name, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.TextArea })),
                        React.createElement(detailed_error_1.DetailedError, { error: (_a = this.state.repository) === null || _a === void 0 ? void 0 : _a.error }),
                        !this.props.disableModal && !!((_e = (((_d = (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.repository) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.hbsVariables) || [])) === null || _e === void 0 ? void 0 : _e.length) && React.createElement(semantic_ui_react_1.Header, { content: "V\u00E1ltoz\u00F3k" }), (_j = (((_h = (_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.repository) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.hbsVariables) || [])) === null || _j === void 0 ? void 0 :
                        _j.map(function (item, index) { return _this.renderVariableInputField(item, item, _this.defaultVariablesForm.fields[item]); })),
                    !!((_k = this.state.sources) === null || _k === void 0 ? void 0 : _k.length) && React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { content: "Forr\u00E1sok" }), (_l = this.state.sources) === null || _l === void 0 ? void 0 :
                        _l.map(function (item, index) { return _this.renderSource(item, index); })))),
            !this.props.disableModal && React.createElement(semantic_ui_react_1.Modal.Actions, { key: 2 },
                React.createElement(semantic_ui_react_1.Button, { color: "blue", disabled: (_m = this.state.repository) === null || _m === void 0 ? void 0 : _m.loading, loading: (_o = this.state.repository) === null || _o === void 0 ? void 0 : _o.loading }, "Ment\u00E9s"))
        ];
    };
    CustomNewsletterTemplateUpdateComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CustomNewsletterTemplateUpdateComponent;
}(template_update_component_1.TemplateUpdateComponent));
exports.CustomNewsletterTemplateUpdateComponent = CustomNewsletterTemplateUpdateComponent;
