"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginScreen = void 0;
var sleep_1 = require("@codebuild/uikit/libs/libs/sleep");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var login_screen_1 = require("@codebuild/uikit/modules/codebuild/authentication/screens/login/login.screen");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var LoginScreen = (function (_super) {
    __extends(LoginScreen, _super);
    function LoginScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoginScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var regCreds, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        regCreds = local_store_1.LocalStore.getItem('SuccessRegCredentials');
                        if (!regCreds) return [3, 8];
                        return [4, sleep_1.sleep(700)];
                    case 1:
                        _b.sent();
                        _a = regCreds.type;
                        switch (_a) {
                            case 'password': return [3, 2];
                            case 'facebook': return [3, 4];
                            case 'google': return [3, 6];
                        }
                        return [3, 8];
                    case 2:
                        local_store_1.LocalStore.deleteItem('SuccessRegCredentials');
                        return [4, this.handleLogin(regCreds.credentials)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        local_store_1.LocalStore.deleteItem('SuccessRegCredentials');
                        return [4, this.handleFacebookLogin()];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6:
                        local_store_1.LocalStore.deleteItem('SuccessRegCredentials');
                        return [4, this.handleGoogleLogin()];
                    case 7:
                        _b.sent();
                        _b.label = 8;
                    case 8: return [2];
                }
            });
        });
    };
    LoginScreen.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Dimmer.Dimmable, { as: semantic_ui_react_1.Segment, className: "LoginScreen", fluid: true, attached: true },
            React.createElement(semantic_ui_react_1.Dimmer, { active: this.props.routeLoading, inverted: true },
                React.createElement(semantic_ui_react_1.Loader, { active: this.props.routeLoading })),
            this.renderHeader(),
            this.renderSocialButtons(),
            this.renderSocialLoginDivider(),
            this.renderForm(),
            React.createElement("div", { style: {
                    height: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                } },
                React.createElement("div", null,
                    React.createElement("a", { href: "https://www.paymentgateway.hu", target: '_blank' },
                        React.createElement("img", { src: "/assets/logo/pmgw-logo-narrow-black.png", alt: "BigFish Payment Gateway log\u00F3", height: "75px" }))),
                React.createElement("div", null,
                    React.createElement("img", { src: "/assets/logo/barion-card-strip-intl__small.png", alt: "Barion log\u00F3", width: "100%" })),
                React.createElement("div", null,
                    React.createElement("p", { style: {
                            marginTop: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        } },
                        React.createElement("b", null,
                            React.createElement("a", { href: "https://miclub.hu/aszf", target: "_blank" }, "\u00C1ltal\u00E1nos szerz\u0151d\u00E9si felt\u00E9telek (\u00C1SZF)")),
                        React.createElement("b", null,
                            React.createElement("a", { href: "https://miclub.hu/adatvedelem", target: "_blank" }, "Adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3"))))));
    };
    LoginScreen.prototype.renderFacebookLogin = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "SocialLoginButton facebook", onClick: function () { return _this.handleFacebookLogin(); }, loading: this.state.loadingFacebook, "data-cy": "login-facebook-button" },
            React.createElement(semantic_ui_react_1.Icon, { name: "facebook f" }),
            React.createElement("span", null, trans_1.trans('auth.login.social-button.facebook')));
    };
    LoginScreen.prototype.renderGoogleLogin = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "SocialLoginButton google", onClick: function () { return _this.handleGoogleLogin(); }, loading: this.state.loadingGoogle, "data-cy": "login-google-button" },
            React.createElement(semantic_ui_react_1.Icon, { name: "google" }),
            React.createElement("span", null, trans_1.trans('auth.login.social-button.google')));
    };
    return LoginScreen;
}(login_screen_1.LoginScreen));
exports.LoginScreen = LoginScreen;
