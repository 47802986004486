"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentCartSumOfUserCardComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var currency_parser_1 = require("../../../common/libs/currency-parser");
require("./lead-stats.scss");
var CurrentCartSumOfUserCardComponent = (function (_super) {
    __extends(CurrentCartSumOfUserCardComponent, _super);
    function CurrentCartSumOfUserCardComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            cartSum: _this.props.cartSum || 0
        };
        return _this;
    }
    CurrentCartSumOfUserCardComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!lodash_1.isEqual(prevProps.cartSum, this.props.cartSum)) {
            this.setState({ cartSum: this.props.cartSum });
        }
    };
    CurrentCartSumOfUserCardComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Message, { className: 'LeadStat CurrentCartSum' },
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(semantic_ui_react_1.Icon, { name: 'shopping basket' })),
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("p", null, "Jelenleg kos\u00E1rban"),
                React.createElement("h2", null, currency_parser_1.hufFormat(this.state.cartSum)),
                React.createElement("span", { className: 'cursor-pointer', color: 'blue', onClick: function () { return _this.handleFilter(); } },
                    React.createElement(semantic_ui_react_1.Icon, { name: 'filter' }),
                    "\u00D6sszes megtekint\u00E9se")));
    };
    CurrentCartSumOfUserCardComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    CurrentCartSumOfUserCardComponent.prototype.handleFilter = function () {
        if (this.props.onFilter) {
            this.props.onFilter(['cart-change', 'add-to-cart', 'remove-from-cart']);
        }
    };
    return CurrentCartSumOfUserCardComponent;
}(responsive_component_1.ResponsiveComponent));
exports.CurrentCartSumOfUserCardComponent = CurrentCartSumOfUserCardComponent;
