"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoriesListComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var number_parser_1 = require("../../../common/libs/number-parser");
var state_repository_1 = require("../../../common/libs/state-repository");
require("./categories-list.component.scss");
var category_list_item_component_1 = require("./category-list-item.component");
var CategoriesListComponent = (function (_super) {
    __extends(CategoriesListComponent, _super);
    function CategoriesListComponent() {
        var _a, _b, _c, _d, _e;
        var _this = _super.apply(this, arguments) || this;
        _this.category = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'categoryCount');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 500,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                _query: (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query._query) !== null && _e !== void 0 ? _e : ''
            }
        });
        _this.state = __assign(__assign({}, _this.state), { openedCategories: [], categoryCount: '-' });
        return _this;
    }
    CategoriesListComponent.prototype.componentDidMount = function () {
        var _a;
        _super.prototype.componentDidMount.call(this);
        this.category.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/category/category-count");
    };
    CategoriesListComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        return React.createElement("div", { className: "CategoryListComponent ListComponent" },
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, trans_1.trans('webshop-data.category-list.screen.title')),
                        React.createElement(semantic_ui_react_1.Statistic, { horizontal: true, size: 'tiny', label: trans_1.trans('webshop-data.category-list.screen.category-count.label'), value: number_parser_1.splitToNumberString((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.categoryCount) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.count, 0) })))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_d = this.getCurrentShop()) === null || _d === void 0 ? void 0 : _d._id) + "/data/temporary/category", renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 5 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 5 })); } }));
    };
    CategoriesListComponent.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, trans_1.trans('webshop-data.category-list.item.name.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' }, trans_1.trans('webshop-data.category-list.item.gross-income.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' }, trans_1.trans('webshop-data.category-list.item.product-count.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' }, trans_1.trans('webshop-data.category-list.item.advertisable.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' }, trans_1.trans('webshop-data.category-list.item.recommended.label')))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    CategoriesListComponent.prototype.renderItem = function (ctx, item, index, level) {
        var _a;
        if (level === void 0) { level = 0; }
        return React.createElement(category_list_item_component_1.CategoryListItemComponent, { item: item, index: index, level: level, recommended: (_a = item === null || item === void 0 ? void 0 : item.settings) === null || _a === void 0 ? void 0 : _a.recommendedCategories });
    };
    CategoriesListComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CategoriesListComponent;
}(responsive_component_1.ResponsiveComponent));
exports.CategoriesListComponent = CategoriesListComponent;
