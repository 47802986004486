"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportSubscribersComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var state_repository_1 = require("../../../common/libs/state-repository");
require("./shop-settings.scss");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var moment = require("moment");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var errors_constant_1 = require("../../../shoprenter/errors/errors.constant");
var EXPORT_TYPES = {
    LAST_ONE_YEAR_WITHOUT_EMAIL_OPENED_EVENT: "LAST_ONE_YEAR_WITHOUT_EMAIL_OPENED_EVENT",
    LAST_HALF_YEAR_WITHOUT_EMAIL_OPENED_EVENT: "LAST_HALF_YEAR_WITHOUT_EMAIL_OPENED_EVENT",
    LAST_HALF_YEAR_WITH_EMAIL_OPENED_EVENT: "LAST_HALF_YEAR_WITH_EMAIL_OPENED_EVENT"
};
var ExportSubscribersComponent = (function (_super) {
    __extends(ExportSubscribersComponent, _super);
    function ExportSubscribersComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            loading: false
        };
        return _this;
    }
    ExportSubscribersComponent.prototype.handleExport = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, results, rows, csvContent, encodedUri, link, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        this.setState({ loading: true });
                        return [4, this.shop.post("/a/shop/" + ((_a = this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/export-subscribers")];
                    case 1:
                        response = _b.sent();
                        console.log('handleExport response length', response === null || response === void 0 ? void 0 : response.length);
                        results = response || [];
                        rows = __spreadArrays([
                            [
                                'email'
                            ]
                        ], results.map(function (row) { return ([
                            row.email,
                        ]); }));
                        csvContent = "data:text/csv;charset=utf-8," + rows.join('\n');
                        encodedUri = encodeURI(csvContent);
                        link = document.createElement('a');
                        link.setAttribute('href', encodedUri);
                        link.setAttribute('download', "feliratkozok-export-" + moment(new Date()).format('YYYY-MM-DD'));
                        document.body.appendChild(link);
                        link.click();
                        toast_util_1.openSuccessToast(trans_1.trans('Sikeres export!'));
                        return [3, 4];
                    case 2:
                        e_1 = _b.sent();
                        console.log(e_1);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_UNKNOWN);
                        return [3, 4];
                    case 3:
                        this.setState({ loading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    ExportSubscribersComponent.prototype.downLoadListCleaningCsv = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, results, csvContent, encodedUri, link;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.shop.post("/a/shop/" + ((_a = this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/list-cleaning")];
                    case 1:
                        response = _b.sent();
                        console.log('handleExport response length', response === null || response === void 0 ? void 0 : response.length);
                        results = response || [];
                        if (results.length > 0) {
                            results.unshift('email');
                            csvContent = 'data:text/csv;charset=utf-8,' + results.map(function (row) { return "\"" + row + "\""; }).join('\n');
                            encodedUri = encodeURI(csvContent);
                            link = document.createElement('a');
                            link.setAttribute('href', encodedUri);
                            link.setAttribute('download', "inaktiv-feliratkozok-" + moment(new Date()).format('YYYY-MM-DD') + ".csv");
                            document.body.appendChild(link);
                            link.click();
                        }
                        return [2];
                }
            });
        });
    };
    ExportSubscribersComponent.prototype.transformShopTitleForFilename = function (originalShopTitle) {
        return originalShopTitle.toLowerCase().replace(/\s+/g, '-');
    };
    ExportSubscribersComponent.prototype.downloadListCleaningCsv = function (exportType, requestBody) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, exportTypeKey, shopnameKey, outputFileName, results, headerRow, csvContent, encodedUri, link, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.setState({ loading: true });
                        console.log('downloadListCleaningCsv input requestBody', requestBody);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, 4, 5]);
                        return [4, this.shop.post("/a/shop/" + ((_a = this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/list-cleaning", {
                                data: requestBody
                            })];
                    case 2:
                        response = _c.sent();
                        console.log('downloadListCleaningCsv response length', response === null || response === void 0 ? void 0 : response.length);
                        exportTypeKey = 'EXPORT_TYPE';
                        shopnameKey = 'SHOPNAME';
                        outputFileName = "inaktiv-feliratkozok-EXPORT_TYPE-SHOPNAME-" + moment(new Date()).format('YYYY-MM-DD') + ".csv";
                        outputFileName = outputFileName.replace(shopnameKey, this.transformShopTitleForFilename((_b = this.props.shop) === null || _b === void 0 ? void 0 : _b.title));
                        switch (exportType) {
                            case EXPORT_TYPES.LAST_ONE_YEAR_WITHOUT_EMAIL_OPENED_EVENT: {
                                outputFileName = outputFileName.replace(exportTypeKey, "1-ev-email-nyitas-nelkul");
                                break;
                            }
                            case EXPORT_TYPES.LAST_HALF_YEAR_WITHOUT_EMAIL_OPENED_EVENT: {
                                outputFileName = outputFileName.replace(exportTypeKey, "fel-ev-email-nyitas-nelkul");
                                break;
                            }
                            case EXPORT_TYPES.LAST_HALF_YEAR_WITH_EMAIL_OPENED_EVENT: {
                                outputFileName = outputFileName.replace(exportTypeKey, "fel-ev-email-nyitassal");
                                break;
                            }
                        }
                        console.log("Output filename: " + outputFileName);
                        results = response || [];
                        if (results.length > 0) {
                            results.unshift('email');
                            headerRow = 'email,firstName,lastName,subscribe\n';
                            csvContent = 'data:text/csv;charset=utf-8,' +
                                headerRow +
                                results.map(function (row) { return "\"" + row + "\",,,0"; }).join('\n');
                            encodedUri = encodeURI(csvContent);
                            link = document.createElement('a');
                            link.setAttribute('href', encodedUri);
                            link.setAttribute('download', outputFileName);
                            document.body.appendChild(link);
                            link.click();
                        }
                        else {
                            console.error(errors_constant_1.getErrorMessage(errors_constant_1.ERROR_ENUM.ERROR_API_EMPTY_INACTIVE_USERS_RESPONSE));
                            toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_EMPTY_INACTIVE_USERS_RESPONSE);
                        }
                        return [3, 5];
                    case 3:
                        error_1 = _c.sent();
                        console.error('downloadListCleaningCsv error:', error_1);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_EXPORT_INACTIVE_USERS);
                        return [3, 5];
                    case 4:
                        this.setState({ loading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    ExportSubscribersComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Grid, { columns: 1, className: "w-100" },
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("h2", null, "Feliratkoz\u00F3k export\u00E1l\u00E1sa"),
                    React.createElement("p", null,
                        "Ez a funkci\u00F3 egyel\u0151re kiz\u00E1r\u00F3lag ",
                        React.createElement("strong", null, "Shoprenter"),
                        " webshopokra m\u0171k\u00F6dik. Egy\u00E9b webshopra \u00FCres CSV f\u00E1jl t\u00F6lt\u0151dik le."))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.handleExport(); }, content: 'Exportálás' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("h2", null, "Inakt\u00EDv feliratkoz\u00F3k export\u00E1l\u00E1sa (1 \u00E9v, email nyit\u00E1s n\u00E9lk\u00FCl)"),
                    React.createElement("p", null, "1 \u00E9ve nincsen semmilyen aktivit\u00E1sa \u00C9S nem is nyitott meg emailt."))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.downloadListCleaningCsv(EXPORT_TYPES.LAST_ONE_YEAR_WITHOUT_EMAIL_OPENED_EVENT, {
                            days: 365,
                            enableEmailOpenedEvents: false
                        }); }, content: 'Exportálás' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("h2", null, "Inakt\u00EDv feliratkoz\u00F3k export\u00E1l\u00E1sa (f\u00E9l \u00E9v, email nyit\u00E1s n\u00E9lk\u00FCl)"),
                    React.createElement("p", null, "f\u00E9l \u00E9ve nincsen semmilyen aktivit\u00E1sa \u00C9S nem is nyitott meg emailt."))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.downloadListCleaningCsv(EXPORT_TYPES.LAST_HALF_YEAR_WITHOUT_EMAIL_OPENED_EVENT, {
                            days: 180,
                            enableEmailOpenedEvents: false
                        }); }, content: 'Exportálás' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("h2", null, "Inakt\u00EDv feliratkoz\u00F3k export\u00E1l\u00E1sa (f\u00E9l \u00E9v, nyithatott meg emailt)"),
                    React.createElement("p", null, "f\u00E9l \u00E9ve nincsen semmilyen aktivit\u00E1sa, de lehet nyitott meg emailt."))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.downloadListCleaningCsv(EXPORT_TYPES.LAST_HALF_YEAR_WITH_EMAIL_OPENED_EVENT, {
                            days: 180,
                            enableEmailOpenedEvents: true
                        }); }, content: 'Exportálás' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("h2", null, "Inakt\u00EDv feliratkoz\u00F3k export\u00E1l\u00E1sa (eredeti)"))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.downLoadListCleaningCsv(); }, content: 'Exportálás' }))));
    };
    return ExportSubscribersComponent;
}(abstract_component_1.AbstractComponent));
exports.ExportSubscribersComponent = ExportSubscribersComponent;
