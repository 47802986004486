"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnonymousLeadListComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var capitalize_1 = require("../../../common/libs/capitalize");
require("./lead-list.component.scss");
var moment = require("moment");
var activity_indicator_1 = require("../../../common/components/activity-indicator/activity-indicator");
var AnonymousLeadListComponent = (function (_super) {
    __extends(AnonymousLeadListComponent, _super);
    function AnonymousLeadListComponent() {
        var _a, _b, _c, _d, _e;
        var _this = _super.apply(this, arguments) || this;
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 5,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                _query: (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query._query) !== null && _e !== void 0 ? _e : '',
                '_sort[updatedAt]': -1
            }
        });
        _this.state = __assign(__assign({}, _this.state), { openedCategories: [] });
        return _this;
    }
    AnonymousLeadListComponent.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: "LeadListComponent" },
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/event/anonymous", renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 2 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 2 })); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                    { text: 1, value: 1 },
                    { text: 10, value: 10 },
                    { text: 25, value: 25 },
                    { text: 50, value: 50 },
                    { text: 100, value: 100 },
                ] }));
    };
    AnonymousLeadListComponent.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "N\u00E9v"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center' }, "Regisztr\u00E1ci\u00F3 ideje"))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    AnonymousLeadListComponent.prototype.renderItem = function (ctx, item, index, level) {
        var _a;
        if (level === void 0) { level = 0; }
        var detailsPath = "/leads/pe/" + (item === null || item === void 0 ? void 0 : item._id);
        return [
            React.createElement(semantic_ui_react_1.Table.Row, { className: 'cursor-pointer', onClick: function () { return router_provider_1.RouterProvider.goTo(detailsPath); }, key: item._id, verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderCustomerName(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, moment((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.createdAt).format('YYYY.MM.DD'))),
        ];
    };
    AnonymousLeadListComponent.prototype.renderCustomerName = function (item) {
        var _a, _b;
        return React.createElement("div", { className: ' w-100 display-inline-flex' },
            React.createElement("div", { className: 'display-flex align-items-center' },
                React.createElement(activity_indicator_1.ActivityIndicator, { shopId: (_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id, cIdOrSId: (_b = item === null || item === void 0 ? void 0 : item.sIds) === null || _b === void 0 ? void 0 : _b[0] },
                    React.createElement("span", { style: { color: "" + (item === null || item === void 0 ? void 0 : item.anonymousColor) } },
                        capitalize_1.capitalize(item === null || item === void 0 ? void 0 : item.anonymousFirstName),
                        " ",
                        capitalize_1.capitalize(item === null || item === void 0 ? void 0 : item.anonymousLastName)))));
    };
    AnonymousLeadListComponent.prototype.renderLoader = function () {
        if (!this.props.routeLoading) {
            return null;
        }
        return React.createElement("div", { className: 'Loader' },
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }));
    };
    AnonymousLeadListComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    AnonymousLeadListComponent.prototype.handleListChange = function (ctx) {
        var _a;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    return AnonymousLeadListComponent;
}(responsive_component_1.ResponsiveComponent));
exports.AnonymousLeadListComponent = AnonymousLeadListComponent;
