"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'sync-progress.title': 'Az adatok importálása elkezdődött!',
    'sync-progress.description': 'Ez eltarthat néhány percig, addig is mutatjuk, mi történik a webáruházadban. Az oldalt bármikor elhagyhatod, az importálási folyamat nem szakad meg. 🙂 ',
    'sync-progress.success.product': 'Termékek áttöltése',
    'sync-progress.default.product': 'Termékek áttöltése',
    'sync-progress.pending.product': 'Termékek áttöltése',
    'sync-progress.error.product': 'Termékek áttöltése',
    'sync-progress.success.category': 'Kategóriák áttöltése',
    'sync-progress.default.category': 'Kategóriák áttöltése',
    'sync-progress.pending.category': 'Kategóriák áttöltése',
    'sync-progress.error.category': 'Kategóriák áttöltése',
    'sync-progress.success.order': 'Megrendelések áttöltése',
    'sync-progress.default.order': 'Megrendelések áttöltése',
    'sync-progress.pending.order': 'Megrendelések áttöltése',
    'sync-progress.error.order': 'Megrendelések áttöltése',
    'sync-progress.success.customer': 'Felhasználók áttöltése',
    'sync-progress.default.customer': 'Felhasználók áttöltése',
    'sync-progress.pending.customer': 'Felhasználók áttöltése',
    'sync-progress.error.customer': 'Felhasználók áttöltése',
    'sync-progress.success.subscriber': 'Feliratkozók áttöltése',
    'sync-progress.default.subscriber': 'Feliratkozók áttöltése',
    'sync-progress.pending.subscriber': 'Feliratkozók áttöltése',
    'sync-progress.error.subscriber': 'Feliratkozók áttöltése',
    'sync-progress.success.images': 'Képek áttöltése',
    'sync-progress.default.images': 'Képek áttöltése',
    'sync-progress.pending.images': 'Képek áttöltése',
    'sync-progress.error.images': 'Képek áttöltése',
    'integration.sync.goToDashboard': React.createElement("span", null,
        "Ir\u00E1ny a vez\u00E9rl\u0151pult! ",
        React.createElement("span", { style: { fontSize: '24px' } }, "\uD83E\uDD18"),
        " "),
    'sync-progress.postprocessing.syncing.title': 'Adatok feldolgozása',
    'sync-progress.postprocessing.processing.title': 'Adatok feldolgozása folyamatban',
    'sync-progress.postprocessing.success.title': 'Adatok feldolgozása sikeres',
    'sync-progress.postprocessing.error.title': 'Adatok feldolgozása sikertelen',
};
