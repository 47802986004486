"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopRegistrationStartStepComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var ShopRegistrationStartStepComponent = (function (_super) {
    __extends(ShopRegistrationStartStepComponent, _super);
    function ShopRegistrationStartStepComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShopRegistrationStartStepComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { textAlign: 'center', as: 'h2' }, trans_1.trans('shop-registration.start-step.title')),
                    React.createElement("p", { style: { textAlign: 'left' } }, trans_1.trans('shop-registration.start-step.description')))),
            React.createElement(semantic_ui_react_1.Grid.Row, { centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'center', className: 'button-container' },
                    React.createElement(semantic_ui_react_1.Button, { basic: false, onClick: function () { return _this.handleSubmit(); }, content: trans_1.trans('shop-registration.start-button.title') }),
                    React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.handleCancel(); }, basic: true, id: 'continue-later', content: trans_1.trans('shop-registration.cancel-button.title') }))));
    };
    ShopRegistrationStartStepComponent.prototype.handleCancel = function () {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };
    ShopRegistrationStartStepComponent.prototype.handleSubmit = function () {
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    };
    return ShopRegistrationStartStepComponent;
}(abstract_component_1.AbstractComponent));
exports.ShopRegistrationStartStepComponent = ShopRegistrationStartStepComponent;
