"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateContentComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var state_repository_1 = require("../../../../common/libs/state-repository");
var banner_1 = require("./blocks/basic-blocks/banner");
var button_block_1 = require("./blocks/basic-blocks/button-block");
var footer_block_1 = require("./blocks/basic-blocks/footer-block");
var header_block_1 = require("./blocks/basic-blocks/header-block");
var heading_block_1 = require("./blocks/basic-blocks/heading-block");
var text_block_1 = require("./blocks/basic-blocks/text-block");
var product_loops_1 = require("./blocks/product-loops/product-loops");
var content_variables_1 = require("./content-variables");
var content_variable_component_1 = require("./libs/content-variable.component");
var TemplateContentComponent = (function (_super) {
    __extends(TemplateContentComponent, _super);
    function TemplateContentComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sources = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'sources');
        _this.accessToken = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'accessToken');
        _this.content = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'content');
        _this.upload = new state_repository_1.StateRepository(repository_1.Repository.use('attachment'), _this, 'upload');
        _this.state = {
            modalSpot: null
        };
        return _this;
    }
    TemplateContentComponent.prototype.getEndpoint = function () {
        return '/a/template';
    };
    TemplateContentComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sources.get(this.getEndpoint() + "/sources")];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    TemplateContentComponent.prototype.getAccessToken = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, template, chamaileon, user, config, instance;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.accessToken.get('/a/chamaileon/credentials')];
                    case 1:
                        response = _b.sent();
                        return [4, this.content.get(this.getEndpoint() + "/" + this.props._id)];
                    case 2:
                        template = _b.sent();
                        return [4, window.chamaileonSdk.init({
                                mode: 'serverless',
                                accessToken: response === null || response === void 0 ? void 0 : response.accessToken,
                                whitelabel: {
                                    locale: 'hu',
                                    urls: {
                                        splashScreen: '/assets/chamaileon-splash.html',
                                    },
                                    colors: {
                                        primary: '#2D3291',
                                        secondary: '#009f4a',
                                        red: '#ff5546',
                                        darkBlue: '#2d3291',
                                        darkGreen: '#00af6e',
                                        lightGreen: '#50d791',
                                        weirdGreen: '#50d791',
                                        pink: '#ff91a0',
                                        yellow: '#ffd23c'
                                    }
                                }
                            })];
                    case 3:
                        chamaileon = _b.sent();
                        user = local_store_1.LocalStore.getItem('User');
                        console.log('KIOLVASAS', JSON.stringify((template.content || {})).length, JSON.stringify((template.content || {})));
                        config = {
                            document: template.content || {},
                            title: 'Vissza',
                            user: {
                                id: user === null || user === void 0 ? void 0 : user._id,
                                name: (_a = user === null || user === void 0 ? void 0 : user.meta) === null || _a === void 0 ? void 0 : _a.firstName,
                                avatar: 'https://misys-v3-uat-cdn.fra1.digitaloceanspaces.com/iconfinder_4043232_avatar_batman_comics_hero_icon_512px.png'
                            },
                            dropdownButtons: [],
                            settings: {
                                elements: {
                                    content: {
                                        text: true,
                                        image: true,
                                        button: true,
                                        social: false,
                                        divider: true,
                                        code: true
                                    },
                                    structure: {
                                        box: true,
                                        multiColumn: true
                                    },
                                    advanced: {
                                        loop: false,
                                        conditional: false,
                                        dynamicImage: true
                                    },
                                    addons: {
                                        blockLock: {
                                            enabled: false
                                        },
                                        variableSystem: {
                                            enabled: false
                                        }
                                    }
                                },
                                blockLibraries: this.getBlockLibraries(),
                                staticAssetsBaseUrl: 'https://v3.miclub.hu/assets/',
                            },
                            hooks: {
                                onSave: function (payload) { return _this.onSave(payload); },
                                onAutoSave: function (payload) { return _this.onSave(payload); },
                                onEditImage: function () {
                                    var args = [];
                                    for (var _i = 0; _i < arguments.length; _i++) {
                                        args[_i] = arguments[_i];
                                    }
                                    return _this.uploadFile();
                                },
                                onTextInsertPluginButtonClicked: function (_a) {
                                    var buttonId = _a.buttonId;
                                    switch (buttonId) {
                                        case 'variables':
                                            return _this.onMergeTagClick();
                                        default:
                                    }
                                },
                                onLoadBlocks: function (_a) {
                                    var libId = _a.libId;
                                    return __awaiter(_this, void 0, void 0, function () {
                                        var _i, _b, blockIdentifier;
                                        return __generator(this, function (_c) {
                                            for (_i = 0, _b = this.getBlockLibraries(); _i < _b.length; _i++) {
                                                blockIdentifier = _b[_i];
                                                if (libId === blockIdentifier.id) {
                                                    return [2, { blocks: blockIdentifier.blocks }];
                                                }
                                            }
                                            return [2, { blocks: [] }];
                                        });
                                    });
                                },
                                onExpressionEditClicked: function (_a) {
                                    var expression = _a.expression;
                                    return _this.onExpressionAddClick();
                                }
                            },
                            textInsertPluginButtons: [
                                {
                                    id: 'variables',
                                    label: 'Változók beillesztése',
                                    icon: 'https://raw.githubusercontent.com/ckeditor/ckeditor4/major/skins/kama/icons/paste.png'
                                }
                            ],
                        };
                        return [4, chamaileon.editEmail(config)];
                    case 4:
                        instance = _b.sent();
                        return [2];
                }
            });
        });
    };
    TemplateContentComponent.prototype.onSave = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.content = new state_repository_1.StateRepository(repository_1.Repository.use('default'), this, 'content');
                        return [4, this.content.put(this.getEndpoint() + "/" + this.props._id + "/content", {
                                data: {
                                    content: payload.document
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.props.onSave();
                        return [2];
                }
            });
        });
    };
    TemplateContentComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        return [
            React.createElement(semantic_ui_react_1.Button, { color: "yellow", loading: ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.accessToken) === null || _b === void 0 ? void 0 : _b.loading) || ((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.sources) === null || _d === void 0 ? void 0 : _d.loading), onClick: function () { return _this.getAccessToken(); }, key: 0 }, "Sablon szerkeszt\u00E9se"),
            React.createElement("input", { key: 1, style: { position: 'absolute', padding: 0, opacity: 0, zIndex: -1, maxWidth: 0, maxHeight: 0 }, type: 'file', ref: function (ref) { return (_this.fileInputRef = ref); } }),
            this.state.modalSpot
        ];
    };
    TemplateContentComponent.prototype.getBlockCategories = function () {
        var _a, _b;
        var variables = (_b = (_a = this.state.sources) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.variables; }).flat();
        var cat = variables
            .filter(function (item) { return item.isBlock; })
            .map(function (item) { return item.blockCategory; });
        return lodash_1.uniq(cat);
    };
    TemplateContentComponent.prototype.onMergeTagClick = function () {
        var _this = this;
        var _a, _b, _c;
        var variables = __spreadArrays((_c = (_b = (_a = this.state.content) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.sources) === null || _c === void 0 ? void 0 : _c.map(function (item) { return item.variables; }).flat(), content_variables_1.contentVariables);
        return new Promise(function (resolve, reject) {
            var element = React.createElement(content_variable_component_1.ContentVariableComponent, { variables: variables, onSelect: function (result) {
                    resolve(result);
                    _this.onModalClose();
                }, onClose: function () { return _this.onModalClose(); } });
            _this.setState({ modalSpot: element });
        });
    };
    TemplateContentComponent.prototype.onExpressionAddClick = function () {
        var _this = this;
        var _a, _b, _c;
        var variables = __spreadArrays(content_variables_1.contentVariables, (_c = (_b = (_a = this.state.content) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.sources) === null || _c === void 0 ? void 0 : _c.map(function (item) { return item.variables; }).flat());
        return new Promise(function (resolve, reject) {
            var element = React.createElement(content_variable_component_1.ContentVariableComponent, { variables: variables, onSelect: function (result) {
                    resolve({ expression: result.valueKey });
                    _this.setState({ modalSpot: null });
                }, onClose: function () { return _this.onModalClose(); } });
            _this.setState({ modalSpot: element });
        });
    };
    TemplateContentComponent.prototype.onModalClose = function () {
        this.setState({ modalSpot: null });
    };
    TemplateContentComponent.prototype.getBlockLibraries = function () {
        var _a;
        var sources = ((_a = this.state.sources) === null || _a === void 0 ? void 0 : _a.response) || [];
        var getBlocks = function (category) { return sources
            .map(function (source) { return ({
            source: source,
            matchedVariables: source.variables.filter(function (variable) { return (variable.blockCategory === category) && (variable.isBlock); })
        }); })
            .filter(function (i) { var _a; return !!((_a = i === null || i === void 0 ? void 0 : i.matchedVariables) === null || _a === void 0 ? void 0 : _a.length); })
            .map(function (i) { var _a; return (_a = i === null || i === void 0 ? void 0 : i.matchedVariables) === null || _a === void 0 ? void 0 : _a.map(function (mv) {
            var _a, _b, _c;
            return ({
                sourceTitle: (_a = i.source) === null || _a === void 0 ? void 0 : _a.title,
                sourceDescription: (_b = i.source) === null || _b === void 0 ? void 0 : _b.description,
                sourceKey: (_c = i.source) === null || _c === void 0 ? void 0 : _c.key,
                source: i.source,
                variable: mv
            });
        }); })
            .flat()
            .map(function (i) { return product_loops_1.productLoops
            .filter(function (pl) { return (pl.categories.includes(category)); })
            .map(function (pl) { return pl.create(i); }); })
            .flat(); };
        return __spreadArrays(this.getBlockCategories().map(function (item) { return ({
            id: item,
            label: trans_1.trans("email-editor.block-category." + item),
            blocks: getBlocks(item)
        }); }), [
            {
                id: 'heading-blocks',
                label: trans_1.trans('email-editor.heading-blocks'),
                blocks: [
                    heading_block_1.headingBlock('1'),
                    heading_block_1.headingBlock('2'),
                    heading_block_1.headingBlock('3'),
                    heading_block_1.headingBlock('4'),
                    heading_block_1.headingBlock('5'),
                ]
            },
            {
                id: 'text-blocks',
                label: trans_1.trans('email-editor.text-blocks'),
                blocks: [
                    text_block_1.textBlock('1'),
                    text_block_1.textBlock('2'),
                    text_block_1.textBlock('3'),
                    text_block_1.textBlock('4'),
                    text_block_1.textBlock('5'),
                ]
            },
            {
                id: 'button-blocks',
                label: trans_1.trans('email-editor.button-blocks'),
                blocks: [
                    button_block_1.buttonBlock('1'),
                    button_block_1.buttonBlock('2'),
                    button_block_1.buttonBlock('3'),
                    button_block_1.buttonBlock('4'),
                    button_block_1.buttonBlock('5'),
                ]
            },
            {
                id: 'header-blocks',
                label: trans_1.trans('email-editor.header-blocks'),
                blocks: [
                    header_block_1.headerBlock()
                ]
            },
            {
                id: 'footer-blocks',
                label: trans_1.trans('email-editor.footer-blocks'),
                blocks: [
                    footer_block_1.footerBlock()
                ]
            },
            {
                id: 'misc-blocks',
                label: trans_1.trans('email-editor.misc-blocks'),
                blocks: [
                    banner_1.bannerBlock(''),
                ]
            }
        ]).map(function (item) { return (__assign(__assign({}, item), { canDeleteBlock: false, canRenameBlock: false, canSaveBlock: false })); });
    };
    TemplateContentComponent.prototype.getBlocksByCategory = function (category) {
        return [];
    };
    TemplateContentComponent.prototype.uploadFile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.fileInputRef.click();
                return [2, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            this.fileInputRef.onchange = function (event) { return __awaiter(_this, void 0, void 0, function () {
                                var file, form, response, e_1;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _b.trys.push([0, 2, , 3]);
                                            console.log(event);
                                            file = event.target.files.item(0);
                                            form = new FormData();
                                            form.append('data', file);
                                            form.append('name', file.name);
                                            return [4, this.upload.post('/attachment', {
                                                    data: form
                                                })];
                                        case 1:
                                            response = _b.sent();
                                            resolve({
                                                src: (_a = response === null || response === void 0 ? void 0 : response.default) === null || _a === void 0 ? void 0 : _a.payload
                                            });
                                            return [3, 3];
                                        case 2:
                                            e_1 = _b.sent();
                                            reject(e_1);
                                            return [3, 3];
                                        case 3: return [2];
                                    }
                                });
                            }); };
                            return [2];
                        });
                    }); })];
            });
        });
    };
    return TemplateContentComponent;
}(abstract_component_1.AbstractComponent));
exports.TemplateContentComponent = TemplateContentComponent;
