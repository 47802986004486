"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var redirect_middleware_1 = require("@codebuild/uikit/components/middlewares/redirect.middleware");
var business_report_export_screen_1 = require("../modules/admin/screens/business-report-export/business-report-export.screen");
var categories_screen_1 = require("../modules/admin/screens/categories.screen");
var custom_newsletters_screen_1 = require("../modules/admin/screens/custom-newsletters/custom-newsletters.screen");
var integration_stat_screen_1 = require("../modules/admin/screens/integration-stat/integration-stat.screen");
var performance_by_template_screen_1 = require("../modules/admin/screens/performance/performance-by-template.screen");
var performance_screen_1 = require("../modules/admin/screens/performance/performance.screen");
var shop_details_screen_1 = require("../modules/admin/screens/shop/shop-details.screen");
var shop_list_screen_1 = require("../modules/admin/screens/shop/shop-list.screen");
var templates_screen_1 = require("../modules/admin/screens/templates.screen");
var admin_layout_1 = require("../modules/common/layouts/admin.layout/admin.layout");
var authorized_middleware_1 = require("../modules/common/middlewares/authorized.middleware");
var shoprenter_app_details_screen_1 = require("../modules/admin/screens/shoprenter-app-details/shoprenter-app-details.screen");
var shoprenter_invoices_screen_1 = require("../modules/admin/screens/shoprenter-invoices/shoprenter-invoices.screen");
var shoprenter_apps_list_screen_1 = require("../modules/admin/screens/shoprenter-apps/shoprenter-apps-list.screen");
var generated_reports_screen_1 = require("../modules/admin/screens/generated-reports/generated-reports.screen");
var invite_manager_to_all_shops_screen_1 = require("../modules/admin/screens/invite-manager-to-all-shops/invite-manager-to-all-shops.screen");
var commonMiddlewares = [
    new authorized_middleware_1.AuthorizedMiddleware({
        redirectTo: '/login',
        adminOnly: true
    })
];
module.exports = [
    {
        middlewares: __spreadArrays(commonMiddlewares, [
            new redirect_middleware_1.RedirectMiddleware('/admin/categories')
        ]),
        path: '/admin'
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/categories',
        layout: admin_layout_1.AdminLayout,
        component: categories_screen_1.CategoriesScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/performance',
        layout: admin_layout_1.AdminLayout,
        component: performance_screen_1.PerformanceScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/integration-stat',
        layout: admin_layout_1.AdminLayout,
        component: integration_stat_screen_1.IntegrationStatScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/performance-by-template',
        layout: admin_layout_1.AdminLayout,
        component: performance_by_template_screen_1.PerformanceByTemplateScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/templates',
        layout: admin_layout_1.AdminLayout,
        component: templates_screen_1.TemplatesScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/custom-newsletters',
        layout: admin_layout_1.AdminLayout,
        component: custom_newsletters_screen_1.CustomNewslettersScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/shops',
        layout: admin_layout_1.AdminLayout,
        component: shop_list_screen_1.ShopListScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/business-report-export',
        layout: admin_layout_1.AdminLayout,
        component: business_report_export_screen_1.BusinessReportExportScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/shoprenter-apps',
        layout: admin_layout_1.AdminLayout,
        component: shoprenter_apps_list_screen_1.ShoprenterAppsListScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/shoprenter-apps/:shopId',
        layout: admin_layout_1.AdminLayout,
        component: shoprenter_app_details_screen_1.ShoprenterAppDetailsScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/shops/:shopId',
        layout: admin_layout_1.AdminLayout,
        component: shop_details_screen_1.ShopDetailsScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/shoprenter-invoices',
        layout: admin_layout_1.AdminLayout,
        component: shoprenter_invoices_screen_1.ShoprenterInvoicesScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/generated-reports',
        layout: admin_layout_1.AdminLayout,
        component: generated_reports_screen_1.GeneratedReportsScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/admin/invite-manager-to-all-shops',
        layout: admin_layout_1.AdminLayout,
        component: invite_manager_to_all_shops_screen_1.InviteManagerToAllShopsScreen
    },
];
