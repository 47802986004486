"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopSynchronizationLayout = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./shop-synchronization.layout.scss");
var ShopSynchronizationLayout = (function (_super) {
    __extends(ShopSynchronizationLayout, _super);
    function ShopSynchronizationLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShopSynchronizationLayout.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Container, { className: "ShopSynchronizationLayout", fluid: true },
            React.createElement(semantic_ui_react_1.Grid, { className: 'py-7' },
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, stretched: true, centered: true, textAlign: 'center' },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Image, { centered: true, size: 'medium', className: 'pt-3', src: '/assets/logo/MI_logo.svg' })))),
            React.createElement(semantic_ui_react_1.Grid, { padded: true },
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true, stretched: true },
                    React.createElement(semantic_ui_react_1.Grid.Column, { mobile: 16, tablet: 10, computer: 8, largeScreen: 6, widescreen: 6 }, this.props.children))));
    };
    return ShopSynchronizationLayout;
}(responsive_component_1.ResponsiveComponent));
exports.ShopSynchronizationLayout = ShopSynchronizationLayout;
