"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoListSyncTabContent = void 0;
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var axios_1 = require("axios");
var semantic_ui_react_1 = require("semantic-ui-react");
var todo_list_tab_content_container_component_1 = require("./todo-list-tab-content-container.component");
var environment_1 = require("@codebuild/uikit/libs/environment");
var session_storage_1 = require("../../../../libs/session.storage");
var toast_util_1 = require("../../errors/toast.util");
var errors_constant_1 = require("../../errors/errors.constant");
var TodoListSyncTabContent = (function (_super) {
    __extends(TodoListSyncTabContent, _super);
    function TodoListSyncTabContent(props) {
        var _a, _b;
        var _this = _super.call(this, props) || this;
        _this.handleCheckboxChange = function (name) {
            _this.setState(function (prevState) {
                var _a;
                return (_a = {},
                    _a[name] = !prevState[name],
                    _a);
            });
        };
        _this.handleButtonClick = function () { return __awaiter(_this, void 0, void 0, function () {
            var api, shop;
            var _this = this;
            return __generator(this, function (_a) {
                this.setState({ isLoading: true });
                api = environment_1.Environment.get('shoprenterApiRootUrl');
                shop = this.getCurrentShop();
                axios_1.default.put(api + "/dashboard/shop/" + shop._id + "/request-sync")
                    .then(function (response) {
                    var _a, _b;
                    _this.setState({
                        isLoading: false,
                        isProductSyncImminent: true,
                    });
                    console.log('API response:', response.data);
                    (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.refetch) === null || _b === void 0 ? void 0 : _b.call(_a);
                })
                    .catch(function (error) {
                    toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_REQUEST_SYNC);
                    _this.setState({
                        isLoading: false,
                        isProductSyncImminent: false,
                        isProductSynced: false
                    });
                    console.error('API error:', error);
                })
                    .finally(function () {
                    _this.setState({ isLoading: false });
                });
                return [2];
            });
        }); };
        _this.state = {
            isLoading: false,
            isProductSyncImminent: (_b = (_a = _this.props.dashboardData) === null || _a === void 0 ? void 0 : _a.dashboard) === null || _b === void 0 ? void 0 : _b.productSyncImminent,
            isProductSynced: false,
            checkbox1: false,
            checkbox2: false,
            checkbox3: false
        };
        return _this;
    }
    TodoListSyncTabContent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    TodoListSyncTabContent.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.state, isLoading = _c.isLoading, isProductSyncImminent = _c.isProductSyncImminent, checkbox1 = _c.checkbox1, checkbox2 = _c.checkbox2, checkbox3 = _c.checkbox3;
        var allCheckboxesChecked = checkbox1 && checkbox2 && checkbox3;
        console.log("checkboxes: " + checkbox1, checkbox2, checkbox3);
        return (React.createElement(todo_list_tab_content_container_component_1.TodoListTabContentContainerComponent, null,
            React.createElement(semantic_ui_react_1.Message, { info: true, style: { backgroundColor: '#EDF4FF', boxShadow: 'none', color: "#051D40" } },
                React.createElement("p", null,
                    React.createElement("strong", null, "A szinkroniz\u00E1ci\u00F3 megkezd\u00E9s\u00E9hez fontos, hogy elfogad\u00E1sra ker\u00FCljenek az al\u00E1bbi dokumentumok!")),
                React.createElement("p", null, "Csak p\u00E1r kattint\u00E1s, \u00E9s m\u00E1ris elindulhat a webshopod szinkroniz\u00E1l\u00E1sa! Te is annyira v\u00E1rod m\u00E1r, mint \u00E9n?"),
                React.createElement("p", null,
                    "Miut\u00E1n megkaptad a ",
                    React.createElement("strong", null, "\"Kezd\u0151dj\u00F6n a szinkroniz\u00E1ci\u00F3\""),
                    " gombot, a web\u00E1ruh\u00E1zad adatainak szinkroniz\u00E1l\u00E1sa megt\u00F6rt\u00E9nik. Ezt az\u00E9rt fontos tudnod, mert ett\u0151l a pillanatt\u00F3l kezdve jogilag az ",
                    React.createElement("strong", null, "M.I. Solution Kft. lesz az adatfeldolgoz\u00F3"),
                    "."),
                React.createElement("p", null,
                    "\uD83D\uDCA1",
                    React.createElement("strong", null, "FONTOS!"),
                    " K\u00E9rlek, az ",
                    React.createElement("strong", null, "Adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3t"),
                    " \u00E9s az ",
                    React.createElement("strong", null, "\u00C1ltal\u00E1nos Szerz\u0151d\u00E9si Felt\u00E9teleket"),
                    " is olvasd el, hogy teljes m\u00E9rt\u00E9kben \u00FAgy fogadd el, hogy minden r\u00E9sz\u00E9vel egyet\u00E9rtesz!")),
            React.createElement("div", null, ((_b = (_a = this.props.dashboardData) === null || _a === void 0 ? void 0 : _a.dashboard) === null || _b === void 0 ? void 0 : _b.productSynced) === false ? (React.createElement("div", null,
                "  ",
                !isProductSyncImminent ? (React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Checkbox, { label: "Hozz\u00E1j\u00E1rulok, hogy a web\u00E1ruh\u00E1zban telep\u00EDt\u00E9sre ker\u00FClj\u00F6n az M.I. m\u0171k\u00F6d\u00E9s\u00E9hez sz\u00FCks\u00E9ges JS k\u00F3d.", checked: checkbox1, onChange: function () { return _this.handleCheckboxChange('checkbox1'); } }),
                    React.createElement("br", null),
                    React.createElement("div", { style: { display: 'inline', alignItems: 'flex-start' } },
                        React.createElement(semantic_ui_react_1.Checkbox, { style: { paddingTop: 9, paddingRight: 2 }, checked: checkbox2, onChange: function () { return _this.handleCheckboxChange('checkbox2'); } }),
                        React.createElement("label", { style: { flex: 3, paddingLeft: 12, fontSize: 14 } },
                            "Elfogadom az ",
                            React.createElement("a", { href: 'https://miclub.hu/adatvedelem', target: '_blank' },
                                React.createElement("u", null, "Adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3ban")),
                            " foglaltakat!")),
                    React.createElement("br", null),
                    React.createElement(semantic_ui_react_1.Checkbox, { label: "Elfogadom az \u00C1SZF-et!", checked: checkbox3, onChange: function () { return _this.handleCheckboxChange('checkbox3'); } }),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement(semantic_ui_react_1.Button, { onClick: this.handleButtonClick, disabled: isLoading || !allCheckboxesChecked, className: 'sync-button' }, "Indulhat a szinkroniz\u00E1ci\u00F3"))) : (React.createElement("div", { style: { display: 'flex', flexDirection: 'row', alignItems: 'center' } },
                    React.createElement(semantic_ui_react_1.Button, { onClick: this.handleButtonClick, disabled: true, className: 'sync-button' }, "Indulhat a szinkroniz\u00E1ci\u00F3"),
                    React.createElement(semantic_ui_react_1.Message, { success: true, style: { marginLeft: 20, boxShadow: 'none', backgroundColor: '#FAFCFF', color: '#A6A6A6' } },
                        React.createElement("p", null, "\uD83E\uDE84Szinkroniz\u00E1ci\u00F3 elindult, a web\u00E1ruh\u00E1z m\u00E9ret\u00E9t\u0151l f\u00FCgg\u0151en ez ak\u00E1r 2-30 \u00F3r\u00E1t is ig\u00E9nybe vehet! Menj\u00FCnk tov\u00E1bb addig, a 3. l\u00E9p\u00E9sre! M\u00E1r a fel\u00E9n\u00E9l tartunk!")))))) : (React.createElement(semantic_ui_react_1.Message, { success: true },
                React.createElement("p", null, "Szinkroniz\u00E1ci\u00F3 sikeres."))))));
    };
    return TodoListSyncTabContent;
}(abstract_component_1.AbstractComponent));
exports.TodoListSyncTabContent = TodoListSyncTabContent;
