"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordResetComponent = void 0;
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var forgot_password_reset_component_1 = require("@codebuild/uikit/modules/codebuild/authentication/components/forgot-password-reset.component");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var password_input_1 = require("../../common/semantic/password-input/password-input");
var password_strength_1 = require("../../common/semantic/password-strength/password-strength");
var ForgotPasswordResetComponent = (function (_super) {
    __extends(ForgotPasswordResetComponent, _super);
    function ForgotPasswordResetComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ForgotPasswordResetComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.handleSubmit(); } },
            React.createElement(form_control_1.FormControl, { field: this.form.fields.password, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-password" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.password, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType, component: password_strength_1.PasswordStrength }),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.rePassword, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-re-password" })),
            this.renderError(),
            this.renderSuccess(),
            this.renderSubmit());
    };
    ForgotPasswordResetComponent.prototype.renderSubmit = function () {
        return React.createElement(semantic_ui_react_1.Button, { className: "ForgotPasswordSubmit", fluid: true, type: "submit", loading: this.props.loading, positive: false, "data-cy": "form-submit" }, trans_1.trans('auth.forgot-password.reset-form.submit'));
    };
    return ForgotPasswordResetComponent;
}(forgot_password_reset_component_1.ForgotPasswordResetComponent));
exports.ForgotPasswordResetComponent = ForgotPasswordResetComponent;
