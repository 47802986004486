"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardScreen = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var current_cart_sum_card_component_1 = require("../components/current-cart-sum-card.component");
var current_online_card_component_1 = require("../components/current-online-card.component");
var dashboard_header_component_1 = require("../components/dashboard-header.component");
var happening_now_component_1 = require("../components/happening-now.component");
var revenue_card_component_1 = require("../components/revenue-card.component");
require("./dashboard.screens.scss");
var DashboardScreen = (function (_super) {
    __extends(DashboardScreen, _super);
    function DashboardScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DashboardScreen.prototype.render = function () {
        return React.createElement("div", { className: "DashboardScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement(semantic_ui_react_1.Grid, { stackable: true },
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(happening_now_component_1.HappeningNowComponent, null)),
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(dashboard_header_component_1.DashboardHeaderComponent, null),
                            React.createElement(revenue_card_component_1.RevenueCardComponent, null),
                            React.createElement(current_online_card_component_1.CurrentOnlineCardComponent, null),
                            React.createElement(current_cart_sum_card_component_1.CurrentCartSumCardComponent, null))))));
    };
    return DashboardScreen;
}(abstract_component_1.AbstractComponent));
exports.DashboardScreen = DashboardScreen;
