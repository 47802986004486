"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorPicker = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var react_color_1 = require("react-color");
var semantic_ui_react_1 = require("semantic-ui-react");
var ColorPicker = (function (_super) {
    __extends(ColorPicker, _super);
    function ColorPicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listenerIndex = null;
        _this.state = {
            showPicker: false,
            value: _this.props.value
        };
        return _this;
    }
    ColorPicker.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Input, __assign({}, this.props, { readOnly: true, onClick: function () { return _this.togglePicker(); }, label: this.renderLabel() }));
    };
    ColorPicker.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    };
    ColorPicker.prototype.openedChanged = function () {
        if (this.state.showPicker)
            this.setState({ showPicker: false, value: this.state.value });
    };
    ColorPicker.prototype.componentDidMount = function () {
        var _this = this;
        ColorPicker.onCloseAllListener.push(function () { return _this.openedChanged(); });
    };
    ColorPicker.prototype.componentWillUnmount = function () {
        if (this.listenerIndex) {
            ColorPicker.onCloseAllListener[this.listenerIndex] = null;
        }
    };
    ColorPicker.prototype.renderLabel = function () {
        var _this = this;
        return React.createElement("div", { style: { padding: 0 } },
            React.createElement(semantic_ui_react_1.Label, { onClick: function () { return _this.togglePicker(); }, style: {
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    height: '100%',
                    backgroundColor: this.state.value || '#aaa',
                    cursor: 'pointer'
                } }),
            this.renderColorPicker());
    };
    ColorPicker.prototype.renderColorPicker = function () {
        var _this = this;
        if (!this.state.showPicker) {
            return;
        }
        return React.createElement("div", { style: {
                paddingBottom: 8,
                position: 'absolute',
                backgroundColor: '#FFF',
                boxShadow: '0 0 2px rgba(0,0,0,.3), 0 4px 8px rgba(0,0,0,.3)',
                borderRadius: 2,
                top: -308,
                zIndex: 9
            } },
            React.createElement(react_color_1.ChromePicker, { color: this.state.value, onChange: function (color) { return _this.handleChange(color); }, className: 'chrome-color-picker' }),
            React.createElement("div", { className: 'w-100 display-flex justify-content-center' },
                React.createElement(semantic_ui_react_1.Button, { basic: true, icon: 'check', onClick: function () { return _this.togglePicker(); }, style: { width: 40 } })));
    };
    ColorPicker.prototype.togglePicker = function () {
        var showPicker = this.state.showPicker;
        if (!showPicker)
            ColorPicker.onCloseAllListener.forEach(function (e) { return e === null || e === void 0 ? void 0 : e(); });
        this.setState({ showPicker: !showPicker });
    };
    ColorPicker.prototype.handleChange = function (color) {
        return __awaiter(this, void 0, void 0, function () {
            var hex;
            return __generator(this, function (_a) {
                if (!color) {
                    return [2];
                }
                hex = color.hex.toString().toUpperCase();
                this.setState({ value: hex });
                if (this.props.onChange) {
                    this.props.onChange({ target: { value: hex } }, null);
                }
                return [2];
            });
        });
    };
    ColorPicker.onCloseAllListener = [];
    return ColorPicker;
}(abstract_component_1.AbstractComponent));
exports.ColorPicker = ColorPicker;
