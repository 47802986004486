"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsScreen = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var state_repository_1 = require("../../common/libs/state-repository");
var billing_component_1 = require("../components/billing/billing.component");
var emailSettingsComponent_1 = require("../components/email/emailSettingsComponent");
var integration_component_1 = require("../components/integration/integration.component");
var managers_component_1 = require("../components/managers/managers.component");
var profile_component_1 = require("../components/profile/profile.component");
var settings_menu_1 = require("./settings-menu");
require("./settings.screen.scss");
var credit_card_payments_component_1 = require("../components/billing/credit-card-payments.component");
var SettingsScreen = (function (_super) {
    __extends(SettingsScreen, _super);
    function SettingsScreen() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.settingsMenu = settings_menu_1.settingsMenu;
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            activeTabId: _this.props.route.query.tab || ((_a = _this.settingsMenu[0]) === null || _a === void 0 ? void 0 : _a.identifier) || '',
            isSRAppShop: false,
            isLoading: true,
        };
        return _this;
    }
    SettingsScreen.prototype.componentDidMount = function () {
        this.fetchShop();
    };
    SettingsScreen.prototype.fetchShop = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shopResponse, isSRAppShop;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.shop.get("/a/shop/" + ((_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id), {})];
                    case 1:
                        shopResponse = _b.sent();
                        if (shopResponse) {
                            isSRAppShop = ((shopResponse === null || shopResponse === void 0 ? void 0 : shopResponse.source) === "shoprenterOnDemand" || (shopResponse === null || shopResponse === void 0 ? void 0 : shopResponse.source) === "shoprenterOnDemandChanged") || false;
                            this.setState({ isSRAppShop: isSRAppShop, isLoading: false });
                        }
                        return [2];
                }
            });
        });
    };
    SettingsScreen.prototype.render = function () {
        return React.createElement("div", { className: "SettingsScreen display-flex flex-row" },
            React.createElement("div", { className: 'SettingsMenuWrapper' },
                React.createElement(semantic_ui_react_1.Header, { as: 'h4', className: 'mb-4' }, trans_1.trans('settings.menu.title')),
                this.renderSettingsMenu()),
            React.createElement("div", { className: "flex-fill SettingsContentWrapper" }, this.renderTabContent()));
    };
    SettingsScreen.prototype.renderSettingsMenu = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Menu, { secondary: true, vertical: true }, this.settingsMenu.map(function (m) { return _this.renderMenuItem(m); }));
    };
    SettingsScreen.prototype.renderMenuItem = function (m) {
        var _this = this;
        var isCreditCardPaymentMenuItem = m.identifier === "creditCardPayments";
        var disabled = isCreditCardPaymentMenuItem && this.state.isSRAppShop;
        return React.createElement(semantic_ui_react_1.Menu.Item, { className: 'MenuItem', active: m.identifier === this.state.activeTabId, onClick: function () { return _this.changeTab(m.identifier); }, content: this.renderItemContent(m), disabled: disabled || this.state.isLoading });
    };
    SettingsScreen.prototype.renderItemContent = function (item) {
        return React.createElement("div", { className: 'MenuItemContent' },
            React.createElement(semantic_ui_react_1.Icon, { name: item.icon, className: 'mr-4' }),
            React.createElement("span", null, trans_1.trans("settings.menu.item." + item.name)));
    };
    SettingsScreen.prototype.changeTab = function (tabId) {
        router_provider_1.RouterProvider.replaceQuery({
            tab: tabId
        });
    };
    SettingsScreen.prototype.renderTabContent = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var active = this.state.activeTabId;
        switch (active) {
            case 'profiles':
                return React.createElement(profile_component_1.ProfileComponent, null);
            case 'integration':
                return React.createElement(integration_component_1.IntegrationComponent, { showSyncProgress: true });
            case 'billing':
                return React.createElement(billing_component_1.BillingComponent, null);
            case 'emailSettings':
                return React.createElement(emailSettingsComponent_1.EmailSettingsComponent, { onUpdate: function () { return _this.refresh(); }, shop: (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.response });
            case 'managers':
                return React.createElement(managers_component_1.ManagersComponent, { onUpdate: function () { return _this.refresh(); }, shop: (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.shop) === null || _d === void 0 ? void 0 : _d.response });
            case 'creditCardPayments':
                return React.createElement(credit_card_payments_component_1.CreditCardPaymentsPage, null);
            default:
                return null;
        }
    };
    SettingsScreen.prototype.refresh = function () {
        this.fetchShop();
    };
    return SettingsScreen;
}(abstract_component_1.AbstractComponent));
exports.SettingsScreen = SettingsScreen;
