"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterAnalyticComponent = void 0;
var environment_1 = require("@codebuild/uikit/libs/environment");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var axios_1 = require("axios");
var React = require("react");
var react_copy_to_clipboard_1 = require("react-copy-to-clipboard");
var ReactMarkdown = require("react-markdown");
var rxjs_1 = require("rxjs");
var semantic_ui_react_1 = require("semantic-ui-react");
var ShoprenterAnalyticComponent = (function (_super) {
    __extends(ShoprenterAnalyticComponent, _super);
    function ShoprenterAnalyticComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.user = local_store_1.LocalStore.getItem('User');
        _this.state = __assign(__assign({}, _this.state), { instruction: null });
        return _this;
    }
    ShoprenterAnalyticComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(rxjs_1.timer(0, 1000).subscribe(function () { return _this.fetchInstructions(); }));
    };
    ShoprenterAnalyticComponent.prototype.fetchInstructions = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var instruction;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, axios_1.default.get(environment_1.Environment.get('scriptUrl') + "/docs/latest.shoprenter.installation.md")];
                    case 1:
                        instruction = _b.sent();
                        if (!((_a = instruction === null || instruction === void 0 ? void 0 : instruction.data) === null || _a === void 0 ? void 0 : _a.length)) {
                            return [2];
                        }
                        this.setState({ instruction: instruction === null || instruction === void 0 ? void 0 : instruction.data });
                        return [2];
                }
            });
        });
    };
    ShoprenterAnalyticComponent.prototype.renderReplacedString = function (str) {
        var _a, _b;
        return (str || '').replace(/__INTEGRATION_IDENTIFIER/g, (_b = (_a = this.props.webshopData) === null || _a === void 0 ? void 0 : _a.integrationId) === null || _b === void 0 ? void 0 : _b.toString());
    };
    ShoprenterAnalyticComponent.prototype.renderReplacedCopyString = function (str) {
        return this.renderReplacedString((str || '')
            .replace(/```js/g, '')
            .replace(/```/g, ''));
    };
    ShoprenterAnalyticComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { className: 'pb-0', centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { textAlign: 'center', className: 'mb-6', as: 'h2' }, trans_1.trans('shop-registration.integration.shoprenter-analytic.title')),
                    React.createElement("div", null,
                        React.createElement("p", null, trans_1.trans('shop-registration.integration.shoprenter-analytic.description.subtitle')),
                        !!((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.instruction) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement("div", null,
                            React.createElement("div", { className: 'CodeBlock' },
                                React.createElement(ReactMarkdown, null, this.renderReplacedString((_c = this.state) === null || _c === void 0 ? void 0 : _c.instruction))),
                            React.createElement(react_copy_to_clipboard_1.CopyToClipboard, { text: this.renderReplacedCopyString((_d = this.state) === null || _d === void 0 ? void 0 : _d.instruction) },
                                React.createElement(semantic_ui_react_1.Button, { icon: "copy", basic: true, floated: "right", content: trans_1.trans('shop-registration.integration.shoprenter-analytics.copy-button.title') }))))))),
            ((_e = this.state.shop) === null || _e === void 0 ? void 0 : _e.error) && React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Message, { negative: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, trans_1.trans('errors.integration.shoprenter.accessError.title')),
                        React.createElement("p", null, trans_1.trans('errors.integration.shoprenter.accessError.description'))))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'center' },
                    React.createElement(semantic_ui_react_1.Button, { basic: false, onClick: function () { return _this.handleSubmit(); }, content: trans_1.trans('shop-registration.integration.shoprenter-analytic.start-button.title') }))));
    };
    ShoprenterAnalyticComponent.prototype.handleSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    if (this.props.onSubmit) {
                        this.props.onSubmit(null);
                    }
                }
                catch (e) {
                    console.log(e);
                }
                return [2];
            });
        });
    };
    return ShoprenterAnalyticComponent;
}(abstract_component_1.AbstractComponent));
exports.ShoprenterAnalyticComponent = ShoprenterAnalyticComponent;
