"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCard = void 0;
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
require("./credit-card.component.scss");
var MASTERCARD_LOGO = (React.createElement("svg", { width: "78", height: "50", viewBox: "0 0 780 500", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: "logo" },
    React.createElement("path", { d: "M465.738 113.525H313.812V386.475H465.738V113.525Z", fill: "#FF5A00" }),
    React.createElement("path", { d: "M323.926 250C323.926 194.545 349.996 145.326 390 113.525C360.559 90.3769 323.42 76.3867 282.91 76.3867C186.945 76.3867 109.297 154.035 109.297 250C109.297 345.965 186.945 423.614 282.91 423.614C323.42 423.614 360.559 409.623 390 386.475C349.94 355.123 323.926 305.455 323.926 250Z", fill: "#EB001B" }),
    React.createElement("path", { d: "M670.711 250C670.711 345.965 593.062 423.614 497.098 423.614C456.588 423.614 419.449 409.623 390.008 386.475C430.518 354.618 456.082 305.455 456.082 250C456.082 194.545 430.012 145.326 390.008 113.525C419.393 90.3769 456.532 76.3867 497.041 76.3867C593.062 76.3867 670.711 154.541 670.711 250Z", fill: "#F79E1B" })));
var VISA_LOGO = (React.createElement("svg", { width: "72", height: "24", viewBox: "0 0 72 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: "logo" },
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M52.3973 1.01093L51.5588 5.99054C49.0448 4.56717 43.3231 4.23041 43.3231 6.85138C43.3231 7.89285 44.6177 8.60913 46.178 9.47241C48.5444 10.7817 51.5221 12.4291 51.5221 16.062C51.5221 21.8665 45.4731 24 41.4645 24C37.4558 24 34.8325 22.6901 34.8325 22.6901L35.7065 17.4848C38.1115 19.4688 45.4001 20.032 45.4001 16.8863C45.4001 15.5645 43.9656 14.785 42.3019 13.8811C40.0061 12.6336 37.2742 11.1491 37.2742 7.67563C37.2742 1.30988 44.1978 0 47.1132 0C49.8102 0 52.3973 1.01093 52.3973 1.01093ZM66.6055 23.6006H72L67.2966 0.414276H62.5732C60.3923 0.414276 59.8612 2.14215 59.8612 2.14215L51.0996 23.6006H57.2234L58.4481 20.1566H65.9167L66.6055 23.6006ZM60.1406 15.399L63.2275 6.72235L64.9642 15.399H60.1406ZM14.7942 16.3622L20.3951 0.414917H26.7181L17.371 23.6012H11.2498L6.14551 3.45825C2.83215 1.41281 0 0.807495 0 0.807495L0.108643 0.414917H9.36816C11.9161 0.414917 12.1552 2.50314 12.1552 2.50314L14.1313 12.9281L14.132 12.9294L14.7942 16.3622ZM25.3376 23.6006H31.2126L34.8851 0.414917H29.0095L25.3376 23.6006Z", fill: "white" })));
var CreditCard = (function (_super) {
    __extends(CreditCard, _super);
    function CreditCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CreditCard.prototype.render = function () {
        var cardIssuerLogo = VISA_LOGO;
        switch (this.props.cardType) {
            case "Visa":
                cardIssuerLogo = VISA_LOGO;
                break;
            case "MasterCard":
                cardIssuerLogo = MASTERCARD_LOGO;
                break;
        }
        return (React.createElement("div", { className: 'CreditCardContainer' },
            React.createElement("div", { className: "card" },
                React.createElement("div", { className: "card-inner" },
                    React.createElement("div", { className: "card-front" },
                        React.createElement("div", { className: "card-bg" }),
                        cardIssuerLogo,
                        React.createElement("div", { className: "card-contactless" },
                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "46", height: "56" },
                                React.createElement("path", { fill: "none", stroke: "#f9f9f9", "stroke-width": "6", "stroke-linecap": "round", d: "m35,3a50,50 0 0,1 0,50M24,8.5a39,39 0 0,1 0,39M13.5,13.55a28.2,28.5\n        0 0,1 0,28.5M3,19a18,17 0 0,1 0,18" }))),
                        React.createElement("div", { className: "card-chip" }),
                        React.createElement("div", { className: "card-holder" }, this.props.cardHolderName.toUpperCase()),
                        React.createElement("div", { className: "card-number" }, this.props.cardNumberWithLastFourDigits),
                        React.createElement("div", { className: "card-valid" }, this.props.cardExpirationDate)),
                    React.createElement("div", { className: "card-back" },
                        React.createElement("div", { className: "card-signature" }),
                        React.createElement("div", { className: "card-seccode" }, "***"))))));
    };
    return CreditCard;
}(abstract_component_1.AbstractComponent));
exports.CreditCard = CreditCard;
