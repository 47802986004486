"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoriesScreen = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var categories_list_component_1 = require("../components/categories/categories-list.component");
require("./webshop-data.screens.scss");
var CategoriesScreen = (function (_super) {
    __extends(CategoriesScreen, _super);
    function CategoriesScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CategoriesScreen.prototype.render = function () {
        var _a;
        return React.createElement("div", { className: "WebshopDataScreen CategoriesScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement(categories_list_component_1.CategoriesListComponent, { route: (_a = this.props) === null || _a === void 0 ? void 0 : _a.route })));
    };
    return CategoriesScreen;
}(abstract_component_1.AbstractComponent));
exports.CategoriesScreen = CategoriesScreen;
