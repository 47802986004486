"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendErrorMessage = exports.sendAddPaymentInfoMessage = exports.ADD_PAYMENT_INFO_PROPERTIES = exports.sendPurchaseMessage = exports.PURCHASE_PROPERTIES = exports.sendInitiatePurchaseMessage = exports.INITIATE_PURCHASE_PROPERTIES = exports.sendInitiateCheckoutMessage = exports.INITIATE_CHECKOUT_PROPERTIES = exports.sendAddToCartMessage = exports.sendContentViewMessage = exports.MONTHLY_SUBSCRIPTION_CONTENT_VIEW_PROPERTIES = exports.sendRejectConsentMessage = exports.sendGrantConsentMessage = exports.sendEncryptedPhoneNumberMessage = exports.sendEncryptedEmailAddressMessage = exports.BARION_PIXEL_MESSAGES = exports.BARION_PIXEL_MESSAGE_CATEGORIES = exports.BARION_PIXEL_ID = void 0;
exports.BARION_PIXEL_ID = "BP-6aRHMcwzRK-6F";
exports.BARION_PIXEL_MESSAGE_CATEGORIES = {
    IDENTITY: "identity",
    CONSENT: "consent",
    TRACK: "track",
};
exports.BARION_PIXEL_MESSAGES = {
    SET_ENCRYPTED_EMAIL_ADDRESS: "setEncryptedEmail",
    SET_ENCRYPTED_PHONE_NUMBER: "setEncryptedPhone",
    GRANT_CONSENT: "grantConsent",
    REJECT_CONSENT: "rejectConsent",
    CONTENT_VIEW: "contentView",
    ADD_TO_CART: "addToCard",
    INITIATE_CHECKOUT: "initiateCheckout",
    INITIATE_PURCHASE: "initiatePurchase",
    PURCHASE: "purchase",
    ADD_PAYMENT_INFO: "addPaymentInfo",
    ERROR: "error",
};
function digest(input) {
    return __awaiter(this, void 0, void 0, function () {
        var encoder, data, hash;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    encoder = new TextEncoder();
                    data = encoder.encode(input);
                    return [4, window.crypto.subtle.digest("SHA-256", data)];
                case 1:
                    hash = _a.sent();
                    return [2, hash];
            }
        });
    });
}
function sendEncryptedEmailAddressMessage(emailAddress) {
    return __awaiter(this, void 0, void 0, function () {
        var hashedEmailAddress, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, digest(emailAddress)];
                case 1:
                    hashedEmailAddress = _a.sent();
                    if (window["bp"]) {
                        response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.IDENTITY, exports.BARION_PIXEL_MESSAGES.SET_ENCRYPTED_EMAIL_ADDRESS, hashedEmailAddress);
                        console.log('sendEncryptedEmailMessage response:', response);
                    }
                    return [2];
            }
        });
    });
}
exports.sendEncryptedEmailAddressMessage = sendEncryptedEmailAddressMessage;
function sendEncryptedPhoneNumberMessage(phoneNumber) {
    return __awaiter(this, void 0, void 0, function () {
        var hashedPhoneNumber, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, digest(phoneNumber)];
                case 1:
                    hashedPhoneNumber = _a.sent();
                    if (window["bp"]) {
                        response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.IDENTITY, exports.BARION_PIXEL_MESSAGES.SET_ENCRYPTED_PHONE_NUMBER, hashedPhoneNumber);
                        console.log('sendEncryptedPhoneNumberMessage response:', response);
                    }
                    return [2];
            }
        });
    });
}
exports.sendEncryptedPhoneNumberMessage = sendEncryptedPhoneNumberMessage;
function sendGrantConsentMessage() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.CONSENT, exports.BARION_PIXEL_MESSAGES.GRANT_CONSENT);
                console.log('sendGrantConsentMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendGrantConsentMessage = sendGrantConsentMessage;
function sendRejectConsentMessage() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.CONSENT, exports.BARION_PIXEL_MESSAGES.REJECT_CONSENT);
                console.log('sendRejectConsentMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendRejectConsentMessage = sendRejectConsentMessage;
exports.MONTHLY_SUBSCRIPTION_CONTENT_VIEW_PROPERTIES = {
    contentType: 'Product',
    currency: 'HUF',
    id: 'MONTHLY_SUBSCRIPTION_CREDIT_CARD',
    name: 'Havi előfizetés bankkártyás fizetéssel',
    quantity: 1.0,
    unit: 'darab',
    unitPrice: 127,
};
function sendContentViewMessage(contentViewProperties) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            console.log('sendContentViewMessage contentViewProperties:', contentViewProperties);
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.TRACK, exports.BARION_PIXEL_MESSAGES.CONTENT_VIEW, contentViewProperties);
                console.log('sendContentViewMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendContentViewMessage = sendContentViewMessage;
function sendAddToCartMessage(addToCardProperties) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            console.log('sendAddToCartMessage addToCardProperties:', addToCardProperties);
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.TRACK, exports.BARION_PIXEL_MESSAGES.ADD_TO_CART, addToCardProperties);
                console.log('sendAddToCartMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendAddToCartMessage = sendAddToCartMessage;
exports.INITIATE_CHECKOUT_PROPERTIES = {
    contents: [
        exports.MONTHLY_SUBSCRIPTION_CONTENT_VIEW_PROPERTIES
    ],
    currency: 'HUF',
    revenue: 127.0,
    step: 1
};
function sendInitiateCheckoutMessage(initiateCheckoutProperties) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            console.log('sendInitiateCheckoutMessage initiateCheckoutProperties:', initiateCheckoutProperties);
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.TRACK, exports.BARION_PIXEL_MESSAGES.INITIATE_CHECKOUT, initiateCheckoutProperties);
                console.log('sendInitiateCheckoutMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendInitiateCheckoutMessage = sendInitiateCheckoutMessage;
exports.INITIATE_PURCHASE_PROPERTIES = __assign({}, exports.INITIATE_CHECKOUT_PROPERTIES);
function sendInitiatePurchaseMessage(initiatePurchaseProperties) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            console.log('sendInitiatePurchaseMessage initiatePurchaseProperties:', initiatePurchaseProperties);
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.TRACK, exports.BARION_PIXEL_MESSAGES.INITIATE_PURCHASE, initiatePurchaseProperties);
                console.log('sendInitiatePurchaseMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendInitiatePurchaseMessage = sendInitiatePurchaseMessage;
exports.PURCHASE_PROPERTIES = __assign({}, exports.INITIATE_CHECKOUT_PROPERTIES);
function sendPurchaseMessage(purchaseProperties) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            console.log('sendPurchaseMessage purchaseProperties:', purchaseProperties);
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.TRACK, exports.BARION_PIXEL_MESSAGES.PURCHASE, purchaseProperties);
                console.log('sendPurchaseMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendPurchaseMessage = sendPurchaseMessage;
exports.ADD_PAYMENT_INFO_PROPERTIES = {
    contents: [
        exports.MONTHLY_SUBSCRIPTION_CONTENT_VIEW_PROPERTIES
    ],
    paymentMethod: 'Barion',
};
function sendAddPaymentInfoMessage(addPaymentInfoProperties) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            console.log('sendAddPaymentInfoMessage addPaymentInfoProperties:', addPaymentInfoProperties);
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.TRACK, exports.BARION_PIXEL_MESSAGES.ADD_PAYMENT_INFO, addPaymentInfoProperties);
                console.log('sendAddPaymentInfoMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendAddPaymentInfoMessage = sendAddPaymentInfoMessage;
var ERROR_PROPERTIES = {
    leírás: 'ERROR_PLACEHOLDER_DESCRIPTION',
};
function sendErrorMessage(error) {
    return __awaiter(this, void 0, void 0, function () {
        var mutableErrorProperties, response;
        return __generator(this, function (_a) {
            console.log('sendErrorMessage error:', error);
            mutableErrorProperties = __assign(__assign({}, ERROR_PROPERTIES), { leírás: error });
            if (window["bp"]) {
                response = window["bp"](exports.BARION_PIXEL_MESSAGE_CATEGORIES.TRACK, exports.BARION_PIXEL_MESSAGES.ERROR, mutableErrorProperties);
                console.log('sendErrorMessage response:', response);
            }
            return [2];
        });
    });
}
exports.sendErrorMessage = sendErrorMessage;
