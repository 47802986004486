"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsListComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var observable_control_1 = require("@codebuild/uikit/libs/observable-control");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var sort_header_1 = require("../../../common/components/sort-header/sort-header");
var number_parser_1 = require("../../../common/libs/number-parser");
var state_repository_1 = require("../../../common/libs/state-repository");
var product_list_item_component_1 = require("./product-list-item.component");
require("./product-list.component.scss");
var ProductsListComponent = (function (_super) {
    __extends(ProductsListComponent, _super);
    function ProductsListComponent() {
        var _a, _b, _c, _d, _e;
        var _this = _super.apply(this, arguments) || this;
        _this.productRepository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'product');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                query: new field_1.Field({
                    name: 'query',
                    label: '',
                    value: '',
                    placeholder: trans_1.trans('webshop-data.product-list.search.placeholder')
                })
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 5,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query.query) !== null && _e !== void 0 ? _e : ''
            }
        });
        return _this;
    }
    ProductsListComponent.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.form.$value
            .pipe(operators_1.debounceTime(450))
            .subscribe(function (value) {
            _this.control.set('query', value === null || value === void 0 ? void 0 : value.query);
            _this.control.set('page', 0);
        });
    };
    ProductsListComponent.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: "ProductListComponent ListComponent" },
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, trans_1.trans('webshop-data.product-list.screen.title')),
                        React.createElement(observable_control_1.ObservableControl, { "$observable": this.control.response$, transform: function (value) { return ({
                                children: (!value ? '' : React.createElement(semantic_ui_react_1.Statistic, { horizontal: true, size: 'tiny', label: trans_1.trans('webshop-data.product-list.screen.product-count.label'), value: number_parser_1.splitToNumberString(value === null || value === void 0 ? void 0 : value.total) }))
                            }); } },
                            React.createElement("div", null))),
                    React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'right' }, this.renderListHeader()))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, ref: function (ref) { return _this.repositoryListRef = ref; }, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/product", renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 8 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 8 })); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                    { text: 1, value: 1 },
                    { text: 10, value: 10 },
                    { text: 25, value: 25 },
                    { text: 50, value: 50 },
                    { text: 100, value: 100 },
                ] }));
    };
    ProductsListComponent.prototype.renderListHeader = function () {
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'ListHeader' },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.query, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { icon: 'search', control: semantic_ui_react_1.Input })))));
    };
    ProductsListComponent.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                        React.createElement(sort_header_1.SortHeader, { title: trans_1.trans('webshop-data.product-list.item.name.label'), property: 'dataName', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center' }, trans_1.trans('webshop-data.product-list.item.childCount.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: trans_1.trans('webshop-data.product-list.item.stock.label'), property: 'dataStock', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: trans_1.trans('webshop-data.product-list.item.sold.label'), property: 'statisticsSoldAll', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center' }, trans_1.trans('webshop-data.product-list.item.full-price.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center' }, trans_1.trans('webshop-data.product-list.item.discount-price.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, trans_1.trans('webshop-data.product-list.item.category.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center' }, trans_1.trans('webshop-data.product-list.item.advertisable.label')))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    ProductsListComponent.prototype.renderItem = function (ctx, item, index) {
        return React.createElement(product_list_item_component_1.ProductListItemComponent, { item: item });
    };
    ProductsListComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return ProductsListComponent;
}(responsive_component_1.ResponsiveComponent));
exports.ProductsListComponent = ProductsListComponent;
