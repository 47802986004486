"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminMenu = void 0;
exports.adminMenu = [
    {
        name: 'categories',
        path: '/admin/categories',
        icon: 'archive',
        activeRegexp: /^\/admin\/categories/,
        items: []
    },
    {
        name: 'templates',
        path: '/admin/templates',
        icon: 'envelope open',
        activeRegexp: /^\/admin\/templates/,
        items: []
    },
    {
        name: 'performance',
        path: '/admin/performance',
        icon: 'envelope open',
        activeRegexp: /^\/admin\/performance$/,
        items: []
    },
    {
        name: 'integration-stat',
        path: '/admin/integration-stat',
        icon: 'envelope open',
        activeRegexp: /^\/admin\/integration-stat$/,
        items: []
    },
    {
        name: 'performance-by-template',
        path: '/admin/performance-by-template',
        icon: 'envelope open',
        activeRegexp: /^\/admin\/performance-by-template/,
        items: []
    },
    {
        name: 'custom-newsletters',
        path: '/admin/custom-newsletters',
        icon: 'envelope open',
        activeRegexp: /^\/admin\/custom-newsletters/,
        items: []
    },
    {
        name: 'shops',
        path: '/admin/shops',
        icon: 'cart',
        activeRegexp: /^\/admin\/shops/,
        items: []
    },
    {
        name: 'business-report-export',
        path: '/admin/business-report-export',
        icon: 'line graph',
        activeRegexp: /^\/admin\/business-report-export/,
        items: []
    },
    {
        name: 'shoprenter-apps',
        path: '/admin/shoprenter-apps',
        icon: 'list ul',
        activeRegexp: /^\/admin\/shoprenter-apps/,
        items: []
    },
    {
        name: 'shoprenter-invoices',
        path: '/admin/shoprenter-invoices',
        icon: 'dollar sign',
        activeRegexp: /^\/admin\/shoprenter-invoices/,
        items: []
    },
    {
        name: 'generated-reports',
        path: '/admin/generated-reports',
        icon: 'calendar alternate',
        activeRegexp: /^\/admin\/generated-reports/,
        items: []
    },
    {
        name: 'invite-manager-to-all-shops',
        path: '/admin/invite-manager-to-all-shops',
        icon: 'users',
        activeRegexp: /^\/admin\/invite-manager-to-all-shops/,
        items: []
    },
];
