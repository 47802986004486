"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
require("./profile.component.scss");
var shop_profile_component_1 = require("./shop-profile.component");
var user_profile_component_1 = require("./user-profile.component");
var ProfileComponent = (function (_super) {
    __extends(ProfileComponent, _super);
    function ProfileComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProfileComponent.prototype.render = function () {
        return React.createElement("div", { className: 'ProfileComponent' },
            React.createElement("div", { className: 'mb-8' },
                React.createElement(user_profile_component_1.UserProfileComponent, null)),
            React.createElement("div", { className: 'mb-8' },
                React.createElement(shop_profile_component_1.ShopProfileComponent, null)));
    };
    return ProfileComponent;
}(abstract_component_1.AbstractComponent));
exports.ProfileComponent = ProfileComponent;
