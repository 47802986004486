"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationListControl = void 0;
var lodash_1 = require("lodash");
var repository_list_control_1 = require("./repository-list-control");
var PaginationListControl = (function (_super) {
    __extends(PaginationListControl, _super);
    function PaginationListControl(options) {
        if (options === void 0) { options = {}; }
        return _super.call(this, __assign(__assign({}, options), { queryMapper: PaginationListControl.queryMapper, responseMapper: PaginationListControl.responseMapper })) || this;
    }
    PaginationListControl.queryMapper = function (value) {
        var _a, _b, _c;
        return __assign(__assign({}, lodash_1.clone(value)), { _page: (_a = value.page) !== null && _a !== void 0 ? _a : 0, _limit: (_b = value.limit) !== null && _b !== void 0 ? _b : 0, _sort: (_c = value.sort) !== null && _c !== void 0 ? _c : null });
    };
    PaginationListControl.responseMapper = function (value) {
        return {
            page: value.page,
            limit: value.limit,
            total: value.total
        };
    };
    PaginationListControl.prototype.next = function () {
        var _a, _b;
        if (this.response.total === undefined) {
            return;
        }
        if (this.response.page === undefined) {
            return;
        }
        if ((this.response.total / this.response.page) <= (((_a = this.data.page) !== null && _a !== void 0 ? _a : 0) + 1)) {
            return;
        }
        this.set('page', ((_b = this.data.page) !== null && _b !== void 0 ? _b : 0) + 1);
    };
    PaginationListControl.prototype.prev = function () {
        var _a;
        var value = ((_a = this.data.page) !== null && _a !== void 0 ? _a : 0) - 1;
        if (value > 0) {
            return;
        }
        this.set('page', value);
    };
    PaginationListControl.prototype.page = function (index) {
        this.set('page', index);
    };
    PaginationListControl.prototype.limit = function (size) {
        this.set('limit', size);
        this.set('page', 0);
    };
    PaginationListControl.prototype.sort = function (property, sort) {
        var _a;
        if (!sort) {
            this.set('sort', null);
        }
        this.set('sort', (_a = {}, _a[property] = sort, _a));
    };
    return PaginationListControl;
}(repository_list_control_1.RepositoryListControl));
exports.PaginationListControl = PaginationListControl;
