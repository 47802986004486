"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactDatepickerFormControlType = void 0;
var abstract_form_control_type_1 = require("@codebuild/uikit/libs/form/form-control/types/abstract.form-control-type");
var moment = require("moment");
var React = require("react");
var ReactDatepickerFormControlType = (function (_super) {
    __extends(ReactDatepickerFormControlType, _super);
    function ReactDatepickerFormControlType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReactDatepickerFormControlType.prototype.getProps = function () {
        var _this = this;
        var v = this.formControl.props.field.getValue();
        console.log(v);
        return {
            selected: moment(v).isValid() ? moment(v).toDate() : null,
            onChange: function (value) { return _this.formControl.props.field.setValue(value); }
        };
    };
    ReactDatepickerFormControlType.prototype.beforeComponent = function () {
        return React.createElement("label", { className: "ReactDatepickerLabel" }, this.formControl.props.field.label);
    };
    ReactDatepickerFormControlType.prototype.afterComponent = function () {
        return React.createElement("div", { className: "AfterReactDatepicker" });
    };
    return ReactDatepickerFormControlType;
}(abstract_form_control_type_1.AbstractFormControlType));
exports.ReactDatepickerFormControlType = ReactDatepickerFormControlType;
