"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SynchronizationProgressComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var sleep_1 = require("@codebuild/uikit/libs/libs/sleep");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var detailed_error_1 = require("../../common/components/errors/detailed-error");
var state_repository_1 = require("../../common/libs/state-repository");
var SynchronizationProgressComponent = (function (_super) {
    __extends(SynchronizationProgressComponent, _super);
    function SynchronizationProgressComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.integration = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'integration');
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            shop: session_storage_1.SessionStorage.getItem('SelectedShop'),
            integration: null,
            loading: false
        };
        return _this;
    }
    SynchronizationProgressComponent.prototype.componentDidMount = function () {
        this.fetchProgress();
    };
    SynchronizationProgressComponent.prototype.fetchProgress = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ loading: true });
                        return [4, this.integration.get("/s/" + this.props.shopId + "/integration")];
                    case 1:
                        _a.sent();
                        if (this.isIntegrationTerminated()) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        return [4, sleep_1.sleep(1000)];
                    case 2:
                        _a.sent();
                        return [2, this.fetchProgress()];
                }
            });
        });
    };
    SynchronizationProgressComponent.prototype.isIntegrationTerminated = function () {
        var integration = lodash_1.get(this.state, 'integration.response', null);
        return (integration === null || integration === void 0 ? void 0 : integration.status) === 'success' || (integration === null || integration === void 0 ? void 0 : integration.status) === 'error';
    };
    SynchronizationProgressComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        return React.createElement(semantic_ui_react_1.Grid, { as: semantic_ui_react_1.Segment, padded: true },
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { textAlign: 'center' }, trans_1.trans('sync-progress.title')),
                    React.createElement("p", null, trans_1.trans('sync-progress.description')))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 16 }, this.renderSyncStatus())),
            ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.integration) === null || _b === void 0 ? void 0 : _b.error) && React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(detailed_error_1.DetailedError, { error: (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.update) === null || _d === void 0 ? void 0 : _d.integration }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, { className: 'display-flex justify-content-center' },
                    React.createElement(semantic_ui_react_1.Button, { disabled: lodash_1.get(this.state, 'integration.response.status', '') !== 'success', color: 'blue', content: trans_1.trans('integration.sync.goToDashboard'), onClick: function () { return _this.handleIntegrationFinished(); } }))));
    };
    SynchronizationProgressComponent.prototype.handleIntegrationFinished = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.shop.put("/s/" + ((_a = this.props) === null || _a === void 0 ? void 0 : _a.shopId) + "/shop/syncSuccess")];
                    case 1:
                        _b.sent();
                        router_provider_1.RouterProvider.goTo('/');
                        return [3, 3];
                    case 2:
                        e_1 = _b.sent();
                        console.log(e_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    SynchronizationProgressComponent.prototype.renderSyncStatus = function () {
        var _a, _b, _c, _d, _e;
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('product')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('customer')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('subscriber')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('order')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('category')),
                    this.renderPostProcessing())),
            ((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.integration) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.error) && React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(detailed_error_1.DetailedError, { error: (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.update) === null || _e === void 0 ? void 0 : _e.integration }))));
    };
    SynchronizationProgressComponent.prototype.renderEntitySync = function (entity) {
        var data = lodash_1.get(this.state, "integration.response.sync.meta." + entity, 0);
        switch (data === null || data === void 0 ? void 0 : data.status) {
            case 'success':
                return this.renderStateSuccess(entity, data);
            case 'pending':
                return this.renderStatePending(entity, data);
            case 'stopped':
                return this.renderStateStopped(entity, data);
            case 'error':
                return this.renderStateError(entity, data);
            default:
                return this.renderStatePending(entity, data);
        }
    };
    SynchronizationProgressComponent.prototype.renderStatePending = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { loading: true, size: 'large', color: 'grey', className: 'mr-3', name: 'circle notch' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.pending." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    SynchronizationProgressComponent.prototype.renderStateStopped = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { loading: true, size: 'large', color: 'yellow', className: 'mr-3', name: 'minus circle' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.pending." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    SynchronizationProgressComponent.prototype.renderStateSuccess = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'mr-3', color: 'green', loading: false, name: 'check circle' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.success." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    SynchronizationProgressComponent.prototype.renderStateError = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'red', className: 'mr-3', loading: false, name: 'times circle outline' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.error." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    SynchronizationProgressComponent.prototype.renderPostProcessing = function () {
        var status = lodash_1.get(this.state, 'integration.response.status', null);
        if (['queued', 'configured', 'syncing'].includes(status)) {
            return React.createElement(semantic_ui_react_1.Segment, { disabled: true },
                React.createElement("div", { className: 'display-flex flex-row align-items-center' },
                    React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                        React.createElement("h5", { style: { margin: 0 } }, trans_1.trans('sync-progress.postprocessing.syncing.title')))));
        }
        return React.createElement(semantic_ui_react_1.Segment, null,
            React.createElement("div", { className: 'display-flex flex-row align-items-center' },
                React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                    status === 'processing' && React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'grey', className: 'mr-3', loading: true, name: 'circle notch' }),
                    status === 'success' && React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'green', className: 'mr-3', loading: false, name: 'check circle' }),
                    status === 'error' && React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'red', className: 'mr-3', loading: false, name: 'times circle outline' }),
                    React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.postprocessing." + status + ".title")))));
    };
    SynchronizationProgressComponent.prototype.restartSync = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.integration.put("/s/" + ((_a = this.props) === null || _a === void 0 ? void 0 : _a.shopId) + "/integration/startSync")];
                    case 1:
                        _b.sent();
                        return [4, sleep_1.sleep(1000)];
                    case 2:
                        _b.sent();
                        this.fetchProgress();
                        return [2];
                }
            });
        });
    };
    return SynchronizationProgressComponent;
}(abstract_component_1.AbstractComponent));
exports.SynchronizationProgressComponent = SynchronizationProgressComponent;
