"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationStatScreen = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var moment = require("moment");
var React = require("react");
var DatePicker = require("react-datepicker");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var number_parser_1 = require("../../../common/libs/number-parser");
require("./integration-stat.screen.scss");
var IntegrationStatScreen = (function (_super) {
    __extends(IntegrationStatScreen, _super);
    function IntegrationStatScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    name: 'from',
                    label: '',
                    value: moment().add(-1, 'days').toDate(),
                }),
                to: new field_1.Field({
                    name: 'to',
                    label: '',
                    value: moment().toDate(),
                }),
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                from: _this.form.fields.from.getValue(),
                to: _this.form.fields.to.getValue(),
            }
        });
        _this.totalSent = 0;
        _this.totalSentP = 0;
        _this.totalClicked = 0;
        _this.totalClickedP = 0;
        _this.totalSubscriberCount = 0;
        _this.totalSubscriberCountP = 0;
        _this.totalOpened = 0;
        _this.totalOpenedP = 0;
        _this.totalOrderSummary = 0;
        _this.totalOrderSummaryP = 0;
        return _this;
    }
    IntegrationStatScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$value.pipe(operators_1.debounceTime(250)).subscribe(function (value) {
            _this.control.set('page', 0);
        }), rxjs_1.timer(0, 1000).subscribe(function () { return _this.forceUpdate(); }), this.form.fields.from.$value.subscribe(function (value) { return _this.control.set('from', moment(value).startOf('day').toDate()); }), this.form.fields.to.$value.subscribe(function (value) { return _this.control.set('to', moment(value).endOf('day').toDate()); }));
    };
    IntegrationStatScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: "TemplatesScreen AdminScreen PerformanceScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' },
                                    "Havi elsz\u00E1mol\u00E1s",
                                    React.createElement(semantic_ui_react_1.Button, { className: 'ml-4', loading: false, disabled: false, size: 'small', color: "blue", basic: true, onClick: function () { return _this.downloadCsv(_this.rawItems); }, content: "CSV let\u00F6lt\u00E9s" }))),
                            React.createElement(semantic_ui_react_1.Grid.Column, { floated: 'right', className: 'date-picker-wrapper justify-content-end' },
                                React.createElement("p", { className: 'header-filter-label' }, "D\u00E1tum sz\u0171r\u00E9s"),
                                React.createElement(semantic_ui_react_1.Grid.Row, null,
                                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                                        React.createElement(semantic_ui_react_1.Form, { className: 'display-flex' },
                                            React.createElement("div", { className: "date-wrapper" },
                                                React.createElement("div", { className: 'position-relative display-flex' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })),
                                                React.createElement("div", { className: 'date-picker-divider' }),
                                                React.createElement("div", { className: 'position-relative display-flex mr-4' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' }))))))))),
                    React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: '/a/integration-stat', renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 7 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 7 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }))));
    };
    IntegrationStatScreen.prototype.renderListHeader = function () {
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'ListHeader' },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.query, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { icon: 'search', control: semantic_ui_react_1.Input })))));
    };
    IntegrationStatScreen.prototype.renderItemsWrapper = function (ctx) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
        this.totalSent = 0;
        this.totalClicked = 0;
        this.totalSubscriberCount = 0;
        this.totalOpened = 0;
        this.totalOrderSummary = 0;
        this.totalSentP = 0;
        this.totalClickedP = 0;
        this.totalSubscriberCountP = 0;
        this.totalOpenedP = 0;
        this.totalOrderSummaryP = 0;
        var sPEmailSent = [0, 0];
        var sPEmailClicked = [0, 0];
        var sPEmailOpened = [0, 0];
        var sPSubscriberCount = [0, 0];
        var sPOrderSummary = [0, 0];
        for (var _i = 0, _18 = (((_a = ctx.state) === null || _a === void 0 ? void 0 : _a.items) || []); _i < _18.length; _i++) {
            var item = _18[_i];
            this.totalSent += item.stats.emailSent || 0;
            this.totalClicked += item.stats.emailClicked || 0;
            this.totalSubscriberCount += item.stats.subscriberCount || 0;
            this.totalOpened += item.stats.emailOpened || 0;
            this.totalOrderSummary += item.stats.orderSummary || 0;
            sPEmailSent[0] += ((((_c = (_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.emailSent) === null || _c === void 0 ? void 0 : _c.from) || 0) - (((_e = (_d = item === null || item === void 0 ? void 0 : item.data) === null || _d === void 0 ? void 0 : _d.emailSent) === null || _e === void 0 ? void 0 : _e.first) || 0));
            sPEmailSent[1] += ((((_g = (_f = item === null || item === void 0 ? void 0 : item.data) === null || _f === void 0 ? void 0 : _f.emailSent) === null || _g === void 0 ? void 0 : _g.to) || 0) - (((_j = (_h = item === null || item === void 0 ? void 0 : item.data) === null || _h === void 0 ? void 0 : _h.emailSent) === null || _j === void 0 ? void 0 : _j.from) || 0));
            sPEmailClicked[0] += ((((_l = (_k = item === null || item === void 0 ? void 0 : item.data) === null || _k === void 0 ? void 0 : _k.emailClicked) === null || _l === void 0 ? void 0 : _l.from) || 0) - (((_o = (_m = item === null || item === void 0 ? void 0 : item.data) === null || _m === void 0 ? void 0 : _m.emailClicked) === null || _o === void 0 ? void 0 : _o.first) || 0));
            sPEmailClicked[1] += ((((_q = (_p = item === null || item === void 0 ? void 0 : item.data) === null || _p === void 0 ? void 0 : _p.emailClicked) === null || _q === void 0 ? void 0 : _q.to) || 0) - (((_s = (_r = item === null || item === void 0 ? void 0 : item.data) === null || _r === void 0 ? void 0 : _r.emailClicked) === null || _s === void 0 ? void 0 : _s.from) || 0));
            sPEmailOpened[0] += ((((_u = (_t = item === null || item === void 0 ? void 0 : item.data) === null || _t === void 0 ? void 0 : _t.emailOpened) === null || _u === void 0 ? void 0 : _u.from) || 0) - (((_w = (_v = item === null || item === void 0 ? void 0 : item.data) === null || _v === void 0 ? void 0 : _v.emailOpened) === null || _w === void 0 ? void 0 : _w.first) || 0));
            sPEmailOpened[1] += ((((_y = (_x = item === null || item === void 0 ? void 0 : item.data) === null || _x === void 0 ? void 0 : _x.emailOpened) === null || _y === void 0 ? void 0 : _y.to) || 0) - (((_0 = (_z = item === null || item === void 0 ? void 0 : item.data) === null || _z === void 0 ? void 0 : _z.emailOpened) === null || _0 === void 0 ? void 0 : _0.from) || 0));
            sPOrderSummary[0] += ((((_2 = (_1 = item === null || item === void 0 ? void 0 : item.data) === null || _1 === void 0 ? void 0 : _1.orderSummary) === null || _2 === void 0 ? void 0 : _2.from) || 0) - (((_4 = (_3 = item === null || item === void 0 ? void 0 : item.data) === null || _3 === void 0 ? void 0 : _3.orderSummary) === null || _4 === void 0 ? void 0 : _4.first) || 0));
            sPOrderSummary[1] += ((((_6 = (_5 = item === null || item === void 0 ? void 0 : item.data) === null || _5 === void 0 ? void 0 : _5.orderSummary) === null || _6 === void 0 ? void 0 : _6.to) || 0) - (((_8 = (_7 = item === null || item === void 0 ? void 0 : item.data) === null || _7 === void 0 ? void 0 : _7.orderSummary) === null || _8 === void 0 ? void 0 : _8.from) || 0));
            sPSubscriberCount[0] += ((((_10 = (_9 = item === null || item === void 0 ? void 0 : item.data) === null || _9 === void 0 ? void 0 : _9.subscriberCount) === null || _10 === void 0 ? void 0 : _10.from) || 0) - (((_12 = (_11 = item === null || item === void 0 ? void 0 : item.data) === null || _11 === void 0 ? void 0 : _11.subscriberCount) === null || _12 === void 0 ? void 0 : _12.first) || 0));
            sPSubscriberCount[1] += ((((_14 = (_13 = item === null || item === void 0 ? void 0 : item.data) === null || _13 === void 0 ? void 0 : _13.subscriberCount) === null || _14 === void 0 ? void 0 : _14.to) || 0) - (((_16 = (_15 = item === null || item === void 0 ? void 0 : item.data) === null || _15 === void 0 ? void 0 : _15.subscriberCount) === null || _16 === void 0 ? void 0 : _16.from) || 0));
        }
        this.totalSentP = this.calcDiffPercentage(sPEmailSent[0], sPEmailSent[1]);
        this.totalClickedP = this.calcDiffPercentage(sPEmailClicked[0], sPEmailClicked[1]);
        this.totalOpenedP = this.calcDiffPercentage(sPEmailOpened[0], sPEmailOpened[1]);
        this.totalSubscriberCountP = this.calcDiffPercentage(sPSubscriberCount[0], sPSubscriberCount[1]);
        this.totalOrderSummaryP = this.calcDiffPercentage(sPOrderSummary[0], sPOrderSummary[1]);
        this.rawItems = ((_17 = ctx.state) === null || _17 === void 0 ? void 0 : _17.items) || [];
        return [
            React.createElement(semantic_ui_react_1.Grid, { key: 0 },
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 5, className: 'display-flex mb-6', stretched: true },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                            React.createElement("h5", { className: "flex-fill mb-2" }, "Egyedi lista b\u0151v\u00FCl\u00E9s"),
                            React.createElement("h3", null,
                                number_parser_1.splitToNumberString(this.totalSubscriberCount),
                                " db"),
                            React.createElement("h3", null, this.toPercentage(this.totalSubscriberCountP)))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                            React.createElement("h5", { className: "flex-fill mb-2" }, "K\u00FCld\u00E9si r\u00E1ta"),
                            React.createElement("h3", { className: 'mr-4' },
                                number_parser_1.splitToNumberString(this.totalSent),
                                " db"),
                            React.createElement("h3", null, this.toPercentage(this.totalSentP)))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                            React.createElement("h5", { className: "flex-fill mb-2" }, "Kattint\u00E1si r\u00E1ta"),
                            React.createElement("h3", { className: 'mr-4' },
                                number_parser_1.splitToNumberString(this.totalClicked),
                                " db"),
                            React.createElement("h3", null, this.toPercentage(this.totalClickedP)))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                            React.createElement("h5", { className: "flex-fill mb-2" }, "Megnyit\u00E1si r\u00E1ta"),
                            React.createElement("h3", { className: 'mr-4' },
                                number_parser_1.splitToNumberString(this.totalOpened),
                                " db"),
                            React.createElement("h3", null, this.toPercentage(this.totalOpenedP)))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                            React.createElement("h5", { className: "flex-fill mb-2" }, "M.I-hez k\u00F6thet\u0151"),
                            React.createElement("h3", { className: 'mr-4' }, currency_parser_1.hufFormat(this.totalOrderSummary)),
                            React.createElement("h3", null, this.toPercentage(this.totalOrderSummaryP)))))),
            React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true, key: 1 },
                React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Web\u00E1ruh\u00E1z neve"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Egyedi lista b\u0151v\u00FCl\u00E9s"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Els\u0151 OptiMonk"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "K\u00FCld\u00E9si r\u00E1ta"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kattint\u00E1si r\u00E1ta"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Megnyit\u00E1si r\u00E1ta"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Gener\u00E1lt bev\u00E9tel"))),
                React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }))
        ];
    };
    IntegrationStatScreen.prototype.renderItem = function (ctx, item, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        var sendingRate = ((((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.emailOpened) || 0) / ((_b = item === null || item === void 0 ? void 0 : item.stats) === null || _b === void 0 ? void 0 : _b.subscriberCount));
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item._id + index, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true }, item.shop.title),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                number_parser_1.splitToNumberString((_c = item.stats) === null || _c === void 0 ? void 0 : _c.subscriberCount, 0, 'db'),
                " | ",
                this.toPercentage((_d = item.statsPercentage) === null || _d === void 0 ? void 0 : _d.subscriberCount),
                " (",
                number_parser_1.splitToNumberString((_e = item.actual) === null || _e === void 0 ? void 0 : _e.subscriberCount, 0, 'db'),
                ") "),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true }, ((_f = item === null || item === void 0 ? void 0 : item.stats) === null || _f === void 0 ? void 0 : _f.firstOptimonk) ? ((_g = item === null || item === void 0 ? void 0 : item.stats) === null || _g === void 0 ? void 0 : _g.firstOptimonk) + " nap" : '-'),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                number_parser_1.splitToNumberString((_h = item.stats) === null || _h === void 0 ? void 0 : _h.emailSent, 0, 'db'),
                " | ",
                this.toPercentage((_j = item.statsPercentage) === null || _j === void 0 ? void 0 : _j.emailSent),
                " (",
                number_parser_1.splitToNumberString((_k = item.actual) === null || _k === void 0 ? void 0 : _k.emailSent, 0, 'db'),
                ") "),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                number_parser_1.splitToNumberString((_l = item.stats) === null || _l === void 0 ? void 0 : _l.emailClicked, 0, 'db'),
                " | ",
                this.toPercentage((_m = item.statsPercentage) === null || _m === void 0 ? void 0 : _m.emailClicked),
                " (",
                number_parser_1.splitToNumberString((_o = item.actual) === null || _o === void 0 ? void 0 : _o.emailClicked, 0, 'db'),
                ") "),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                number_parser_1.splitToNumberString((_p = item.stats) === null || _p === void 0 ? void 0 : _p.emailOpened, 0, 'db'),
                " | ",
                this.toPercentage((_q = item.statsPercentage) === null || _q === void 0 ? void 0 : _q.emailOpened),
                " (",
                number_parser_1.splitToNumberString((_r = item.actual) === null || _r === void 0 ? void 0 : _r.emailOpened, 0, 'db'),
                ") "),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                number_parser_1.splitToNumberString((_s = item.stats) === null || _s === void 0 ? void 0 : _s.orderSummary, 0, 'Ft'),
                " | ",
                this.toPercentage((_t = item.statsPercentage) === null || _t === void 0 ? void 0 : _t.orderSummary),
                " (",
                number_parser_1.splitToNumberString((_u = item.actual) === null || _u === void 0 ? void 0 : _u.orderSummary, 0, 'Ft'),
                ") "));
    };
    IntegrationStatScreen.prototype.downloadCsv = function (items) {
        var rows = __spreadArrays([
            [
                'Név',
                'OptiMonk (nap)',
                'Egyedi lista bővülés (db)',
                'Egyedi lista bővülés (%)',
                'Kattintási ráta (db)',
                'Kattintási ráta (%)',
                'Megnyitási ráta (db)',
                'Megnyitási ráta (%)',
                'Generált bevétel (Mennyiség)',
                'Generált bevétel (%)'
            ]
        ], items.map(function (i) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            return ([
                (_a = i.shop) === null || _a === void 0 ? void 0 : _a.title,
                ((_b = i === null || i === void 0 ? void 0 : i.stats) === null || _b === void 0 ? void 0 : _b.firstOptimonk) || '-',
                (_c = i === null || i === void 0 ? void 0 : i.stats) === null || _c === void 0 ? void 0 : _c.emailSent,
                (_d = i === null || i === void 0 ? void 0 : i.statsPercentage) === null || _d === void 0 ? void 0 : _d.emailSent,
                (_e = i === null || i === void 0 ? void 0 : i.stats) === null || _e === void 0 ? void 0 : _e.emailClicked,
                (_f = i === null || i === void 0 ? void 0 : i.statsPercentage) === null || _f === void 0 ? void 0 : _f.emailClicked,
                (_g = i === null || i === void 0 ? void 0 : i.stats) === null || _g === void 0 ? void 0 : _g.emailOpened,
                (_h = i === null || i === void 0 ? void 0 : i.statsPercentage) === null || _h === void 0 ? void 0 : _h.emailOpened,
                (_j = i === null || i === void 0 ? void 0 : i.stats) === null || _j === void 0 ? void 0 : _j.orderSummary,
                (_k = i === null || i === void 0 ? void 0 : i.statsPercentage) === null || _k === void 0 ? void 0 : _k.orderSummary,
            ]);
        }));
        var csvContent = "data:text/csv;charset=utf-8, " + rows.map(function (e) { return e.join(','); }).join('\n');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', "elszamolas_" + moment(this.form.fields.from.getValue()).format('YYYY-MM-DD') + "_" + moment(this.form.fields.to.getValue()).format('YYYY-MM-DD'));
        document.body.appendChild(link);
        link.click();
    };
    IntegrationStatScreen.prototype.toPercentage = function (value) {
        if (!Number.isFinite(value)) {
            return '- %';
        }
        return Math.round(value * 10000) / 100 + "%";
    };
    IntegrationStatScreen.prototype.calcDiffPercentage = function (a, b) {
        var factor = a > b
            ? -1
            : 1;
        var p1 = (b / a);
        return (1 - p1) * factor;
    };
    return IntegrationStatScreen;
}(abstract_component_1.AbstractComponent));
exports.IntegrationStatScreen = IntegrationStatScreen;
