"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SensitiveData = void 0;
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var SensitiveData = (function () {
    function SensitiveData() {
    }
    SensitiveData.setPresentationMode = function (state) {
        local_store_1.LocalStore.setItem('PRESENTATION_MODE', state);
    };
    SensitiveData.isPresentationMode = function () {
        return local_store_1.LocalStore.getItem('PRESENTATION_MODE');
    };
    SensitiveData.customerEmail = function (email) {
        var isPresentation = local_store_1.LocalStore.getItem('PRESENTATION_MODE');
        if (!!isPresentation) {
            var address = email.split('@')[1];
            var domain = email.split('@')[1].split('.')[0];
            var tld = email.split('@')[1].split('.')[1];
            return address.replace(/./g, '#') + "@" + domain.replace(/./g, '#') + "." + tld;
        }
        return email;
    };
    SensitiveData.customerName = function (name) {
        var isPresentation = local_store_1.LocalStore.getItem('PRESENTATION_MODE');
        return name;
    };
    return SensitiveData;
}());
exports.SensitiveData = SensitiveData;
