module.exports = {
    'event-types.view-category': 'Kategóriát megtekintette ',
    'event-types.category-view': 'Kategóriát megtekintette ',
    'event-types.view-product': 'Terméket megtekintette ',
    'event-types.product-view': 'Terméket megtekintette ',
    'event-types.subscribe': 'Feliratkozott ',
    'event-types.unsubscribe': 'Leiratkozott ',
    'event-types.page-view': 'Oldalt megtekintette ',
    'event-types.view-page': 'Oldalt megtekintette ',
    'event-types.email': 'Emailben kattintott ',
    'event-types.email-opened': 'Emailt megnyitott',
    'event-types.email-sent': 'Kiküldött email ',
    'event-types.email-queued': 'Ütemezett email ',
    'event-types.add-to-cart': 'Kosárba tette ',
    'event-types.cart-change': 'Kosár tartalma változott ',
    'event-types.order': 'Megrendelést adott le',
    'event-types.remove-from-cart': 'Kosárból eltávolította ',
    'event-types.login': 'Bejelentkezett ',
    'event-types.logout': 'Kijelentkezett ',
    'event-types.email-sent-permanent-failed': 'Sikertelen küldés',
    'event-types.email-sent-temporary-failed': 'Sikertelen küldés',
};
