"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BouncedEmailsComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var state_repository_1 = require("../../../common/libs/state-repository");
var BouncedEmailsComponent = (function (_super) {
    __extends(BouncedEmailsComponent, _super);
    function BouncedEmailsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.integration = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'integration');
        _this.failedEvents = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'failedEvents');
        _this.unsubscribeGroups = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'unsubscribeGroups');
        _this.state = {
            shop: _this.props.shop,
            integration: null,
            currentUnsubscribeProcessCode: null
        };
        return _this;
    }
    BouncedEmailsComponent.prototype.componentDidMount = function () {
        this.fetch();
    };
    BouncedEmailsComponent.prototype.fetch = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.failedEvents.get("/s/" + this.state.shop._id + "/event/failed-events")];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    BouncedEmailsComponent.prototype.render = function () {
        return React.createElement("div", { className: 'ManagersComponent ListComponent' },
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Visszapattan\u00F3 levelek"))),
                React.createElement(semantic_ui_react_1.Grid.Row, null, this.renderList())));
    };
    BouncedEmailsComponent.prototype.renderList = function () {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Mailgun Hibak\u00F3d"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Mailgun Hiba\u00FCzenet (domin\u00E1ns)"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Esem\u00E9nyek sz\u00E1ma"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Customerek sz\u00E1ma"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2 }, this.renderItems()));
    };
    BouncedEmailsComponent.prototype.renderItems = function () {
        var _this = this;
        var _a, _b;
        if ((_a = this.state.failedEvents) === null || _a === void 0 ? void 0 : _a.loading) {
            return [].map(function (i) { return _this.renderItem(i); });
        }
        var items = (((_b = this.state.failedEvents) === null || _b === void 0 ? void 0 : _b.response) || []);
        return items.length
            ? items === null || items === void 0 ? void 0 : items.map(function (i) { return _this.renderItem(i); }) : this.renderEmptyItems();
    };
    BouncedEmailsComponent.prototype.renderEmptyItems = function () {
        return React.createElement(semantic_ui_react_1.Table.Row, { className: 'cursor-pointer', key: 0, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 4 }, "Nincsnek visszapattan\u00F3 emailek"));
    };
    BouncedEmailsComponent.prototype.renderItem = function (item) {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(semantic_ui_react_1.Table.Row, { className: 'cursor-pointer', key: (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a._id, verticalAlign: "middle", warning: ((item === null || item === void 0 ? void 0 : item.type) === 'email-sent-temporary-failed'), error: ((item === null || item === void 0 ? void 0 : item.type) === 'email-sent-permanent-failed') },
            React.createElement(semantic_ui_react_1.Table.Cell, null, item === null || item === void 0 ? void 0 : item.code),
            React.createElement(semantic_ui_react_1.Table.Cell, null, (item === null || item === void 0 ? void 0 : item.sampleMessage) || '-'),
            React.createElement(semantic_ui_react_1.Table.Cell, null, item === null || item === void 0 ? void 0 : item.totalEvents),
            React.createElement(semantic_ui_react_1.Table.Cell, null, item === null || item === void 0 ? void 0 : item.customerCounts),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement(semantic_ui_react_1.Button, { disabled: ((_b = this.state.unsubscribeGroups) === null || _b === void 0 ? void 0 : _b.loading) && (this.state.currentUnsubscribeProcessCode !== (item === null || item === void 0 ? void 0 : item.code)), loading: ((_c = this.state.unsubscribeGroups) === null || _c === void 0 ? void 0 : _c.loading) && (this.state.currentUnsubscribeProcessCode === (item === null || item === void 0 ? void 0 : item.code)), onClick: function () { return _this.unsubscribe(item); } }, "Leiratkoztat\u00E1s")));
    };
    BouncedEmailsComponent.prototype.unsubscribe = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4, this.unsubscribeGroups.post("/s/" + this.state.shop._id + "/event/unsubscribed-failed-events", {
                                data: {
                                    type: item === null || item === void 0 ? void 0 : item.type,
                                    mailgunCode: item === null || item === void 0 ? void 0 : item.code
                                }
                            })];
                    case 1:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres leiratkoztatás');
                        return [4, this.fetch()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        handle_toasts_1.HandleToasts.error(e_1, e_1.message);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return BouncedEmailsComponent;
}(abstract_component_1.AbstractComponent));
exports.BouncedEmailsComponent = BouncedEmailsComponent;
