"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isContentVariableEscapable = exports.contentVariables = void 0;
exports.contentVariables = [
    {
        key: 'Felhasználó utóneve',
        description: 'Annak a felhasználónak az utóneve, aki az emailt fogadja',
        valueKey: 'Context_User_FirstName',
        value: '{{ Context_User_FirstName }}',
        escapable: false
    },
    {
        key: 'Felhasználó vezetékneve',
        description: 'Annak a felhasználónak a vezetékneve, aki az emailt fogadja',
        valueKey: 'Context_User_LastName',
        value: '{{ Context_User_LastName }}',
        escapable: false
    },
    {
        key: 'Felhasználó email',
        description: 'Annak a felhasználónak az emailcíme, aki az emailt fogadja',
        valueKey: 'Context_User_Email',
        value: '{{ Context_User_Email }}',
        escapable: false
    },
    {
        key: 'Felhasználó utolsó megrendelésének a dátuma',
        description: 'Utolsó megrendelés dátuma. Formátuma: YYYY-MM-DD HH:mm:ss',
        valueKey: 'Context_User_LastOrderDate',
        value: '{{ Context_User_LastOrderDate }}',
        escapable: false
    },
    {
        key: 'Felhasználó feliratkozásának a dátuma',
        description: 'Feliratkozás dátuma: Formátuma: YYYY-MM-DD HH:mm:ss',
        valueKey: 'Context_User_SubscribedAt',
        value: '{{ Context_User_SubscribedAt }}',
        escapable: false
    },
    {
        key: 'Felhasználó leiratkozásának a dátuma',
        description: 'Leiratkozás dátuma: Formátuma: YYYY-MM-DD HH:mm:ss',
        valueKey: 'Context_User_UnsubscribedAt',
        value: '{{ Context_User_UnsubscribedAt }}',
        escapable: false
    },
    {
        key: 'Webáruház típusa',
        description: 'Az integráció típusával egyenértékű. Például: WooCommerce, Shoprenter, Unas, Custom',
        valueKey: 'Context_Shop_Type',
        value: '{{ Context_Shop_Type }}',
        escapable: false
    },
    {
        key: 'Webáruház neve',
        description: 'Webáruház megnevezése: Példa Webáruház',
        valueKey: 'Context_Shop_Name',
        value: '{{ Context_Shop_Name }}',
        escapable: false
    },
    {
        key: 'Webáruház url címe',
        description: 'Webáruház RFC 1738 formátumú címe',
        valueKey: 'Context_Shop_Url',
        value: '{{ Context_Shop_Url }}',
        escapable: false
    },
    {
        key: 'Webáruház logó url',
        description: 'Webáruház Logójának RFC 1738 formátumú címe',
        valueKey: 'Context_Shop_Logo',
        value: '{{ Context_Shop_Logo }}',
        escapable: false
    },
    {
        key: 'Webáruház téma színe',
        description: 'Webáruház hexadecimális formátumú színkódja',
        valueKey: 'Context_Shop_ThemeColor',
        value: '{{ Context_Shop_ThemeColor }}',
        escapable: false
    },
    {
        key: 'Webűruház számlázási adatai',
        description: 'Webáruház számlázási adatai objektum formátumban. Használati példa: {{ Context_Shop_BillingData.country }}',
        valueKey: 'Context_Shop_BillingData',
        value: '{{ Context_Shop_BillingData }}',
        escapable: false
    },
    {
        key: 'Leiratkozás link',
        description: 'Kiküldéskor generált RFC 1738 formátumú leiratkozási link. Minden levélbe jelen kell hogy legyen!',
        valueKey: 'Context_UnsubscribeLink',
        value: '{{ Context_UnsubscribeLink }}',
        escapable: false
    },
    {
        key: 'Üdvözlés',
        valueKey: 'Variable_Welcome',
        value: '{{ Variable_Welcome }}',
        escapable: false
    },
    {
        key: '1. szöveges blokk',
        valueKey: 'Variable_Text1',
        value: '{{ Variable_Text1 }}',
        escapable: true
    },
    {
        key: '2. szöveges blokk',
        valueKey: 'Variable_Text2',
        value: '{{ Variable_Text2 }}',
        escapable: true
    },
    {
        key: '3. szöveges blokk',
        valueKey: 'Variable_Text3',
        value: '{{ Variable_Text3 }}',
        escapable: true
    },
    {
        key: '4. szöveges blokk',
        valueKey: 'Variable_Text4',
        value: '{{ Variable_Text4 }}',
        escapable: true
    },
    {
        key: '5. szöveges blokk',
        valueKey: 'Variable_Text5',
        value: '{{ Variable_Text5 }}',
        escapable: true
    }
];
var otherEscapableVariables = [
    'Variable_FooterDescription'
];
var isContentVariableEscapable = function (valueKey) {
    var _a;
    var isTextVariable = /^Variable_Text_/.test(valueKey);
    if (isTextVariable) {
        return true;
    }
    if (otherEscapableVariables.includes(valueKey)) {
        return true;
    }
    return (_a = exports.contentVariables.find(function (item) { return item.valueKey === valueKey; })) === null || _a === void 0 ? void 0 : _a.escapable;
};
exports.isContentVariableEscapable = isContentVariableEscapable;
