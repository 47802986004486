"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuFooterComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./menu.component.scss");
var session_storage_1 = require("../../../../libs/session.storage");
var MenuFooterComponent = (function (_super) {
    __extends(MenuFooterComponent, _super);
    function MenuFooterComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.user = local_store_1.LocalStore.getItem('User');
        return _this;
    }
    MenuFooterComponent.prototype.render = function () {
        return React.createElement("div", { className: 'MenuFooterComponent' },
            React.createElement("div", null, this.renderUser()),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { basic: true, disabled: this.state.isMenuDisabledForShoprenterShop, icon: 'sliders horizontal', onClick: function () { return router_provider_1.RouterProvider.goTo('/settings'); } })));
    };
    MenuFooterComponent.prototype.componentDidMount = function () {
        var _a;
        _super.prototype.componentDidMount.call(this);
        this.user = local_store_1.LocalStore.getItem('User');
        var currentShoprenterData = session_storage_1.SessionStorage.getItem(session_storage_1.SESSION_STORAGE_KEYS.SESSION_STORAGE_SHOPRENTER_DATA_KEY);
        if (!currentShoprenterData) {
            this.setState({ isMenuDisabledForShoprenterShop: false });
        }
        else {
            this.setState({ isMenuDisabledForShoprenterShop: !((_a = currentShoprenterData === null || currentShoprenterData === void 0 ? void 0 : currentShoprenterData.dashboard) === null || _a === void 0 ? void 0 : _a.autoMailUpAndRunning) || false });
        }
    };
    MenuFooterComponent.prototype.renderUser = function () {
        var _a, _b;
        if (!this.user) {
            return;
        }
        return React.createElement("div", { className: 'User display-flex align-items-center' },
            this.renderAvatar(),
            React.createElement("span", null,
                trans_1.trans('menu.footer.user.greetings'),
                ", ",
                React.createElement("strong", null, (_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.firstName)));
    };
    MenuFooterComponent.prototype.renderAvatar = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var monogram = "" + ((_c = (_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.lastName) === null || _c === void 0 ? void 0 : _c[0]) + ((_f = (_e = (_d = this.user) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.firstName) === null || _f === void 0 ? void 0 : _f[0]);
        if (!((_h = (_g = this.user) === null || _g === void 0 ? void 0 : _g.meta) === null || _h === void 0 ? void 0 : _h.firstName) || !((_k = (_j = this.user) === null || _j === void 0 ? void 0 : _j.meta) === null || _k === void 0 ? void 0 : _k.lastName)) {
            monogram = 'SU';
        }
        return React.createElement("div", { className: 'Avatar' },
            React.createElement("span", null, monogram));
    };
    return MenuFooterComponent;
}(responsive_component_1.ResponsiveComponent));
exports.MenuFooterComponent = MenuFooterComponent;
