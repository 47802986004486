"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingHistoryComponent = void 0;
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var BillingHistoryComponent = (function (_super) {
    __extends(BillingHistoryComponent, _super);
    function BillingHistoryComponent() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var _this = _super.apply(this, arguments) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {}
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._limit) !== null && _c !== void 0 ? _c : 0,
                _page: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e._page) !== null && _f !== void 0 ? _f : 0,
                query: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.query.query) !== null && _h !== void 0 ? _h : ''
            }
        });
        _this.state = {
            shop: _this.props.shop,
            updateLoading: false,
            updateError: false,
            loading: false
        };
        return _this;
    }
    BillingHistoryComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _this = this;
        if (!lodash_1.isEqual(this.props.shop, prevProps.shop)) {
            this.setState({ shop: this.props.shop }, function () { return _this.listRef$.reload(); });
        }
    };
    BillingHistoryComponent.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: "SettingsListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, null,
                React.createElement("div", { className: 'ListComponent' },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3' }, "Sz\u00E1mla t\u00F6rt\u00E9net")),
                            React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'right' }, this.renderListHeader()))),
                    React.createElement(repository_list_1.RepositoryList, { ref: function (ref) { return _this.listRef$ = ref; }, control: this.control, hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/a/shop/" + ((_a = this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/billing-history", renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 4 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 4 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }),
                    React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                            { text: 1, value: 1 },
                            { text: 10, value: 10 },
                            { text: 25, value: 25 },
                            { text: 50, value: 50 },
                            { text: 100, value: 100 },
                        ] }))));
    };
    BillingHistoryComponent.prototype.renderListHeader = function () {
        return React.createElement("div", null);
    };
    BillingHistoryComponent.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Status")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Sz\u00E1mla sz\u00E1ma")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Date")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Sz\u00E1mla")))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    BillingHistoryComponent.prototype.renderItem = function (ctx, item, index) {
        var _a, _b, _c;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item._id + index, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, null, item.status === 'success' ? React.createElement(semantic_ui_react_1.Icon, { name: 'check circle outline', color: 'green' }) :
                React.createElement(semantic_ui_react_1.Icon, { name: 'times circle outline', color: 'red' })),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.miniCrmResponse) === null || _a === void 0 ? void 0 : _a.Number)),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("span", null, moment(item.date).format('YYYY.MM.DD. HH:mm'))),
            React.createElement(semantic_ui_react_1.Table.Cell, null, ((_b = item === null || item === void 0 ? void 0 : item.miniCrmResponse) === null || _b === void 0 ? void 0 : _b.DocumentUrl) &&
                React.createElement("a", { target: '_blank', href: (_c = item === null || item === void 0 ? void 0 : item.miniCrmResponse) === null || _c === void 0 ? void 0 : _c.DocumentUrl }, "Let\u00F6lt\u00E9s")));
    };
    return BillingHistoryComponent;
}(abstract_component_1.AbstractComponent));
exports.BillingHistoryComponent = BillingHistoryComponent;
