"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardHeaderComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var DashboardHeaderComponent = (function (_super) {
    __extends(DashboardHeaderComponent, _super);
    function DashboardHeaderComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            shop: _this.getCurrentShop()
        };
        return _this;
    }
    DashboardHeaderComponent.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f;
        return React.createElement("div", { className: 'DashboardHeader display-flex flex-column align-items-center' },
            React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Ir\u00E1ny\u00EDt\u00F3pult"),
            ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.logo) ? React.createElement(semantic_ui_react_1.Image, { src: (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.shop) === null || _d === void 0 ? void 0 : _d.logo })
                : React.createElement(semantic_ui_react_1.Header, { as: 'h3' }, (_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.shop) === null || _f === void 0 ? void 0 : _f.title));
    };
    DashboardHeaderComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return DashboardHeaderComponent;
}(responsive_component_1.ResponsiveComponent));
exports.DashboardHeaderComponent = DashboardHeaderComponent;
