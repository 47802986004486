"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceSelector = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var category_selector_component_1 = require("../../../custom-newsletter/components/category-selector.component");
var state_repository_1 = require("../../../common/libs/state-repository");
var lodash_1 = require("lodash");
var SourceSelector = (function (_super) {
    __extends(SourceSelector, _super);
    function SourceSelector() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        _this.state = {
            products: [],
            isConfirmModalOpen: false,
            newFilter: '',
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                min: new field_1.Field({
                    name: 'min',
                    placeholder: 'Minimum',
                    label: 'Minimum',
                    value: _this.props.source.min
                }),
                max: new field_1.Field({
                    name: 'max',
                    placeholder: 'Maximum',
                    label: 'Maximum',
                    value: _this.props.source.max
                }),
                sort: new field_1.Field({
                    name: 'Sort',
                    placeholder: 'Rendezés',
                    label: 'Termékek rendezése',
                    value: _this.props.source.sort,
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: '/a/template/sorts',
                    optionsMap: function (items) { return items.map(function (item) { return ({
                        key: item.key,
                        text: item.title,
                        value: item.key
                    }); }); },
                    loadOptionsAfterMount: true
                }),
                filter: new field_1.Field({
                    name: 'filter',
                    placeholder: 'Termékek szűkítése',
                    label: 'Termékek szűkítése',
                    value: _this.getFilterValue(),
                    options: [
                        {
                            key: 1,
                            text: 'Minden termék',
                            value: 'all',
                            onClick: function (e, data) { return _this.onFilterChangeModal(data === null || data === void 0 ? void 0 : data.value); }
                        },
                        {
                            key: 2,
                            text: 'Szűkítés csak kategóriára',
                            value: 'category',
                            onClick: function (e, data) { return _this.onFilterChangeModal(data === null || data === void 0 ? void 0 : data.value); }
                        },
                        {
                            key: 3,
                            text: 'Szűkítés csak termékre',
                            value: 'product',
                            onClick: function (e, data) { return _this.onFilterChangeModal(data.value); }
                        },
                    ],
                    loadOptionsAfterMount: true
                }),
                category: new field_1.Field({
                    name: 'category',
                    label: 'Engedélyezett kategóriák',
                    value: _this.props.source.category,
                }),
                product: new field_1.Field({
                    name: 'Products',
                    value: _this.props.source.product,
                }),
                productSearch: new field_1.Field({
                    name: 'productSearch',
                    label: 'Termék kereső',
                    placeholder: 'Termék kereső',
                    value: null,
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: "/s/" + _this.props.enabledFilterIntegration + "/data/temporary/product?advertisable=1",
                    optionsMap: function (response) { var _a, _b; return (_b = (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a, _b, _c, _d, _e; return !((_d = (_c = (_b = (_a = _this.form) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.product) === null || _c === void 0 ? void 0 : _c.getValue()) === null || _d === void 0 ? void 0 : _d.includes((_e = item === null || item === void 0 ? void 0 : item.data) === null || _e === void 0 ? void 0 : _e.identifier)); })) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                        var _a, _b;
                        return ({
                            key: item.identifier,
                            text: ((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.name) + " (" + ((_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.sku) + ")",
                            value: item
                        });
                    }); },
                    loadOptionsAfterMount: !!_this.props.enableFilters
                }),
            }
        });
        return _this;
    }
    SourceSelector.prototype.getFilterValue = function () {
        var _a, _b;
        if (!!((_a = this.props.source.category) === null || _a === void 0 ? void 0 : _a.length)) {
            return 'category';
        }
        if (!!((_b = this.props.source.product) === null || _b === void 0 ? void 0 : _b.length)) {
            return 'product';
        }
        return 'all';
    };
    SourceSelector.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.handleProductListChange()];
                    case 1:
                        _a.sent();
                        this.$subscriptions.push(this.form.$value.subscribe(function () { return _this.props.onUpdate(_this.getPayload()); }), this.form.fields.filter.$value.subscribe(function (value) { return _this.handleFilterChange(value); }), this.form.fields.product.$value.subscribe(function () { return _this.handleProductListChange(); }));
                        return [2];
                }
            });
        });
    };
    SourceSelector.prototype.handleFilterChange = function (value) {
        if (value === 'all') {
            this.form.fields.category.setValue([]);
            this.form.fields.product.setValue([]);
        }
        if (value === 'category') {
            this.form.fields.product.setValue([]);
        }
        if (value === 'product') {
            this.form.fields.category.setValue([]);
        }
    };
    SourceSelector.prototype.handleProductListChange = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var values, _c, params, response;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _c = lodash_1.clone;
                        return [4, ((_b = (_a = this.form.fields) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.getValue())];
                    case 1:
                        values = _c.apply(void 0, [_d.sent()]);
                        if (!!Array.isArray(values)) return [3, 3];
                        return [4, this.setState({ products: [] })];
                    case 2:
                        _d.sent();
                        return [2];
                    case 3:
                        if (!!(values === null || values === void 0 ? void 0 : values.length)) return [3, 5];
                        return [4, this.setState({ products: [] })];
                    case 4:
                        _d.sent();
                        return [2];
                    case 5:
                        params = {
                            identifiersOnly: lodash_1.uniq(values),
                        };
                        return [4, repository_1.Repository.use('default').get("/s/" + this.props.enabledFilterIntegration + "/data/temporary/product", {
                                params: params,
                            })];
                    case 6:
                        response = _d.sent();
                        return [4, this.setState({ products: response.items })];
                    case 7:
                        _d.sent();
                        return [2];
                }
            });
        });
    };
    SourceSelector.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Segment, { className: 'CustomNewsletterSourcesSegment' },
            React.createElement(semantic_ui_react_1.Header, { content: this.props.originalSource.title, as: "h4", subheader: this.props.originalSource.description }),
            this.props.isSelected && React.createElement(semantic_ui_react_1.Divider, null),
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Column, { mobile: 16, tablet: 8, computer: 8 }, this.props.isSelected && React.createElement(form_control_1.FormControl, { field: this.form.fields.min },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, type: "number" }))),
                React.createElement(semantic_ui_react_1.Grid.Column, { mobile: 16, tablet: 8, computer: 8 }, this.props.isSelected && React.createElement(form_control_1.FormControl, { field: this.form.fields.max },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, type: "number" }))),
                React.createElement(semantic_ui_react_1.Grid.Column, { mobile: 16, tablet: 8, computer: 8 }, this.props.isSelected &&
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.sort, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Select, type: "select" })))),
            this.props.isSelected && this.props.showProductFilter &&
                React.createElement(React.Fragment, null,
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.filter, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Select, selection: true, className: 'mt-4' })),
                    React.createElement(semantic_ui_react_1.Modal, { open: this.state.isConfirmModalOpen, onClose: function () { return _this.setState({ isConfirmModalOpen: false }); }, size: 'tiny' },
                        React.createElement(semantic_ui_react_1.Modal.Content, null, "Ha ezt \u00E1t\u00E1ll\u00EDtod, minden itt v\u00E9gzett m\u00F3dos\u00EDt\u00E1s elv\u00E9sz. Biztos vagy benne?"),
                        React.createElement(semantic_ui_react_1.Modal.Actions, null,
                            React.createElement(semantic_ui_react_1.Button, { color: 'red', onClick: function () { return _this.setState({ isConfirmModalOpen: false }); } },
                                React.createElement(semantic_ui_react_1.Icon, { name: 'remove' }),
                                "M\u00E9gse"),
                            React.createElement(semantic_ui_react_1.Button, { color: 'green', onClick: function () {
                                    _this.form.fields.filter.setValue(_this.state.newFilter);
                                    _this.setState({ isConfirmModalOpen: false });
                                } },
                                React.createElement(semantic_ui_react_1.Icon, { name: 'checkmark' }),
                                "Meger\u0151s\u00EDt\u00E9s")))),
            this.props.isSelected && this.props.showProductFilter && this.renderFilter(this.form.fields.filter.getValue()));
    };
    SourceSelector.prototype.renderFilter = function (value) {
        if (value === 'category') {
            return this.renderCategoryFilter();
        }
        if (value === 'product') {
            return this.renderProductFilter();
        }
        return null;
    };
    SourceSelector.prototype.renderCategoryFilter = function () {
        var _this = this;
        var _a, _b;
        return React.createElement("div", null,
            React.createElement("p", { className: 'small-bold-title' }, "Enged\u00E9lyezett kateg\u00F3ri\u00E1k"),
            React.createElement("p", null, "V\u00E1laszd ki azokat a kateg\u00F3ri\u00E1kat amib\u0151l v\u00E1logathat rendszer\u00FCnk a fenti be\u00E1ll\u00EDt\u00E1sok szerint."),
            React.createElement(category_selector_component_1.CategorySelectorComponent, { shop: this.props.shop, onCategoryChange: function (value) { return _this.setCategories(value); }, selectedCategories: (_b = (_a = this.form.fields) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.getValue() }));
    };
    SourceSelector.prototype.setCategories = function (value) {
        var _a, _b;
        (_b = (_a = this.form.fields) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.setValue(value);
    };
    SourceSelector.prototype.renderProductFilter = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", null,
            React.createElement("p", { className: 'small-bold-title' }, "Enged\u00E9lyezett term\u00E9kek"),
            React.createElement("p", null, "A lenti v\u00E1laszt\u00F3 seg\u00EDts\u00E9g\u00E9vel adj hozz\u00E1 term\u00E9keket amik megjelenhetnek ebben a lev\u00E9lben."),
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 13 },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.productSearch, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Dropdown, fluid: true, multiple: false, selection: true, search: true, clearable: false, onSearchChange: function (e) { var _a; return _this.form.fields.productSearch.loadOptions({ query: (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value }); } }))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 3, className: 'display-flex align-items-end' },
                    React.createElement(semantic_ui_react_1.Button, { type: 'button', icon: 'plus', className: 'w-100', onClick: function () { var _a, _b, _c; return _this.addProductToList((_c = (_b = (_a = _this.form.fields) === null || _a === void 0 ? void 0 : _a.productSearch) === null || _b === void 0 ? void 0 : _b.getValue()) === null || _c === void 0 ? void 0 : _c.identifier); } }))),
            !!((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.length) &&
                React.createElement(semantic_ui_react_1.Table, { basic: 'very' },
                    React.createElement(semantic_ui_react_1.Table.Header, null,
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Cikksz\u00E1m"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Term\u00E9k neve"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                    React.createElement(semantic_ui_react_1.Table.Body, null, (_c = this.state.products) === null || _c === void 0 ? void 0 : _c.map(function (item) { return _this.renderSelectedProduct(item); }))));
    };
    SourceSelector.prototype.renderSelectedProduct = function (item) {
        var _this = this;
        var _a, _b;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item === null || item === void 0 ? void 0 : item.identifier },
            React.createElement(semantic_ui_react_1.Table.Cell, { width: 5 }, (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.sku),
            React.createElement(semantic_ui_react_1.Table.Cell, { width: 10 }, (_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.name),
            React.createElement(semantic_ui_react_1.Table.Cell, { width: 1 },
                React.createElement(semantic_ui_react_1.Icon, { name: 'trash', className: 'cursor-pointer', onClick: function () { return _this.deleteProductFromList(item === null || item === void 0 ? void 0 : item.identifier); } })));
    };
    SourceSelector.prototype.addProductToList = function (item) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var list;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (!item) {
                            return [2];
                        }
                        return [4, ((_b = (_a = this.form.fields) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.getValue())];
                    case 1:
                        list = _g.sent();
                        return [4, ((_d = (_c = this.form.fields) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.setValue(lodash_1.uniq(__spreadArrays([item], list))))];
                    case 2:
                        _g.sent();
                        return [4, ((_f = (_e = this.form.fields) === null || _e === void 0 ? void 0 : _e.productSearch) === null || _f === void 0 ? void 0 : _f.setValue(''))];
                    case 3:
                        _g.sent();
                        return [2];
                }
            });
        });
    };
    SourceSelector.prototype.deleteProductFromList = function (item) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var list, newList;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!item) {
                            return [2, null];
                        }
                        return [4, ((_b = (_a = this.form.fields) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.getValue())];
                    case 1:
                        list = _e.sent();
                        newList = list.filter(function (li) { return (li !== item); });
                        return [4, ((_d = (_c = this.form.fields) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.setValue(newList))];
                    case 2:
                        _e.sent();
                        return [2];
                }
            });
        });
    };
    SourceSelector.prototype.onFilterChangeModal = function (value) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_b = (_a = this.form.fields) === null || _a === void 0 ? void 0 : _a.filter) === null || _b === void 0 ? void 0 : _b.getValue()) === 'all' || (!((_e = (_d = (_c = this.form.fields) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.getValue()) === null || _e === void 0 ? void 0 : _e.length) && !((_h = (_g = (_f = this.form.fields) === null || _f === void 0 ? void 0 : _f.category) === null || _g === void 0 ? void 0 : _g.getValue()) === null || _h === void 0 ? void 0 : _h.length))) {
            return this.form.fields.filter.setValue(value);
        }
        this.setState({ isConfirmModalOpen: true, newFilter: value });
    };
    SourceSelector.prototype.getPayload = function () {
        var min = this.form.fields.min.getValue();
        var max = this.form.fields.max.getValue();
        return __assign(__assign(__assign({}, this.props.source), this.form.toJSON()), { min: parseInt(min, 10), max: parseInt(max, 10) });
    };
    return SourceSelector;
}(abstract_component_1.AbstractComponent));
exports.SourceSelector = SourceSelector;
