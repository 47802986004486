"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var moment = require("moment");
module.exports = {
    'custom-filters-type': {
        FirstNameFilterExpression: 'A felhasználó keresztneve',
        LastNameFilterExpression: 'A felhasználó vezetékneve',
        EmailFilterExpression: 'A felhasználó email címe',
        RegisterDateFilterExpression: 'A regisztráció időpontja',
        SubscribeDateFilterExpression: 'A feliratkozás időpontja',
        UnsubscribeDateFilterExpression: 'A leiratkozás időpontja',
        StatusFilterExpression: 'A felhasználó állapota',
        IncartNameFilterExpression: 'A kosarban lévő termék neve',
        IncartSkuFilterExpression: 'A kosarban lévő termék SKU',
        IncartSumPriceFilterExpression: 'A kosár értéke',
        IncartAmountOfProductFilterExpression: 'A kosárban lévő termékek darabszáma',
        OrderedSkuFilterExpression: 'Megvásárolt termék SKU',
        OrderedProductNameFilterExpression: 'Megvásárolt termék neve',
        OrderedFromCategoryFilterExpression: 'Vásárolt a kategóriából',
        OrderedProductLinkFilterExpression: 'Megvásárolt termék URL tartalmazza',
        OrderedAmountIndividualFilterExpression: 'Az megrendelés összege',
        OrderedAmountSumFilterExpression: 'Az összes megrendelés összege',
        EmailAllFilterExpression: 'A kiküldött emailt',
        EmailAutomaticFilterExpression: 'Az egyedi email',
        ActiveCustomerFilterExpression: 'Az aktiv felhasználó aktivitásának dátuma',
        ViewedProductNameFilterExpression: 'Megtekintett termék neve',
        ViewedProductSkuFilterExpression: 'Megtekintett termék SKU',
        ViewedProductLinkFilterExpression: 'Megtekintett URL tartalmazza',
        ViewedCategoryNameFilterExpression: 'Megtekintett kategória neve'
    },
    'custom-filters-operator': {
        received: function (value) { return 'amit megkapott'; },
        notReceived: function (value) { return 'amit NEM megkapott'; },
        opened: function (value) { return 'amit megnyitott'; },
        notOpened: function (value) { return 'amit NEM nyitott meg'; },
        openedBefore: function (value) { return "megnyitotta \"" + trans_1.trans(value) + "\" el\u0151tt"; },
        openedAfter: function (value) { return "megnyitotta \"" + trans_1.trans(value) + "\" ut\u00E1n"; },
        openedAtDay: function (value) { return "megnyitotta \"" + trans_1.trans(value) + "\" napon"; },
        openedBetween: function (value) { return "megnyitotta \"" + trans_1.trans((value === null || value === void 0 ? void 0 : value.from) || '...') + "\" \u00E9s \"" + trans_1.trans((value === null || value === void 0 ? void 0 : value.to) || '...') + "\" k\u00F6z\u00F6tt"; },
        receivedBefore: function (value) { return "kapott \"" + trans_1.trans(value) + "\" el\u0151tt"; },
        receivedAfter: function (value) { return "kapott \"" + trans_1.trans(value) + "\" ut\u00E1n"; },
        receivedAtDay: function (value) { return "kapott \"" + trans_1.trans(value) + "\" napon"; },
        receivedBetween: function (value) { return "kapott \"" + trans_1.trans((value === null || value === void 0 ? void 0 : value.from) || '...') + "\" \u00E9s \"" + trans_1.trans((value === null || value === void 0 ? void 0 : value.to) || '...') + "\" k\u00F6z\u00F6tt"; },
        equals: function (value) { return "megegyezik azzal, hogy \"" + trans_1.trans(value) + "\""; },
        notEquals: function (value) { return "NEM egyezik meg azzal, hogy \"" + trans_1.trans(value) + "\""; },
        isIn: function (value) { return "tartalmazza azt, hogy \"" + trans_1.trans(value) + "\""; },
        notIn: function (value) { return "NEM tartalmazza azt, hogy \"" + trans_1.trans(value) + "\""; },
        endsWith: function (value) { return "azzal v\u00E9gz\u0151dik, hogy \"" + trans_1.trans(value) + "\""; },
        notEndsWith: function (value) { return "NEM azzal v\u00E9gz\u0151dik, hogy \"" + trans_1.trans(value) + "\""; },
        startsWith: function (value) { return "azzal kezd\u0151dik, hogy \"" + trans_1.trans(value) + "\""; },
        notStartsWith: function (value) { return "NEM azzal kezd\u0151dik, hogy \"" + trans_1.trans(value) + "\""; },
        after: function (value) { return (value ? '...' : moment(value).format('YYYY-MM-DD')) + " ut\u00E1n t\u00F6rt\u00E9nt"; },
        before: function (value) { return (value ? '...' : moment(value).format('YYYY-MM-DD')) + " el\u0151tt t\u00F6rt\u00E9nt"; },
        between: function (value) {
            var isDate = (value === null || value === void 0 ? void 0 : value.from) && (value === null || value === void 0 ? void 0 : value.to);
            return isDate
                ? "\"" + ((value === null || value === void 0 ? void 0 : value.from) ? '...' : moment(value === null || value === void 0 ? void 0 : value.from).format('YYYY-MM-DD')) + "\" \u00E9s " + ((value === null || value === void 0 ? void 0 : value.to) ? '...' : moment(value === null || value === void 0 ? void 0 : value.to).format('YYYY-MM-DD')) + " k\u00F6z\u00F6tt t\u00F6rt\u00E9nt"
                : "\u00E9rt\u00E9ke \"" + ((value === null || value === void 0 ? void 0 : value.start) !== undefined ? value === null || value === void 0 ? void 0 : value.start : '...') + "\" \u00E9s \"" + ((value === null || value === void 0 ? void 0 : value.end) !== undefined ? value === null || value === void 0 ? void 0 : value.end : '...') + "\" k\u00F6z\u00F6tt van";
        },
        lowerThanEquals: function (value) { return "kissebb, mint " + value; },
        greaterThanEquals: function (value) { return "nagyobb, mint " + value; },
    }
};
