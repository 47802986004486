"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerNameLabel = void 0;
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var CustomerNameLabel = (function (_super) {
    __extends(CustomerNameLabel, _super);
    function CustomerNameLabel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerNameLabel.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Label, { className: 'MonogramBox' }, this.getMonogram().toUpperCase());
    };
    CustomerNameLabel.prototype.getMonogram = function () {
        var firstName = !!this.props.firstName
            ? this.props.firstName
            : '';
        var lastName = !!this.props.lastName
            ? this.props.lastName
            : '';
        return (firstName + " " + lastName)
            .replace(new RegExp('  ', 'g'), ' ')
            .split(' ')
            .filter(function (item) { return !!item; })
            .map(function (item) { return !!(item === null || item === void 0 ? void 0 : item.length) && item[0]; })
            .filter(function (item) { return !!item; })
            .join('') || 'AA';
    };
    return CustomerNameLabel;
}(React.Component));
exports.CustomerNameLabel = CustomerNameLabel;
