"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterInvoicesScreen = void 0;
require("../libs/common.scss");
var DatePicker = require("react-datepicker");
var React = require("react");
var moment = require("moment");
var axios_1 = require("axios");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var ShoprenterInvoicesScreen = (function (_super) {
    __extends(ShoprenterInvoicesScreen, _super);
    function ShoprenterInvoicesScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    name: 'from',
                    label: '',
                    value: moment().subtract(3, 'month').startOf('month').toDate(),
                }),
                to: new field_1.Field({
                    name: 'to',
                    label: '',
                    value: moment().endOf('month').toDate(),
                }),
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                from: _this.form.fields.from.getValue(),
                to: _this.form.fields.to.getValue(),
            }
        });
        _this.state = {
            isLoading: false
        };
        return _this;
    }
    ShoprenterInvoicesScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.fields.from.$value.subscribe(function (value) { return _this.control.set('from', moment(value).startOf('day').toDate()); }), this.form.fields.to.$value.subscribe(function (value) { return _this.control.set('to', moment(value).endOf('day').toDate()); }));
    };
    ShoprenterInvoicesScreen.prototype.handleClickDownloadCsv = function () {
        return __awaiter(this, void 0, void 0, function () {
            var SHOPRENTER_API_ROOT_URL, fromQueryParameter, toQueryParameter, apiUrl, response, rawRows, csvContent, encodedUri, link, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterPaymentApiRootUrl') || 'https://payment-api.v3.miclub.staging2.greendev.hu';
                        fromQueryParameter = moment(this.form.fields.from.value).startOf('day').toDate().toISOString();
                        toQueryParameter = moment(this.form.fields.to.value).endOf('day').toDate().toISOString();
                        apiUrl = new URL(SHOPRENTER_API_ROOT_URL + "/payments/active/csv?from=" + fromQueryParameter + "&to=" + toQueryParameter);
                        console.log('handleClickDownloadCsv apiUrl', apiUrl);
                        return [4, axios_1.default.get(apiUrl.toString())];
                    case 1:
                        response = _a.sent();
                        console.log('handleClickDownloadCsv response', response);
                        rawRows = (response === null || response === void 0 ? void 0 : response.data) || [];
                        csvContent = "data:text/csv;charset=utf-8," + rawRows;
                        encodedUri = encodeURI(csvContent);
                        link = document.createElement('a');
                        link.setAttribute('href', encodedUri);
                        link.setAttribute('download', "shoprenter-dijelszamolo-csv_" + moment(this.form.fields.from.getValue()).format('YYYY-MM-DD') + "_" + moment(this.form.fields.to.getValue()).format('YYYY-MM-DD'));
                        document.body.appendChild(link);
                        link.click();
                        return [3, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error('handleClickDownloadCsv catch error', error_1);
                        return [3, 4];
                    case 3:
                        this.setState({ isLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    ShoprenterInvoicesScreen.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop egyedi azonos\u00EDt\u00F3 (shopId)")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Webshop neve")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "\u00D6sszeg")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Commission")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Id\u0151")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "#")))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    ShoprenterInvoicesScreen.prototype.renderItem = function (ctx, item, index) {
        var _id = item._id, shopId = item.shopId, shopName = item.shopName, createdAt = item.createdAt, grossAmount = item.grossAmount, commission = item.commission, occasion = item.occasion;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: _id + index, verticalAlign: "middle", style: { cursor: 'pointer' } },
            React.createElement(semantic_ui_react_1.Table.Cell, null, shopId),
            React.createElement(semantic_ui_react_1.Table.Cell, null, shopName),
            React.createElement(semantic_ui_react_1.Table.Cell, null, grossAmount.toLocaleString('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).replace(',', '.')),
            React.createElement(semantic_ui_react_1.Table.Cell, null, commission || 'MISSING_COMMISSION'),
            React.createElement(semantic_ui_react_1.Table.Cell, null, moment(createdAt).format('YYYY.MM.DD. HH:mm')),
            React.createElement(semantic_ui_react_1.Table.Cell, null, occasion));
    };
    ShoprenterInvoicesScreen.prototype.render = function () {
        var _this = this;
        var SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterPaymentApiRootUrl') || 'https://payment-api.v3.miclub.staging2.greendev.hu';
        var url = new URL(SHOPRENTER_API_ROOT_URL + "/payments/active");
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Shoprenter d\u00EDjelsz\u00E1mol\u00F3"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { floated: 'left', className: 'date-picker-wrapper justify-content-end' },
                                React.createElement("p", { className: 'header-filter-label' }, "D\u00E1tum sz\u0171r\u00E9s"),
                                React.createElement(semantic_ui_react_1.Grid.Row, null,
                                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                                        React.createElement(semantic_ui_react_1.Form, { className: 'display-flex' },
                                            React.createElement("div", { className: "date-wrapper" },
                                                React.createElement("div", { className: 'position-relative display-flex' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })),
                                                React.createElement("div", { className: 'date-picker-divider' }),
                                                React.createElement("div", { className: 'position-relative display-flex mr-4' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })))))))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { floated: 'left', className: 'date-picker-wrapper justify-content-end' },
                                React.createElement(semantic_ui_react_1.Button, { loading: this.state.isLoading, disabled: this.state.isLoading, size: 'small', color: "blue", basic: true, onClick: function () { return _this.handleClickDownloadCsv(); }, content: "D\u00EDjelsz\u00E1mol\u00F3 CSV let\u00F6lt\u00E9s" }))),
                        React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: url.toString(), renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 4 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 4 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } })))));
    };
    return ShoprenterInvoicesScreen;
}(abstract_component_1.AbstractComponent));
exports.ShoprenterInvoicesScreen = ShoprenterInvoicesScreen;
