"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@codebuild/uikit/libs/environment");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var translation_1 = require("@codebuild/uikit/libs/translation/translation");
require("react-toastify/dist/ReactToastify.css");
var language_options_1 = require("./libs/language-options");
translation_1.Translation.setLanguage((_a = local_store_1.LocalStore.getItem('language')) !== null && _a !== void 0 ? _a : language_options_1.languageOptions[0].value);
translation_1.Translation.addSource('hu', __assign(__assign(__assign(__assign({}, require('@codebuild/uikit/modules/codebuild/authentication/translations/login/hu')), require('@codebuild/uikit/modules/codebuild/authentication/translations/registration/hu')), require('@codebuild/uikit/modules/codebuild/authentication/translations/forgot-password/hu')), require('./translations/hu/hu')));
environment_1.Environment
    .initialize()
    .then(function () { return require('./application'); });
