"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimatedListComponent = void 0;
var lodash_1 = require("lodash");
var React = require("react");
require("./animated-list.component.scss");
var AnimatedListComponent = (function (_super) {
    __extends(AnimatedListComponent, _super);
    function AnimatedListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.diffKeys = [];
        return _this;
    }
    AnimatedListComponent.prototype.getDifference = function (prevItems, currentItems) {
        if (!(prevItems === null || prevItems === void 0 ? void 0 : prevItems.length) && !(currentItems === null || currentItems === void 0 ? void 0 : currentItems.length)) {
            return [];
        }
        if (!(prevItems === null || prevItems === void 0 ? void 0 : prevItems.length) && !!(currentItems === null || currentItems === void 0 ? void 0 : currentItems.length)) {
            return currentItems;
        }
        var prevTop = prevItems[0];
        var isInNew = currentItems.findIndex(function (item) { return prevTop.key === item.key; });
        if (isInNew === -1) {
            return currentItems;
        }
        var diff = lodash_1.clone(currentItems).slice(0, isInNew);
        this.diffKeys = diff.map(function (item) { return item.key; });
        return diff;
    };
    AnimatedListComponent.prototype.shouldComponentUpdate = function (nextProps, nextState, nextContext) {
        return !!this.getDifference(this.props.children, nextProps.children).length;
    };
    AnimatedListComponent.prototype.render = function () {
        var _this = this;
        var mapped = this.props.children.map(function (item) { return ({
            isNew: _this.diffKeys.includes(item.key),
            item: item
        }); });
        var newItems = mapped.filter(function (item) { return !!item.isNew; }).map(function (item) { return item.item; });
        var oldItems = mapped.filter(function (item) { return !item.isNew; }).map(function (item) { return item.item; });
        if (this.newRef$) {
            this.newRef$.style.transition = 'transform 0s';
            this.newRef$.style.transform = 'translateY(-100%)';
        }
        if (this.oldRef$) {
            this.oldRef$.style.transition = 'transform 0s';
            this.oldRef$.style.transform = 'translateY(0)';
        }
        setTimeout(function () { return _this.finishAnimation(); }, 100);
        return React.createElement("div", { className: "AnimatedListComponent transition-group-wrapper" },
            React.createElement("div", { className: 'w-100 transition-group-new', ref: function (ref) { return (_this.newRef$ = ref); } }, newItems.map(function (item) { return React.createElement(item.type, item.props); })),
            React.createElement("div", { className: 'w-100 transition-group-old', ref: function (ref) { return (_this.oldRef$ = ref); } }, oldItems.map(function (item) { return React.createElement(item.type, item.props); })));
    };
    AnimatedListComponent.prototype.finishAnimation = function () {
        var _a;
        var height = ((_a = this.newRef$) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0;
        this.newRef$.style.transition = 'transform .5s';
        this.oldRef$.style.transition = 'transform .5s';
        this.newRef$.style.transform = 'translateY(0)';
        this.oldRef$.style.transform = "translateY(" + height + "px)";
    };
    return AnimatedListComponent;
}(React.Component));
exports.AnimatedListComponent = AnimatedListComponent;
