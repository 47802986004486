"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventTypeOptions = exports.eventTypes = void 0;
var React = require("react");
var currency_parser_1 = require("./currency-parser");
exports.eventTypes = {
    'product-view': {
        name: 'product-view',
        title: 'event-types.product-view',
        render: function (event) { var _a, _b; return ((_b = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.name) || '-'; },
        icon: 'desktop'
    },
    'category-view': {
        name: 'category-view',
        title: 'event-types.category-view',
        render: function (event) { var _a, _b; return ((_b = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.name) || '-'; },
        icon: 'bookmark'
    },
    'page-view': {
        name: 'page-view',
        title: 'event-types.page-view',
        render: function (event) {
            var _a, _b, _c;
            return ((_a = event === null || event === void 0 ? void 0 : event.meta) === null || _a === void 0 ? void 0 : _a.href) ? React.createElement("a", { href: (_b = event === null || event === void 0 ? void 0 : event.meta) === null || _b === void 0 ? void 0 : _b.href, target: '_blank' }, (_c = event === null || event === void 0 ? void 0 : event.meta) === null || _c === void 0 ? void 0 : _c.href)
                : null;
        },
        icon: 'mouse pointer'
    },
    email: {
        name: 'email',
        title: 'event-types.email',
        render: function (event) {
            var _a, _b, _c, _d;
            if (!((_a = event === null || event === void 0 ? void 0 : event.payload) === null || _a === void 0 ? void 0 : _a.redirectUrl)) {
                return (React.createElement("div", null, "redirectUrl"));
            }
            var url = new URL((_b = event === null || event === void 0 ? void 0 : event.payload) === null || _b === void 0 ? void 0 : _b.redirectUrl);
            return React.createElement("div", null,
                React.createElement("b", null, ((_c = event === null || event === void 0 ? void 0 : event.payload) === null || _c === void 0 ? void 0 : _c.subject) || ((_d = event === null || event === void 0 ? void 0 : event.data) === null || _d === void 0 ? void 0 : _d.subject) || '-'),
                React.createElement("br", null),
                React.createElement("a", { href: "" + (url === null || url === void 0 ? void 0 : url.origin) + (url === null || url === void 0 ? void 0 : url.pathname), target: "_blank" }, "" + (url === null || url === void 0 ? void 0 : url.origin) + (url === null || url === void 0 ? void 0 : url.pathname)));
        },
        icon: 'envelope open'
    },
    'email-opened': {
        name: 'email-opened',
        title: 'event-types.email-opened',
        render: function (event) { var _a, _b; return ((_a = event === null || event === void 0 ? void 0 : event.payload) === null || _a === void 0 ? void 0 : _a.subject) || ((_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.subject) || '-'; },
        icon: 'envelope open'
    },
    'email-sent': {
        name: 'email-sent',
        title: 'event-types.email-sent',
        render: function (event) { var _a, _b; return ((_a = event === null || event === void 0 ? void 0 : event.payload) === null || _a === void 0 ? void 0 : _a.subject) || ((_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.subject) || '-'; },
        icon: 'envelope'
    },
    'email-queued': {
        name: 'email-sent',
        title: 'event-types.email-queued',
        render: function (event) { var _a, _b; return ((_a = event === null || event === void 0 ? void 0 : event.payload) === null || _a === void 0 ? void 0 : _a.subject) || ((_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.subject) || '-'; },
        icon: 'envelope'
    },
    'add-to-cart': {
        name: 'add-to-cart',
        title: 'event-types.add-to-cart',
        render: function (event) { var _a, _b, _c; return (((_a = event === null || event === void 0 ? void 0 : event.payload) === null || _a === void 0 ? void 0 : _a.amount) || 'n/a') + " x " + (((_c = (_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.name) || '-'); },
        icon: 'cart plus'
    },
    'cart-change': {
        name: 'cart-change',
        title: 'event-types.cart-change',
        render: function (event) {
            var _a;
            return React.createElement("div", null,
                React.createElement("ol", null, ((event === null || event === void 0 ? void 0 : event.data) || []).map(function (p) { var _a; return (React.createElement("li", { key: p === null || p === void 0 ? void 0 : p._id }, (_a = p === null || p === void 0 ? void 0 : p.data) === null || _a === void 0 ? void 0 : _a.name)); })),
                React.createElement("div", { className: 'mt-2' },
                    React.createElement("span", null, "\u00D6sszesen:"),
                    " ",
                    currency_parser_1.hufFormat((((_a = event === null || event === void 0 ? void 0 : event.payload) === null || _a === void 0 ? void 0 : _a.value) || 0))));
        },
        icon: 'shopping cart'
    },
    order: {
        name: 'order',
        title: 'event-types.order',
        render: function (event) {
            var _a, _b, _c, _d, _e, _f;
            var totalValue = 0;
            if ((_b = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.totalGrossPrice) {
                totalValue = (_d = (_c = event === null || event === void 0 ? void 0 : event.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.totalGrossPrice;
            }
            return !!totalValue
                ? React.createElement("div", null,
                    React.createElement("ol", null, (((_f = (_e = event === null || event === void 0 ? void 0 : event.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.items) || []).map(function (p) {
                        var _a, _b;
                        return (React.createElement("li", { key: p === null || p === void 0 ? void 0 : p._id }, ((_b = (_a = p === null || p === void 0 ? void 0 : p.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.name) || null));
                    })),
                    React.createElement("div", { className: 'mt-2' },
                        React.createElement("span", null, "\u00D6sszesen:"),
                        " ",
                        currency_parser_1.hufFormat((totalValue || 0))))
                : null;
        },
        icon: 'box'
    },
    'remove-from-cart': {
        name: 'remove-from-cart',
        title: 'event-types.remove-from-cart',
        render: function (event) { var _a, _b, _c; return (((_a = event === null || event === void 0 ? void 0 : event.payload) === null || _a === void 0 ? void 0 : _a.amount) || 'n/a') + " x " + (((_c = (_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.name) || '-'); },
        icon: 'shopping cart'
    },
    login: {
        name: 'login',
        title: 'event-types.login',
        render: function (event) { return ''; },
        icon: 'sign in'
    },
    logout: {
        name: 'logout',
        title: 'event-types.logout',
        render: function (event) { return ''; },
        icon: 'sign out'
    },
    subscribe: {
        name: 'logout',
        title: 'event-types.subscribe',
        render: function (event) { return ''; },
        icon: 'user plus'
    },
    unsubscribe: {
        name: 'logout',
        title: 'event-types.unsubscribe',
        render: function (event) { return ''; },
        icon: 'user times'
    },
    'email-sent-permanent-failed': {
        name: 'email-sent-permanent-failed',
        title: 'event-types.email-sent-permanent-failed',
        render: function (event) { var _a, _b; return ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.subject) || ((_b = event === null || event === void 0 ? void 0 : event.payload) === null || _b === void 0 ? void 0 : _b.subject) || '-'; },
        icon: 'envelope'
    },
    'email-sent-temporary-failed': {
        name: 'email-sent-temporary-failed',
        title: 'event-types.email-sent-temporary-failed',
        render: function (event) { var _a, _b; return ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.subject) || ((_b = event === null || event === void 0 ? void 0 : event.payload) === null || _b === void 0 ? void 0 : _b.subject) || '-'; },
        icon: 'envelope'
    },
};
exports.eventTypeOptions = [
    { value: 'add-to-cart', text: 'event-types.add-to-cart', key: 'add-to-cart' },
    { value: 'cart-change', text: 'event-types.cart-change', key: 'cart-change' },
    { value: 'category-view', text: 'event-types.category-view', key: 'category-view' },
    { value: 'email', text: 'event-types.email', key: 'email' },
    { value: 'email-opened', text: 'event-types.email-opened', key: 'email-opened' },
    { value: 'email-queued', text: 'event-types.email-queued', key: 'email-queued' },
    { value: 'email-sent', text: 'event-types.email-sent', key: 'email-sent' },
    { value: 'login', text: 'event-types.login', key: 'login' },
    { value: 'logout', text: 'event-types.logout', key: 'logout' },
    { value: 'order', text: 'event-types.order', key: 'order' },
    { value: 'page-view', text: 'event-types.page-view', key: 'page-view' },
    { value: 'product-view', text: 'event-types.product-view', key: 'product-view' },
    { value: 'subscribe', text: 'event-types.subscribe', key: 'subscribe' },
    { value: 'unsubscribe', text: 'event-types.unsubscribe', key: 'unsubscribe' },
    {
        value: 'email-sent-permanent-failed',
        text: 'event-types.email-sent-permanent-failed',
        key: 'email-sent-permanent-failed'
    },
    {
        value: 'email-sent-temporary-failed',
        text: 'event-types.email-sent-temporary-failed',
        key: 'email-sent-temporary-failed'
    },
];
