module.exports = {
    'industry-options.electronic': 'Számítógépek és elektronika',
    'industry-options.it': 'Internet és telekommunikáció',
    'industry-options.art': 'Művészet és szórakozás',
    'industry-options.car': 'Autók és járművek',
    'industry-options.beauty': 'Szépség és fitnesz',
    'industry-options.book': 'Könyv és irodalom',
    'industry-options.business': 'Üzleti élet és ipar',
    'industry-options.finance': 'Pénzügy',
    'industry-options.food': 'Étel-ital',
    'industry-options.toy': 'Játékok',
    'industry-options.health': 'Egészség',
    'industry-options.hobby': 'Hobbi és szabadidő',
    'industry-options.home': 'Otthon és kert',
    'industry-options.work': 'Munka és oktatás',
    'industry-options.law': 'Jog és közigazgatás',
    'industry-options.news': 'Hírek',
    'industry-options.society': 'Emberek és társadalom',
    'industry-options.pet': 'Házi kedvencek és állatok',
    'industry-options.online': 'Online közösségek',
    'industry-options.realestate': 'Ingatlan',
    'industry-options.reference': 'Referencia',
    'industry-options.science': 'Tudomány',
    'industry-options.shopping': 'Vásárlás',
    'industry-options.sport': 'Sport',
    'industry-options.travel': 'Utazás',
    'industry-options.other': 'Egyéb'
};
