"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var activity_indicator_1 = require("../../../common/components/activity-indicator/activity-indicator");
var capitalize_1 = require("../../../common/libs/capitalize");
var sensitive_data_1 = require("../../../common/libs/sensitive-data");
var state_repository_1 = require("../../../common/libs/state-repository");
var customer_name_label_component_1 = require("../customer-name-label/customer-name-label.component");
var browser_stats_component_1 = require("../lead-stats/browser-stats.component");
var current_cart_sum_card_component_1 = require("../lead-stats/current-cart-sum-card.component");
var customer_order_stat_component_1 = require("../lead-stats/customer-order-stat.component");
var customer_unsubscribe_component_1 = require("../lead-stats/customer-unsubscribe.component");
var sweetspot_chart_1 = require("../lead-stats/sweetspot.chart");
require("./customer-details.component.scss");
var customer_events_list_component_1 = require("./customer-events-list.component");
var CustomerDetailsComponent = (function (_super) {
    __extends(CustomerDetailsComponent, _super);
    function CustomerDetailsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.customer = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'customer');
        _this.processedEvent = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'processedEvent');
        return _this;
    }
    CustomerDetailsComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _super.prototype.componentDidMount.call(this);
                        return [4, this.fetchCustomer()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    CustomerDetailsComponent.prototype.fetchCustomer = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __awaiter(this, void 0, void 0, function () {
            var useCustomer, useProcessedEvent;
            return __generator(this, function (_o) {
                switch (_o.label) {
                    case 0:
                        useCustomer = this.props.useCustomer;
                        useProcessedEvent = this.props.useProcessedEvent;
                        if (!useCustomer) return [3, 3];
                        return [4, this.customer.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/customer/" + this.props.identifier, {})];
                    case 1:
                        _o.sent();
                        return [4, this.processedEvent.get("/s/" + ((_b = this.getCurrentShop()) === null || _b === void 0 ? void 0 : _b._id) + "/event/customer/" + ((_d = (_c = this.state.customer) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d._id), {})];
                    case 2:
                        _o.sent();
                        return [2];
                    case 3:
                        if (!useProcessedEvent) return [3, 7];
                        return [4, this.processedEvent.get("/s/" + ((_e = this.getCurrentShop()) === null || _e === void 0 ? void 0 : _e._id) + "/event/processed-event-by-id/" + this.props.identifier, {})];
                    case 4:
                        _o.sent();
                        if (!((_h = (_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.processedEvent) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.cId)) return [3, 6];
                        return [4, this.customer.get("/s/" + ((_j = this.getCurrentShop()) === null || _j === void 0 ? void 0 : _j._id) + "/data/temporary/customer/" + ((_m = (_l = (_k = this.state) === null || _k === void 0 ? void 0 : _k.processedEvent) === null || _l === void 0 ? void 0 : _l.response) === null || _m === void 0 ? void 0 : _m.cId) + "/by-event-identifier", {})];
                    case 5:
                        _o.sent();
                        _o.label = 6;
                    case 6: return [2];
                    case 7: return [2];
                }
            });
        });
    };
    CustomerDetailsComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        return React.createElement("div", { className: "LeadDetailsComponent" },
            React.createElement(semantic_ui_react_1.Grid, { reversed: 'computer', stackable: true },
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { mobile: 16, tablet: 16, computer: 4 },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h3' }, "Statisztik\u00E1k"),
                        React.createElement(sweetspot_chart_1.SweetSpotChart, { processedEvent: (_a = this.state.processedEvent) === null || _a === void 0 ? void 0 : _a.response }),
                        React.createElement(current_cart_sum_card_component_1.CurrentCartSumOfUserCardComponent, { onFilter: function (value) { return _this.eventListRef.filterEvents(value); }, cartSum: (_e = (_d = (_c = (_b = this.state.processedEvent) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.statistics) === null || _d === void 0 ? void 0 : _d.currentCart) === null || _e === void 0 ? void 0 : _e.value }),
                        React.createElement(browser_stats_component_1.BrowserStatsComponent, { stats: (_h = (_g = (_f = this.state.processedEvent) === null || _f === void 0 ? void 0 : _f.response) === null || _g === void 0 ? void 0 : _g.statistics) === null || _h === void 0 ? void 0 : _h.device, loading: (_j = this.state.processedEvent) === null || _j === void 0 ? void 0 : _j.loading }),
                        React.createElement(customer_order_stat_component_1.CustomerOrderStat, { onFilter: function (value) { return _this.eventListRef.filterEvents(value); }, stats: (_m = (_l = (_k = this.state) === null || _k === void 0 ? void 0 : _k.customer) === null || _l === void 0 ? void 0 : _l.response) === null || _m === void 0 ? void 0 : _m.statistics }),
                        React.createElement(customer_unsubscribe_component_1.CustomerUnsubscribeComponent, { customer: (_p = (_o = this.state) === null || _o === void 0 ? void 0 : _o.customer) === null || _p === void 0 ? void 0 : _p.response })),
                    React.createElement(semantic_ui_react_1.Grid.Column, { mobile: 16, tablet: 16, computer: 12 },
                        React.createElement("div", null, this.renderCustomerName()),
                        ((_r = (_q = this.state) === null || _q === void 0 ? void 0 : _q.processedEvent) === null || _r === void 0 ? void 0 : _r.response) && React.createElement(customer_events_list_component_1.CustomerEventsListComponent, { ref: function (ref) { return (_this.eventListRef = ref); }, peId: (_u = (_t = (_s = this.state) === null || _s === void 0 ? void 0 : _s.processedEvent) === null || _t === void 0 ? void 0 : _t.response) === null || _u === void 0 ? void 0 : _u._id, shopId: (_v = this.getCurrentShop()) === null || _v === void 0 ? void 0 : _v._id })))));
    };
    CustomerDetailsComponent.prototype.renderCustomerName = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35;
        if ((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c._id) {
            return React.createElement("div", { className: ' w-100 display-inline-flex' },
                React.createElement(customer_name_label_component_1.CustomerNameLabel, { lastName: (_g = (_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.customer) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.lastName, firstName: (_l = (_k = (_j = (_h = this.state) === null || _h === void 0 ? void 0 : _h.customer) === null || _j === void 0 ? void 0 : _j.response) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.firstName, identifier: (_q = (_p = (_o = (_m = this.state) === null || _m === void 0 ? void 0 : _m.customer) === null || _o === void 0 ? void 0 : _o.response) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.identifier, shopId: (_r = this.getCurrentShop()) === null || _r === void 0 ? void 0 : _r._id, activity: true }),
                React.createElement("div", { className: 'display-flex flex-column ml-4' },
                    React.createElement(activity_indicator_1.ActivityIndicator, { shopId: (_s = this.getCurrentShop()) === null || _s === void 0 ? void 0 : _s._id, cIdOrSId: (_w = (_v = (_u = (_t = this.state) === null || _t === void 0 ? void 0 : _t.customer) === null || _u === void 0 ? void 0 : _u.response) === null || _v === void 0 ? void 0 : _v.data) === null || _w === void 0 ? void 0 : _w.identifier },
                        React.createElement("h3", { className: 'CustomerName' },
                            sensitive_data_1.SensitiveData.customerName((_0 = (_z = (_y = (_x = this.state) === null || _x === void 0 ? void 0 : _x.customer) === null || _y === void 0 ? void 0 : _y.response) === null || _z === void 0 ? void 0 : _z.data) === null || _0 === void 0 ? void 0 : _0.lastName),
                            " ",
                            sensitive_data_1.SensitiveData.customerName((_4 = (_3 = (_2 = (_1 = this.state) === null || _1 === void 0 ? void 0 : _1.customer) === null || _2 === void 0 ? void 0 : _2.response) === null || _3 === void 0 ? void 0 : _3.data) === null || _4 === void 0 ? void 0 : _4.firstName))),
                    React.createElement("span", { className: 'CustomerEmail' }, sensitive_data_1.SensitiveData.customerEmail((_8 = (_7 = (_6 = (_5 = this.state) === null || _5 === void 0 ? void 0 : _5.customer) === null || _6 === void 0 ? void 0 : _6.response) === null || _7 === void 0 ? void 0 : _7.data) === null || _8 === void 0 ? void 0 : _8.email)),
                    this.renderSubscribeLabel((_10 = (_9 = this.state) === null || _9 === void 0 ? void 0 : _9.customer) === null || _10 === void 0 ? void 0 : _10.response)));
        }
        return React.createElement("div", { className: ' w-100 display-inline-flex' },
            React.createElement(customer_name_label_component_1.CustomerNameLabel, { lastName: (_13 = (_12 = (_11 = this.state) === null || _11 === void 0 ? void 0 : _11.processedEvent) === null || _12 === void 0 ? void 0 : _12.response) === null || _13 === void 0 ? void 0 : _13.anonymousLastName, firstName: (_16 = (_15 = (_14 = this.state) === null || _14 === void 0 ? void 0 : _14.processedEvent) === null || _15 === void 0 ? void 0 : _15.response) === null || _16 === void 0 ? void 0 : _16.anonymousFirstName, identifier: (_20 = (_19 = (_18 = (_17 = this.state) === null || _17 === void 0 ? void 0 : _17.processedEvent) === null || _18 === void 0 ? void 0 : _18.response) === null || _19 === void 0 ? void 0 : _19.sIds) === null || _20 === void 0 ? void 0 : _20[0], shopId: (_21 = this.getCurrentShop()) === null || _21 === void 0 ? void 0 : _21._id, activity: true }),
            React.createElement("div", { className: 'display-flex flex-column ml-4' },
                React.createElement(activity_indicator_1.ActivityIndicator, { shopId: (_22 = this.getCurrentShop()) === null || _22 === void 0 ? void 0 : _22._id, cIdOrSId: (_26 = (_25 = (_24 = (_23 = this.state) === null || _23 === void 0 ? void 0 : _23.processedEvent) === null || _24 === void 0 ? void 0 : _24.response) === null || _25 === void 0 ? void 0 : _25.sIds) === null || _26 === void 0 ? void 0 : _26[0] },
                    React.createElement("h3", { className: 'CustomerName', style: { color: "" + ((_29 = (_28 = (_27 = this.state) === null || _27 === void 0 ? void 0 : _27.processedEvent) === null || _28 === void 0 ? void 0 : _28.response) === null || _29 === void 0 ? void 0 : _29.anonymousColor) } },
                        capitalize_1.capitalize((_32 = (_31 = (_30 = this.state) === null || _30 === void 0 ? void 0 : _30.processedEvent) === null || _31 === void 0 ? void 0 : _31.response) === null || _32 === void 0 ? void 0 : _32.anonymousFirstName),
                        " ",
                        capitalize_1.capitalize((_35 = (_34 = (_33 = this.state) === null || _33 === void 0 ? void 0 : _33.processedEvent) === null || _34 === void 0 ? void 0 : _34.response) === null || _35 === void 0 ? void 0 : _35.anonymousLastName)))));
    };
    CustomerDetailsComponent.prototype.renderSubscribeLabel = function (item) {
        var _a, _b, _c, _d;
        var subscribed = (_a = item === null || item === void 0 ? void 0 : item.statement) === null || _a === void 0 ? void 0 : _a.subscribed;
        var unsubscribed = (_b = item === null || item === void 0 ? void 0 : item.statement) === null || _b === void 0 ? void 0 : _b.unsubscribed;
        var subscribeReason = (_d = (_c = item === null || item === void 0 ? void 0 : item.statement) === null || _c === void 0 ? void 0 : _c.unsubscribeReason) === null || _d === void 0 ? void 0 : _d.split('Unsubscribed at sync').length;
        if (subscribed) {
            return React.createElement("div", { className: 'subscription-label subscribed' },
                React.createElement(semantic_ui_react_1.Icon, { name: 'check' }),
                "Feliratkozva");
        }
        if (unsubscribed && subscribeReason > 1) {
            return React.createElement("div", { className: 'subscription-label unsubscribed' },
                React.createElement(semantic_ui_react_1.Icon, { name: 'delete' }),
                "Leiratkozva");
        }
        return React.createElement("div", { className: 'subscription-label not-subscribed' }, "Nincs feliratkozva");
    };
    CustomerDetailsComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CustomerDetailsComponent;
}(responsive_component_1.ResponsiveComponent));
exports.CustomerDetailsComponent = CustomerDetailsComponent;
