"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var authorized_middleware_1 = require("../modules/common/middlewares/authorized.middleware");
var shop_synchronization_layout_1 = require("../modules/shop-synchronization/layouts/shop-synchronization.layout");
var synchronization_progress_screen_1 = require("../modules/shop-synchronization/screens/synchronization-progress.screen");
var commonMiddlewares = [
    new authorized_middleware_1.AuthorizedMiddleware({
        redirectTo: '/login'
    }),
];
module.exports = [
    {
        middlewares: commonMiddlewares,
        path: '/shop-synchronization/:shopId/progress',
        layout: shop_synchronization_layout_1.ShopSynchronizationLayout,
        component: synchronization_progress_screen_1.SynchronizationProgressScreen.withProps({})
    },
];
