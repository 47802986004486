"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoriesScreen = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var lodash_1 = require("lodash");
var React = require("react");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var list_empty_1 = require("../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var sort_header_1 = require("../../common/components/sort-header/sort-header");
var state_repository_1 = require("../../common/libs/state-repository");
var category_toggle_component_1 = require("../components/category/category-toggle.component");
var category_update_component_1 = require("../components/category/category-update.component");
require("./libs/common.scss");
var CategoriesScreen = (function (_super) {
    __extends(CategoriesScreen, _super);
    function CategoriesScreen() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var _this = _super.apply(this, arguments) || this;
        _this.order = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'order');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                query: new field_1.Field({
                    name: 'query',
                    label: '',
                    value: '',
                    placeholder: 'Keresés'
                })
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._limit) !== null && _c !== void 0 ? _c : 0,
                _page: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e._page) !== null && _f !== void 0 ? _f : 0,
                query: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.query.query) !== null && _h !== void 0 ? _h : ''
            }
        });
        return _this;
    }
    CategoriesScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$value.pipe(operators_1.debounceTime(250)).subscribe(function (value) {
            _this.control.set('query', value === null || value === void 0 ? void 0 : value.query);
            _this.control.set('page', 0);
        });
    };
    CategoriesScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: "CategoriesScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, className: 'display-flex' },
                                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Email csomagok")),
                                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                                        React.createElement(category_update_component_1.CategoryUpdateComponent, { onCreate: function () { return _this.control.reload(); } })))),
                            React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'right' }, this.renderListHeader()))),
                    React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/a/category", renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 4 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 4 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }),
                    React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                            { text: 1, value: 1 },
                            { text: 10, value: 10 },
                            { text: 25, value: 25 },
                            { text: 50, value: 50 },
                            { text: 100, value: 100 },
                        ] }))));
    };
    CategoriesScreen.prototype.renderListHeader = function () {
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'ListHeader' },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.query, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { icon: 'search', control: semantic_ui_react_1.Input })))));
    };
    CategoriesScreen.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement(sort_header_1.SortHeader, { title: "Csomag neve", property: 'name', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    CategoriesScreen.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item._id + index, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true }, this.renderOrderButtons(ctx, item, index, index === 0, (index === (ctx.state.items.length - 1)))),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("strong", null, item.name)),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                React.createElement(category_update_component_1.CategoryUpdateComponent, { _id: item._id, onDelete: function () { return _this.control.reload(); }, onUpdate: function () { return _this.control.reload(); } },
                    React.createElement(semantic_ui_react_1.Button, { size: 'small', color: "blue", basic: true, icon: "pencil", content: 'szerkesztés' }))),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                React.createElement(category_toggle_component_1.CategoryToggleComponent, { _id: item._id, active: item.active })));
    };
    CategoriesScreen.prototype.renderOrderButtons = function (ctx, item, currentIndex, isFirst, isLast) {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button.Group, { vertical: true, size: "mini" },
            !isFirst && React.createElement(semantic_ui_react_1.Button, { icon: "angle up", onClick: function () { return _this.onOrderChange(ctx, currentIndex, -1); } }),
            !isLast && React.createElement(semantic_ui_react_1.Button, { icon: "angle down", onClick: function () { return _this.onOrderChange(ctx, currentIndex, 1); } }));
    };
    CategoriesScreen.prototype.onOrderChange = function (ctx, currentIndex, direction) {
        return __awaiter(this, void 0, void 0, function () {
            var items, currentElement, targetElement;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        items = lodash_1.clone(ctx.state.items || []);
                        currentElement = lodash_1.clone(items[currentIndex]);
                        targetElement = lodash_1.clone(items[currentIndex + direction]);
                        if (targetElement) {
                            items[currentIndex] = targetElement;
                            items[currentIndex + direction] = currentElement;
                        }
                        return [4, this.order.put('/a/category/order', {
                                data: {
                                    order: items.map(function (item, index) { return ({
                                        order: index,
                                        name: item.name,
                                        _id: item._id
                                    }); })
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.control.reload();
                        return [2];
                }
            });
        });
    };
    return CategoriesScreen;
}(abstract_component_1.AbstractComponent));
exports.CategoriesScreen = CategoriesScreen;
