"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var redirect_middleware_1 = require("@codebuild/uikit/components/middlewares/redirect.middleware");
var default_layout_1 = require("../modules/common/layouts/default.layout/default.layout");
var authorized_middleware_1 = require("../modules/common/middlewares/authorized.middleware");
var shop_middleware_1 = require("../modules/common/middlewares/shop.middleware");
var custom_newsletter_details_screen_1 = require("../modules/custom-newsletter/custom-newsletter-details.screen");
var custom_newsletter_screen_1 = require("../modules/custom-newsletter/custom-newsletter.screen");
var dashboard_screen_1 = require("../modules/dashboard/screens/dashboard.screen");
var customer_details_screen_1 = require("../modules/leads/screens/details/customer-details.screen");
var lead_list_screen_1 = require("../modules/leads/screens/list/lead-list.screen");
var newsletter_screen_1 = require("../modules/newsletter/newsletter.screen");
var performance_screen_1 = require("../modules/performance/performance.screen");
var settings_screen_1 = require("../modules/settings/screens/settings.screen");
var categories_screen_1 = require("../modules/webshop-data/screens/categories.screen");
var orders_screen_1 = require("../modules/webshop-data/screens/orders.screen");
var products_screen_1 = require("../modules/webshop-data/screens/products.screen");
var custom_newsletter_success_screen_1 = require("../modules/custom-newsletter/custom-newsletter-success.screen");
var shoprenter_screen_1 = require("../modules/shoprenter/screens/shoprenter.screen");
var default_layout_2 = require("../modules/common/layouts/shoprenter-app.layout/default.layout");
var payment_redirect_screen_1 = require("../modules/bigfish/payment-redirect.screen");
var commonMiddlewares = [
    new authorized_middleware_1.AuthorizedMiddleware({
        redirectTo: '/login'
    }),
    new shop_middleware_1.ShopMiddleware({
        redirectTo: '/shop-registration',
    })
];
module.exports = [
    {
        middlewares: __spreadArrays(commonMiddlewares, [
            new redirect_middleware_1.RedirectMiddleware('/dashboard')
        ]),
        path: '/'
    },
    {
        middlewares: commonMiddlewares,
        path: '/dashboard',
        layout: default_layout_1.DefaultLayout,
        component: dashboard_screen_1.DashboardScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/products',
        layout: default_layout_1.DefaultLayout,
        component: products_screen_1.ProductsScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/orders',
        layout: default_layout_1.DefaultLayout,
        component: orders_screen_1.OrdersScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/leads',
        layout: default_layout_1.DefaultLayout,
        component: lead_list_screen_1.LeadListScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/leads/pe/:peId',
        layout: default_layout_1.DefaultLayout,
        component: customer_details_screen_1.CustomerDetailsScreen.withProps({ useProcessedEvent: true })
    },
    {
        middlewares: commonMiddlewares,
        path: '/leads/customer/:cId',
        layout: default_layout_1.DefaultLayout,
        component: customer_details_screen_1.CustomerDetailsScreen.withProps({ useCustomer: true })
    },
    {
        middlewares: commonMiddlewares,
        path: '/categories',
        layout: default_layout_1.DefaultLayout,
        component: categories_screen_1.CategoriesScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/newsletters',
        layout: default_layout_1.DefaultLayout,
        component: newsletter_screen_1.NewsletterScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/custom-newsletters',
        layout: default_layout_1.DefaultLayout,
        component: custom_newsletter_screen_1.CustomNewsletterScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/custom-newsletters/success/:id',
        layout: default_layout_1.DefaultLayout,
        component: custom_newsletter_success_screen_1.CustomNewsletterSuccessScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/custom-newsletters/:id',
        layout: default_layout_1.DefaultLayout,
        component: custom_newsletter_details_screen_1.CustomNewsletterDetailsScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/performance',
        layout: default_layout_1.DefaultLayout,
        component: performance_screen_1.PerformanceScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/settings',
        layout: default_layout_1.DefaultLayout,
        component: settings_screen_1.SettingsScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/shoprenter',
        layout: default_layout_2.ShoprenterAppLayout,
        component: shoprenter_screen_1.ShoprenterScreen
    },
    {
        middlewares: commonMiddlewares,
        path: '/payment/redirect',
        layout: default_layout_1.DefaultLayout,
        component: payment_redirect_screen_1.PaymentRedirectScreen
    },
];
