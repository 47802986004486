"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopRegistrationSecondStepComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var ShopRegistrationSecondStepComponent = (function (_super) {
    __extends(ShopRegistrationSecondStepComponent, _super);
    function ShopRegistrationSecondStepComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShopRegistrationSecondStepComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { textAlign: 'center', as: 'h2' }, trans_1.trans('shop-registration.second-step.title')),
                    React.createElement("p", { style: { textAlign: 'center' } }, trans_1.trans('shop-registration.second-step.description')))),
            React.createElement(semantic_ui_react_1.Grid.Row, { className: 'py-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: function () { return _this.handleSelect('unas'); }, content: 'Unas' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { className: 'py-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: function () { return _this.handleSelect('shopRenter'); }, content: 'Shoprenter' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { className: 'py-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: function () { return _this.handleSelect('wooCommerce'); }, content: 'WooCommerce' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { className: 'py-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, onClick: function () { return _this.handleSelect('shopifyPlus'); }, content: 'Shopify Plus' }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { className: 'pt-7' },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderBox())),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'center' },
                    React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.handleCancel(); }, basic: true, id: 'continue-later', content: trans_1.trans('shop-registration.back-button.title') }))));
    };
    ShopRegistrationSecondStepComponent.prototype.renderBox = function () {
        return React.createElement(semantic_ui_react_1.Message, { className: 'info-message' },
            React.createElement(semantic_ui_react_1.Message.Header, null,
                React.createElement(semantic_ui_react_1.Header, { as: 'h3', textAlign: 'left', className: 'mb-4' }, trans_1.trans('shop-registration.second-step.emailBox.title'))),
            React.createElement(semantic_ui_react_1.Message.Content, { className: 'mb-4' }, trans_1.trans('shop-registration.second-step.emailBox.description')),
            React.createElement("div", { className: 'display-flex justify-content-center' },
                React.createElement(semantic_ui_react_1.Button, { as: 'a', href: 'mailto:help@miclub.hu', basic: true, icon: 'envelope', content: trans_1.trans('shop-registration.second-step.emailBox.button') })));
    };
    ShopRegistrationSecondStepComponent.prototype.handleSelect = function (type) {
        if (this.props.onSelect) {
            return this.props.onSelect(type);
        }
    };
    ShopRegistrationSecondStepComponent.prototype.handleCancel = function () {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };
    return ShopRegistrationSecondStepComponent;
}(abstract_component_1.AbstractComponent));
exports.ShopRegistrationSecondStepComponent = ShopRegistrationSecondStepComponent;
