"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationControl = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var PaginationControl = (function (_super) {
    __extends(PaginationControl, _super);
    function PaginationControl() {
        var _a, _b, _c, _d, _e, _f;
        var _this = _super.apply(this, arguments) || this;
        _this.state = {
            limit: (_b = (_a = _this.props.control$.getQuery()) === null || _a === void 0 ? void 0 : _a._limit) !== null && _b !== void 0 ? _b : 0,
            page: (_d = (_c = _this.props.control$.getQuery()) === null || _c === void 0 ? void 0 : _c._page) !== null && _d !== void 0 ? _d : 0,
            total: (_f = (_e = _this.props.control$.getQuery()) === null || _e === void 0 ? void 0 : _e._total) !== null && _f !== void 0 ? _f : 0
        };
        return _this;
    }
    PaginationControl.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.$subscriptions.push(this.props.control$.response$.subscribe(function (response) { return _this.setState({
            limit: response.limit,
            page: response.page,
            total: response.total,
        }); }));
    };
    PaginationControl.prototype.render = function () {
        var columns = this.getResponsiveValue({
            mobile: 1,
            default: 2
        });
        return React.createElement(semantic_ui_react_1.Grid, { columns: columns },
            React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: "left" }, this.renderLimit()),
            React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'right' }, this.renderPagination()));
    };
    PaginationControl.prototype.renderPagination = function () {
        var _this = this;
        var totalPages = Math.ceil(this.state.total / this.state.limit) > 0 ? Math.ceil(this.state.total / this.state.limit) : 1;
        if (totalPages <= 1) {
            return null;
        }
        var options = this.getResponsiveValue({
            default: {
                boundaryRange: 1,
                siblingRange: 1,
            },
            mobile: {
                boundaryRange: 0,
                siblingRange: 0,
            }
        });
        return React.createElement(this.props.component, {
            totalPages: totalPages,
            activePage: this.state.page + 1,
            defaultActivePage: 1,
            boundaryRange: options.boundaryRange,
            siblingRange: options.siblingRange,
            showEllipsis: totalPages > 5,
            firstItem: { content: React.createElement(semantic_ui_react_1.Icon, { name: "angle double left" }), icon: true, disabled: this.state.page + 1 === 1 },
            lastItem: { content: React.createElement(semantic_ui_react_1.Icon, { name: "angle double right" }), icon: true, disabled: this.state.page + 1 === totalPages },
            prevItem: { content: React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }), icon: true, disabled: this.state.page + 1 === 1 },
            nextItem: { content: React.createElement(semantic_ui_react_1.Icon, { name: "angle right" }), icon: true, disabled: this.state.page + 1 === totalPages },
            onPageChange: function (e, d) { return _this.handlePageChange(e, d); },
        });
    };
    PaginationControl.prototype.renderLimit = function () {
        var _this = this;
        var fluid = this.getResponsiveValue({
            mobile: true,
            default: false
        });
        return React.createElement(this.props.limitComponent, {
            options: this.props.limitOptions,
            onChange: function (e, d) { return _this.handleLimitChange(e, d); },
            value: parseInt(this.state.limit, 10),
            fluid: fluid
        });
    };
    PaginationControl.prototype.handlePageChange = function (e, data) {
        this.props.control$.page(data.activePage - 1);
    };
    PaginationControl.prototype.handleLimitChange = function (e, data) {
        this.props.control$.limit(parseInt(data.value, 10));
    };
    return PaginationControl;
}(responsive_component_1.ResponsiveComponent));
exports.PaginationControl = PaginationControl;
