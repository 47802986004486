"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionStorage = exports.SESSION_STORAGE_KEYS = void 0;
exports.SESSION_STORAGE_KEYS = {
    SESSION_STORAGE_SHOPRENTER_DATA_KEY: 'ShoprenterDashboardData',
    SESSION_STORAGE_SELECTED_SHOP_KEY: 'SelectedShop',
};
var SessionStorage = (function () {
    function SessionStorage() {
    }
    SessionStorage.hasItem = function (key) {
        this.init();
        return window.sessionStorage.getItem(key) !== undefined;
    };
    SessionStorage.getItem = function (key) {
        this.init();
        var data = window.sessionStorage.getItem(key);
        try {
            return this.decode(data);
        }
        catch (e) {
            return null;
        }
    };
    SessionStorage.setItem = function (key, value) {
        this.init();
        var encoded = this.encode(value);
        window.sessionStorage.setItem(key, encoded);
    };
    SessionStorage.deleteItem = function (key) {
        this.init();
        window.sessionStorage.removeItem(key);
    };
    SessionStorage.init = function () {
        if (!window.sessionStorage) {
            throw new Error('UnsupportedFeatureLocalStorage');
        }
    };
    SessionStorage.decode = function (data) {
        var parsed = JSON.parse(data);
        if (!parsed) {
            throw new Error('UnableToParseData');
        }
        return parsed._d;
    };
    SessionStorage.encode = function (raw) {
        return JSON.stringify({ _d: raw });
    };
    return SessionStorage;
}());
exports.SessionStorage = SessionStorage;
