"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralSettingsComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./shop-settings.scss");
var session_storage_1 = require("../../../../libs/session.storage");
var status_history_component_1 = require("./status-history.component");
var EMAIL_ACTIVE_STATUS_KEY = "emailActive";
var MAILGUN_STATUS_STATUS_KEY = "mailgunStatus";
var GeneralSettingsComponent = (function (_super) {
    __extends(GeneralSettingsComponent, _super);
    function GeneralSettingsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: _this.props.shop,
            updateLoading: false,
            updateError: false,
            loading: false,
            statusChangeLoading: null
        };
        return _this;
    }
    GeneralSettingsComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!lodash_1.isEqual(this.props.shop, prevProps.shop)) {
            this.setState({ shop: this.props.shop });
        }
    };
    GeneralSettingsComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        return React.createElement("div", { className: 'BillingComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement("div", { className: 'ShopHeader display-flex align-items-center mb-4' },
                            React.createElement("div", { className: 'ShopLogo mr-4' },
                                React.createElement(semantic_ui_react_1.Image, { className: '', src: (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.logo })),
                            React.createElement("div", null,
                                React.createElement(semantic_ui_react_1.Header, null, (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.shop) === null || _d === void 0 ? void 0 : _d.title))))),
                React.createElement(semantic_ui_react_1.Grid.Row, null, this.renderGeneralData()),
                React.createElement(semantic_ui_react_1.Grid.Row, null, this.state.shop && this.renderStatusChangeButtons()),
                React.createElement(semantic_ui_react_1.Grid.Row, null, this.state.shop && React.createElement(status_history_component_1.StatusHistoryComponent, { shop: (_e = this.state) === null || _e === void 0 ? void 0 : _e.shop })),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement("div", { className: 'ui red message', style: { width: "fit-content" } },
                        React.createElement("h2", null, "Webshop t\u00F6rl\u00E9se"),
                        React.createElement("p", null,
                            "A T\u00F6rl\u00E9s gombra kattintva a rendszerb\u0151l elt\u00E1vol\u00EDt\u00E1sra ker\u00FCl a webshop.",
                            React.createElement("br", null),
                            "M\u00E9g van egy meger\u0151s\u00EDt\u00E9s, de ut\u00E1na a webshop minden adata elv\u00E9sz."),
                        React.createElement(semantic_ui_react_1.Button, { basic: true, color: "red", onClick: function () { return _this.deleteShop(); }, content: 'Törlés' })))));
    };
    GeneralSettingsComponent.prototype.renderStatusChangeButtons = function () {
        var _a, _b, _c, _d;
        return React.createElement(semantic_ui_react_1.Grid.Column, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'mb-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderStatusChangeButton('active'))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'mb-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderStatusChangeButton('suspended'))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'mb-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderStatusChangeButton('syncActive'))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'mb-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderStatusChangeButton('billingActive'))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'mb-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderEmaiActivelAndMailgunStatusChangeButton(((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b[EMAIL_ACTIVE_STATUS_KEY]) || false, ((_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.shop) === null || _d === void 0 ? void 0 : _d[MAILGUN_STATUS_STATUS_KEY]) || false))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'mb-2' },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderStatusChangeButton('eventActive'))));
    };
    GeneralSettingsComponent.prototype.renderStatusChangeButton = function (status) {
        var _this = this;
        var currentStatus = this.props.shop[status];
        return React.createElement("div", { className: 'display-flex flex-row align-items-center justify-content-start' },
            React.createElement(semantic_ui_react_1.Button, { loading: this.state.statusChangeLoading === status, disabled: this.state.statusChangeLoading === status, size: 'mini', color: currentStatus ? 'blue' : 'red', basic: true, icon: currentStatus ? 'check' : 'close', onClick: function () { return _this.toggleStatus(status); } }),
            React.createElement("strong", { className: 'ml-4' }, trans_1.trans("shop-status." + status)));
    };
    GeneralSettingsComponent.prototype.renderEmaiActivelAndMailgunStatusChangeButton = function (emailActive, mailgunStatus) {
        var _this = this;
        console.log('renderEmaiActivelAndMailgunStatusChangeButton', { emailActive: emailActive, mailgunStatus: mailgunStatus });
        var disableStatusChangeButton = mailgunStatus === false;
        var calculatedStatus;
        if (mailgunStatus === true) {
            calculatedStatus = emailActive;
        }
        else {
            calculatedStatus = false;
        }
        return React.createElement("div", { className: 'display-flex flex-row align-items-center justify-content-start' },
            React.createElement(semantic_ui_react_1.Button, { loading: this.state.statusChangeLoading === EMAIL_ACTIVE_STATUS_KEY, disabled: (this.state.statusChangeLoading === EMAIL_ACTIVE_STATUS_KEY) ||
                    disableStatusChangeButton, size: 'mini', color: calculatedStatus ? 'blue' : 'red', basic: true, icon: calculatedStatus ? 'check' : 'close', onClick: function () { return _this.toggleStatus(EMAIL_ACTIVE_STATUS_KEY); } }),
            React.createElement("strong", { className: 'ml-4' }, trans_1.trans("shop-status." + EMAIL_ACTIVE_STATUS_KEY)));
    };
    GeneralSettingsComponent.prototype.renderGeneralData = function () {
        var _a, _b;
        if (!this.state.shop) {
            return;
        }
        return React.createElement(semantic_ui_react_1.Grid.Column, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("span", null, "Regisztr\u00E1ci\u00F3 d\u00E1tuma:")),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("strong", null, moment((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.createdAt).format('YYYY.MM.DD. HH:mm')))),
            React.createElement(semantic_ui_react_1.Divider, { className: 'mx-0' }));
    };
    GeneralSettingsComponent.prototype.deleteShop = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!confirm('\nVIGYÁZAT!\n\nA webshop minden adata elvész!\n\nBiztosan töröljük?')) return [3, 2];
                        if (!confirm('Utolsó figyelmeztetés.\nBiztosan töröljük?')) return [3, 2];
                        return [4, this.shop.delete("/a/shop/" + ((_a = this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/" + ((_b = this.props.shop) === null || _b === void 0 ? void 0 : _b.integrationId) + "/remove")];
                    case 1:
                        _c.sent();
                        window.location.href = '/admin/shops';
                        _c.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    GeneralSettingsComponent.prototype.toggleStatus = function (status) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var current, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.setState({ statusChangeLoading: status });
                        current = this.props.shop[status];
                        return [4, this.shop.put("/a/shop/" + ((_a = this.props.shop) === null || _a === void 0 ? void 0 : _a._id) + "/set-status", {
                                data: {
                                    status: status,
                                    value: !current
                                }
                            })];
                    case 1:
                        _b.sent();
                        if (this.props.onUpdate) {
                            this.props.onUpdate();
                        }
                        this.setState({ statusChangeLoading: null });
                        return [3, 3];
                    case 2:
                        e_1 = _b.sent();
                        this.setState({ statusChangeLoading: null });
                        console.log(e_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    GeneralSettingsComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return GeneralSettingsComponent;
}(abstract_component_1.AbstractComponent));
exports.GeneralSettingsComponent = GeneralSettingsComponent;
