"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCardPaymentsPage = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var credit_card_settings_component_1 = require("./credit-card-settings.component");
var shop_payment_events_component_1 = require("../../../common/components/payments/shop-payment-events.component");
var invoices_with_populated_payments_component_1 = require("./invoices-with-populated-payments.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
require("./billing.component.scss");
var CreditCardPaymentsPage = (function (_super) {
    __extends(CreditCardPaymentsPage, _super);
    function CreditCardPaymentsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: session_storage_1.SessionStorage.getItem('SelectedShop'),
            loading: false,
            isCreditCardConfirmed: false
        };
        _this.panels = [{
                key: "panel-service",
                title: {
                    content: React.createElement("span", { style: {
                            fontSize: "1.2rem",
                            lineHeight: "18px",
                            fontWeight: 700,
                        } }, "Szolg\u00E1ltat\u00E1s"),
                },
                content: {
                    content: (React.createElement(semantic_ui_react_1.Message, { info: true, style: {
                            backgroundColor: '#EDF4FF',
                            boxShadow: 'none',
                            color: '#051D40'
                        } },
                        React.createElement("p", null, "A web\u00E1ruh\u00E1zra k\u00F6thet\u0151 M.I. szoftver\u00FCnk haszn\u00E1lati d\u00EDj\u00E1t bankk\u00E1rtya megad\u00E1s\u00E1t k\u00F6vet\u0151en aktiv\u00E1ljuk. A szolg\u00E1ltat\u00E1s az al\u00E1bbiakat tartalmazza:"),
                        React.createElement("ul", null,
                            React.createElement("li", null, "M.I. email marketinget automatiz\u00E1l\u00F3 szoftver haszn\u00E1lata teljes funkcionalit\u00E1ssal"),
                            React.createElement("li", null, "a szoftver eredm\u00E9nyes m\u0171k\u00F6d\u00E9s\u00E9hez kapcsol\u00F3d\u00F3 emailek kik\u00FCld\u00E9se (automata emailek \u00E9s egyedi emailek egyar\u00E1nt)"),
                            React.createElement("li", null,
                                "\u00DCgyf\u00E9lszolg\u00E1latot munkanapokon 9:00 - 16:00 k\u00F6z\u00F6tt, mely a  ",
                                React.createElement("a", { href: "mailto:help@miclub.hu" }, "help@miclub.hu"),
                                " email c\u00EDmen kereszt\u00FCl \u00E9rhet\u0151 el")))),
                },
            }, {
                key: "panel-fee-calculation",
                title: {
                    content: (React.createElement("span", { style: {
                            fontSize: "1.2rem",
                            lineHeight: "18px",
                            fontWeight: 700,
                        } }, "D\u00EDjsz\u00E1m\u00EDt\u00E1s, d\u00EDjfizet\u00E9s m\u00F3dja (meg\u00FAjul\u00F3 bankk\u00E1rty\u00E1s fizet\u00E9s)")),
                },
                content: {
                    content: (React.createElement(semantic_ui_react_1.Message, { info: true, style: {
                            backgroundColor: '#EDF4FF',
                            boxShadow: 'none',
                            color: '#051D40'
                        } },
                        React.createElement("p", null, "Az M.I. email marketinget automatiz\u00E1l\u00F3 szoftver d\u00EDj fizet\u00E9se 30 naponta meg\u00FAjul\u00F3 bankk\u00E1rty\u00E1s fizet\u00E9ssel t\u00F6rt\u00E9nik. A d\u00EDj minden esetben Eur alap\u00FA."),
                        React.createElement("p", { style: { fontWeight: 500 } }, "D\u00EDjsz\u00E1m\u00EDt\u00E1s:"),
                        React.createElement("p", null, "A d\u00EDj minden esetben 2 t\u00E9telb\u0151l \u00E1ll:"),
                        React.createElement("p", null,
                            React.createElement("b", null, "1: \u00DCzemeltet\u00E9si d\u00EDj:"),
                            " 127 Eur + \u00E1fa"),
                        React.createElement("p", null,
                            React.createElement("b", null, "2: Listakezel\u00E9si d\u00EDj"),
                            ", aminek alapja a d\u00EDjsz\u00E1m\u00EDt\u00E1s napj\u00E1n az M.I. rendszerben megtal\u00E1lhat\u00F3 h\u00EDrlev\u00E9l lista m\u00E9rete \u00E9s a s\u00E1vosan meghat\u00E1rozott listakezel\u00E9si d\u00EDj az al\u00E1bbiak szerint (a t\u00E1bl\u00E1zatban szerepl\u0151 d\u00EDjak nett\u00F3 d\u00EDjak, az \u00C1FA-t nem tartalmazz\u00E1k):"),
                        React.createElement(semantic_ui_react_1.Table, { celled: true, structured: true },
                            React.createElement(semantic_ui_react_1.TableHeader, null,
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "Lista m\u00E9rete"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "D\u00EDj felhaszn\u00E1l\u00F3nk\u00E9nt (Eur)"))),
                            React.createElement(semantic_ui_react_1.TableBody, null,
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0-3000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0405")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "3001 - 5000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0270")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "5001 - 10000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0259")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "10001 - 20000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0232")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "20001 - 35000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0189")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "35001 - 50000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0162")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "50001 - 75000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0151")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "75001 - 100000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0135")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "100001 - 150000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0108")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "150001 - 200000"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0097")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableCell, null, "200001+"),
                                    React.createElement(semantic_ui_react_1.TableCell, null, "0,0078")))),
                        React.createElement("p", null,
                            React.createElement("b", null, "Megjegyz\u00E9s:"),
                            " Szolg\u00E1ltat\u00F3 fenntartja jogot, hogy Felhaszn\u00E1l\u00F3val egyedi meg\u00E1llapod\u00E1s keret\u00E9ben extra d\u00EDjkedvezm\u00E9nyeket biztos\u00EDtson, melyet egyedi meg\u00E1llapod\u00E1sban r\u00F6gz\u00EDtenek. Ilyen felt\u00E9tel eset\u00E9n a fenti d\u00EDjakn\u00E1l alacsonyabb d\u00EDjszab\u00E1s alkalmazhat\u00F3. A kedvezm\u00E9ny m\u00E9rt\u00E9k\u00E9t a terhel\u00E9shez tartoz\u00F3 sz\u00E1mla tartalmazza."),
                        React.createElement("p", { style: { fontWeight: 500 } }, "D\u00EDjfizet\u00E9s m\u00F3dja (meg\u00FAjul\u00F3 bankk\u00E1rty\u00E1s fizet\u00E9s)"),
                        React.createElement("p", null, "A bankk\u00E1rtya terhel\u00E9se 30 naponta Ft-ban t\u00F6rt\u00E9nik, ahol a forint \u00E9rt\u00E9k a d\u00EDjfizet\u00E9s napj\u00E1n \u00E9rv\u00E9nyes MNB Eur/Huf k\u00F6z\u00E9p\u00E1rfolyam szerint ker\u00FCl \u00E1tv\u00E1lt\u00E1sra."),
                        React.createElement("p", null, "A bankk\u00E1rtya megad\u00E1s\u00E1val Felhaszn\u00E1l\u00F3 hozz\u00E1j\u00E1rul ahhoz, hogy Szolg\u00E1ltat\u00F3 a Szolg\u00E1ltat\u00E1s d\u00EDj\u00E1t levonhassa Felhaszn\u00E1l\u00F3 \u00E1ltal megadott bankk\u00E1rty\u00E1r\u00F3l (meg\u00FAjul\u00F3 fizet\u00E9s)."),
                        React.createElement("p", null, "Felhaszn\u00E1l\u00F3 bankk\u00E1rty\u00E1j\u00E1nak meg\u00FAjul\u00F3 terhel\u00E9shez val\u00F3 hozz\u00E1j\u00E1rul\u00E1s\u00E1t b\u00E1rmikor visszavonhatja a bankk\u00E1rtya t\u00F6rl\u00E9s\u00E9vel."))),
                },
            }, {
                key: "panel-card-registration",
                title: {
                    content: React.createElement("span", { style: {
                            fontSize: "1.2rem",
                            lineHeight: "18px",
                            fontWeight: 700,
                        } }, "Bankk\u00E1rtya megad\u00E1sa, els\u0151 d\u00EDjfizet\u00E9s"),
                },
                content: {
                    content: (React.createElement(semantic_ui_react_1.Message, { info: true, style: {
                            backgroundColor: '#EDF4FF',
                            boxShadow: 'none',
                            color: '#051D40'
                        } },
                        React.createElement("p", null, "A \u201CBankk\u00E1rtya megad\u00E1sa\u201D gombra kattintva enged\u00E9lyezni tudja Felhaszn\u00E1l\u00F3 a k\u00E1rty\u00E1s fizet\u00E9s lehet\u0151s\u00E9g\u00E9t. A kattint\u00E1s ut\u00E1n Felhaszn\u00E1l\u00F3 a Barion k\u00E1rtyaregisztr\u00E1ci\u00F3s fel\u00FClet\u00E9re \u00E9rkezik, ahol a bankk\u00E1rtya adatainak megad\u00E1s\u00E1t k\u00F6vet\u0151en a Bankk\u00E1rtya ment\u00E9se gombra kattintva az alkalmaz\u00E1s ind\u00EDt egy 100 Ft-s tranzakci\u00F3t, amivel a bankk\u00E1rtya pr\u00F3baterhel\u00E9se t\u00F6rt\u00E9nik. A 100 forintos tranzakci\u00F3t Felhaszn\u00E1l\u00F3nak er\u0151s \u00FCgyf\u00E9lhiteles\u00EDt\u00E9ssel j\u00F3v\u00E1 kell hagynia a bankj\u00E1n\u00E1l meghat\u00E1rozott m\u00F3don: a banki alkalmaz\u00E1sban \u00E9s/vagy SMS/telek\u00F3d megad\u00E1s\u00E1val."),
                        React.createElement("p", null, "Amennyiben sikeres a j\u00F3v\u00E1hagy\u00E1s \u00E9s a tranzakci\u00F3, a bankk\u00E1rtya elment\u00E9sre ker\u00FCl az alkalmaz\u00E1sban, a sikeres k\u00E1rtyar\u00F6gz\u00EDt\u00E9s t\u00E9nye pedig az M.I. rendszerben. (Az M.I. rendszere nem t\u00E1rol \u00E9s nem haszn\u00E1l bankk\u00E1rtya inform\u00E1ci\u00F3kat!)"),
                        React.createElement("p", null, "A 100 Ft-os pr\u00F3baterhel\u00E9s \u00F6sszege automatikusan visszat\u00E9r\u00EDt\u00E9sre ker\u00FCl a sz\u00E1ml\u00E1ra - ennek \u00E1tfut\u00E1si ideje 1-2 munkanapot ig\u00E9nybe vehet a bank gyakorlat\u00E1t\u00F3l f\u00FCgg\u0151en."))),
                },
            }, {
                key: "panel-cancellation",
                title: {
                    content: React.createElement("span", { style: {
                            fontSize: "1.2rem",
                            lineHeight: "18px",
                            fontWeight: 700,
                        } }, "Szolg\u00E1ltat\u00E1s lemond\u00E1sa, bankk\u00E1rtya t\u00F6rl\u00E9se"),
                },
                content: {
                    content: (React.createElement(semantic_ui_react_1.Message, { info: true, style: {
                            backgroundColor: '#EDF4FF',
                            boxShadow: 'none',
                            color: '#051D40'
                        } },
                        React.createElement("p", null, "Felhaszn\u00E1l\u00F3 jogosult b\u00E1rmikor a szolg\u00E1ltat\u00E1st lemondani a bankk\u00E1rtya adatok t\u00F6rl\u00E9s\u00E9vel. Ezt a \u201Cbankk\u00E1rtya adatok t\u00F6rl\u00E9se\u201D gombra kattintva tudja megtenni, ahol felugr\u00F3 ablakban meg kell er\u0151s\u00EDtenie a t\u00F6rl\u00E9si sz\u00E1nd\u00E9k\u00E1t."),
                        React.createElement("p", null, "Ezt k\u00F6vet\u0151en Szolg\u00E1ltat\u00F3 tov\u00E1bbi d\u00EDjakat nem von le Felhaszn\u00E1l\u00F3 bankk\u00E1rty\u00E1j\u00E1r\u00F3l \u00E9s az ide kapcsol\u00F3d\u00F3 adatokat t\u00F6rli. Ezt k\u00F6vet\u0151en az \u00C1SZF-ben foglaltaknak megfelel\u0151en Szolg\u00E1ltat\u00F3 a szolg\u00E1ltat\u00E1st korl\u00E1tozza, majd a fi\u00F3kot t\u00F6rli."))),
                },
            }, {
                key: "panel-events",
                title: {
                    content: React.createElement("span", { style: {
                            fontSize: "1.2rem",
                            lineHeight: "18px",
                            fontWeight: 700,
                        } }, "Esem\u00E9nyek"),
                },
                content: {
                    content: (React.createElement(shop_payment_events_component_1.ShopPaymentEvents, { shopId: _this.state.shop._id.toString(), ref: function (ref) { return _this.shopPaymentEventsRef = ref; } })),
                },
            }, {
                key: "panel-invoices",
                title: {
                    content: React.createElement("span", { style: {
                            fontSize: "1.2rem",
                            lineHeight: "18px",
                            fontWeight: 700,
                        } }, "Sz\u00E1ml\u00E1k, bankk\u00E1rty\u00E1s fizet\u00E9sek"),
                },
                content: {
                    content: (React.createElement(semantic_ui_react_1.Table, { celled: true, structured: true },
                        React.createElement(semantic_ui_react_1.TableHeader, null,
                            React.createElement(semantic_ui_react_1.TableRow, null,
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { colSpan: '4', textAlign: 'center' }, "Sz\u00E1mla"),
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { colSpan: '3', textAlign: 'center' }, "Bankk\u00E1rty\u00E1s fizet\u00E9sek")),
                            React.createElement(semantic_ui_react_1.TableRow, null,
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "Id\u0151pont"),
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "Tartalom"),
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "\u00C1llapot"),
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "Let\u00F6lt\u00E9s"),
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "Id\u0151pont"),
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "St\u00E1tusz"),
                                React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: 'center' }, "Megjegyz\u00E9s"))),
                        React.createElement(semantic_ui_react_1.TableBody, null, _this.renderCreditCardPayments()))),
                },
            }];
        return _this;
    }
    CreditCardPaymentsPage.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    CreditCardPaymentsPage.prototype.renderCreditCardPayments = function () {
        return React.createElement(invoices_with_populated_payments_component_1.InvoicesWithPopulatedPayments, { shopId: this.state.shop._id.toString() });
    };
    CreditCardPaymentsPage.prototype.componentDidMount = function () {
        var _a;
        var source = (_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.source;
        var isSRAppShop = (source === "shoprenterOnDemand" || source === "shoprenterOnDemandChanged") || false;
        if (isSRAppShop) {
            console.log('CreditCardPaymentsPage componentDidMount shop is SRAPP, navigate to /dashboard page');
            router_provider_1.RouterProvider.goTo("/dashboard");
        }
    };
    CreditCardPaymentsPage.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'BillingComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'mt-4' },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Bankk\u00E1rty\u00E1s fizet\u00E9s"))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 7 },
                        React.createElement(credit_card_settings_component_1.CreditCardPaymentSettings, { onSuccessfulUpdate: function () {
                                if (_this.shopPaymentEventsRef) {
                                    _this.shopPaymentEventsRef.fetchPaymentEventsByShopId();
                                }
                            } })),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 7 },
                        "Fizet\u00E9si szolg\u00E1ltat\u00F3k",
                        React.createElement("br", null),
                        React.createElement("img", { src: "/assets/logo/barion-card-strip-intl__small.png", alt: "Barion log\u00F3" }),
                        React.createElement("br", null),
                        React.createElement("img", { src: "/assets/logo/pmgw-logo-narrow-black.png", alt: "BigFish Payment Gateway log\u00F3", height: "100px" }),
                        React.createElement("p", null, "Az online bankk\u00E1rty\u00E1s fizet\u00E9sek a Barion rendszer\u00E9n kereszt\u00FCl val\u00F3sulnak meg. A bankk\u00E1rtya adatok a keresked\u0151h\u00F6z nem jutnak el. A szolg\u00E1ltat\u00E1st ny\u00FAjt\u00F3 Barion Payment Zrt. a Magyar Nemzeti Bank fel\u00FCgyelete alatt \u00E1ll\u00F3 int\u00E9zm\u00E9ny, enged\u00E9ly\u00E9nek sz\u00E1ma: H-EN-I-1064/2013."))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Accordion, { panels: this.panels, exclusive: false, fluid: true })))));
    };
    return CreditCardPaymentsPage;
}(abstract_component_1.AbstractComponent));
exports.CreditCardPaymentsPage = CreditCardPaymentsPage;
