"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersListComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var observable_control_1 = require("@codebuild/uikit/libs/observable-control");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var DatePicker = require("react-datepicker");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var session_storage_1 = require("../../../../libs/session.storage");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var sort_header_1 = require("../../../common/components/sort-header/sort-header");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var number_parser_1 = require("../../../common/libs/number-parser");
var sensitive_data_1 = require("../../../common/libs/sensitive-data");
var order_stats_component_1 = require("./order-stats.component");
require("./orders-list.component.scss");
var OrdersListComponent = (function (_super) {
    __extends(OrdersListComponent, _super);
    function OrdersListComponent() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var _this = _super.apply(this, arguments) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                query: new field_1.Field({
                    name: 'query',
                    label: '',
                    value: '',
                    placeholder: trans_1.trans('webshop-data.order-list.search.placeholder')
                }),
                from: new field_1.Field({
                    name: 'from',
                    label: '',
                    value: '',
                }),
                to: new field_1.Field({
                    name: 'to',
                    label: '',
                    value: '',
                }),
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 5,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query.query) !== null && _e !== void 0 ? _e : '',
                from: (_g = (_f = _this.props.route) === null || _f === void 0 ? void 0 : _f.query.from) !== null && _g !== void 0 ? _g : '',
                to: (_j = (_h = _this.props.route) === null || _h === void 0 ? void 0 : _h.query.to) !== null && _j !== void 0 ? _j : '',
                sort: { dataCreatedAt: -1 }
            }
        });
        _this.state = __assign(__assign({}, _this.state), { openedCategories: [], isModalOpen: false, isLoading: false, orderStats: [] });
        return _this;
    }
    OrdersListComponent.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.form.fields.query.$value
            .pipe(operators_1.debounceTime(450))
            .subscribe(function (value) {
            _this.control.set('query', value);
            _this.control.set('page', 0);
        });
        this.form.fields.from.$value
            .subscribe(function (value) { return _this.control.set('from', moment(value).startOf('day').toDate()); });
        this.form.fields.to.$value
            .subscribe(function (value) { return _this.control.set('to', moment(value).endOf('day').toDate()); });
    };
    OrdersListComponent.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: "OrderListComponent ListComponent" },
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, trans_1.trans('webshop-data.order-list.screen.title')),
                        React.createElement(observable_control_1.ObservableControl, { "$observable": this.control.response$, transform: function (value) { return ({
                                children: (!value ? '' : React.createElement(semantic_ui_react_1.Statistic, { horizontal: true, size: 'tiny', label: trans_1.trans('webshop-data.order-list.screen.orders-count.label'), value: number_parser_1.splitToNumberString(value === null || value === void 0 ? void 0 : value.total) }))
                            }); } },
                            React.createElement("div", null))),
                    React.createElement(semantic_ui_react_1.Grid.Column, { className: "date-picker-wrapper" },
                        React.createElement("span", { className: 'header-filter-label' }, "D\u00E1tum sz\u0171r\u00E9s"),
                        this.renderHeaderFilters()))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/order", renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 7 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 7 })); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                    { text: 1, value: 1 },
                    { text: 10, value: 10 },
                    { text: 25, value: 25 },
                    { text: 50, value: 50 },
                    { text: 100, value: 100 },
                ] }),
            React.createElement(semantic_ui_react_1.Modal, { closeIcon: true, open: this.state.isModalOpen, centered: true, size: "large", onOpen: function () { return _this.setState({ isModalOpen: true }); }, onClose: function () { return _this.setState({ isModalOpen: false }); }, as: semantic_ui_react_1.Form, closeOnEscape: true, closeOnDimmerClick: true },
                React.createElement(order_stats_component_1.OrderStatsModal, { isLoading: this.props.isLoading, orderStats: this.state.orderStats })));
    };
    OrdersListComponent.prototype.renderHeaderFilters = function () {
        var _this = this;
        var _a;
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'ListHeader' },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement(semantic_ui_react_1.Form, { className: 'display-flex' },
                    React.createElement("div", { className: "date-wrapper" },
                        React.createElement("div", { className: 'position-relative display-flex' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                            React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })),
                        React.createElement("div", { className: 'date-picker-divider' }),
                        React.createElement("div", { className: 'position-relative display-flex mr-4' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                            React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' }))),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.query, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { icon: 'search', control: semantic_ui_react_1.Input, className: 'w-100' })),
                    React.createElement("div", { className: 'ml-4' },
                        React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.setState({ isModalOpen: true }); }, disabled: ((_a = this.state.orderStats) === null || _a === void 0 ? void 0 : _a.length) === 0, loading: this.state.isLoading }, trans_1.trans('webshop-data.order-list.screen.order-stats.button.label'))))));
    };
    OrdersListComponent.prototype.renderItemsWrapper = function (ctx) {
        var _a, _b;
        if ((_b = (_a = ctx === null || ctx === void 0 ? void 0 : ctx.state) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.summary) {
            var summary = ctx.state.response.summary;
            var summaryString = JSON.stringify(summary);
            if (JSON.stringify(this.state.orderStats) !== summaryString) {
                this.setState({ orderStats: summary });
            }
        }
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, sortable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' }, trans_1.trans('webshop-data.order-list.item.identifier.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                        React.createElement(sort_header_1.SortHeader, { title: trans_1.trans('webshop-data.order-list.item.name.label'), property: 'dataCustomerLastName', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' }, trans_1.trans('webshop-data.order-list.item.product-count.label')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: trans_1.trans('webshop-data.order-list.item.sum-expense.label'), property: 'customerTotalSpent', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: trans_1.trans('webshop-data.order-list.item.order-price.label'), property: 'dataTotalGrossPrice', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: trans_1.trans('webshop-data.order-list.item.order-date.label'), property: 'dataCreatedAt', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { collapsing: true, textAlign: 'center' },
                        React.createElement(sort_header_1.SortHeader, { title: "M.I.-hez k\u00F6thet\u0151?", property: 'relatedToMILevel', "control$": this.control })))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    OrdersListComponent.prototype.renderItem = function (ctx, item, index, level) {
        var _a, _b, _c, _d;
        if (level === void 0) { level = 0; }
        return [
            React.createElement(semantic_ui_react_1.Table.Row, { key: item._id + "-0", verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, "#" + ((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.identifier)),
                React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderCustomerData(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderProductCount(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, currency_parser_1.hufFormat((_b = item === null || item === void 0 ? void 0 : item.statistics) === null || _b === void 0 ? void 0 : _b.customerTotalSpent)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, currency_parser_1.hufFormat((_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.totalGrossPrice)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, moment((_d = item === null || item === void 0 ? void 0 : item.data) === null || _d === void 0 ? void 0 : _d.createdAt).format('YYYY.MM.DD HH:mm')),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderRelatedToMiLevel(item === null || item === void 0 ? void 0 : item.relatedToMILevel))),
        ];
    };
    OrdersListComponent.prototype.renderRelatedToMiLevel = function (level) {
        switch (level) {
            case 3:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "green" }, "Bizony\u00EDthat\u00F3an");
            case 2:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "blue" }, "Er\u0151sen");
            case 1:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "yellow" }, "Feltehet\u0151en");
            case 0:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "grey" }, "Nem k\u00F6thet\u0151");
            default:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "grey" }, "Nem k\u00F6thet\u0151");
        }
    };
    OrdersListComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    OrdersListComponent.prototype.renderCustomerData = function (item) {
        var _a, _b, _c, _d, _e, _f, _g;
        var hasUser = !!((_b = (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b._id);
        var detailsPath = "/leads/customer/" + ((_d = (_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.customer) === null || _d === void 0 ? void 0 : _d._id);
        return React.createElement("div", { className: "w-100 display-inline-flex " + (hasUser ? 'cursor-pointer' : ''), onClick: function () { return hasUser && router_provider_1.RouterProvider.goTo(detailsPath); } },
            React.createElement("div", { className: 'display-flex flex-column' },
                React.createElement("span", null,
                    sensitive_data_1.SensitiveData.customerName((_e = item === null || item === void 0 ? void 0 : item.data) === null || _e === void 0 ? void 0 : _e.customerLastName),
                    " ",
                    sensitive_data_1.SensitiveData.customerName((_f = item === null || item === void 0 ? void 0 : item.data) === null || _f === void 0 ? void 0 : _f.customerFirstName)),
                React.createElement("i", { className: '' }, sensitive_data_1.SensitiveData.customerEmail((_g = item === null || item === void 0 ? void 0 : item.data) === null || _g === void 0 ? void 0 : _g.customerEmail))));
    };
    OrdersListComponent.prototype.renderProductCount = function (item) {
        var _a;
        var count = lodash_1.sumBy((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.items, 'amount');
        return React.createElement("span", null,
            count,
            " db");
    };
    OrdersListComponent.prototype.renderLoader = function () {
        if (!this.props.routeLoading) {
            return null;
        }
        return React.createElement("div", { className: 'Loader' },
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }));
    };
    return OrdersListComponent;
}(responsive_component_1.ResponsiveComponent));
exports.OrdersListComponent = OrdersListComponent;
