"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
module.exports = {
    'shop-registration.start-step.title': 'Sikeres regisztráció!',
    'shop-registration.start-step.description': 'Szuper! Örülünk, hogy itt vagy! Mielőtt hátradőlnél a rendszer használatához szükséges néhány lépés. Először be kell regisztrálni a webáruházad, ezután mi áttöltjük a webáruház adataidat és már meg is vagyunk!',
    'shop-registration.start-button.title': 'Rendben, kezdjük!',
    'shop-registration.first-step.title': 'Webáruház profil elkészítése',
    'shop-registration.first-step.description': 'Ahhoz, hogy az integrációt megkezdjük, szükségünk van néhány adatra a webáruházaddal kapcsolatban. Ezeket az adatokat később a Beállítások menüpontban módosíthatod.',
    'shop-registration.cancel-button.title': 'Később folytatom',
    'shop-registration.back-button.title': 'Vissza',
    'shop-registration.next-button.title': 'Tovább az összekötéshez',
    'shop-registration.second-step.title': 'Webáruház típus kiválasztása',
    'shop-registration.second-step.description': 'Válaszd ki a webáruházad típusát az integráció folytatásához!',
    'shop-registration.second-step.emailBox.button': 'Emailt küldök',
    'shop-registration.second-step.emailBox.title': 'Ha nem találod...',
    'shop-registration.second-step.emailBox.description': React.createElement("p", null,
        "Ha nem tal\u00E1lod a web\u00E1ruh\u00E1zat t\u00EDpus\u00E1t, semmi gond! Vedd fel vel\u00FCnk a kapcsolatot a ",
        React.createElement("span", null, "help@miclub.hu"),
        " email c\u00EDmen \u00E9s megoldjuk a probl\u00E9m\u00E1t! "),
    'shop-registration.first-step.form.subtitle.base-data': 'Webáruház alapadatok',
    'shop-registration.first-step.form.subtitle.shop-style': 'Opcionálisan megadható adatok',
    'shop-registration.first-step.form.title.label': 'Webáruház neve',
    'shop-registration.first-step.form.phone.label': 'Webáruház telefonszáma',
    'shop-registration.first-step.form.email.label': 'Webáruház email címe',
    'shop-registration.first-step.form.title.placeholder': 'Emag',
    'shop-registration.first-step.form.url.label': 'Webáruház domain',
    'shop-registration.first-step.form.url.placeholder': 'emag.hu',
    'shop-registration.first-step.form.themeColor.label': 'Témaszín',
    'shop-registration.first-step.form.themeColor.placeholder': 'Válassz színt',
    'shop-registration.first-step.form.logo.label': 'Webáruház logója',
    'shop-registration.first-step.form.logo.placeholder': '',
    'shop-registration.first-step.form.industry.label': 'Webáruház iparága/tevékenységi köre',
    'shop-registration.first-step.form.industry.placeholder': 'Válassz iparágat --- például: Elektronika',
    'shop-registration.integration.woocommerce.title': 'Woocommerce integráció',
    'shop-registration.integration.woocommerce.description.title': 'Webáruház összekötése az M.I. API-val',
    'shop-registration.integration.woocommerce.description.subtitle': 'Ez ahhoz szükséges, hogy Digitális Asszisztensünk elemezhesse az adatokat. Ez a lépés mindenféleképpen szükséges a teljeskörű használhathoz.',
    'shop-registration.integration.woocommerce.description.step1': 'Lépj be a webáruházad adminisztrációs felületére. link',
    'shop-registration.integration.woocommerce.description.step2': 'Bejelentkezés után navigálj el az REST API oldalra. Woocommerce -> Beállítások -> Haladó -> REST API',
    'shop-registration.integration.woocommerce.description.step3': 'Hozz létre egy új API kulcsot úgy, hogy olvasási és írási jogot választasz ki.',
    'shop-registration.integration.woocommerce.description.step4': 'A létrejött Felhasználói kulcsot és Felhasználói titkos kulcsot másold be a lenti bevitelimezőkbe.',
    'shop-registration.integration.woocommerce.cancel-button.title': 'Másik integrációt választok',
    'shop-registration.integration.woocommerce.start-button.title': 'Tovább az események összekötéséhe',
    'shop-registration.integration.woocommerce-analytic.title': 'Woocommerce integráció',
    'shop-registration.integration.woocommerce-analytic.description.title': 'Analitika script elhelyezése az webáruház weboldalába',
    'shop-registration.integration.woocommerce-analytic.description.subtitle': 'Az analatika script segítségével olyan analitikai adatokat gyűjtünk, melyek segítségével többek között fel tudjuk építeni látogatóid profilját, hasznos tippeket tudunk adni, hogyan növelheted webáruházad teljesítményét és még nagyok sok minden mást is :). A teljes M.I. élményért elengedhetetlen lépés. ',
    'shop-registration.integration.woocommerce-analytic.description.step1': 'Másold ki a scriptet a lenti mezőből.',
    'shop-registration.integration.woocommerce-analytic.description.step2': 'Webáruházad adminisztrációs felületén belül navigálj el az Egyedi scriptek oldalra: Megjelenés -> Téma testreszabása-> Egyedi scriptekI',
    'shop-registration.integration.woocommerce-analytic.description.step3': 'Hozz létre egy új API kulcsot úgy, hogy olvasási és írási jogot választasz ki.',
    'shop-registration.integration.woocommerce-analytic.description.step4': 'Illeszd be a kimásolt scriptet a HEAD részbe',
    'shop-registration.integration.woocommerce-analytic.start-button.title': 'Tovább az adatok importálásához!',
    'shop-registration.integration.woocommerce-analytics.copy-button.title': 'script másolása',
    'shop-registration.integration.woocommerce.consumerKey.label': 'Felhasználó kulcs',
    'shop-registration.integration.woocommerce.consumerKey.placeholder': '',
    'shop-registration.integration.woocommerce.consumerSecret.label': 'Felhasználó titkos kulcs',
    'shop-registration.integration.woocommerce.consumerSecret.placeholder': '',
    'shop-registration.integration.woocommerce.origins.placeholder': '',
    'shop-registration.integration.woocommerce.origins.label': 'Engedélyezett domain',
    'shop-registration.integration.shoprenter.title': 'Shoprenter integráció',
    'shop-registration.integration.shoprenter.description.title': 'Webáruház összekötése az M.I. API-val',
    'shop-registration.integration.shoprenter.description.subtitle': 'Ez ahhoz szükséges, hogy Digitális Asszisztensünk elemezhesse az adatokat. Ez a lépés mindenféleképpen szükséges a teljeskörű használhathoz.',
    'shop-registration.integration.shoprenter.description.step1': React.createElement("p", null,
        "L\u00E9pj be a web\u00E1ruh\u00E1zad adminisztr\u00E1ci\u00F3s fel\u00FClet\u00E9re. ",
        React.createElement("span", null, "https://shoprenter/belepes")),
    'shop-registration.integration.shoprenter.description.step2': 'Bejelentkezés után navigálj el a Beállítások -> API beállítások oldalra.',
    'shop-registration.integration.shoprenter.description.step3': 'Hozz létre egy új API kulcsot úgy, hogy a státusz engedélyezett legyen és a kulcsot pedig nevezd el MISys-nek.',
    'shop-registration.integration.shoprenter.description.step4': 'A létrejött API kulcsot másold ki és alul illeszd be az API kulcs beviteli mezőbe és add meg a felhasználónevedet is.',
    'shop-registration.integration.shoprenter.cancel-button.title': 'Másik integrációt választok',
    'shop-registration.integration.shoprenter.start-button.title': 'Tovább az események összekötéséhez',
    'shop-registration.integration.shoprenter-analytic.title': 'Shoprenter integráció',
    'shop-registration.integration.shoprenter-analytic.description.title': 'Analitika script elhelyezése az webáruház weboldalába',
    'shop-registration.integration.shoprenter-analytic.description.subtitle': 'Az analatika script segítségével olyan analitikai adatokat gyűjtünk, melyek segítségével többek között fel tudjuk építeni látogatóid profilját, hasznos tippeket tudunk adni, hogyan növelheted webáruházad teljesítményét és még nagyok sok minden mást is :). A teljes M.I. élményért elengedhetetlen lépés. ',
    'shop-registration.integration.shoprenter-analytic.description.step1': 'Másold ki a scriptet a lenti mezőből.',
    'shop-registration.integration.shoprenter-analytic.description.step2': 'Webáruházad adminisztrációs felületén belül navigálj el az Egyedi scriptek oldalra: Megjelenés -> Téma testreszabása-> Egyedi scriptekI',
    'shop-registration.integration.shoprenter-analytic.description.step3': 'Hozz létre egy új API kulcsot úgy, hogy olvasási és írási jogot választasz ki.',
    'shop-registration.integration.shoprenter-analytic.description.step4': 'Illeszd be a kimásolt scriptet a HEAD részbe',
    'shop-registration.integration.shoprenter-analytic.start-button.title': 'Tovább az adatok importálásához!',
    'shop-registration.integration.shoprenter-analytics.copy-button.title': 'script másolása',
    'shop-registration.integration.shoprenter.username.label': 'Felhasználó név',
    'shop-registration.integration.shoprenter.username.placeholder': '',
    'shop-registration.integration.shoprenter.password.label': 'Jelszó',
    'shop-registration.integration.shoprenter.password.placeholder': '',
    'shop-registration.integration.shoprenter.appUrl.label': 'App url',
    'shop-registration.integration.shoprenter.appUrl.placeholder': '',
    'shop-registration.integration.shoprenter.url.label': 'Shoprenter API URL',
    'shop-registration.integration.shoprenter.url.placeholder': '',
    'shop-registration.integration.shoprenter.origins.placeholder': '',
    'shop-registration.integration.shoprenter.origins.label': 'Engedélyezett domain',
    'shop-registration.continue-later.title': 'Semmi gond, de mielőtt elmész ...',
    'shop-registration.continue-later.description': 'A felhasználód már létrehozásra került, viszont mivel nem kötötted be a webáruházadat az M.I. rendszere még nem áll rendelkezésedre. A folyamatot bármikor végigviheted, de ne késlekedj sokat, hogy minél előbb segíthessünk 🙂',
    'shop-registration.continue-later.button.cancel': 'Mégse',
    'shop-registration.continue-later.button.logout': 'Kijelentkezés',
    'shop-registration.integration.unas.title': 'Unas integráció',
    'shop-registration.integration.unas.description.title': 'Webáruház összekötése az M.I. API-val',
    'shop-registration.integration.unas.description.subtitle': 'Ez ahhoz szükséges, hogy Digitális Asszisztensünk elemezhesse az adatokat. Ez a lépés mindenféleképpen szükséges a teljeskörű használhathoz.',
    'shop-registration.integration.unas.description.step1': React.createElement("p", null, "L\u00E9pj be a web\u00E1ruh\u00E1zad adminisztr\u00E1ci\u00F3s fel\u00FClet\u00E9re."),
    'shop-registration.integration.unas.description.step2': 'Bejelentkezés után navigálj el a Beállítások -> API beállítások oldalra.',
    'shop-registration.integration.unas.description.step3': 'Hozz létre egy új API kulcsot úgy, hogy a státusz engedélyezett legyen és a kulcsot pedig nevezd el MISys-nek.',
    'shop-registration.integration.unas.description.step4': 'A létrejött API kulcsot másold ki és alul illeszd be az API kulcs beviteli mezőbe és add meg a felhasználónevedet is.',
    'shop-registration.integration.unas.cancel-button.title': 'Másik integrációt választok',
    'shop-registration.integration.unas.start-button.title': 'Tovább az események összekötéséhez',
    'shop-registration.integration.unas-analytic.title': 'Unas integráció',
    'shop-registration.integration.unas-analytic.description.title': 'Analitika script elhelyezése az webáruház weboldalába',
    'shop-registration.integration.unas-analytic.description.subtitle': 'Az analatika script segítségével olyan analitikai adatokat gyűjtünk, melyek segítségével többek között fel tudjuk építeni látogatóid profilját, hasznos tippeket tudunk adni, hogyan növelheted webáruházad teljesítményét és még nagyok sok minden mást is :). A teljes M.I. élményért elengedhetetlen lépés. ',
    'shop-registration.integration.unas-analytic.description.step1': 'Másold ki a scriptet a lenti mezőből.',
    'shop-registration.integration.unas-analytic.description.step2': 'Webáruházad adminisztrációs felületén belül navigálj el az Egyedi scriptek oldalra: Megjelenés -> Téma testreszabása-> Egyedi scriptekI',
    'shop-registration.integration.unas-analytic.description.step3': 'Hozz létre egy új API kulcsot úgy, hogy olvasási és írási jogot választasz ki.',
    'shop-registration.integration.unas-analytic.description.step4': 'Illeszd be a kimásolt scriptet a HEAD részbe',
    'shop-registration.integration.unas-analytic.start-button.title': 'Tovább az adatok importálásához!',
    'shop-registration.integration.unas-analytics.copy-button.title': 'script másolása',
    'shop-registration.integration.unas.username.label': 'Felhasználó név',
    'shop-registration.integration.unas.username.placeholder': '',
    'shop-registration.integration.unas.apiKey.label': 'API kulcs',
    'shop-registration.integration.unas.apiKey.placeholder': '',
    'shop-registration.integration.unas.appUrl.label': 'App url',
    'shop-registration.integration.unas.appUrl.placeholder': '',
    'shop-registration.integration.unas.url.label': 'URL',
    'shop-registration.integration.unas.url.placeholder': '',
    'shop-registration.integration.unas.origins.placeholder': '',
    'shop-registration.integration.unas.origins.label': 'Engedélyezett domain',
    'shop-registration.integration-success.title': 'Gratulálunk, az adatok áttöltése sikeres! ',
    'shop-registration.integration-success.description1': 'Nézd meg mit nyertél a regisztrációval!',
    'shop-registration.integration-success.description2': 'Köszönjük, hogy az M.I.-t választottad!',
    'shop-registration.integration-success.button.start': 'Elkezdem használni',
    'shop-registration.footer.copyright.text': React.createElement("p", null,
        "Copyright ",
        React.createElement(semantic_ui_react_1.Icon, { name: "copyright outline" }),
        " 2021 M.I. Solutions Kft."),
    'shop-registration.footer.terms-of-use.link': React.createElement("a", { target: "_blank", href: "https://miclub.hu/aszf", onClick: function (e) { return e.stopPropagation(); } }, "\u00C1ltal\u00E1nos szerz\u0151d\u00E9si felt\u00E9telek"),
    'shop-registration.footer.privacy-policy.link': React.createElement("a", { target: "_blank", href: "https://miclub.hu/adatvedelem", onClick: function (e) { return e.stopPropagation(); } }, "Adatv\u00E9delmi t\u00E1j\u00E9koztat\u00F3"),
    'shop-registration.integration.shopify.admin-token-key': 'Admin access token',
    'shop-registration.integration.shopify.title': 'Shopify Plus integráció',
    'shop-registration.integration.shopify.description.title': 'Webáruház összekötése az M.I. API-val',
    'shop-registration.integration.shopify.description.subtitle': 'Ez ahhoz szükséges, hogy Digitális Asszisztensünk elemezhesse az adatokat. Ez a lépés mindenféleképpen szükséges a teljeskörű használhathoz.',
    'shop-registration.integration.shopify.description.step1': React.createElement("p", null, "L\u00E9pj be a web\u00E1ruh\u00E1zad adminisztr\u00E1ci\u00F3s fel\u00FClet\u00E9re."),
    'shop-registration.integration.shopify.description.step2': 'Bejelentkezés után navigálj el az Apps->Developer apps->Create an app beállítások oldalra.',
    'shop-registration.integration.shopify.description.step3': 'Hozz létre egy új app-ot és nevezd el MISys-nek.',
    'shop-registration.integration.shopify.description.step4': 'Kattints a Configure Admin API scopes opcióra. Pipáld ki az adott opciók közül az összeset, majd kattints a Save gombra.',
    'shop-registration.integration.shopify.description.step5': 'Az API credentials tab alatt kattints az Install app gombra',
    'shop-registration.integration.shopify.description.step6': 'A létrejött Admin API access token kulcsot másold ki és alul illeszd be az Admin API access token kulcs beviteli mezőbe.',
    'shop-registration.integration.shopify.cancel-button.title': 'Másik integrációt választok',
    'shop-registration.integration.shopify.start-button.title': 'Tovább az események összekötéséhez',
    'shop-registration.integration.shopify.webshop-url.label': 'Webshop domain',
    'shop-registration.integration.shopify-analytic.title': 'Shopify Plus integráció',
    'shop-registration.integration.shopify-analytic.description.title': 'Analitika script elhelyezése az webáruház weboldalába',
    'shop-registration.integration.shopify-analytic.description.subtitle': 'Az analatika script segítségével olyan analitikai adatokat gyűjtünk, melyek segítségével többek között fel tudjuk építeni látogatóid profilját, hasznos tippeket tudunk adni, hogyan növelheted webáruházad teljesítményét és még nagyok sok minden mást is :). A teljes M.I. élményért elengedhetetlen lépés. ',
    'shop-registration.integration.shopify-analytic.description.step1': 'Másold ki a scriptet a lenti mezőből.',
    'shop-registration.integration.shopify-analytic.description.step2': 'Webáruházad adminisztrációs felületén belül navigálj el az Egyedi scriptek oldalra: Megjelenés -> Téma testreszabása-> Egyedi scriptekI',
    'shop-registration.integration.shopify-analytic.description.step3': 'Hozz létre egy új API kulcsot úgy, hogy olvasási és írási jogot választasz ki.',
    'shop-registration.integration.shopify-analytic.description.step4': 'Illeszd be a kimásolt scriptet a HEAD részbe',
    'shop-registration.integration.shopify-analytic.start-button.title': 'Tovább az adatok importálásához!',
    'shop-registration.integration.shopify-analytics.copy-button.title': 'script másolása',
    'shop-registration.integration.shopify.username.label': 'Felhasználó név',
    'shop-registration.integration.shopify.username.placeholder': '',
    'shop-registration.integration.shopify.apiKey.label': 'API kulcs',
    'shop-registration.integration.shopify.apiKey.placeholder': '',
    'shop-registration.integration.shopify.appUrl.label': 'App url',
    'shop-registration.integration.shopify.appUrl.placeholder': '',
    'shop-registration.integration.shopify.url.label': 'URL',
    'shop-registration.integration.shopify.url.placeholder': '',
    'shop-registration.integration.shopify.origins.placeholder': '',
    'shop-registration.integration.shopify.origins.label': 'Engedélyezett domain',
};
