"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductListItemComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var state_repository_1 = require("../../../common/libs/state-repository");
require("./product-list.component.scss");
var ProductListItemComponent = (function (_super) {
    __extends(ProductListItemComponent, _super);
    function ProductListItemComponent() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.productRepository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'product');
        _this.state = {
            loading: false,
            item: (_a = _this.props) === null || _a === void 0 ? void 0 : _a.item
        };
        return _this;
    }
    ProductListItemComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!lodash_1.isEqual(prevProps.item, this.props.item)) {
            this.setState({ item: this.props.item });
        }
    };
    ProductListItemComponent.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var item = this.state.item;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item._id, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderProduct(item)),
            React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' },
                React.createElement("div", null,
                    React.createElement("span", null,
                        ((_a = item === null || item === void 0 ? void 0 : item.additionalIdentifiers) === null || _a === void 0 ? void 0 : _a.length) || 0,
                        " db"))),
            React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' },
                React.createElement("div", null,
                    React.createElement("span", null, !!((_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.manageStock) ? ((_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.stock) + " db" : '-'))),
            React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' },
                React.createElement("div", null,
                    React.createElement("span", null,
                        ((_d = item === null || item === void 0 ? void 0 : item.statistics) === null || _d === void 0 ? void 0 : _d.soldAll) || 0,
                        " db"))),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true, textAlign: 'center' },
                React.createElement("div", null,
                    React.createElement("span", null, currency_parser_1.hufFormat(((_e = item === null || item === void 0 ? void 0 : item.data) === null || _e === void 0 ? void 0 : _e.grossPrice) * ((_f = item === null || item === void 0 ? void 0 : item.statistics) === null || _f === void 0 ? void 0 : _f.soldAll)) || currency_parser_1.hufFormat(0)))),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true, textAlign: 'center' },
                React.createElement("div", null, this.renderDiscountPrice((_g = item === null || item === void 0 ? void 0 : item.data) === null || _g === void 0 ? void 0 : _g.grossPrice, (_h = item === null || item === void 0 ? void 0 : item.data) === null || _h === void 0 ? void 0 : _h.saleGrossPrice))),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("div", null, this.renderCategories((_j = item === null || item === void 0 ? void 0 : item.data) === null || _j === void 0 ? void 0 : _j.categories))),
            React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderAdvertisable(item)));
    };
    ProductListItemComponent.prototype.renderAdvertisable = function (item) {
        var _this = this;
        var _a;
        var advertisable = (_a = item === null || item === void 0 ? void 0 : item.settings) === null || _a === void 0 ? void 0 : _a.advertisableEmail;
        return React.createElement("div", { className: 'display-flex flex-row align-items-center justify-content-center CheckButton' },
            React.createElement(semantic_ui_react_1.Button, { loading: this.state.loading, disabled: this.state.loading, size: 'tiny', basic: true, icon: advertisable ? 'check' : '', onClick: function () { return _this.toggleAdvertisable(item); } }));
    };
    ProductListItemComponent.prototype.toggleAdvertisable = function (item) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, currentItem, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.productRepository.put("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/settings/" + (item === null || item === void 0 ? void 0 : item._id), {
                                data: {
                                    key: 'advertisableEmail',
                                    value: !((_b = item === null || item === void 0 ? void 0 : item.settings) === null || _b === void 0 ? void 0 : _b.advertisableEmail)
                                }
                            })];
                    case 1:
                        response = _c.sent();
                        currentItem = this.state.item;
                        this.setState({ loading: false, item: __assign(__assign({}, currentItem), { settings: __assign({}, response === null || response === void 0 ? void 0 : response.settings) }) });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ loading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ProductListItemComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    ProductListItemComponent.prototype.renderProduct = function (item) {
        var _a, _b, _c, _d;
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'ProductImageWrapper' }, this.renderProductImage((_b = (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.image) === null || _b === void 0 ? void 0 : _b.contain)),
            React.createElement("div", { className: 'pt-2' },
                React.createElement("p", null, ((_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.name) || null),
                React.createElement("span", null,
                    "Cikksz\u00E1m: ",
                    React.createElement("strong", null, ((_d = item === null || item === void 0 ? void 0 : item.data) === null || _d === void 0 ? void 0 : _d.sku) || null))));
    };
    ProductListItemComponent.prototype.renderProductImage = function (imageUrl) {
        var image = imageUrl ? imageUrl : '/assets/images/placeholder_image.png';
        return React.createElement(semantic_ui_react_1.Image, { className: 'ProductImage mr-3', src: image });
    };
    ProductListItemComponent.prototype.renderDiscountPrice = function (grossPrice, discountPrice) {
        var discount = Math.floor((grossPrice - discountPrice) / grossPrice * 100);
        if (!discount) {
            return React.createElement("span", null, currency_parser_1.hufFormat(grossPrice));
        }
        return React.createElement("div", { className: 'DiscountPrice' },
            React.createElement("s", null, currency_parser_1.hufFormat(grossPrice)),
            React.createElement("p", null,
                currency_parser_1.hufFormat(discountPrice),
                " "),
            React.createElement("small", null,
                "-",
                discount,
                "%"));
    };
    ProductListItemComponent.prototype.renderCategories = function (categories) {
        return React.createElement("div", { className: 'display-flex flex-row flex-wrap' }, categories.map(function (c) {
            var _a, _b;
            return React.createElement(semantic_ui_react_1.Label, { size: 'small', className: 'mb-2', basic: true, color: 'blue', key: (_a = c === null || c === void 0 ? void 0 : c.data) === null || _a === void 0 ? void 0 : _a.identifier }, (_b = c === null || c === void 0 ? void 0 : c.data) === null || _b === void 0 ? void 0 : _b.name);
        }));
    };
    return ProductListItemComponent;
}(responsive_component_1.ResponsiveComponent));
exports.ProductListItemComponent = ProductListItemComponent;
