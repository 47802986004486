"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterModal = void 0;
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var customer_filter_1 = require("./customer-filter");
var save_filter_modal_1 = require("./save-filter.modal");
var delete_filter_modal_1 = require("./delete-filter.modal");
var FilterModal = (function (_super) {
    __extends(FilterModal, _super);
    function FilterModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            show: false,
            expressions: _this.props.expressions || [],
            selectedId: 0,
            selectedName: null,
            selectedDescription: null
        };
        return _this;
    }
    FilterModal.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var prev = JSON.stringify(prevProps.expressions);
        var next = JSON.stringify(this.props.expressions);
        if (prev !== next) {
            this.setState({
                expressions: this.props.expressions || []
            });
        }
    };
    FilterModal.prototype.selectItem = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({
                            selectedId: item._id,
                            selectedName: item.name,
                            selectedDescription: item.description,
                            expressions: item.payload,
                        })];
                    case 1:
                        _a.sent();
                        this.emit(false);
                        return [2];
                }
            });
        });
    };
    FilterModal.prototype.reset = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({
                            selectedId: 0,
                            selectedName: null,
                            selectedDescription: null,
                            expressions: []
                        })];
                    case 1:
                        _a.sent();
                        this.emit(true);
                        return [2];
                }
            });
        });
    };
    FilterModal.prototype.render = function () {
        return [
            this.renderTrigger(),
            this.renderSidebar()
        ];
    };
    FilterModal.prototype.renderTrigger = function () {
        var _this = this;
        var _a;
        return [
            React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.setState({ show: true }); }, key: 0, content: this.getButtonContent(), basic: true, className: 'LeadFilterButtons' }),
            !!((_a = this.state.expressions) === null || _a === void 0 ? void 0 : _a.length) && React.createElement(semantic_ui_react_1.Button, { onClick: function () {
                    _this.setState({
                        show: false,
                        selectedId: 0,
                        expressions: []
                    });
                    if (_this.props.onClose) {
                        _this.props.onClose();
                    }
                }, key: 1, icon: "trash alternate", basic: true, className: 'LeadFilterButtons' })
        ];
    };
    FilterModal.prototype.getButtonContent = function () {
        return "Sz\u0171r\u00E9s" + (this.state.expressions.length ? " (" + this.state.expressions.length + ")" : '');
    };
    FilterModal.prototype.renderSidebar = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return React.createElement(semantic_ui_react_1.Sidebar, { as: semantic_ui_react_1.Segment, width: "wide", direction: "right", animation: "overlay", visible: this.state.show },
            React.createElement("div", { className: "w-100 h-100 display-flex flex-column" },
                React.createElement("h3", null, "Sz\u0171r\u0151 be\u00E1ll\u00EDt\u00E1sa"),
                React.createElement("div", { className: "flex-fill" },
                    React.createElement(customer_filter_1.CustomerFilter, { expressions: this.state.expressions, onChange: function (value) { return _this.setState({ expressions: value }); }, className: 'w-100' })),
                React.createElement(semantic_ui_react_1.List, null,
                    React.createElement(semantic_ui_react_1.List.Item, { className: "w-100" },
                        React.createElement(semantic_ui_react_1.Button, { content: "Sz\u0171r\u00E9s futtat\u00E1sa", fluid: true, onClick: function () { return _this.emit(); } })),
                    React.createElement(semantic_ui_react_1.List.Item, { className: "w-100" },
                        React.createElement(save_filter_modal_1.SaveFilterModal, { show: (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.expressions) === null || _b === void 0 ? void 0 : _b.length, filter: (_c = this.state) === null || _c === void 0 ? void 0 : _c.expressions, _id: (_d = this.state) === null || _d === void 0 ? void 0 : _d.selectedId, name: (_e = this.state) === null || _e === void 0 ? void 0 : _e.selectedName, description: (_f = this.state) === null || _f === void 0 ? void 0 : _f.selectedDescription, handleNewFilterIsSaved: function () { return _this.handleNewFilterIsSaved(); } })),
                    ((_g = this.state) === null || _g === void 0 ? void 0 : _g.selectedId) ?
                        React.createElement(semantic_ui_react_1.List.Item, { className: "w-100" },
                            React.createElement(delete_filter_modal_1.DeleteFilterModal, { show: (_j = (_h = this.state) === null || _h === void 0 ? void 0 : _h.expressions) === null || _j === void 0 ? void 0 : _j.length, _id: (_k = this.state) === null || _k === void 0 ? void 0 : _k.selectedId }))
                        : null,
                    React.createElement(semantic_ui_react_1.List.Item, null,
                        React.createElement(semantic_ui_react_1.Button, { basic: true, content: "Bez\u00E1r\u00E1s", fluid: true, onClick: function () { return _this.setState({ show: false }); } })))));
    };
    FilterModal.prototype.handleNewFilterIsSaved = function () {
        if (this.props.handleNewFilterIsSaved) {
            this.props.handleNewFilterIsSaved();
        }
    };
    FilterModal.prototype.emit = function (reset) {
        if (reset === void 0) { reset = false; }
        if (this.props.onChange) {
            this.props.onChange(this.state.expressions, reset);
        }
    };
    return FilterModal;
}(React.Component));
exports.FilterModal = FilterModal;
