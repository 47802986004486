"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoListDnsTabContent = void 0;
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var todo_list_tab_content_container_component_1 = require("./todo-list-tab-content-container.component");
var session_storage_1 = require("../../../../libs/session.storage");
var state_repository_1 = require("../../../common/libs/state-repository");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var errors_constant_1 = require("../../errors/errors.constant");
var toast_util_1 = require("../../errors/toast.util");
var mockResponse = {
    req: {
        method: "GET",
        url: "https://api.eu.mailgun.net/v3/domains/shop.mikonyhank.hu",
    },
    status: 200,
    response: {
        text: "{\"domain\":{\"created_at\":\"Wed, 28 Aug 2019 14:06:07 GMT\",\"id\":\"5d668a501171a08e0faa43c3\",\"is_disabled\":false,\"name\":\"mailgun.miclub.hu\",\"require_tls\":false,\"skip_verification\":false,\"smtp_login\":\"postmaster@mailgun.miclub.hu\",\"spam_action\":\"disabled\",\"state\":\"active\",\"type\":\"custom\",\"web_prefix\":\"email\",\"web_scheme\":\"http\",\"wildcard\":false},\"receiving_dns_records\":[{\"is_active\":true,\"cached\":[\"10 mxa.eu.mailgun.org\",\"10 mxb.eu.mailgun.org\"],\"priority\":\"10\",\"record_type\":\"MX\",\"valid\":\"valid\",\"value\":\"mxa.eu.mailgun.org\"},{\"is_active\":true,\"cached\":[\"10 mxa.eu.mailgun.org\",\"10 mxb.eu.mailgun.org\"],\"priority\":\"10\",\"record_type\":\"MX\",\"valid\":\"valid\",\"value\":\"mxb.eu.mailgun.org\"}],\"sending_dns_records\":[{\"is_active\":true,\"cached\":[\"k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDC73RKDnv/4xJwiXEfB2sKnDCVGIOq0S78KBd0VyqpThKdgp7P8dz7T/J0lV2+hJXEufdJLicyfBhyhXEYit7BexuNq7dlzcmaGkKWSRqiBKPUhwJ7NvV8yhkEL6jiAgIvOU69xtdpjh+945/KQ3p57y85MeltuWUs5xIZJ7+saQIDAQAB\"],\"name\":\"mx._domainkey.mailgun.miclub.hu\",\"record_type\":\"TXT\",\"valid\":\"valid\",\"value\":\"k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDC73RKDnv/4xJwiXEfB2sKnDCVGIOq0S78KBd0VyqpThKdgp7P8dz7T/J0lV2+hJXEufdJLicyfBhyhXEYit7BexuNq7dlzcmaGkKWSRqiBKPUhwJ7NvV8yhkEL6jiAgIvOU69xtdpjh+945/KQ3p57y85MeltuWUs5xIZJ7+saQIDAQAB\"},{\"is_active\":true,\"cached\":[\"v=spf1 include:eu.mailgun.org ~all\"],\"name\":\"mailgun.miclub.hu\",\"record_type\":\"TXT\",\"valid\":\"valid\",\"value\":\"v=spf1 include:mailgun.org ~all\"},{\"is_active\":true,\"cached\":[],\"name\":\"email.mailgun.miclub.hu\",\"record_type\":\"CNAME\",\"valid\":\"unknown\",\"value\":\"eu.mailgun.org\"}]}\n"
    }
};
var TodoListDnsTabContent = (function (_super) {
    __extends(TodoListDnsTabContent, _super);
    function TodoListDnsTabContent(props) {
        var _this = _super.call(this, props) || this;
        _this.mailgunCredentials = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'mailgunCredentials');
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            isLoading: false,
            isError: false,
            error: null
        };
        return _this;
    }
    TodoListDnsTabContent.prototype.fetchMailgunSettings = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.setState({ mailgunCredentials: null })];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4, this.mailgunCredentials.get("/s/" + ((_a = this.state) === null || _a === void 0 ? void 0 : _a.shop._id) + "/shop/domain")];
                    case 3:
                        response = _b.sent();
                        console.log('fetchMailgunSettings response', response);
                        this.setState({
                            isError: false,
                            error: null
                        });
                        return [3, 5];
                    case 4:
                        error_1 = _b.sent();
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_FETCH_MAILGUN_SETTINGS);
                        console.log('fetchMailgunSettings catch error', error_1);
                        this.setState({
                            isError: true,
                            error: error_1,
                            mailgunCredentials: mockResponse
                        });
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    TodoListDnsTabContent.prototype.componentDidMount = function () {
        this.fetchShop();
        this.fetchMailgunSettings();
    };
    TodoListDnsTabContent.prototype.fetchShop = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({ shop: session_storage_1.SessionStorage.getItem('SelectedShop') });
                return [2];
            });
        });
    };
    TodoListDnsTabContent.prototype.refresh = function () {
        this.fetchShop();
    };
    TodoListDnsTabContent.prototype.renderSendingDnsRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        if (!((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.mailgunCredentials) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.text)) {
            return React.createElement("div", { className: 'p-8' },
                React.createElement(semantic_ui_react_1.Loader, { active: true }));
        }
        var records = (_g = JSON.parse(((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.mailgunCredentials) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.text) || '')) === null || _g === void 0 ? void 0 : _g.sending_dns_records;
        return React.createElement(semantic_ui_react_1.Table, { className: 'w-100 MailgunCredentials header-blue', celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 1 }, "Type"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 3 }, "Hostname"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 6 }, "Enter this value"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 6 }, "Current value"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, { width: 1, className: 'word-break-all' }, (_h = records[0]) === null || _h === void 0 ? void 0 : _h.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, { width: 3, className: 'word-break-all' }, (_j = records[0]) === null || _j === void 0 ? void 0 : _j.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { width: 6, className: 'word-break-all' }, (_k = records[0]) === null || _k === void 0 ? void 0 : _k.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { width: 6, className: "word-break-all " + ((((_m = (_l = records[0]) === null || _l === void 0 ? void 0 : _l.cached) === null || _m === void 0 ? void 0 : _m.length) && ((_o = records[0]) === null || _o === void 0 ? void 0 : _o.valid) === 'valid') ? 'valid' : 'invalid') }, ((_q = (_p = records[0]) === null || _p === void 0 ? void 0 : _p.cached) === null || _q === void 0 ? void 0 : _q.length) ? (_r = records[0]) === null || _r === void 0 ? void 0 : _r.cached : 'Not found')),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_s = records[1]) === null || _s === void 0 ? void 0 : _s.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_t = records[1]) === null || _t === void 0 ? void 0 : _t.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_u = records[1]) === null || _u === void 0 ? void 0 : _u.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_w = (_v = records[1]) === null || _v === void 0 ? void 0 : _v.cached) === null || _w === void 0 ? void 0 : _w.length) && ((_x = records[1]) === null || _x === void 0 ? void 0 : _x.valid) === 'valid') ? 'valid' : 'invalid') }, ((_z = (_y = records[1]) === null || _y === void 0 ? void 0 : _y.cached) === null || _z === void 0 ? void 0 : _z.length) ? (_0 = records[1]) === null || _0 === void 0 ? void 0 : _0.cached : 'Not found')),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_1 = records[3]) === null || _1 === void 0 ? void 0 : _1.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_2 = records[3]) === null || _2 === void 0 ? void 0 : _2.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_3 = records[3]) === null || _3 === void 0 ? void 0 : _3.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_5 = (_4 = records[3]) === null || _4 === void 0 ? void 0 : _4.cached) === null || _5 === void 0 ? void 0 : _5.length) && ((_6 = records[3]) === null || _6 === void 0 ? void 0 : _6.valid) === 'valid') ? 'valid' : 'invalid') }, ((_8 = (_7 = records[3]) === null || _7 === void 0 ? void 0 : _7.cached) === null || _8 === void 0 ? void 0 : _8.length) ? (_9 = records[3]) === null || _9 === void 0 ? void 0 : _9.cached : 'Not found'))));
    };
    TodoListDnsTabContent.prototype.renderMxRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        if (!((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.mailgunCredentials) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.text)) {
            return React.createElement("div", { className: 'p-8' },
                React.createElement(semantic_ui_react_1.Loader, { active: true }));
        }
        var records = (_g = JSON.parse(((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.mailgunCredentials) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.text) || '')) === null || _g === void 0 ? void 0 : _g.receiving_dns_records;
        var name = (_m = (_l = JSON.parse(((_k = (_j = (_h = this.state) === null || _h === void 0 ? void 0 : _h.mailgunCredentials) === null || _j === void 0 ? void 0 : _j.response) === null || _k === void 0 ? void 0 : _k.text) || '')) === null || _l === void 0 ? void 0 : _l.domain) === null || _m === void 0 ? void 0 : _m.name;
        return React.createElement(semantic_ui_react_1.Table, { className: 'w-100 MailgunCredentials header-blue', celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 1 }, "Type"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 2 }, "Hostname"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 1 }, "Priority"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 6 }, "Enter this value"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 6 }, "Current value"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_o = records[0]) === null || _o === void 0 ? void 0 : _o.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_p = records[0]) === null || _p === void 0 ? void 0 : _p.priority),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_q = records[0]) === null || _q === void 0 ? void 0 : _q.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_s = (_r = records[0]) === null || _r === void 0 ? void 0 : _r.cached) === null || _s === void 0 ? void 0 : _s.length) && ((_t = records[0]) === null || _t === void 0 ? void 0 : _t.valid) === 'valid') ? 'valid' : 'invalid') }, ((_v = (_u = records[0]) === null || _u === void 0 ? void 0 : _u.cached) === null || _v === void 0 ? void 0 : _v.length) ? (_w = records[0]) === null || _w === void 0 ? void 0 : _w.cached : 'Not found')),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_x = records[1]) === null || _x === void 0 ? void 0 : _x.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_y = records[1]) === null || _y === void 0 ? void 0 : _y.priority),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_z = records[1]) === null || _z === void 0 ? void 0 : _z.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_1 = (_0 = records[1]) === null || _0 === void 0 ? void 0 : _0.cached) === null || _1 === void 0 ? void 0 : _1.length) && ((_2 = records[1]) === null || _2 === void 0 ? void 0 : _2.valid) === 'valid') ? 'valid' : 'invalid') }, ((_4 = (_3 = records[1]) === null || _3 === void 0 ? void 0 : _3.cached) === null || _4 === void 0 ? void 0 : _4.length) ? (_5 = records[1]) === null || _5 === void 0 ? void 0 : _5.cached : 'Not found'))));
    };
    TodoListDnsTabContent.prototype.renderTrackingRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        if (!((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.mailgunCredentials) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.text)) {
            return React.createElement("div", { className: 'p-8' },
                React.createElement(semantic_ui_react_1.Loader, { active: true }));
        }
        var records = (_g = JSON.parse(((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.mailgunCredentials) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.text) || '')) === null || _g === void 0 ? void 0 : _g.sending_dns_records;
        return React.createElement(semantic_ui_react_1.Table, { className: 'w-100 MailgunCredentials header-blue', celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 1 }, "Type"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 3 }, "Hostname"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 6 }, "Enter this value"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { textAlign: 'center', width: 6 }, "Current value"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_h = records[2]) === null || _h === void 0 ? void 0 : _h.record_type),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_j = records[2]) === null || _j === void 0 ? void 0 : _j.name),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: 'word-break-all' }, (_k = records[2]) === null || _k === void 0 ? void 0 : _k.value),
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "word-break-all " + ((((_m = (_l = records[2]) === null || _l === void 0 ? void 0 : _l.cached) === null || _m === void 0 ? void 0 : _m.length) && ((_o = records[2]) === null || _o === void 0 ? void 0 : _o.valid) === 'valid') ? 'valid' : 'invalid') }, ((_q = (_p = records[2]) === null || _p === void 0 ? void 0 : _p.cached) === null || _q === void 0 ? void 0 : _q.length) ? (_r = records[2]) === null || _r === void 0 ? void 0 : _r.cached : 'Not found'))));
    };
    TodoListDnsTabContent.prototype.renderEmailSendingStatusByDNSRecords = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.mailgunStatus) {
            return React.createElement("div", null,
                React.createElement("p", { style: { fontWeight: 'bold' } }, "Enged\u00E9lyezve, minden rekord \u00E9rv\u00E9nyes."),
                "Utolj\u00E1ra ellen\u0151rizve: ",
                new Date((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.shop) === null || _d === void 0 ? void 0 : _d.mailgunStatusUpdatedAt).toLocaleDateString(),
                ",",
                new Date((_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.shop) === null || _f === void 0 ? void 0 : _f.mailgunStatusUpdatedAt).toLocaleTimeString(),
                React.createElement("br", null));
        }
        var hasAlreadyChecked = !!((_h = (_g = this.state) === null || _g === void 0 ? void 0 : _g.shop) === null || _h === void 0 ? void 0 : _h.mailgunStatusUpdatedAt);
        return React.createElement("div", null,
            React.createElement("p", null,
                React.createElement("strong", null, "Valami m\u00E9g nem az igazi, k\u00E9rlek ellen\u0151rizd alul a t\u00E1bl\u00E1zatot \u00E9s a pirossal \u00EDrt r\u00E9szeket sz\u00FCks\u00E9ges jav\u00EDtani."),
                React.createElement("br", null),
                hasAlreadyChecked && (React.createElement(React.Fragment, null,
                    "Utolj\u00E1ra ellen\u0151rizve: ",
                    new Date((_k = (_j = this.state) === null || _j === void 0 ? void 0 : _j.shop) === null || _k === void 0 ? void 0 : _k.mailgunStatusUpdatedAt).toLocaleDateString(),
                    ",",
                    new Date((_m = (_l = this.state) === null || _l === void 0 ? void 0 : _l.shop) === null || _m === void 0 ? void 0 : _m.mailgunStatusUpdatedAt).toLocaleTimeString()))));
    };
    TodoListDnsTabContent.prototype.render = function () {
        return (React.createElement(todo_list_tab_content_container_component_1.TodoListTabContentContainerComponent, null,
            React.createElement("div", { className: 'EmailComponent' },
                React.createElement(semantic_ui_react_1.Message, { info: true, style: { backgroundColor: '#EDF4FF', boxShadow: 'none', color: "#051D40" } },
                    React.createElement("p", null,
                        React.createElement("strong", null, "\u00C1ll\u00EDtsuk be a DNS-t az e-mail k\u00FCld\u00E9shez! Ez az egyik legfontosabb l\u00E9p\u00E9s!")),
                    React.createElement("p", null, "Az e-mailek k\u00FCld\u00E9s\u00E9hez fontos, hogy a domainhez tartoz\u00F3 DNS alap\u00E9rt\u00E9kek be\u00E1ll\u00EDt\u00E1sa megt\u00F6rt\u00E9njen!"),
                    React.createElement("p", null,
                        React.createElement("strong", null, "DNS be\u00E1ll\u00EDt\u00E1s l\u00E9p\u00E9sei:"),
                        React.createElement("br", null),
                        "1. T\u00F6ltsd le a f\u00E1jlt, a ",
                        React.createElement("strong", null, "\u201CBE\u00C1LL\u00CDT\u00C1SI DOKUMENT\u00C1CI\u00D3 LET\u00D6LT\u00C9SE\u201D"),
                        " gombra kattintva!",
                        React.createElement("br", null),
                        "2. K\u00FCldd el a \u201CTXT\u201D f\u00E1jlt v\u00E1ltozatlan form\u00E1ban a domain szolg\u00E1ltat\u00F3dnak \u00E9s k\u00E9rdd meg, hogy k\u00F6vesse a benne foglalt be\u00E1ll\u00EDt\u00E1si param\u00E9tereket!",
                        React.createElement("br", null),
                        "3. Miut\u00E1n a be\u00E1ll\u00EDt\u00E1sokat v\u00E9grehajtotta, v\u00E1rhat\u00F3an 1-4 \u00F3ra eltelt\u00E9vel a rendszered lefriss\u00FCl, ekkor minden \u00E9rt\u00E9knek z\u00F6ldre kell v\u00E1ltania!",
                        React.createElement("br", null),
                        "4. Gyere vissza 1-4 \u00F3ra m\u00FAlva \u00E9s ellen\u0151rizd le, hogy minden \u00E9rt\u00E9k be\u00E1ll\u00EDt\u00E1sa sikeresen megt\u00F6rt\u00E9nt!"),
                    React.createElement("p", null, "A be\u00E1ll\u00EDt\u00E1sokat elv\u00E9gezheted te is, de ink\u00E1bb azt aj\u00E1nlom, hogy a domain fenntart\u00F3ddal v\u00E9geztesd el a be\u00E1ll\u00EDt\u00E1sokat!")),
                React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement("div", { className: 'col-sm-6' },
                                React.createElement("h4", { style: { color: "#337EFF" } }, "E-mail k\u00FCld\u00E9s jelenlegi \u00E1llapota:"),
                                React.createElement("div", { className: 'mb-4' }, this.renderEmailSendingStatusByDNSRecords()))),
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement("div", { className: 'align-content-lg-center' }, this.renderDownloadDnsRequirements()))),
                    React.createElement(semantic_ui_react_1.Grid.Row, null,
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement("div", { className: 'mb-4' }, this.renderSendingDnsRecords()),
                            React.createElement("div", { className: 'mb-4' }, this.renderMxRecords()),
                            React.createElement("h5", null, "DNS record for tracking"),
                            React.createElement("div", { className: 'mb-4' }, this.renderTrackingRecords())))),
                React.createElement("br", null),
                React.createElement("p", null, "A web\u00E1ruh\u00E1z hat\u00E9kony m\u0171k\u00F6d\u00E9se \u00E9rdek\u00E9ben, folyamatosan figyelemmel k\u00EDs\u00E9rem a DNS be\u00E1ll\u00EDt\u00E1sokat. Ez kulcsfontoss\u00E1g\u00FA a vev\u0151kkel val\u00F3 zavartalan kommunik\u00E1ci\u00F3 szempontj\u00E1b\u00F3l."),
                React.createElement("p", null, "Ha elt\u00E9r\u00E9st \u00E9szlelek a DNS \u00E9rt\u00E9kek k\u00F6z\u00F6tt a jelz\u0151 pirosra v\u00E1lt, majd a nem v\u00E1rt probl\u00E9m\u00E1k elker\u00FCl\u00E9se c\u00E9lj\u00E1b\u00F3l ideiglenesen felf\u00FCggesztem a levelez\u00E9st! Ez az int\u00E9zked\u00E9s a webshop \u00E9rdek\u00E9ben t\u00F6rt\u00E9nik, hogy a kommunik\u00E1ci\u00F3 mindig magas sz\u00EDnvonalon t\u00F6rt\u00E9nhessen. Ekkor m\u00F3dos\u00EDtani sz\u00FCks\u00E9ges a be\u00E1ll\u00EDt\u00E1sokon, az \u00E1ltalam jelzett \u00E9rt\u00E9kek alapj\u00E1n!"),
                React.createElement("p", null, "Amint a DNS be\u00E1ll\u00EDt\u00E1sok megfelel\u0151ek, a folyamatjelz\u0151 z\u00F6ldre v\u00E1lt, jelezve, hogy minden rendben van."))));
    };
    TodoListDnsTabContent.prototype.renderDownloadDnsRequirements = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.downloadDnsRequirements(); }, content: 'BEÁLLÍTÁSI DOKUMENTÁCIÓ LETÖLTÉSE', style: { marginTop: 40 } });
    };
    TodoListDnsTabContent.prototype.downloadDnsRequirements = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var sending_dns_records = (_d = JSON.parse(((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.mailgunCredentials) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.text) || '')) === null || _d === void 0 ? void 0 : _d.sending_dns_records;
        var receiving_dns_records = (_h = JSON.parse(((_g = (_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.mailgunCredentials) === null || _f === void 0 ? void 0 : _f.response) === null || _g === void 0 ? void 0 : _g.text) || '')) === null || _h === void 0 ? void 0 : _h.receiving_dns_records;
        var text = "\n            SPF\n            ======\n            Type: TXT\n            Hostname: " + sending_dns_records.find(function (r) { return r.value.includes('v=spf1'); }).name + "\n            Value: " + sending_dns_records.find(function (r) { return r.value.includes('v=spf1'); }).value + "\n\n            DKIM\n            ======\n            Type: TXT\n            Hostname: " + sending_dns_records.find(function (r) { return r.value.includes('k=rsa'); }).name + "\n            Value: " + sending_dns_records.find(function (r) { return r.value.includes('k=rsa'); }).value + "\n\n            DMARC\n            =======\n            Type:TXT\n            Hostname: " + sending_dns_records.find(function (r) { return r.value.includes('v=DMARC1'); }).name + "\n            Value: " + sending_dns_records.find(function (r) { return r.value.includes('v=DMARC1'); }).value + "\n\n            CNAME\n            =======\n            Type:CNAME\n            Host: " + sending_dns_records.find(function (r) { return r.record_type === 'CNAME'; }).name + "\n            Value: " + sending_dns_records.find(function (r) { return r.record_type === 'CNAME'; }).value + "\n\n            MX\n            =======\n            Type:MX\n            Host: " + sending_dns_records.find(function (r) { return r.value.includes('v=DMARC1'); }).name.replace('_dmarc.', '') + "\n            Value: " + receiving_dns_records[0].value + "\n            Priority: 10\n\n            Type:MX\n            Host: " + sending_dns_records.find(function (r) { return r.value.includes('v=DMARC1'); }).name.replace('_dmarc.', '') + "\n            Value: " + receiving_dns_records[1].value + "\n            Priority: 10\n        ";
        var el = document.createElement('a');
        el.setAttribute('href', "data:text/plain;charset=utf-8," + encodeURIComponent(text));
        el.setAttribute('download', 'dns_records.txt');
        el.style.display = 'none';
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
    };
    return TodoListDnsTabContent;
}(abstract_component_1.AbstractComponent));
exports.TodoListDnsTabContent = TodoListDnsTabContent;
