"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagersComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var number_parser_1 = require("../../../common/libs/number-parser");
var state_repository_1 = require("../../../common/libs/state-repository");
var invite_manager_component_1 = require("./invite-manager.component");
var ManagersComponent = (function (_super) {
    __extends(ManagersComponent, _super);
    function ManagersComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.integration = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'integration');
        _this.managers = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'managers');
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            shop: _this.props.shop || session_storage_1.SessionStorage.getItem('SelectedShop'),
            managers: null,
            listItems: [],
            integration: null,
            listCount: 0,
            isModalOpen: false,
            loading: false
        };
        return _this;
    }
    ManagersComponent.prototype.componentDidMount = function () {
        this.fetchManagers();
    };
    ManagersComponent.prototype.fetchManagers = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var owners, allInvitedUser_1, managers, pendingInvitations, listItems, e_1;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _j.trys.push([0, 2, , 3]);
                        return [4, this.managers.get("/s/" + this.state.shop._id + "/shop/shopManagers")];
                    case 1:
                        _j.sent();
                        owners = (((_b = (_a = this.state.managers) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.owners) || []).map(function (o) { return ({
                            user: o,
                            invitedAt: o.createdAt,
                            acceptedAt: o.createdAt
                        }); });
                        allInvitedUser_1 = (((_d = (_c = this.state.managers) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.invitedUsers) || []);
                        managers = (((_f = (_e = this.state.managers) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.managers) || []).map(function (m) {
                            var _a, _b;
                            return ({
                                user: m,
                                invitedAt: (_a = allInvitedUser_1.find(function (u) { var _a; return ((_a = u.user) === null || _a === void 0 ? void 0 : _a._id) === (m === null || m === void 0 ? void 0 : m._id); })) === null || _a === void 0 ? void 0 : _a.invitedAt,
                                acceptedAt: (_b = allInvitedUser_1.find(function (u) { var _a; return ((_a = u.user) === null || _a === void 0 ? void 0 : _a._id) === (m === null || m === void 0 ? void 0 : m._id); })) === null || _b === void 0 ? void 0 : _b.acceptedAt,
                            });
                        });
                        pendingInvitations = (((_h = (_g = this.state.managers) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.invitedUsers) || []).filter(function (u) { return !u.acceptedAt; });
                        listItems = __spreadArrays(owners, managers, pendingInvitations);
                        this.setState({ listItems: listItems });
                        return [3, 3];
                    case 2:
                        e_1 = _j.sent();
                        console.log(e_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ManagersComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", { className: 'ManagersComponent ListComponent' },
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, 'Kezelők'),
                        React.createElement(semantic_ui_react_1.Statistic, { horizontal: true, size: 'tiny', label: 'DB', value: number_parser_1.splitToNumberString((_a = this.state.listItems) === null || _a === void 0 ? void 0 : _a.length) })),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(invite_manager_component_1.InviteManagerComponent, { shopId: (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.shop) === null || _c === void 0 ? void 0 : _c._id, onSuccess: function () { return _this.fetchManagers(); } }))),
                React.createElement(semantic_ui_react_1.Grid.Row, null, this.renderList())));
    };
    ManagersComponent.prototype.renderList = function () {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "N\u00E9v"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Email c\u00EDm"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Regisztr\u00E1ci\u00F3 ideje"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kezel\u0151 szerkeszt\u00E9se"))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2 }, this.renderItems()));
    };
    ManagersComponent.prototype.renderItems = function () {
        var _this = this;
        return this.state.listItems.map(function (i) { return _this.renderItem(i); });
    };
    ManagersComponent.prototype.renderItem = function (item) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        return [
            React.createElement(semantic_ui_react_1.Table.Row, { className: 'cursor-pointer', key: (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a._id, verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, ((_c = (_b = item === null || item === void 0 ? void 0 : item.user) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.lastName) + " " + ((_e = (_d = item === null || item === void 0 ? void 0 : item.user) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.firstName)),
                React.createElement(semantic_ui_react_1.Table.Cell, null, "" + ((_f = item === null || item === void 0 ? void 0 : item.user) === null || _f === void 0 ? void 0 : _f.email)),
                React.createElement(semantic_ui_react_1.Table.Cell, null, (item === null || item === void 0 ? void 0 : item.acceptedAt) ? moment(item === null || item === void 0 ? void 0 : item.acceptedAt).format('YYYY.MM.DD. HH:mm') :
                    React.createElement(semantic_ui_react_1.Button, { loading: this.state.loading, disabled: this.state.loading, content: 'Meghívó újraküldése', color: 'blue', basic: true, onClick: function () { var _a; return _this.resendInvitation((_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a._id); } })),
                React.createElement(semantic_ui_react_1.Table.Cell, null,
                    React.createElement(semantic_ui_react_1.Button, { content: 'Kezelő törlése', loading: this.state.loading, disabled: this.state.loading, basic: true, color: 'red', onClick: function () { var _a; return _this.removeUser((_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a._id); } }))),
        ];
    };
    ManagersComponent.prototype.resendInvitation = function (userId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.repository.put("/s/" + ((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a._id) + "/shop/resendManagerInvitation", {
                                data: {
                                    userId: userId
                                }
                            })];
                    case 1:
                        _b.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success('Sikeres meghívás');
                        return [3, 3];
                    case 2:
                        e_2 = _b.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ManagersComponent.prototype.removeManager = function (userId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        return [4, this.repository.delete("/s/" + ((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a._id) + "/shop/removeManager", {
                                data: {
                                    userId: userId
                                }
                            })];
                    case 1:
                        _b.sent();
                        return [4, this.fetchManagers()];
                    case 2:
                        _b.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success('Sikeres törlés');
                        return [3, 4];
                    case 3:
                        e_3 = _b.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ManagersComponent.prototype.removeUser = function (userId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        this.setState({ loading: true });
                        return [4, this.repository.delete("/s/" + ((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a._id) + "/shop/removeUser", {
                                data: {
                                    userId: userId
                                }
                            })];
                    case 1:
                        _b.sent();
                        return [4, this.fetchManagers()];
                    case 2:
                        _b.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success('Sikeres törlés');
                        return [3, 4];
                    case 3:
                        e_4 = _b.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return ManagersComponent;
}(abstract_component_1.AbstractComponent));
exports.ManagersComponent = ManagersComponent;
