module.exports = {
    'preview.MissingEmail': 'Az előnézet megrekintéséhez válassz egy érdeklődőt',
    'preview.ResultsNotReachedMin': 'Ennél az érdeklődőnél, nem találtunk, a levél tartalmához, megfelelő mennyíségű adatot',
    'preview.UnknownSource': 'Ismeretlen forrás',
    'preview.UnknownSort': 'Ismeretlen rendezés',
    'preview.UnknownMin': 'A megjelenítendő termékek minimum mennyisége nem lett meghatározva',
    'preview.UnknownMax': 'A megjelenítendő termékek maximum mennyisége nem lett meghatározva',
    'preview.UnknownCustomer': 'Ismeretlen érdeklődő',
    'preview.UnknownIntegration': 'Ismeretlen webáruház (integration)',
    'preview.UnknownShop': 'Ismeretlen webáruház (shop)',
    'preview.MissingValue': 'Az email hiányosan lett beállítva (value or parameter)',
    'preview.DisallowedOperator': 'Nem engedélyezett operátor',
    'preview.ValueMustBeAString': 'A megadott forrásparaméternek szövegnek kell lennnie',
    'preview.UserHasNoOrders': 'Az érdeklődőnek nincsenek megrendelései',
    'preview.UserHasNoLastOrder': 'Az érdeklődőnek nincsenek megrendelései',
    'preview.UnableToGetBestProductFromLastOrder': 'A levél egy kiemelt terméke, nem megjeleníthető a felhasználó számára (product)',
    'preview.BestProductHasNoCategories': 'A levél egy kiemelt terméke, nem megjeleníthető a felhasználó számára (product-category)',
    'preview.UnableToGetBestProductCategories': 'A levél egy kiemelt terméke, nem megjeleníthető a felhasználó számára (fetch product-category)',
    'preview.Min3RecommendedCategoryRequired': 'A levélben szereplő termékhez, nem tartozik megfelelő mennyíségű ajálnható kategória',
    'preview.MinRecommendedCategoryRequired': 'A levélben szereplő termékhez, nem tartozik megfelelő mennyíségű ajálnható kategória',
    'preview.NoProductsToShow': 'Ennél az érdeklődőnél, nem találtunk, a levél tartalmához, megfelelő mennyíségű adatot (product)',
    'preview.NoProcessedEventsWithCartData': 'Ennél az érdeklődőnél, nem találtunk, a levél tartalmához, megfelelő mennyíségű adatot (cart)',
    'preview.NoCartProducts': 'Ennél az érdeklődőnél, nem találtunk, a levél tartalmához, megfelelő mennyíségű adatot (cart-product)',
    'preview.ThereIsNoOrdersOrderedInLast23Hours': 'Ennél az érdeklődőnél, nem találtunk, a levél tartalmához, megfelelő mennyíségű adatot (order-23)',
    'preview.ThereIsNoProductsOrderedInLast23Hours': 'Ennél az érdeklődőnél, nem találtunk, a levél tartalmához, megfelelő mennyíségű adatot (order-product-23)',
    'preview.InvalidValueFrom': 'Az email hiányosan lett beállítva (value or parameter)',
    'preview.InvalidValueTo': 'Az email hiányosan lett beállítva (value or parameter)',
    'preview.InvalidValue': 'Az email hiányosan lett beállítva (value or parameter)',
    'preview.InvalidStartOrEndValue': 'Az email hiányosan lett beállítva (value or parameter)',
    'preview.NoProcessedEvent': 'Ennél az érdeklődőnél, nem találtunk, a levél tartalmához, megfelelő mennyíségű adatot (p-event)',
};
