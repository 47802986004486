"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatsModal = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var chart_js_1 = require("chart.js");
var react_chartjs_2_1 = require("react-chartjs-2");
var chartjs_plugin_datalabels_1 = require("chartjs-plugin-datalabels");
chart_js_1.Chart.register(chart_js_1.ArcElement, chart_js_1.Tooltip, chart_js_1.Legend, chartjs_plugin_datalabels_1.default);
var getEmptyLevel = function (level) {
    return {
        amount: 0,
        count: 0,
        relatedToMILevel: level
    };
};
var formatRawDataForPieChart = function (rawData) {
    var _a, _b, _c, _d;
    var formattedData = [];
    formattedData[0] = {
        amount: (((_a = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _a === void 0 ? void 0 : _a.amount) || 0) + (((_b = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _b === void 0 ? void 0 : _b.amount) || 0),
        count: (((_c = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _c === void 0 ? void 0 : _c.count) || 0) + (((_d = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _d === void 0 ? void 0 : _d.count) || 0),
        relatedToMILevel: 0
    };
    formattedData[1] = rawData.find(function (object) { return object.relatedToMILevel === 1; }) || getEmptyLevel(1);
    formattedData[2] = rawData.find(function (object) { return object.relatedToMILevel === 2; }) || getEmptyLevel(2);
    formattedData[3] = rawData.find(function (object) { return object.relatedToMILevel === 3; }) || getEmptyLevel(3);
    return formattedData.filter(function (i) { return !!i; });
};
var formatAsPercentage = function (value, all) {
    return (value / all * 100).toFixed(2) + "%";
};
var formatLabelsForPieChart = function (rawData) {
    var _a, _b, _c, _d;
    var formattedLabels = [];
    var sumCounts = rawData.reduce(function (previous, current) {
        return previous + current.count;
    }, 0);
    var valueForLevel0 = {
        amount: (((_a = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _a === void 0 ? void 0 : _a.amount) || 0) + (((_b = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _b === void 0 ? void 0 : _b.amount) || 0),
        count: (((_c = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _c === void 0 ? void 0 : _c.count) || 0) + (((_d = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _d === void 0 ? void 0 : _d.count) || 0),
        relatedToMILevel: 0
    };
    if (valueForLevel0) {
        formattedLabels[0] = "Nem k\u00F6thet\u0151 (" + formatAsPercentage(valueForLevel0.count, sumCounts) + ")";
    }
    else {
        formattedLabels[0] = "Nem k\u00F6thet\u0151 (0%)";
    }
    var valueForLevel1 = rawData.find(function (object) { return object.relatedToMILevel === 1; });
    if (valueForLevel1) {
        formattedLabels[1] = "Feltehet\u0151en (" + formatAsPercentage(valueForLevel1.count, sumCounts) + ")";
    }
    else {
        formattedLabels[1] = "Feltehet\u0151en (0%)";
    }
    var valueForLevel2 = rawData.find(function (object) { return object.relatedToMILevel === 2; });
    if (valueForLevel2) {
        formattedLabels[2] = "Er\u0151sen (" + formatAsPercentage(valueForLevel2.count, sumCounts) + ")";
    }
    else {
        formattedLabels[2] = "Er\u0151sen (0%)";
    }
    var valueForLevel3 = rawData.find(function (object) { return object.relatedToMILevel === 3; });
    if (valueForLevel3) {
        formattedLabels[3] = "Bizony\u00EDthat\u00F3an (" + formatAsPercentage(valueForLevel3.count, sumCounts) + ")";
    }
    else {
        formattedLabels[3] = "Bizony\u00EDthat\u00F3an (0%)";
    }
    return formattedLabels;
};
var OrderStatsModal = (function (_super) {
    __extends(OrderStatsModal, _super);
    function OrderStatsModal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            processedChartData: {
                labels: formatLabelsForPieChart(props.orderStats),
                datasets: [
                    {
                        label: 'Bevétel összege, darabszám',
                        data: formatRawDataForPieChart(props.orderStats).map(function (object) { return (object === null || object === void 0 ? void 0 : object.amount) || 0; }),
                        backgroundColor: [
                            'rgba(189, 189, 189, 1)',
                            'rgba(251, 189, 8, 1)',
                            'rgba(15, 18, 52, 1)',
                            'rgba(33, 186, 69, 1)'
                        ],
                        borderColor: [
                            'rgba(189, 189, 189, 1)',
                            'rgba(251, 189, 8, 1)',
                            'rgba(15, 18, 52, 1)',
                            'rgba(33, 186, 69, 1)'
                        ],
                        borderWidth: 1,
                    },
                ],
            },
        };
        return _this;
    }
    OrderStatsModal.prototype.render = function () {
        var _this = this;
        var _a;
        if (this.props.isLoading) {
            return (React.createElement("div", { className: 'Loader' },
                React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' })));
        }
        if (!this.props.orderStats || ((_a = this.props.orderStats) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return (React.createElement("div", null, "Nincsenek el\u00E9rhet\u0151 statisztikai adatok."));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Modal.Header, { key: 0 }, "Rendel\u00E9sek \u00F6sszegz\u00E9se"),
            React.createElement(semantic_ui_react_1.Modal.Content, { key: 1 },
                React.createElement("div", { style: {
                        height: "70vh"
                    } },
                    React.createElement(react_chartjs_2_1.Pie, { data: this.state.processedChartData, options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                datalabels: {
                                    color: 'black',
                                    labels: {
                                        value: {
                                            color: 'black',
                                            backgroundColor: 'white',
                                            font: {
                                                size: 24
                                            }
                                        }
                                    },
                                    formatter: function (value, context) {
                                        var _a;
                                        var currency = new Intl.NumberFormat("hu-HU", {
                                            style: "currency",
                                            currency: "HUF",
                                        }).format(value);
                                        var count = ((_a = formatRawDataForPieChart(_this.props.orderStats)[context.dataIndex]) === null || _a === void 0 ? void 0 : _a.count) || 0;
                                        return currency + ", " + count + " darab";
                                    },
                                    anchor: 'center',
                                    align: 'end',
                                    textAlign: 'end'
                                },
                                legend: {
                                    position: 'top',
                                    labels: {
                                        font: {
                                            size: 16
                                        }
                                    }
                                }
                            }
                        }, plugins: [chartjs_plugin_datalabels_1.default] })))));
    };
    return OrderStatsModal;
}(abstract_component_1.AbstractComponent));
exports.OrderStatsModal = OrderStatsModal;
