"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoListWebshopDataTabContentComponent = void 0;
var React = require("react");
var axios_1 = require("axios");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var errors_constant_1 = require("../../errors/errors.constant");
var environment_1 = require("@codebuild/uikit/libs/environment");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var session_storage_1 = require("../../../../libs/session.storage");
var todo_list_tab_content_container_component_1 = require("./todo-list-tab-content-container.component");
var mini_crm_countries_1 = require("./mini-crm.countries");
var toast_util_1 = require("../../errors/toast.util");
var blacklistedEmailDomains = [
    'gmail.com',
    'outlook.com',
    'yahoo.com',
    'protonmail.com',
    'aol.com',
    'gmx.com',
    'yandex.com',
    'freemail.hu',
    'citromail.hu',
    'indamail.hu',
    'freestart.hu',
    't-online.hu',
    'vipmail.hu ',
];
var TodoListWebshopDataTabContentComponent = (function (_super) {
    __extends(TodoListWebshopDataTabContentComponent, _super);
    function TodoListWebshopDataTabContentComponent(props) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22;
        var _this = _super.call(this, props) || this;
        _this.taxNumberRegex = /^[0-9]{8}-[0-9]-[0-9]{2}$/;
        _this.HUN = mini_crm_countries_1.miniCrmCountries.find(function (country) { return country.key === 36; });
        _this.handleInputChange = function (event, fieldGroup, fieldName) {
            var formData = _this.state.formData;
            var updatedFormData = __assign({}, formData);
            updatedFormData[fieldGroup][fieldName] = event.target.value;
            _this.setState({
                formData: updatedFormData
            });
        };
        _this.handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var valid, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        console.log('handlesubmit');
                        return [4, this.form.validate()];
                    case 1:
                        valid = _d.sent();
                        _b = (_a = console).log;
                        _c = ['handleSubmit valid', valid];
                        return [4, this.form.getErrors()];
                    case 2:
                        _b.apply(_a, _c.concat([_d.sent()]));
                        if (!valid) {
                            console.warn('Form is invalid! We do not send API request...');
                        }
                        else {
                            this.mutateWebshopSettings();
                        }
                        return [2];
                }
            });
        }); };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                companyName: new field_1.Field({
                    name: 'companyName',
                    placeholder: '',
                    label: 'Cégnév',
                    value: ((_c = (_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.billingData) === null || _c === void 0 ? void 0 : _c.companyName) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                taxNumber: new field_1.Field({
                    name: 'taxNumber',
                    placeholder: '12345678-9-12',
                    label: 'Adószám',
                    value: ((_f = (_e = (_d = _this.state) === null || _d === void 0 ? void 0 : _d.shop) === null || _e === void 0 ? void 0 : _e.billingData) === null || _f === void 0 ? void 0 : _f.taxNumber) || ''
                }),
                euTaxNumber: new field_1.Field({
                    name: 'euTaxNumber',
                    placeholder: '',
                    label: 'EU Adószám',
                    value: ((_j = (_h = (_g = _this.state) === null || _g === void 0 ? void 0 : _g.shop) === null || _h === void 0 ? void 0 : _h.billingData) === null || _j === void 0 ? void 0 : _j.euTaxNumber) || ''
                }),
                country: new field_1.Field({
                    name: 'country',
                    placeholder: '',
                    label: 'Ország',
                    options: mini_crm_countries_1.miniCrmCountries,
                    value: ((_m = (_l = (_k = _this.state) === null || _k === void 0 ? void 0 : _k.shop) === null || _l === void 0 ? void 0 : _l.billingData) === null || _m === void 0 ? void 0 : _m.country) || _this.HUN.value,
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                city: new field_1.Field({
                    name: 'city',
                    placeholder: '',
                    label: 'Város',
                    value: ((_q = (_p = (_o = _this.state) === null || _o === void 0 ? void 0 : _o.shop) === null || _p === void 0 ? void 0 : _p.billingData) === null || _q === void 0 ? void 0 : _q.city) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                zipCode: new field_1.Field({
                    name: 'zipCode',
                    placeholder: '',
                    label: 'Irányítószám',
                    value: ((_t = (_s = (_r = _this.state) === null || _r === void 0 ? void 0 : _r.shop) === null || _s === void 0 ? void 0 : _s.billingData) === null || _t === void 0 ? void 0 : _t.zipCode) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                address: new field_1.Field({
                    name: 'address',
                    placeholder: '',
                    label: 'Utca',
                    value: ((_w = (_v = (_u = _this.state) === null || _u === void 0 ? void 0 : _u.shop) === null || _v === void 0 ? void 0 : _v.billingData) === null || _w === void 0 ? void 0 : _w.address) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                contactFirstName: new field_1.Field({
                    name: 'contactFirstName',
                    placeholder: '',
                    label: 'Kapcsolattartó keresztneve',
                    value: ((_z = (_y = (_x = _this.state) === null || _x === void 0 ? void 0 : _x.shop) === null || _y === void 0 ? void 0 : _y.miniCrmSettings) === null || _z === void 0 ? void 0 : _z.contactFirstName) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                contactLastName: new field_1.Field({
                    name: 'contactLastName',
                    placeholder: '',
                    label: 'Kapcsolattartó vezetékneve',
                    value: ((_2 = (_1 = (_0 = _this.state) === null || _0 === void 0 ? void 0 : _0.shop) === null || _1 === void 0 ? void 0 : _1.miniCrmSettings) === null || _2 === void 0 ? void 0 : _2.contactLastName) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                contactEmail: new field_1.Field({
                    name: 'contactEmail',
                    placeholder: '',
                    label: 'Kapcsolattartó e-mail címe',
                    value: ((_5 = (_4 = (_3 = _this.state) === null || _3 === void 0 ? void 0 : _3.shop) === null || _4 === void 0 ? void 0 : _4.miniCrmSettings) === null || _5 === void 0 ? void 0 : _5.contactEmail) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                contactPhone: new field_1.Field({
                    name: 'contactPhone',
                    placeholder: '+36...',
                    label: 'Kapcsolattartó telefonszáma (+36…)',
                    value: ((_8 = (_7 = (_6 = _this.state) === null || _6 === void 0 ? void 0 : _6.shop) === null || _7 === void 0 ? void 0 : _7.miniCrmSettings) === null || _8 === void 0 ? void 0 : _8.contactPhone) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                newsLetterSenderName: new field_1.Field({
                    name: 'newsLetterSenderName',
                    placeholder: '',
                    label: 'Hírlevél küldő neve (ez szerepel majd a hírlevelek feladójaként)',
                    value: ((_11 = (_10 = (_9 = _this.state) === null || _9 === void 0 ? void 0 : _9.shop) === null || _10 === void 0 ? void 0 : _10.miniCrmSettings) === null || _11 === void 0 ? void 0 : _11.newsLetterSenderName) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                newsLetterSenderEmail: new field_1.Field({
                    name: 'newsLetterSenderEmail',
                    placeholder: '',
                    label: 'Hírlevél küldő e-mail címe',
                    value: ((_14 = (_13 = (_12 = _this.state) === null || _12 === void 0 ? void 0 : _12.shop) === null || _13 === void 0 ? void 0 : _13.miniCrmSettings) === null || _14 === void 0 ? void 0 : _14.newsLetterSenderEmail) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                subdomain: new field_1.Field({
                    name: 'subdomain',
                    placeholder: '',
                    label: 'Subdomain',
                    value: ((_17 = (_16 = (_15 = _this.state) === null || _15 === void 0 ? void 0 : _15.shop) === null || _16 === void 0 ? void 0 : _16.miniCrmSettings) === null || _17 === void 0 ? void 0 : _17.subdomain) || ''
                }),
                customerServicePhoneNumber: new field_1.Field({
                    name: 'customerServicePhoneNumber',
                    placeholder: '+36...',
                    label: 'Ügyfélszolgálat telefonszáma (+36…)',
                    value: ((_20 = (_19 = (_18 = _this.state) === null || _18 === void 0 ? void 0 : _18.shop) === null || _19 === void 0 ? void 0 : _19.miniCrmSettings) === null || _20 === void 0 ? void 0 : _20.customerServicePhoneNumber) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                webshopUrl: new field_1.Field({
                    name: 'webshopUrl',
                    placeholder: 'https://...',
                    label: 'Webshop URL (https://...)',
                    value: ((_22 = (_21 = _this.state) === null || _21 === void 0 ? void 0 : _21.shop) === null || _22 === void 0 ? void 0 : _22.url) || ''
                }),
            },
        });
        _this.state = {
            isLoading: false,
            webshopDataFormTab: 1,
            invalidStep1: false,
            invalidStep2: false,
            isBlacklistedEmailDomain: false,
            isTaxNumberDisabled: false,
            isEuTaxNumberDisabled: false,
            isCountryDisabled: false
        };
        return _this;
    }
    TodoListWebshopDataTabContentComponent.prototype.isEmailBlacklisted = function (email) {
        if (!email.includes('@')) {
            return false;
        }
        var parts = email.split('@');
        var domain = parts[1];
        if (domain.length > 0) {
            return blacklistedEmailDomains.includes(domain);
        }
        return false;
    };
    TodoListWebshopDataTabContentComponent.prototype.componentDidMount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('TodoListWebshopDataTabContentComponent isWebshopDataSaved', (_a = this.props) === null || _a === void 0 ? void 0 : _a.isWebshopDataSaved);
                        return [4, this.fetchWebshopSettings()];
                    case 1:
                        _b.sent();
                        this.subscribeToTaxNumberFormValueChanges();
                        this.subscribeToEuTaxNumberFormValueChanges();
                        this.subscribeToNewsLetterSenderEmailFormValueChanges();
                        this.subscribeToCountryFormValueChanges();
                        return [2];
                }
            });
        });
    };
    TodoListWebshopDataTabContentComponent.prototype.subscribeToTaxNumberFormValueChanges = function () {
        var _this = this;
        this.form.fields.taxNumber.$value.subscribe(function (currentValue) {
            _this.form.fields.country.options = mini_crm_countries_1.miniCrmCountries.filter(function (country) {
                var _a;
                if (currentValue === null || currentValue === void 0 ? void 0 : currentValue.length) {
                    return country.key === 36;
                }
                if ((_a = _this.form.fields.euTaxNumber.value) === null || _a === void 0 ? void 0 : _a.length) {
                    return country.key !== 36;
                }
                return true;
            });
            if (currentValue === null || currentValue === void 0 ? void 0 : currentValue.length) {
                _this.form.fields.country.setValue(_this.HUN.value);
                _this.form.fields.euTaxNumber.setValue('');
                _this.setState({ isCountryDisabled: true });
                _this.setState({ isEuTaxNumberDisabled: true });
            }
            else {
                _this.setState({ isCountryDisabled: false });
                _this.setState({ isEuTaxNumberDisabled: false });
            }
        });
    };
    TodoListWebshopDataTabContentComponent.prototype.subscribeToEuTaxNumberFormValueChanges = function () {
        var _this = this;
        this.form.fields.euTaxNumber.$value.subscribe(function (currentValue) {
            _this.form.fields.country.options = mini_crm_countries_1.miniCrmCountries.filter(function (country) {
                var _a;
                if ((_a = _this.form.fields.taxNumber.value) === null || _a === void 0 ? void 0 : _a.length) {
                    return country.key === 36;
                }
                if (currentValue === null || currentValue === void 0 ? void 0 : currentValue.length) {
                    return country.key !== 36;
                }
                return true;
            });
            if (currentValue === null || currentValue === void 0 ? void 0 : currentValue.length) {
                _this.form.fields.taxNumber.setValue('');
                if (_this.form.fields.country.value === _this.HUN.value) {
                    _this.form.fields.country.setValue('');
                }
                _this.setState({ isCountryDisabled: false });
                _this.setState({ isTaxNumberDisabled: true });
            }
            else {
                _this.setState({ isCountryDisabled: false });
                _this.setState({ isTaxNumberDisabled: false });
            }
        });
    };
    TodoListWebshopDataTabContentComponent.prototype.subscribeToCountryFormValueChanges = function () {
        var _this = this;
        this.form.fields.country.$value.subscribe(function (currentValue) {
            if (currentValue === _this.HUN.value) {
                _this.form.fields.euTaxNumber.setValue('');
                _this.setState({ isEuTaxNumberDisabled: true });
                _this.setState({ isTaxNumberDisabled: false });
            }
            else {
                _this.form.fields.taxNumber.setValue('');
                _this.setState({ isEuTaxNumberDisabled: false });
                _this.setState({ isTaxNumberDisabled: true });
            }
        });
    };
    TodoListWebshopDataTabContentComponent.prototype.subscribeToNewsLetterSenderEmailFormValueChanges = function () {
        var _this = this;
        var _a, _b;
        (_b = (_a = this.form.fields) === null || _a === void 0 ? void 0 : _a.newsLetterSenderEmail) === null || _b === void 0 ? void 0 : _b.$value.subscribe(function (currentValue) {
            var isBlacklisted = _this.isEmailBlacklisted(currentValue);
            console.log('newsLetterSenderEmail', currentValue, isBlacklisted);
            _this.setState({
                isBlacklistedEmailDomain: isBlacklisted
            });
            if (!isBlacklisted) {
                var calculatedValue = '';
                if (currentValue && currentValue.includes('@')) {
                    var splittedByAtChar = currentValue.split('@');
                    var domainPart = (splittedByAtChar === null || splittedByAtChar === void 0 ? void 0 : splittedByAtChar[1]) || '';
                    if (domainPart.length > 0) {
                        calculatedValue = "shop." + domainPart;
                    }
                }
                console.log('$value.subscribe calculatedValue', calculatedValue);
                _this.form.fields.subdomain.setValue(calculatedValue);
            }
        });
    };
    TodoListWebshopDataTabContentComponent.prototype.fetchWebshopSettings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop, api, API_URL, response, responseData, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            isLoading: true
                        });
                        shop = this.getCurrentShop();
                        api = environment_1.Environment.get('shoprenterApiRootUrl');
                        API_URL = api + "/marketplace/shop/" + shop._id + "/settings";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, axios_1.default.get(API_URL)];
                    case 2:
                        response = _a.sent();
                        responseData = response.data;
                        this.setState({
                            formData: responseData
                        });
                        this.updateFormData();
                        return [3, 5];
                    case 3:
                        error_1 = _a.sent();
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_FETCH_WEBSHOP_SETTINGS);
                        console.error('API error:', error_1);
                        return [3, 5];
                    case 4:
                        this.setState({
                            isLoading: false
                        });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    TodoListWebshopDataTabContentComponent.prototype.mutateWebshopSettings = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var shop, api, API_URL, jsonFormData, address, city, companyName, country, euTaxNumber, taxNumber, zipCode, billingData, billingPeriod, customerId, isReverseCharge, miLastStarted, paymentDeadline, contactEmail, contactPhone, newsLetterSenderEmail, contactFirstName, contactLastName, newsLetterSenderName, subdomain, customerServicePhoneNumber, leftFromAtChar, backgroundMutatedSenderEmail, miniCrmSettings, webshopUrl, response, responseData, error_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.setState({
                            isLoading: true
                        });
                        shop = this.getCurrentShop();
                        api = environment_1.Environment.get('shoprenterApiRootUrl');
                        API_URL = api + "/marketplace/shop/" + shop._id + "/settings";
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, 4, 5]);
                        jsonFormData = this.form.toJSON();
                        console.log('mutateWebshopSettings jsonFormData', jsonFormData);
                        address = jsonFormData.address, city = jsonFormData.city, companyName = jsonFormData.companyName, country = jsonFormData.country, euTaxNumber = jsonFormData.euTaxNumber, taxNumber = jsonFormData.taxNumber, zipCode = jsonFormData.zipCode;
                        billingData = {
                            address: address,
                            city: city,
                            companyName: companyName,
                            country: country,
                            euTaxNumber: euTaxNumber,
                            taxNumber: taxNumber,
                            zipCode: zipCode,
                        };
                        billingPeriod = jsonFormData.billingPeriod, customerId = jsonFormData.customerId, isReverseCharge = jsonFormData.isReverseCharge, miLastStarted = jsonFormData.miLastStarted, paymentDeadline = jsonFormData.paymentDeadline, contactEmail = jsonFormData.contactEmail, contactPhone = jsonFormData.contactPhone, newsLetterSenderEmail = jsonFormData.newsLetterSenderEmail, contactFirstName = jsonFormData.contactFirstName, contactLastName = jsonFormData.contactLastName, newsLetterSenderName = jsonFormData.newsLetterSenderName, subdomain = jsonFormData.subdomain, customerServicePhoneNumber = jsonFormData.customerServicePhoneNumber;
                        leftFromAtChar = ((_a = newsLetterSenderEmail.split('@')) === null || _a === void 0 ? void 0 : _a[0]) || '';
                        backgroundMutatedSenderEmail = leftFromAtChar + "@" + subdomain;
                        console.log('User inserted sender email:', newsLetterSenderEmail);
                        console.log('Left part of the sender email:', leftFromAtChar);
                        console.log('Background mutated sender email:', backgroundMutatedSenderEmail);
                        miniCrmSettings = {
                            billingPeriod: billingPeriod,
                            customerId: customerId,
                            isReverseCharge: isReverseCharge,
                            miLastStarted: miLastStarted,
                            paymentDeadline: paymentDeadline,
                            contactEmail: contactEmail,
                            contactPhone: contactPhone,
                            contactFirstName: contactFirstName,
                            contactLastName: contactLastName,
                            newsLetterSenderName: newsLetterSenderName,
                            subdomain: subdomain,
                            customerServicePhoneNumber: customerServicePhoneNumber,
                            newsLetterSenderEmail: backgroundMutatedSenderEmail,
                        };
                        webshopUrl = jsonFormData.webshopUrl;
                        console.log('mutateWebshopSettings', { jsonFormData: jsonFormData, billingData: billingData, miniCrmSettings: miniCrmSettings, webshopUrl: webshopUrl });
                        return [4, axios_1.default.put(API_URL, { billingData: billingData, miniCrmSettings: miniCrmSettings, webshopUrl: webshopUrl })];
                    case 2:
                        response = _d.sent();
                        console.log('mutateWebshopSettings response', response);
                        this.form.updateFields(response);
                        responseData = response.data;
                        this.setState({
                            formData: responseData
                        });
                        this.updateFormData();
                        (_c = (_b = this.props) === null || _b === void 0 ? void 0 : _b.refetch) === null || _c === void 0 ? void 0 : _c.call(_b);
                        router_provider_1.RouterProvider.updateQuery({ tab: 1 });
                        return [3, 5];
                    case 3:
                        error_2 = _d.sent();
                        console.error('API error:', error_2);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_UPDATE_WEBSHOP_SETTINGS);
                        return [3, 5];
                    case 4:
                        this.setState({
                            isLoading: false
                        });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    TodoListWebshopDataTabContentComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    TodoListWebshopDataTabContentComponent.prototype.updateFormData = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        console.log('state formData', this.state.formData);
        if ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.formData) === null || _b === void 0 ? void 0 : _b.webshopUrl) {
            this.form.fields.webshopUrl.setValue(this.state.formData.webshopUrl);
        }
        var _loop_1 = function (key) {
            if (!((_c = this_1.state) === null || _c === void 0 ? void 0 : _c.formData) || (!((_d = this_1.state) === null || _d === void 0 ? void 0 : _d.formData.billingData) && !((_e = this_1.state) === null || _e === void 0 ? void 0 : _e.formData.miniCrmSettings))) {
                return "continue";
            }
            var settingsGroup = this_1.state.formData.billingData.hasOwnProperty(key)
                ? (_f = this_1.state) === null || _f === void 0 ? void 0 : _f.formData.billingData : (_g = this_1.state) === null || _g === void 0 ? void 0 : _g.formData.miniCrmSettings;
            if (settingsGroup[key]) {
                if (key === 'country') {
                    var formCountry = mini_crm_countries_1.miniCrmCountries.find(function (country) { return country.text === settingsGroup[key]; });
                    this_1.form.fields[key].setValue(formCountry === null || formCountry === void 0 ? void 0 : formCountry.value);
                }
                else {
                    this_1.form.fields[key].setValue(settingsGroup[key]);
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _h = Object.keys(this.form.fields); _i < _h.length; _i++) {
            var key = _h[_i];
            _loop_1(key);
        }
    };
    TodoListWebshopDataTabContentComponent.prototype.renderWebshopDataFormStepper = function () {
        var _this = this;
        var isFormDisabled = this.props.isWebshopDataSaved;
        return React.createElement(semantic_ui_react_1.Container, { fluid: true },
            React.createElement(semantic_ui_react_1.Step.Group, { widths: 3 },
                React.createElement(semantic_ui_react_1.Step, { active: this.state.webshopDataFormTab === 1 },
                    React.createElement(semantic_ui_react_1.Icon, { name: "address card" }),
                    React.createElement(semantic_ui_react_1.Step.Content, null,
                        React.createElement(semantic_ui_react_1.Step.Title, { style: { color: '#051D40' } }, "KAPCSOLATTART\u00C1SI ADATOK"))),
                React.createElement(semantic_ui_react_1.Step, { active: this.state.webshopDataFormTab === 2 },
                    React.createElement(semantic_ui_react_1.Icon, { name: "warehouse" }),
                    React.createElement(semantic_ui_react_1.Step.Content, null,
                        React.createElement(semantic_ui_react_1.Step.Title, { style: { color: '#051D40' } }, "C\u00C9GADATOK"))),
                React.createElement(semantic_ui_react_1.Step, { active: this.state.webshopDataFormTab === 3 },
                    React.createElement(semantic_ui_react_1.Icon, { name: "envelope outline" }),
                    React.createElement(semantic_ui_react_1.Step.Content, null,
                        React.createElement(semantic_ui_react_1.Step.Title, { style: { color: '#051D40' } }, "EMAIL ADATOK")))),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement("div", { hidden: this.state.webshopDataFormTab !== 1, style: {
                        backgroundColor: '#fff',
                        padding: '16px',
                        paddingBottom: '16px',
                        border: '1px solid rgba(34, 36, 38, 0.15)',
                        marginTop: '-17px',
                        color: '#051D40'
                    } },
                    React.createElement(semantic_ui_react_1.Grid, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.contactLastName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.contactFirstName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true }))),
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.contactEmail, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { type: "email", control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.contactPhone, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true, title: "+36xxxxxxxxx" })))),
                    React.createElement(semantic_ui_react_1.Message, { negative: true, style: { marginTop: '16px' }, hidden: !this.state.invalidStep1 },
                        React.createElement("p", null, "K\u00E9rlek n\u00E9zd \u00E1t az \u0171rlapot!")),
                    React.createElement(semantic_ui_react_1.Divider, { hidden: true }),
                    React.createElement(semantic_ui_react_1.Container, { fluid: true, textAlign: "right", hidden: this.state.isFormDisabled },
                        React.createElement(semantic_ui_react_1.Button, { onClick: function () {
                                _this.stepForward(2);
                            }, className: "dashboard-save-button", type: "submit" }, "Tov\u00E1bb"))),
                React.createElement("div", { hidden: this.state.webshopDataFormTab !== 2, style: {
                        backgroundColor: '#fff',
                        padding: '16px',
                        paddingBottom: '16px',
                        border: '1px solid rgba(34, 36, 38, 0.15)',
                        marginTop: '-17px'
                    } },
                    React.createElement(semantic_ui_react_1.Grid, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.companyName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.taxNumber, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled || this.state.isTaxNumberDisabled, required: false })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.euTaxNumber, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled || this.state.isEuTaxNumberDisabled, required: false }))),
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.country, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Dropdown, fluid: true, search: true, selection: true, disabled: isFormDisabled || this.state.isCountryDisabled, required: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.city, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.zipCode, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true, title: "1234" })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.address, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true })))),
                    React.createElement(semantic_ui_react_1.Message, { negative: true, style: { marginTop: '16px' }, hidden: !this.state.invalidStep2 },
                        React.createElement("p", null, "K\u00E9rlek n\u00E9zd \u00E1t az \u0171rlapot!")),
                    React.createElement(semantic_ui_react_1.Divider, { hidden: true }),
                    React.createElement(semantic_ui_react_1.Container, { fluid: true, textAlign: "right", hidden: this.state.isFormDisabled },
                        React.createElement(semantic_ui_react_1.Button, { onClick: function () {
                                _this.setState({ webshopDataFormTab: 1 });
                            }, type: "button", className: "back-button" }, "Vissza"),
                        React.createElement(semantic_ui_react_1.Button, { onClick: function () {
                                _this.stepForward(3);
                            }, className: "dashboard-save-button", type: "submit" }, "Tov\u00E1bb"))),
                React.createElement("div", { hidden: this.state.webshopDataFormTab !== 3, style: {
                        backgroundColor: '#fff',
                        padding: '16px',
                        paddingBottom: '16px',
                        border: '1px solid rgba(34, 36, 38, 0.15)',
                        marginTop: '-17px'
                    } },
                    React.createElement(semantic_ui_react_1.Message, { info: true, style: { color: '#051D40' } },
                        React.createElement("p", null, "M\u00E1r csak egy e-mail c\u00EDmre \u00E9s telefonsz\u00E1mra van sz\u00FCks\u00E9gem, ami az \u00E1ltalam gener\u00E1lt levelekbe is beker\u00FClhet, illetve err\u0151l az e-mail c\u00EDmr\u0151l fogom k\u00FCldeni az e-maileket.")),
                    React.createElement(semantic_ui_react_1.Grid, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.newsLetterSenderName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.newsLetterSenderEmail, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true, type: "email" }))),
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.customerServicePhoneNumber, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: isFormDisabled, required: true, title: "+36xxxxxxxxx" })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.webshopUrl, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: true, type: "url" })))),
                    this.state.isBlacklistedEmailDomain === true && (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.Divider, { hidden: true }),
                        React.createElement(semantic_ui_react_1.Container, { fluid: true, textAlign: "left" },
                            React.createElement(semantic_ui_react_1.Message, { info: true, style: { color: '#051D40' } },
                                React.createElement("p", null,
                                    React.createElement("strong", null, "Nem megfelel\u0151 e-mail c\u00EDm!")),
                                React.createElement("p", null, "A megadott email c\u00EDm - melyr\u0151l a h\u00EDrleveleket fogjuk kik\u00FCldeni - sajnos nem megfelel\u0151, mivel ennek az email c\u00EDmnek meg kell egyeznie a webshop domain c\u00EDm\u00E9vel! Az is lehets\u00E9ges, hogy olyan email c\u00EDmr\u0151l k\u00FCldj\u00FCk ki a levelet, ami nem egyezik meg a webshop domain c\u00EDm\u00E9vel, de ez esetben sem lehet ez az email c\u00EDm egy ingyenes levelez\u0151 rendszer (pl gmail.com vagy freemail.hu) c\u00EDme, mivel ehhez a domain n\u00E9vhez l\u00E9tre kell majd hozni DNS bejegyz\u00E9seket."),
                                React.createElement("p", null, "K\u00E9rj\u00FCk, hogy adjon meg egy saj\u00E1t domain n\u00E9vvel rendelkez\u0151 email c\u00EDmet, ami a saj\u00E1t tulajdon\u00E1ban van (ez pl. onnan tudhat\u00F3, hogy fizeti a domain fenntart\u00E1si d\u00EDjat \u00E9vente)."))))),
                    React.createElement(semantic_ui_react_1.Divider, { hidden: true }),
                    React.createElement(semantic_ui_react_1.Container, { fluid: true, textAlign: "right" },
                        React.createElement(semantic_ui_react_1.Button, { onClick: function () {
                                _this.setState({ webshopDataFormTab: 2 });
                            }, type: "button", className: "back-button" }, "Vissza"),
                        React.createElement(semantic_ui_react_1.Button, { loading: this.state.isLoading, onClick: function () { return _this.handleSubmit(); }, content: 'Mentés', className: "dashboard-save-button", disabled: isFormDisabled || this.state.isBlacklistedEmailDomain === true })))),
            React.createElement(semantic_ui_react_1.Divider, { hidden: true }));
    };
    TodoListWebshopDataTabContentComponent.prototype.stepForward = function (to) {
        var from = this.state.webshopDataFormTab;
        if (from === 1 && to === 2) {
            var toValidate = ['contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone'];
            var form = this.form.toJSON();
            if (form[toValidate[0]] !== '' &&
                form[toValidate[1]] !== '' &&
                form[toValidate[2]] !== '' &&
                form[toValidate[3]] !== '') {
                this.setState({ invalidStep1: false });
                this.setState({ webshopDataFormTab: 2 });
            }
            else {
                this.setState({ invalidStep1: true });
            }
        }
        if (from === 2 && to === 3) {
            var toValidate = ['companyName', 'taxNumber', 'country', 'city', 'zipCode', 'address'];
            var form = this.form.toJSON();
            if (form[toValidate[0]] !== '' &&
                (form[toValidate[1]] === '' || this.taxNumberRegex.test(form[toValidate[1]])) &&
                form[toValidate[2]] !== '' &&
                form[toValidate[3]] !== '' &&
                form[toValidate[4]] !== '' &&
                form[toValidate[5]] !== '') {
                this.setState({ invalidStep2: false });
                this.setState({ webshopDataFormTab: 3 });
            }
            else {
                this.setState({ invalidStep2: true });
            }
        }
    };
    TodoListWebshopDataTabContentComponent.prototype.render = function () {
        return (React.createElement(todo_list_tab_content_container_component_1.TodoListTabContentContainerComponent, null,
            React.createElement(semantic_ui_react_1.Message, { info: true, style: { marginTop: '16px', backgroundColor: '#EDF4FF', boxShadow: 'none', color: '#051D40' } },
                React.createElement("p", null,
                    React.createElement("strong", null, "K\u00E9rlek add meg a webshopod legfontosabb alapadatait!")),
                React.createElement("p", null, "K\u00E9sz\u00FClj fel, mert n\u00E9h\u00E1ny \u00F3r\u00E1n bel\u00FCl, m\u00E1r teljes g\u0151zzel dolgozom a web\u00E1ruh\u00E1zad siker\u00E9\u00E9rt. Miel\u0151tt belev\u00E1gunk, k\u00E9rlek, sz\u00E1nj egy pillanatot arra, hogy megadd a sz\u00FCks\u00E9ges alapadatokat! Ne agg\u00F3dj, a folyamat nagyobb r\u00E9sz\u00E9t \u00E9n v\u00E9gzem el helyetted, \u00EDgy te v\u00E9gig a v\u00E1llalkoz\u00E1sodra koncentr\u00E1lhatsz!"),
                React.createElement("p", null,
                    "\uD83D\uDCA1",
                    React.createElement("strong", null, "FONTOS!"),
                    " K\u00E9rlek, az ",
                    React.createElement("strong", null, "e-mail adatokn\u00E1l a web\u00E1ruh\u00E1zad domain-tartom\u00E1ny\u00E1ba tartoz\u00F3"),
                    ", azzal ",
                    React.createElement("strong", null, "megegyez\u0151 e-mail c\u00EDmet"),
                    " adj meg , mert csak \u00EDgy tudom v\u00E9gezni a munk\u00E1mat! \u00A0",
                    React.createElement("div", { style: { color: '#337EFF', fontWeight: 'bold' } }, "(pl. pelda.nev@webshopom.hu)"))),
            this.renderWebshopDataFormStepper()));
    };
    return TodoListWebshopDataTabContentComponent;
}(abstract_component_1.AbstractComponent));
exports.TodoListWebshopDataTabContentComponent = TodoListWebshopDataTabContentComponent;
