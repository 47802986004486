module.exports = {
    'custom-newsletter.screen.title': 'Egyedi hírlevelek',
    'custom-newsletter.screen.search-placeholder': 'Keresés az egyedi hírlevelek között',
    'custom-newsletter.screen.create-button.title': 'Új létrehozása',
    'custom-newsletter.screen.list.name': 'Név',
    'custom-newsletter.screen.list.scheduledAt': 'Ütemezve',
    'custom-newsletter.screen.list.statistics': 'Statisztika',
    'custom-newsletter.screen.list.status': 'Státusz',
    'custom-newsletter.screen.list.createdAt': 'Létrehozva',
    'custom-newsletter.screen.list.contentUpdatedAt': 'Módosítva',
    'custom-newsletter.screen.list-item.status-scheduled': 'időzítve',
    'custom-newsletter.screen.list-item.status-progress': 'előkészítés alatt',
    'custom-newsletter.screen.list-item.status-sent': 'ütemezve',
    'custom-newsletter.screen.list-item.status-draft': 'piszkozat',
    'custom-newsletter.screen.list-item.stats-sent': 'Ütemezve',
    'custom-newsletter.screen.list-item.stats-clicked': 'Kattintott',
    'custom-newsletter.screen.list-item.stats-all-clicks': 'Összes kattintás',
};
