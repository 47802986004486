"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportSubscribersComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var state_repository_1 = require("../../../common/libs/state-repository");
var csv_uploader_1 = require("./libs/csv-uploader");
require("./shop-settings.scss");
var ImportSubscribersComponent = (function (_super) {
    __extends(ImportSubscribersComponent, _super);
    function ImportSubscribersComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            success: [],
            successCount: 0,
            error: [],
            errorCount: 0,
            total: 0
        };
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        return _this;
    }
    ImportSubscribersComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Grid, { columns: 1, className: "w-100" },
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(csv_uploader_1.CsvUploader, { onChange: function (data) { return _this.sendImportData(data); } }))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("h2", null, "Az import\u00E1l\u00E1s t\u00F6bb percig is eltarthat. K\u00E9rj\u00FCk ne friss\u00EDtse az oldalt, mivel az eredm\u00E9nyr\u0151l csak akkor tudjuk \u00E9rtes\u00EDteni!"),
                    React.createElement("h3", null,
                        "P\u00E9lda csv: ",
                        React.createElement("a", { href: "/assets/example-import.csv" }, "example-import.csv")),
                    React.createElement("p", null, "Az import\u00E1l\u00E1s sor\u00E1n k\u00E9t dolog t\u00F6rt\u00E9nik"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "A csv-ben szerepl\u00F6 usereket regisztr\u00E1ljuk az MI rendszer\u00E9be"),
                        React.createElement("li", null, "A csv-ben szerepl\u00F6 usereket fel/leiratkoztatjuk az eredeti web\u00E1ruh\u00E1zban")),
                    React.createElement("p", null, "Abban az esetben, ha a felhaszn\u00E1l\u00F3, m\u00E1r l\u00E9tezik az MI rendszer\u00E9ben, az eredm\u00E9ny azonnali (az MI ben is l\u00E1that\u00F3 lesz, hogy le/feliratkozott), azonban ha \u00FAj felhaszn\u00E1l\u00F3r\u00F3l van sz\u00F3, az eredm\u00E9ny csak a k\u00F6vetkez\u0151 synchroniz\u00E1ci\u00F3 sor\u00E1n v\u00E1lik l\u00E1lthat\u00F3v\u00E1"))),
            React.createElement(semantic_ui_react_1.Grid.Row, null,
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("h3", null,
                        "Total: ",
                        this.state.total,
                        " / ",
                        this.state.successCount + this.state.errorCount))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                React.createElement(semantic_ui_react_1.Grid.Column, { as: semantic_ui_react_1.Segment },
                    React.createElement("h3", null,
                        "Success: ",
                        this.state.successCount),
                    React.createElement(semantic_ui_react_1.Grid.Row, null,
                        React.createElement(semantic_ui_react_1.Grid.Column, { className: "importScroll" }, (this.state.success || []).map(function (item, key) {
                            var _a;
                            return React.createElement(semantic_ui_react_1.Grid.Row, { key: key, verticalAlign: "middle" },
                                React.createElement(semantic_ui_react_1.Grid.Column, null,
                                    React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.item) === null || _a === void 0 ? void 0 : _a.email)));
                        })))),
                React.createElement(semantic_ui_react_1.Grid.Column, { as: semantic_ui_react_1.Segment },
                    React.createElement("h3", null,
                        "Error: ",
                        this.state.errorCount),
                    React.createElement(semantic_ui_react_1.Grid.Row, null,
                        React.createElement(semantic_ui_react_1.Grid.Column, { className: "importScroll" }, (this.state.error || []).map(function (item, key) {
                            var _a, _b;
                            return React.createElement(semantic_ui_react_1.Grid.Row, { key: key, verticalAlign: "middle" },
                                React.createElement(semantic_ui_react_1.Grid.Column, null,
                                    React.createElement("span", null, (_a = item === null || item === void 0 ? void 0 : item.item) === null || _a === void 0 ? void 0 :
                                        _a.email,
                                        " - "),
                                    React.createElement("b", null, ((_b = item === null || item === void 0 ? void 0 : item.error) === null || _b === void 0 ? void 0 : _b.message) || (item === null || item === void 0 ? void 0 : item.error))));
                        }))))));
    };
    ImportSubscribersComponent.prototype.sendImportData = function (data) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var _i, _d, item, result, _e, result_1, resultItem, e_1;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if ((_a = data.errors) === null || _a === void 0 ? void 0 : _a.length) {
                            alert('Hiba a CSV-ben. Nezd meg a console-t');
                            console.log('CSV ERRORS ->', data.errors);
                            return [2];
                        }
                        return [4, this.setState({ total: (data.data || []).length })];
                    case 1:
                        _f.sent();
                        _i = 0, _d = data.data || [];
                        _f.label = 2;
                    case 2:
                        if (!(_i < _d.length)) return [3, 14];
                        item = _d[_i];
                        _f.label = 3;
                    case 3:
                        _f.trys.push([3, 11, , 13]);
                        return [4, this.repository.post("/a/shop/" + this.props.shop._id + "/import-subscribers", {
                                data: {
                                    items: [item]
                                }
                            })];
                    case 4:
                        result = _f.sent();
                        _e = 0, result_1 = result;
                        _f.label = 5;
                    case 5:
                        if (!(_e < result_1.length)) return [3, 10];
                        resultItem = result_1[_e];
                        if (!((_b = resultItem === null || resultItem === void 0 ? void 0 : resultItem.result) === null || _b === void 0 ? void 0 : _b.success)) return [3, 7];
                        return [4, this.setState({
                                successCount: this.state.successCount + 1,
                                success: __spreadArrays([
                                    { error: null, item: (resultItem === null || resultItem === void 0 ? void 0 : resultItem.plan) || {} }
                                ], this.state.success)
                            })];
                    case 6:
                        _f.sent();
                        return [3, 9];
                    case 7: return [4, this.setState({
                            errorCount: this.state.errorCount + 1,
                            error: __spreadArrays([
                                { error: ((_c = resultItem === null || resultItem === void 0 ? void 0 : resultItem.result) === null || _c === void 0 ? void 0 : _c.reason) || '', item: (resultItem === null || resultItem === void 0 ? void 0 : resultItem.plan) || {} }
                            ], this.state.error)
                        })];
                    case 8:
                        _f.sent();
                        _f.label = 9;
                    case 9:
                        _e++;
                        return [3, 5];
                    case 10: return [3, 13];
                    case 11:
                        e_1 = _f.sent();
                        return [4, this.setState({
                                errorCount: this.state.errorCount + 1,
                                error: __spreadArrays([
                                    { error: e_1, item: item }
                                ], this.state.error)
                            })];
                    case 12:
                        _f.sent();
                        return [3, 13];
                    case 13:
                        _i++;
                        return [3, 2];
                    case 14: return [2];
                }
            });
        });
    };
    return ImportSubscribersComponent;
}(abstract_component_1.AbstractComponent));
exports.ImportSubscribersComponent = ImportSubscribersComponent;
