"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterAppDetailsScreen = void 0;
require("../libs/common.scss");
var React = require("react");
var moment = require("moment");
var axios_1 = require("axios");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var list_cleaning_button_component_1 = require("./list-cleaning-button.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var request_sync_button_component_1 = require("./request-sync-button.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var session_storage_1 = require("../../../../libs/session.storage");
var state_repository_1 = require("../../../common/libs/state-repository");
var tax_number_input_component_1 = require("./tax-number-input.component");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var errors_constant_1 = require("../../../shoprenter/errors/errors.constant");
var checkIcon = (React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'mr-3', color: 'green', loading: false, name: 'check circle' }));
var crossIcon = (React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'mr-3', color: 'red', loading: false, name: 'times circle' }));
var exclamationIcon = (React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'mr-3', color: 'yellow', loading: false, name: 'exclamation circle' }));
var ShoprenterAppDetailsScreen = (function (_super) {
    __extends(ShoprenterAppDetailsScreen, _super);
    function ShoprenterAppDetailsScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.commissionForm = new form_builder_1.FormBuilder({
            fields: {
                commission: new field_1.Field({
                    name: 'commission',
                    label: 'commission',
                    placeholder: 'commission',
                    value: 0,
                }),
            }
        });
        _this.shopDiscountForm = new form_builder_1.FormBuilder({
            fields: {
                delay: new field_1.Field({
                    name: 'delay',
                    label: 'Kedvezmény',
                    placeholder: '0',
                    value: 0,
                }),
            }
        });
        _this.mailgunForm = new form_builder_1.FormBuilder({
            fields: {
                mailgunDomain: new field_1.Field({
                    name: 'mailgunDomain',
                    label: 'MailGun domain',
                    placeholder: 'MailGun domain',
                    value: '',
                }),
            }
        });
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            isLoading: false,
            dashboardData: null,
            shop: null,
            actualDiscount: null,
            previousDiscounts: null
        };
        return _this;
    }
    ShoprenterAppDetailsScreen.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    ShoprenterAppDetailsScreen.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b;
        console.log('ShoprenterAppDetailsScreen', (_a = this.props.route.params) === null || _a === void 0 ? void 0 : _a.shopId);
        if (!((_b = this.props.route.params) === null || _b === void 0 ? void 0 : _b.shopId)) {
            router_provider_1.RouterProvider.goTo('/admin/shoprenter-apps');
        }
        this.fetchDashboardDataForShop();
        this.setState({ shop: this.getCurrentShop() });
        this.$subscriptions.push(this.commissionForm.$submitSuccess.subscribe(function () { return _this.handleCommissionSubmit(); }));
        this.$subscriptions.push(this.shopDiscountForm.$submitSuccess.subscribe(function () { return _this.handleShopDiscountSubmit(); }));
        this.$subscriptions.push(this.mailgunForm.$submitSuccess.subscribe(function () { return _this.handleMailGunFormSubmit(); }));
    };
    ShoprenterAppDetailsScreen.prototype.handleMailGunFormSubmit = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shopId, api, API_URL, jsonFormData, mailgunDomain, urlWithCommissionParameter, response, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        shopId = (_a = this.props.route.params) === null || _a === void 0 ? void 0 : _a.shopId;
                        api = environment_1.Environment.get('shoprenterApiRootUrl');
                        API_URL = api + "/marketplace/shop/" + shopId + "/addmailgundomain";
                        jsonFormData = this.mailgunForm.toJSON();
                        mailgunDomain = jsonFormData === null || jsonFormData === void 0 ? void 0 : jsonFormData.mailgunDomain;
                        urlWithCommissionParameter = API_URL + "?mailgunDomain=" + mailgunDomain;
                        console.log('handleMailGunFormSubmit: Update mailgunDomain value with PATCH request', {
                            jsonFormData: jsonFormData,
                            urlWithCommissionParameter: urlWithCommissionParameter
                        });
                        return [4, axios_1.default.patch(urlWithCommissionParameter)];
                    case 2:
                        response = _b.sent();
                        console.log('handleMailGunFormSubmit: PATCH response', response);
                        toast_util_1.openSuccessToast('Sikeres mailgunDomain beállítás!');
                        this.fetchDashboardDataForShop();
                        return [3, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.log('handleMailGunFormSubmit catch', error_1);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_UPDATE_MAILGUN_DOMAIN);
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    ShoprenterAppDetailsScreen.prototype.fetchDashboardDataForShop = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var SHOPRENTER_API_ROOT_URL, url, response, error_2;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
                        url = new URL(SHOPRENTER_API_ROOT_URL + "/dashboard/shops/" + ((_a = this.props.route.params) === null || _a === void 0 ? void 0 : _a.shopId));
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, 4, 5]);
                        return [4, this.shop.get(url.toString())];
                    case 2:
                        response = _e.sent();
                        console.log('fetchDashboardDataForShop', response);
                        if ((_b = response === null || response === void 0 ? void 0 : response.shop) === null || _b === void 0 ? void 0 : _b.commission) {
                            console.log('Shop has commission, update commission form value to:', response.shop.commission);
                            this.commissionForm.fields.commission.setValue(response.shop.commission);
                        }
                        if ((_d = (_c = response === null || response === void 0 ? void 0 : response.shop) === null || _c === void 0 ? void 0 : _c.billingData) === null || _d === void 0 ? void 0 : _d.taxNumber) {
                            console.log('Shop has tax number, update this.taxNumber to:', response.shop.billingData.taxNumber);
                            this.taxNumber = response.shop.billingData.taxNumber;
                        }
                        if (response === null || response === void 0 ? void 0 : response.actualDiscount) {
                            console.log('Shop has discount, update discount form value to:', response.shop.billingData.taxNumber);
                            this.shopDiscountForm.fields.delay.setValue(response.actualDiscount.delay);
                        }
                        this.setState({
                            isLoading: false,
                            dashboardData: response === null || response === void 0 ? void 0 : response.dashboard,
                            shop: response === null || response === void 0 ? void 0 : response.shop,
                            syncSummary: response === null || response === void 0 ? void 0 : response.integration,
                            actualDiscount: response === null || response === void 0 ? void 0 : response.actualDiscount,
                            previousDiscounts: response === null || response === void 0 ? void 0 : response.previousDiscounts
                        });
                        return [3, 5];
                    case 3:
                        error_2 = _e.sent();
                        console.log('fetchDashboardDataForShop', error_2);
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    ShoprenterAppDetailsScreen.prototype.renderItem = function (label, value, actions, adminActionNeeded) {
        if (adminActionNeeded === void 0) { adminActionNeeded = false; }
        var iconComponent = null;
        if (label === 'Webshop sync') {
            if (adminActionNeeded) {
                iconComponent = exclamationIcon;
            }
            else {
                iconComponent = value ? checkIcon : crossIcon;
            }
        }
        else if (label === 'Lista tisztítás') {
            if (adminActionNeeded) {
                iconComponent = exclamationIcon;
            }
            else {
                iconComponent = value ? checkIcon : crossIcon;
            }
        }
        else {
            iconComponent = value ? checkIcon : crossIcon;
        }
        return (React.createElement(semantic_ui_react_1.Table.Row, { key: label, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, null, label),
            React.createElement(semantic_ui_react_1.Table.Cell, null, iconComponent),
            React.createElement(semantic_ui_react_1.Table.Cell, null, actions)));
    };
    ShoprenterAppDetailsScreen.prototype.renderItems = function () {
        var _this = this;
        var _a, _b, _c;
        var _d = this.state.dashboardData, adminActionNeeded = _d.adminActionNeeded, webShopDataSaved = _d.webShopDataSaved, productSynced = _d.productSynced, productSyncImminent = _d.productSyncImminent, productSyncImminentTimeStamp = _d.productSyncImminentTimeStamp, webShopScriptsInserted = _d.webShopScriptsInserted, listCleaned = _d.listCleaned, listCleanApproved = _d.listCleanApproved, dnsSetup = _d.dnsSetup, autoMailUpAndRunning = _d.autoMailUpAndRunning;
        var status = this.state.syncSummary.status;
        console.log('syncSummary', this.state.syncSummary);
        console.log('Sync status data:', {
            productSynced: productSynced,
            productSyncImminent: productSyncImminent,
            productSyncImminentTimeStamp: productSyncImminentTimeStamp,
            isDisabled: productSynced || !productSyncImminent
        });
        return [
            {
                label: 'Adatok megadása',
                value: webShopDataSaved,
                actions: React.createElement(tax_number_input_component_1.TaxNumberInput, { taxNumber: this.taxNumber, shopId: (_a = this.props.route.params) === null || _a === void 0 ? void 0 : _a.shopId, refetch: function () { return _this.fetchDashboardDataForShop(); } })
            },
            {
                label: (this.state.syncSummary && status) ? "Webshop sync (status: " + status + ")" : 'Webshop sync',
                value: productSynced,
                actions: React.createElement(request_sync_button_component_1.RequestSyncButton, { shopId: (_b = this.props.route.params) === null || _b === void 0 ? void 0 : _b.shopId, isDisabled: productSynced || !productSyncImminent, refetch: function () { return _this.fetchDashboardDataForShop(); } }),
                adminActionNeeded: adminActionNeeded && !productSynced
            },
            {
                label: 'Webshop script', value: webShopScriptsInserted, actions: null
            },
            {
                label: 'Lista tisztítás',
                value: listCleaned,
                actions: React.createElement(list_cleaning_button_component_1.ListCleaningButton, { shopId: (_c = this.props.route.params) === null || _c === void 0 ? void 0 : _c.shopId, isDisabled: !listCleanApproved || listCleaned, refetch: function () { return _this.fetchDashboardDataForShop(); } }),
                adminActionNeeded: adminActionNeeded && !listCleaned
            },
            {
                label: 'DNS beállítás', value: dnsSetup, actions: null
            },
            {
                label: 'Automata levelek', value: autoMailUpAndRunning, actions: null
            }
        ].map(function (element) { return _this.renderItem(element.label, element.value, element.actions, (element === null || element === void 0 ? void 0 : element.adminActionNeeded) || false); });
    };
    ShoprenterAppDetailsScreen.prototype.renderDiscountItems = function () {
        var discounts = this.state.previousDiscounts;
        return discounts.map(function (d) {
            return React.createElement(semantic_ui_react_1.Table.Row, { key: d._id.toString(), verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, null, moment(d.createdAt).format('YYYY.MM.DD. HH:mm:ss')),
                React.createElement(semantic_ui_react_1.Table.Cell, null, moment(d.updatedAt).format('YYYY.MM.DD. HH:mm:ss')),
                React.createElement(semantic_ui_react_1.Table.Cell, null, d.delay));
        });
    };
    ShoprenterAppDetailsScreen.prototype.handleCommissionSubmit = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shopId, api, API_URL, jsonFormData, commission, urlWithCommissionParameter, response, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        shopId = (_a = this.props.route.params) === null || _a === void 0 ? void 0 : _a.shopId;
                        api = environment_1.Environment.get('shoprenterApiRootUrl');
                        API_URL = api + "/marketplace/shop/" + shopId + "/commission";
                        jsonFormData = this.commissionForm.toJSON();
                        commission = (jsonFormData === null || jsonFormData === void 0 ? void 0 : jsonFormData.commission) || 0;
                        urlWithCommissionParameter = API_URL + "?commission=" + commission;
                        console.log('handleSubmit: Update commission value with PATCH request', {
                            jsonFormData: jsonFormData,
                            urlWithCommissionParameter: urlWithCommissionParameter
                        });
                        return [4, axios_1.default.patch(urlWithCommissionParameter)];
                    case 2:
                        response = _b.sent();
                        console.log('handleSubmit: PATCH response', response);
                        toast_util_1.openSuccessToast('Sikeres commission mentés!');
                        this.fetchDashboardDataForShop();
                        return [3, 5];
                    case 3:
                        error_3 = _b.sent();
                        console.log('handleSubmit catch', error_3);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_UPDATE_COMMISSION);
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    ShoprenterAppDetailsScreen.prototype.handleShopDiscountSubmit = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var discountId, api, API_URL, jsonFormData, shopDiscount, response, error_4;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 7, 8, 9]);
                        discountId = (_a = this.state.actualDiscount) === null || _a === void 0 ? void 0 : _a._id;
                        api = environment_1.Environment.get('shoprenterApiRootUrl');
                        API_URL = api + "/shop-discount";
                        jsonFormData = this.shopDiscountForm.toJSON();
                        shopDiscount = {
                            shopId: (_b = this.props.route.params) === null || _b === void 0 ? void 0 : _b.shopId,
                            shopName: (_c = this.state.shop) === null || _c === void 0 ? void 0 : _c.title,
                            delay: (jsonFormData === null || jsonFormData === void 0 ? void 0 : jsonFormData.delay) || 0
                        };
                        response = void 0;
                        if (!discountId) return [3, 3];
                        return [4, axios_1.default.put(API_URL + "/" + discountId, shopDiscount)];
                    case 2:
                        response = _d.sent();
                        return [3, 5];
                    case 3: return [4, axios_1.default.post(API_URL, shopDiscount)];
                    case 4:
                        response = _d.sent();
                        _d.label = 5;
                    case 5:
                        console.log("handleSubmit: " + (discountId ? 'PUT' : 'POST') + " response", response);
                        toast_util_1.openSuccessToast('Sikeres shop discount mentés!');
                        return [4, this.fetchDashboardDataForShop()];
                    case 6:
                        _d.sent();
                        return [3, 9];
                    case 7:
                        error_4 = _d.sent();
                        console.log('handleSubmit catch', error_4);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_UPDATE_COMMISSION);
                        return [3, 9];
                    case 8:
                        this.setState({ isLoading: false });
                        return [7];
                    case 9: return [2];
                }
            });
        });
    };
    ShoprenterAppDetailsScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "SHOPRENTER_V3_ADMIN_WEBSHOP_DETAILS_PAGE"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Loader, { active: this.state.isLoading, inline: 'centered', size: 'big' }, "Adatok bet\u00F6lt\u00E9se..."),
                                this.state.dashboardData && this.state.isLoading === false && (React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
                                    React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                                        React.createElement(semantic_ui_react_1.Table.Row, null,
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                                React.createElement("span", null, ((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.title) || 'MISSING_WEBSHOP_TITLE_shop.title')),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                                React.createElement("span", null, "\u00C1llapot")),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                                React.createElement("span", null, "Akci\u00F3")))),
                                    React.createElement(semantic_ui_react_1.Table.Body, { key: 2 }, this.renderItems())))))),
                    React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                            React.createElement(semantic_ui_react_1.Grid.Column, { as: semantic_ui_react_1.Form, width: 6, onSubmit: function () { return _this.commissionForm.submit(); } },
                                React.createElement(form_control_1.FormControl, { field: this.commissionForm.fields.commission, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
                                React.createElement("div", null,
                                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.isLoading, loading: this.state.isLoading }, "Ment\u00E9s"))),
                            React.createElement(semantic_ui_react_1.Grid.Column, { as: semantic_ui_react_1.Form, width: 6, onSubmit: function () { return _this.shopDiscountForm.submit(); } },
                                React.createElement(form_control_1.FormControl, { field: this.shopDiscountForm.fields.delay, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })),
                                React.createElement("div", null,
                                    React.createElement(semantic_ui_react_1.Button, { disabled: this.state.isLoading, loading: this.state.isLoading }, "Ment\u00E9s")))))),
                React.createElement("div", { className: "ListComponent mt-5" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h3' }, "Kor\u00E1bbi kedvezm\u00E9nyek"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Loader, { active: this.state.isLoading, inline: 'centered', size: 'big' }, "Adatok bet\u00F6lt\u00E9se..."),
                                this.state.dashboardData && this.state.isLoading === false && (React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
                                    React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                                        React.createElement(semantic_ui_react_1.Table.Row, null,
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                                React.createElement("span", null, "L\u00E9trehozva")),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                                React.createElement("span", null, "Friss\u00EDtve")),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                                React.createElement("span", null, "Kedvezm\u00E9nyes napok sz\u00E1ma")))),
                                    React.createElement(semantic_ui_react_1.Table.Body, { key: 2 }, this.renderDiscountItems())))))))),
            ((_b = this.state.shop) === null || _b === void 0 ? void 0 : _b.mailGunAddDomainFailed) && (React.createElement(semantic_ui_react_1.Container, { fluid: true, className: 'p-4 mt-8' },
                React.createElement(semantic_ui_react_1.Message, { color: "red" },
                    "A ",
                    React.createElement("strong", null, (_c = this.state.shop) === null || _c === void 0 ? void 0 : _c.mailgunDomain),
                    " hozz\u00E1ad\u00E1sa a MailGun-hoz sikertelen volt."),
                React.createElement(semantic_ui_react_1.Grid, { as: semantic_ui_react_1.Form, onSubmit: function () { return _this.mailgunForm.submit(); } },
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                            React.createElement(form_control_1.FormControl, { field: this.mailgunForm.fields.mailgunDomain, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false, value: this.state.shop.mailgunDomain })))),
                    React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Button, { disabled: this.state.isLoading, loading: this.state.isLoading }, "Bek\u00FCld\u00E9s a MailGun-ba"))))));
    };
    return ShoprenterAppDetailsScreen;
}(abstract_component_1.AbstractComponent));
exports.ShoprenterAppDetailsScreen = ShoprenterAppDetailsScreen;
