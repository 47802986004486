"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationSuccessComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var state_repository_1 = require("../../../common/libs/state-repository");
var IntegrationSuccessComponent = (function (_super) {
    __extends(IntegrationSuccessComponent, _super);
    function IntegrationSuccessComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentShop = session_storage_1.SessionStorage.getItem('SelectedShop');
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            open: true
        };
        return _this;
    }
    IntegrationSuccessComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { centered: true, closeIcon: false, closeOnEscape: false, closeOnDimmerClick: false, size: 'small', onClose: function () { return _this.setOpen(false); }, onOpen: function () { return _this.setOpen(true); }, open: this.state.open },
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Modal.Description, { className: 'mb-6' },
                    React.createElement(semantic_ui_react_1.Header, { as: 'h2', textAlign: 'center' }, trans_1.trans('shop-registration.integration-success.title')),
                    React.createElement(semantic_ui_react_1.Header, { textAlign: 'center', as: 'h5', className: 'mb-8' }, trans_1.trans('shop-registration.integration-success.description1')),
                    React.createElement("div", null, this.renderCarousel()),
                    React.createElement(semantic_ui_react_1.Header, { as: 'h5', textAlign: 'center' }, trans_1.trans('shop-registration.integration-success.description2'))),
                React.createElement(semantic_ui_react_1.Grid, null,
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                        React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'center' },
                            React.createElement(semantic_ui_react_1.Button, { basic: false, primary: true, color: 'blue', onClick: function () { return _this.handleStart(); }, content: trans_1.trans('shop-registration.integration-success.button.start') }))))));
    };
    IntegrationSuccessComponent.prototype.setOpen = function (value) {
        this.setState({ open: value });
    };
    IntegrationSuccessComponent.prototype.handleStart = function () {
        var shop = this.shop.put("/s/" + this.currentShop._id + "/shop/successNoticed");
        this.setOpen(false);
    };
    IntegrationSuccessComponent.prototype.renderCarousel = function () {
        var options = {
            items: 1,
            nav: true,
            rewind: true,
            autoplay: true
        };
        return React.createElement("div", null);
    };
    return IntegrationSuccessComponent;
}(abstract_component_1.AbstractComponent));
exports.IntegrationSuccessComponent = IntegrationSuccessComponent;
