"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginLayout = void 0;
var login_layout_1 = require("@codebuild/uikit/modules/codebuild/layouts/login.layout/login.layout");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./login.layout.scss");
var LoginLayout = (function (_super) {
    __extends(LoginLayout, _super);
    function LoginLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoginLayout.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Container, { fluid: true },
            React.createElement(semantic_ui_react_1.Grid, { className: "LoginLayout display-flex", padded: false },
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, null,
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Container, null,
                                    React.createElement("div", null),
                                    React.createElement("div", null),
                                    React.createElement("div", null),
                                    React.createElement("div", null)),
                                this.props.children))))));
    };
    return LoginLayout;
}(login_layout_1.LoginLayout));
exports.LoginLayout = LoginLayout;
