"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListLoader = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./list-loader.scss");
var ListLoader = (function (_super) {
    __extends(ListLoader, _super);
    function ListLoader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListLoader.prototype.render = function () {
        return this.props.table
            ? this.renderTableRow()
            : this.renderDiv();
    };
    ListLoader.prototype.renderDiv = function () {
        return React.createElement("div", { className: 'ListLoader' },
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }, this.props.content ? this.props.content : 'Adatok betöltése'));
    };
    ListLoader.prototype.renderTableRow = function () {
        return React.createElement(semantic_ui_react_1.Table.Row, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: this.props.tableColspan || 1 },
                React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }, this.props.content ? this.props.content : 'Adatok betöltése')));
    };
    return ListLoader;
}(abstract_component_1.AbstractComponent));
exports.ListLoader = ListLoader;
