module.exports = {
    'shop-settings-menu.title': 'Beállítások',
    'shop-settings-menu.item.general': 'Általános',
    'shop-settings-menu.item.miniCrmSettings': 'Számlázás',
    'shop-settings-menu.item.emailSettings': 'Mailgun',
    'shop-settings-menu.item.integration': 'Integráció',
    'shop-settings-menu.item.billingHistory': 'Számla történet',
    'shop-settings-menu.item.statusHistory': 'Státusz történet',
    'shop-settings-menu.item.relevant-ranking': 'Pontszámítás',
    'shop-settings-menu.item.import-subscribers': 'Importálás',
    'shop-settings-menu.item.export-subscribers': 'Exportálás',
    'shop-settings-menu.item.bounced-emails': 'Visszapattanó levelek',
    'shop-settings.general.title': 'Általános',
    'shop-settings-menu.item.orders-with-different-emails': 'Rendelés különböző emaillel',
    'shop-settings-menu.item.creditCardPayments': 'Bankkártyás fizetés',
};
