"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationScreen = void 0;
var registration_screen_1 = require("@codebuild/uikit/modules/codebuild/authentication/screens/registration/registration.screen");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var RegistrationScreen = (function (_super) {
    __extends(RegistrationScreen, _super);
    function RegistrationScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegistrationScreen.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Dimmer.Dimmable, { as: semantic_ui_react_1.Segment, className: "RegistrationScreen", attached: true },
            React.createElement(semantic_ui_react_1.Dimmer, { active: this.props.routeLoading, inverted: true },
                React.createElement(semantic_ui_react_1.Loader, { active: this.props.routeLoading })),
            this.renderHeader(),
            this.renderSocialButtons(),
            this.renderSocialLoginDivider(),
            this.renderForm(),
            React.createElement(semantic_ui_react_1.Divider, null),
            this.renderRegistrationCrossLink());
    };
    RegistrationScreen.prototype.renderFacebookLogin = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "SocialLoginButton facebook", onClick: function () { return _this.handleFacebookRegistration(); }, loading: this.state.loadingFacebook, "data-cy": "login-facebook-button" },
            React.createElement(semantic_ui_react_1.Icon, { name: "facebook f" }),
            React.createElement("span", null, trans_1.trans('auth.registration.social-button.facebook')));
    };
    RegistrationScreen.prototype.renderGoogleLogin = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "SocialLoginButton google", onClick: function () { return _this.handleGoogleRegistration(); }, loading: this.state.loadingGoogle, "data-cy": "login-google-button" },
            React.createElement(semantic_ui_react_1.Icon, { name: "google" }),
            React.createElement("span", null, trans_1.trans('auth.registration.social-button.google')));
    };
    return RegistrationScreen;
}(registration_screen_1.RegistrationScreen));
exports.RegistrationScreen = RegistrationScreen;
