"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorMessage = exports.ERROR_ENUM = void 0;
var ERROR_ENUM;
(function (ERROR_ENUM) {
    ERROR_ENUM["ERROR_API_APPROVED_EMAIL_TEMPLATES_COUNT"] = "ERROR_API_APPROVED_EMAIL_TEMPLATES_COUNT";
    ERROR_ENUM["ERROR_API_CANNOT_FETCH_DASHBOARD"] = "ERROR_API_CANNOT_FETCH_DASHBOARD";
    ERROR_ENUM["ERROR_API_CANNOT_FETCH_WEBSHOP_SETTINGS"] = "ERROR_API_CANNOT_FETCH_WEBSHOP_SETTINGS";
    ERROR_ENUM["ERROR_API_CANNOT_UPDATE_WEBSHOP_SETTINGS"] = "ERROR_API_CANNOT_UPDATE_WEBSHOP_SETTINGS";
    ERROR_ENUM["ERROR_API_CANNOT_REQUEST_SYNC"] = "ERROR_API_CANNOT_REQUEST_SYNC";
    ERROR_ENUM["ERROR_API_CANNOT_REQUEST_LIST_CLEANING"] = "ERROR_API_CANNOT_REQUEST_LIST_CLEANING";
    ERROR_ENUM["ERROR_API_CANNOT_FETCH_MAILGUN_SETTINGS"] = "ERROR_API_CANNOT_FETCH_MAILGUN_SETTINGS";
    ERROR_ENUM["ERROR_API_CANNOT_UPDATE_WEBSHOP_PROFILE"] = "ERROR_API_CANNOT_UPDATE_WEBSHOP_PROFILE";
    ERROR_ENUM["ERROR_API_CANNOT_UPDATE_MAILGUN_DOMAIN"] = "ERROR_API_CANNOT_UPDATE_MAILGUN_DOMAIN";
    ERROR_ENUM["ERROR_API_CANNOT_UPDATE_COMMISSION"] = "ERROR_API_CANNOT_UPDATE_COMMISSION";
    ERROR_ENUM["ERROR_API_CANNOT_UPDATE_TAX_NUMBER"] = "ERROR_API_CANNOT_UPDATE_TAX_NUMBER";
    ERROR_ENUM["ERROR_API_CANNOT_START_MI_SYSTEM"] = "ERROR_API_CANNOT_START_MI_SYSTEM";
    ERROR_ENUM["ERROR_API_CANNOT_EXPORT_INACTIVE_USERS"] = "ERROR_API_CANNOT_EXPORT_INACTIVE_USERS";
    ERROR_ENUM["ERROR_API_EMPTY_INACTIVE_USERS_RESPONSE"] = "ERROR_API_EMPTY_INACTIVE_USERS_RESPONSE";
    ERROR_ENUM["ERROR_API_ERROR_FROM_RAINDITAS_POLLING"] = "ERROR_API_ERROR_FROM_RAINDITAS_POLLING";
    ERROR_ENUM["ERROR_API_CANNOT_FETCH_GENERATED_REPORTS"] = "ERROR_API_CANNOT_FETCH_GENERATED_REPORTS";
    ERROR_ENUM["ERROR_API_CANNOT_INVITE_MANAGER_TO_ALL_SHOPS"] = "ERROR_API_CANNOT_INVITE_MANAGER_TO_ALL_SHOPS";
    ERROR_ENUM["ERROR_API_CANNOT_REMOVE_MANAGER_FROM_ALL_SHOPS"] = "ERROR_API_CANNOT_REMOVE_MANAGER_FROM_ALL_SHOPS";
    ERROR_ENUM["ERROR_UNKNOWN"] = "ERROR_UNKNOWN";
})(ERROR_ENUM = exports.ERROR_ENUM || (exports.ERROR_ENUM = {}));
var getErrorMessage = function (error) {
    switch (error) {
        case ERROR_ENUM.ERROR_API_APPROVED_EMAIL_TEMPLATES_COUNT: return "Nem sikerült betölteni az elfogadott email sablonok darabszámát!";
        case ERROR_ENUM.ERROR_API_CANNOT_FETCH_DASHBOARD: return "Nem sikerült betölteni a webshop dashboardot!";
        case ERROR_ENUM.ERROR_API_CANNOT_FETCH_WEBSHOP_SETTINGS: return "Nem sikerült betölteni a webshop beállításokat!";
        case ERROR_ENUM.ERROR_API_CANNOT_UPDATE_WEBSHOP_SETTINGS: return "Nem sikerült frissíteni a webshop beállításokat!";
        case ERROR_ENUM.ERROR_API_CANNOT_REQUEST_SYNC: return "Nem sikerült szinkronizációt kérvényezni!";
        case ERROR_ENUM.ERROR_API_CANNOT_REQUEST_LIST_CLEANING: return "Nem sikerült listatisztítást kérvényezni!";
        case ERROR_ENUM.ERROR_API_CANNOT_FETCH_MAILGUN_SETTINGS: return "Nem sikerült adatot lekérdezni a Mailguntól!";
        case ERROR_ENUM.ERROR_API_CANNOT_UPDATE_WEBSHOP_PROFILE: return "Nem sikerült a webshop adatlapot elmenteni!";
        case ERROR_ENUM.ERROR_API_CANNOT_UPDATE_MAILGUN_DOMAIN: return "A Mailgun domain mentése közben hiba történt!";
        case ERROR_ENUM.ERROR_API_CANNOT_UPDATE_COMMISSION: return "Commission mentése közben hiba történt!";
        case ERROR_ENUM.ERROR_API_CANNOT_UPDATE_TAX_NUMBER: return "Adószám mentése közben hiba történt!";
        case ERROR_ENUM.ERROR_API_CANNOT_START_MI_SYSTEM: return 'Sajnos hiba történt az M.I. rendser indítása során!';
        case ERROR_ENUM.ERROR_API_CANNOT_FETCH_GENERATED_REPORTS: return 'Hiba történt a riportok betöltése közben!';
        case ERROR_ENUM.ERROR_API_CANNOT_EXPORT_INACTIVE_USERS: return 'Hiba történt az inaktív feliratkozók exportálása közben!';
        case ERROR_ENUM.ERROR_API_EMPTY_INACTIVE_USERS_RESPONSE: return 'Üres inaktív feliratkozók export válasz az API-tól!';
        case ERROR_ENUM.ERROR_API_ERROR_FROM_RAINDITAS_POLLING: return 'A script lekérdezése közben hiba történt!';
        case ERROR_ENUM.ERROR_API_CANNOT_INVITE_MANAGER_TO_ALL_SHOPS: return 'A kezelő meghívása közben hiba történt!';
        case ERROR_ENUM.ERROR_API_CANNOT_REMOVE_MANAGER_FROM_ALL_SHOPS: return 'A kezelő eltávolítása közben hiba történt!';
        default: return "Váratlan hiba történt!";
    }
};
exports.getErrorMessage = getErrorMessage;
