"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomNewslettersScreen = void 0;
var environment_1 = require("@codebuild/uikit/libs/environment");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var axios_1 = require("axios");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
require("../libs/common.scss");
var CustomNewslettersScreen = (function (_super) {
    __extends(CustomNewslettersScreen, _super);
    function CustomNewslettersScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            customNewsletters: [],
            isLoading: false
        };
        return _this;
    }
    CustomNewslettersScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apiBaseUrl, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        apiBaseUrl = environment_1.Environment.get('api');
                        return [4, axios_1.default.get(apiBaseUrl + "/a/custom-newsletters", {
                                headers: {
                                    Authorization: get_access_token_1.getAccessToken()
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        this.setState({ customNewsletters: response === null || response === void 0 ? void 0 : response.data });
                        return [3, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('componentDidMount catch', error_1);
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    CustomNewslettersScreen.prototype.navigateToCustomNewsletterPage = function (shopId, customNewsletterId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        session_storage_1.SessionStorage.deleteItem('SelectedShop');
                        return [4, router_provider_1.RouterProvider.updateQuery({ shop: shopId })];
                    case 1:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo("/custom-newsletters/" + customNewsletterId + "?shop=" + shopId);
                        return [2];
                }
            });
        });
    };
    CustomNewslettersScreen.prototype.renderCustomNewslettersTable = function (customNewsletters) {
        var _this = this;
        if (customNewsletters.length === 0) {
            return (React.createElement(semantic_ui_react_1.Table.Row, { key: 'zero-results-table-row', verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: "7" }, "Nincs \u00FCtemezett h\u00EDrlev\u00E9l a k\u00F6zelj\u00F6v\u0151ben.")));
        }
        return customNewsletters === null || customNewsletters === void 0 ? void 0 : customNewsletters.map(function (newsletter) {
            var _a, _b;
            return (React.createElement(semantic_ui_react_1.Table.Row, { key: newsletter._id, verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, { onClick: function () { return router_provider_1.RouterProvider.goTo("/admin/shops/" + (newsletter === null || newsletter === void 0 ? void 0 : newsletter.shop._id)); }, style: { cursor: 'pointer' } },
                    React.createElement("div", { className: 'display-flex align-items-center ShopListTitle' },
                        React.createElement("div", { className: 'ShopLogo mr-4' },
                            React.createElement(semantic_ui_react_1.Image, { fluid: true, className: '', src: (_a = newsletter === null || newsletter === void 0 ? void 0 : newsletter.shop) === null || _a === void 0 ? void 0 : _a.logo })),
                        React.createElement("div", null,
                            React.createElement("strong", null, (_b = newsletter === null || newsletter === void 0 ? void 0 : newsletter.shop) === null || _b === void 0 ? void 0 : _b.title)))),
                React.createElement(semantic_ui_react_1.Table.Cell, { onClick: function () { return _this.navigateToCustomNewsletterPage(newsletter === null || newsletter === void 0 ? void 0 : newsletter.shop._id, newsletter._id); }, style: { cursor: 'pointer' } },
                    React.createElement("strong", null, newsletter === null || newsletter === void 0 ? void 0 : newsletter.name)),
                React.createElement(semantic_ui_react_1.Table.Cell, null, "TODO"),
                React.createElement(semantic_ui_react_1.Table.Cell, null, moment(newsletter === null || newsletter === void 0 ? void 0 : newsletter.originalScheduleDate).format('YYYY-MM-DDTHH:mm:ss')),
                React.createElement(semantic_ui_react_1.Table.Cell, null, moment(newsletter === null || newsletter === void 0 ? void 0 : newsletter.createdAt).format('YYYY-MM-DDTHH:mm:ss')),
                React.createElement(semantic_ui_react_1.Table.Cell, null, moment(newsletter === null || newsletter === void 0 ? void 0 : newsletter.updatedAt).format('YYYY-MM-DDTHH:mm:ss')),
                React.createElement(semantic_ui_react_1.Table.Cell, null, newsletter === null || newsletter === void 0 ? void 0 : newsletter.status)));
        });
    };
    CustomNewslettersScreen.prototype.render = function () {
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Egyedi h\u00EDrlevelek"))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
                                    React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                                        React.createElement(semantic_ui_react_1.Table.Row, null,
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "\u00C1ruh\u00E1z neve"),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "H\u00EDrlev\u00E9l neve"),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "Statisztikai mez\u0151k"),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "\u00DCtemezve"),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "L\u00E9trehozva"),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "M\u00F3dos\u00EDtva"),
                                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "St\u00E1tusz"))),
                                    React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: this.state.isLoading
                                            ? (React.createElement(semantic_ui_react_1.Table.Row, { key: 'loading-row' },
                                                React.createElement(semantic_ui_react_1.Table.Cell, null,
                                                    React.createElement("div", { className: 'ListLoader' },
                                                        React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }, "Adatok bet\u00F6lt\u00E9se"))))) : (this.renderCustomNewslettersTable(this.state.customNewsletters)) }))))))));
    };
    return CustomNewslettersScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomNewslettersScreen = CustomNewslettersScreen;
