"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserStatsComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./lead-stats.scss");
var session_storage_1 = require("../../../../libs/session.storage");
var BrowserStatsComponent = (function (_super) {
    __extends(BrowserStatsComponent, _super);
    function BrowserStatsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            stats: _this.props.stats
        };
        return _this;
    }
    BrowserStatsComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _this = this;
        if (!lodash_1.isEqual(prevProps.stats, this.props.stats)) {
            this.setState({ stats: this.props.stats }, function () { return _this.getStats(); });
        }
    };
    BrowserStatsComponent.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Message, { className: 'LeadStat CurrentCartSum' },
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(semantic_ui_react_1.Icon, { name: 'mobile' })),
            React.createElement("div", { className: "content-wrapper" }, this.renderStats()));
    };
    BrowserStatsComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    BrowserStatsComponent.prototype.renderStats = function () {
        if (this.props.loading) {
            return React.createElement(semantic_ui_react_1.Loader, { active: true });
        }
        var stats = this.getStats();
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("div", { className: 'display-flex flex-column' },
                        React.createElement("span", { className: 'mb-2' }, "Mobil"),
                        React.createElement("h3", { className: 'mb-1 mt-0' },
                            stats.mobileDevice,
                            "%"),
                        React.createElement("span", null, stats.mobileOs),
                        React.createElement("strong", null, stats.mobileBrowser))),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement("div", { className: 'display-flex flex-column' },
                        React.createElement("span", { className: 'mb-2' }, "Asztali"),
                        React.createElement("h3", { className: 'mb-1 mt-0' },
                            stats.desktopDevice,
                            "%"),
                        React.createElement("span", null, stats.desktopOs),
                        React.createElement("strong", null, stats.desktopBrowser)))));
    };
    BrowserStatsComponent.prototype.getStats = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var desktop = ((_b = (_a = this.state.stats) === null || _a === void 0 ? void 0 : _a.formFactor) === null || _b === void 0 ? void 0 : _b.other) || 0;
        var mobile = ((_d = (_c = this.state.stats) === null || _c === void 0 ? void 0 : _c.formFactor) === null || _d === void 0 ? void 0 : _d.mobile) || 0;
        var maxMobileOs = '-';
        var maxDesktopOs = '-';
        var maxMobileBrowser = '-';
        var maxDesktopBrowser = '-';
        lodash_1.map((_e = this.state.stats) === null || _e === void 0 ? void 0 : _e.os, function (os, key) {
            if (key.includes('-mobile') && os > (_this.state.stats[maxMobileOs] || 0)) {
                maxMobileOs = key;
            }
            if (!key.includes('-mobile') && os > (_this.state.stats[maxDesktopOs] || 0)) {
                maxDesktopOs = key;
            }
        });
        lodash_1.map((_f = this.state.stats) === null || _f === void 0 ? void 0 : _f.browser, function (browser, key) {
            if (key.includes('-mobile') && browser > (_this.state.stats[maxMobileBrowser] || 0)) {
                maxMobileBrowser = key;
            }
            if (!key.includes('-mobile') && browser > (_this.state.stats[maxDesktopBrowser] || 0)) {
                maxDesktopBrowser = key;
            }
        });
        return {
            desktopDevice: (!!mobile || !!desktop) ? Math.round(desktop / (mobile + desktop) * 100) || 0 : '-',
            mobileDevice: (!!mobile || !!desktop) ? 100 - (Math.round(desktop / (mobile + desktop) * 100)) || 0 : '-',
            mobileOs: maxMobileOs.toString().replace('-mobile', ''),
            desktopOs: maxDesktopOs,
            mobileBrowser: maxMobileBrowser.toString().replace('-mobile', ''),
            desktopBrowser: maxDesktopBrowser,
        };
    };
    return BrowserStatsComponent;
}(responsive_component_1.ResponsiveComponent));
exports.BrowserStatsComponent = BrowserStatsComponent;
