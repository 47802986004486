"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerEventsListComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_1 = require("@codebuild/uikit/libs/router/router");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var event_types_1 = require("../../../common/libs/event-types");
var state_repository_1 = require("../../../common/libs/state-repository");
var event_list_card_component_1 = require("../event-list-card/event-list-card.component");
require("./customer-details.component.scss");
var CustomerEventsListComponent = (function (_super) {
    __extends(CustomerEventsListComponent, _super);
    function CustomerEventsListComponent() {
        var _a, _b, _c, _d, _e;
        var _this = _super.apply(this, arguments) || this;
        _this.events = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'events');
        _this.eventTypes = event_types_1.eventTypes;
        _this.cancelToken = null;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                eventType: new field_1.Field({
                    name: 'eventType',
                    placeholder: trans_1.trans('customer-events-list.type-select.placeholder'),
                    label: trans_1.trans('customer-events-list.type-select.label'),
                    value: [],
                    options: event_types_1.eventTypeOptions.map(function (i) { return (__assign(__assign({}, i), { text: trans_1.trans(i.text) })); }),
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                })
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 5,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query.query) !== null && _e !== void 0 ? _e : ''
            }
        });
        _this.state = __assign(__assign({}, _this.state), { openedGroups: [], isLoading: false, error: null, selectedEventTypes: [] });
        return _this;
    }
    CustomerEventsListComponent.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.$subscriptions.push(this.form.fields.eventType.$value.subscribe(function (value) { return (_this.setState({ selectedEventTypes: value }, function () { return _this.fetchEvents(); })); }), this.control.query$.subscribe(function (q) { return _this.fetchEvents(q); }));
        this.fetchEvents();
    };
    CustomerEventsListComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.peId !== this.props.peId) {
            this.fetchEvents();
        }
    };
    CustomerEventsListComponent.prototype.fetchEvents = function (q) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var query, e_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 3, , 4]);
                        if (!!this.cancelToken) {
                            this.cancelToken.cancel('canceled-by-client');
                        }
                        this.cancelToken = repository_1.Repository.createCancelToken();
                        this.setState({ isLoading: true });
                        if (!this.props.peId) {
                            return [2];
                        }
                        query = router_1.Router.encodeQuery({
                            withData: true,
                            eventType: this.state.selectedEventTypes,
                            _limit: (q === null || q === void 0 ? void 0 : q._limit) || 50,
                            _page: (q === null || q === void 0 ? void 0 : q._page) || 0,
                            '_sort[createdAt]': -1,
                            from: moment().add(-500, 'days').toDate()
                        }, true);
                        return [4, this.events.get("/s/" + ((_a = this.props) === null || _a === void 0 ? void 0 : _a.shopId) + "/event/" + ((_b = this.props) === null || _b === void 0 ? void 0 : _b.peId) + "/customer-events-by-processed-event/" + query, { cancelToken: this.cancelToken.token })];
                    case 1:
                        _e.sent();
                        return [4, this.setState({ isLoading: false })];
                    case 2:
                        _e.sent();
                        this.control.setResponse((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.events) === null || _d === void 0 ? void 0 : _d.response);
                        return [3, 4];
                    case 3:
                        e_1 = _e.sent();
                        console.log(e_1);
                        this.setState({ isLoading: false, error: e_1 });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    CustomerEventsListComponent.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement("div", { className: 'mb-4 display-flex justify-content-between align-items-end' },
                React.createElement("div", null,
                    React.createElement("h3", null, "Esem\u00E9nyek")),
                React.createElement("div", { className: 'EventTypeFilter' }, this.renderEventTypeFilter())),
            React.createElement("div", { className: 'position-relative' }, this.renderList()));
    };
    CustomerEventsListComponent.prototype.renderList = function () {
        var _this = this;
        var _a;
        if (this.state.isLoading) {
            return this.renderLoader();
        }
        var events = lodash_1.get(this.state.events, 'response.items', []);
        var groupedByDay = lodash_1.groupBy(events, function (e) { return moment(e.createdAt).format('YYYY.MM.DD.'); });
        if (((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message) === 'ProcessedEventNotFound') {
            return React.createElement("div", null, "Nincsenek esem\u00E9nyek");
        }
        if (!events.length) {
            return React.createElement("div", null, "Nincsenek esem\u00E9nyek");
        }
        return React.createElement("div", null,
            lodash_1.map(groupedByDay, function (value, key) { return _this.renderEventsByDay(key, value); }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                    { text: 1, value: 1 },
                    { text: 10, value: 10 },
                    { text: 25, value: 25 },
                    { text: 50, value: 50 },
                    { text: 100, value: 100 },
                ] }));
    };
    CustomerEventsListComponent.prototype.renderEventTypeFilter = function () {
        return React.createElement("div", null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.eventType, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Dropdown, multiple: true, selection: true, fluid: true, disabled: false })));
    };
    CustomerEventsListComponent.prototype.renderEventsByDay = function (date, events) {
        console.log(date);
        return React.createElement("div", { className: 'mb-5', key: date },
            React.createElement("div", { className: 'mb-2' },
                React.createElement("div", null,
                    React.createElement("div", { className: ' display-flex align-items-center' },
                        React.createElement("div", { className: 'flex-fill' },
                            React.createElement("h4", null, date))))),
            React.createElement("div", { className: 'EventList' }, (events || []).map(function (e) { return React.createElement(event_list_card_component_1.EventListCardComponent, { key: e._id, item: e }); })));
    };
    CustomerEventsListComponent.prototype.renderLoader = function () {
        return React.createElement("div", null,
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'medium' }));
    };
    CustomerEventsListComponent.prototype.filterEvents = function (filters) {
        this.form.fields.eventType.setValue((event_types_1.eventTypeOptions.filter(function (e) { return filters.includes(e.key); })).map(function (f) { return f.value; }));
    };
    return CustomerEventsListComponent;
}(responsive_component_1.ResponsiveComponent));
exports.CustomerEventsListComponent = CustomerEventsListComponent;
