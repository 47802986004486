"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var menu_body_component_1 = require("./menu-body.component");
var menu_footer_component_1 = require("./menu-footer.component");
require("./menu.component.scss");
var shop_changer_1 = require("./shop-changer");
var MenuComponent = (function (_super) {
    __extends(MenuComponent, _super);
    function MenuComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.user = local_store_1.LocalStore.getItem('User');
        _this.state = {
            menuIsOpen: false,
        };
        return _this;
    }
    MenuComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Container, null,
            React.createElement(semantic_ui_react_1.Segment, { className: 'TabletHeader' },
                React.createElement(semantic_ui_react_1.Button, { icon: 'sidebar', content: 'Menü', basic: true, onClick: function () { return _this.toggleMenuIsOpen(); } }),
                React.createElement(semantic_ui_react_1.Image, { className: 'MILogo', src: '/assets/logo/MI_logo.svg' })),
            React.createElement(semantic_ui_react_1.Segment, { padded: false, className: "MenuComponent flex-fill " + (this.state.menuIsOpen ? 'open' : '') },
                React.createElement("div", { onClick: function () { return _this.closeMenu(); } }),
                React.createElement("div", { className: 'MenuHeader' },
                    React.createElement(semantic_ui_react_1.Image, { className: 'MILogo', src: '/assets/logo/MI_logo.svg' })),
                React.createElement("div", { className: 'flex-fill', style: { display: 'flex' } },
                    React.createElement(menu_body_component_1.MenuBodyComponent, __assign({}, this.props))),
                React.createElement(shop_changer_1.ShopChangerComponent, null),
                React.createElement(menu_footer_component_1.MenuFooterComponent, null)));
    };
    MenuComponent.prototype.toggleMenuIsOpen = function () {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen
        });
    };
    MenuComponent.prototype.closeMenu = function () {
        this.setState({
            menuIsOpen: false
        });
    };
    return MenuComponent;
}(responsive_component_1.ResponsiveComponent));
exports.MenuComponent = MenuComponent;
