"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsMenu = void 0;
exports.settingsMenu = [
    {
        name: 'profiles',
        identifier: 'profiles',
        icon: 'user',
    },
    {
        name: 'integration',
        identifier: 'integration',
        icon: 'chart pie',
    },
    {
        name: 'billing',
        identifier: 'billing',
        icon: 'money bill alternate',
    },
    {
        name: 'emailSettings',
        identifier: 'emailSettings',
        icon: 'envelope',
    },
    {
        name: 'managers',
        identifier: 'managers',
        icon: 'users',
    },
    {
        name: 'creditCardPayments',
        identifier: 'creditCardPayments',
        icon: 'credit card outline',
    }
];
