"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultLayout = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var integration_success_component_1 = require("../../../shop-registration/components/steps/integration-success.component");
var menu_component_1 = require("../../components/menu/menu.component");
require("./default.layout.scss");
var DefaultLayout = (function (_super) {
    __extends(DefaultLayout, _super);
    function DefaultLayout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            shop: session_storage_1.SessionStorage.getItem('SelectedShop')
        };
        return _this;
    }
    DefaultLayout.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var shop = session_storage_1.SessionStorage.getItem('SelectedShop');
        if (!lodash_1.isEqual(prevState.shop, shop)) {
            this.setState({ shop: shop });
        }
    };
    DefaultLayout.prototype.render = function () {
        if (this.props.routeLoading) {
            return this.renderLoader();
        }
        if (!this.state.shop) {
            return null;
        }
        return React.createElement("div", { className: 'DefaultLayout' },
            React.createElement("div", { className: 'MenuContainer' },
                React.createElement(menu_component_1.MenuComponent, __assign({}, this.props))),
            React.createElement(semantic_ui_react_1.Container, { className: "flex-fill MainContainer", style: this.props.route.path === '/dashboard' ? { overflow: 'hidden' } : {}, fluid: true },
                this.renderLoader(),
                !this.state.routeLoading && React.createElement("div", null,
                    this.renderIntegrationSuccessModal(),
                    this.props.children)));
    };
    DefaultLayout.prototype.renderLoader = function () {
        if (!this.props.routeLoading) {
            return null;
        }
        return React.createElement("div", { className: 'Loader' },
            React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }));
    };
    DefaultLayout.prototype.renderIntegrationSuccessModal = function () {
        var _a, _b;
        var isShoprenterMarketplaceShop = ((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.source) === "shoprenterOnDemand" || false;
        if (!!((_b = this.state.shop) === null || _b === void 0 ? void 0 : _b.integrationSuccessNoticed) || isShoprenterMarketplaceShop === true) {
            return null;
        }
        return React.createElement(integration_success_component_1.IntegrationSuccessComponent, null);
    };
    return DefaultLayout;
}(responsive_component_1.ResponsiveComponent));
exports.DefaultLayout = DefaultLayout;
