"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.menu = void 0;
exports.menu = [
    {
        name: 'dashboard',
        path: '/dashboard',
        icon: 'chart line',
        activeRegexp: /^\/dashboard/,
        items: []
    },
    {
        name: 'leads',
        path: '/leads',
        icon: 'users',
        activeRegexp: /^\/leads/,
        items: []
    },
    {
        name: 'products',
        path: '/products',
        icon: 'dolly',
        activeRegexp: /^\/products/,
        items: []
    },
    {
        name: 'categories',
        path: '/categories',
        activeRegexp: /^\/categories/,
        icon: 'boxes',
        items: []
    },
    {
        name: 'orders',
        path: '/orders',
        icon: 'cart',
        activeRegexp: /^\/orders/,
        items: []
    },
    {
        name: 'newsletters',
        path: '/newsletters',
        icon: 'envelope open',
        activeRegexp: /^\/newsletters/,
        items: []
    },
    {
        name: 'custom-newsletters',
        path: '/custom-newsletters',
        icon: 'envelope square',
        activeRegexp: /^\/custom-newsletters/,
        items: []
    },
    {
        name: 'performance',
        path: '/performance',
        icon: 'chart bar',
        activeRegexp: /^\/performance/,
        items: []
    }
];
