"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoListSummaryTabContent = void 0;
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var state_repository_1 = require("../../../common/libs/state-repository");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var todo_list_tab_content_container_component_1 = require("./todo-list-tab-content-container.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var TodoListSummaryTabContent = (function (_super) {
    __extends(TodoListSummaryTabContent, _super);
    function TodoListSummaryTabContent(props) {
        var _this = _super.call(this, props) || this;
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            isLoading: false,
            isWebShopDataSaved: _this.props.dashboardData.dashboard.webShopDataSaved,
            isProductSynced: _this.props.dashboardData.dashboard.productSynced,
            isListCleaned: _this.props.dashboardData.dashboard.listCleaned,
            isDnsSetup: _this.props.dashboardData.dashboard.dnsSetup,
            isProfileFinished: _this.props.profileFinished,
            currentApprovedTemplates: _this.props.approvedTemplatesCount
        };
        return _this;
    }
    TodoListSummaryTabContent.prototype.renderSummaryRecords = function () {
        var _a = this.state, isWebShopDataSaved = _a.isWebShopDataSaved, isProductSynced = _a.isProductSynced, isListCleaned = _a.isListCleaned, isDnsSetup = _a.isDnsSetup, isProfileFinished = _a.isProfileFinished, currentApprovedTemplates = _a.currentApprovedTemplates;
        return React.createElement(semantic_ui_react_1.Table, { className: 'MailgunCredentials SummaryTable', celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 5 }, "FELADAT"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { width: 2 }, "ST\u00C1TUSZ"))),
            React.createElement(semantic_ui_react_1.Table.Body, null,
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("strong", null, "1. WEBSHOP ADATOK")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, isWebShopDataSaved ?
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'green', loading: false, name: 'check circle' }) :
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'red', loading: false, name: 'exclamation circle' }))),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("strong", null, "2. SZINKRONIZ\u00C1CI\u00D3")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, isProductSynced ?
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'green', loading: false, name: 'check circle' }) :
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'red', loading: false, name: 'exclamation circle' }))),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("strong", null, "3. LISTA TISZT\u00CDT\u00C1S")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, isListCleaned ?
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'green', loading: false, name: 'check circle' }) :
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'red', loading: false, name: 'exclamation circle' }))),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("strong", null, "4. DNS BE\u00C1LL\u00CDT\u00C1S")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, isDnsSetup ?
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'green', loading: false, name: 'check circle' }) :
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'red', loading: false, name: 'exclamation circle' }))),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("strong", null, "5. SABLON BE\u00C1LL\u00CDT\u00C1S")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null, isProfileFinished ?
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'green', loading: false, name: 'check circle' }) :
                        React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'red', loading: false, name: 'exclamation circle' }))),
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        React.createElement("strong", null, "6. AUTOMATA LEVELEK")),
                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                        " ",
                        currentApprovedTemplates >= 10 ?
                            React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'green', loading: false, name: 'check circle' }) :
                            React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', color: 'red', loading: false, name: 'exclamation circle' })))));
    };
    TodoListSummaryTabContent.prototype.render = function () {
        return (React.createElement(todo_list_tab_content_container_component_1.TodoListTabContentContainerComponent, null,
            React.createElement("div", { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' } },
                React.createElement(semantic_ui_react_1.Message, { info: true, style: { backgroundColor: '#EDF4FF', boxShadow: 'none', width: '100%', flex: 1, color: "#051D40" } },
                    React.createElement("p", null,
                        React.createElement("strong", null, "Telep\u00EDt\u00E9s l\u00E9p\u00E9seinek aktu\u00E1lis st\u00E1tusz\u00E1t \u00F6sszefoglaltam neked!")),
                    React.createElement("p", null, "\uD83D\uDCA1A telep\u00EDt\u00E9s folyamat\u00E1nak teljes lefut\u00E1sa ak\u00E1r t\u00F6bb \u00F3r\u00E1t is ig\u00E9nybe vehet, ez\u00E9rt \u00E9rdemes t\u00F6bbsz\u00F6r visszan\u00E9zni, hogy ellen\u0151rizd a st\u00E1tuszokat."),
                    React.createElement("p", null,
                        React.createElement(semantic_ui_react_1.Icon, { size: 'small', className: 'ml-1', color: 'green', loading: false, name: 'check circle' }),
                        "Ha z\u00F6ld az \u00E1llapotjelz\u0151, akkor teljesen lefutott, \u00E9s minden be\u00E1ll\u00EDt\u00E1s sikeresen megt\u00F6rt\u00E9nt.Tov\u00E1bbi teend\u0151d nincs vele."),
                    React.createElement("p", null,
                        React.createElement(semantic_ui_react_1.Icon, { size: 'small', className: 'ml-1', color: 'red', loading: false, name: 'exclamation circle' }),
                        "Ha piros az \u00E1llapotjelz\u0151, akkor vagy hiba t\u00F6rt\u00E9nt, vagy m\u00E9g nem ment v\u00E9gig a teljes folyamat!"),
                    React.createElement("p", null,
                        React.createElement("strong", null, "Ha minden \u00E1llapotjelz\u0151 z\u00F6ldre v\u00E1ltott, ut\u00E1na aktiv\u00E1l\u00F3dik a lev\u00E9lk\u00FCld\u00E9s, \u00E9s a bal oldali kezel\u0151fel\u00FClet is! Ezt k\u00F6vet\u0151en nincs m\u00E1s h\u00E1tra, kezdhetj\u00FCk a k\u00F6z\u00F6s munk\u00E1t!"))),
                this.renderSummaryRecords())));
    };
    return TodoListSummaryTabContent;
}(abstract_component_1.AbstractComponent));
exports.TodoListSummaryTabContent = TodoListSummaryTabContent;
