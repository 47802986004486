"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryListItemComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var color_options_1 = require("../../../../styles/color.options");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var state_repository_1 = require("../../../common/libs/state-repository");
require("./categories-list.component.scss");
var recommended_category_selector_component_1 = require("./recommended-category-selector.component");
var CategoryListItemComponent = (function (_super) {
    __extends(CategoryListItemComponent, _super);
    function CategoryListItemComponent() {
        var _a, _b, _c, _d, _e;
        var _this = _super.apply(this, arguments) || this;
        _this.categoryRepository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'category');
        _this.state = {
            loading: false,
            item: (_a = _this.props) === null || _a === void 0 ? void 0 : _a.item,
            level: (_b = _this.props) === null || _b === void 0 ? void 0 : _b.level,
            index: (_c = _this.props) === null || _c === void 0 ? void 0 : _c.index,
            lastItem: (_d = _this.props) === null || _d === void 0 ? void 0 : _d.lastItem,
            recommended: ((_e = _this.props) === null || _e === void 0 ? void 0 : _e.recommended) || []
        };
        return _this;
    }
    CategoryListItemComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!lodash_1.isEqual(prevProps.item, this.props.item)) {
            this.setState({ item: this.props.item });
        }
        if (!lodash_1.isEqual(prevProps.level, this.props.level)) {
            this.setState({ level: this.props.level });
        }
        if (!lodash_1.isEqual(prevProps.index, this.props.index)) {
            this.setState({ index: this.props.index });
        }
    };
    CategoryListItemComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var item = this.state.item;
        var level = this.state.level;
        var index = this.state.index;
        var lastItem = this.state.lastItem;
        var hasChildren = !!((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.children.length);
        var isActive = this.isActive(item === null || item === void 0 ? void 0 : item.identifier);
        var alphaLevel = level < 8 ? ((level + 4) * 10) : 90;
        var backgroundAlpha = "" + alphaLevel.toString(16);
        return [
            React.createElement(semantic_ui_react_1.Table.Row, { className: (level === 0 ? 'MainCategoryRow' : 'CategoryRow') + " " + (hasChildren ? 'HasChildren' : ''), key: item._id + "-0", verticalAlign: "middle" },
                React.createElement(semantic_ui_react_1.Table.Cell, { className: (hasChildren ? 'cursor-pointer' : '') + " display-flex", onClick: function () { return _this.toggleCategory(item === null || item === void 0 ? void 0 : item.identifier); }, style: {
                        paddingLeft: "calc(" + (level * 2 + 1) + "rem + 1px",
                        position: 'relative',
                    } },
                    level !== 0
                        ? React.createElement("div", { className: 'position-relative' },
                            React.createElement("span", { className: "VerticalMarker " + (lastItem ? 'last-item' : ''), style: {
                                    backgroundColor: color_options_1.COLOR_OPTIONS[Math.floor((index + 1) % color_options_1.COLOR_OPTIONS.length)]
                                } }),
                            React.createElement("span", { className: 'HorizontalMarker', style: {
                                    backgroundColor: color_options_1.COLOR_OPTIONS[Math.floor((index + 1) % color_options_1.COLOR_OPTIONS.length)]
                                } }))
                        : null,
                    React.createElement(semantic_ui_react_1.Button, { className: 'display-flex justify-content-center align-items-center px-4', style: {
                            position: 'relative',
                            top: '50%',
                            left: '1rem',
                            backgroundColor: "" + color_options_1.COLOR_OPTIONS[Math.floor((index + 1) % color_options_1.COLOR_OPTIONS.length)] + backgroundAlpha,
                            color: ((_b = this.state.recommended) === null || _b === void 0 ? void 0 : _b.includes(item === null || item === void 0 ? void 0 : item.identifier)) ? '' : 'grey',
                        } },
                        hasChildren && React.createElement(semantic_ui_react_1.Icon, { name: isActive ? 'chevron up' : 'chevron down' }), (_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 :
                        _c.name)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderIncome(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderProductCount(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderAdvertisable(item)),
                React.createElement(semantic_ui_react_1.Table.Cell, { textAlign: 'center' }, this.renderRecommended(item))),
            isActive ? (_d = item === null || item === void 0 ? void 0 : item.data) === null || _d === void 0 ? void 0 : _d.children.map(function (c, i) {
                var _a;
                return (React.createElement(CategoryListItemComponent, { key: c === null || c === void 0 ? void 0 : c._id, item: c, level: level + 1, index: index, lastItem: i === ((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.children.length) - 1, recommended: _this.state.recommended }));
            }) : null
        ];
    };
    CategoryListItemComponent.prototype.toggleCategory = function (identifier) {
        var openedCategories = this.state.openedCategories || [];
        var currentOpen = lodash_1.find(openedCategories, function (c) { return c === identifier; });
        if (!!currentOpen) {
            openedCategories = lodash_1.filter(openedCategories, function (c) { return c !== identifier; });
            return this.setState({ openedCategories: openedCategories });
        }
        return this.setState({ openedCategories: __spreadArrays(openedCategories, [identifier]) });
    };
    CategoryListItemComponent.prototype.isActive = function (identifier) {
        var openedCategories = this.state.openedCategories || [];
        return !!lodash_1.find(openedCategories, function (c) { return c === identifier; });
    };
    CategoryListItemComponent.prototype.renderProductCount = function (item) {
        var _a;
        var count = (_a = item === null || item === void 0 ? void 0 : item.statistics) === null || _a === void 0 ? void 0 : _a.totalProductCount;
        return React.createElement("span", null,
            count,
            " db");
    };
    CategoryListItemComponent.prototype.renderIncome = function (item) {
        var _a, _b;
        var count = ((_a = item === null || item === void 0 ? void 0 : item.statistics) === null || _a === void 0 ? void 0 : _a.incomeChildDeepAll) + ((_b = item === null || item === void 0 ? void 0 : item.statistics) === null || _b === void 0 ? void 0 : _b.incomeOwnAll);
        return React.createElement("span", null, currency_parser_1.hufFormat(count));
    };
    CategoryListItemComponent.prototype.renderAdvertisable = function (item) {
        var _this = this;
        var _a;
        var advertisable = (_a = item === null || item === void 0 ? void 0 : item.settings) === null || _a === void 0 ? void 0 : _a.advertisableEmail;
        return React.createElement("div", { className: 'display-flex flex-row align-items-center justify-content-center CheckButton' },
            React.createElement(semantic_ui_react_1.Button, { loading: this.state.loading, disabled: this.state.loading, size: 'tiny', basic: true, icon: advertisable ? 'check' : '', onClick: function () { return _this.toggleAdvertisable(item); } }));
    };
    CategoryListItemComponent.prototype.renderRecommended = function (item) {
        return React.createElement("div", null,
            React.createElement(recommended_category_selector_component_1.RecommendedCategorySelectorComponent, { shop: this.getCurrentShop(), category: item }));
    };
    CategoryListItemComponent.prototype.toggleAdvertisable = function (item) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var response, currentItem, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        return [4, this.categoryRepository.put("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/settings/" + (item === null || item === void 0 ? void 0 : item._id), {
                                data: {
                                    key: 'advertisableEmail',
                                    value: !((_b = item === null || item === void 0 ? void 0 : item.settings) === null || _b === void 0 ? void 0 : _b.advertisableEmail)
                                }
                            })];
                    case 1:
                        response = _c.sent();
                        currentItem = this.state.item;
                        this.setState({ loading: false, item: __assign(__assign({}, currentItem), { settings: __assign({}, response === null || response === void 0 ? void 0 : response.settings) }) });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ loading: false });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CategoryListItemComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CategoryListItemComponent;
}(responsive_component_1.ResponsiveComponent));
exports.CategoryListItemComponent = CategoryListItemComponent;
