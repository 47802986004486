"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openSuccessToast = exports.openErrorToastWithMessage = exports.openErrorToast = exports.openWarningToast = exports.defaultToastOptions = void 0;
var react_toastify_1 = require("react-toastify");
var errors_constant_1 = require("./errors.constant");
exports.defaultToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};
var openWarningToast = function (error) {
    react_toastify_1.toast.warn(errors_constant_1.getErrorMessage(error), exports.defaultToastOptions);
};
exports.openWarningToast = openWarningToast;
var openErrorToast = function (error) {
    react_toastify_1.toast.error(errors_constant_1.getErrorMessage(error), exports.defaultToastOptions);
};
exports.openErrorToast = openErrorToast;
var openErrorToastWithMessage = function (errorMessage) {
    react_toastify_1.toast.error(errorMessage, exports.defaultToastOptions);
};
exports.openErrorToastWithMessage = openErrorToastWithMessage;
var openSuccessToast = function (text) {
    react_toastify_1.toast.success(text);
};
exports.openSuccessToast = openSuccessToast;
