"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitToNumberString = void 0;
var splitToNumberString = function (amount, precision, suffix) {
    if (suffix === void 0) { suffix = ''; }
    if (amount === '-') {
        return '-';
    }
    if (amount !== 0 && !amount) {
        return '-';
    }
    var splitedNumber = parseFloat(amount.toString()).toString().split('.');
    var wholePart = splitedNumber[0];
    var fractionalPart = splitedNumber[1];
    var fractionalString = !!fractionalPart ? "." + fractionalPart.toString().slice(0, (!!precision ? precision : 2)) : '';
    if (precision === 0) {
        fractionalString = '';
    }
    if (wholePart.toString().length <= 3) {
        return "" + (wholePart.toString() + fractionalString) + (suffix ? " " + suffix : '');
    }
    var end = wholePart.toString().slice(wholePart.toString().length - 3, wholePart.toString().length) + fractionalString;
    var carry = wholePart.toString().slice(0, wholePart.toString().length - 3);
    if (carry.length > 3) {
        return exports.splitToNumberString(carry) + " " + end + (suffix ? " " + suffix : '');
    }
    return carry + " " + end + (suffix ? " " + suffix : '');
};
exports.splitToNumberString = splitToNumberString;
