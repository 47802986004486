"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomNewsletterScreen = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var observable_control_1 = require("@codebuild/uikit/libs/observable-control");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../libs/session.storage");
var custom_newsletter_template_update_component_1 = require("../admin/components/template/custom-newsletter-template-update.component");
var list_empty_1 = require("../common/components/list-empty/list-empty");
var list_loader_1 = require("../common/components/loader/list-loader");
var pagination_list_control_1 = require("../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../common/components/repository-list/controls/pagination.control");
var repository_list_1 = require("../common/components/repository-list/repository-list");
var number_parser_1 = require("../common/libs/number-parser");
var state_repository_1 = require("../common/libs/state-repository");
var custom_newsletter_row_1 = require("./components/custom-newsletter-row");
require("./custom-newsletter-screen.scss");
var CustomNewsletterScreen = (function (_super) {
    __extends(CustomNewsletterScreen, _super);
    function CustomNewsletterScreen() {
        var _a, _b, _c, _d, _e;
        var _this = _super.apply(this, arguments) || this;
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                query: new field_1.Field({
                    name: 'query',
                    label: '',
                    value: '',
                    placeholder: trans_1.trans('custom-newsletter.screen.search-placeholder')
                })
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 5,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query.query) !== null && _e !== void 0 ? _e : '',
                '_sort[createdAt]': -1
            }
        });
        return _this;
    }
    CustomNewsletterScreen.prototype.componentDidMount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.repository.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template")];
                    case 1:
                        _b.sent();
                        this.$subscriptions.push(this.form.$value
                            .pipe(operators_1.debounceTime(450))
                            .subscribe(function (value) {
                            _this.control.set('query', value === null || value === void 0 ? void 0 : value.query);
                            _this.control.set('page', 0);
                        }));
                        return [2];
                }
            });
        });
    };
    CustomNewsletterScreen.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: "WebshopDataScreen CustomNewsletterScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "CustomNewsletterList ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, trans_1.trans('custom-newsletter.screen.title')),
                                React.createElement(observable_control_1.ObservableControl, { "$observable": this.control.response$, transform: function (value) { return ({
                                        children: (!value ? '' : React.createElement(semantic_ui_react_1.Statistic, { horizontal: true, size: 'tiny', label: 'db', value: number_parser_1.splitToNumberString(value === null || value === void 0 ? void 0 : value.total) }))
                                    }); } },
                                    React.createElement("div", null))),
                            React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderListHeader()))),
                    React.createElement(repository_list_1.RepositoryList, { control: this.control, ref: function (ref) { return _this.repositoryListRef = ref; }, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template", renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 8 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 8 })); } }),
                    React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                            { text: 1, value: 1 },
                            { text: 10, value: 10 },
                            { text: 25, value: 25 },
                            { text: 50, value: 50 }
                        ] }))));
    };
    CustomNewsletterScreen.prototype.renderListHeader = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'ListHeader' },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement("div", { className: "display-flex justify-content-end" },
                    React.createElement(custom_newsletter_template_update_component_1.CustomNewsletterTemplateUpdateComponent, { className: "noWrap", content: trans_1.trans('custom-newsletter.screen.create-button.title'), onCreate: function (item) { return _this.redirectToRecentLetter(item); } }))));
    };
    CustomNewsletterScreen.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative cursor-pointer', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, trans_1.trans('custom-newsletter.screen.list.name')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { colSpan: 3 },
                        React.createElement("div", { className: "display-flex justify-content-center align-items-center flex-column" }, trans_1.trans('custom-newsletter.screen.list.statistics'))),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, trans_1.trans('custom-newsletter.screen.list.scheduledAt')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, trans_1.trans('custom-newsletter.screen.list.createdAt')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, trans_1.trans('custom-newsletter.screen.list.contentUpdatedAt')),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, trans_1.trans('custom-newsletter.screen.list.status')))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    CustomNewsletterScreen.prototype.renderItem = function (ctx, item, index) {
        return React.createElement(custom_newsletter_row_1.CustomNewsletterRow, { item: item, onClick: function () { return router_provider_1.RouterProvider.goTo("/custom-newsletters/" + item._id); } });
    };
    CustomNewsletterScreen.prototype.redirectToRecentLetter = function (item) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var itemCount, id;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        if (item._id) {
                            return [2, router_provider_1.RouterProvider.goTo("/custom-newsletters/" + item._id)];
                        }
                        return [4, this.repository.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template")];
                    case 1:
                        _k.sent();
                        itemCount = (_e = (_d = (_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.repository) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e.length;
                        id = (_j = (_h = (_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.repository) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.items[itemCount - 1]) === null || _j === void 0 ? void 0 : _j._id;
                        return [2, router_provider_1.RouterProvider.goTo("/custom-newsletters/" + id)];
                }
            });
        });
    };
    CustomNewsletterScreen.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CustomNewsletterScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomNewsletterScreen = CustomNewsletterScreen;
