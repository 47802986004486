"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopRegistrationLayout = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./shop-registration.layout.scss");
var ShopRegistrationLayout = (function (_super) {
    __extends(ShopRegistrationLayout, _super);
    function ShopRegistrationLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShopRegistrationLayout.prototype.render = function () {
        return React.createElement(semantic_ui_react_1.Container, { className: "ShopRegistrationLayout", fluid: true },
            React.createElement(semantic_ui_react_1.Container, null,
                React.createElement(semantic_ui_react_1.Grid, null,
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, stretched: true, centered: true, textAlign: 'center' },
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(semantic_ui_react_1.Image, { centered: true, className: 'logo', src: '/assets/logo/MI_logo.svg' })))),
                React.createElement(semantic_ui_react_1.Grid, null,
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true, stretched: true },
                        React.createElement(semantic_ui_react_1.Grid.Column, null, this.props.children)))),
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: "center" }, trans_1.trans('shop-registration.footer.copyright.text')),
                    React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: "center" },
                        trans_1.trans('shop-registration.footer.terms-of-use.link'),
                        trans_1.trans('shop-registration.footer.privacy-policy.link')))));
    };
    return ShopRegistrationLayout;
}(responsive_component_1.ResponsiveComponent));
exports.ShopRegistrationLayout = ShopRegistrationLayout;
