"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortHeader = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var SortHeader = (function (_super) {
    __extends(SortHeader, _super);
    function SortHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SortHeader.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'cursor-pointer', onClick: function () { return _this.onSort(); } },
            React.createElement("span", { className: 'white-space-nowrap' },
                ((_a = this.props) === null || _a === void 0 ? void 0 : _a.title) || '',
                this.getSortIcon()));
    };
    SortHeader.prototype.onSort = function () {
        var currentDirection = lodash_1.get(this.props, "control$.data.sort." + lodash_1.get(this.props, 'property', null));
        var direction = null;
        if (currentDirection === -1) {
            direction = 1;
        }
        if (currentDirection === 1) {
            direction = null;
        }
        if (!currentDirection) {
            direction = -1;
        }
        this.props.control$.sort(this.props.property, direction);
    };
    SortHeader.prototype.getSortIcon = function () {
        if (!lodash_1.get(this.props, "control$.data.sort." + lodash_1.get(this.props, 'property', null))) {
            return null;
        }
        return parseInt(lodash_1.get(this.props, "control$.data.sort." + lodash_1.get(this.props, 'property', 1)) || 1, 10) === 1
            ? React.createElement(semantic_ui_react_1.Icon, { name: "sort amount up" })
            : React.createElement(semantic_ui_react_1.Icon, { name: "sort amount down" });
    };
    return SortHeader;
}(abstract_component_1.AbstractComponent));
exports.SortHeader = SortHeader;
