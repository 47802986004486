"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceByTemplateScreen = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var moment = require("moment");
var React = require("react");
var DatePicker = require("react-datepicker");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var session_storage_1 = require("../../../../libs/session.storage");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var number_parser_1 = require("../../../common/libs/number-parser");
require("./performance.screen.scss");
var PerformanceByTemplateScreen = (function (_super) {
    __extends(PerformanceByTemplateScreen, _super);
    function PerformanceByTemplateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                template: new field_1.Field({
                    name: 'template',
                    value: null,
                    placeholder: 'Válassz sablont',
                    label: 'Email sablon',
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: '/a/template',
                    loadOptionsAfterMount: true,
                    optionsMap: function (response) { return (response.items || []).map(function (item) { return ({
                        key: item._id,
                        text: item === null || item === void 0 ? void 0 : item.name,
                        value: item === null || item === void 0 ? void 0 : item._id,
                    }); }); }
                }),
                from: new field_1.Field({
                    name: 'from',
                    label: '',
                    value: moment().add(-1, 'month').toDate(),
                }),
                to: new field_1.Field({
                    name: 'to',
                    label: '',
                    value: moment().toDate(),
                }),
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                from: _this.form.fields.from.getValue(),
                to: _this.form.fields.to.getValue(),
            }
        });
        _this.totalSent = 0;
        _this.totalClicked = 0;
        _this.totalClickedUnique = 0;
        _this.totalOpened = 0;
        _this.totalOpenedUnique = 0;
        _this.state = {
            selectedTemplate: null
        };
        return _this;
    }
    PerformanceByTemplateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$value.pipe(operators_1.debounceTime(250)).subscribe(function (value) {
            _this.control.set('query', value === null || value === void 0 ? void 0 : value.query);
            _this.control.set('page', 0);
        }), rxjs_1.timer(0, 1000).subscribe(function () { return _this.forceUpdate(); }), this.form.fields.from.$value.subscribe(function (value) { return _this.control.set('from', moment(value).startOf('day').toDate()); }), this.form.fields.to.$value.subscribe(function (value) { return _this.control.set('to', moment(value).endOf('day').toDate()); }), this.form.fields.template.$value.subscribe(function (value) { return _this.onSelectTemplate(value); }));
    };
    PerformanceByTemplateScreen.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: "TemplatesScreen AdminScreen PerformanceScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                            React.createElement(semantic_ui_react_1.Grid.Column, { className: 'flex-fill' },
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Levelentk\u00E9nti hat\u00E9konys\u00E1g")),
                            React.createElement(semantic_ui_react_1.Grid.Column, { floated: 'right', className: 'date-picker-wrapper justify-content-end' },
                                React.createElement("p", { className: 'header-filter-label' }, "D\u00E1tum sz\u0171r\u00E9s"),
                                React.createElement(semantic_ui_react_1.Grid.Row, null,
                                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                                        React.createElement(semantic_ui_react_1.Form, { className: 'display-flex' },
                                            React.createElement("div", { className: "date-wrapper" },
                                                React.createElement("div", { className: 'position-relative display-flex' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })),
                                                React.createElement("div", { className: 'date-picker-divider' }),
                                                React.createElement("div", { className: 'position-relative display-flex mr-4' },
                                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                        React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                                    React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })))))))),
                        React.createElement(semantic_ui_react_1.Grid.Row, null,
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Form, null,
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.template, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { className: "w-100", control: semantic_ui_react_1.Dropdown, clearable: true, selection: true, search: true, fluid: true, disabled: false }))))),
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 5, className: 'display-flex mb-6', stretched: true },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                                    React.createElement("h5", { className: "flex-fill mb-2" }, "\u00D6sszes kik\u00FCld\u00F6tt email"),
                                    React.createElement("h3", null,
                                        number_parser_1.splitToNumberString(this.totalSent),
                                        " db"))),
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                                    React.createElement("h5", { className: "flex-fill mb-2" }, "\u00D6sszes megnyitott email"),
                                    React.createElement("div", { className: 'display-flex align-items-center' },
                                        React.createElement("h3", { className: 'mr-4' },
                                            number_parser_1.splitToNumberString(this.totalOpened),
                                            " db"),
                                        !!this.totalSent ?
                                            React.createElement("h3", null,
                                                Math.round((this.totalOpened / this.totalSent) * 10000) / 100,
                                                "%") :
                                            React.createElement(semantic_ui_react_1.Icon, { loading: true, className: "circle notched" })))),
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                                    React.createElement("h5", { className: "flex-fill mb-2" }, "\u00D6sszes megnyitott email (egyedi)"),
                                    React.createElement("div", { className: 'display-flex align-items-center' },
                                        React.createElement("h3", { className: 'mr-4' },
                                            number_parser_1.splitToNumberString(this.totalOpenedUnique),
                                            " db"),
                                        !!this.totalSent ?
                                            React.createElement("h3", null,
                                                Math.round((this.totalOpenedUnique / this.totalSent) * 10000) / 100,
                                                "%") :
                                            React.createElement(semantic_ui_react_1.Icon, { loading: true, className: "circle notched" })))),
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                                    React.createElement("h5", { className: "flex-fill mb-2" }, "\u00D6sszes kattint\u00E1s"),
                                    React.createElement("div", { className: 'display-flex align-items-center' },
                                        React.createElement("h3", { className: 'mr-4' },
                                            number_parser_1.splitToNumberString(this.totalClicked),
                                            " db"),
                                        !!this.totalSent ?
                                            React.createElement("h3", null,
                                                Math.round((this.totalClicked / this.totalSent) * 10000) / 100,
                                                "%") :
                                            React.createElement(semantic_ui_react_1.Icon, { loading: true, className: "circle notched" })))),
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Segment, { className: "display-flex flex-column performance-card" },
                                    React.createElement("h5", { className: "flex-fill mb-2" }, "\u00D6sszes kattint\u00E1s (egyedi)"),
                                    React.createElement("div", { className: 'display-flex align-items-center' },
                                        React.createElement("h3", { className: 'mr-4' },
                                            number_parser_1.splitToNumberString(this.totalClickedUnique),
                                            " db"),
                                        !!this.totalSent ?
                                            React.createElement("h3", null,
                                                Math.round((this.totalClickedUnique / this.totalSent) * 10000) / 100,
                                                "%") :
                                            React.createElement(semantic_ui_react_1.Icon, { loading: true, className: "circle notched" })))))),
                    !!this.state.selectedTemplate && React.createElement(repository_list_1.RepositoryList, { control: this.control, ref: function (ref) { return _this.listref = ref; }, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/email-artifact/performance-2/all/" + this.state.selectedTemplate, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 7 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 7 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }))));
    };
    PerformanceByTemplateScreen.prototype.onSelectTemplate = function (templateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(templateId);
                        return [4, this.setState({ selectedTemplate: templateId })];
                    case 1:
                        _a.sent();
                        this.listref.reload();
                        return [2];
                }
            });
        });
    };
    PerformanceByTemplateScreen.prototype.renderItemsWrapper = function (ctx) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        this.totalSent = (_b = (((_a = ctx.state) === null || _a === void 0 ? void 0 : _a.items) || [])) === null || _b === void 0 ? void 0 : _b.reduce(function (acc, item) { var _a; return acc + (((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.sent) || 0); }, 0);
        this.totalClicked = (_d = (((_c = ctx.state) === null || _c === void 0 ? void 0 : _c.items) || [])) === null || _d === void 0 ? void 0 : _d.reduce(function (acc, item) { var _a; return acc + (((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.click) || 0); }, 0);
        this.totalClickedUnique = (_f = (((_e = ctx.state) === null || _e === void 0 ? void 0 : _e.items) || [])) === null || _f === void 0 ? void 0 : _f.reduce(function (acc, item) { var _a; return acc + (((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a['click-uniq']) || 0); }, 0);
        this.totalOpened = (_h = (((_g = ctx.state) === null || _g === void 0 ? void 0 : _g.items) || [])) === null || _h === void 0 ? void 0 : _h.reduce(function (acc, item) { var _a; return acc + (((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a.opened) || 0); }, 0);
        this.totalOpenedUnique = (_k = (((_j = ctx.state) === null || _j === void 0 ? void 0 : _j.items) || [])) === null || _k === void 0 ? void 0 : _k.reduce(function (acc, item) { var _a; return acc + (((_a = item === null || item === void 0 ? void 0 : item.stats) === null || _a === void 0 ? void 0 : _a['opened-uniq']) || 0); }, 0);
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kateg\u00F3ria"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Shop neve"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Kik\u00FCld\u00F6tt"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "\u00D6ssz. megnyit\u00E1s"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Egyedi megnyit\u00E1s"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "\u00D6ssz. kattint\u00E1s"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Egyedi kattint\u00E1s"))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    PerformanceByTemplateScreen.prototype.renderItem = function (ctx, item, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item._id + index, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true }, trans_1.trans("email-performance-type." + item.sessionDescription)),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true }, item.shopTitle || '-'),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                number_parser_1.splitToNumberString(((_a = item.stats) === null || _a === void 0 ? void 0 : _a.sent) || 0),
                " db"),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                "(",
                number_parser_1.splitToNumberString((((_b = item.stats) === null || _b === void 0 ? void 0 : _b.opened) || 0)),
                " db) ",
                Math.round((((_c = item.stats) === null || _c === void 0 ? void 0 : _c.opened) || 0) / (((_d = item.stats) === null || _d === void 0 ? void 0 : _d.sent) || Infinity) * 10000) / 100,
                "%"),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                "(",
                number_parser_1.splitToNumberString((((_e = item.stats) === null || _e === void 0 ? void 0 : _e['opened-uniq']) || 0)),
                " db) ",
                Math.round((((_f = item.stats) === null || _f === void 0 ? void 0 : _f['opened-uniq']) || 0) / (((_g = item.stats) === null || _g === void 0 ? void 0 : _g.sent) || Infinity) * 10000) / 100,
                "%"),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                "(",
                number_parser_1.splitToNumberString((((_h = item.stats) === null || _h === void 0 ? void 0 : _h.click) || 0)),
                " db) ",
                Math.round((((_j = item.stats) === null || _j === void 0 ? void 0 : _j.click) || 0) / (((_k = item.stats) === null || _k === void 0 ? void 0 : _k.sent) || Infinity) * 10000) / 100,
                "%"),
            React.createElement(semantic_ui_react_1.Table.Cell, { collapsing: true },
                "(",
                number_parser_1.splitToNumberString((((_l = item.stats) === null || _l === void 0 ? void 0 : _l['click-uniq']) || 0)),
                " db) ",
                Math.round((((_m = item.stats) === null || _m === void 0 ? void 0 : _m['click-uniq']) || 0) / (((_o = item.stats) === null || _o === void 0 ? void 0 : _o.sent) || Infinity) * 10000) / 100,
                "%"));
    };
    PerformanceByTemplateScreen.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return PerformanceByTemplateScreen;
}(abstract_component_1.AbstractComponent));
exports.PerformanceByTemplateScreen = PerformanceByTemplateScreen;
