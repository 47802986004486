"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryUpdateComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var rxjs_1 = require("rxjs");
var semantic_ui_react_1 = require("semantic-ui-react");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var state_repository_1 = require("../../../common/libs/state-repository");
var CategoryUpdateComponent = (function (_super) {
    __extends(CategoryUpdateComponent, _super);
    function CategoryUpdateComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        _this.repositoryDelete = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repositoryDelete');
        _this.state = {
            isModalOpen: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                name: new field_1.Field({
                    name: 'name',
                    label: 'Név',
                    placeholder: 'Csomag neve',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                order: new field_1.Field({
                    name: 'order',
                    value: null,
                    validators: []
                }),
            }
        });
        return _this;
    }
    CategoryUpdateComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.onSubmit(); }));
                        if (!this.props._id) return [3, 2];
                        return [4, this.repository.get("/a/category/" + this.props._id)];
                    case 1:
                        result = _a.sent();
                        this.form.updateFields(result);
                        _a.label = 2;
                    case 2:
                        rxjs_1.timer(0, 1).subscribe(function () {
                            var result = Math.floor((Math.random() * 11) + 10);
                            if (result === 21) {
                                console.log(result);
                            }
                        });
                        return [2];
                }
            });
        });
    };
    CategoryUpdateComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var update, create, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!this.props._id) return [3, 2];
                        return [4, this.repository.put("/a/category/" + this.props._id, {
                                data: this.form.toJSON()
                            })];
                    case 1:
                        update = _a.sent();
                        if (this.props.onUpdate) {
                            this.props.onUpdate(update);
                        }
                        handle_toasts_1.HandleToasts.success('Sikeres módosítás');
                        this.setState({ isModalOpen: false });
                        return [2];
                    case 2: return [4, this.repository.post('/a/category', {
                            data: this.form.toJSON()
                        })];
                    case 3:
                        create = _a.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres létrehozás');
                        if (this.props.onCreate) {
                            this.props.onCreate(create);
                        }
                        this.setState({ isModalOpen: false });
                        return [3, 5];
                    case 4:
                        e_1 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    CategoryUpdateComponent.prototype.onDelete = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repositoryDelete.delete("/a/category/" + this.props._id)];
                    case 1:
                        _a.sent();
                        if (this.props.onDelete) {
                            this.props.onDelete();
                        }
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    CategoryUpdateComponent.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { open: this.state.isModalOpen, centered: false, size: "tiny", trigger: this.renderTrigger(), onSubmit: function () { return _this.form.submit(); }, onOpen: function () { return _this.setState({ isModalOpen: true }); }, as: semantic_ui_react_1.Form }, this.renderContent());
    };
    CategoryUpdateComponent.prototype.renderTrigger = function () {
        if (this.props.children) {
            return this.props.children;
        }
        return this.props._id
            ? React.createElement(semantic_ui_react_1.Button, { color: "blue" }, "Csomag m\u00F3dos\u00EDt\u00E1sa")
            : React.createElement(semantic_ui_react_1.Button, { color: "blue" }, "\u00DAj csomag l\u00E9trehoz\u00E1sa");
    };
    CategoryUpdateComponent.prototype.renderContent = function () {
        var _a, _b, _c;
        return [
            React.createElement(semantic_ui_react_1.Modal.Header, { key: 0 }, this.props._id
                ? 'Csomag módosítása'
                : 'Új csomag létrehozása'),
            React.createElement(semantic_ui_react_1.Modal.Content, { key: 1 },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.name, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input })),
                React.createElement(detailed_error_1.DetailedError, { error: (_a = this.state.repository) === null || _a === void 0 ? void 0 : _a.error })),
            React.createElement(semantic_ui_react_1.Modal.Actions, { key: 2 },
                React.createElement(semantic_ui_react_1.Button, { color: "blue", disabled: (_b = this.state.repository) === null || _b === void 0 ? void 0 : _b.loading, loading: (_c = this.state.repository) === null || _c === void 0 ? void 0 : _c.loading }, "Ment\u00E9s"))
        ];
    };
    return CategoryUpdateComponent;
}(abstract_component_1.AbstractComponent));
exports.CategoryUpdateComponent = CategoryUpdateComponent;
