"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productLoops = void 0;
var productCardBasicWithLoop = require('./product-card-basic-with-loop');
var categoryProLoop = require('./category-pro-loop');
exports.productLoops = [
    {
        categories: ['cart', 'visited', 'ordered', 'general', 'sale', 'news', 'general'],
        create: function (options) { return ({
            title: options.sourceTitle,
            body: productCardBasicWithLoop.body(options.variable, options.sourceTitle, options.sourceDescription),
            placeholder: productCardBasicWithLoop.placeholder(options.variable, options.sourceTitle, options.sourceDescription),
        }); }
    },
    {
        categories: ['category-pro'],
        create: function (options) { return ({
            title: options.sourceTitle,
            body: categoryProLoop.body(options.variable, options.sourceTitle, options.sourceDescription),
            placeholder: categoryProLoop.placeholder(options.variable, options.sourceTitle, options.sourceDescription),
        }); }
    },
];
