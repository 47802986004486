"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopRegistrationFirstStepComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var url_validator_1 = require("../../../common/components/url-validator/url-validator");
var industry_options_1 = require("../../../common/libs/industry-options");
var state_repository_1 = require("../../../common/libs/state-repository");
var color_picker_1 = require("../../../common/semantic/color-picker/color-picker");
var image_uploader_1 = require("../../../common/semantic/image-uploader/image-uploader");
var ShopRegistrationFirstStepComponent = (function (_super) {
    __extends(ShopRegistrationFirstStepComponent, _super);
    function ShopRegistrationFirstStepComponent() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var _this = _super.apply(this, arguments) || this;
        _this.user = local_store_1.LocalStore.getItem('User');
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title: new field_1.Field({
                    name: 'title',
                    label: trans_1.trans('shop-registration.first-step.form.title.label'),
                    value: ((_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.title) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                email: new field_1.Field({
                    name: 'email',
                    label: trans_1.trans('shop-registration.first-step.form.email.label'),
                    value: ((_d = (_c = _this.props) === null || _c === void 0 ? void 0 : _c.values) === null || _d === void 0 ? void 0 : _d.email) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                phone: new field_1.Field({
                    name: 'phone',
                    label: trans_1.trans('shop-registration.first-step.form.phone.label'),
                    value: ((_f = (_e = _this.props) === null || _e === void 0 ? void 0 : _e.values) === null || _f === void 0 ? void 0 : _f.phone) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                url: new field_1.Field({
                    name: 'url',
                    label: trans_1.trans('shop-registration.first-step.form.url.label'),
                    value: ((_h = (_g = _this.props) === null || _g === void 0 ? void 0 : _g.values) === null || _h === void 0 ? void 0 : _h.url) || '',
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                        new url_validator_1.UrlValidator('invalid-url')
                    ]
                }),
                industry: new field_1.Field({
                    name: 'industry',
                    placeholder: trans_1.trans('shop-registration.first-step.form.industry.placeholder'),
                    label: trans_1.trans('shop-registration.first-step.form.industry.label'),
                    value: ((_k = (_j = _this.props) === null || _j === void 0 ? void 0 : _j.values) === null || _k === void 0 ? void 0 : _k.industry) || '',
                    options: industry_options_1.industryOptions.map(function (i) { return (__assign(__assign({}, i), { text: trans_1.trans(i.text) })); }),
                    validators: [
                        new required_validator_1.RequiredValidator('required'),
                    ]
                }),
                themeColor: new field_1.Field({
                    name: 'themeColor',
                    placeholder: trans_1.trans('shop-registration.first-step.form.themeColor.placeholder'),
                    label: trans_1.trans('shop-registration.first-step.form.themeColor.label'),
                    value: ((_m = (_l = _this.props) === null || _l === void 0 ? void 0 : _l.values) === null || _m === void 0 ? void 0 : _m.themeColor) || '',
                    validators: []
                }),
                logo: new field_1.Field({
                    name: 'logo',
                    placeholder: trans_1.trans('shop-registration.first-step.form.logo.placeholder'),
                    label: trans_1.trans('shop-registration.first-step.form.logo.label'),
                    value: ((_p = (_o = _this.props) === null || _o === void 0 ? void 0 : _o.values) === null || _p === void 0 ? void 0 : _p.logo) || '',
                    validators: []
                }),
            }
        });
        return _this;
    }
    ShopRegistrationFirstStepComponent.prototype.render = function () {
        var _this = this;
        var _a, _b;
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { textAlign: 'center', as: 'h2' }, trans_1.trans('shop-registration.first-step.title')),
                    React.createElement("p", null, trans_1.trans('shop-registration.first-step.description')))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderForm())),
            ((_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.error) && React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(detailed_error_1.DetailedError, { error: (_b = this.state.shop) === null || _b === void 0 ? void 0 : _b.error }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'center', className: 'button-container' },
                    React.createElement(semantic_ui_react_1.Button, { basic: false, onClick: function () { return _this.handleSubmit(); }, content: trans_1.trans('shop-registration.next-button.title') }),
                    React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.handleCancel(); }, basic: true, id: 'continue-later', content: trans_1.trans('shop-registration.cancel-button.title') }))));
    };
    ShopRegistrationFirstStepComponent.prototype.renderForm = function () {
        return React.createElement(semantic_ui_react_1.Form, null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.title, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false, className: 'required' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.email, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false, className: 'required', type: "email", pattern: "[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.phone, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false, className: 'required', type: "tel" })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.url, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: function (props) { return (React.createElement(semantic_ui_react_1.Input, __assign({}, props, { label: { content: 'https://' } }))); }, fluid: true, disabled: false, className: 'required' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.industry, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Select, fluid: true, disabled: false, className: 'required' })),
            React.createElement(semantic_ui_react_1.Header, { className: 'SubHeader', as: 'h5' }, trans_1.trans('shop-registration.first-step.form.subtitle.shop-style').toString()),
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: 'mb-0' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.logo, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: image_uploader_1.ImageUploader, fluid: true })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.themeColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true }))));
    };
    ShopRegistrationFirstStepComponent.prototype.getPayload = function () {
        var payload = this.form.toJSON();
        payload.url = this.parseUrl(payload.url);
        if (payload.email) {
            payload.email = payload.email.toLowerCase();
        }
        return payload;
    };
    ShopRegistrationFirstStepComponent.prototype.handleSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid, data, shop, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            return [2];
                        }
                        data = this.getPayload();
                        return [4, this.shop.post('/u/user-shop/registration', { data: data })];
                    case 2:
                        shop = _a.sent();
                        if (this.props.onSubmit) {
                            this.props.onSubmit(shop);
                        }
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ShopRegistrationFirstStepComponent.prototype.handleCancel = function () {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };
    ShopRegistrationFirstStepComponent.prototype.parseUrl = function (url) {
        var trimmedUrl = url.trim().replace(/\s/g, '');
        if ((/^(http:\/\/|https:\/\/)/).test(trimmedUrl)) {
            return trimmedUrl;
        }
        return "https://" + trimmedUrl;
    };
    return ShopRegistrationFirstStepComponent;
}(abstract_component_1.AbstractComponent));
exports.ShopRegistrationFirstStepComponent = ShopRegistrationFirstStepComponent;
