"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerFilter = void 0;
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var state_repository_1 = require("../../libs/state-repository");
var expression_filter_1 = require("./expression-filter");
var CustomerFilter = (function (_super) {
    __extends(CustomerFilter, _super);
    function CustomerFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expressions = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'expressions');
        _this.state = {
            expressionChain: _this.props.expressions || [],
            nominatedExpression: null,
            nominatedOperator: null,
            nominatedValue: null
        };
        return _this;
    }
    CustomerFilter.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var prev = JSON.stringify(prevProps.expressions);
        var next = JSON.stringify(this.props.expressions);
        if (prev !== next) {
            this.setState({
                expressionChain: this.props.expressions || []
            });
        }
    };
    CustomerFilter.prototype.componentDidMount = function () {
        this.init();
    };
    CustomerFilter.prototype.init = function () {
        var shop = session_storage_1.SessionStorage.getItem('SelectedShop');
        this.expressions.get("/s/" + shop._id + "/data/customer-filter/filter-expressions");
    };
    CustomerFilter.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Grid, { columns: 1 },
            this.state.expressionChain.map(function (item, index) {
                var _a;
                return React.createElement(semantic_ui_react_1.Grid.Column, { key: index + "-2", className: 'py-0' },
                    React.createElement(expression_filter_1.ExpressionFilter, { type: item.type, operator: item.operator, value: item.value, expressions: ((_a = _this.state.expressions) === null || _a === void 0 ? void 0 : _a.response) || [], onChange: function (result) { return _this.onChange(result, index); }, onDelete: function () { return _this.removeFilter(index); } }),
                    React.createElement("div", { className: 'my-2 display-flex justify-content-center' },
                        React.createElement("span", null, "\u00C9S")));
            }),
            React.createElement(semantic_ui_react_1.Grid.Column, { key: -1 + "-2", className: 'py-0' },
                React.createElement(semantic_ui_react_1.Button, { content: "Sz\u0171r\u0151felt\u00E9tel hozz\u00E1ad\u00E1sa", basic: true, icon: "plus", onClick: function () { return _this.addNewFilter(); }, className: 'w-100' })));
    };
    CustomerFilter.prototype.onChange = function (expression, index) {
        return __awaiter(this, void 0, void 0, function () {
            var currentResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentResult = this.state.expressionChain[index];
                        if (!currentResult) return [3, 2];
                        this.state.expressionChain[index] = expression;
                        return [4, this.setState({
                                expressionChain: this.state.expressionChain
                            })];
                    case 1:
                        _a.sent();
                        return [3, 4];
                    case 2: return [4, this.setState({
                            expressionChain: __spreadArrays(this.state.expressionChain, [
                                expression
                            ])
                        })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.props.onChange(this.state.expressionChain);
                        return [2];
                }
            });
        });
    };
    CustomerFilter.prototype.addNewFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({
                            expressionChain: __spreadArrays((this.state.expressionChain || []), [
                                {}
                            ])
                        })];
                    case 1:
                        _a.sent();
                        this.props.onChange(this.state.expressionChain);
                        return [2];
                }
            });
        });
    };
    CustomerFilter.prototype.removeFilter = function (index) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({
                            expressionChain: this.state.expressionChain.filter(function (item, i) { return i !== index; })
                        })];
                    case 1:
                        _a.sent();
                        this.props.onChange(this.state.expressionChain);
                        return [2];
                }
            });
        });
    };
    return CustomerFilter;
}(React.Component));
exports.CustomerFilter = CustomerFilter;
