module.exports = {
    'detailed-error.default-title': 'Hiba történt!',
    'errors.Unauthenticated': 'Az általad megadott email és/vagy jelszó nem megfelelő.',
    'errors.UnknownUser': 'Az általad megadott email és/vagy jelszó nem megfelelő.',
    'errors.UserAlreadyExists': 'Az általad megadott felhasználó már létezik.',
    'errors.Invalid': 'Érvénytelen',
    'errors.Network Error': 'A szerver nem elérhető',
    'errors.BadAccessToken': 'Hibás hozzáférés',
    'errors.MissingOldPassword': 'Hiányzó régi jelszó!',
    'errors.MissingNewPassword': 'Hiányzó új jelszó!',
    'errors.MissingNewRePassword': 'Hiányzó új jelszó!',
    'errors.MismatchRePassword': 'A megadott új jelszavak nem eggyeznek !',
    'errors.InvalidOldPassword': 'Hibás régi jelszó!',
    'errors.integration.woocommerce.accessError.title': 'Hupsz! Valami hiba történt!',
    'errors.integration.woocommerce.accessError.description': 'Nem sikerült létrehozni a kapcsolatot az M.I. és a webáruházad között. Kérlek ellenőrizd, hogy mindent a leírtaknak megfelelően csináltál. Ha ez megtörtént próbáld újra a kapcsolat létrehozását a gombra kattintva. ',
};
