"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'settings.menu.title': 'Beállítások',
    'settings.menu.item.profiles': 'Profilok',
    'settings.menu.item.integration': 'Integráció',
    'settings.menu.item.subscription': 'Előfizetés',
    'settings.menu.item.billing': 'Számlázás',
    'settings.menu.item.managers': 'Kezelők',
    'settings.menu.item.email': 'Levélküldés',
    'settings.menu.item.emailSettings': 'Levélküldés',
    'settings.menu.item.bounced-emails': 'Visszapattanó levelek',
    'settings.menu.item.creditCardPayments': 'Bankkártyás fizetés',
    'settings.billing.title': 'Számlázási adatok',
    'settings.billing.companyName.label': 'Cégnév',
    'settings.billing.taxNumber.label': 'Adószám',
    'settings.billing.euTaxNumber.label': 'EU adószám',
    'settings.billing.country.label': 'Ország',
    'settings.billing.city.label': 'Település',
    'settings.billing.zipCode.label': 'Irányítószám',
    'settings.billing.address.label': 'Cím',
    'settings.billing.termsOfUse.label': React.createElement("label", null,
        "Elfogadom a ",
        React.createElement("a", { target: "_blank", href: "https://miclub.hu/aszf", onClick: function (e) { return e.stopPropagation(); } }, " \u00C1ltal\u00E1nos szerz\u0151d\u00E9si felt\u00E9teleket,"),
        " \u00E9s az ",
        React.createElement("a", { target: "_blank", href: "https://miclub.hu/adatvedelem", onClick: function (e) { return e.stopPropagation(); } }, "Adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3")),
    'settings.billing.creditCardSettings.title': 'Bankkártyás fizetés',
    'settings.profile.user-profile.title': 'Felhasználói profil',
    'settings.profile.user-profile.name.label': 'Név',
    'settings.profile.user-profile.email.label': 'Email',
    'settings.profile.user-profile.password.label': 'Jelszó',
    'settings.profile.user-profile.change-password.button.title': 'Jelszóváltoztatás',
    'settings.profile.shop-profile.title': 'Webáruház profil',
    'settings.profile.shop-profile.data.subtitle': 'E-mail alap sablon személyreszabása',
    'settings.profile.shop-profile.theme.subtitle': 'Stílus',
    'settings.profile.shop-profile.name.label': 'Név',
    'settings.profile.shop-profile.industry.label': 'Iparág',
    'settings.profile.shop-profile.url.label': 'URL',
    'settings.profile.shop-profile.image-uploader.label': 'Logó',
    'settings.profile.shop-profile.color-picker-themeColor.label': 'Témaszín',
    'settings.profile.shop-profile.color-picker-buttonTextColor.label': 'Gomb szövegszín',
    'settings.profile.shop-profile.color-picker-buttonBackgroundColor.label': 'Gomb háttérszín',
    'settings.profile.shop-profile.color-picker-headingTextColor.label': 'Header szövegszín',
    'settings.profile.shop-profile.color-picker-headingBackgroundColor.label': 'Header háttérszín',
    'settings.profile.shop-profile.color-picker-textTextColor.label': 'Szövegszín',
    'settings.profile.shop-profile.color-picker-headerBackgroundColor.label': 'Fejléc háttérszín',
    'settings.profile.shop-profile.color-picker-headerTextColor.label': 'Fejléc szövegszín',
    'settings.profile.change-password.title': 'Jelszóváltoztatás',
    'settings.profile.change-password.old.label': 'Régi jelszó',
    'settings.profile.change-password.new.label': 'Új jelszó',
    'settings.profile.change-password.re-new.label': 'Új jelszó újra',
    'settings.integration.type.label': 'Webáruház típusa',
    'settings.integration.name.label': 'Webáruház neve',
    'settings.integration.identifier.label': 'Integrációs azonosító',
    'settings.integration.createdAt.label': 'Integráció dátuma',
    'settings.integration.updatedAt.label': 'Utolsó frissítés dátuma',
    'settings.integration.data.title': 'Integrációs adatok',
    'settings.integration.sync.title': 'Frissítés eredménye',
    'settings.email.title': 'Levelezés',
    'settings.email.mailgun-credentials.senderEmail.label': 'Küldő email címe',
    'settings.email.mailgun-credentials.mailgunDomain.label': 'Mailgun domain',
    'settings.miniCrmSettings.title': 'MiniCRM Beállítások',
    'settings.miniCrmSettings.customerId.label': 'Ügyfél azonosító',
    'settings.miniCrmSettings.mainDataSheetId.label': 'Adatlap azonosító',
    'settings.miniCrmSettings.discount.label': 'Kedvezmény (%)',
    'settings.miniCrmSettings.miLastStarted.label': 'Utolsó elindítás dátuma',
    'settings.miniCrmSettings.lastBillingDate.label': 'Utolsó számlázás dátuma',
    'settings.miniCrmSettings.maintenanceFee.label': 'Üzemeltetési díj (Ft)',
    'settings.miniCrmSettings.maintenanceFeeEur.label': 'Üzemeltetési díj (Euró)',
    'settings.miniCrmSettings.billingCurrency.label': 'Számla pénzneme',
    'settings.miniCrmSettings.billingCurrency.placeholder': 'Válasszon pénznemet a következő számlához!',
    'settings.miniCrmSettings.paymentDeadline.label': 'Fizetési határidő (nap)',
    'settings.miniCrmSettings.billingPeriod.label': 'Számlázási periódus (hónap)',
    'settings.miniCrmSettings.isReverseCharge.label': 'Fordított adózás',
    'settings.admin.creditCardSettings.title': 'Bankkártyás fizetés',
    'settings.admin.creditCardSettings.payments': 'Számlák, bankkártyás fizetések',
    'shop-status.active': 'Aktív',
    'shop-status.suspended': 'Szüneteltetve',
    'shop-status.syncActive': 'Adat szinkronizáció',
    'shop-status.billingActive': 'Számlázás',
    'shop-status.emailActive': 'Email küldés',
    'shop-status.eventActive': 'Események fogadása',
};
