"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordRequestComponent = void 0;
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var forgot_password_request_component_1 = require("@codebuild/uikit/modules/codebuild/authentication/components/forgot-password-request.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var ForgotPasswordRequestComponent = (function (_super) {
    __extends(ForgotPasswordRequestComponent, _super);
    function ForgotPasswordRequestComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ForgotPasswordRequestComponent.prototype.renderSubmit = function () {
        return React.createElement(semantic_ui_react_1.Button, { className: "ForgotPasswordSubmit", fluid: true, type: "submit", loading: this.props.loading, positive: false, "data-cy": "form-submit" }, trans_1.trans('auth.forgot-password.request-form.submit'));
    };
    return ForgotPasswordRequestComponent;
}(forgot_password_request_component_1.ForgotPasswordRequestComponent));
exports.ForgotPasswordRequestComponent = ForgotPasswordRequestComponent;
