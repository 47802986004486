module.exports = {
    'menu.header.revenue.label': 'Összbevétel',
    'menu.footer.user.greetings': 'Szia',
    'menu.item.products': 'Termékek',
    'menu.item.categories': 'Kategóriák',
    'menu.item.leads': 'Érdeklődők',
    'menu.item.newsletters': 'Automata hírlevelek',
    'menu.item.custom-newsletters': 'Egyedi hírlevelek',
    'menu.item.orders': 'Megrendelések',
    'menu.item.admin': 'Adminisztrációs felület',
    'menu.item.dashboard': 'Irányítópult',
    'menu.item.performance': 'Levelezés hatékonysága',
    'menu.item.support-email-prefix': 'Segíthetünk?',
    'menu.shop-selector.title': 'Webshop választó',
    'menu.shop-selector.subtitle': 'A választott webáruházadon végezhetsz műveleteket, a hozzá tartozó adatokat fogod látni',
    'menu.shop-selector.selected': 'Bejelentkezve',
    'menu.shop-selector.not-selected': 'Átjelentkezés',
};
