"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SynchronizationProgressScreen = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var synchronization_progress_component_1 = require("../components/synchronization-progress.component");
var SynchronizationProgressScreen = (function (_super) {
    __extends(SynchronizationProgressScreen, _super);
    function SynchronizationProgressScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SynchronizationProgressScreen.prototype.render = function () {
        var _a, _b, _c;
        return React.createElement("div", { className: "ShopRegistrationScreen" },
            React.createElement(synchronization_progress_component_1.SynchronizationProgressComponent, { shopId: (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.shopId }));
    };
    return SynchronizationProgressScreen;
}(abstract_component_1.AbstractComponent));
exports.SynchronizationProgressScreen = SynchronizationProgressScreen;
