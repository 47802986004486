"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptInvitationComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var equals_to_validator_1 = require("@codebuild/uikit/libs/form/validators/equals-to.validator");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_checkbox_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.checkbox.form-control-type");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var detailed_error_1 = require("../../common/components/errors/detailed-error");
var handle_toasts_1 = require("../../common/libs/handle.toasts");
var state_repository_1 = require("../../common/libs/state-repository");
var password_input_1 = require("../../common/semantic/password-input/password-input");
var password_strength_1 = require("../../common/semantic/password-strength/password-strength");
var AcceptInvitationComponent = (function (_super) {
    __extends(AcceptInvitationComponent, _super);
    function AcceptInvitationComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        _this.user = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'user');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                lastName: new field_1.Field({
                    name: 'lastName',
                    label: 'Vezetéknév',
                    placeholder: '',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                firstName: new field_1.Field({
                    name: 'firstName',
                    label: 'Keresztnév',
                    placeholder: '',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                password: new field_1.Field({
                    name: 'password',
                    label: 'Jelszó',
                    placeholder: '',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                rePassword: new field_1.Field({
                    name: 'rePassword',
                    label: 'Jelszó újra',
                    placeholder: '',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!'), new equals_to_validator_1.EqualsToValidator('password', 'A megadott jelszavak nem eggyeznek meg')
                    ]
                }),
                acceptedTermsOfUse: new field_1.Field({
                    name: 'accepted-terms-of-use',
                    label: trans_1.trans('auth.registration.form.accepted-terms-of-use.label'),
                    placeholder: trans_1.trans('auth.registration.form.accepted-terms-of-use.placeholder'),
                    validators: [
                        new required_validator_1.RequiredValidator('required')
                    ]
                }),
                acceptedPrivacyPolicy: new field_1.Field({
                    name: 'accepted-privacy-policy',
                    label: trans_1.trans('auth.registration.form.accepted-privacy-policy.label'),
                    placeholder: trans_1.trans('auth.registration.form.accepted-privacy-policy.placeholder'),
                    value: true,
                    validators: []
                }),
            }
        });
        return _this;
    }
    AcceptInvitationComponent.prototype.componentDidMount = function () {
        this.isRegFinished();
    };
    AcceptInvitationComponent.prototype.render = function () {
        var _this = this;
        var _a;
        return React.createElement(semantic_ui_react_1.Dimmer.Dimmable, { as: semantic_ui_react_1.Segment, className: "RegistrationScreen", attached: true },
            React.createElement(semantic_ui_react_1.Dimmer, { active: this.props.routeLoading, inverted: true },
                React.createElement(semantic_ui_react_1.Loader, { active: this.props.routeLoading })),
            React.createElement(semantic_ui_react_1.Header, { as: 'h1', textAlign: 'center' }, "Megh\u00EDv\u00E1s elfogad\u00E1sa"),
            !this.state.registrationFinished && React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.handleSubmit(); } },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.lastName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, "data-cy": "form-last-name" })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.firstName, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, "data-cy": "form-first-name" })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.password, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-password" })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.password, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType, component: password_strength_1.PasswordStrength }),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.rePassword, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: password_input_1.PasswordInput, enableReveal: true, type: "password", "data-cy": "form-re-password" })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.acceptedTermsOfUse, type: semantic_ui_checkbox_form_control_type_1.SemanticUiCheckboxFormControlType },
                    React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Checkbox, "data-cy": "accepted-terms-of-use" })),
                this.renderSubmit(),
                React.createElement(detailed_error_1.DetailedError, { error: (_a = this.state.repository) === null || _a === void 0 ? void 0 : _a.error })),
            !!this.state.registrationFinished && React.createElement("div", { className: 'p-4 display-flex justify-content-center' },
                React.createElement(semantic_ui_react_1.Button, { loading: this.state.loading, disabled: this.state.loading, content: 'Meghívás elfogadása', onClick: function () { return _this.acceptInvitation(); } })));
    };
    AcceptInvitationComponent.prototype.renderSubmit = function () {
        return React.createElement(semantic_ui_react_1.Button, { className: "LoginButton", fluid: true, type: "submit", loading: this.props.loading, positive: false, "data-cy": "form-submit" }, trans_1.trans('auth.registration.form.submit'));
    };
    AcceptInvitationComponent.prototype.isRegFinished = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var user, token, shop, response, e_1;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        _k.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        user = (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.query) === null || _c === void 0 ? void 0 : _c.u;
                        token = (_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.route) === null || _e === void 0 ? void 0 : _e.query) === null || _f === void 0 ? void 0 : _f.t;
                        shop = (_j = (_h = (_g = this.props) === null || _g === void 0 ? void 0 : _g.route) === null || _h === void 0 ? void 0 : _h.query) === null || _j === void 0 ? void 0 : _j.s;
                        return [4, this.repository.put('/p/register/isUserRegFinished', {
                                data: {
                                    user: user,
                                    token: token
                                }
                            })];
                    case 1:
                        response = _k.sent();
                        this.setState({ loading: false, registrationFinished: response.isRegistrationFinished });
                        return [3, 3];
                    case 2:
                        e_1 = _k.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    AcceptInvitationComponent.prototype.handleSubmit = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var user, token, shop, e_2;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        _k.trys.push([0, 2, , 3]);
                        this.setState({ loading: true });
                        user = (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.query) === null || _c === void 0 ? void 0 : _c.u;
                        token = (_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.route) === null || _e === void 0 ? void 0 : _e.query) === null || _f === void 0 ? void 0 : _f.t;
                        shop = (_j = (_h = (_g = this.props) === null || _g === void 0 ? void 0 : _g.route) === null || _h === void 0 ? void 0 : _h.query) === null || _j === void 0 ? void 0 : _j.s;
                        return [4, this.repository.post('/p/register/acceptInvitation', {
                                data: {
                                    user: user,
                                    shop: shop,
                                    token: token,
                                    credentials: this.form.toJSON()
                                }
                            })];
                    case 1:
                        _k.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success('Sikeresen elfogadás');
                        router_provider_1.RouterProvider.goTo('/login');
                        return [3, 3];
                    case 2:
                        e_2 = _k.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    AcceptInvitationComponent.prototype.acceptInvitation = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var user, token, shop, e_3;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        _k.trys.push([0, 2, , 3]);
                        user = (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.query) === null || _c === void 0 ? void 0 : _c.u;
                        token = (_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.route) === null || _e === void 0 ? void 0 : _e.query) === null || _f === void 0 ? void 0 : _f.t;
                        shop = (_j = (_h = (_g = this.props) === null || _g === void 0 ? void 0 : _g.route) === null || _h === void 0 ? void 0 : _h.query) === null || _j === void 0 ? void 0 : _j.s;
                        this.setState({ loading: true });
                        return [4, this.repository.post('/p/register/acceptInvitation', {
                                data: {
                                    user: user,
                                    shop: shop,
                                    token: token,
                                    credentials: null
                                }
                            })];
                    case 1:
                        _k.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success('Sikeresen elfogadás');
                        router_provider_1.RouterProvider.goTo('/login');
                        return [3, 3];
                    case 2:
                        e_3 = _k.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült végrehajtani a műveletet');
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return AcceptInvitationComponent;
}(abstract_component_1.AbstractComponent));
exports.AcceptInvitationComponent = AcceptInvitationComponent;
