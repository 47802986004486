"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAYMENT_EVENT_EVENT_TYPES = void 0;
var PAYMENT_EVENT_EVENT_TYPES;
(function (PAYMENT_EVENT_EVENT_TYPES) {
    PAYMENT_EVENT_EVENT_TYPES["ADMIN_TURNED_ON_ONLINE_PAYMENT_FOR_SHOP"] = "ADMIN_TURNED_ON_ONLINE_PAYMENT_FOR_SHOP";
    PAYMENT_EVENT_EVENT_TYPES["ADMIN_TURNED_OFF_ONLINE_PAYMENT_FOR_SHOP"] = "ADMIN_TURNED_OFF_ONLINE_PAYMENT_FOR_SHOP";
    PAYMENT_EVENT_EVENT_TYPES["SHOP_MANAGER_CONFIRMED_CREDIT_CARD_PAYMENT"] = "SHOP_MANAGER_CONFIRMED_CREDIT_CARD_PAYMENT";
    PAYMENT_EVENT_EVENT_TYPES["SHOP_MANAGER_TURNED_OFF_CREDIT_CARD_PAYMENT"] = "SHOP_MANAGER_TURNED_OFF_CREDIT_CARD_PAYMENT";
    PAYMENT_EVENT_EVENT_TYPES["CARD_REGISTRATION_SUCCESSFUL_REGISTRATION_FEE_PAYMENT"] = "CARD_REGISTRATION_SUCCESSFUL_REGISTRATION_FEE_PAYMENT";
    PAYMENT_EVENT_EVENT_TYPES["CARD_REGISTRATION_FAILED_REGISTRATION_FEE_PAYMENT"] = "CARD_REGISTRATION_FAILED_REGISTRATION_FEE_PAYMENT";
    PAYMENT_EVENT_EVENT_TYPES["CARD_REGISTRATION_SUCCESSFUL_REGISTRATION_FEE_REFUND"] = "CARD_REGISTRATION_SUCCESSFUL_REGISTRATION_FEE_REFUND";
    PAYMENT_EVENT_EVENT_TYPES["CARD_REGISTRATION_FAILED_REGISTRATION_FEE_REFUND"] = "CARD_REGISTRATION_FAILED_REGISTRATION_FEE_REFUND";
    PAYMENT_EVENT_EVENT_TYPES["RECURRING_FEE_SUCCESSFUL_TRANSACTION"] = "RECURRING_FEE_SUCCESSFUL_TRANSACTION";
    PAYMENT_EVENT_EVENT_TYPES["RECURRING_FEE_FAILED_TRANSACTION"] = "RECURRING_FEE_FAILED_TRANSACTION";
})(PAYMENT_EVENT_EVENT_TYPES = exports.PAYMENT_EVENT_EVENT_TYPES || (exports.PAYMENT_EVENT_EVENT_TYPES = {}));
