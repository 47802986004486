"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopPaymentEvents = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var axios_1 = require("axios");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var environment_1 = require("@codebuild/uikit/libs/environment");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var moment = require("moment");
var payment_event_types_const_1 = require("../../../../constants/payment-event-types.const");
var transformRawEventIntoHumanReadableFormat = function (event) {
    var _a, _b, _c, _d, _e, _f;
    switch (event.event) {
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.ADMIN_TURNED_ON_ONLINE_PAYMENT_FOR_SHOP: {
            return __assign(__assign({}, event), { message: "Az adminisztrátor bekapcsolta a bankkártyás fizetést.", icon: "check circle", iconColor: "green" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.ADMIN_TURNED_OFF_ONLINE_PAYMENT_FOR_SHOP: {
            return __assign(__assign({}, event), { message: "Az adminisztrátor kikapcsolta a bankkártyás fizetést.", icon: "times circle", iconColor: "red" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.SHOP_MANAGER_CONFIRMED_CREDIT_CARD_PAYMENT: {
            return __assign(__assign({}, event), { message: "Ügyfél megerősítette a bankkártyás fizetést.", icon: "check circle", iconColor: "green" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.SHOP_MANAGER_TURNED_OFF_CREDIT_CARD_PAYMENT: {
            return __assign(__assign({}, event), { message: "Ügyfél kikapcsolta a bankkártyás fizetést.", icon: "times circle", iconColor: "red" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.CARD_REGISTRATION_SUCCESSFUL_REGISTRATION_FEE_PAYMENT: {
            var transactionId = (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.transactionId;
            var message = event.data.amount + " ft-os d\u00EDj levon\u00E1sra ker\u00FClt.";
            if (transactionId) {
                message += " Tranzakci\u00F3s azonos\u00EDt\u00F3: " + transactionId + ".";
            }
            return __assign(__assign({}, event), { message: message, icon: "credit card outline", iconColor: "green" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.CARD_REGISTRATION_FAILED_REGISTRATION_FEE_PAYMENT: {
            var transactionId = (_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.transactionId;
            var resultCode = (_c = event === null || event === void 0 ? void 0 : event.data) === null || _c === void 0 ? void 0 : _c.resultCode;
            var message = event.data.amount + " ft-os d\u00EDjat nem tudtuk levonni a k\u00E1rty\u00E1r\u00F3l.";
            if (transactionId) {
                message += " Tranzakci\u00F3s azonos\u00EDt\u00F3: " + transactionId + ".";
            }
            if (resultCode) {
                message += " Result code: " + transactionId + ".";
            }
            return __assign(__assign({}, event), { message: message, icon: "credit card outline", iconColor: "red" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.CARD_REGISTRATION_SUCCESSFUL_REGISTRATION_FEE_REFUND: {
            return __assign(__assign({}, event), { message: event.data.amount + " ft-os d\u00EDj visszat\u00E9r\u00EDt\u00E9sre ker\u00FClt.", icon: "undo", iconColor: "green" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.CARD_REGISTRATION_FAILED_REGISTRATION_FEE_REFUND: {
            return __assign(__assign({}, event), { message: event.data.amount + " ft-os d\u00EDjat nem tudtuk visszat\u00E9r\u00EDteni.", icon: "undo", iconColor: "red" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.RECURRING_FEE_SUCCESSFUL_TRANSACTION: {
            var transactionId = (_d = event === null || event === void 0 ? void 0 : event.data) === null || _d === void 0 ? void 0 : _d.transactionId;
            var message = "Havi díjfizetés: sikeres fizetés.";
            if (transactionId) {
                message += " Tranzakci\u00F3s azonos\u00EDt\u00F3: " + transactionId + ".";
            }
            return __assign(__assign({}, event), { message: message, icon: "credit card outline", iconColor: "green" });
        }
        case payment_event_types_const_1.PAYMENT_EVENT_EVENT_TYPES.RECURRING_FEE_FAILED_TRANSACTION: {
            var transactionId = (_e = event === null || event === void 0 ? void 0 : event.data) === null || _e === void 0 ? void 0 : _e.transactionId;
            var resultCode = (_f = event === null || event === void 0 ? void 0 : event.data) === null || _f === void 0 ? void 0 : _f.resultCode;
            var message = "Havi díjfizetés: sikertelen fizetés.";
            if (transactionId) {
                message += " Tranzakci\u00F3s azonos\u00EDt\u00F3: " + transactionId + ".";
            }
            if (resultCode) {
                message += " Result code: " + transactionId + ".";
            }
            return __assign(__assign({}, event), { message: message, icon: "credit card outline", iconColor: "red" });
        }
        default: {
            console.warn('Unsupported event type! Event:', event);
            return __assign(__assign({}, event), { message: event.event, icon: "credit card outline", iconColor: "black" });
        }
    }
};
var ShopPaymentEvents = (function (_super) {
    __extends(ShopPaymentEvents, _super);
    function ShopPaymentEvents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isLoading: true,
            isError: false,
            isSuccess: false,
            events: []
        };
        return _this;
    }
    ShopPaymentEvents.prototype.fetchPaymentEventsByShopId = function () {
        return __awaiter(this, void 0, void 0, function () {
            var baseUrl, url, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true, isError: false, isSuccess: false });
                        baseUrl = environment_1.Environment.get('api');
                        url = baseUrl + "/s/" + this.props.shopId + "/shop/bigfish/payment-events?shopId=" + this.props.shopId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, axios_1.default.get(url, {
                                headers: {
                                    Authorization: get_access_token_1.getAccessToken()
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        this.setState({ events: response.data, isSuccess: true });
                        console.log('fetchPaymentEventsByShopId events:', response.data);
                        return [3, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.log('fetchPaymentEventsByShopId error:', error_1);
                        toast_util_1.openErrorToastWithMessage("Az események betöltése közben hiba történt.");
                        this.setState({ isError: true });
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    ShopPaymentEvents.prototype.componentDidMount = function () {
        this.fetchPaymentEventsByShopId();
    };
    ShopPaymentEvents.prototype.render = function () {
        if (this.state.isLoading) {
            return React.createElement(semantic_ui_react_1.Loader, { active: true, inline: true, size: 'big' }, "Bankk\u00E1rty\u00E1s fizet\u00E9s esem\u00E9nyek bet\u00F6lt\u00E9se");
        }
        if (this.state.isError) {
            return React.createElement("p", null, "Az esem\u00E9nyek bet\u00F6lt\u00E9se k\u00F6zben hiba t\u00F6rt\u00E9nt.");
        }
        if (this.state.isSuccess) {
            if (this.state.events.length > 0) {
                return (React.createElement(semantic_ui_react_1.List, { divided: true, relaxed: true }, this.state.events.map(function (event) {
                    var humanReadableEvent = transformRawEventIntoHumanReadableFormat(event);
                    var _id = humanReadableEvent._id, icon = humanReadableEvent.icon, iconColor = humanReadableEvent.iconColor, message = humanReadableEvent.message, createdAt = humanReadableEvent.createdAt;
                    return (React.createElement(semantic_ui_react_1.ListItem, { key: _id },
                        React.createElement(semantic_ui_react_1.ListIcon, { name: icon, size: "large", verticalAlign: "middle", color: iconColor }),
                        React.createElement(semantic_ui_react_1.ListContent, null,
                            React.createElement(semantic_ui_react_1.ListHeader, null, message),
                            React.createElement(semantic_ui_react_1.ListDescription, null, moment(createdAt).format('YYYY.MM.DD. HH:mm:ss')))));
                })));
            }
            return React.createElement("p", null, "Nincsenek fizet\u00E9shez kapcsol\u00F3d\u00F3 esem\u00E9nyek.");
        }
        return React.createElement("p", null, "Nem tudtuk bet\u00F6lteni az adatokat: ismeretlen hiba t\u00F6rt\u00E9nt.");
    };
    return ShopPaymentEvents;
}(abstract_component_1.AbstractComponent));
exports.ShopPaymentEvents = ShopPaymentEvents;
