module.exports = {
    'email-editor.heading-blocks': 'Címsorok',
    'email-editor.text-blocks': 'Szövegdobozok',
    'email-editor.button-blocks': 'Gombok',
    'email-editor.header-blocks': 'Fejléc blokkok',
    'email-editor.footer-blocks': 'Lábléc blokkok',
    'email-editor.misc-blocks': 'Egyéb blokkok',
    'email-editor.block-category.cart': 'Kosár termékek komponensek',
    'email-editor.block-category.visited': 'Megtekintett termékek komponensek',
    'email-editor.block-category.ordered': 'Megrendelt termékek komponensek',
    'email-editor.block-category.sale': 'Akciós termékek komponensek',
    'email-editor.block-category.news': 'Újdonságok komponensek',
    'email-editor.block-category.general': 'Általános komponensek',
    'email-editor.block-category.category-pro': 'Kategória ajánló PRO komponensek',
    'email-editor.Variable_Heading_1': 'Címsor1 szövege:',
    'email-editor.Variable_Heading_2': 'Címsor2 szövege:',
    'email-editor.Variable_Heading_3': 'Címsor3 szövege:',
    'email-editor.Variable_Heading_4': 'Címsor4 szövege:',
    'email-editor.Variable_Heading_5': 'Címsor5 szövege:',
    'email-editor.Variable_Text_1': 'Szövegdoboz1 tartalma:',
    'email-editor.Variable_Text_2': 'Szövegdoboz2 tartalma:',
    'email-editor.Variable_Text_3': 'Szövegdoboz3 tartalma:',
    'email-editor.Variable_Text_4': 'Szövegdoboz4 tartalma:',
    'email-editor.Variable_Text_5': 'Szövegdoboz5 tartalma:',
    'email-editor.Variable_Button_1_Link': 'Gomb1 Link:',
    'email-editor.Variable_Button_2_Link': 'Gomb2 Link:',
    'email-editor.Variable_Button_3_Link': 'Gomb3 Link:',
    'email-editor.Variable_Button_4_Link': 'Gomb4 Link:',
    'email-editor.Variable_Button_5_Link': 'Gomb5 Link:',
    'email-editor.Variable_Button_1_Title': 'Gomb1 felirat:',
    'email-editor.Variable_Button_2_Title': 'Gomb2 felirat:',
    'email-editor.Variable_Button_3_Title': 'Gomb3 felirat:',
    'email-editor.Variable_Button_4_Title': 'Gomb4 felirat:',
    'email-editor.Variable_Button_5_Title': 'Gomb5 felirat:',
    'email-editor.Variable_ProductButtonTitle': 'Termék gomb felirat:',
    'email-editor.Variable_CategoryProSource_Heading_1': 'Kategória ajánló PRO - Címsor felirat 1',
    'email-editor.Variable_CategoryProSource_Text_1': 'Kategória ajánló PRO - Felirat 1',
    'email-editor.Variable_Header_Title': 'Fejléc szöveg',
    'email-editor.Variable_FooterTitleLeft': 'Lábléc - Bal szöveg',
    'email-editor.Variable_FooterTitleRight': 'Lábléc - Jobb szöveg',
    'email-editor.Variable_FooterDescription': 'Lábléc - Részletes leírás',
};
