"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShoprenterShopProfileComponent = void 0;
var React = require("react");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var lodash_1 = require("lodash");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var state_repository_1 = require("../../../common/libs/state-repository");
var color_picker_1 = require("../../../common/semantic/color-picker/color-picker");
var image_uploader_1 = require("../../../common/semantic/image-uploader/image-uploader");
var toast_util_1 = require("../../errors/toast.util");
var errors_constant_1 = require("../../errors/errors.constant");
var ShoprenterShopProfileComponent = (function (_super) {
    __extends(ShoprenterShopProfileComponent, _super);
    function ShoprenterShopProfileComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shopRepository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            shop: session_storage_1.SessionStorage.getItem('SelectedShop'),
            updateLoading: false,
            updateError: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                themeColor: new field_1.Field({
                    name: 'themeColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.themeColor || '',
                    validators: []
                }),
                buttonTextColor: new field_1.Field({
                    name: 'buttonTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.buttonTextColor || '#FFFFFF',
                    validators: []
                }),
                buttonBackgroundColor: new field_1.Field({
                    name: 'buttonBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.buttonBackgroundColor || '#F43F50',
                    validators: []
                }),
                headingTextColor: new field_1.Field({
                    name: 'headingTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headingTextColor || '#FFFFFF',
                    validators: []
                }),
                headingBackgroundColor: new field_1.Field({
                    name: 'headingBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headingBackgroundColor || '#162542',
                    validators: []
                }),
                textTextColor: new field_1.Field({
                    name: 'textTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.textTextColor || '#000000',
                    validators: []
                }),
                headerBackgroundColor: new field_1.Field({
                    name: 'headerBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headerBackgroundColor || '#FFFFFF',
                    validators: []
                }),
                headerTextColor: new field_1.Field({
                    name: 'headerTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headerTextColor || '#000000',
                    validators: []
                }),
                footerBackgroundColor: new field_1.Field({
                    name: 'footerBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.footerBackgroundColor || '#FFFFFF',
                    validators: []
                }),
                logo: new field_1.Field({
                    name: 'logo',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.logo || '',
                    validators: []
                }),
                bannerImage: new field_1.Field({
                    name: 'bannerImage',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.bannerImage || '',
                    validators: []
                }),
                bannerLink: new field_1.Field({
                    name: 'bannerLink',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.bannerLink || '',
                    validators: []
                }),
            }
        });
        return _this;
    }
    ShoprenterShopProfileComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.handleSubmit(); }));
        this.form.fields.headerTextColor.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.headerBackgroundColor.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.headingBackgroundColor.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.headingTextColor.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.textTextColor.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.buttonBackgroundColor.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.buttonTextColor.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.bannerLink.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.logo.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
        this.form.fields.bannerImage.$value.subscribe(function () { return _this.setState(__assign({}, _this.state)); });
    };
    ShoprenterShopProfileComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        if (!!lodash_1.isEqual(prevState.shop, shop)) return [3, 2];
                        return [4, this.setState({ shop: shop })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ShoprenterShopProfileComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'ShopProfileComponent' },
            React.createElement(semantic_ui_react_1.Message, { info: true, style: { backgroundColor: '#EDF4FF', boxShadow: 'none', color: "#051D40" } },
                React.createElement("p", null,
                    React.createElement("strong", null, "Most hozzuk l\u00E9tre a megjelen\u00E9s\u00E9t az e-maileknek! Ez igaz\u00E1n l\u00E1tv\u00E1nyos r\u00E9sz!")),
                React.createElement("p", null, "Itt az ideje, hogy elk\u00E9sz\u00EDts\u00FCk az e-mail sablonok sz\u00EDnvil\u00E1g\u00E1t!"),
                React.createElement("p", null,
                    React.createElement("strong", null, "\uD83D\uDCA1A minta h\u00EDrlev\u00E9l elk\u00E9sz\u00EDt\u00E9s\u00E9hez adok neked p\u00E1r hasznos tippet, amire \u00E9rdemes odafigyelni a sablon kialak\u00EDt\u00E1s\u00E1hoz:")),
                React.createElement("p", null, "1. Haszn\u00E1lj olyan sz\u00EDnk\u00F3dokat, amit az arculatodban \u00E9s a webshopodban is m\u00E1r megjelen\u00EDtesz, ez seg\u00EDt abban, hogy egys\u00E9ges arculatod legyen! Ez n\u00F6veli a bizalmat a vev\u0151idben!"),
                React.createElement("p", null, "2. T\u00F6ltsd fel a webshop logo-t, ami legyen j\u00F3 min\u0151s\u00E9g\u0171! Egy j\u00F3 felbont\u00E1s\u00FA log\u00F3 elengedhetetlen ahhoz, j\u00F3 megjelen\u00E9se legyen a webshopodnak!"),
                React.createElement("p", null, "3. A logo felt\u00F6lt\u00E9sekor els\u0151sorban .png form\u00E1tumot haszn\u00E1lj, ezt minden levelez\u0151 j\u00F3l fogja kezelni!"),
                React.createElement("p", null, "A jobb oldalra gener\u00E1ltam neked egy minta e-mailt, felt\u00F6lt\u00F6ttem minta sz\u00F6vegekkel, hogy l\u00E1sd milyen lesz az ellen\u0151rz\u00E9se a h\u00EDrleveleidnek."),
                React.createElement("p", null,
                    "Ha mindennel meg vagy, kattints alul a ",
                    React.createElement("strong", null, "\u201CMent\u00E9s\u201D"),
                    " gombra \u00E9s ugorhatunk az utols\u00F3 l\u00E9p\u00E9shez, de ne agg\u00F3dj, ha k\u00E9s\u0151bb szeretn\u00E9l valamin v\u00E1ltoztatni, b\u00E1rmikor megteheted!")),
            React.createElement(semantic_ui_react_1.Grid, { as: semantic_ui_react_1.Form, className: 'pl-4', onSubmit: function () { return _this.form.submit(); } },
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h5' }, trans_1.trans('settings.profile.shop-profile.data.subtitle'))),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h5', style: { textAlign: 'center' } }, "E-mail sablon el\u0151n\u00E9zet"))),
                React.createElement(semantic_ui_react_1.Divider, { className: 'my-0' }),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(semantic_ui_react_1.Grid, null,
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "Web\u00E1ruh\u00E1z log\u00F3"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.logo, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: image_uploader_1.ImageUploader.withProps({
                                                buttonTitle: 'Webáruház logó feltöltése (.png, .jpg)',
                                                maxWidth: 220,
                                                maxHeight: 90
                                            }), fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "Gomb sz\u00F6vegsz\u00EDne"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "Gomb h\u00E1tt\u00E9rsz\u00EDne"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonBackgroundColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "C\u00EDmsor sz\u00F6vegsz\u00EDne"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.headingTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "C\u00EDmsor h\u00E1tt\u00E9rsz\u00EDne"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.headingBackgroundColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "Sz\u00F6vegsz\u00EDn"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.textTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "Fejl\u00E9c h\u00E1tt\u00E9rsz\u00EDne"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.headerBackgroundColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }, "Fejl\u00E9c sz\u00F6vegsz\u00EDne"),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12 },
                                    React.createElement(form_control_1.FormControl, { field: this.form.fields.headerTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                        React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                            React.createElement(semantic_ui_react_1.Grid.Row, null,
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                                    React.createElement(detailed_error_1.DetailedError, { error: this.state.updateError }))),
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 }),
                                React.createElement(semantic_ui_react_1.Grid.Column, { width: 12, textAlign: 'right' },
                                    React.createElement(semantic_ui_react_1.Button, { loading: this.state.updateLoading, disabled: this.state.updateLoading, content: 'Mentés', type: "submit" }))))),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement("html", { style: { maxWidth: '610px', maxHeight: '730px' } },
                            React.createElement("body", null,
                                React.createElement("div", { id: "_______root" },
                                    React.createElement("meta", { "http-equiv": "Content-Type", content: "text/html,  charset=utf-8" }),
                                    React.createElement("meta", { "http-equiv": "X-UA-Compatible", content: "IE=edge" }),
                                    React.createElement("meta", { name: "x-apple-disable-message-reformatting" }),
                                    React.createElement("meta", { name: "format-detection", content: "telephone=no" }),
                                    React.createElement("link", { href: "https://fonts.googleapis.com/css?family=Nunito%20Sans", rel: "stylesheet", type: "text/css" }),
                                    React.createElement("style", null, "\nhtml,body,table,tbody,tr,td,div,p,ul,ol,li,h1,h2,h3,h4,h5,h6 {\nmargin: 0;\npadding: 0; \n}\n\nbody {\n-ms-text-size-adjust: 100%;\n-webkit-text-size-adjust: 100%;\n}\n\ntable {\nborder-spacing: 0;\nmso-table-lspace: 0pt;\nmso-table-rspace: 0pt; \n}\n\ntable td {\nborder-collapse: collapse;\n}\n\nh1,h2,h3,h4,h5,h6 {\nfont-family: Arial;\n}\n\n.ExternalClass {\nwidth: 100%;\n}\n\n.ExternalClass,\n.ExternalClass p,\n.ExternalClass span,\n.ExternalClass font,\n.ExternalClass td,\n.ExternalClass div {\nline-height: 100%;\n}\n\n/* Outermost container in Outlook.com */\n.ReadMsgBody {\nwidth: 100%;\n}\n\nimg {\n-ms-interpolation-mode: bicubic;\n}\n"),
                                    React.createElement("style", null, "\na[x-apple-data-detectors=true]{\ncolor: inherit !important;\ntext-decoration: inherit !important;\n}\n\nu + #body a {\ncolor: inherit;\ntext-decoration: inherit !important;\nfont-size: inherit;\nfont-family: inherit;\nfont-weight: inherit; \nline-height: inherit; \n}\n\na, a:link, .no-detect-local a, .appleLinks a {\ncolor: inherit !important;\ntext-decoration: inherit;\n}\n"),
                                    React.createElement("style", null, "\n                                            .width600 {\n                                                width: 600px;\n                                                max-width: 100%;\n                                            }\n\n                                            @media all and (max-width: 599px) {\n                                                .width600 {\n                                                    width: 100% !important;\n                                                }\n                                            }\n\n@media screen and (min-width: 600px) {\n.hide-on-desktop {\ndisplay: none !important;\n}\n}\n\n@media all and (max-width: 599px),\nonly screen and (max-device-width: 599px) {\n.main-container {\nwidth: 100% !important;\n}\n\n.col {\nwidth: 100%;\n}\n\n.fluid-on-mobile {\nwidth: 100% !important;\nheight: auto !important;\ntext-align:center;\n}\n\n.fluid-on-mobile img {\nwidth: 100% !important;\n}\n\n.hide-on-mobile {\ndisplay:none !important;\nwidth:0px !important;\nheight:0px !important;\noverflow:hidden;\n}\n}\n"),
                                    React.createElement("style", null, "\n    a.link-fix { color: #00c0e7 !important; text-decoration: underline !important; }</style><style>b.bold-fix { font-weight: bold !important; }\n    "),
                                    React.createElement("style", null, "\n@media screen and (min-width: 600px) {\n.hide-on-desktop {\ndisplay: none;\n}\n}\n@media all and (max-width: 599px) {\n.hide-on-mobile {\ndisplay:none !important;\nwidth:0px !important;\nheight:0px !important;\noverflow:hidden;\n}\n.main-container {\nwidth: 100% !important;\n}\n.col {\nwidth: 100%;\n}\n.fluid-on-mobile {\nwidth: 100% !important;\nheight: auto !important;\ntext-align:center;\n}\n.fluid-on-mobile img {\nwidth: 100% !important;\n}\n}\n"),
                                    React.createElement("div", { style: { backgroundColor: '#ffffff' } },
                                        React.createElement("table", { width: "100%", style: { borderSpacing: 0, borderWidth: 0, height: '100%' } },
                                            React.createElement("tbody", null,
                                                React.createElement("tr", null,
                                                    React.createElement("td", { valign: "top" },
                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                            React.createElement("tbody", null,
                                                                React.createElement("tr", null,
                                                                    React.createElement("td", { width: "100%" },
                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                            React.createElement("tbody", null,
                                                                                React.createElement("tr", null,
                                                                                    React.createElement("td", { align: "center", width: "100%" },
                                                                                        React.createElement("table", { className: "width600 main-container", width: "100%", style: { maxWidth: '600px', borderSpacing: 0, borderWidth: 0 } },
                                                                                            React.createElement("tbody", null,
                                                                                                React.createElement("tr", null,
                                                                                                    React.createElement("td", { width: "100%" },
                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                            React.createElement("tbody", null,
                                                                                                                React.createElement("tr", null,
                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '32px' } },
                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                            React.createElement("tbody", null,
                                                                                                                                React.createElement("tr", null,
                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                        React.createElement("table", { width: "100%", style: { backgroundColor: this.form.fields.headerBackgroundColor.value, borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingBottom: '8px' } },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%", className: "mcol" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: 0, display: 'flex', flexDirection: 'column', alignContent: 'center', flexWrap: 'wrap' } },
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "48.666666666666664%", className: "col" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                        React.createElement("table", { style: { maxWidth: '100%', borderSpacing: 0, borderWidth: 0 }, className: "img-wrap" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center", style: { paddingTop: '8px' } },
                                                                                                                                                                                                                        React.createElement("img", { src: this.form.fields.logo.value, width: 180, alt: "", style: { borderWidth: 0, display: 'block', fontSize: '14px', maxWidth: '100%', height: 'auto' }, className: "width180" }))))))))))))),
                                                                                                                                                                        React.createElement("table", { width: "2.6666666666666665%", className: "col hide-on-mobile", style: { minWidth: '16px', height: '1px', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { lineHeight: '1px', padding: 0, fontSize: '0px' } }, "\u00A0")))),
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "48.666666666666664%", className: "col" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '16px', paddingRight: '8px', paddingBottom: '16px', paddingLeft: '8px' } },
                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: '#000000', lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                                                            React.createElement("h2", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '24px', color: this.form.fields.headerTextColor.value, fontWeight: 'normal', lineHeight: '32px', padding: 0, margin: 0, textAlign: 'center' } },
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                                                    React.createElement("strong", null, "Minta fejl\u00E9c sz\u00F6veg.")))))))))))))))))))))))))))))))))))),
                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                            React.createElement("tbody", null,
                                                                                React.createElement("tr", null,
                                                                                    React.createElement("td", { align: "center", width: "100%" },
                                                                                        React.createElement("table", { className: "width600 main-container", width: "100%", style: { maxWidth: '600px', borderSpacing: 0, borderWidth: 0 } },
                                                                                            React.createElement("tbody", null,
                                                                                                React.createElement("tr", null,
                                                                                                    React.createElement("td", { width: "100%" },
                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                            React.createElement("tbody", null,
                                                                                                                React.createElement("tr", null,
                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                            React.createElement("tbody", null,
                                                                                                                                React.createElement("tr", null,
                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                        React.createElement("table", { width: "100%", style: { backgroundColor: this.form.fields.headingBackgroundColor.value, borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '16px', paddingRight: '10px', paddingBottom: '16px', paddingLeft: '10px' } },
                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '24px', color: this.form.fields.headingTextColor.value, lineHeight: '32px', textAlign: 'left' } },
                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0, textAlign: 'center' } },
                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                    React.createElement("strong", null,
                                                                                                                                                                                        React.createElement("span", { style: { fontSize: '24px' } }, "Minta c\u00EDmsor sz\u00F6veg, amit szabadon fogsz tudni szerkeszteni."))))))))))))))))))))))))))))),
                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                            React.createElement("tbody", null,
                                                                                React.createElement("tr", null,
                                                                                    React.createElement("td", { align: "center", width: "100%" },
                                                                                        React.createElement("table", { className: "width600 main-container", width: "100%", style: { maxWidth: '600px', borderSpacing: 0, borderWidth: 0 } },
                                                                                            React.createElement("tbody", null,
                                                                                                React.createElement("tr", null,
                                                                                                    React.createElement("td", { width: "100%" },
                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                            React.createElement("tbody", null,
                                                                                                                React.createElement("tr", null,
                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                        React.createElement("table", { width: "100%", style: { backgroundColor: '#FFFFFF', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                            React.createElement("tbody", null,
                                                                                                                                React.createElement("tr", null,
                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingBottom: '8px' } },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: this.form.fields.textTextColor.value, lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0 } },
                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" })),
                                                                                                                                                                            React.createElement("p", { style: { textAlign: 'justify' } }, "Ez is egy minta sz\u00F6veg. Ide fog majd ker\u00FClni olyan sz\u00F6veg, amit vagy \u00E9n (M.I.) gener\u00E1lok, de ha akarod, akkor te is meg\u00EDrhatod. Ez most csak egy minta az\u00E9rt, hogy l\u00E1sd, hogyan fog kin\u00E9zni egy email \u00E9s a sz\u00EDneket be tudd \u00E1ll\u00EDtani."),
                                                                                                                                                                            React.createElement("p", null)))))))))))))))))))))))))),
                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                            React.createElement("tbody", null,
                                                                                React.createElement("tr", null,
                                                                                    React.createElement("td", { align: "center", width: "100%" },
                                                                                        React.createElement("table", { className: "width600 main-container", width: "100%", style: { maxWidth: '600px', borderSpacing: 0, borderWidth: 0 } },
                                                                                            React.createElement("tbody", null,
                                                                                                React.createElement("tr", null,
                                                                                                    React.createElement("td", { width: "100%" },
                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                            React.createElement("tbody", null,
                                                                                                                React.createElement("tr", null,
                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                        React.createElement("table", { width: "100%", className: "mcol", style: { backgroundColor: '#ffffff', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                            React.createElement("tbody", null,
                                                                                                                                React.createElement("tr", null,
                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: 0, display: 'flex' } },
                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "50%", className: "col" },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingRight: '4px', paddingBottom: '8px', paddingLeft: '8px' } },
                                                                                                                                                                                        React.createElement("table", { width: "100%", style: { border: '1px solid #e0e0e0', borderRadius: '4px', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: '16px' } },
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("table", { style: { maxWidth: '100%', borderSpacing: 0, borderWidth: 0 }, className: "img-wrap" },
                                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", className: "imglink link-fix", target: "_blank" },
                                                                                                                                                                                                                                                                            React.createElement("img", { src: "https://greenformatics3.cdn.shoprenter.hu/custom/greenformatics3/image/cache/w900h900wt1q100/product/Ferfi_bukosisak_terep.jpg", width: 200, alt: "", style: { borderWidth: 0, display: 'block', fontSize: '14px', maxWidth: '100%', height: 'auto' }, className: "width200" })))))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '16px', paddingBottom: '8px' } },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: '#131313', lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0, textAlign: 'center' } },
                                                                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" }, "F\u00E9rfi buk\u00F3sisak - terep"))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { display: 'inline-block', textDecoration: 'none' }, className: "fluid-on-mobile link-fix" },
                                                                                                                                                                                                                                                            React.createElement("span", null,
                                                                                                                                                                                                                                                                React.createElement("table", { style: { borderRadius: '5px', borderSpacing: 0, borderWidth: 0, backgroundColor: this.form.fields.buttonBackgroundColor.value }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                    React.createElement("tbody", null,
                                                                                                                                                                                                                                                                        React.createElement("tr", null,
                                                                                                                                                                                                                                                                            React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))),
                                                                                                                                                                                                                                                        React.createElement("div", { style: { display: 'none' } },
                                                                                                                                                                                                                                                            React.createElement("table", { style: { borderRadius: '5px', borderSpacing: 0, borderWidth: 0, backgroundColor: this.form.fields.buttonBackgroundColor.value }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                React.createElement("tbody", null,
                                                                                                                                                                                                                                                                    React.createElement("tr", null,
                                                                                                                                                                                                                                                                        React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                            React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { color: '#FFFFFF !important', fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal', textDecoration: 'none', textAlign: 'center' }, className: "link-fix" },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))))))))))))))))))))))))))))))),
                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "50%", className: "col" },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingRight: '4px', paddingBottom: '8px', paddingLeft: '8px' } },
                                                                                                                                                                                        React.createElement("table", { width: "100%", style: { border: '1px solid #e0e0e0', borderRadius: '4px', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: '16px' } },
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("table", { style: { maxWidth: '100%', borderSpacing: 0, borderWidth: 0 }, className: "img-wrap" },
                                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", className: "imglink link-fix", target: "_blank" },
                                                                                                                                                                                                                                                                            React.createElement("img", { src: "https://greenformatics3.cdn.shoprenter.hu/custom/greenformatics3/image/cache/w900h900wt1q100/product/MTB_kerekparos_cipo_narancs_fekete.jpg", width: 200, alt: "", style: { borderWidth: 0, display: 'block', fontSize: '14px', maxWidth: '100%', height: 'auto' }, className: "width200" })))))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '16px', paddingBottom: '8px' } },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: '#131313', lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0, textAlign: 'center' } },
                                                                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" }, "MTB ker\u00E9kp\u00E1ros cip\u0151 - narancs-fekete"))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { display: 'inline-block', textDecoration: 'none' }, className: "fluid-on-mobile link-fix" },
                                                                                                                                                                                                                                                            React.createElement("span", null,
                                                                                                                                                                                                                                                                React.createElement("table", { style: { borderRadius: '5px', borderSpacing: 0, borderWidth: 0, backgroundColor: this.form.fields.buttonBackgroundColor.value }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                    React.createElement("tbody", null,
                                                                                                                                                                                                                                                                        React.createElement("tr", null,
                                                                                                                                                                                                                                                                            React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))),
                                                                                                                                                                                                                                                        React.createElement("div", { style: { display: 'none' } },
                                                                                                                                                                                                                                                            React.createElement("table", { style: { borderRadius: '5px', borderSpacing: 0, borderWidth: 0, backgroundColor: this.form.fields.buttonBackgroundColor.value }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                React.createElement("tbody", null,
                                                                                                                                                                                                                                                                    React.createElement("tr", null,
                                                                                                                                                                                                                                                                        React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                            React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { color: '#FFFFFF !important', fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal', textDecoration: 'none', textAlign: 'center' }, className: "link-fix" },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))))))))))))))))))))))))))))))))))),
                                                                                                                        React.createElement("table", { width: "100%", className: "mcol", style: { backgroundColor: '#ffffff', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                            React.createElement("tbody", null,
                                                                                                                                React.createElement("tr", null,
                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: 0, display: 'flex' } },
                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "50%", className: "col" },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingRight: '4px', paddingBottom: '8px', paddingLeft: '8px' } },
                                                                                                                                                                                        React.createElement("table", { width: "100%", style: { border: '1px solid #e0e0e0', borderRadius: '4px', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: '16px' } },
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("table", { style: { maxWidth: '100%', borderSpacing: 0, borderWidth: 0 }, className: "img-wrap" },
                                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", className: "imglink link-fix", target: "_blank" },
                                                                                                                                                                                                                                                                            React.createElement("img", { src: "https://greenformatics3.cdn.shoprenter.hu/custom/greenformatics3/image/cache/w900h900wt1q100/product/Sport_orszaguti_nyereg_fekete.jpg", width: 200, alt: "", style: { borderWidth: 0, display: 'block', fontSize: '14px', maxWidth: '100%', height: 'auto' }, className: "width200" })))))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '16px', paddingBottom: '8px' } },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: '#131313', lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0, textAlign: 'center' } },
                                                                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" }, "Sport orsz\u00E1g\u00FAti nyereg - fekete"))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { display: 'inline-block', textDecoration: 'none' }, className: "fluid-on-mobile link-fix" },
                                                                                                                                                                                                                                                            React.createElement("span", null,
                                                                                                                                                                                                                                                                React.createElement("table", { style: { borderRadius: '5px', borderSpacing: 0, borderWidth: 0, backgroundColor: this.form.fields.buttonBackgroundColor.value }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                    React.createElement("tbody", null,
                                                                                                                                                                                                                                                                        React.createElement("tr", null,
                                                                                                                                                                                                                                                                            React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))),
                                                                                                                                                                                                                                                        React.createElement("div", { style: { display: 'none' } },
                                                                                                                                                                                                                                                            React.createElement("table", { style: { borderRadius: '5px', borderSpacing: 0, borderWidth: 0, backgroundColor: this.form.fields.buttonBackgroundColor.value }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                React.createElement("tbody", null,
                                                                                                                                                                                                                                                                    React.createElement("tr", null,
                                                                                                                                                                                                                                                                        React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                            React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { color: '#FFFFFF !important', fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal', textDecoration: 'none', textAlign: 'center' }, className: "link-fix" },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))))))))))))))))))))))))))))))),
                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "50%", className: "col" },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingRight: '4px', paddingBottom: '8px', paddingLeft: '8px' } },
                                                                                                                                                                                        React.createElement("table", { width: "100%", style: { border: '1px solid #e0e0e0', borderRadius: '4px', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: '16px' } },
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("table", { style: { maxWidth: '100%', borderSpacing: 0, borderWidth: 0 }, className: "img-wrap" },
                                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", className: "imglink link-fix", target: "_blank" },
                                                                                                                                                                                                                                                                            React.createElement("img", { src: "https://greenformatics3.cdn.shoprenter.hu/custom/greenformatics3/image/cache/w900h900wt1q100/product/Amsterdam_2020_varosi_kerekpar_kek.jpg", width: 200, alt: "", style: { display: 'block', fontSize: '14px', maxWidth: '100%', height: 'auto', borderWidth: 0 }, className: "width200" })))))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '16px', paddingBottom: '8px' } },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: '#131313', lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0, textAlign: 'center' } },
                                                                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" }, "Amsterdam 2020 v\u00E1rosi ker\u00E9kp\u00E1r - k\u00E9k"))))))))))))))),
                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { display: 'inline-block', textDecoration: 'none' }, className: "fluid-on-mobile link-fix" },
                                                                                                                                                                                                                                                            React.createElement("span", null,
                                                                                                                                                                                                                                                                React.createElement("table", { style: { borderRadius: '5px', backgroundColor: this.form.fields.buttonBackgroundColor.value, borderSpacing: 0, borderWidth: 0 }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                    React.createElement("tbody", null,
                                                                                                                                                                                                                                                                        React.createElement("tr", null,
                                                                                                                                                                                                                                                                            React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))),
                                                                                                                                                                                                                                                        React.createElement("div", { style: { display: 'none' } },
                                                                                                                                                                                                                                                            React.createElement("table", { style: { borderRadius: '5px', backgroundColor: this.form.fields.buttonBackgroundColor.value, borderSpacing: 0, borderWidth: 0 }, className: "fluid-on-mobile" },
                                                                                                                                                                                                                                                                React.createElement("tbody", null,
                                                                                                                                                                                                                                                                    React.createElement("tr", null,
                                                                                                                                                                                                                                                                        React.createElement("td", { align: "center", style: { paddingTop: '8px', paddingRight: '16px', paddingBottom: '8px', paddingLeft: '16px' } },
                                                                                                                                                                                                                                                                            React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { color: '#FFFFFF !important', fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal', textDecoration: 'none', textAlign: 'center' }, className: "link-fix" },
                                                                                                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb")))))))))))))))))))))))))))))))))))))))))))))))))))))),
                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                            React.createElement("tbody", null,
                                                                                React.createElement("tr", null,
                                                                                    React.createElement("td", { align: "center", width: "100%" },
                                                                                        React.createElement("table", { className: "width600 main-container", width: "100%", style: { maxWidth: '600px', borderSpacing: 0, borderWidth: 0 } },
                                                                                            React.createElement("tbody", null,
                                                                                                React.createElement("tr", null,
                                                                                                    React.createElement("td", { width: "100%" },
                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                            React.createElement("tbody", null,
                                                                                                                React.createElement("tr", null,
                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                        React.createElement("table", { width: "100%", style: { backgroundColor: '#ffffff', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                            React.createElement("tbody", null,
                                                                                                                                React.createElement("tr", null,
                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingBottom: '8px' } },
                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingRight: '8px', paddingLeft: '8px' } },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top", align: "center" },
                                                                                                                                                                        React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { display: 'inline-block', textDecoration: 'none' }, className: "fluid-on-mobile link-fix" },
                                                                                                                                                                            React.createElement("span", null,
                                                                                                                                                                                React.createElement("table", { style: { borderRadius: '5px', backgroundColor: this.form.fields.buttonBackgroundColor.value, borderSpacing: 0, borderWidth: 0 }, className: "fluid-on-mobile" },
                                                                                                                                                                                    React.createElement("tbody", null,
                                                                                                                                                                                        React.createElement("tr", null,
                                                                                                                                                                                            React.createElement("td", { align: "center", style: { paddingTop: '12px', paddingRight: '16px', paddingBottom: '12px', paddingLeft: '16px' } },
                                                                                                                                                                                                React.createElement("span", { style: { color: this.form.fields.buttonTextColor.value, fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', lineHeight: '24px', letterSpacing: 'normal' } },
                                                                                                                                                                                                    React.createElement("div", { style: { color: this.form.fields.buttonTextColor.value }, className: "button" },
                                                                                                                                                                                                        React.createElement("span", null,
                                                                                                                                                                                                            React.createElement("strong", null, "Tov\u00E1bb a webshopra!")))))))))))))))))))))))))))))))))),
                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                            React.createElement("tbody", null,
                                                                                React.createElement("tr", null,
                                                                                    React.createElement("td", { align: "center", width: "100%" },
                                                                                        React.createElement("table", { className: "width600 main-container", width: "100%", style: { maxWidth: '600px', borderSpacing: 0, borderWidth: 0 } },
                                                                                            React.createElement("tbody", null,
                                                                                                React.createElement("tr", null,
                                                                                                    React.createElement("td", { width: "100%" },
                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                            React.createElement("tbody", null,
                                                                                                                React.createElement("tr", null,
                                                                                                                    React.createElement("td", { valign: "top", style: { paddingBottom: '32px' } },
                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                            React.createElement("tbody", null,
                                                                                                                                React.createElement("tr", null,
                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                        React.createElement("table", { width: "100%", style: { backgroundColor: '#ffffff', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%", className: "mcol" },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top", style: { padding: 0, display: 'flex' } },
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "50%", className: "col" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '18px', color: '#000000', lineHeight: '25px', textAlign: 'left' } },
                                                                                                                                                                                                            React.createElement("h3", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: '#000000', fontWeight: 'normal', lineHeight: '24px', padding: 0, margin: 0 } },
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                                                    React.createElement("strong", null, "Kapcsolat")))))))),
                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top" },
                                                                                                                                                                                                        React.createElement("table", { style: { maxWidth: '100%', borderSpacing: 0, borderWidth: 0 }, className: "img-wrap" },
                                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingRight: '8px', paddingLeft: '8px' } },
                                                                                                                                                                                                                        React.createElement("img", { src: this.form.fields.logo.value, width: 112, alt: "", style: { borderWidth: 0, display: 'block', fontSize: '14px', maxWidth: '100%', height: 'auto' }, className: "width112" }))))))))),
                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '14px', color: '#000000', lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0 } },
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                                                    React.createElement("strong", null, "E-mail:")),
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" }, " minta@e-mail.hu")),
                                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0 } },
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                                                    React.createElement("strong", null, "Telefonsz\u00E1m:")),
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" }, " +36-12-345-6789"))))))))))),
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "50%", className: "col" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", width: "100%", style: { padding: 0 } },
                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '18px', color: '#000000', lineHeight: '25px', textAlign: 'left' } },
                                                                                                                                                                                                            React.createElement("h3", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '16px', color: '#000000', fontWeight: 'normal', lineHeight: '24px', padding: 0, margin: 0 } },
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                                                    React.createElement("strong", null, "Jogi nyilatkozat")))))))),
                                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '14px', color: '#000000', lineHeight: '16px', textAlign: 'left' } },
                                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0 } },
                                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" })),
                                                                                                                                                                                                            React.createElement("p", { style: { textAlign: 'justify' } }, "Ezt a levelet az\u00E9rt kaptad, mert feliratkozt\u00E1l a h\u00EDrlevel\u00FCnkre. Ha nem szeretn\u00E9l t\u00F6bb levelet kapni, csak kattints a leiratkoz\u00E1s gombra. Ha \u00FAgy v\u00E9led, hogy k\u00E9retlen levelet kapt\u00E1l, k\u00E9rj\u00FCk jelezd ezt fel\u00E9nk, hogy kivizsg\u00E1lhassuk."),
                                                                                                                                                                                                            React.createElement("p", null)))))))))))))),
                                                                                                                                                        React.createElement("table", { width: "100%", style: { backgroundColor: 'white', borderSpacing: 0, borderWidth: 0 } },
                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '8px', paddingBottom: '8px', paddingLeft: '8px' } },
                                                                                                                                                                        React.createElement("table", { style: { borderSpacing: 0, borderWidth: 0 }, width: "100%" },
                                                                                                                                                                            React.createElement("tbody", null,
                                                                                                                                                                                React.createElement("tr", null,
                                                                                                                                                                                    React.createElement("td", { valign: "top", style: { paddingTop: '5px', paddingRight: '10px', paddingBottom: '5px', paddingLeft: '10px' } },
                                                                                                                                                                                        React.createElement("div", { style: { fontFamily: 'Nunito Sans, Arial, Helvetica Neue, Helvetica, sans-serif', fontSize: '14px', color: '#131313', lineHeight: '24px', textAlign: 'left' } },
                                                                                                                                                                                            React.createElement("p", { style: { padding: 0, margin: 0, textAlign: 'center', display: 'flex', justifyContent: 'center' } },
                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                                    React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { color: '#00c0e7 !important', textDecoration: 'underline !important' }, className: "link-fix" },
                                                                                                                                                                                                        React.createElement("div", { style: { color: '#00c0e7' } }, "Web\u00E1ruh\u00E1z neve"))),
                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" }, " - Minden jog fenntartva - "),
                                                                                                                                                                                                React.createElement("span", { className: "mso-font-fix-arial" },
                                                                                                                                                                                                    React.createElement("a", { href: "javascript:void(0)", target: "_blank", style: { color: '#00c0e7 !important', textDecoration: 'underline !important' }, className: "link-fix" },
                                                                                                                                                                                                        React.createElement("div", { style: { color: '#00c0e7' } },
                                                                                                                                                                                                            React.createElement("u", null, "Leiratkoz\u00E1s")))))))))))))))))))))))))))))))))))))))))))),
                                React.createElement("script", null,
                                    " ", "\n    window.addEventListener(\"message\", function (event) {\n        switch (event.data.type) {\n            case 'init':\n                document.getElementById('_______root').innerHTML = event.data.payload, \n                break, \n            case 'zoom':\n                // document.body.style.zoom = event.data.payload, \n                break, \n            default:\n                //\n        }\n    }, false), \n\n    function disableLink() {\n        var links = document.getElementsByTagName('a'), \n\n        for (let i = 0,  i < links.length,  i++) {\n            var item = links.item(i), \n\n            item.href = \"javascript:void(0)\", \n        }\n    }\n\n    disableLink(), \n\n    setInterval(function () {\n        disableLink(), \n    }, 300), \n")))))));
    };
    ShoprenterShopProfileComponent.prototype.getPayload = function () {
        var data = this.form.toJSON();
        return __assign(__assign({}, this.state.shop), data);
    };
    ShoprenterShopProfileComponent.prototype.handleSubmit = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var payload, result, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        this.setState({ updateLoading: true, updateError: false });
                        payload = this.getPayload();
                        return [4, this.shopRepository.put("/s/" + ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/shop", { data: payload })];
                    case 1:
                        result = _d.sent();
                        this.setState({ updateLoading: false });
                        toast_util_1.openSuccessToast(trans_1.trans('setting.profile.update.success'));
                        session_storage_1.SessionStorage.setItem('SelectedShop', result);
                        console.log('Refetching shop document after successful saving of the shop profile...');
                        (_c = this.props) === null || _c === void 0 ? void 0 : _c.refetch();
                        return [3, 3];
                    case 2:
                        e_1 = _d.sent();
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_CANNOT_UPDATE_WEBSHOP_PROFILE);
                        this.setState({ updateLoading: false, updateError: e_1 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return ShoprenterShopProfileComponent;
}(abstract_component_1.AbstractComponent));
exports.ShoprenterShopProfileComponent = ShoprenterShopProfileComponent;
