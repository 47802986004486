"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersWithDifferentEmailsComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./shop-settings.scss");
var session_storage_1 = require("../../../../libs/session.storage");
var axios_1 = require("axios");
var environment_1 = require("@codebuild/uikit/libs/environment");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var react_datepicker_form_control_type_1 = require("../../../../libs/form-control-types/react-datepicker.form-control-type");
var DatePicker = require("react-datepicker");
var OrdersWithDifferentEmailsComponent = (function (_super) {
    __extends(OrdersWithDifferentEmailsComponent, _super);
    function OrdersWithDifferentEmailsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                from: new field_1.Field({
                    name: 'from',
                    label: '',
                    value: moment().startOf('month').toDate(),
                }),
                to: new field_1.Field({
                    name: 'to',
                    label: '',
                    value: moment().endOf('month').toDate(),
                }),
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                from: _this.form.fields.from.getValue(),
                to: _this.form.fields.to.getValue(),
            }
        });
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: _this.props.shop,
            isLoading: false,
            orders: [],
        };
        return _this;
    }
    OrdersWithDifferentEmailsComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!lodash_1.isEqual(this.props.shop, prevProps.shop)) {
            this.setState({ shop: this.props.shop });
        }
    };
    OrdersWithDifferentEmailsComponent.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.fetchDataFromApi();
                return [2];
            });
        });
    };
    OrdersWithDifferentEmailsComponent.prototype.fetchDataFromApi = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var baseUrl, fromQueryParameter, toQueryParameter, requestOptions, response, error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, 3, 4]);
                        this.setState({ isLoading: true });
                        baseUrl = environment_1.Environment.get('api');
                        fromQueryParameter = moment(this.form.fields.from.value).format('YYYY-MM-DD');
                        toQueryParameter = moment(this.form.fields.to.value).format('YYYY-MM-DD');
                        requestOptions = {
                            url: baseUrl + "/s/" + ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/data/orders-with-different-emails?from=" + fromQueryParameter + "&to=" + toQueryParameter,
                            method: 'get',
                            headers: {
                                Authorization: get_access_token_1.getAccessToken()
                            }
                        };
                        return [4, axios_1.default(requestOptions)];
                    case 1:
                        response = _d.sent();
                        if ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.items) {
                            this.setState({ orders: response.data.items });
                        }
                        return [3, 4];
                    case 2:
                        error_1 = _d.sent();
                        console.error('fetchDataFromApi', error_1);
                        return [3, 4];
                    case 3:
                        this.setState({ isLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    OrdersWithDifferentEmailsComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'ManagersComponent ListComponent' },
            React.createElement(semantic_ui_react_1.Grid, null,
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Rendel\u00E9sek k\u00FCl\u00F6nb\u00F6z\u0151 email c\u00EDmekkel"))),
                React.createElement(semantic_ui_react_1.Grid.Row, { className: 'display-flex' },
                    React.createElement(semantic_ui_react_1.Grid.Column, { floated: 'left', className: 'date-picker-wrapper justify-content-end' },
                        React.createElement("p", { className: 'header-filter-label' }, "D\u00E1tum sz\u0171r\u00E9s"),
                        React.createElement(semantic_ui_react_1.Grid.Row, null,
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Form, { className: 'display-flex' },
                                    React.createElement("div", { className: "date-wrapper" },
                                        React.createElement("div", { className: 'position-relative display-flex' },
                                            React.createElement(form_control_1.FormControl, { field: this.form.fields.from, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                            React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' })),
                                        React.createElement("div", { className: 'date-picker-divider' }),
                                        React.createElement("div", { className: 'position-relative display-flex mr-4' },
                                            React.createElement(form_control_1.FormControl, { field: this.form.fields.to, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                                React.createElement(DatePicker.default, { showTimeSelect: false, dateFormat: 'yyyy.MM.dd.', style: { width: '100% !important' } })),
                                            React.createElement(semantic_ui_react_1.Icon, { name: 'calendar alternate' }))))),
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Button, { isLoading: this.state.isLoading, disabled: this.state.isLoading, size: 'small', color: "blue", basic: true, onClick: function () { return _this.fetchDataFromApi(); }, content: "T\u00E1bl\u00E1zat friss\u00EDt\u00E9se", className: 'display-flex' }))))),
                React.createElement(semantic_ui_react_1.Grid.Row, null, this.state.isLoading ? React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }) : this.renderList())));
    };
    OrdersWithDifferentEmailsComponent.prototype.renderRelatedToMiLevel = function (level) {
        switch (level) {
            case 3:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "green" }, "Bizony\u00EDthat\u00F3an");
            case 2:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "blue" }, "Er\u0151sen");
            case 1:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "yellow" }, "Feltehet\u0151en");
            case 0:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "grey" }, "Nem k\u00F6thet\u0151");
            default:
                return React.createElement(semantic_ui_react_1.Label, { basic: true, color: "grey" }, "Nem k\u00F6thet\u0151");
        }
    };
    OrdersWithDifferentEmailsComponent.prototype.renderList = function () {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Rendel\u00E9s azonos\u00EDt\u00F3"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Rendel\u00E9s d\u00E1tum"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Rendel\u00E9skor megadott email"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Feliratkozott email"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "M.I.-hez k\u00F6thet\u0151?"))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2 }, this.renderItems()));
    };
    OrdersWithDifferentEmailsComponent.prototype.renderItems = function () {
        var _this = this;
        if (this.state.isLoading) {
            return [].map(function (i) { return _this.renderItem(i); });
        }
        var items = (this.state.orders || []);
        return items.length
            ? items === null || items === void 0 ? void 0 : items.map(function (i) { return _this.renderItem(i); }) : this.renderEmptyItems();
    };
    OrdersWithDifferentEmailsComponent.prototype.renderItem = function (item) {
        var _a = item.data, identifier = _a.identifier, createdAt = _a.createdAt, customerEmail = _a.customerEmail, frontendEventCustomerEmail = _a.frontendEventCustomerEmail, relatedToMILevel = item.relatedToMILevel;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: identifier + "-" + customerEmail, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, null, identifier),
            React.createElement(semantic_ui_react_1.Table.Cell, null, createdAt),
            React.createElement(semantic_ui_react_1.Table.Cell, null, customerEmail),
            React.createElement(semantic_ui_react_1.Table.Cell, null, frontendEventCustomerEmail || 'nincs találat'),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderRelatedToMiLevel(relatedToMILevel)));
    };
    OrdersWithDifferentEmailsComponent.prototype.renderEmptyItems = function () {
        return React.createElement(semantic_ui_react_1.Table.Row, { className: 'cursor-pointer', key: 0, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 4 }, "Nincsenek rendel\u00E9sek a kiv\u00E1lasztott id\u0151intervallumban."));
    };
    OrdersWithDifferentEmailsComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return OrdersWithDifferentEmailsComponent;
}(abstract_component_1.AbstractComponent));
exports.OrdersWithDifferentEmailsComponent = OrdersWithDifferentEmailsComponent;
