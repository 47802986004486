"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopListScreen = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var moment = require("moment");
var React = require("react");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var list_empty_1 = require("../../../common/components/list-empty/list-empty");
var list_loader_1 = require("../../../common/components/loader/list-loader");
var pagination_list_control_1 = require("../../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../../common/components/repository-list/controls/pagination.control");
var repository_list_1 = require("../../../common/components/repository-list/repository-list");
var sort_header_1 = require("../../../common/components/sort-header/sort-header");
require("../libs/common.scss");
var shop_sync_status_1 = require("../libs/shop-sync-status");
var ShopListScreen = (function (_super) {
    __extends(ShopListScreen, _super);
    function ShopListScreen() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var _this = _super.apply(this, arguments) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                query: new field_1.Field({
                    name: 'query',
                    label: '',
                    value: '',
                    placeholder: 'Keresés'
                })
            }
        });
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._limit) !== null && _c !== void 0 ? _c : 200,
                limit: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.limit) !== null && _f !== void 0 ? _f : 200,
                _page: (_j = (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.query) === null || _h === void 0 ? void 0 : _h._page) !== null && _j !== void 0 ? _j : 0,
                query: (_l = (_k = _this.props.route) === null || _k === void 0 ? void 0 : _k.query.query) !== null && _l !== void 0 ? _l : '',
                withIntegration: 1
            }
        });
        return _this;
    }
    ShopListScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.form.$value.pipe(operators_1.debounceTime(250)).subscribe(function (value) {
            _this.control.set('query', value === null || value === void 0 ? void 0 : value.query);
            _this.control.set('page', 0);
        });
    };
    ShopListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: "ShopListScreen AdminScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement("div", { className: "ListComponent" },
                    React.createElement(semantic_ui_react_1.Grid, null,
                        React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Web\u00E1ruh\u00E1zak")),
                            React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'right' }, this.renderListHeader()))),
                    React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: false, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, repository: repository_1.Repository.use('default'), endpoint: "/a/shop", renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, { table: true, tableColspan: 10 })); }, renderEmpty: function (ctx) { return (React.createElement(list_empty_1.ListEmpty, { table: true, tableColspan: 10 })); }, renderItemsWrapper: function (ctx) { return _this.renderItemsWrapper(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); } }),
                    React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, component: semantic_ui_react_1.Pagination, limitComponent: semantic_ui_react_1.Select, limitOptions: [
                            { text: 1, value: 1 },
                            { text: 10, value: 10 },
                            { text: 25, value: 25 },
                            { text: 50, value: 50 },
                            { text: 100, value: 100 },
                            { text: 200, value: 200 },
                        ] }))));
    };
    ShopListScreen.prototype.renderListHeader = function () {
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, className: 'ListHeader' },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.query, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { icon: 'search', control: semantic_ui_react_1.Input })))));
    };
    ShopListScreen.prototype.renderItemsWrapper = function (ctx) {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement(sort_header_1.SortHeader, { title: "\u00C1ruh\u00E1z neve", property: 'title', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "Url"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "Utols\u00F3 elind\u00EDt\u00E1s d\u00E1tuma"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement(sort_header_1.SortHeader, { title: "Havi sz\u00E1mla \u00E1llapota", property: 'lastBillingState', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement(sort_header_1.SortHeader, { title: "Utols\u00F3 esem\u00E9ny", property: 'lastProcessedEventCreatedAt', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement(sort_header_1.SortHeader, { title: "Utols\u00F3 megrendel\u00E9s", property: 'lastOrderCreatedAt', "control$": this.control })),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "Szinkroniz\u00E1ci\u00F3 \u00E1llapota"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "DNS \u00E1llapota"),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' }, "Levelez\u00E9s"))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: ctx.renderItems(ctx) }));
    };
    ShopListScreen.prototype.renderItem = function (ctx, item, index) {
        return React.createElement(semantic_ui_react_1.Table.Row, { key: item._id + index, verticalAlign: "middle" },
            React.createElement(semantic_ui_react_1.Table.Cell, { onClick: function () { return router_provider_1.RouterProvider.goTo("/admin/shops/" + item._id); } },
                React.createElement("div", { className: 'display-flex align-items-center mb-4 ShopListTitle' },
                    React.createElement("div", { className: 'ShopLogo mr-4' },
                        React.createElement(semantic_ui_react_1.Image, { fluid: true, className: '', src: item === null || item === void 0 ? void 0 : item.logo })),
                    React.createElement("div", null,
                        React.createElement("strong", null, item === null || item === void 0 ? void 0 : item.title)))),
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("a", { target: '_blank', href: item.url }, item.url)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderLastBillingNew(item)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderMonthlyBillingStatus(item)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderElapsedTimeInHours(item === null || item === void 0 ? void 0 : item.lastProcessedEventCreatedAt)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderElapsedTimeInHours(item === null || item === void 0 ? void 0 : item.lastOrderCreatedAt)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderSyncState(item)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderDnsState(item)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderEmailState(item)));
    };
    ShopListScreen.prototype.renderMonthlyBillingStatus = function (item) {
        if (item._billingNotActive) {
            return React.createElement(semantic_ui_react_1.Icon, { name: "stop circle", color: "grey", title: "Nincs bekapcsolva a sz\u00E1ml\u00E1z\u00E1s jelenleg" });
        }
        if (item._billingHappened) {
            return React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green", title: "Ebben a h\u00F3napban m\u00E1r ki\u00E1llt a sz\u00E1mla rendben" });
        }
        if (item._billingShouldHaveHappened) {
            return React.createElement(semantic_ui_react_1.Icon, { name: "times circle", color: "red", title: "Ebben a h\u00F3napban m\u00E1r el\u00E9rt\u00FCk a fordul\u00F3napot, de nem \u00E1llt ki a sz\u00E1mla" });
        }
        if (!item._billingShouldHaveHappened) {
            return React.createElement(semantic_ui_react_1.Icon, { name: "pause circle", color: "blue", title: "Ebben a h\u00F3napban m\u00E9g nem \u00E9rt\u00FCk el a fordul\u00F3napot" });
        }
    };
    ShopListScreen.prototype.renderLastBillingNew = function (item) {
        if (!!(item === null || item === void 0 ? void 0 : item.lastBillingDate)) {
            return React.createElement("span", null, moment(item === null || item === void 0 ? void 0 : item.lastBillingDate).format('YYYY.MM.DD. HH:mm'));
        }
    };
    ShopListScreen.prototype.renderLastBilling = function (item) {
        if (item === null || item === void 0 ? void 0 : item.billingFailed) {
            return React.createElement("span", null, "Sikertelen sz\u00E1ml\u00E1z\u00E1s!");
        }
        if (!!(item === null || item === void 0 ? void 0 : item.lastBillingDate)) {
            return React.createElement("span", null, moment(item === null || item === void 0 ? void 0 : item.lastBillingDate).format('YYYY.MM.DD. HH:mm'));
        }
        return React.createElement("span", null, "-");
    };
    ShopListScreen.prototype.renderSyncState = function (item) {
        return React.createElement(shop_sync_status_1.ShopSyncStatus, { shop: item._id });
    };
    ShopListScreen.prototype.renderDnsState = function (item) {
        return item.mailgunStatus
            ? React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green", title: "Minden rekord rendben" })
            : React.createElement(semantic_ui_react_1.Icon, { name: "times circle", color: "red", title: "Hib\u00E1s rekord(ok)" });
    };
    ShopListScreen.prototype.renderEmailState = function (item) {
        if (!item.emailActive) {
            return React.createElement("p", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "times circle", color: "red", title: "Admin letiltotta" }));
        }
        if (item.pausedAutomaticEmailsByClient) {
            return React.createElement("p", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "pause circle", color: "blue", title: "\u00DCgyf\u00E9l sz\u00FCnetelteti" }));
        }
        return React.createElement("p", null,
            React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green", title: "Minden rendben" }));
    };
    ShopListScreen.prototype.renderElapsedTimeInHours = function (item) {
        if (item) {
            var now = new Date();
            var hoursSinceDate = moment(now).diff(new Date(item), 'hours');
            return React.createElement("span", null,
                hoursSinceDate,
                " \u00F3ra");
        }
        return React.createElement("span", null, "-");
    };
    return ShopListScreen;
}(abstract_component_1.AbstractComponent));
exports.ShopListScreen = ShopListScreen;
