"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuillFormControlType = void 0;
var abstract_form_control_type_1 = require("@codebuild/uikit/libs/form/form-control/types/abstract.form-control-type");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var QuillFormControlType = (function (_super) {
    __extends(QuillFormControlType, _super);
    function QuillFormControlType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    QuillFormControlType.prototype.getProps = function () {
        var _this = this;
        console.log(this.formControl.props.field.getValue());
        return {
            onChange: function (value) { return _this.formControl.props.field.setValue(_this.valueHandler(value || '')); },
            value: this.formControl.props.field.getValue() || '',
            placeholder: this.formControl.props.field.getPlaceholder(),
            required: this.formControl.props.field.getRequired(),
            error: this.errors,
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike', 'link'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['image'],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    ['clean']
                ]
            }
        };
    };
    QuillFormControlType.prototype.valueHandler = function (v) {
        if (v === '<p><br></p>') {
            return '';
        }
        return v;
    };
    QuillFormControlType.prototype.beforeComponent = function () {
        var label = this.formControl.props.field.getLabel();
        if (!label) {
            return null;
        }
        return React.createElement(semantic_ui_react_1.Form.Field, { className: "mb0" },
            React.createElement("label", { htmlFor: "" }, label));
    };
    QuillFormControlType.prototype.afterComponent = function () {
        return React.createElement("br", null);
    };
    return QuillFormControlType;
}(abstract_form_control_type_1.AbstractFormControlType));
exports.QuillFormControlType = QuillFormControlType;
