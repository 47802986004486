"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateRepository = void 0;
var axios_1 = require("axios");
var environment_1 = require("@codebuild/uikit/libs/environment");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var session_storage_1 = require("../../../libs/session.storage");
var StateRepository = (function () {
    function StateRepository(repository, ctx, stateProperty) {
        var _a;
        this.repository = repository;
        this.context = ctx;
        this.stateProperty = stateProperty;
        ctx.state = (_a = ctx.state) !== null && _a !== void 0 ? _a : {};
    }
    StateRepository.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    StateRepository.prototype.delete = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        this.startRequest();
                        return [4, this.repository.delete(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2, this.successRequest(response)];
                    case 2:
                        e_1 = _a.sent();
                        return [4, this.failRequest(e_1)];
                    case 3:
                        _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    StateRepository.prototype.get = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        this.startRequest();
                        return [4, this.repository.get(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2, this.successRequest(response)];
                    case 2:
                        e_2 = _a.sent();
                        return [4, this.failRequest(e_2)];
                    case 3:
                        _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    StateRepository.prototype.post = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        this.startRequest();
                        return [4, this.repository.post(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2, this.successRequest(response)];
                    case 2:
                        e_3 = _a.sent();
                        return [4, this.failRequest(e_3)];
                    case 3:
                        _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    StateRepository.prototype.put = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        this.startRequest();
                        return [4, this.repository.put(url, options)];
                    case 1:
                        response = _a.sent();
                        return [2, this.successRequest(response)];
                    case 2:
                        e_4 = _a.sent();
                        return [4, this.failRequest(e_4)];
                    case 3:
                        _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    StateRepository.prototype.request = function (method, url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        this.startRequest();
                        return [4, this.repository.request(method, url, options)];
                    case 1:
                        response = _a.sent();
                        return [2, this.successRequest(response)];
                    case 2:
                        e_5 = _a.sent();
                        return [4, this.failRequest(e_5)];
                    case 3:
                        _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    StateRepository.prototype.startRequest = function () {
        var _a;
        if (!this.context) {
            return;
        }
        this.context.setState((_a = {},
            _a[this.stateProperty] = __assign(__assign({}, (this.context.state && this.context.state[this.stateProperty] || [])), { loading: true, error: null }),
            _a));
    };
    StateRepository.prototype.successRequest = function (response) {
        var _a;
        if (!this.context) {
            return Promise.resolve(response);
        }
        this.context.setState((_a = {},
            _a[this.stateProperty] = __assign(__assign({}, (this.context.state && this.context.state[this.stateProperty] || [])), { loading: false, response: response }),
            _a));
        return Promise.resolve(response);
    };
    StateRepository.prototype.failRequest = function (error) {
        var _a;
        if (!this.context) {
            return Promise.reject(error);
        }
        this.context.setState((_a = {},
            _a[this.stateProperty] = __assign(__assign({}, (this.context.state && this.context.state[this.stateProperty] || [])), { loading: false, error: error }),
            _a));
        return Promise.reject(error);
    };
    StateRepository.prototype.putWithPureAxios = function (url, options) {
        return __awaiter(this, void 0, void 0, function () {
            var baseUrl, requestOptions, response, e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        this.startRequest();
                        baseUrl = environment_1.Environment.get('api');
                        requestOptions = __assign(__assign({}, options), { url: "" + baseUrl + url, method: 'put', headers: {
                                Authorization: get_access_token_1.getAccessToken()
                            } });
                        return [4, axios_1.default(requestOptions)];
                    case 1:
                        response = _a.sent();
                        return [2, this.successRequest(response.data)];
                    case 2:
                        e_6 = _a.sent();
                        return [4, this.failRequest(e_6)];
                    case 3:
                        _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return StateRepository;
}());
exports.StateRepository = StateRepository;
