var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
module.exports = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, require('./general')), require('./authentication')), require('./errors')), require('./success')), require('./shop-registration')), require('./shop-synchronization')), require('./menu')), require('./admin-menu')), require('./settings')), require('./product-list')), require('./category-list')), require('./order-list')), require('./lead-list')), require('./event')), require('./industries')), require('./shop-settings-menu')), require('./shoprenter-apps-list-menu')), require('./custom-filter')), require('./custom-newsletter')), require('./email-editor')), require('./preview')), require('./performance')), { subscribed: 'Feliratkozott', unsubscribed: 'Leiratkozott', 'errors.integration.shoprenter.accessError.title': 'Sajnos nem tudtunk csatlakozni a webáruházához a megadott adatokkal', 'errors.integration.shoprenter.accessError.description': 'Ellenőrizze a megadott adatokat' });
