"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopDetailsScreen = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var state_repository_1 = require("../../../common/libs/state-repository");
var integration_component_1 = require("../../../settings/components/integration/integration.component");
var bounced_emails_component_1 = require("../../components/shop/bounced-emails.component");
var emailSettingsComponent_1 = require("../../components/shop/email/emailSettingsComponent");
var general_settings_component_1 = require("../../components/shop/general-settings.component");
var import_subscribers_component_1 = require("../../components/shop/import-subscribers.component");
var mini_crm_settings_component_1 = require("../../components/shop/mini-crm-settings.component");
var orders_with_different_emails_component_1 = require("../../components/shop/orders-with-different-emails.component");
var relevant_ranking_component_1 = require("../../components/shop/relevant-ranking.component");
require("../libs/common.scss");
var shop_settings_menu_1 = require("../libs/shop-settings-menu");
var export_subscribers_component_1 = require("../../components/shop/export-subscribers.component");
var admin_credit_card_payments_component_1 = require("../../components/shop/admin-credit-card-payments.component");
var ShopDetailsScreen = (function (_super) {
    __extends(ShopDetailsScreen, _super);
    function ShopDetailsScreen() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.shopSettingsMenu = shop_settings_menu_1.shopSettingsMenu;
        _this.state = {
            activeTabId: ((_a = _this.shopSettingsMenu[0]) === null || _a === void 0 ? void 0 : _a.identifier) || '',
            isSRAppShop: false,
            isLoading: true,
        };
        return _this;
    }
    ShopDetailsScreen.prototype.componentDidMount = function () {
        this.fetchShop();
    };
    ShopDetailsScreen.prototype.fetchShop = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shopResponse, isSRAppShop;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.shop.get("/a/shop/" + ((_a = this.props.route.params) === null || _a === void 0 ? void 0 : _a.shopId), {})];
                    case 1:
                        shopResponse = _b.sent();
                        if (shopResponse) {
                            isSRAppShop = ((shopResponse === null || shopResponse === void 0 ? void 0 : shopResponse.source) === "shoprenterOnDemand" || (shopResponse === null || shopResponse === void 0 ? void 0 : shopResponse.source) === "shoprenterOnDemandChanged") || false;
                            this.setState({ isSRAppShop: isSRAppShop, isLoading: false });
                        }
                        return [2];
                }
            });
        });
    };
    ShopDetailsScreen.prototype.render = function () {
        return React.createElement("div", { className: "SettingsScreen display-flex flex-row" },
            React.createElement("div", { className: 'SettingsMenuWrapper' },
                React.createElement(semantic_ui_react_1.Header, { as: 'h4', className: 'mb-4' }, trans_1.trans('shop-settings-menu.title')),
                this.renderSettingsMenu()),
            React.createElement("div", { className: "flex-fill SettingsContentWrapper" }, this.renderTabContent()));
    };
    ShopDetailsScreen.prototype.renderSettingsMenu = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Menu, { secondary: true, vertical: true }, this.shopSettingsMenu.map(function (m) { return _this.renderMenuItem(m); }));
    };
    ShopDetailsScreen.prototype.renderMenuItem = function (m) {
        var _this = this;
        var isCreditCardPaymentMenuItem = m.identifier === "creditCardPayments";
        var disabled = isCreditCardPaymentMenuItem && this.state.isSRAppShop;
        return React.createElement(semantic_ui_react_1.Menu.Item, { className: 'MenuItem', active: m.identifier === this.state.activeTabId, onClick: function () { return _this.changeTab(m.identifier); }, content: this.renderItemContent(m), disabled: disabled || this.state.isLoading });
    };
    ShopDetailsScreen.prototype.renderItemContent = function (item) {
        return React.createElement("div", { className: 'MenuItemContent' },
            React.createElement(semantic_ui_react_1.Icon, { name: item.icon, className: 'mr-4' }),
            React.createElement("span", null, trans_1.trans("shop-settings-menu.item." + item.name)));
    };
    ShopDetailsScreen.prototype.changeTab = function (tabId) {
        this.setState({ activeTabId: tabId });
    };
    ShopDetailsScreen.prototype.renderTabContent = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var active = this.state.activeTabId;
        switch (active) {
            case 'general':
                return React.createElement(general_settings_component_1.GeneralSettingsComponent, { onUpdate: function () { return _this.refresh(); }, shop: (_a = this.state.shop) === null || _a === void 0 ? void 0 : _a.response });
            case 'miniCrmSettings':
                return React.createElement(mini_crm_settings_component_1.MiniCrmSettingsComponent, { shop: (_b = this.state.shop) === null || _b === void 0 ? void 0 : _b.response });
            case 'emailSettings':
                return React.createElement(emailSettingsComponent_1.EmailSettingsComponent, { onUpdate: function () { return _this.refresh(); }, shop: (_c = this.state.shop) === null || _c === void 0 ? void 0 : _c.response });
            case 'integration':
                return React.createElement(integration_component_1.IntegrationComponent, { showV1Helper: true, shop: (_d = this.state.shop) === null || _d === void 0 ? void 0 : _d.response });
            case 'relevant-ranking':
                return React.createElement(relevant_ranking_component_1.RelevantRankingComponent, { shop: (_e = this.state.shop) === null || _e === void 0 ? void 0 : _e.response });
            case 'import-subscribers':
                return React.createElement(import_subscribers_component_1.ImportSubscribersComponent, { shop: (_f = this.state.shop) === null || _f === void 0 ? void 0 : _f.response });
            case 'export-subscribers':
                return React.createElement(export_subscribers_component_1.ExportSubscribersComponent, { shop: (_g = this.state.shop) === null || _g === void 0 ? void 0 : _g.response });
            case 'bounced-emails':
                return React.createElement(bounced_emails_component_1.BouncedEmailsComponent, { shop: (_h = this.state.shop) === null || _h === void 0 ? void 0 : _h.response });
            case 'orders-with-different-emails':
                return React.createElement(orders_with_different_emails_component_1.OrdersWithDifferentEmailsComponent, { shop: (_j = this.state.shop) === null || _j === void 0 ? void 0 : _j.response });
            case 'creditCardPayments':
                return React.createElement(admin_credit_card_payments_component_1.AdminCreditCardPayments, { shop: (_k = this.state.shop) === null || _k === void 0 ? void 0 : _k.response });
            default:
                return null;
        }
    };
    ShopDetailsScreen.prototype.refresh = function () {
        this.fetchShop();
    };
    return ShopDetailsScreen;
}(abstract_component_1.AbstractComponent));
exports.ShopDetailsScreen = ShopDetailsScreen;
