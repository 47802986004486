"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadListComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var filter_modal_1 = require("../../../common/components/customer-filter/filter-modal");
var saved_filters_bar_1 = require("../../../common/components/customer-filter/saved-filters-bar");
var number_parser_1 = require("../../../common/libs/number-parser");
var state_repository_1 = require("../../../common/libs/state-repository");
var anonymous_lead_list_component_1 = require("./anonymous-lead-list.component");
var custom_list_component_1 = require("./custom-list.component");
var customer_list_component_1 = require("./customer-list.component");
require("./lead-list.component.scss");
var LeadListComponent = (function (_super) {
    __extends(LeadListComponent, _super);
    function LeadListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.customerListRef = null;
        _this.customListRef = null;
        _this.savedFiltersBarRef = null;
        _this.anonymous = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'anonymousCount');
        _this.customer = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'customerCount');
        _this.state = {
            customerTotal: '0',
            anonymousTotal: '0',
            filteredTotal: '0',
            customFilter: false,
            customFilterExpression: null,
            customFilterSearch: '',
            tab: 0
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                query: new field_1.Field({
                    name: 'query',
                    label: '',
                    value: '',
                    placeholder: trans_1.trans('customer-events-list.search.placeholder')
                })
            }
        });
        return _this;
    }
    LeadListComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.tab !== this.props.tab) {
            this.setState({
                tab: parseInt(this.state.tab || 0, 10)
            });
        }
    };
    LeadListComponent.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.getAnonymousLeadCount();
        this.getCustomerLeadCount();
        this.$subscriptions.push(this.form.fields.query.$value
            .pipe(operators_1.debounceTime(500)).subscribe(function (value) { return _this.onSearch(value); }));
    };
    LeadListComponent.prototype.onSearch = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.setState({
                            customFilterSearch: value
                        })];
                    case 1:
                        _a.sent();
                        if (!!this.state.customFilter) {
                            return [2];
                        }
                        if (!value) {
                            this.getAnonymousLeadCount();
                        }
                        this.getCustomerLeadCount();
                        this.customerListRef.search(value);
                        return [2];
                }
            });
        });
    };
    LeadListComponent.prototype.render = function () {
        var _this = this;
        var _a, _b;
        return React.createElement("div", { className: "LeadListComponent ListComponent" },
            React.createElement(semantic_ui_react_1.Grid, { className: 'mb-2 ListComponentHeader' },
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "\u00C9rdekl\u0151d\u0151k"),
                        React.createElement(semantic_ui_react_1.Statistic, { horizontal: true, size: 'tiny', label: trans_1.trans('webshop-data.product-list.screen.product-count.label'), value: number_parser_1.splitToNumberString((this.state.customerTotal)) })),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement("div", { className: "display-flex" },
                            React.createElement(semantic_ui_react_1.Form, { className: "flex-fill pr-4" },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.query, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                    React.createElement(semantic_ui_react_1.Form.Field, { icon: 'search', control: semantic_ui_react_1.Input }))),
                            React.createElement(filter_modal_1.FilterModal, { ref: function (ref) { return (_this.customFilterRef = ref); }, onClose: function () { return _this.setState({ customFilter: false }); }, onChange: function (value, reset) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4, this.setState({
                                                    tab: 0,
                                                    customFilter: !reset,
                                                    customFilterExpression: value
                                                })];
                                            case 1:
                                                _a.sent();
                                                this.customListRef.refetchWithExpressions();
                                                return [2];
                                        }
                                    });
                                }); }, handleNewFilterIsSaved: function () { return _this.savedFiltersBarRef.fetch(); } })))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(saved_filters_bar_1.SavedFiltersBar, { ref: function (ref) { return (_this.savedFiltersBarRef = ref); }, onDelete: function () { return _this.customFilterRef.reset(); }, onSelect: function (item) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.customFilterRef.selectItem(item)];
                        }); }); }, selected: (_b = (_a = this.customFilterRef) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.selectedId }))),
            React.createElement(semantic_ui_react_1.Tab, { panes: this.getTabPanes(this.state.customFilter), activeIndex: this.state.tab || 0, onTabChange: function (e, payload) { return _this.setState({ tab: payload.activeIndex }); } }));
    };
    LeadListComponent.prototype.getTabPanes = function (filter) {
        var _this = this;
        var _a, _b, _c, _d;
        return [
            filter
                ? {
                    menuItem: (this.renderMenuLabel('customers', 'Regisztráltak', this.state.filteredTotal || ' - ')),
                    render: function () { return React.createElement(custom_list_component_1.CustomListComponent, { ref: function (ref) { return _this.customListRef = ref; }, expressions: _this.state.customFilterExpression || [], totalChange: function (total) { return total !== _this.state.customerTotal && (_this.setState({
                            customerTotal: total,
                            filteredTotal: total,
                        })); }, search: _this.state.customFilterSearch }); }
                }
                : {
                    menuItem: (this.renderMenuLabel('customers', 'Regisztráltak', ((_b = (_a = this.state.customerCount) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.count) || ' - ')),
                    render: function () { return React.createElement(customer_list_component_1.CustomerListComponent, { ref: function (ref) { return _this.customerListRef = ref; }, totalChange: function (total) { return total !== _this.state.customerTotal && (_this.setState({ customerTotal: total })); } }); }
                },
            {
                menuItem: (this.renderMenuLabel('anonymous', 'Ismeretlenek', ((_d = (_c = this.state.anonymousCount) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.count) || ' - ')),
                render: function () { return React.createElement(anonymous_lead_list_component_1.AnonymousLeadListComponent, { totalChange: function (total) { return total !== _this.state.customerTotal && (_this.setState({ customerTotal: total })); } }); }
            },
        ];
    };
    LeadListComponent.prototype.renderMenuLabel = function (key, title, total) {
        return React.createElement(semantic_ui_react_1.Menu.Item, { key: key, className: 'display-flex justify-content-center align-items-center' },
            React.createElement("span", { className: 'mr-4' },
                title,
                " (",
                total,
                ")"));
    };
    LeadListComponent.prototype.getAnonymousLeadCount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.anonymous.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/event/anonymous-count?query=" + this.form.fields.query.getValue())];
                    case 1:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    LeadListComponent.prototype.getCustomerLeadCount = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.customer.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/customer/customer-count?query=" + this.form.fields.query.getValue())];
                    case 1:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    LeadListComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return LeadListComponent;
}(responsive_component_1.ResponsiveComponent));
exports.LeadListComponent = LeadListComponent;
