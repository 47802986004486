"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordScreen = void 0;
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var forgot_password_screen_1 = require("@codebuild/uikit/modules/codebuild/authentication/screens/forgot-password/forgot-password.screen");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var ForgotPasswordScreen = (function (_super) {
    __extends(ForgotPasswordScreen, _super);
    function ForgotPasswordScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ForgotPasswordScreen.prototype.render = function () {
        var isRequest = this.isRequest();
        return React.createElement(semantic_ui_react_1.Dimmer.Dimmable, { as: semantic_ui_react_1.Segment, className: "ForgotPasswordScreen", attached: true },
            React.createElement(semantic_ui_react_1.Dimmer, { active: this.props.routeLoading, inverted: true },
                React.createElement(semantic_ui_react_1.Loader, { active: this.props.routeLoading })),
            isRequest ? this.renderRequest() : this.renderReset());
    };
    ForgotPasswordScreen.prototype.renderRequest = function () {
        var _a;
        if (!!((_a = this.state.response) === null || _a === void 0 ? void 0 : _a.issued)) {
            return React.createElement("div", null,
                this.renderRequestSuccessHeader(),
                this.renderRequestSuccessDescription());
        }
        return React.createElement("div", null,
            this.renderRequestHeader(),
            this.renderRequestDescription(),
            this.renderRequestForm(),
            React.createElement(semantic_ui_react_1.Divider, null),
            this.renderRequestCrossLink());
    };
    ForgotPasswordScreen.prototype.renderReset = function () {
        return React.createElement("div", null,
            this.renderResetHeader(),
            this.renderResetDescription(),
            this.renderResetForm());
    };
    ForgotPasswordScreen.prototype.renderRequestDescription = function () {
        return React.createElement("span", null,
            trans_1.trans('auth.forgot-password.request.description'),
            React.createElement("br", null),
            React.createElement("br", null));
    };
    ForgotPasswordScreen.prototype.renderResetDescription = function () {
        return React.createElement("span", null,
            trans_1.trans('auth.forgot-password.reset.description'),
            React.createElement("br", null),
            React.createElement("br", null));
    };
    ForgotPasswordScreen.prototype.renderRequestSuccessDescription = function () {
        return React.createElement("span", null,
            trans_1.trans('auth.forgot-password.request-success.description'),
            React.createElement("br", null),
            React.createElement("br", null));
    };
    ForgotPasswordScreen.prototype.renderRequestSuccessHeader = function () {
        return React.createElement(semantic_ui_react_1.Header, { as: 'h1', className: 'ForgotPasswordScreenHeader' }, trans_1.trans('auth.forgot-password.request-success.header'));
    };
    return ForgotPasswordScreen;
}(forgot_password_screen_1.ForgotPasswordScreen));
exports.ForgotPasswordScreen = ForgotPasswordScreen;
