"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailMenuStatusComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var React = require("react");
require("./menu.component.scss");
var session_storage_1 = require("../../../../libs/session.storage");
var semantic_ui_react_1 = require("semantic-ui-react");
var EmailMenuStatusComponent = (function (_super) {
    __extends(EmailMenuStatusComponent, _super);
    function EmailMenuStatusComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isLoading: true,
            currentShoprenterData: null
        };
        return _this;
    }
    EmailMenuStatusComponent.prototype.componentDidMount = function () {
        var currentShoprenterData = session_storage_1.SessionStorage.getItem(session_storage_1.SESSION_STORAGE_KEYS.SESSION_STORAGE_SHOPRENTER_DATA_KEY);
        if (currentShoprenterData === null || currentShoprenterData === void 0 ? void 0 : currentShoprenterData.dashboard) {
            this.setState({ currentShoprenterData: currentShoprenterData === null || currentShoprenterData === void 0 ? void 0 : currentShoprenterData.dashboard, isLoading: false });
        }
        else {
            this.setState({ isLoading: false });
        }
    };
    EmailMenuStatusComponent.prototype.render = function () {
        var _a;
        var isAutomaticMailingEnabled = (_a = this.state.currentShoprenterData) === null || _a === void 0 ? void 0 : _a.autoMailUpAndRunning;
        return React.createElement("div", { style: {
                padding: '4px 8px',
                marginTop: '4px',
                marginBottom: '8px',
                color: 'white',
                fontWeight: 700,
                lineHeight: '24px',
            } }, this.state.isLoading ? (React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered' })) : (this.state.currentShoprenterData ? (React.createElement("p", null,
            "Lev\u00E9lk\u00FCld\u00E9s:",
            React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'ml-1', loading: false, color: isAutomaticMailingEnabled ? 'green' : 'red', name: isAutomaticMailingEnabled ? 'check circle' : 'exclamation circle' }))) : null));
    };
    return EmailMenuStatusComponent;
}(responsive_component_1.ResponsiveComponent));
exports.EmailMenuStatusComponent = EmailMenuStatusComponent;
