"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentCartSumCardComponent = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var React = require("react");
var CountUp = require("react-countup");
var rxjs_1 = require("rxjs");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var state_repository_1 = require("../../common/libs/state-repository");
require("./dashboard.component.scss");
var CurrentCartSumCardComponent = (function (_super) {
    __extends(CurrentCartSumCardComponent, _super);
    function CurrentCartSumCardComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentCartSum = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'currentCartSum');
        _this.state = {
            shop: _this.getCurrentShop(),
            cartSum: 0
        };
        return _this;
    }
    CurrentCartSumCardComponent.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.$subscriptions.push(rxjs_1.timer(0, 10000).subscribe(function () { return _this.fetchCurrentCartSum(); }));
    };
    CurrentCartSumCardComponent.prototype.fetchCurrentCartSum = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.currentCartSum.get("/s/" + ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/event/current-cart-sum")];
                    case 1:
                        _c.sent();
                        return [2];
                }
            });
        });
    };
    CurrentCartSumCardComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement(semantic_ui_react_1.Message, { className: 'DashboardMessage CurrentCartSum' },
            React.createElement("div", { className: "icon-wrapper" },
                React.createElement(semantic_ui_react_1.Icon, { name: 'cart' })),
            React.createElement("p", null, "Jelenleg kos\u00E1rban"),
            React.createElement("h2", null,
                React.createElement(CountUp.default, { start: this.state.cartSum, end: ((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.currentCartSum) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.currentCartSum) || 0, separator: ' ', duration: 3, onEnd: function () { var _a, _b, _c; return _this.setState({ cartSum: (_c = (_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.currentCartSum) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.currentCartSum }); } }),
                " Ft"),
            React.createElement("br", null),
            React.createElement(semantic_ui_react_1.Button, { basic: true, onClick: function () { return router_provider_1.RouterProvider.goTo('/leads', { query: { selectFilter: 'has-cart' } }); } }, "Mutasd \u0151ket"));
    };
    CurrentCartSumCardComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CurrentCartSumCardComponent;
}(responsive_component_1.ResponsiveComponent));
exports.CurrentCartSumCardComponent = CurrentCartSumCardComponent;
