"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestSyncButton = void 0;
var React = require("react");
var environment_1 = require("@codebuild/uikit/libs/environment");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var state_repository_1 = require("../../../common/libs/state-repository");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var RequestSyncButton = (function (_super) {
    __extends(RequestSyncButton, _super);
    function RequestSyncButton(props) {
        var _this = _super.call(this, props) || this;
        _this.integration = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'integration');
        _this.state = {
            isLoading: false
        };
        return _this;
    }
    RequestSyncButton.prototype.restartSync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('restartSync for shop:', this.props.shopId);
                        return [4, this.integration.put("/s/" + this.props.shopId + "/integration/startSync")];
                    case 1:
                        response = _a.sent();
                        console.log('restartSync response', response);
                        return [2, response];
                }
            });
        });
    };
    RequestSyncButton.prototype.handleButtonClick = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var SHOPRENTER_API_ROOT_URL, url, response, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        SHOPRENTER_API_ROOT_URL = environment_1.Environment.get('shoprenterApiRootUrl');
                        url = new URL(SHOPRENTER_API_ROOT_URL + "/dashboard/shop/" + this.props.shopId + "/request-sync");
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, 4, 5]);
                        return [4, this.restartSync()];
                    case 2:
                        response = _c.sent();
                        console.log('RequestSyncButton handleButtonClick', response);
                        (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.refetch) === null || _b === void 0 ? void 0 : _b.call(_a);
                        return [3, 5];
                    case 3:
                        error_1 = _c.sent();
                        console.log('RequestSyncButton handleButtonClick', error_1);
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    RequestSyncButton.prototype.render = function () {
        var _this = this;
        console.log('RequestSyncButton', this.props.shopId, this.props.isDisabled);
        return (React.createElement(semantic_ui_react_1.Button, { disabled: this.state.isLoading || this.props.isDisabled, loading: this.state.isLoading, onClick: function () { return _this.handleButtonClick(); } }, "Szinkroniz\u00E1ci\u00F3 indul"));
    };
    return RequestSyncButton;
}(abstract_component_1.AbstractComponent));
exports.RequestSyncButton = RequestSyncButton;
