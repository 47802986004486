"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminMenuBodyComponent = void 0;
require("./admin-menu.component.scss");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var admin_menu_1 = require("./admin-menu");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var AdminMenuBodyComponent = (function (_super) {
    __extends(AdminMenuBodyComponent, _super);
    function AdminMenuBodyComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.menu = admin_menu_1.adminMenu;
        _this.state = {
            active: null
        };
        return _this;
    }
    AdminMenuBodyComponent.prototype.render = function () {
        return React.createElement("div", { className: 'MenuBodyComponent' }, this.renderMenu());
    };
    AdminMenuBodyComponent.prototype.renderMenu = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Menu, { secondary: true, vertical: true }, this.menu.map(function (m) { return _this.renderMenuItem(m); }));
    };
    AdminMenuBodyComponent.prototype.renderMenuItem = function (m) {
        return React.createElement(semantic_ui_react_1.Menu.Item, { key: m.name, className: 'MenuItem', active: m.activeRegexp.test(this.props.route.path), onClick: function () { return router_provider_1.RouterProvider.goTo(m.path); }, content: this.renderItemContent(m) });
    };
    AdminMenuBodyComponent.prototype.renderItemContent = function (item) {
        return React.createElement("div", { className: 'MenuItemContent' },
            React.createElement(semantic_ui_react_1.Icon, { name: item.icon, className: 'mr-4' }),
            React.createElement("strong", null, trans_1.trans("admin-menu.item." + item.name)));
    };
    return AdminMenuBodyComponent;
}(responsive_component_1.ResponsiveComponent));
exports.AdminMenuBodyComponent = AdminMenuBodyComponent;
