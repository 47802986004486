"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopRegistrationComponent = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
require("./shop-registration.component.scss");
var continue_later_component_1 = require("./steps/continue-later.component");
var shop_registration_first_step_component_1 = require("./steps/shop-registration-first-step.component");
var shop_registration_second_step_component_1 = require("./steps/shop-registration-second-step.component");
var shop_registration_start_step_component_1 = require("./steps/shop-registration-start-step.component");
var shopify_plus_analytic_component_1 = require("./steps/shopify-integration/shopify-plus-analytic.component");
var shopify_plus_integration_component_1 = require("./steps/shopify-integration/shopify-plus-integration.component");
var shoprenter_analytic_component_1 = require("./steps/shoprenter-integration/shoprenter-analytic.component");
var shoprenter_integration_component_1 = require("./steps/shoprenter-integration/shoprenter-integration.component");
var unas_analytic_component_1 = require("./steps/unas-integration/unas-analytic.component");
var unas_integration_component_1 = require("./steps/unas-integration/unas-integration.component");
var woocommerce_analytic_component_1 = require("./steps/woocommerce-integration/woocommerce-analytic.component");
var woocommerce_integration_component_1 = require("./steps/woocommerce-integration/woocommerce-integration.component");
var ShopRegistrationComponent = (function (_super) {
    __extends(ShopRegistrationComponent, _super);
    function ShopRegistrationComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userShopRepository = repository_1.Repository.use('default');
        _this.integrationRepository = repository_1.Repository.use('default');
        _this.continueLaterModalRef = null;
        _this.state = {
            loading: false,
            currentStep: 0,
            webshopData: null,
            integrationType: null,
            showProgress: true
        };
        return _this;
    }
    ShopRegistrationComponent.prototype.componentDidMount = function () {
        this.initRegistrationState();
    };
    ShopRegistrationComponent.prototype.initRegistrationState = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, shops, integration;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({ loading: true });
                        return [4, this.userShopRepository.get('/u/user-shop')];
                    case 1:
                        response = _b.sent();
                        shops = (response === null || response === void 0 ? void 0 : response.items) || [];
                        if (!shops || !shops.length) {
                            return [2, this.setState({
                                    currentStep: 0,
                                    webshopData: null,
                                    integrationType: null,
                                    loading: false
                                })];
                        }
                        if (!!!shops[0].integrationId) return [3, 3];
                        return [4, this.integrationRepository.get("/s/" + ((_a = shops[0]) === null || _a === void 0 ? void 0 : _a._id) + "/integration")];
                    case 2:
                        integration = _b.sent();
                        return [2, this.setState({
                                currentStep: 4,
                                webshopData: shops[0],
                                integrationType: integration === null || integration === void 0 ? void 0 : integration.type,
                                loading: false
                            })];
                    case 3:
                        this.setState({
                            currentStep: 2,
                            webshopData: shops[0],
                            integrationType: null,
                            loading: false
                        });
                        return [2];
                }
            });
        });
    };
    ShopRegistrationComponent.prototype.render = function () {
        var _this = this;
        if (this.state.loading) {
            return React.createElement(semantic_ui_react_1.Loader, { active: true });
        }
        return React.createElement(semantic_ui_react_1.Grid, { className: 'ShopRegistrationComponent' },
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Segment, null,
                        React.createElement(semantic_ui_react_1.Grid, null,
                            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                                React.createElement(semantic_ui_react_1.Grid.Column, { textAlign: 'center' }, this.state.currentStep === 0 ? this.renderShopRegistrationStartImage() : this.renderRegistrationStepper()))),
                        this.renderStep()))),
            React.createElement(continue_later_component_1.ContinueLaterComponent, { ref: function (ref) { return _this.continueLaterModalRef = ref; } }));
    };
    ShopRegistrationComponent.prototype.renderShopRegistrationStartImage = function () {
        return React.createElement(semantic_ui_react_1.Image, { src: '/assets/images/shop_registration_image.svg', className: 'shop-registration-image' });
    };
    ShopRegistrationComponent.prototype.renderRegistrationStepper = function () {
        return React.createElement("div", null,
            React.createElement(semantic_ui_react_1.Icon, { className: "StepIcon " + this.getStepperColorClass(1), name: 'circle' }),
            React.createElement(semantic_ui_react_1.Icon, { className: "StepIcon " + this.getStepperColorClass(2), name: 'circle' }),
            React.createElement(semantic_ui_react_1.Icon, { className: "StepIcon " + this.getStepperColorClass(3), name: 'circle' }),
            React.createElement(semantic_ui_react_1.Icon, { className: "StepIcon " + this.getStepperColorClass(4), name: 'circle' }));
    };
    ShopRegistrationComponent.prototype.renderStep = function () {
        var _this = this;
        if (this.state.loading) {
            return;
        }
        switch (this.state.currentStep) {
            case 0:
                return React.createElement(shop_registration_start_step_component_1.ShopRegistrationStartStepComponent, { onCancel: function () { return _this.handleOnCancel(); }, onSubmit: function (data) { return _this.setState({
                        currentStep: 1
                    }); } });
            case 1:
                return React.createElement(shop_registration_first_step_component_1.ShopRegistrationFirstStepComponent, { values: this.state.webshopData, onCancel: function () { return _this.handleOnCancel(); }, onSubmit: function (data) { return _this.handleFirstStepSubmit(data); } });
            case 2:
                return React.createElement(shop_registration_second_step_component_1.ShopRegistrationSecondStepComponent, { onBack: function () { return _this.setState({ currentStep: 0 }); }, onCancel: function () { return _this.handleOnCancel(); }, onSelect: function (type) { return _this.handleSecondStepSelect(type); } });
            case 3:
                return this.renderIntegrationStep();
            case 4:
                return this.renderAnalyticScript();
            default:
                return;
        }
    };
    ShopRegistrationComponent.prototype.renderIntegrationStep = function () {
        var _this = this;
        switch (this.state.integrationType) {
            case 'wooCommerce':
                return React.createElement(woocommerce_integration_component_1.WoocommerceIntegrationComponent, { webshopData: this.state.webshopData, onSubmit: function (shop) { return _this.handleIntegrationCreated(shop); } });
            case 'shopRenter':
                return React.createElement(shoprenter_integration_component_1.ShopRenterIntegrationComponent, { webshopData: this.state.webshopData, onSubmit: function (shop) { return _this.handleIntegrationCreated(shop); } });
            case 'unas':
                return React.createElement(unas_integration_component_1.UnasIntegrationComponent, { webshopData: this.state.webshopData, onSubmit: function (shop) { return _this.handleIntegrationCreated(shop); } });
            case 'shopifyPlus':
                return React.createElement(shopify_plus_integration_component_1.ShopifyPlusIntegrationComponent, { webshopData: this.state.webshopData, onSubmit: function () { return _this.handleScriptInserted(); } });
            default:
                return React.createElement(semantic_ui_react_1.Header, null,
                    "THIRD_STEP ",
                    this.state.integrationType);
        }
    };
    ShopRegistrationComponent.prototype.renderAnalyticScript = function () {
        var _this = this;
        switch (this.state.integrationType) {
            case 'wooCommerce':
                return React.createElement(woocommerce_analytic_component_1.WoocommerceAnalyticComponent, { webshopData: this.state.webshopData, onSubmit: function () { return _this.handleScriptInserted(); } });
            case 'shopRenter':
                return React.createElement(shoprenter_analytic_component_1.ShoprenterAnalyticComponent, { webshopData: this.state.webshopData, onSubmit: function () { return _this.handleScriptInserted(); } });
            case 'unas':
                return React.createElement(unas_analytic_component_1.UnasAnalyticComponent, { webshopData: this.state.webshopData, onSubmit: function () { return _this.handleScriptInserted(); } });
            case 'shopifyPlus':
                return React.createElement(shopify_plus_analytic_component_1.ShopifyPlusAnalyticComponent, { webshopData: this.state.webshopData, onSubmit: function () { return _this.handleScriptInserted(); } });
            default:
                return React.createElement(semantic_ui_react_1.Header, null,
                    "THIRD_STEP ",
                    this.state.integrationType);
        }
    };
    ShopRegistrationComponent.prototype.handleFirstStepSubmit = function (data) {
        this.setState({ currentStep: 2, webshopData: data });
    };
    ShopRegistrationComponent.prototype.handleSecondStepSelect = function (type) {
        this.setState({ currentStep: 3, integrationType: type });
    };
    ShopRegistrationComponent.prototype.handleOnCancel = function () {
        return this.continueLaterModalRef.setOpen(true);
    };
    ShopRegistrationComponent.prototype.handleIntegrationCreated = function (shop) {
        return this.initRegistrationState();
    };
    ShopRegistrationComponent.prototype.handleScriptInserted = function () {
        var _a, _b;
        router_provider_1.RouterProvider.goTo("/shop-synchronization/" + ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.webshopData) === null || _b === void 0 ? void 0 : _b._id) + "/progress");
    };
    ShopRegistrationComponent.prototype.getStepperColorClass = function (step) {
        if (this.state.currentStep === step) {
            return '';
        }
        if (this.state.currentStep > step) {
            return 'green';
        }
        return 'outline';
    };
    return ShopRegistrationComponent;
}(abstract_component_1.AbstractComponent));
exports.ShopRegistrationComponent = ShopRegistrationComponent;
