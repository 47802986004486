"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.industryOptions = void 0;
exports.industryOptions = [
    { text: 'industry-options.electronic', value: 'ELECTRONIC', key: 'ELECTRONIC' },
    { text: 'industry-options.it', value: 'IT', key: 'IT' },
    { text: 'industry-options.art', value: 'ART', key: 'ART' },
    { text: 'industry-options.car', value: 'CAR', key: 'CAR' },
    { text: 'industry-options.beauty', value: 'BEAUTY', key: 'BEAUTY' },
    { text: 'industry-options.book', value: 'BOOK', key: 'BOOK' },
    { text: 'industry-options.business', value: 'BUSINESS', key: 'BUSINESS' },
    { text: 'industry-options.finance', value: 'FINANCE', key: 'FINANCE' },
    { text: 'industry-options.food', value: 'FOOD', key: 'FOOD' },
    { text: 'industry-options.toy', value: 'TOY', key: 'TOY' },
    { text: 'industry-options.health', value: 'HEALTH', key: 'HEALTH' },
    { text: 'industry-options.hobby', value: 'HOBBY', key: 'HOBBY' },
    { text: 'industry-options.home', value: 'HOME', key: 'HOME' },
    { text: 'industry-options.work', value: 'WORK', key: 'WORK' },
    { text: 'industry-options.law', value: 'LAW', key: 'LAW' },
    { text: 'industry-options.news', value: 'NEWS', key: 'NEWS' },
    { text: 'industry-options.society', value: 'SOCIETY', key: 'SOCIETY' },
    { text: 'industry-options.pet', value: 'PET', key: 'PET' },
    { text: 'industry-options.online', value: 'ONLINE', key: 'ONLINE' },
    { text: 'industry-options.realestate', value: 'REALESTATE', key: 'REALESTATE' },
    { text: 'industry-options.reference', value: 'REFERENCE', key: 'REFERENCE' },
    { text: 'industry-options.science', value: 'SCIENCE', key: 'SCIENCE' },
    { text: 'industry-options.shopping', value: 'SHOPPING', key: 'SHOPPING' },
    { text: 'industry-options.sport', value: 'SPORT', key: 'SPORT' },
    { text: 'industry-options.travel', value: 'TRAVEL', key: 'TRAVEL' },
    { text: 'industry-options.other', value: 'OTHER', key: 'OTHER' }
];
