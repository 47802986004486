"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationComponent = void 0;
var React = require("react");
var moment = require("moment");
var axios_1 = require("axios");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var environment_1 = require("@codebuild/uikit/libs/environment");
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var session_storage_1 = require("../../../../libs/session.storage");
var state_repository_1 = require("../../../common/libs/state-repository");
var lodash_1 = require("lodash");
var sleep_1 = require("@codebuild/uikit/libs/libs/sleep");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var errors_constant_1 = require("../../../shoprenter/errors/errors.constant");
var IntegrationComponent = (function (_super) {
    __extends(IntegrationComponent, _super);
    function IntegrationComponent() {
        var _a, _b;
        var _this = _super.apply(this, arguments) || this;
        _this.integration = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'integration');
        _this.state = {
            shop: _this.props.shop || session_storage_1.SessionStorage.getItem('SelectedShop'),
            integration: null,
            loading: false,
            updateLoading: false,
            history: null,
            createNewPingEventForSubscribersScriptStatusTimer: null
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                url: new field_1.Field({
                    name: 'url',
                    placeholder: 'https://www.testshop.hu/',
                    label: '',
                    value: ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.url) || '',
                    validators: []
                })
            }
        });
        return _this;
    }
    IntegrationComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.integration.get("/s/" + this.state.shop._id + "/integration");
        this.fetchProgress();
        if (this.props.showV1Helper) {
            this.fetchRelevantEmailValidations();
        }
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.handleSubmit(); }));
    };
    IntegrationComponent.prototype.fetchRelevantEmailValidations = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchFinishedEmailValidation()];
                    case 1:
                        _a.sent();
                        return [4, this.fetchOngoingEmailValidation()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.fetchFinishedEmailValidation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shopId, integrationId, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ loading: true });
                        shopId = this.state.shop._id;
                        integrationId = this.state.shop.integrationId;
                        url = "/s/" + shopId + "/shop/email-validation/" + integrationId + "?status=FINISHED";
                        return [4, this.integration.get(url)];
                    case 1:
                        response = _a.sent();
                        if (response) {
                            this.setState({ finishedValidation: response });
                        }
                        this.setState({ loading: false });
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.fetchOngoingEmailValidation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shopId, integrationId, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ loading: true });
                        shopId = this.state.shop._id;
                        integrationId = this.state.shop.integrationId;
                        return [4, this.integration.get("/s/" + shopId + "/shop/email-validation/" + integrationId + "?status=RECEIVED")];
                    case 1:
                        response = _a.sent();
                        if (!response) return [3, 2];
                        this.setState({ ongoingValidation: response });
                        return [3, 4];
                    case 2: return [4, this.integration.get("/s/" + shopId + "/shop/email-validation/" + integrationId + "?status=SENT")];
                    case 3:
                        response = _a.sent();
                        if (response) {
                            this.setState({ ongoingValidation: response });
                        }
                        _a.label = 4;
                    case 4:
                        this.setState({ loading: false });
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.requestEmailValidation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shopId, integrationId, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.state.ongoingValidation) {
                            return [2];
                        }
                        this.setState({ loading: true });
                        shopId = this.state.shop._id;
                        integrationId = this.state.shop.integrationId;
                        url = "/s/" + shopId + "/shop/email-validation/" + integrationId;
                        return [4, this.integration.post(url)];
                    case 1:
                        _a.sent();
                        return [4, this.fetchOngoingEmailValidation()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.cleanEmailList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shopId, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.ongoingValidation || this.state.ongoingValidation.status !== 'RECEIVED') {
                            return [2];
                        }
                        this.setState({ loading: true });
                        shopId = this.state.shop._id;
                        url = "/s/" + shopId + "/shop/email-validation/" + this.state.ongoingValidation._id.toString();
                        return [4, this.integration.put(url)];
                    case 1:
                        _a.sent();
                        this.setState({ finishedValidation: null });
                        this.setState({ ongoingValidation: null });
                        return [4, this.fetchFinishedEmailValidation()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.cancelEmailListCleaning = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shopId, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.ongoingValidation) {
                            return [2];
                        }
                        this.setState({ loading: true });
                        shopId = this.state.shop._id;
                        url = "/s/" + shopId + "/shop/email-validation/" + this.state.ongoingValidation._id.toString();
                        return [4, this.integration.delete(url)];
                    case 1:
                        _a.sent();
                        this.setState({ ongoingValidation: null });
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.fetchProgress = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.showSyncProgress) return [3, 3];
                        this.setState({ loading: true });
                        return [4, this.integration.get("/s/" + this.state.shop._id + "/integration")];
                    case 1:
                        _a.sent();
                        if (this.isIntegrationTerminated()) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        return [4, sleep_1.sleep(1000)];
                    case 2:
                        _a.sent();
                        return [2, this.fetchProgress()];
                    case 3: return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.handleSubmit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var api, url, API_URL, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ updateLoading: true });
                        api = environment_1.Environment.get('shoprenterApiRootUrl');
                        url = this.form.fields.url.getValue();
                        API_URL = api + "/marketplace/shop/" + ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/url?url=" + url;
                        return [4, axios_1.default.patch(API_URL)];
                    case 1:
                        _c.sent();
                        this.setState({ updateLoading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ updateLoading: false, updateError: e_1 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.setSyncWithChildren = function (actualState) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.integration.put("/a/shop/" + ((_a = this.state) === null || _a === void 0 ? void 0 : _a.shop._id) + "/set-sync-with-children", {
                            data: { syncWithChildren: !actualState }
                        })];
                    case 1:
                        _b.sent();
                        this.restartSync();
                        location.reload();
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.isIntegrationTerminated = function () {
        var integration = lodash_1.get(this.state, 'integration.response', null);
        return (integration === null || integration === void 0 ? void 0 : integration.status) === 'success' || (integration === null || integration === void 0 ? void 0 : integration.status) === 'error';
    };
    IntegrationComponent.prototype.render = function () {
        return React.createElement("div", { className: 'ShopProfileComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'pl-4' },
                this.renderIntegrationInformation(),
                this.renderV1Helper(),
                this.renderIntegrationProcess()),
            this.props.showV1Helper &&
                React.createElement("div", null,
                    React.createElement("div", { className: "pt-4 pl-4" },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h4', className: "pt-4" }, "Legut\u00F3bbi sikeres listatiszt\u00EDt\u00E1s"),
                        this.renderLastSuccessfulEmailValidation()),
                    React.createElement("div", { className: "pt-4 pl-4" },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h4', className: "pt-4" }, "Folyamatban l\u00E9v\u0151 listatiszt\u00EDt\u00E1s"),
                        this.renderOngoingEmailValidation()),
                    React.createElement("div", { className: "pt-4 pl-4" },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h4', className: "pt-4" }, "Inakt\u00EDv feliratkoz\u00F3k"),
                        this.renderInactiveSubscribersCompontent())));
    };
    IntegrationComponent.prototype.renderLastSuccessfulEmailValidation = function () {
        var lastSuccessfulListCleaning;
        if (this.state.finishedValidation) {
            lastSuccessfulListCleaning = React.createElement("div", { className: "mt-4" },
                React.createElement("div", null,
                    React.createElement("span", null,
                        React.createElement("strong", null, "Folyamat l\u00E9trehozva:"),
                        " ",
                        this.renderDateTime(this.state.finishedValidation.createdAt))),
                React.createElement("div", null,
                    React.createElement("span", null,
                        React.createElement("strong", null, "Teljes\u00EDtve:"),
                        " ",
                        this.renderDateTime(this.state.finishedValidation.updatedAt))));
        }
        else {
            lastSuccessfulListCleaning = React.createElement("div", null,
                React.createElement("p", null, "M\u00E9g nem volt listatiszt\u00EDt\u00E1s"));
        }
        return lastSuccessfulListCleaning;
    };
    IntegrationComponent.prototype.renderOngoingEmailValidation = function () {
        var _this = this;
        if (this.state.ongoingValidation) {
            var ongoingValidationTable = this.renderEmailValidation();
            var executeListCleaningButton = React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.cleanEmailList(); }, disabled: this.state.ongoingValidation.status !== 'RECEIVED', content: 'Kockázatos címek leiratkoztatása', className: "mt-2" });
            var cancelListCleaningButton = React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.cancelEmailListCleaning(); }, disabled: !this.state.ongoingValidation, content: 'Folyamat megszakítása', className: "mt-2" });
            return React.createElement("div", null,
                ongoingValidationTable,
                executeListCleaningButton,
                cancelListCleaningButton);
        }
        return React.createElement("div", null,
            React.createElement("p", null, "Nincs v\u00E1rakoz\u00F3 listatiszt\u00EDt\u00E1s"),
            React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.requestEmailValidation(); }, content: 'Email validáció indítása', className: "mt-2" }));
    };
    IntegrationComponent.prototype.renderEmailValidation = function () {
        return React.createElement(semantic_ui_react_1.Table, { className: 'position-relative', selectable: true, celled: true },
            React.createElement(semantic_ui_react_1.Table.Header, { key: 1 },
                React.createElement(semantic_ui_react_1.Table.Row, null,
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "Ind\u00EDt\u00E1s id\u0151pontja")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "\u00C1llapotba \u00E1ll\u00E1s id\u0151pontja")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "St\u00E1tusz")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "UNKNOWN")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "LOW")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "MEDIUM")),
                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { rowSpan: '2' },
                        React.createElement("span", null, "HIGH")))),
            React.createElement(semantic_ui_react_1.Table.Body, { key: 2, children: this.renderEmailValidationRow() }));
    };
    IntegrationComponent.prototype.renderEmailValidationRow = function () {
        return React.createElement(semantic_ui_react_1.Table.Row, { key: this.state.ongoingValidation._id, verticalAlign: "middle", style: { cursor: 'pointer' } },
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderDateTime(this.state.ongoingValidation.createdAt)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderDateTime(this.state.ongoingValidation.updatedAt)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderStatus(this.state.ongoingValidation)),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderRisk(this.state.ongoingValidation, 'unknown')),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderRisk(this.state.ongoingValidation, 'low')),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderRisk(this.state.ongoingValidation, 'medium')),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderRisk(this.state.ongoingValidation, 'high')));
    };
    IntegrationComponent.prototype.renderStatus = function (emailValidation) {
        switch (emailValidation.status) {
            case 'RECEIVED':
                return 'Feldolgozott';
            case 'SENT':
                return 'MailGun-ra vár';
            case 'NEW':
                return 'Küldés MailGun-nak';
            default:
                return '';
        }
    };
    IntegrationComponent.prototype.renderRisk = function (history, riskLevel) {
        return history.status === 'RECEIVED' ? history.risk[riskLevel] + " db" : '-';
    };
    IntegrationComponent.prototype.renderDateTime = function (date) {
        return moment(date).format('YYYY.MM.DD. HH:mm:ss');
    };
    IntegrationComponent.prototype.renderSyncStatus = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        return React.createElement(semantic_ui_react_1.Grid, null,
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('product')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('customer')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('subscriber')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('order')),
                    React.createElement(semantic_ui_react_1.Segment, null, this.renderEntitySync('category')),
                    this.renderPostProcessing())),
            ((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.integration) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.error) && React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(detailed_error_1.DetailedError, { error: (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.update) === null || _e === void 0 ? void 0 : _e.integration }))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, centered: true },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.restartSync(); }, content: 'Szinkronizáció elindítása' }))));
    };
    IntegrationComponent.prototype.renderEntitySync = function (entity) {
        var data = lodash_1.get(this.state, "integration.response.sync.meta." + entity, 0);
        var status = lodash_1.get(this.state, 'integration.response.status', null);
        if (status === 'success') {
            return this.renderStateSuccess(entity, data);
        }
        switch (data === null || data === void 0 ? void 0 : data.status) {
            case 'success':
                return this.renderStateSuccess(entity, data);
            case 'pending':
                return this.renderStatePending(entity, data);
            case 'stopped':
                return this.renderStateStopped(entity, data);
            case 'error':
                return this.renderStateError(entity, data);
            default:
                return this.renderStatePending(entity, data);
        }
    };
    IntegrationComponent.prototype.renderStatePending = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { loading: true, size: 'large', color: 'grey', className: 'mr-3', name: 'circle notch' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.pending." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    IntegrationComponent.prototype.renderStateStopped = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'yellow', className: 'mr-3', name: 'minus circle' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.pending." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    IntegrationComponent.prototype.renderStateSuccess = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { size: 'large', className: 'mr-3', color: 'green', loading: false, name: 'check circle' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.success." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    IntegrationComponent.prototype.renderStateError = function (entity, data) {
        return React.createElement("div", { className: 'display-flex flex-row align-items-center' },
            React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'red', className: 'mr-3', loading: false, name: 'times circle outline' }),
                React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.error." + entity))),
            !!data && React.createElement("strong", null,
                (data === null || data === void 0 ? void 0 : data.currentValid) || 0,
                " db"));
    };
    IntegrationComponent.prototype.renderPostProcessing = function () {
        var status = lodash_1.get(this.state, 'integration.response.status', null);
        if (['queued', 'configured', 'syncing'].includes(status)) {
            return React.createElement(semantic_ui_react_1.Segment, { disabled: true },
                React.createElement("div", { className: 'display-flex flex-row align-items-center' },
                    React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                        React.createElement("h5", { style: { margin: 0 } }, trans_1.trans('sync-progress.postprocessing.syncing.title')))));
        }
        return React.createElement(semantic_ui_react_1.Segment, null,
            React.createElement("div", { className: 'display-flex flex-row align-items-center' },
                React.createElement("div", { className: 'flex-fill display-flex align-items-center' },
                    status === 'processing' && React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'grey', className: 'mr-3', loading: true, name: 'circle notch' }),
                    status === 'success' && React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'green', className: 'mr-3', loading: false, name: 'check circle' }),
                    status === 'error' && React.createElement(semantic_ui_react_1.Icon, { size: 'large', color: 'red', className: 'mr-3', loading: false, name: 'times circle outline' }),
                    React.createElement("h5", { style: { margin: 0 } }, trans_1.trans("sync-progress.postprocessing." + status + ".title")))));
    };
    IntegrationComponent.prototype.restartSync = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.integration.put("/s/" + ((_a = this.state) === null || _a === void 0 ? void 0 : _a.shop._id) + "/integration/startSync")];
                    case 1:
                        _b.sent();
                        return [4, sleep_1.sleep(1000)];
                    case 2:
                        _b.sent();
                        this.fetchProgress();
                        return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.renderIntegrationInformation = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        return [
            React.createElement(semantic_ui_react_1.Grid.Row, { key: 0 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, trans_1.trans('settings.integration.data.title')))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, trans_1.trans('settings.integration.name.label'))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.title))),
            React.createElement(semantic_ui_react_1.Divider, { className: 'my-0', key: 2 }),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 3 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, trans_1.trans('settings.integration.type.label'))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, ((_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.integration) === null || _d === void 0 ? void 0 : _d.response) === null || _e === void 0 ? void 0 : _e.type) || '-'))),
            React.createElement(semantic_ui_react_1.Divider, { className: 'my-0', key: 4 }),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 5 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, trans_1.trans('settings.integration.identifier.label'))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, (_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.shop) === null || _g === void 0 ? void 0 : _g.integrationId))),
            React.createElement(semantic_ui_react_1.Divider, { className: 'my-0', key: 6 }),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 7 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, trans_1.trans('settings.integration.createdAt.label'))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, ((_k = (_j = (_h = this.state) === null || _h === void 0 ? void 0 : _h.integration) === null || _j === void 0 ? void 0 : _j.response) === null || _k === void 0 ? void 0 : _k.createdAt) ? moment((_o = (_m = (_l = this.state) === null || _l === void 0 ? void 0 : _l.integration) === null || _m === void 0 ? void 0 : _m.response) === null || _o === void 0 ? void 0 : _o.createdAt).format('YYYY.MM.DD. HH:mm') : '-'))),
            React.createElement(semantic_ui_react_1.Divider, { className: 'my-0', key: 8 }),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 9 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, trans_1.trans('settings.integration.updatedAt.label'))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, ((_r = (_q = (_p = this.state) === null || _p === void 0 ? void 0 : _p.integration) === null || _q === void 0 ? void 0 : _q.response) === null || _r === void 0 ? void 0 : _r.updatedAt) ? moment((_u = (_t = (_s = this.state) === null || _s === void 0 ? void 0 : _s.integration) === null || _t === void 0 ? void 0 : _t.response) === null || _u === void 0 ? void 0 : _u.updatedAt).format('YYYY.MM.DD. HH:mm') : '-'))),
            React.createElement(semantic_ui_react_1.Divider, { className: 'my-0', key: 10 }),
            this.renderChildProductSyncSetting()
        ];
    };
    IntegrationComponent.prototype.renderChildProductSyncSetting = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        if (this.props.showV1Helper) {
            return null;
        }
        return [
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 11 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, "Gyerek-term\u00E9kek szinkroniz\u00E1ci\u00F3ja")),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, ((_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.integration) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.syncWithChildren) === true ? 'be van kapcsolva' : 'nincs bekapcsolva'))),
            React.createElement(semantic_ui_react_1.Button, { key: 12, className: "ml-4 mb-2", onClick: function () { var _a, _b, _c; return _this.setSyncWithChildren(((_c = (_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.integration) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.syncWithChildren) === true); }, content: "Gyermek-term\u00E9kek szinkroniz\u00E1ci\u00F3j\u00E1nak " + (((_f = (_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.integration) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.syncWithChildren) === true ? 'kikapcsolása' : 'bekapcsolása') })
        ];
    };
    IntegrationComponent.prototype.renderV1Helper = function () {
        var _this = this;
        var _a, _b;
        if (!this.props.showV1Helper) {
            return null;
        }
        return [
            React.createElement(semantic_ui_react_1.Grid.Row, { key: 0 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, "Misys V1 Bridge adatok"))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, "Webshop API el\u00E9r\u00E9si \u00FAt")),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null,
                        "http://v1-connector.v3.miclub.hu/", (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 :
                        _b.integrationId,
                        "/"))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, "Wepshop API felhaszn\u00E1l\u00F3n\u00E9v")),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, "k32rf02l"))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, "Jelsz\u00F3")),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null,
                        React.createElement("span", null, "w"),
                        React.createElement("span", null, "3"),
                        React.createElement("span", null, "r"),
                        React.createElement("span", null, "q"),
                        React.createElement("span", null, "r"),
                        React.createElement("span", null, "x"),
                        React.createElement("span", null, "N"),
                        React.createElement("span", null, "y"),
                        React.createElement("span", null, "2"),
                        React.createElement("span", null, "5"),
                        React.createElement("span", null, "x"),
                        React.createElement("span", null, "Y"),
                        React.createElement("span", null, "S"),
                        React.createElement("span", null, "D"),
                        React.createElement("span", null, "d"),
                        React.createElement("span", null, "n")))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2, key: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, "Webshop k\u00E9pek el\u00E9r\u00E9si \u00FAt")),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement("strong", null, "https://misys-v3-uat-cdn.fra1.digitaloceanspaces.com"))),
            React.createElement(semantic_ui_react_1.Grid.Row, { as: semantic_ui_react_1.Form, onSubmit: function () { return _this.form.submit(); }, columns: 2, key: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 4 },
                    React.createElement("span", null, "Webshop el\u00E9r\u00E9si \u00FAt")),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.url, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                        React.createElement(semantic_ui_react_1.Form.Field, { control: function (props) { return (React.createElement(semantic_ui_react_1.Input, __assign({}, props))); }, fluid: true, disabled: this.state.updateLoading }))),
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 },
                    React.createElement(semantic_ui_react_1.Button, { loading: this.state.updateLoading, disabled: this.state.updateLoading, content: 'Mentés', type: "submit" }))),
            React.createElement(semantic_ui_react_1.Divider, { className: 'my-0', key: 2 })
        ];
    };
    IntegrationComponent.prototype.renderIntegrationProcess = function () {
        if (!this.props.showSyncProgress) {
            return null;
        }
        return [
            React.createElement(semantic_ui_react_1.Grid.Row, { key: 0 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, trans_1.trans('settings.integration.sync.title')))),
            React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1, key: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, { width: 10 }, this.renderSyncStatus()))
        ];
    };
    IntegrationComponent.prototype.startCreateNewPingEventForSubscribersScript = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var shopId, integrationId, url, response, jobName_1, timerId, error_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('Called startCreateNewPingEventForSubscribersScript');
                        if (this.state.isRunningScript) {
                            console.log('Cannot start a new script while a script is already running!');
                            return [2];
                        }
                        this.setState({ loading: true });
                        shopId = this.state.shop._id;
                        integrationId = this.state.shop.integrationId;
                        url = "/s/" + shopId + "/shop/inactive-subscribers/rainditas/" + integrationId;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4, this.integration.post(url)];
                    case 2:
                        response = _b.sent();
                        jobName_1 = ((_a = response === null || response === void 0 ? void 0 : response.job) === null || _a === void 0 ? void 0 : _a.name) || "";
                        console.log("response from " + url, response);
                        timerId = setInterval(function () {
                            _this.getCreateNewPingEventForSubscribersScriptStatus(jobName_1);
                        }, 20000);
                        this.setState({
                            isRunningScript: true,
                            createNewPingEventForSubscribersStatus: response.data,
                            createNewPingEventForSubscribersScriptStatusTimer: timerId
                        });
                        toast_util_1.openSuccessToast("Sikeres ráindítás kezdeményezés! Folyamatosan frissítjük a script állapotát.");
                        return [3, 4];
                    case 3:
                        error_1 = _b.sent();
                        console.log('startCreateNewPingEventForSubscribersScript error', error_1);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.getCreateNewPingEventForSubscribersScriptStatus = function (jobName) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function () {
            var shopId, integrationId, url, response, error_2;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        console.log('Called getCreateNewPingEventForSubscribersScriptStatus');
                        if (!this.state.isRunningScript) {
                            console.log('Cannot query script status in case there is no running script');
                            clearInterval(this.state.createNewPingEventForSubscribersScriptStatusTimer);
                            this.setState({
                                isRunningScript: false,
                                createNewPingEventForSubscribersStatus: { status: "done" },
                                createNewPingEventForSubscribersScriptStatusTimer: null,
                            });
                            return [2];
                        }
                        shopId = this.state.shop._id;
                        integrationId = this.state.shop.integrationId;
                        url = "/s/" + shopId + "/shop/inactive-subscribers/rainditas/" + integrationId + "?jobName=" + jobName;
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 3, , 4]);
                        return [4, this.integration.get(url)];
                    case 2:
                        response = _g.sent();
                        console.log('response', response);
                        console.log('response?.status?.succeeded', (_a = response === null || response === void 0 ? void 0 : response.status) === null || _a === void 0 ? void 0 : _a.succeeded);
                        console.log('response?.status?.succeeded >= 1', ((_b = response === null || response === void 0 ? void 0 : response.status) === null || _b === void 0 ? void 0 : _b.succeeded) >= 1);
                        if (((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.status) === "done" ||
                            ((_d = response === null || response === void 0 ? void 0 : response.status) === null || _d === void 0 ? void 0 : _d.succeeded) >= 1) {
                            console.log('Script has finished, stop polling');
                            clearInterval(this.state.createNewPingEventForSubscribersScriptStatusTimer);
                            this.setState({
                                isRunningScript: false,
                                createNewPingEventForSubscribersStatus: { status: "done" },
                                createNewPingEventForSubscribersScriptStatusTimer: null,
                            });
                            toast_util_1.openSuccessToast("A ráindítás script sikeresen lefutott.");
                        }
                        else if (((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.status) === "error" ||
                            ((_f = response === null || response === void 0 ? void 0 : response.status) === null || _f === void 0 ? void 0 : _f.failed) > 0) {
                            console.log('Error! Stop polling');
                            clearInterval(this.state.createNewPingEventForSubscribersScriptStatusTimer);
                            this.setState({
                                isRunningScript: false,
                                createNewPingEventForSubscribersStatus: { status: "error" },
                                createNewPingEventForSubscribersScriptStatusTimer: null,
                            });
                            toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_ERROR_FROM_RAINDITAS_POLLING);
                        }
                        else {
                            this.setState({
                                createNewPingEventForSubscribersStatus: response.data
                            });
                        }
                        return [3, 4];
                    case 3:
                        error_2 = _g.sent();
                        console.log('getCreateNewPingEventForSubscribersScriptStatus error', error_2);
                        toast_util_1.openErrorToast(errors_constant_1.ERROR_ENUM.ERROR_API_ERROR_FROM_RAINDITAS_POLLING);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    IntegrationComponent.prototype.renderInactiveSubscribersCompontent = function () {
        var _this = this;
        return (React.createElement("div", null,
            this.state.isRunningScript && (React.createElement("div", { style: { width: "25%" } },
                React.createElement(semantic_ui_react_1.Loader, { active: this.state.isRunningScript, inline: 'centered', size: 'big' }, "Fut a script..."))),
            React.createElement(semantic_ui_react_1.Button, { onClick: function () { return _this.startCreateNewPingEventForSubscribersScript(); }, disabled: this.state.isRunningScript, content: 'Ráindítás', className: "mt-2" })));
    };
    return IntegrationComponent;
}(abstract_component_1.AbstractComponent));
exports.IntegrationComponent = IntegrationComponent;
